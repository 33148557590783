import { useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Dimmer, Dropdown, Input, Loader, Modal, Radio, TextArea } from "semantic-ui-react";
import DeleteIcon from "../../components/icons/DeleteIcon";
import EditIcon from "../../components/icons/EditIcon";
import styled from "styled-components";
import { deleteVLinkNotification, insertVLinkNotification, updateVlinkNotification } from "../../graphql/customMutations";
import { getVlinkNotifications } from "../../graphql/customQueries";
import { useAlerts } from "../../hooks/useAlerts";
import { FeatureBlock } from "./FeatureBlock";

const NotificationsWrapper = styled.div``;

const NotificationList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 1rem;
`;

const NotificationItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;

    padding: 1rem;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #B5B5C3;

    .header {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        h5 {
            font-size: 1.1rem;
            font-weight: 400;
            margin: 0;
            color: #212121;
        }

        span {
            font-size: 1rem;
            color: #464E5F;
            flex-shrink: 0;
        }
    }
    
    .description {
        color: #777;
        overflow: hidden;
        overflow-wrap: break-word;
        margin-bottom: 20px;
    }

    .actions {
        display: flex;
        gap: 1rem;
        justify-content: flex-end;
        .alignContainer {
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 5px;
          }
        }
        .delete {
            color: #a54c4c;
            font-weight: 500;
        }

        .delete, .edit {
            cursor: pointer;
        }
    }
`;

const ModalInputs = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    .radio:not(:first-child) {
        margin-left: 20px;
    }

    textarea {
        width: 400px;
        height: 100px;
        padding: 10px;
        resize: none;
        color: rgba(0,0,0,.95);
        border: 1px solid rgba(34,36,38,.15);
        border-radius: 0.28571429rem;
        background: #fff;
        box-shadow: 0 0 0 0 rgb(34 36 38 / 35%) inset;

        &:focus {
            border-color: #85b7d9;
        }
    }

    .hidden {
        display: none;
    }

    .large {
        width: 500px;

        .dropdown {
            width: 500px;
        }
    }
    
`;

const ViewMoreBtn = styled.button`
  display: flex;
  position: relative;
  font-size: 16px;
  color: #595973;
  background: transparent;
  border: none;
  font-weight: 500;
  cursor: pointer;
  margin: 15px 0 15px auto;
  padding: 0;
`

function createEmptyNotification(level, targetId) {
    return {
        source_id: null,
        vendor_id: level === 'VENDOR' ? targetId : null,
        product_id: level === 'PRODUCT' ? targetId : null,
        notification_source_type: 'OTHER',
        notification_audience_type: 'EVERYONE',
        title: null,
        description: null,
        alert_timestamp: new Date().toISOString()
    }
}

export default function NotificationsBlock({permissions, level, vsourceData, page}) {
    const showMoreItems = 3;

    const { id: targetId } = useParams();

    const [notifications, setNotifications] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedNotification, setSelectedNotification] = useState(createEmptyNotification(level, targetId));
    const [loading, setLoading] = useState(false);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    const [showItemsCounter, setShowItemsCounter] = useState(showMoreItems);

    const onCollapse = useCallback(() => {
        if (!!notifications && !!notifications.length && (notifications.length > showItemsCounter)) {
            setShowItemsCounter(showItemsCounter + showMoreItems);
        } else if (notifications.length - 1 <= showItemsCounter) {
            setShowItemsCounter(showMoreItems);
        }
    }, [
        showItemsCounter,
        notifications,
        showMoreItems
    ]);

    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const [insertNotificationMutation] = useMutation(insertVLinkNotification);
    const [updateNotificationMutation] = useMutation(updateVlinkNotification);
    const [deleteNotificationMutation] = useMutation(deleteVLinkNotification);

    const { loading_notifications, error, data } = useQuery(getVlinkNotifications, {
		variables: { type: level, id: targetId},
		skip: !permissions.isVLinkPremiumUser,
	});

	useEffect(() => {
        if(data?.vlink?.userNotifications && Array.isArray(data.vlink.userNotifications)) {
            setNotifications(data.vlink.userNotifications);
        }
    }, [data])

    const filledNotifications = useMemo(() => {
        return notifications.map(notification => {
            switch(notification.notification_source_type) {
                case 'CORPORATE_ACTION':
                    const relatedCorporateAction = vsourceData?.corporate_actions.find(action => action.id === notification.source_id);
                    if(!relatedCorporateAction) {
                        return notification;
                    }
                    return {
                        ...notification,
                        title: relatedCorporateAction.type,
                        description: relatedCorporateAction.description,
                        alert_timestamp: relatedCorporateAction.action_time
                    }
                case 'PRODUCT_CHANGE':
                    const relatedProductChange = vsourceData?.product_change.find(change => change.id === notification.source_id);
                    if(!relatedProductChange) {
                        return notification;
                    }
                    return {
                        ...notification,
                        title: relatedProductChange.change_type,
                        description: relatedProductChange.change_notes,
                        alert_timestamp: relatedProductChange.change_time
                    }
                default: // other type
                    return notification;
            }
        })
    }, [notifications, vsourceData])

    function AddNewClickHandler() {
        setSelectedNotification(createEmptyNotification(level, targetId));
        setModalIsOpen(true);
        setSelectedNotificationId(null);
    }

    function EditClickHandler(notification) {
        let nextSelectedNotification = {};
        for(let prop in selectedNotification) {
            nextSelectedNotification[prop] = notification[prop];
        }
        setSelectedNotification(nextSelectedNotification);
        setModalIsOpen(true);
        setSelectedNotificationId(notification.id);
    }

    function modalCloseHandler(){
        setModalIsOpen(false);
        setSelectedNotification(createEmptyNotification(level, targetId));
    }

    function modalTypeChangeHandler(_, data) {
        const nextSelectedNotification = {
            ...selectedNotification,
            notification_source_type: data.value,
            title: null,
            description: null
        };

        setSelectedNotification(nextSelectedNotification);
    }

    function modalTitleChangeHandler(_, data) {
        if(selectedNotification.notification_source_type === 'OTHER') {
            setSelectedNotification({
                ...selectedNotification,
                title: data.value
            })
        } else {
            const nextSelectedNotification = {
                ...selectedNotification,
                source_id: data.options.find(opiton => opiton.value === data.value).id,
                title: null,
                description: null
            };

            if(data !== 'OTHER') {
                if(level === 'VENDOR') {
                    const relatedAction = vsourceData?.corporate_actions.find(action => action.type_code === data.value);
                    if(relatedAction) {
                        nextSelectedNotification.alert_timestamp = relatedAction.action_time;
                    }
                } else {
                    const relatedAction = vsourceData?.product_change.find(action => action.typeCode === data.value);
                    if(relatedAction) {
                        nextSelectedNotification.alert_timestamp = relatedAction.changeTime;
                    }
                }
            }

            setSelectedNotification(nextSelectedNotification);
        }
    }

    function modalDateChangeHandler(_, data) {
        setSelectedNotification({
            ...selectedNotification,
            alert_timestamp: new Date(data.value).toISOString()
        })
    }

    function modalDescriptionChangeHandler(_, data) {
        setSelectedNotification({
            ...selectedNotification,
            description: data.value
        })
    }

    async function insertNotification() {
        try {
            setLoading(true);
            const res = await insertNotificationMutation({
                variables: {
                    input: selectedNotification
                },
            });
            
            if (res?.data?.vlink?.insertVLinkNotification?.success) {
                setNotifications([
                    ...notifications,
                    {
                        ...selectedNotification,
                        id: res.data.vlink.insertVLinkNotification.notification_id
                    }
                ])
                setModalIsOpen(false);
                newSuccessAlert("Alert added successfully");
            } else {
                newErrorAlert("Failed to add a new alert");
            }
        } catch (e) {
            console.log("GraphQL fetch error:", e);
            newErrorAlert("Failed to add a new alert");
        } finally{
            setLoading(false);
        }
    }

    async function updateNotification() {
        try {
            setLoading(true);
            const res = await updateNotificationMutation({
                variables: {
                    updateVLinkNotificationId: selectedNotificationId,
                    input: selectedNotification
                },
            });
            
            if (res?.data?.vlink?.updateVLinkNotification?.success) {
                let selectedNotificationIndex = notifications.findIndex(notification => notification.id === selectedNotificationId);
                if(selectedNotificationIndex > -1) {
                    let nextNotifications = [...notifications];
                    nextNotifications.splice(selectedNotificationIndex, 1, {...selectedNotification, id: selectedNotificationId});
                    setNotifications(nextNotifications);
                }
                setModalIsOpen(false);
                newSuccessAlert("Alert updated successfully");
            } else {
                newErrorAlert("Failed to update the alert");
            }
        } catch (e) {
            console.log("GraphQL fetch error:", e);
            newErrorAlert("Failed to update the alert");
        } finally{
            setLoading(false);
        }
    }

    function modalSaveHandler() {
        if(selectedNotificationId) {
            updateNotification();
        } else {
            insertNotification();
        }
    }

    async function deleteHandler(id) {
        try {
            setLoading(true);
            const res = await deleteNotificationMutation({
                variables: {
                    deleteVLinkNotificationId: id
                },
            });
            
            if (res?.data?.vlink?.deleteVLinkNotification?.success) {
                let selectedNotificationIndex = notifications.findIndex(notification => notification.id === id);
                
                if(selectedNotificationIndex > -1) {
                    let nextNotifications = [...notifications];
                    nextNotifications.splice(selectedNotificationIndex, 1);
                    setNotifications(nextNotifications);
                }
                newSuccessAlert("Alert deleted successfully");
            } else {
                newErrorAlert("Failed to delete the alert");
            }
        } catch (e) {
            console.log("GraphQL fetch error:", e);
            newErrorAlert("Failed to delete the alert");
        } finally{
            setLoading(false);
        }
    }
    const showButtons = permissions.isVLinkPremiumUser && !page.isVSourceVendorPage && !page.isPreviewOrPublic;

    return (
        <FeatureBlock
            title="Notifications"
            headerButtonValue={showButtons ? "Add new alert" : null}
            onTitleBtnClick={AddNewClickHandler}
        >
            {loading || loading_notifications ? (
                <Dimmer active inverted inline="centered" style={{ height: "100%" }}>
                    <Loader />
                </Dimmer>
            ) :
                <NotificationsWrapper>
                    <NotificationList>
                        {
                            filledNotifications.map((notification, index) => showItemsCounter >= index + 1 ? (
                                <NotificationItem key={notification.id}>
                                    <div className="header">
                                        <h5>{notification.title}</h5>
                                        <span>{format(notification.alert_timestamp, "DD-MMM-YYYY")}</span>
                                    </div>
                                    <div className="description">
                                        <p>
                                            {notification.description}
                                        </p>
                                    </div>
                                    {
                                        showButtons && (
                                            <div className="actions">
                                                <div className="delete alignContainer" onClick={() => deleteHandler(notification.id)}>
                                                    <DeleteIcon />
                                                    <span>Delete</span>
                                                </div>
                                                <div className="edit alignContainer" onClick={() => EditClickHandler(notification)}>
                                                    <EditIcon/>
                                                    <span>Edit</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                </NotificationItem>
                            ): null)
                        }
                    </NotificationList>
                    {
                        !!notifications && !!notifications.length && notifications.length > showMoreItems ?
                            <ViewMoreBtn onClick={onCollapse} type="button">
                                {
                                    notifications.length > showItemsCounter ? "Show more" :
                                    notifications.length - 1 <= showItemsCounter ? "Show less": null
                                }
                            </ViewMoreBtn> : null
                    }
                </NotificationsWrapper>
        }
        <Modal
            open={modalIsOpen}
            onClose={modalCloseHandler}
        >
            <Modal.Header>{`${selectedNotificationId ? 'Update' : 'Add'} Alert`}</Modal.Header>
            <Modal.Content>
                <ModalInputs>
                    <div style={{width: '100%'}}>
                        <h5>Type</h5>
                        <div>
                            {level === 'VENDOR' ?
                                <Radio
                                    label='Company Actions'
                                    name='type'
                                    value='CORPORATE_ACTION'
                                    checked={selectedNotification.notification_source_type === 'CORPORATE_ACTION'}
                                    onChange={modalTypeChangeHandler}
                                /> :
                                <Radio
                                    label='Product Status Updates'
                                    name='type'
                                    value='PRODUCT_CHANGE'
                                    checked={selectedNotification.notification_source_type === 'PRODUCT_CHANGE'}
                                    onChange={modalTypeChangeHandler}
                                />
                            }
                            <Radio
                                label='Other'
                                name='type'
                                value='OTHER'
                                checked={selectedNotification.notification_source_type === 'OTHER'}
                                onChange={modalTypeChangeHandler}
                            />
                        </div>
                        
                    </div>
                    <div>
                        <h5>
                            {
                                selectedNotification.notification_source_type === 'CORPORATE_ACTION' ? 'Company Actions' :
                                selectedNotification.notification_source_type === 'PRODUCT_CHANGE' ? 'Product Status Updates' :
                                'Title'
                            }
                        </h5>
                        <div className={selectedNotification.notification_source_type !== 'OTHER'? 'large' : ''}>
                            {selectedNotification.notification_source_type === 'OTHER' ?
                                <Input value={selectedNotification.title || ''} onChange={modalTitleChangeHandler} /> :
                                <Dropdown 
                                    label='Please Select' 
                                    search
                                    selection
                                    options={
                                        vsourceData ? (
                                            level === 'VENDOR' ? 
                                                vsourceData['corporate_actions']
                                                .map(el => ({
                                                    id: el.id,
                                                    text: el.type, 
                                                    value: el.type_code,
                                                    description: format(el.action_time, 'DD-MMM-YYYY')
                                                })) :
                                                vsourceData['product_change']
                                                .map(el => ({
                                                    id: el.id,
                                                    text: el.change_type, 
                                                    value: el.change_type_original,
                                                    description: format(el.change_time, 'DD-MMM-YYYY')
                                                }))
                                        ):
                                        []
                                    } 
                                    value={ 
                                        vsourceData ? 
                                            vsourceData[level === 'VENDOR' ? 'corporate_actions' : 'product_change']
                                            .find(el => el.id === selectedNotification.source_id)?.type_code :
                                        ''
                                    }
                                    onChange={modalTitleChangeHandler}
                                />
                            }
                        </div>
                    </div>
                    <div className={selectedNotification.notification_source_type !== 'OTHER' ? 'hidden' : ''}>
                        <h5>Alert date</h5>
                        <div>
                            <Input 
                                type='date' 
                                value={format(selectedNotification.alert_timestamp || new Date(), 'YYYY-MM-DD')}
                                onChange={modalDateChangeHandler} 
                                disabled={selectedNotification.notification_source_type !== 'OTHER'}
                            />
                        </div>
                    </div>
                    <div className={selectedNotification.notification_source_type !== 'OTHER' ? 'hidden' : ''} style={{width: '100%'}}>
                        <h5>Description</h5>
                        <div>
                            <TextArea
                                disabled={selectedNotification.notification_source_type !== 'OTHER'}
                                value={selectedNotification.description || ''}
                                onChange={modalDescriptionChangeHandler}
                            />
                        </div>
                    </div>
                    
                </ModalInputs>
            </Modal.Content>
            <Modal.Actions>
                <Button positive onClick={modalSaveHandler}>
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
        </FeatureBlock>
    );
}
