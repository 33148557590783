import styled from "styled-components/macro";

export const PageWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
    margin: 0;
`;
