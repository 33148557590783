import { resetFieldValues } from "../../../helpers/vlink/revisionHelpers";

export default function formRowReducer(fieldsetState, action) {
	console.log('RowListReducer: [[' + action.type + ']]');
	switch (action.type) {
		case 'UPDATE_FIELDSET': {
			return action.data;
		}
		case 'CHANGE_FIELD_VALUE': {
			let {context = 'VENDOR', rowIndex, fieldIndex, key: keys, value: values } = action;

			let nextFieldsetState = [...fieldsetState];
			let nextRow = { ...nextFieldsetState[rowIndex] };
			let nextFields = [...nextRow.fields];
			let nextField = { ...nextFields[fieldIndex] };

			if (!Array.isArray(keys)) {
				keys = [keys];
				values = [values];
			}

			keys.forEach(function changeValue(key, index) {
				nextField[key === 'value' ? 'latestNewValue' : key] = values[index];
			});

			if (nextField.latestNewValue != null) {
				if(context === 'VENDOR') {
					nextRow.proposed = true;
					nextRow.certified = false;
				} else {
					nextRow.accepted = false;	
				} 
			}

			nextFields.splice(fieldIndex, 1, nextField);
			nextRow.fields = nextFields;
			nextFieldsetState.splice(rowIndex, 1, nextRow);

			return nextFieldsetState;
		}
		case 'CHANGE_ROW_VALUE': {
			let { rowIndex, key: keys, value: values } = action;
			let nextFieldsetState = [...fieldsetState];
			let nextRow = { ...nextFieldsetState[rowIndex] };

			if (!Array.isArray(keys)) {
				keys = [keys];
				values = [values];
			}

			keys.forEach(function changeValue(key, index) {
				let nestedKeys = key.split('.');
				if(nestedKeys.length > 1) {
					let nextNestedObject = {...nextRow[nestedKeys[0]]}
					nextNestedObject[nestedKeys[1]] = values[index];
					nextRow[nestedKeys[0]] = nextNestedObject;
				} else {
					nextRow[key] = values[index];
				}

				nextFieldsetState.splice(rowIndex, 1, nextRow);
			});

			return nextFieldsetState;
		}
		case 'RESET_ROW': {
			let { rowIndex, context } = action;
			let nextFieldsetState = [...fieldsetState];
			let nextRow = { ...nextFieldsetState[rowIndex] };
			if (context !== 'VENDEX' && (nextRow.new || !nextRow.itemId) && nextRow.itemTypeCode === 'RECORD') {
				nextFieldsetState.splice(rowIndex, 1);
			} else {
				let nextFields = nextRow.fields.map(function resetField(field) {
					let nextField = { ...field };
					delete nextField.latestNewValue;
					return nextField;
				});

				nextRow.status = 'CREATED';
				nextRow.fields = nextFields;
				nextRow.proposed = false;

				nextFieldsetState[rowIndex] = nextRow;
			}

			return nextFieldsetState;
		}
		case 'ADD_ROW': {
			let { fields } = action;
			if(fields) {
				resetFieldValues(fields, true);
			}
			let nextRow = {
				...fieldsetState[0],
				certified: false,
				proposed: false,
				status: 'CREATING',
				new: true,
				fields: fields || fieldsetState[0].fields.map(function copyAndResetValues(field) {
					let nextField = { ...field, latestOldValue: '' };
					return nextField;
				})
			};

			// if(nextRow.tableName === 'location') {
			// 	nextRow.supportLocations = [...fieldsetState[0].supportLocations];
			// 	nextRow.supportLocations[0] = {
			// 		...nextRow.supportLocations[0],
			// 		fields: nextRow.supportLocations[0].fields.map(field => ({...field})),
			// 		parentItemId = locationData.itemId || -locationData.latestRevision?.revisionId || null;
			// 	}
			// }
			
			return [...fieldsetState, nextRow];
		}
		case 'DELETE_ROW': {
			let { rowIndex, level = 'soft' } = action;
			if (rowIndex < fieldsetState.length) {
				let nextFieldsetState = [...fieldsetState];
				if (level === 'soft') {
					let nextRow = { ...nextFieldsetState[rowIndex] };
					nextRow.deleted = true;
					nextRow.proposed = true;
					nextRow.certified = false;
					nextFieldsetState[rowIndex] = nextRow;
				} else {
					nextFieldsetState.splice(rowIndex, 1);
				}

				return nextFieldsetState;
			}
			return fieldsetState;
		}
		default:
			return fieldsetState;
	}
}