import React from "react";
import styled from "styled-components";
import Lottie from "react-lottie";
import loadingAnimData from "../anim/loading";

const LoaderWrapper = styled.div`
    width: 150px
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    left: 43%;
    z-index: 999;
    background-color: white;
    opacity: 0.8;
`;

const LoadingWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const Loader = () => {
    return (
        <LoaderWrapper>
            <p>Loading...</p>
        </LoaderWrapper>
    );
};

export const LoadingAnimation = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <LoadingWrapper>
            <Lottie options={defaultOptions} height={300} width={300} />
        </LoadingWrapper>
    );
};

export default Loader;
