import styled from 'styled-components';

export const WarningButtonPanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    
    & > * {
      margin-right: 8px;
    }
    
    & > *:last-child {
      margin-right: 0;
    }
`;
