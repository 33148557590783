import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { Storage } from "aws-amplify";
import _get from "lodash/get";

import { useVsourceResource } from "./vlink/hooks/useVsourceResource";
import { useCheckAuth } from "./Navigation/useCheckAuth";
import DefaultLogo from "../images/default_logo.svg";
import Eye from "../images/eye-alt.svg";
import EyeClosed from "../images/eye-close.svg";


export const ProfileHeaderWrapper = styled.div`
  padding: 45px 70px;
  box-sizing: border-box;
  background-color: rgb(241, 243, 244);
  display: flex;
  justify-content: flex-start;
  position: relative;
`;

export const HideEmptyFieldsButton = styled.div`
  position: absolute;
  top: calc(100% + 12px);
  font-size: 12px;
  right: 0;
  background: url(${Eye}) left 10px center no-repeat;
  padding: 2px 10px;
  padding-left: 30px;
  background-size: 16px;
  opacity: 0.8;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.085);
  cursor: pointer;
  
  &.active {
    background: #fff url(${EyeClosed}) left 10px center no-repeat;
    background-size: 16px;
  }
`;

export const PreviewBanner = styled.div`
  position: fixed;
  top: 5rem;
  left: 56px;
  right: 0;
  background: #2564BB;
  padding: 6px 8px;
  z-index: 2;
  color: #fff;
  text-align: center;
  font-weight: 600;
`;

const BoxShadowContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  min-width: 960px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);
  position: relative;
  & .logoContainer {
    max-width: 280px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    img{
      max-width: 100%;
      max-height: 100%;
      margin: auto;
    }
  }
`

const DescrContainer = styled.div`
    min-width: 20%;
    padding: 32px 16px;
    font-size: 16px;
    color: #464E5F;
`
const DescrRow = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
`
const DescrLabel = styled.div`
  margin-right: 4px;
  b:first-child {
    color: #F6C142;
  }
`
const DescrText = styled.span`
  font-weight: 600;
`
const ProfileHeader = ({ level, context }) => {
    const [logoUrl, setLogoUrl] = useState(null);
    const [emptyHidden, setEmptyHidden] = useState(false);
    const {
        isVSourceSuperUser,
        isVSourceAdminUser
    } = useCheckAuth();

    const vsourceData = useVsourceResource({ level });
    const formData = {};

    // A bit of a hack using DOM
    useEffect(() => {
        const elements = document.querySelectorAll('.empty-data');
        const parents = new Set();

        if (emptyHidden) {
            elements.forEach(el => {
                el.style.display = 'none';

                parents.add(el.parentNode);
            });
        } else {
            elements.forEach(el => {
                el.style.display = 'flex';

                parents.add(el.parentNode);
            });
        }

        parents.forEach(el => {
            // See if all are empty boys
            const allEmpty = el.querySelectorAll('.empty-data');
            const allNotEmpty = el.querySelectorAll('.has-data');

            if (emptyHidden && allEmpty.length > 0 && allNotEmpty.length === 0) {
                el.style.display = 'none';
            } else {
                el.style.display = 'block';
            }
        });

    }, [emptyHidden]);

    useEffect(() => {
        if(vsourceData){
            let logoName = '';
            if (context === 'VENDOR_PREVIEW' && vsourceData?.vsource?.vendor?.preview_vendor_logo_filename) {
                logoName = vsourceData?.vsource?.vendor?.preview_vendor_logo_filename;
            } else {
                logoName = vsourceData.vendor_logo_filename || vsourceData?.vendor?.vendor_logo_filename;
            }
            // Fetch Logo url
            if (
                logoName
            ) {
                // setLogoLoading(true);

                Storage.get(`vsource/logos/${logoName}`, {
                    level: "public",
                })
                    .then((s3ImageUrl) => {
                        setLogoUrl(s3ImageUrl);
                    })
                    .catch((e) => console.error("Error fetching logo URL: ", e));

                // setLogoLoading(false);
            } else{
                setLogoUrl(null);
            }
        }
    },[vsourceData]);

    const vidsvValue = useMemo(() => {
        if (level === 'VENDOR') {
            return `${_get(vsourceData, 'vsource.vendor.vendor_id_code', '')}-${_get(vsourceData, 'vsource.vendor.hierarchy_code', '')}`
        } else {

            return `${_get(vsourceData, 'vsource.product.vendor.vendor_id_code', '')}-${_get(vsourceData, 'vsource.product.vendor.hierarchy_code', '')}-${_get(vsourceData, 'vsource.product.product_id_code', '')}`
        }
    }, [level, vsourceData]);

    const previewFallbacks = [
        vsourceData?.vendor?.preview_brief_description,
        vsourceData?.vsource?.vendor?.preview_brief_description,
        vsourceData?.vsource?.product?.vendor?.preview_brief_description,
        vsourceData?.vsource?.product?.vendor?.brief_description,
        vsourceData?.vendor?.brief_description,
        vsourceData?.brief_description
    ];

    return (
    <>
       <ProfileHeaderWrapper>
           {context === 'VENDOR_PREVIEW' && <PreviewBanner>
               THIS IS A PREVIEW
           </PreviewBanner>}
           <BoxShadowContainer>
               <div className="logoContainer">
                   { logoUrl ?
                       <img src={logoUrl} alt="logo" /> :
                       <img className="default-logo" src={DefaultLogo} alt="default-logo" /> }
               </div>
               { context === 'VENDOR_PREVIEW' ?
                   previewFallbacks.find(f => !!f) : vsourceData?.vsource?.vendor?.brief_description || vsourceData?.vendor?.brief_description
               }
               <HideEmptyFieldsButton
                   onClick={() => setEmptyHidden(!emptyHidden)}
                   className={emptyHidden ? 'active' : ''}
               >
                   {!emptyHidden ? 'Hide Empty Fields' : 'Show Empty Fields'}
               </HideEmptyFieldsButton>
           </BoxShadowContainer>
           <DescrContainer>
               <DescrRow>
                   <DescrLabel>
                       <b>V</b><b>ID</b>:
                   </DescrLabel>
                   <DescrText>
                       {vidsvValue}
                   </DescrText>
               </DescrRow>
               {
                   level === 'VENDOR' && (isVSourceSuperUser ||
                       isVSourceAdminUser) && (
                       <DescrRow>
                           <DescrLabel>
                               Vendor Stage:
                           </DescrLabel>
                           <DescrText>
                               {_get(vsourceData, `vsource.vendor.vendor_stage_value`, "")}
                           </DescrText>
                       </DescrRow>
                   )
               }
               {
                   (isVSourceSuperUser ||
                       isVSourceAdminUser
                   ) && (
                       <DescrRow>
                           <DescrLabel>
                               Assigned Analyst:
                           </DescrLabel>
                           <DescrText>
                               {_get(vsourceData, `vsource.${level === "VENDOR" ? 'vendor' : 'product.vendor'}.assigned_analyst`, "")}
                           </DescrText>
                       </DescrRow>
                   )
               }
           </DescrContainer>
       </ProfileHeaderWrapper>
    </>
    )
};

export default ProfileHeader;