import { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { PageWrapper } from "../../components/PageWrapper";
import PremiumForm from "../../components/vlink/PremiumForm";
import RevisionForm from "../../components/vlink/RevisionForm";
import { AuthContext } from "../../context/AuthContext";
import { initialMessageBoxData, MessageBox, VendorFormsWrapper } from "./VendorDetails";
import Breadcrumb from "../../components/breadcrumb/VendexBreadcrumb";

export default function ProductDetails() {
    const [messageBoxData, setMessageBoxData] = useState(initialMessageBoxData);
    const messageRef = useRef(null);
    const history = useHistory();

    const {
        context: { user },
        isVLinkAnalyst,
        isVLinkVendor,
        isVLinkSuperUser,
    } = useContext(AuthContext);

    let context = isVLinkVendor(user) ? 'VENDOR' : (isVLinkAnalyst(user) || isVLinkSuperUser(user)) ? 'VENDEX' : null;

    const breadcrumbSections = [
        { key: "Dashboard", content: "Vendor Dashboard", onClick: ()=>history.push("/vlink/dashboard") },
        { key: "Product-Profile", content: "Product Profile" },
    ];

    useEffect(function hideMessageBox(){
        if(context === 'VENDEX') {
            let timeout = null;
            if(messageBoxData.message) {
                timeout = setTimeout(setMessageBoxData, 5000, initialMessageBoxData);  
            }

            return () => timeout && clearTimeout(timeout);
        }
        
    }, [messageBoxData, setMessageBoxData, context]);

    if(context == null) {
        return <Redirect to="/vlink/dashboard" />
    }

    return (
        <PageWrapper>
            {context === "VENDEX" && (
                <div
                    style={{
                        background: "#FFC30B",
                        color: "white",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: 6,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        textTransform: "uppercase",
                        fontSize: 14,
                        fontWeight: 600,
                    }}
                >
                    VendEx analyst view
                </div>
            )}
            <Breadcrumb sections={breadcrumbSections} />
            <PageHeader title='Product Profile'>
            </PageHeader>
            <MessageBox ref={messageRef}>
                {messageBoxData.message && 
                    <div>
                        <h3>{messageBoxData.message}</h3>
                        <p>{messageBoxData.subMessage}</p>
                    </div>
                }
            </MessageBox>
            <VendorFormsWrapper>
                <RevisionForm 
                    context={context}
                    level='PRODUCT' 
                    setMessageBoxData={setMessageBoxData} 
                    messageRef={messageRef}
                />
                <PremiumForm context={context} level='PRODUCT'/>
            </VendorFormsWrapper>
        </PageWrapper>
    )
}