import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
  /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }
  
  html, body, #root {
    position: relative !important;
    display: block;
    line-height: 1.4285em;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Muli', 'Open Sans', 'Montserrat', 'Lato', sans-serif;
  }
  
  body {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  * {
    box-sizing: border-box;
    outline: none;
  }

  h1 {
    font-size: 32px !important;
    font-weight: 100 !important;
    font-family: "Muli Light", 'Open Sans', 'Montserrat', 'Lato', sans-serif !important;
    color: #546982;
  }

  h2 {
    font-size: 20px !important;
    font-weight: 500;
  }

  h3 {
    font-size: 18px !important;
    font-weight: 500;
  }

  h4 {
    font-size: 16px !important;
    font-weight: 500;
  }
  
  .pdf-page {
    margin-bottom: 24px;
  }
`;

export default GlobalStyle;
