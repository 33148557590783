import React, { useContext } from "react";
import Select from "react-select";
import styled, { ThemeContext } from "styled-components";
import CompanyHeader from "../../../components/CompanyHeader";
import { TableComponent, Table, CloseIcon, FormInput, _dropDownStyles } from "../../../components/Tables";

const TableBlock = styled((props) => <Table {...props} />)`
    tr {
        td:first-child {
            & > div {
                font-weight: normal !important;
            }
        }
    }
`;
export const OfficersTable = ({ data, removeRow, options, setValue }) => {
    const themeContext = useContext(ThemeContext);
    const dropDownStyles = _dropDownStyles(themeContext);
    return (
        <TableComponent key={"off-table"}>
            <CompanyHeader title={"Officer Information"} />
            {data && data.length > 0 && (
                <TableBlock>
                    <tbody>
                        {data.map((row, i) => {
                            return (
                                <tr key={`off-row-${i}-${row.id}`}>
                                    <td>
                                        <div
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "1fr 10fr 10fr",
                                                alignItems: "center",
                                                columnGap: "30px",
                                                paddingRight: "1rem",
                                            }}
                                        >
                                            <CloseIcon title={"Delete"} onClick={() => removeRow(row.id)}>
                                                &times;
                                            </CloseIcon>
                                            <Select
                                                options={options}
                                                onChange={(e) => setValue("business_role_code", e.value, row.id)}
                                                defaultValue={
                                                    options.filter((el) => el.value === row.business_role_code)[0] ??
                                                    null
                                                }
                                                styles={dropDownStyles}
                                                key={`officer-select-${i}-${row.id}`}
                                            />
                                            <FormInput
                                                value={row.full_name}
                                                onChange={(e) => setValue("full_name", e.target.value, row.id)}
                                                key={`off-name-${i}-${row.id}`}
                                            />
                                        </div>
                                        {row.business_role_code === "ADDITIONAL_OFFICER" && (
                                            <div
                                                key={`off-name-${i}-${row.id}-additional`}
                                                style={{
                                                    display: "grid",
                                                    gridTemplateColumns: "1fr 10fr 10fr",
                                                    alignItems: "center",
                                                    columnGap: "30px",
                                                    paddingRight: "1rem",
                                                }}
                                            >
                                                <div></div>
                                                <span style={{ width: "100%", paddingLeft: "1rem" }}>
                                                    Additional Officer Title
                                                </span>
                                                <FormInput
                                                    value={row.business_role_additional}
                                                    onChange={(e) =>
                                                        setValue("business_role_additional", e.target.value, row.id)
                                                    }
                                                />
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableBlock>
            )}
        </TableComponent>
    );
};
