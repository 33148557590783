import React from "react";
import PdfViewer from "../pages/PdfViewer";
import {ButtonWrapper} from "./VendBtn";
import styled from "styled-components/macro";

const AddButtonWrapper = styled.div`
   position: absolute;
   bottom: 0;
   right: 0;
   left: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 20px;
`;

export const ModalPdfViewer = ({match, history, callbackFn}) => {
    return (
        <>
            <PdfViewer match={match} history={history}/>
            <AddButtonWrapper>
                <ButtonWrapper
                    data-cy={'attach-document-btn'}
                    onClick={(e) => {
                        callbackFn(match.params.id);
                        history.goBack();
                    }}>Attach Document</ButtonWrapper>
            </AddButtonWrapper>
        </>
    );
};