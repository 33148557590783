import _isEqual from "lodash/isEqual";

export default function validate(value, rules, allValues=[], field, row, rowList, context) {
    let errors = [];
    rules.forEach(function validateRule(rulePattern) {
        let message;
        let [rule, options] = rulePattern.split('|');
        switch (rule) {
            case 'required': {
                !notEmpty(value) && (message = 'This field is required');
                break;
            }
            case 'datetime': {
                !isCorrectDate(value, options) && (message = 'Incorrect date format. Must be ' + options);
                break;
            }
            case 'number': {
                !isNumeric(value) && (message = 'This field must be a number');
                break;
            }
            // this field is required if others are not set
            case 'required_partially': {
                !notAllEmpty(allValues) && (message = 'Please fill at least one field');
                break;
            }
            // this field is required if conditionFunction returns true
            case 'required_with_condition': {
                if (field?.conditionFunction(allValues)) {
                    !notEmpty(value) && (message = 'This field is required');
                }
                break;
            }
            case 'unique': {
                let otherValues = rowList
                    .filter(r => r.status !== 'TEMPLATE' && r !== row)
                    .map(r => {
                        let otherField = r.fields.find(f => f.name === field.name);
                        if(context === 'VENDOR') {
                            return otherField.latestNewValue ?? otherField.vsourceValue;
                        }

                        return otherField.submittedVendexValue ?? otherField.submittedNewValue ?? otherField.vsourceValue;
                    })

                !isUnique(value, otherValues) && (message = `Row with exact same ${field.name} already exists`);

                break;
            }
            case 'row_unique': {
                let otherFields = rowList
                    .filter(r => r.status !== 'TEMPLATE' && r !== row)
                    .map(r => {
                        return r.fields.map(field => {
                            if(context === 'VENDOR') {
                                return field.latestNewValue ?? field.vsourceValue;
                            }

                            return field.submittedVendexValue ?? field.submittedNewValue ?? field.vsourceValue;
                        });
                    });

                let fields = allValues.map(field => field ?? null);

                if(row.tableName === 'product_vidsv') {
                    fields = fields.slice(1);
                    otherFields = otherFields.map(fields => fields.slice(1));
                }

                !isUnique(fields, otherFields) && (message = 'Row with exact same values already exists');
                break;
            }
            default: {
                break;
            }
        }

        if (message) {
            errors.push({ rule, message });
        }
    });

    return errors;
}

export function notAllEmpty(allValues) {
    for(let i in allValues) {
        if(notEmpty(allValues[i])) {
            return true;
        }
    }

    return false;
}

export function notEmpty(value) {
    return value != null && value !== '';
}

export function isNumeric(value) {
    return !isNaN(value);
}

export function isCorrectDate(value, format) {
    const supportedSegments = {
        YYYY: '([1-9]\\d{3})',
        MM: '(0[1-9]|1[0-2])',
        M: '([1-9]|1[0-2])',
        DD: '([0-2][1-9]|[1-3]0|31)',
        D: '([1-9]|[1-2][0-9]|30|31)',
        HH: '([0-1][0-9]|2[0-3])',
        H: '([0-9]|1[0-9]|2[0-3])',
        mm: '([0-5][0-9])',
        m: '([0-9]|[1-5][0-9])',
        ss: '([0-5][0-9])',
        s: '([0-9]|[1-5][0-9])'
    }

    const supportedSeparators = {
        '/': '\\/',
        ':': ':',
        ' ': '\\s',
        '-': '-'
    };

    // take segments and seperators from date format string
    const regexp = `(${Object.keys(supportedSegments).join('|')})(${Object.keys(supportedSeparators).join('|')}|$)`;
    const matchesIterator = format.matchAll(regexp);

    // build regex from taken format segments and seperators
    let res = '^';
    for (let match of matchesIterator) {
        res += supportedSegments[match[1]] + (supportedSeparators[match[2]] || '');
    }
    res += '$';

    return RegExp(res).test(value);
}

export function isUnique(data, otherData) {
    for(let d of otherData){
        if(_isEqual(data, d)) {
            return false;
        }
    }

    return true;
}