import userEmail from "../../utils/userEmail";
import { isArray, get } from "lodash";
import API, { graphqlOperation } from "@aws-amplify/api";

const synchronizeProductAndPricingGroups = async (
    metaId,
    groups,
    createMutation,
    updateMutation,
    deleteMutation,
    { user, activeEntity }
) => {
    if (!isArray(groups)) {
        return { id: null, value: null };
    }

    await groups.reduce(async (prev, group) => {
        await prev;

        let operation = createMutation;
        let input = {
            ...group,
            agreementMetaProductAndPricingGroupsId: metaId,
            groupsCanAccess: activeEntity?.groupName,
            createdBy: userEmail(user),
        };

        const productId = get(group, "product.id");

        // It's not allowed to edit the link to the VKey product anymore
        /* if (productId) {
            input.productAndPricingGroupProductId = productId;
        }
        */

        delete input.product;
        delete input.vSourceProduct;

        if (group.id) {
            if (group.removed) {
                operation = deleteMutation;
                input = {
                    id: input.id,
                };
            } else {
                operation = updateMutation;
                delete input.createdBy;
                input.updatedBy = userEmail(user);
            }
        } else {
            if (group.removed) return null;
        }

        return await API.graphql(
            graphqlOperation(operation, {
                input,
            })
        );
    }, Promise.resolve());

    return { id: null, value: null };
};

export default synchronizeProductAndPricingGroups;
