import { Switch } from "react-router-dom";
import { VSourceAdminRoute } from "./VSourceAdminRoute";
import { VSourceRoute } from "./VSourceRoute";
import VendorDetailsPublicPage from "../pages/VLink/VendorDetailsPublic";
import ProductDetailsPublicPage from "../pages/VLink/ProductDetailsPublic";
import { ProductConfiguratorPage } from "../pages/VSource/ProductConfiguratorPage";
import { VendorConfiguratorPage } from "../pages/VSource/VendorConfiguratorPage/VendorConfiguratorPage";
import { ProductsPage } from "../pages/VSource/ProductsPage";
import { VendorsPage } from "../pages/VSource/VendorsPage";
import AddVendorPage from "../pages/VSource/AddVendorPage";
import AddProductPage from "../pages/VSource/ProductConfiguratorPage/AddProductPage";

export default function VSourceRoutes({match}){
    return (
        <Switch>
            <VSourceAdminRoute
                path={`${match.url}/products/product-configurator/:id`}
                component={ProductConfiguratorPage}
            />
            <VSourceAdminRoute
                path={`${match.url}/product/edit/:id`}
                exact={true}
                component={AddProductPage}
            />
            <VSourceAdminRoute path={`${match.url}/product/add`} exact={true} component={AddProductPage} />
            <VSourceRoute path={`${match.url}/product/:id`} component={ProductDetailsPublicPage} />
            <VSourceAdminRoute path={`${match.url}/products`} exact={true} component={ProductsPage} />
            <VSourceAdminRoute
                path={`${match.url}/vendors/vendor-configurator/:id`}
                component={VendorConfiguratorPage}
            />
            <VSourceAdminRoute path={`${match.url}/vendor/add`} exact={true} component={AddVendorPage} />
            <VSourceRoute path={`${match.url}/vendors/:id`} component={VendorDetailsPublicPage} />
            <VSourceRoute path={`${match.url}/vendors`} component={VendorsPage} />
        </Switch>
    );
}