import React, { useState, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { graphqlOperation } from "@aws-amplify/api-graphql";
import API from "@aws-amplify/api";

import { deleteVendor } from "../../graphql/mutations";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";
import { useAlerts } from "../../hooks/useAlerts";
import { AuthContext } from "../../context/AuthContext";
import { ButtonWrapper } from "../../components/VendBtn";

const VendorEditButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
`;

const VendorEditButtons = ({ context, activeTab }) => {
    const [showVendorDeleteModal, setShowVendorDeleteModal] = useState(false);

    const {
        context: { user },
        isVSourceVendexSuperUser,
    } = useContext(AuthContext);
    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const history = useHistory();
    const { id: targetId } = useParams();

    const isVSourcePage = window.location.pathname.includes(`/vsource/vendors/${targetId}`);

    const onDeleteVendor = async () => {
        if (targetId) {
            try {
                const { data } = await API.graphql(
                    graphqlOperation(deleteVendor, {
                        id: targetId,
                    })
                ).catch((e) => {
                    console.error("GraphQL fetch error:", e);
                    throw new Error(e.errors[0].message);
                });

                if (data && data.deleteVendor) {
                    newSuccessAlert(`Vendor deleted successfully`);
                    history.push(`/vsource/vendors`);
                } else newErrorAlert("Failed to delete vendor");
            } catch (e) {
                console.log("Delete vendor errors: ", e);
                newErrorAlert(e.message);
            }
        }
    };

    // Show the buttons only on VSource vendor page if the user is a Vendex-Super-User
    if (!(isVSourcePage && ["VENDOR_PREVIEW", "PUBLIC"].includes(context) && isVSourceVendexSuperUser(user))) {
        return null;
    }

    return (
        <React.Fragment>
            {showVendorDeleteModal && (
                <LargeConfirmationModal
                    show={showVendorDeleteModal}
                    onClose={() => setShowVendorDeleteModal(false)}
                    onSubmit={() => {
                        setShowVendorDeleteModal(false);
                        onDeleteVendor();
                    }}
                    onDiscard={() => setShowVendorDeleteModal(false)}
                    title={"Delete Vendor?"}
                    description={"Once deleted, you will not be able to recover the Vendor"}
                />
            )}
            <VendorEditButtonsWrapper>
                {activeTab === "Products" && (
                    <ButtonWrapper
                        space-between
                        onClick={() => history.push(`/vsource/product/add?vendor=${targetId}`)}
                    >
                        Add Product
                    </ButtonWrapper>
                )}
                <ButtonWrapper
                    space-between
                    onClick={() => history.push(`/vsource/vendors/vendor-configurator/${targetId}`)}
                >
                    <span style={{ marginRight: "4px" }}>Edit vendor</span>
                </ButtonWrapper>
                <ButtonWrapper space-between onClick={() => setShowVendorDeleteModal(true)}>
                    Delete Vendor
                </ButtonWrapper>
            </VendorEditButtonsWrapper>
        </React.Fragment>
    );
};

export default VendorEditButtons;
