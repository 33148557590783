export const getEnvByHostname = () => {
    let env = "unknown";
    
    if(window.location.host.includes('localhost:3000')){
      env = "localhost";
    } else if(window.location.host.includes('dev.vendexsolutions.com')){
      env = "test";
    } else if(window.location.host.includes('demo.vendexsolutions.com')){
      env = "demo";
    } else if(window.location.host.includes('staging.vendexsolutions.com')){
      env = "staging";
    } else if(window.location.host.includes('app.vendexsolutions.com')){
      env = "prod";
    }
    
    return env;
  }