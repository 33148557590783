import React, { useEffect, useCallback, useContext } from "react";
import { ThemeProvider } from "styled-components";
import { AgreementProvider } from "./context/agreement/AgreementContext";
import { AUTH_STATE, AuthContext } from "./context/AuthContext";
import theme from "./styles/theme";
import GlobalStyle from "./styles/GlobalStyle";
import { Switch, Route, useHistory } from 'react-router-dom';
import { Layout } from "./layouts/MainLayout";
import { BaseRoute } from "./components/BaseRoute";
import LoginPage from "./pages/LoginPage";
import { VsourceProvider } from "./context/vsource/vsourceContext";
import { AlertContextProvider } from "./context/alert/AlertContext";
import { AlertsComponent } from "./components/Alerts";
import ApolloProvider from "./components/ApolloProvider";
import ErrorBoundary from "./components/ErrorBoundary";
import API from "@aws-amplify/api";

import Amplify, { Auth, Hub } from "aws-amplify";
import aws_exports from "./aws-exports";
import * as Sentry from "@sentry/react";

import { LoginRoute } from "./components/LoginRoute";
import { getUserRegistryData } from "./utils/user-registry-utils";

// The CSS files are loaded in a different order in local dev and production build
// That's why we are using the CDN alternative for now
// import "semantic-ui-css/semantic.min.css";
import "react-table/react-table.css";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./styles/react-table-overrides.css";
import "./styles/rootStyles.css";
import { NotificationContextProvider } from "./context/notification/NotificationContext";
import AcceptTerms from "./components/AcceptTerms/AcceptTerms";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import { getUserCredentailsFromAutenticationCode } from "./utils/user-registry-utils";

Amplify.configure(aws_exports);

//Main Application component.
const App = () => {
    const { context, dispatch, checkEntityAccess, getAuthTime } = useContext(AuthContext);
    const history = useHistory();

    const ssoLogin = async (code) => {
        const jwt = await getUserCredentailsFromAutenticationCode(code);
        if (!jwt) {
            return;
        }
        try {
            const AccessToken = new AmazonCognitoIdentity.CognitoAccessToken({ AccessToken: jwt.access_token });
            const IdToken = new AmazonCognitoIdentity.CognitoIdToken({ IdToken: jwt.id_token });
            const RefreshToken = new AmazonCognitoIdentity.CognitoRefreshToken({ RefreshToken: jwt.refresh_token });
            const sessionData = { IdToken, AccessToken, RefreshToken };
            const newSession = new AmazonCognitoIdentity.CognitoUserSession(sessionData);

            const ClientId = AccessToken.payload.client_id;
            const iss = AccessToken.payload.iss;
            const UserPoolId = iss?.split(".amazonaws.com/")[1];

            const Pool = new AmazonCognitoIdentity.CognitoUserPool({ UserPoolId, ClientId });
            const userData = { Username: AccessToken.payload.username, Pool };
            const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
            cognitoUser.setSignInUserSession(newSession);
            Auth.currentAuthenticatedUser()
                .then(() => console.log("SSO User auth"))
                .catch((e) => console.log("SSO AuthErr:", e));
            const session = await Auth.currentSession();
            API.session = session;
        } catch (e) {
            console.log("SSO AuthErr:", e);
        }
    };

    const checkLogin = useCallback(async () => {
        let user = undefined;

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        const code = params.code;
        if (code) {
            await ssoLogin(code);
        }

        try {
            user = await Auth.currentAuthenticatedUser();
            Sentry.setUser({ email: user?.attributes?.email || "unknown" });
            checkEntityAccess(user);
            // Get UR stuff on the user
            user = await getUserRegistryData(user);

            dispatch({ type: "SIGN_IN", user });
        } catch (err) {
            console.log("currentAuthenticatedUser error : ", err);
            dispatch({ type: "SIGN_IN" }); // TODO: Is this really the right behaviour?
        } finally {
            if (user && !user.challengeName) {
                const last_login = await getAuthTime(user);
                dispatch({ type: "UPDATE_USER_LAST_LOGIN", last_login });
            }
        }
    }, []);

    useEffect(() => {
        const { user } = context;

        if (user?.attributes) {
            const terms = user?.attributes['custom:terms'];

            // TODO: VDX-29 Enable terms again
            /*
            if (!terms) {
                history.push('/accept-terms');
            }
            */
        }
    }, [context.user]);

    useEffect(() => {
        checkLogin();
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                    dispatch({ type: "LOGIN", user: data });
                    break;
                case "signOut":
                    dispatch({ type: "LOGOUT" });
                    break;
                case "signIn_failure":
                    dispatch({
                        type: "SIGN_IN_FAILURE",
                        message: data.message,
                    });
                    break;
                default:
                    break;
            }
        });
    }, []);

    return (
        <ErrorBoundary>
            <GlobalStyle />
            <AlertContextProvider>
                <ApolloProvider>
                    <NotificationContextProvider>
                        <AgreementProvider>
                            <VsourceProvider>
                                <ThemeProvider theme={theme}>
                                    <AlertsComponent />
                                    {context.authState !== AUTH_STATE.LOADING ? (
                                        <Switch>
                                            <BaseRoute exact path={"/"} />
                                            <LoginRoute path={"/login"} component={LoginPage} />
                                            <Route path="/accept-terms" component={AcceptTerms} />
                                            <Route component={Layout} />
                                        </Switch>
                                    ) : (
                                        <div>Loading</div>
                                    )}
                                </ThemeProvider>
                            </VsourceProvider>
                        </AgreementProvider>
                    </NotificationContextProvider>
                </ApolloProvider>
            </AlertContextProvider>
        </ErrorBoundary>
    );
};

export default App;
