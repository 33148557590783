import React from "react";
import styled from "styled-components";

import { FormTable } from "../../../components/Tables";

const TableWrapper = styled.div`
 & > div {
    padding: 0;
    margin-right: 45%;
    
    h1 {
        font-size: 14px !important;
        font-weight: 700 !important;
    }    
    table {
        border: 1px solid #347FF6;
        border-radius: 5px;
        border-collapse: separate;
        tr{
            background-color: transparent !important;
            td:first-child {
                width: 13rem;
                display: table-cell;
                vertical-align: middle;
            }
            td:nth-child(2) {
                width: 100%;
            }

            td {
                font-size: 14px;
            }
        }
    }
}
`;

export const ProductChangeTables = ({
    data = [],
    changeTypes,
    setFieldValue,
    removeProductChangeRow,
    addProductChangeRow,
}) => {
    return data.map((productChange, i) => {
        if(productChange.action_type === "DELETE"){
            return null;
        }
        const data = [
            {
                title: "Change Type",
                type: "dropdown",
                value: changeTypes,
                onChange: (selection) => setFieldValue("change_type_original", selection.value, productChange.id),
                props: {
                    onRemove: () => removeProductChangeRow(productChange.id),
                    selected: productChange.change_type_original || changeTypes[0].value,
                },
            },
            {
                title: "Change Date",
                type: "date",
                value: productChange.change_time ? new Date(productChange.change_time) : null,
                onChange: (date) =>
                setFieldValue("change_time", date, productChange.id),
            },
            {
                title: "Change Notes",
                type: "textarea",
                value: productChange.change_notes,
                onChange: (value) =>
                setFieldValue("change_notes", value, productChange.id),
                props: {
                    rows: 5
                }
            },
            
        ];

        return (
            <TableWrapper key={`loc-${productChange.id}`}>
                <FormTable title={i === 0 ? "Product Status Updates" : ""} titleMargin={false} data={data} />
            </TableWrapper>
        );
    });
};
