import React, { createContext, useEffect, useMemo } from "react";
import { Auth, Storage } from "aws-amplify";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useImmerReducer } from "use-immer";
import { format } from "date-fns";
import _get from "lodash/get";
import _uniq from "lodash/uniq";
import _omit from "lodash/omit";
import _sortBy from "lodash/sortBy";
import * as Sentry from "@sentry/react";
import * as queries from "../graphql/queries";
import { createUserStat } from "../graphql/mutations";
import { USER_ROLES, USER_STATS_TYPES, ACTIVE_VLINK_VENDOR } from "../Constants";
import { setStorage, getStorage, removeStorage } from "../utils/localStorage";
import { useHistory } from "react-router-dom";
import { getUserRegistryData } from "../utils/user-registry-utils";

const axios = require("axios");
const jwtDecode = require("jwt-decode");

const AUTH_STATE = {
    LOADING: "LOADING",
    LOADED: "LOADED",
    SIGNED_IN: "SIGNED_IN",
    SIGNED_IN_PROBLEM: "SIGNED_IN_PROBLEM",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    LOGGED_OUT: "LOGGED_OUT",
};

const DOC_LIST_SORT = {
    ASC: "ASC",
    DESC: "DESC",
};

const VKEY_ACTIVE_ENTITY = "VKEY_ACTIVE_ENTITY";

const initialActiveEntity = {
    name: "",
    id: "",
    logoUrl: "",
    groupName: "",
};

const initialActiveVendor = {
    full_legal_name: "",
    id: "",
    vendor_id_code: "",
};

const initialState = {
    user: null,
    userLastLogin: 0,
    loadingDocs: false,
    authState: AUTH_STATE.LOADING,
    entityCheckLoading: true,
    docSearch: "",
    docList: {},
    docItems: [],
    searchItems: [],
    uploadFiles: {},
    downloadList: {},
    signInError: "",
    changePasswordError: "",
    activeEntity: initialActiveEntity,
    entities: [], // List of entity groups the user has access to
    activeVendor: initialActiveVendor,
    availableVendors: [], // List of vendors the user has access to (VLink)
    docSort: DOC_LIST_SORT.ASC,
    sendResetError: "",
    resetError: "",
    vendors: null,
};

const getVendorByIdCode = async (id) => {
    // Fetch the vendor name from VSource

    if (!id) {
        return;
    }

    const vendorDataQuery = /* GraphQL */ `
        query vendorsBy($param: String!, $value: String!) {
            vendorsBy(param: $param, value: $value) {
                data {
                    id
                    full_legal_name
                    vendor_id_code
                }
            }
        }
    `;
    try {
        const {
            data: {
                vendorsBy: { data },
            },
        } = await API.graphql(
            graphqlOperation(vendorDataQuery, {
                param: "vendor_id_code",
                value: id,
            }),
        );

        if (Array.isArray(data) && data.length > 0) {
            return data[0];
        }
        return null;
    } catch (e) {
        console.error("Error fetching vendor data from VSource:", e);
    }
};

// Get the list of allowed vendors and fetch the vendor data from VSource
const getUserVendorsList = async (user) => {
    const _isVLinkAnalyst = isVLinkAnalyst(user);
    const _isVLinkVendor = isVLinkVendor(user);
    const _isVlinkSuperUser = isVLinkSuperUser(user);

    console.log("getUserVendorsList", user);

    // Check only vendor users
    if (_isVLinkVendor && (!_isVLinkAnalyst || !_isVlinkSuperUser)) {
        if (!user?.attributes || !user.attributes["custom:vendor_id_code"]) {
            throw new Error("Missing user attributes: custom:vendor_id_code");
        }
        const vendorIdCode = _get(user, "attributes[\"custom:vendor_id_code\"]", null);
        const vendor = await getVendorByIdCode(vendorIdCode);

        console.log("vendorIdCode", vendor);
        console.log("vendor", vendor);


        if (vendor) {
            return [vendor];
        }
        return [];
    }
    throw new Error("Only vendor users are allowed to query vendors list from Cognito");
};

const logOut = async () => {
    try {
        // Logout from BE
        await fetch(process?.env?.REACT_APP_VPORT_API_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "authentication": `Bearer ${await getCurrentJWT()}`,
            },
            body: JSON.stringify({
                operationName: "Mutation",
                query: `
                    mutation Mutation{
                        logout
                    }
                `,
                variables: {},
            }),
        });

        Sentry.configureScope(scope => scope.setUser(null));
        await Auth.signOut();
        localStorage.clear();

        return true;
    } catch (err) {
        console.log("Logout error");
    }
};

const signIn = async ({ email, password }) => {
    const amplifyUser = await Auth.signIn({
        username: email,
        password,
    });

    const user = await getUserRegistryData(amplifyUser);

    try {
        // If the user is signed in and don't need to replace password etc.
        if (user && !user.challengeName) {
            // Get the last login timestamp from the DB
            user.last_login = await getAuthTime(user);

            // Send new login timestamp to the DB
            API.graphql(
                graphqlOperation(createUserStat, {
                    input: {
                        username: _get(user, "attributes.email"),
                        userId: _get(user, "username"),
                        type: USER_STATS_TYPES.LOGIN,
                        value: Date.now(),
                    },
                }),
            );
        }
    } catch (e) {
        console.log("Sending user statistics error:", e);
    } finally {
        return user;
    }
};

const changePassword = async ({ user, newPassword, requiredAttributes }) => {
    return Auth.completeNewPassword(user, newPassword, requiredAttributes);
};

const forgotPassword = async ({ username }) => {
    return await Auth.forgotPassword(username);
};

const resetPassword = async ({ username, code, newPassword }) => {
    return await Auth.forgotPasswordSubmit(username, code, newPassword);
};

const setupMFA = async ({ user }) => {
    return await Auth.setupTOTP(user);
};

const verifyTotpToken = async ({ user, code }) => {
    try {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "TOTP");

        return true;
    } catch (e) {
        throw e;
    }
};

const getNonCachedUser = async () => {
    return await Auth.currentAuthenticatedUser();
};

const fetchDocs = async ({ searchTerm = "", entityGroup }) => {
    const graphqlOptions = {
        limit: 4000,
        dummy: "a",
        groupsCanAccess: entityGroup,
        sortDirection: "DESC",
        filter: {
            migrate: {
                eq: true,
            },
        },
    };
    let items = [];
    try {
        if (searchTerm !== "") {
            // console.log("begin search");
            graphqlOptions.filter.vendExFileName = {
                contains: searchTerm,
            };
        }

        const {
            data: { documentsByGroupAccessAndDate },
        } = await API.graphql(graphqlOperation(queries.documentsByGroupAccessAndDate, graphqlOptions));
        items = documentsByGroupAccessAndDate.items;
    } catch (e) {
        console.log("fetchDocs error :", e);
        if (e.data) {
            console.log("Partially applying update");
            items = e.data.documentsByGroupAccessAndDate.items;
        }
    }

    const docList = items.reduce((memo, {
        updatedAt,
        vendExFileName,
        id,
        agreements,
        docAgreementName,
        docEndDate,
        docNumberOfPages,
        docProductNames,
        docVendorName,
        uploadDate,
        uploadedBy,
    }) => {
        const lastModified = new Date(updatedAt);

        const dateKey = format(lastModified, "dddd MMMM, Do YYYY");

        if (memo[dateKey]) {
            memo[dateKey]["docs"].push({
                docId: id,
                id: vendExFileName,
                description: "",
                filename: vendExFileName,
                docAgreementName,
                docEndDate,
                docNumberOfPages,
                docProductNames,
                docVendorName,
                uploadDate,
                uploadedBy,
                agreements: agreements.items
                    .filter(({ agreement }) => agreement !== null)
                    .map(({ agreement }) => agreement),
            });
        } else {
            memo[dateKey] = {
                docs: [
                    {
                        docId: id,
                        id: vendExFileName,
                        description: "",
                        filename: vendExFileName,
                        docAgreementName,
                        docEndDate,
                        docNumberOfPages,
                        docProductNames,
                        docVendorName,
                        uploadDate,
                        uploadedBy,
                        agreements: agreements.items
                            .filter(({ agreement }) => agreement !== null)
                            .map(({ agreement }) => agreement),
                    },
                ],
            };
        }

        return memo;
    }, {});

    // console.log("listDocuments: ", documents);

    return {
        docList,
        docItems: items,
    };
};

const fetchDoc = async ({ key }) => {
    try {
        return await Storage.get(key);
    } catch (err) {
        console.log(err);
        return err;
    }
};

const uploadDoc = async (file, { progressCallback }, folderName) => {
    try {
        const name = encodeURIComponent(file.name);
        //console.log("Upload to:", `vkey/contracts/${folderName}/${file.name}-${new Date().getTime()}`);
        return await Storage.put(`vkey/contracts/${folderName}/${name}-${new Date().getTime()}`, file, {
            contentType: file.type,
            metadata: { filename: `${name}` },
            contentDisposition: `filename=\"${file.name}\"`,
            progressCallback,
        });
    } catch (err) {
        console.log("uploadDoc err ", err);
    }
};

// Define all user roles and the hierarchy here
const roleHierarchy = {
    [USER_ROLES.VENDEX_SUPER_USER]: {
        [USER_ROLES.VSOURCE_VENDEX_SUPER_USER]: [USER_ROLES.VSOURCE_VENDEX_USER, USER_ROLES.VSOURCE_CLIENT_USER],
        [USER_ROLES.VKEY_VENDEX_SUPER_USER]: [
            USER_ROLES.VKEY_CLIENT_USER,
            USER_ROLES.VKEY_CLIENT_SECURE_VAULT_USER,
            USER_ROLES.VKEY_VENDEX_ANALYST,
        ],
        [USER_ROLES.VKEY_VENDEX_ANALYST]: [USER_ROLES.VKEY_CLIENT_USER],
        [USER_ROLES.VSOURCE_VENDEX_USER]: [USER_ROLES.VSOURCE_CLIENT_USER],
        [USER_ROLES.VPORT_VENDEX_ANALYST]: [USER_ROLES.VPORT_CLIENT_USER],
        // [USER_ROLES.VLINK_VENDEX_ANALYST]: [USER_ROLES.VLINK_VENDOR_USER],
        [USER_ROLES.VLINK_VENDEX_ANALYST]: [],
    },
};

const getUserAccessRoles = (groups) => {
    let r = [];
    groups.forEach((group) => {
        // Add the selected user group and all of the subroles
        r = [...r, group, ...getSubRoles(group)];
    });
    return _uniq(r);
};

const getSubRoles = (role) => {
    const superAdmin = USER_ROLES.VENDEX_SUPER_USER;
    const VSourceAdmin = USER_ROLES.VSOURCE_VENDEX_SUPER_USER;
    const VSourceVendexUser = USER_ROLES.VSOURCE_VENDEX_USER;
    const VKeyAdmin = USER_ROLES.VKEY_VENDEX_SUPER_USER;
    const VKeyAnalyst = USER_ROLES.VKEY_VENDEX_ANALYST;
    const VPortAnalyst = USER_ROLES.VPORT_VENDEX_ANALYST;
    const VLinkAnalyst = USER_ROLES.VLINK_VENDEX_ANALYST;

    let lst = [];

    switch (role) {
        // If super user then return all roles
        case superAdmin:
            Object.keys(roleHierarchy[superAdmin]).forEach((key) => {
                lst.push(key);
                lst = [...lst, ...roleHierarchy[superAdmin][key]];
            });
            break;

        case VSourceAdmin:
            lst = [...lst, ...roleHierarchy[superAdmin][VSourceAdmin]];
            break;

        case VSourceVendexUser:
            lst = [...lst, ...roleHierarchy[superAdmin][VSourceVendexUser]];
            break;

        case VKeyAdmin:
            lst = [...lst, ...roleHierarchy[superAdmin][VKeyAdmin]];
            break;

        case VKeyAnalyst:
            lst = [...lst, ...roleHierarchy[superAdmin][VKeyAnalyst]];
            break;

        case VPortAnalyst:
            lst = [...lst, ...roleHierarchy[superAdmin][VPortAnalyst]];
            break;

        case VLinkAnalyst:
            lst = [...lst, ...roleHierarchy[superAdmin][VLinkAnalyst]];
            break;

        default:
            lst.push(role);
    }

    return lst;
};

// Get all roles user has access to
// If user has higher role (admin) then we add the permissions of the subroles also
const userAccessRoles = (user) => {
    const groups = getUserGroups({ user });
    return getUserAccessRoles(groups);
};

const userHavePermission = ({ user }, role) => {
    // TODO: VDX-29 Enable terms again
    return /* user?.attributes["custom:terms"] && */ userAccessRoles(user).includes(role);
};

// VKey super user or analyst
const isVKeyAdminUser = (user) => userHavePermission({ user }, USER_ROLES.VKEY_VENDEX_ANALYST);
const isVKeySuperUser = (user) => userHavePermission({ user }, USER_ROLES.VKEY_VENDEX_SUPER_USER);
const isVKeyClientUser = (user) => userHavePermission({ user }, USER_ROLES.VKEY_CLIENT_USER);
const isVKeyClientUserOnly = (user) => getUserGroups({ user }).includes(USER_ROLES.VKEY_CLIENT_USER);
const isVSourceAdminUser = (user) => userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_USER);
const isVSourceVendexSuperUser = (user) => userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER);
const isVSourceClientUserOnly = (user) => getUserGroups({ user }).includes(USER_ROLES.VSOURCE_CLIENT_USER);
const isVSourceUser = (user) => userHavePermission({ user }, USER_ROLES.VSOURCE_CLIENT_USER);
const isVPortAnalyst = (user) => userHavePermission({ user }, USER_ROLES.VPORT_VENDEX_ANALYST);
const isVPortClientUserOnly = (user) => getUserGroups({ user }).includes(USER_ROLES.VPORT_CLIENT_USER);
const isVLinkAnalyst = (user) => userHavePermission({ user }, USER_ROLES.VLINK_VENDEX_ANALYST);
const isVLinkVendor = (user) => userHavePermission({ user }, USER_ROLES.VLINK_VENDOR_USER);
const isVLinkSuperUser = (user) => userHavePermission({ user }, USER_ROLES.VLINK_SUPER_USER);
const isVLinkPremiumUser = (user) => userHavePermission({ user }, USER_ROLES.VLINK_PREMIUM_USER);

const isVendexSuperUser = (user) => userHavePermission({ user }, USER_ROLES.VENDEX_SUPER_USER);

const isVKey2Analyst = (user) => userHavePermission({ user }, USER_ROLES.VKEY2_ANALYST);
const isVKey2ReadOnly = (user) => userHavePermission({ user }, USER_ROLES.VKEY2_READ_ONLY);

const getUserJWT = (user) => {
    return _get(user, "signInUserSession.idToken.jwtToken");
};

// Aync function to get the current JWT and refresh it in the background if needed
const getCurrentJWT = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession();
    if (session) {
        // console.log('session JWT:', session.getAccessToken().getJwtToken());
        // console.log('Token data:', jwtDecode(session.getAccessToken().getJwtToken()));
        return session.getAccessToken().getJwtToken();
    }

    return null;
};

const getUserGroups = ({ user }) => {
    if (user?.userRegistryData) {
        try {
            const entitys = user?.userRegistryData?.entityUsers;
            const roles = user?.userRegistryData?.userRoles;
            return entitys.map((e) => `Entity-${e.name}`).concat(roles.map((r) => r.name));
        } catch (e) {
            console.error("Error when calling getUserGroups, using fallback - ", e);
        }
    }

    // Old code pre UR - use as fallback
    const token = getUserJWT(user);
    if (token) {
        const auth = jwtDecode(token);
        // console.log("Token info: ", auth);
        const groups = auth["cognito:groups"] ?? [];
        return groups;
    } else {
        console.log("Token not found");
        return [];
    }
};

const getFreshUser = async () => {
    const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
    });

    return user;
};

const getAuthTime = async (user) => {
    if (!user || user.challengeName) {
        return false;
    }

    const userId = user?.username;
    let last_login = 0;

    if (!userId) {
        return false;
    }
    try {
        const { data } = await API.graphql(
            graphqlOperation(queries.userStatsByTypeByDate, {
                userId,
                typeCreatedAt: {
                    beginsWith: {
                        type: USER_STATS_TYPES.LOGIN,
                    },
                },
                sortDirection: "DESC",
                limit: 1,
            }),
        );
        last_login = _get(data, "userStatsByTypeByDate.items[0].value", 0);
    } catch (e) {
        console.log("Error getting last login time:", e);
        return false;
    }
    return parseInt(last_login, 10);
};

const reducer = (draft, action) => {
    switch (action.type) {
        case "SIGN_IN":
            if (action.user && action.user.last_login) {
                draft.userLastLogin = action.user.last_login;
            }
            if (action.user?.vendorsList && Array.isArray(action.user.vendorsList)) {
                draft.activeVendor = action.user.vendorsList[0];
                draft.availableVendors = action.user.vendorsList;
            }
            draft.user = action.user;
            draft.authState = action.user ? AUTH_STATE.SIGNED_IN : AUTH_STATE.LOADED;
            draft.signInError = "";
            break;
        case "SIGN_IN_FAILURE":
            draft.signInError = action.message;
            break;
        case "LOADING_DOCS":
            draft.loadingDocs = true;
            break;
        case "DOC_SEARCH":
            draft.searchItems = action.searchItems;
            draft.docSearch = action.docSearch;
            break;
        case "SET_VKEY_ENTITY":
            draft.activeEntity = action.activeEntity;
            setStorage(VKEY_ACTIVE_ENTITY, action.activeEntity);
            break;
        case "SET_USER_ENTITIES":
            draft.entities = action.entities;
            break;
        case "SET_ACTIVE_VENDOR":
            draft.activeVendor = action.activeVendor;
            setStorage(ACTIVE_VLINK_VENDOR, action.activeVendor);
            break;
        case "SET_USER_VENDORS":
            draft.availableVendors = action.availableVendors;
            break;
        case "FETCH_DOCS":
            draft.docList = action.docList;
            draft.docItems = action.docItems;
            draft.loadingDocs = false;
            break;
        case "CLEAR_DOCS":
            draft.docList = [];
            draft.docSearch = "";
            break;
        case "SORT_DOCS":
            draft.docSort = draft.docSort === DOC_LIST_SORT.ASC ? DOC_LIST_SORT.DESC : DOC_LIST_SORT.ASC;
            draft.docList = action.docList;
            draft.docItems = action.docItems;
            break;
        case "ADD_DOWNLOAD":
            draft.downloadList = action.downloadList;
            break;
        case "REMOVE_DOWNLOAD":
            console.log("action.key : ", action.key);
            draft.downloadList = _omit(draft.downloadList, [action.key]);
            break;
        case "CHANGE_PASSWORD":
            draft.changePasswordError = action.changePasswordError.message;
            break;
        case "LOGOUT":
            draft.authState = AUTH_STATE.LOGGED_OUT;
            draft.user = null;
            draft.activeEntity = initialActiveEntity;
            draft.docList = initialState.docList;
            draft.docItems = initialState.docItems;
            draft.activeVendor = initialActiveVendor;
            draft.availableVendors = [];
            removeStorage(ACTIVE_VLINK_VENDOR);
            break;
        case "UPDATE_USER":
            draft.user = action.user;
            break;
        case "SEND_PASSWORD_RESET":
            draft.authState = AUTH_STATE.RESET_PASSWORD;
            draft.sendResetError = action.sendResetError;
            break;
        case "RESET_PASSWORD":
            draft.resetError = action.resetError;
            break;
        case "SET_ENTITY_LOADING":
            draft.entityCheckLoading = action.loading;
            break;
        case "UPDATE_USER_LAST_LOGIN":
            draft.userLastLogin = action.last_login;
            break;
        default:
            break;
    }
};

const AuthContext = createContext(initialState);

const AuthProvider = ({ children, ...rest }) => {
    const [context, dispatch] = useImmerReducer(reducer, initialState);

    const checkAndRefreshSession = async () => {
        let user;

        try {
            user = await Auth.currentAuthenticatedUser();
        } catch (e) {
        }

        if (user) {
            const token = getUserJWT(user);
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            const expIn = decodedToken.exp - currentTime;

            if (expIn <= 120) {
                console.log("Token expiring, refreshing");
                await refreshUser(); // Maybe needed?
                const newToken = await getCurrentJWT();

                if (newToken) {
                    const proxyEvent = new CustomEvent("new-token", {
                        detail: { token: newToken },
                    });

                    window.dispatchEvent(proxyEvent);
                }
            }
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            checkAndRefreshSession().then(r => {});
        }
    };

    useEffect(() => {
        let interval;

        checkAndRefreshSession().then(() => {
            interval = setInterval(checkAndRefreshSession, 60000);
        });

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            clearInterval(interval);
            document.removeEventListener('visibilitychange', handleVisibilityChange)
        };
    }, []);

    const refreshUser = async () => {
        const user = await getFreshUser();
        dispatch({
            type: "UPDATE_USER",
            user,
        });
    };

    // Check access to Entity data in VKey
    const checkEntityAccess = async (user) => {
        dispatch({
            type: "SET_ENTITY_LOADING",
            loading: true,
        });

        try {
            let {
                data: {
                    listEntitiess: { items },
                },
            } = await API.graphql(graphqlOperation(queries.listEntitiess, {}));
            // console.log("Entities:", items);

            items = _sortBy(items, ["name"]);

            const entityGroups = items.map((el) => el.groupName);

            dispatch({ type: "SET_USER_ENTITIES", entities: entityGroups });

            const userGroups = getUserGroups({ user });
            // console.log("All user groups:", userGroups);

            if (userGroups) {
                if (entityGroups.length > 0) {
                    let entityFromLocalStorage = undefined;

                    try {
                        entityFromLocalStorage = getStorage(VKEY_ACTIVE_ENTITY);
                    } catch (e) {
                        console.error("Error receiving entity from localStorage: ", e);
                    }

                    // Set the first group as a default
                    let defaultEntity = items[0];

                    // If the entity is found from localstorage use this one
                    if (entityFromLocalStorage && !!items.find(el => el?.clientID === entityFromLocalStorage?.id)) {
                        defaultEntity = entityFromLocalStorage;
                    }

                    // console.log("default entity:", defaultEntity);

                    if (defaultEntity) {
                        dispatch({
                            type: "SET_VKEY_ENTITY",
                            activeEntity: {
                                name: defaultEntity.name,
                                id: defaultEntity.clientID,
                                logoUrl: defaultEntity.logoUrl,
                                groupName: defaultEntity.groupName,
                            },
                        });
                    }
                }
            }
            // console.log("User entity groups:", entityGroups);
        } catch (e) {
            console.log("Get entities error:", e);
        } finally {
            dispatch({
                type: "SET_ENTITY_LOADING",
                loading: false,
            });
        }
    };

    const value = useMemo(
        () => ({
            context,
            dispatch,
            fetchDocs,
            fetchDoc,
            uploadDoc,
            signIn,
            logOut,
            changePassword,
            forgotPassword,
            resetPassword,
            setupMFA,
            verifyTotpToken,
            getUserGroups,
            isVKeyAdminUser,
            isVKeySuperUser,
            isVKeyClientUser,
            isVKeyClientUserOnly,
            isVSourceClientUserOnly,
            isVSourceAdminUser,
            isVSourceVendexSuperUser,
            isVSourceUser,
            isVPortAnalyst,
            isVPortClientUserOnly,
            isVLinkAnalyst,
            isVLinkVendor,
            isVLinkSuperUser,
            isVLinkPremiumUser,
            isVendexSuperUser,
            isVKey2Analyst,
            isVKey2ReadOnly,
            userHavePermission,
            checkEntityAccess,
            refreshUser,
            getAuthTime,
            getUserJWT,
            getCurrentJWT,
            getVendorByIdCode,
            getUserVendorsList,
            getFreshUser,
            getNonCachedUser,
        }),
        [context],
    );

    return (
        <AuthContext.Provider {...rest} value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider, AUTH_STATE, DOC_LIST_SORT };
