import React, { useState } from "react";
import styled from "styled-components/macro";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import VidsvModal from "../components/VidsvModal";
import { useMutation } from "@apollo/client";
import cloneDeep from "lodash/cloneDeep";

import { useAlerts } from "../hooks/useAlerts";
import { createProductVidsv, deleteProductVidsv, updateProductVidsv } from "../graphql/customMutations";
import { vidsvMultiLabels, vidsvMultiKeys, vidsvMultiTypes } from "../Constants";
import { getPathToNestedClassifier } from "../helpers/vlink/revisionHelpers";
import { Popup } from "semantic-ui-react";

const VidsvTableWrapper = styled.div`
    width: 100%;
`;

const TableWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
`
const TableHeader = styled.div`
  display: flex;
  width: 100%;
`
const TableBodyRow = styled.div`
    width: 100%;
    padding: 15px;
    
    &:nth-of-type(odd) {
        border: 1px solid #F5F6F7;
        background: #FBFCFD;
    }
    
    .row {
        display: flex;
        align-items: flex-start;
    }

    p.name {
        // color: black;
        font-style: italic;
    }
`
const TableTdHeader = styled.div`
  flex: 1;
  padding: 15px;
  font-weight: bold;
  color: #6A7891;
  text-transform: uppercase;
`
const TableTdBody = styled.div`
  flex: 1;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #252525;
  &:last-child {
    display: flex;
    justify-content: right;
  }
`
const VidsvItem = styled.span`
    width: 100%;
`
const AddNewRowButton = styled.button`
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  color: #3172BB;
`

const DeleteButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #AE5D65;
    cursor: pointer;
    margin-right: 10px;
    background: none;
    border: none;
`
const EditButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7F7E93;
    cursor: pointer;
    background: none;
    border: none;
`

const DuplicateButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7F7E93;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
`
const stylesForDeleteIcon = {
    fontSize: 22,
    color: "#AE5D65",
    marginRight: "5px"
}

const stylesForEditIcon = {
    fontSize: 22,
    color: "#7F7E93",
    marginRight: "5px"
}

const stylesForAddIcon = {
    color: "#3172BB",
    marginRight: "5px"
}

export function getPath(id, classifiers, index) {
    let path = '';
    if(classifiers.length) {
        path = getPathToNestedClassifier(id, classifiers[index] || []);
    }
    if(vidsvMultiTypes[index] === 'PRODUCT_GEOGRAPHY') {
        path = path.split('> ').slice(-1).toString();
    }

    return path;
}


export const VidsvTable = (
    {
        productId,
        templates = [],
        defaultSelectedValues,
        refreshTableData,
        vidsvClassifiers
    }
) => {
    const [loadingModal, setLoadingModal] = useState(false);
    const [modalMode, setModalMode] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [onCreateProductVidsv] = useMutation(createProductVidsv);
    const [onUpdateProductVidsv] = useMutation(updateProductVidsv);

    const [onDeleteProductVidsv] = useMutation(deleteProductVidsv);

    const { newErrorAlert } = useAlerts();

    const onClose = () => {
        setModalMode('');
        setEditRow(null);
    }
    const showAddModal = (initialValues = null) => {
        setModalMode('add');
        setEditRow(initialValues);
    }
    const onSetEditRow = (row) => {
        setModalMode('edit');
        setEditRow(row);
    }
    const onSave = async (selectedItems, mode, name) => {
        let input = {name};
        vidsvMultiKeys.forEach((key, index) => {
            let singleVidsvValues = selectedItems[vidsvMultiTypes[index]];
            if(singleVidsvValues.length) {
                input[key] = singleVidsvValues;
            } else {
                input[key] = null;
            }
        });

        try {
            setLoadingModal(true);
            let res;
            if (mode === 'add') {
                const { data } = await onCreateProductVidsv({
                    variables: {
                        productId: Number(productId),
                        input: input
                    }
                });
                console.log(data, 'data');
                res = data;
            } else if (mode === 'edit') {
                const { data } = await onUpdateProductVidsv({
                    variables: {
                        updateProductVdisvId: Number(editRow.id),
                        input: input
                    }
                });
                res = data;
            }
            setLoadingModal(false);
            if (res) {
                setModalMode('');
                setEditRow(null);
                await refreshTableData();
            }
        } catch (e) {
            setLoadingModal(false);
            newErrorAlert(e.message);
            console.error(e, 'Error with edit or create vidsv row')
        }
    }
    const onDelete = async (id) => {
        try {
            const { data } = await onDeleteProductVidsv({
                variables: {
                    deleteProductVidsvId: Number(id),
                }
            });
            if (data) {
                await refreshTableData();
            }
        } catch (e) {
            setLoadingModal(false);
            newErrorAlert(e.message);
            console.error(e, 'Issue with on delete vidsv item')
        }
    }

    function duplicateHandler(row) {
        let rowClone = cloneDeep(row);
        rowClone.name = rowClone.name + ' copy #' + (defaultSelectedValues.length + 1)
        showAddModal(rowClone);
    }
    return (
        <VidsvTableWrapper>
            <VidsvModal
                show={!!modalMode}
                loading={loadingModal}
                mode={modalMode}
                onClose={onClose}
                onSave={onSave}
                templates={templates}
                editData={editRow}
                allRows={defaultSelectedValues}
                vidsvClassifiers={vidsvClassifiers}
            />
            <TableWrapper>
                <TableHeader>
                    {vidsvMultiLabels.map(type => (
                        <TableTdHeader key={type}>
                            {type}
                        </TableTdHeader>
                    ))}
                    <TableTdHeader >
                    </TableTdHeader>
                </TableHeader>
                {
                    defaultSelectedValues.map((row) => (
                        <TableBodyRow key={row.id}>
                            <p className="name"><strong>{row.name}</strong></p>
                            <div className="row">
                            {vidsvMultiKeys.map((key, index) => (
                                <TableTdBody key={key}>
                                    {
                                        (row[key] || []).map(({ id, path }) => (
                                            <VidsvItem key={id}>
                                                {path}
                                            </VidsvItem>
                                        ))
                                    }
                                </TableTdBody>
                            ))}
                            <TableTdBody>
                                <DeleteButton type="button" onClick={() => onDelete(row.id)}>
                                    <DeleteIcon
                                        style={stylesForDeleteIcon}
                                    />
                                    Delete
                                </DeleteButton>
                                <EditButton type="button" onClick={() => onSetEditRow(row)}>
                                    <EditIcon
                                        style={stylesForEditIcon}
                                    />
                                    Edit
                                </EditButton>
                                <Popup
                                    trigger={
                                        <DuplicateButton type="button" onClick={() => duplicateHandler(row)}>
                                            <i className={`copy outline icon`}></i>
                                            <span></span>
                                        </DuplicateButton>
                                    }
                                    content={'Duplicate Grouping'}
                                    position="top center"
                                    hoverable
                                />
                            </TableTdBody>
                            </div>
                        </TableBodyRow>
                    ))
                }
            </TableWrapper>
            <AddNewRowButton type="button" onClick={showAddModal}>
                <AddIcon style={stylesForAddIcon} />
                Add Grouping
            </AddNewRowButton>
        </VidsvTableWrapper>
    );
};