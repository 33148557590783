import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Redirect, Route, useLocation } from "react-router-dom";

export const LoginRoute = (props) => {
    const { component: Component, ...rest } = props;
    const { context } = useContext(AuthContext);
    const location = useLocation();

    return (
        <Route
            {...rest}
            render={(props) =>
                !context.user || context.user.challengeName || location.pathname !== "/login" ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/vsource/vendors",
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};
