import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import { get } from "lodash";

const getDocumentIdFromKey = async (docKey, activeEntity) => {
    const {
        data: { listDocuments },
    } = await API.graphql(
        graphqlOperation(queries.listDocuments, {
            filter: {
                vendExFileName: { eq: docKey },
                migrate: { eq: true },
                groupsCanAccess: { eq: activeEntity?.groupName },
            },
            limit: 1000,
        })
    );

    return get(listDocuments, "items[0].id", null);
};

export default getDocumentIdFromKey;
