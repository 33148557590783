import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import _isEqual from "lodash/isEqual";
import { getStorage } from "../utils/localStorage";

import { AuthContext, AUTH_STATE } from "../context/AuthContext";
import ErrorBoundary from "../components/ErrorBoundary";
import { TopNavigation, SideNavigation } from "../components/Navigation";

import { ACTIVE_VLINK_VENDOR } from "../Constants";
import { getAnalystData } from "../graphql/customQueries";
import Routes from "../routes/Routes";

const AppWrapper = styled.div`
    position: relative;
    display: grid;
    overflow: auto;
    height: 100vh;
    grid-template-columns: auto 1fr;
    grid-template-rows: 5rem 1fr;
    grid-template-areas:
        "topnav topnav"
        "sidenav main";
`;

const TopNavigationWrapper = styled(TopNavigation)`
    grid-area: topnav;
`;

const SideNavigationWrapper = styled(SideNavigation)`
    grid-area: sidenav;
`;

const MainWrapper = styled.div`
    grid-area: main;
    overflow: auto;
`;

export const Layout = () => {
    const {
        context,
        isVLinkAnalyst,
        isVLinkVendor,
        isVLinkSuperUser,
        dispatch,
        getUserVendorsList
    } = useContext(AuthContext);

    const { loading, error, data } = useQuery(getAnalystData, {
        skip: !isVLinkSuperUser(context.user) && !isVLinkAnalyst(context.user),
    });
    useEffect(() => {
        const isVendorUserOnly = isVLinkVendor(context.user) && !isVLinkAnalyst(context.user) && !isVLinkSuperUser(context.user);
        
        async function loadVendorsList() {
            try {
                const VLinkVendors = await getUserVendorsList(context.user);
                if (Array.isArray(VLinkVendors)) {
                    dispatch({
                        type: "SET_USER_VENDORS",
                        availableVendors: VLinkVendors,
                    });
                    
                    // If the active vendor is already in the storage use this one for the analyst and super user
                    const vendor = getStorage(ACTIVE_VLINK_VENDOR);
                    if(isVendorUserOnly){
                        dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: VLinkVendors[0] });
                    } else {
                        dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: vendor ?? VLinkVendors[0] });
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }

        // If the user has VLink vendor permissions then load the vendor ID-s from Cognito
        if (isVendorUserOnly) {
            loadVendorsList();
        }
    }, []);

    useEffect(() => {
        if (!loading && (isVLinkAnalyst(context.user) || isVLinkSuperUser(context.user))) {
            const res = data?.vlink?.analystData;
            if (res?.assignedVendors && !_isEqual(context.availableVendors, res?.assignedVendors)) {
                dispatch({
                    type: "SET_USER_VENDORS",
                    availableVendors: res.assignedVendors,
                });

                // If the active vendor is already in the storage use this one
                const vendor = getStorage(ACTIVE_VLINK_VENDOR);
                dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: vendor ?? res.assignedVendors[0] });
            }
        }
    }, [loading, data]);

    if (error) {
        console.error("Error receiving analyst vendors list (VLink): ", error);
    }

    if (context.authState !== AUTH_STATE.SIGNED_IN) {
        return <Redirect to="/login" />;
    }

    return (
        <AppWrapper>
            <TopNavigationWrapper />
            <SideNavigationWrapper />
            <ErrorBoundary>
                <MainWrapper>
                    <Routes />
                </MainWrapper>
            </ErrorBoundary>
        </AppWrapper>
    );
};
