import React, {
    useCallback,
    useState,
    useEffect,
    useContext,
    useMemo,
    useReducer,
} from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';

import API, { graphqlOperation } from "@aws-amplify/api";
import Select from "react-select";
import styled from "styled-components";
import * as yup from "yup";
import queryString from "query-string";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy";
import _cloneDeep from "lodash/cloneDeep";

import { PageWrapper } from "../../../components/PageWrapper";
import { PageHeader } from "../../../components/PageHeader";
import CompanyHeader from "../../../components/CompanyHeader";
import { DetailedDescription } from "../../../components/DetailedDescription";
import { CompanyInfoSection } from "../../../components/CompanyInfoSection";
import { getPath, VidsvTable } from "../../../components/VidsvTable";
import Loader from "../../../components/Loader";
import { ButtonWrapper } from "../../../components/VendBtn";
import { FormWrapper as _FormWrapper, InputRow, InputTitle, Input, TextArea } from "../AddVendorPage";
import { CompanyHeaderRow, CompanyLogo, AnalystLabel, AnalystName } from "../VendorProfilePage";
import VidSV from "../../../components/VID-SV";
import { LargeConfirmationModal } from "../../../components/ModalConfirmation";
import OldHierarchicalSelectBox from "../../../components/hierarchical-selectbox/OldHierarchicalSelectBox";
import { ProductChangeTables } from "./ProductChangeTables";

import { VsourceContext } from "../../../context/vsource/vsourceContext";
import { useAlerts } from "../../../hooks/useAlerts";

import { addVendorProduct } from "../../../graphql/mutations";
import { updateVSourceVendorProduct } from "../../../graphql/customMutations";
import { updateProductTags } from "../../../graphql/customMutations";
import { getProductTags, getProductVidsv } from "../../../graphql/customQueries";

import "react-datepicker/dist/react-datepicker.css";
import _listToTree from "../../../utils/listToTree";
import { vidsvLabels, vidsvMultiTypes, vidsvTypes, vidsvMultiKeys } from "../../../Constants";
import getSortedClassifiersByType from "../../../utils/getSortedClassifiersByType";
import sortClassifiers from "../../../utils/sortClassifiers";
import sortClassifierValues from "../../../utils/sortClassifierValues";
import HierarchicalSelectBox from "../../../components/hierarchical-selectbox/HierarchicalSelectBox";

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({ theme }) => theme.textGray};
    overflow: auto;
    margin-bottom: 5rem;
    
    }
`;

const FormWrapper = styled(_FormWrapper)`
    width: 100%;
`;

const InputWrapper = styled.div`
    margin-right: 45%;
`;

const SelectBoxWrapper = styled.div`
  & > div:nth-child(1){
    margin-bottom: 50px;
  }
  & > div:nth-child(2){
    margin-bottom: 50px;
  }
`;

let schema = yup.object().shape({
    name: yup.string().required().label("Name"),
    detailed_description: yup.string().label("Detailed Description"),
    url: yup.string().label("URL"),
    demo_url: yup.string().nullable().label("Demo URL"),
    brief_description: yup.string().label("Brief Description"),
    status_code: yup.string().label("Product Active/Historical"),
    /* type_code: yup.string().nullable().label("Change Type"),
    change_time: yup.string().nullable().label("Change Date"),
    change_notes: yup.string().nullable().label("Change Notes"), */
});

const vidsvReducer = (state, { type, classifierType, data = [] }) => {
    if (type === "UPDATE") {
        return {
            ...state,
            [classifierType]: {
                ...data
            }
        }
    } else if (type === "INIT") {
       return {
           ...data
       }
    } else {
        return state;
    }
}
const selectedVidsvReducer = (state, { type, classifierType, data = []}) => {
    if (type === "UPDATE") {
        return {
            ...state,
            [classifierType]: [
                ...data
            ]
        }
    } else if (type === "INIT") {
        return {
            ...data
        }
    } else {
        return state;
    }
}

const AddNewProductPage = ({ history }) => {
    const [loading, setLoading] = useState(true);
    const [changeTypes, setChangeTypes] = useState([]);
    const [changeTypesLoaded, setChangeTypesLoaded] = useState(false);
    const [vendorId, setVendorId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        detailed_description: "",
        url: "",
        demo_url: "",
        brief_description: "",
        status_code: "ACTIVE_PRODUCT",
    });
    const [productChangeData, setProductChangeData] = useState([]);
    const [vidsv, vidsvDispatch] = useReducer(vidsvReducer, null);
    const [selectedVidsv, selectedVidsvDispatch] = useReducer(selectedVidsvReducer, null);
    const [classifiersData, setClassifiersData] = useState([]);

    const isEditing = window.location.pathname.includes("product/edit/");

    const url = useLocation();
    const match = useRouteMatch();
    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const [onUpdateProductTags] = useMutation(updateProductTags);
    const { data: getProductTagsData, refetch: refetchSelectedTags } =
        useQuery(getProductTags, {
            variables: {
                productTagsId: match.params.id,
                types: [...vidsvTypes]
            },
            notifyOnNetworkStatusChange: true,
        });
    const { data: getProductVidsvData, refetch: refetchSelectedVidsv } =
        useQuery(getProductVidsv, {
            variables: {
                productVidsvId: match.params.id
            },
            notifyOnNetworkStatusChange: true,
        });

    const [updateVSourceVendorProductMutation] = useMutation(updateVSourceVendorProduct);

    const initTagData = useMemo(() => {
        const types = [...vidsvTypes];
        const selectedProductTags = _groupBy(_get(getProductTagsData, 'vsource.productTags', []), (item) => item.type);
        return types.map((item, index) => {
            return _get(selectedProductTags, `[${types[index]}][0].tags`, []);
        })
    }, [getProductTagsData]);


    const initVidsvMultiData = useMemo(() => {
        let vidsvInitialData = _get(getProductVidsvData, 'vsource.productVidsv', []);

        // sort each column of each row for vidsv table, using sortClassifierValues
        if(classifiersData.length) {
            return vidsvInitialData.map(data => {
                let newData = {...data};
                vidsvMultiKeys.forEach((key, i) => {
                    // let sortedValues = sortClassifierValues(data[key].map(d => d.id), classifiersData[i + 3], 'code')
                    // let sortedData = sortedValues.map(value => ({
                    //     id: value,
                    //     label: data[key].find(d => d.id === value).label
                    // }))

                    let sortedData = data[key]
                        .map(d => ({...d, path: getPath(d.id, classifiersData.slice(vidsvTypes.length), i)}))
                        .sort((a,b) => a.path > b.path ? 1 : -1);

                    newData[key] = sortedData;
                });

                return newData;
            });
        }

        return vidsvInitialData;
    }, [getProductVidsvData, classifiersData]);


    const vidsvTreeData = useMemo(() => {
        const types = [...vidsvTypes, ...vidsvMultiTypes];
        return types.map((item, index) => {
            return _get(vidsv, `[${types[index]}]`);
        })
    }, [vidsv, vidsvTypes, vidsvMultiTypes]);

    const {
        context: { vendors },
        fetchVendor,
    } = useContext(VsourceContext);

    const setInputValue = useCallback(
        (name, value) => {
            const data = { ...formData };
            data[name] = value;
            setFormData(data);
        },
        [formData]
    );

    const onSelectVidsv = useCallback((arr, index) => {
        selectedVidsvDispatch({
            type: 'UPDATE',
            classifierType: vidsvTypes[index],
            data: arr.map(data => data.id),
        })
    }, [vidsvTypes]);

    const saveSelectedTags = useCallback(async (productId) => {
        const { data } = await onUpdateProductTags({
            variables: {
                productId: Number(productId || match.params.id),
                values: vidsvTypes.map((item, index) => (
                    {
                        classifier_type: vidsvTypes[index],
                        tags: _get(selectedVidsv, `[${vidsvTypes[index]}]`, [])
                    }
                ))
            }
        })
        return data;
    }, [match.params.id, vidsv, selectedVidsv])

    const fetchProductChangeTypes = useCallback(async () => {
        try {
            const { data } = await getSortedClassifiersByType('PRODUCT_CHANGE')
            if (data && data.listClassifiersByType) {
                const list = data.listClassifiersByType.map((el) => {
                    return {
                        label: el.value,
                        value: el.code,
                    };
                });
                setChangeTypes(list);
                // If adding new product set default change type code value
                if (!isEditing) {
                    setInputValue("type_code", "");
                }
            }
        } catch (e) {
            console.log("Classifier fetch error: ", e);
            newErrorAlert("Error fetching product change type data");
        } finally {
            if (!isEditing) setLoading(false);
            setChangeTypesLoaded(true);
        }
    }, [setInputValue, newErrorAlert]);
    const fetchVidsvData = async () => {
        const types = [...vidsvTypes, ...vidsvMultiTypes];
        const actions = types.map((item) => {
            return getSortedClassifiersByType(item).then(({ data }) => _get(data, 'listClassifiersByType')).catch((e) => {
                throw new Error('Error in fetchVidsvData', e.errors[0].message)
            });
        })
        const res = await Promise.all(actions);
        if (match.params.id) {
            await Promise.all([
                refetchSelectedTags,
                refetchSelectedVidsv
            ])
        }
        if (res && res.length) {
            setClassifiersData(res);
            const data = {};
            res.forEach((array, index) => {
                if (array && array.length) {
                    data[types[index]] = _listToTree(array);
                } else {
                    data[types[index]] = [];
                }
            });

            vidsvDispatch({
                type: 'INIT',
                data,
            });
        }
    }
    useEffect(() => {
        const fetchProductData = async () => {
            const product = /* GraphQL */ `
                query GetProduct($id: ID!) {
                    product(id: $id) {
                        id
                        name
                        brief_description
                        detailed_description
                        product_id_code
                        url
                        demo_url
                        status_code_original
                        product_change {
                            id
                            change_time
                            change_notes
                            change_type_original
                        }
                    }
                }
            `;
            try {
                setLoading(true);
                const { data } = await API.graphql(graphqlOperation(product, { id: match.params.id }));
                if (data && data.product) {
                    setFormData({
                        name: data.product.name,
                        detailed_description: data.product.detailed_description || "",
                        url: data.product.url || "",
                        demo_url: data.product.demo_url,
                        brief_description: data.product.brief_description || "",
                        status_code: data.product.status_code_original || "ACTIVE_PRODUCT",
                    });
                    setProductChangeData(groupProductChangesByID(data.product.product_change));
                }
            } catch (e) {
                console.log("Product fetch error: ", e);
            } finally {
                setLoading(false);
            }
        };

        const queryParams = queryString.parse(url.search);
        if (queryParams.vendor) {
            setVendorId(queryParams.vendor);
            fetchProductChangeTypes();
            if (Object.keys(vendors).length === 0) fetchVendor(queryParams.vendor);
            // init template tree for VID-sv selectBoxes
            fetchVidsvData();
            // Check if it's editing or new product entry page
            if (isEditing) {
                // If editing load initial data from database
                fetchProductData();
            }
        } else {
            newErrorAlert("Invalid URL");
            history.push(`/vsource/vendors`);
        }
    }, []);

    const productStatusOptions = [
        { label: "Active", value: "ACTIVE_PRODUCT" },
        { label: "Historical", value: "HISTORICAL_PRODUCT" },
        { label: "Draft (hidden)", value: "PRODUCT_DRAFT" }
    ];

    const addNewProductChange = (e) => {
        e.preventDefault();
        let list = _cloneDeep(productChangeData);
        // Add new entry with random ID
        const id = new Date().getTime();
        list[id] = {
            id,
            action_type: "INSERT",
            change_type_original: "NONE"
        }

        setProductChangeData(list);
    }

    const removeProductChangeRow = (id) => {
        let list = _cloneDeep(productChangeData);
        list[id].action_type = "DELETE";
        setProductChangeData(list);
    }

    const setProductChangeFieldValue = (fieldName, value, id) => {
        let list = _cloneDeep(productChangeData);
        list[id][fieldName] = value;
        if(!list[id]["action_type"]){
            list[id]["action_type"] = "UPDATE";
        }
        
        setProductChangeData(list);
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);

            // Function to convert the product change list to the correct format for the mutation
            const convertProductChangeData = () => {
                let list = _cloneDeep(productChangeData);
                return Object.keys(list).map(key => {
                    const productChange = list[key];
                    productChange.type_code = productChange.change_type_original;
                    productChange.change_time = productChange.change_time?.getTime() / 1000 || null; // to timestamp
                    delete productChange.change_type_original;
                    return productChange;
                })
            }

            await schema.validate(formData).catch((err) => {
                // Yup validation errors
                console.log("Form validation error:", err);
                throw new Error(err.errors);
            });
            // New product form
            if (!isEditing) {
                try {
                    const { data } = await API.graphql(
                        graphqlOperation(addVendorProduct, {
                            name: formData.name,
                            detailed_description: formData.detailed_description,
                            url: formData.url,
                            demo_url: formData.demo_url,
                            brief_description: formData.brief_description,
                            status_code: formData.status_code,
                            type_code: formData.type_code !== "" ? formData.type_code : "NONE",
                            change_time: formData.change_time,
                            change_notes: formData.change_notes,
                            vendor_id: vendorId,
                        })
                    );
                    const productId = _get(data, 'addVendorProduct.product_id');
                    if (data && data.addVendorProduct && data.addVendorProduct.status && productId) {
                        await saveSelectedTags(productId);
                        newSuccessAlert(`New product added successfully`);
                        history.push(`/vsource/vendors/${vendorId}`);
                    } else newErrorAlert("Failed to add a new product");
                } catch (e) {
                    throw new Error(e.errors[0].message);
                }
            }
            // Update the product
            else {
                try {
                    const { data } = await updateVSourceVendorProductMutation({
                        variables: {
                            productId: match.params.id,
                            input: {
                                name: formData.name,
                                detailed_description: formData.detailed_description,
                                url: formData.url,
                                demo_url: formData.demo_url,
                                brief_description: formData.brief_description,
                                status_code: formData.status_code || "ACTIVE_PRODUCT",
                                product_change: convertProductChangeData()
                            }
                        },
                    });

                    if (data && data.vsource && data.vsource.updateVendorProduct) {
                        await saveSelectedTags();
                        newSuccessAlert(`Product updated successfully`);
                        history.push(`/vsource/product/${match.params.id}`);
                    } else newErrorAlert("Failed to edit the product");
                } catch (e) {
                    throw new Error(e.errors[0].message);
                }
            }
        } catch (e) {
            console.log("Form errors: ", e);
            newErrorAlert(e.message);
        } finally {
            setLoading(false);
        }
    };

    const productStatusDefault = () => {
        if (isEditing) {
            return productStatusOptions.filter((el) => el.value === formData["status_code"])[0];
        } else return productStatusOptions[0];
    };

    const changeTypeDefault = () => {
        if (isEditing) {
            return changeTypes.filter((el) => el.value === formData["type_code"])[0];
        } else return formData.type_code;
    };
    return (
        <PageWrapper>
            <LargeConfirmationModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={() => {
                    setShowModal(false);
                    history.push(`/vsource/product/${match.params.id}?vendor=${vendorId}`);
                }}
                onDiscard={() => setShowModal(false)}
                title={"Quit Editing?"}
                description={"Changes you made so far will not be published and cannot be recovered"}
            />
            <PageHeader title={`Product Profile: ${formData.name}`}>
                <ButtonWrapper space-between form={"newProductForm"} type={"submit"}>
                    {isEditing ? "Save" : "Publish"}
                </ButtonWrapper>
                <ButtonWrapper onClick={() => setShowModal(true)}>Exit</ButtonWrapper>
            </PageHeader>
            {(loading || !changeTypesLoaded) && <Loader />}
            {!loading && changeTypesLoaded && (
                <>
                    {vendors && vendors[vendorId] && (
                        <CompanyHeaderRow>
                            <CompanyHeader title={vendors[vendorId]?.full_legal_name || ""} />
                            <CompanyLogo>
                                {vendors[vendorId].logo_url && (
                                    <img
                                        src={vendors[vendorId].logo_url}
                                        alt={`${vendors[vendorId].full_legal_name} logo`}
                                    />
                                )}
                            </CompanyLogo>
                            <div>
                                <VidSV
                                    value={`${vendors[vendorId]?.vendor_id_code}-${vendors[vendorId]?.hierarchy_code}-${formData["product_id_code"]}`}
                                />
                                <div style={{ marginTop: "5px" }}>
                                    <AnalystLabel>VendEx Analyst Assigned </AnalystLabel>
                                    <AnalystName>{vendors[vendorId]?.assigned_analyst || ""}</AnalystName>
                                </div>
                            </div>
                        </CompanyHeaderRow>
                    )}
                    <CompanyInfoSection>
                        <DetailedDescription>{vendors[vendorId]?.brief_description || ""}</DetailedDescription>
                    </CompanyInfoSection>
                    <ContentWrapper>
                        <FormWrapper id={"newProductForm"} onSubmit={onFormSubmit}>
                            <InputWrapper>
                                <InputRow>
                                    <InputTitle>Product name</InputTitle>
                                    <Input
                                        type="text"
                                        name=""
                                        id=""
                                        value={formData["name"]}
                                        onChange={(e) => setInputValue("name", e.target.value)}
                                        required
                                    />
                                </InputRow>
                            </InputWrapper>
                            <InputWrapper>
                                <InputRow>
                                    <InputTitle>Product Active/Historical</InputTitle>
                                    <Select
                                        options={productStatusOptions}
                                        onChange={(e) => setInputValue("status_code", e.value)}
                                        defaultValue={productStatusDefault()}
                                        className={"input-dropdown"}
                                    />
                                </InputRow>
                            </InputWrapper>
                            <InputRow>
                                <InputTitle>Brief Product Description</InputTitle>
                                <TextArea
                                    rows={10}
                                    value={formData["brief_description"]}
                                    onChange={(e) => setInputValue("brief_description", e.target.value)}
                                />
                            </InputRow>
                            <InputRow>
                                <InputTitle>Detailed Product Description</InputTitle>
                                <TextArea
                                    rows={23}
                                    value={formData["detailed_description"]}
                                    onChange={(e) => setInputValue("detailed_description", e.target.value)}
                                />
                            </InputRow>
                            <InputWrapper>
                                <InputRow>
                                    <InputTitle>URL</InputTitle>
                                    <Input
                                        type="text"
                                        name=""
                                        id=""
                                        value={formData["url"]}
                                        onChange={(e) => setInputValue("url", e.target.value)}
                                    />
                                </InputRow>
                                <InputRow>
                                    <InputTitle>Demo URL</InputTitle>
                                    <Input
                                        type="text"
                                        name=""
                                        id=""
                                        value={formData["demo_url"]}
                                        onChange={(e) => setInputValue("demo_url", e.target.value)}
                                    />
                                </InputRow>
                                </InputWrapper>
                            {isEditing && changeTypesLoaded && 
                                <React.Fragment>
                                    <ProductChangeTables 
                                        data={productChangesToArray(productChangeData)} 
                                        removeProductChangeRow={removeProductChangeRow}
                                        changeTypes={changeTypes}
                                        setFieldValue={setProductChangeFieldValue}
                                    />
                                    <button onClick={addNewProductChange}>Add new Product Status Update</button>
                                </React.Fragment>
                                }
                            <SelectBoxWrapper>
                                {
                                    vidsvTypes.map((vidsvItem, index) => (
                                        _get(vidsvTreeData, `${index}`, []).length > 0 &&  (
                                            <InputRow key={vidsvItem}>
                                                <InputTitle>{vidsvLabels[index]}</InputTitle>
                                                {vidsvItem === 'DELIVERY_METHOD' ? (
                                                    <HierarchicalSelectBox
                                                        flatTemplate={classifiersData[index]}
                                                        template={vidsvTreeData[index]}
                                                        defaultSelectedValues={initTagData[index]}
                                                        isMultiselect
                                                        lastLevelSelectable={false}
                                                        showValueWithPath
                                                        onSelect={selectedItems => onSelectVidsv(selectedItems, index)}
                                                        nonSelectableParents
                                                        showSelectedItemsBar={false}
                                                    />
                                                ) : (
                                                    <OldHierarchicalSelectBox
                                                       selectIndex={index}
                                                       template={vidsvTreeData[index]}
                                                       defaultSelectedValues={initTagData[index]}
                                                       isMultiselect
                                                       lastLevelSelectable={true}
                                                       showValueWithPath
                                                       onSelect={onSelectVidsv}
                                                   />
                                                )}
                                            </InputRow>
                                        )
                                    ))
                                }
                            </SelectBoxWrapper>
                            {
                                !!isEditing && (
                                    <InputRow>
                                        <InputTitle>Product Attribute Groupings</InputTitle>
                                        <VidsvTable
                                            productId={match.params.id}
                                            templates={vidsvTreeData}
                                            vidsvClassifiers={classifiersData.slice(vidsvTypes.length)}
                                            defaultSelectedValues={initVidsvMultiData}
                                            refreshTableData={refetchSelectedVidsv}
                                        />
                                    </InputRow>
                                )
                            }
                        </FormWrapper>
                    </ContentWrapper>
                </>
            )}
        </PageWrapper>
    );
};

const groupProductChangesByID = (data) => {
    const res = {};
    data.forEach(productChange => {
        res[productChange.id] = productChange;
        res[productChange.id].change_time = productChange.change_time ? new Date(productChange.change_time) : null;
    })
    return res;
}

const productChangesToArray = (data) => {
    return Object.keys(data).map(key => data[key])
}

export default AddNewProductPage;
