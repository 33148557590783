import React from "react";
import { format } from "date-fns";
import _get from "lodash/get";
import TimeFrame from "../TimeFrame/TimeFrame";
import {
    moneyConvert,
    percentConvert,
} from "../../../../utils/numberConverts";

const baseTdStyles = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontWeight: "bold"
};

export const Cell = row => (
    <div
        style={baseTdStyles}
    >
        {
            row?.value
        }
    </div>
);

export const CellTitle = row => {
    const title = _get(row, 'original.title');
    return (
        <div
            style={baseTdStyles}
        >
            {
                title
            }
        </div>
    )
}

export const CellWithLink = row => (
    <div
        style={baseTdStyles}
    >
        {
            !!row?.value.link ?
                <a
                    href={row?.value.link}
                >
                    {row.value.title}
                </a>
                : row?.value.title
        }
    </div>
);

export const CellWithDate = row => (
    <div
        style={baseTdStyles}
    >
        {format(row.value, "DD-MMM-YYYY")}
    </div>
);

export const TimeFrameCell = row => (
    <TimeFrame date={row.value} />
);

export const PercentCell = row => (
    <div
        style={baseTdStyles}
    >
        {
            percentConvert(row?.value)
        }%
    </div>
);

export const MoneyCell = row => (
    <div
        style={baseTdStyles}
    >
        {
            moneyConvert(row?.value)
        }
    </div>
);
