import styled from "styled-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import _get from "lodash/get";
import ReactTooltip from "react-tooltip";
import * as PropTypes from "prop-types";

import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";
import { createUserStat } from "../graphql/mutations";
import { withRouter } from "react-router-dom";
import cogoToast from "cogo-toast";
import { formatToTimeZone } from "date-fns-timezone";
import { generateAgreementName } from "../context/agreement/agreementVSourceData";
import { AuthContext } from "../context/AuthContext";
import { USER_STATS_TYPES } from "../Constants";
import { LargeConfirmationModal } from "./ModalConfirmation";
import Tooltip from "./Tooltip";
import { deleteDocument } from "../graphql/customMutations";
import { useMutation } from "@apollo/client";

const GradientsShield = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    min-height: 217px;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid #bdbdbd;
    box-sizing: border-box;
    border-radius: 4px;
`;
const DocItemWrapper = styled.a`
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    width: 247px;
    padding: 16px 12px;
    &:hover > #DocTray {
        opacity: 1;
    }
`;
const DocTray = styled.div`
    display: flex;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex: 0 0 60px;
    padding: 8px;
    transition: all 0.2s ease;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) -44.98%, #ffffff 60.08%);
`;

const DeleteFileBtn = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    color: #f64e60;
    border: none;
    font-size: 12px;
`;
const DocInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 2;
    width: 100%;
    padding-bottom: 30px;
    margin-bottom: 20px;
    &::after {
        position: absolute;
        content: "";
        width: 80%;
        border: 1px solid #e8e8e8;
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
    }
`;
const FileName = styled.div`
    position: relative;
    display: block;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: #212121;
    white-space: pre-wrap;
`;

const AgreementLink = styled.div`
    position: absolute;
    height: 20px;
    width: 20px;
    top: 8px;
    right: 8px;
    svg {
        width: 100%;
        height: 100%;
    }
`;

const TooltipList = styled.ul``;
const TooltipListItem = styled.li`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    &:first-child {
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    }
    &:last-child {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }
    p {
        text-align: left;
        padding-left: 2rem;
        width: 50%;
    }
`;

const DocItem = withRouter(
    ({
        id,
        agreements,
        description,
        filename,
        match,
        history,
        allowDeletingDoc,
        vendorsData,
        tooltipProps,
        ...props
    }) => {
        const { context, dispatch, fetchDocs } = useContext(AuthContext);
        const [imageUrl, setImageUrl] = useState(null);
        const [confirmationModalRowIndex, setConfirmationModalRowIndex] = useState(null);
        const [deleteDocumentMutation] = useMutation(deleteDocument);

        const fileNameFix = decodeURIComponent(filename?.replace(/%(?!\d|[ABCDEF]+)/g, "%25"));

        useEffect(() => {
            Storage.get(`vkey/contracts/${context.activeEntity?.groupName}/${filename}.jpg`, { level: "public" }).then(
                (s3ImageUrl) => {
                    setImageUrl(s3ImageUrl);
                }
            );
        }, [id, context.loadingDocs, filename]);

        const refreshDocs = useCallback(() => {
            fetchDocs({ entityGroup: context.activeEntity?.groupName }).then(({ docList, docItems }) => {
                dispatch({ type: "FETCH_DOCS", docList, docItems });
            });
        }, []);

        const removeDocument = useCallback(() => {
            setConfirmationModalRowIndex(null);
            if (agreements.length > 0) {
                const single = `Warning, this file is attached to an Agreement, please disassociate this file from the Agreement before you delete it.`;
                const multiple = `Warning, this file is attached to ${agreements.length} Agreements, please disassociate this file from the Agreements before you delete it.`;

                const headerText = agreements.length > 1 ? multiple : single;

                const { hide } = cogoToast.error(
                    <>
                        <h5>{headerText}</h5>
                        {agreements.map((agreement, index) => {
                            return (
                                <div key={index}>
                                    {generateAgreementName(
                                        agreement,
                                        vendorsData[agreement.vendorVSourceId]?.full_legal_name,
                                        context.activeEntity
                                    )}
                                </div>
                            );
                        })}
                    </>,

                    {
                        hideAfter: 0,
                        onClick: () => {
                            hide();
                        },
                    }
                );

                return;
            }

            // Log the file remove information to the DB
            API.graphql(
                graphqlOperation(createUserStat, {
                    input: {
                        username: _get(context.user, "attributes.email"),
                        userId: _get(context.user, "username"),
                        type: USER_STATS_TYPES.SECURE_VAULT_REMOVE_FILE,
                        value: JSON.stringify({
                            name: filename,
                        }),
                    },
                })
            );

            deleteDocumentMutation({ variables: { documentUuid: id } })
                .then((response) => {
                    refreshDocs();
                })
                .catch((err) => console.error(err));
            API.graphql(
                graphqlOperation(deleteDocument, {
                    input: { id },
                })
            )
                .then(() => {
                    refreshDocs();
                })
                .catch((err) => console.log(err));
        }, [id, agreements]);

        const handleDocOpen = useCallback(() => {
            const uri = `${match.url.endsWith("/") ? match.url : match.url + "/"}pdf-viewer/${filename}`;
            history.push(encodeURI(uri));
        }, [filename, match]);

        return (
            <DocItemWrapper {...props} data-tip data-for={`document_${id}`}>
                <DocInfo>
                    <FileName title={fileNameFix}>{fileNameFix}</FileName>
                </DocInfo>
                <GradientsShield onClick={handleDocOpen}>
                    <img
                        alt={"Preparing preview ..."}
                        src={imageUrl}
                        style={{
                            textAlign: "center",
                            lineHeight: "14em",
                        }}
                        height={"90%"}
                        width={"90%"}
                    />
                </GradientsShield>

                <DocTray id={"DocTray"}>
                    {allowDeletingDoc && (
                        <DeleteFileBtn
                            type="button"
                            className="deleteFileBtn"
                            onClick={() => setConfirmationModalRowIndex(id)}
                        >
                            Delete
                        </DeleteFileBtn>
                    )}
                </DocTray>
                <LargeConfirmationModal
                    show={id === confirmationModalRowIndex}
                    onClose={() => setConfirmationModalRowIndex(null)}
                    onSubmit={removeDocument}
                    onDiscard={() => setConfirmationModalRowIndex(null)}
                    title="Delete Document?"
                    description="Once deleted, you will not be able to recover the document"
                />
                {/*{agreements.length > 0 ? (*/}
                {/*    <AgreementLink>*/}
                {/*        <LinkImage />*/}
                {/*    </AgreementLink>*/}
                {/*) : null}*/}
                <Tooltip id={`document_${id}`}>
                    <TooltipList>
                        <TooltipListItem>
                            <b>Vendor</b>
                            <p>{tooltipProps.docVendorName}</p>
                        </TooltipListItem>
                        <TooltipListItem>
                            <b>Product(s)</b>
                            <p>{tooltipProps.docProductNames}</p>
                        </TooltipListItem>
                        <TooltipListItem>
                            <b>End Date</b>
                            <p>
                                {tooltipProps?.docEndDate
                                    ? formatToTimeZone(new Date(tooltipProps.docEndDate), "DD-MMM-YYYY", {
                                          timeZone: "GMT",
                                      })
                                    : ""}
                            </p>
                        </TooltipListItem>
                        <TooltipListItem>
                            <b>Agreement Name</b>
                            <p>{tooltipProps.docAgreementName}</p>
                        </TooltipListItem>
                        <TooltipListItem>
                            <b>Number of Pages</b>
                            <p>{tooltipProps.docNumberOfPages}</p>
                        </TooltipListItem>
                        <TooltipListItem>
                            <b>File Name</b>
                            <p style={{ whiteSpace: "pre-wrap" }}>{fileNameFix}</p>
                        </TooltipListItem>
                    </TooltipList>
                </Tooltip>
            </DocItemWrapper>
        );
    }
);

DocItem.propTypes = {
    description: PropTypes.any,
    filename: PropTypes.any,
};

export default React.memo(DocItem);
