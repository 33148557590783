import styled from "styled-components";

export const FooterLinks = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 48px;
    width: 100%;
    
    & > * {
      margin-right: 8px;
    }
`;