import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import ReviewStars from '../ReviewStars';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { AuthContext } from "../../context/AuthContext";

const Wrapper = styled.div`
    font-size: 16px;
`;

const Header = styled.div`
    h2 {
        margin-bottom: 5px;
    }

    .icon {
        font-size: 20px;
    }
`;

const Details = styled.div`
    margin-top: 20px;
    h4 {
        margin-bottom: 0;
        font-weight: 600;
    };

    .icon {
        font-size: 15px;
    }
`;

const SubReviews = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 5px;

    .sub-review {
        width: 130px;
        span {
            display: block;
        }
    }
`;

const InputsWrapper = styled.form`
    margin-top: 25px;
`;

const InputRow = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
`;

const InputTitle = styled.span`
    margin-bottom: 10px;
    font-weight: bold;

    .required-field::after {
        content: "*";
        color: red;
        margin-left: 2px;
    }
`;

const Input = styled.input`
    width: 200px;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    height: 24px;
    padding: 16px;
    border-radius: 3px;
    color: inherit;
`;

const TextArea = styled.textarea`
    width: 100%;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    padding: 16px;
    border-radius: 3px;
    color: inherit;
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
`;

const CreateReview = ({ subReviews, selectedReview = null, onSubmit }) => {
    const { context } = useContext(AuthContext);

    const [reviewData, setReviewData] = useState({
        id: '',
        user_name: context.user.attributes.email,
        headline_text: '',
        comment_text: '',
        rating: 0,
        updated_at: Date(),
        avatar: null,
        sub_reviews: subReviews.map(review => ({ ...review, value: 0 }))
    });

    useEffect(() => {
        if (selectedReview) {
            setReviewData({
                ...selectedReview,
                sub_reviews: [...selectedReview.sub_reviews]
            });
        }
    }, [selectedReview]);

    const dataChangeHandler = (key, value) => {
        setReviewData({
            ...reviewData,
            [key]: value
        });
    };

    const subReviewChangeHandler = (id, value) => {
        const nextReviewData = { ...reviewData };
        const nextSubReviews = [...reviewData.sub_reviews];
        const subReviewIndex = nextSubReviews.findIndex(rev => rev.id === id);
        const nextSubReview = {
            ...nextSubReviews[subReviewIndex],
            value: value
        };
        nextSubReviews[subReviewIndex] = nextSubReview;
        nextReviewData.sub_reviews = nextSubReviews;

        setReviewData(nextReviewData);
    };

    return (
        <Wrapper>
            <Header>
                <h2>Vendor rating</h2>
                <ReviewStars editable value={0} onChange={value => dataChangeHandler('rating', value)} />
            </Header>
            <Details>
                <h4>Rate vendor qualities</h4>
                <SubReviews>
                    {reviewData.sub_reviews.map(rev => (
                        <div className="sub-review" key={rev.id}>
                            <span>{rev.name}</span>
                            <ReviewStars
                                editable
                                color='grey'
                                value={rev.value}
                                onChange={value => subReviewChangeHandler(rev.id, value)}
                            />
                        </div>
                    ))}
                </SubReviews>
            </Details>
            <InputsWrapper>
                <InputRow>
                    <InputTitle>
                        Add a headline
                        {/* <span className={"required-field"}></span> */}
                    </InputTitle>
                    <Input
                        type="text"
                        name=""
                        id=""
                        value={reviewData.headline_text}
                        onChange={e => dataChangeHandler('headline_text', e.target.value)}
                        required
                    />
                </InputRow>
                <InputRow>
                    <InputTitle>Write your note</InputTitle>
                    <TextArea
                        rows={5}
                        value={reviewData.comment_text}
                        onChange={e => dataChangeHandler('comment_text', e.target.value)}
                    />
                </InputRow>
            </InputsWrapper>
            <Actions>
                <Button color="yellow" onClick={() => onSubmit(reviewData)}>
                    Submit A note
                </Button>
            </Actions>
        </Wrapper>
    )
};

CreateReview.propTypes = {
    subReviews: PropTypes.array.isRequired,
    selectedReview: PropTypes.object,
    onSubmit: PropTypes.func
};

export default CreateReview;
