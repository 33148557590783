import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FrameWrapper, LoginGraphic, LoginWrapper, VModal, ButtonWrapper } from './styles';
import Iframe from 'react-iframe';
import { Button, Checkbox } from 'semantic-ui-react';
import { AuthContext } from '../../context/AuthContext';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { updateTerms } from '../../graphql/customMutations';

const tocIUrl = "https://app.termly.io/document/terms-of-use-for-saas/1c8b3515-63da-4722-b065-541c9dab16e5";

const AcceptTerms = (props) => {
  const { context, refreshUser } = useContext(AuthContext);
  const [checked, setChecked] = useState();
  const history = useHistory();
  const [acceptTerms] = useMutation(updateTerms);


  useEffect(() => {
    if (!context.user) {
      history.push("/login");
    } else {
      if (context.user && context.user.attributes['custom:terms']) {
        history.push("/");
      }
    }
  }, [context.user]);

  const handleAccept = useCallback(() => {
    acceptTerms().then(response => {
      if (response.data?.updateTerms?.time) {
        refreshUser();
      }
    }).catch(e => {
      console.log(e);
    });
  });

  return <LoginWrapper>
    <LoginGraphic>
      <VModal>
        <FrameWrapper>
          <Iframe
            url={tocIUrl}
            width="600px"
            height="450px"
            display="initial"
            position="relative"
          />
        </FrameWrapper>

        <ButtonWrapper>
          <Checkbox label="I hereby accept the terms of use" onChange={(e, data) => {
            setChecked(data.checked);
          }}/>
          <Button disabled={!checked} color="blue" onClick={handleAccept}>Accept</Button>
        </ButtonWrapper>


      </VModal>
    </LoginGraphic>
  </LoginWrapper>;
};

export default AcceptTerms;

