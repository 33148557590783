import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import theme from '../../styles/theme';

const Wrapper = styled.div`
    position: relative;
    display: block;
    background: ${({active}) => active ? theme.success : theme.accent};
    cursor: pointer;
    border-radius: 60px;
    height: 30px;
    width: 60px;
    transition: all 250ms;
`;

const Nob = styled.div`
    position: relative;
    display: block;
    background: white;
    border-radius: 100px;
    height: 30px;
    width: 30px;
    border: 1px solid ${({active}) => active ? theme.success : theme.accent};
    transform: ${({active}) => active ? `translateX(30px)` : `translateX(0px)`};
    transition: all 250ms;
`;

export const ToggleStyled = ({form, callback, field, value, ...props}) => {
    const [active, setActive] = useState(!!value);

    const handleClick = useCallback(() => {
        setActive(!active);

        form.setFieldValue(field.name, !active);

        if( callback ) {
            callback(active);
        }
    }, [active]);

    return (
        <Wrapper {...props}
                toggle
                active={active}
                className={`${active ? `active` : ``}`}
                onClick={handleClick}>
            <Nob active={active}/>
        </Wrapper>
    );
};