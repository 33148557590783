import React from "react";

const CloseIcon = props => (
    <svg width={10} height={10} {...props}>
        <path
            d="M9.73 8.134L6.592 5l3.134-3.136A.917.917 0 0 0 9.728.563L9.438.27A.905.905 0 0 0 8.785 0h-.002a.912.912 0 0 0-.65.27L5 3.407 1.863.274a.917.917 0 0 0-1.3-.002l-.291.29A.917.917 0 0 0 .27 1.865L3.408 5 .273 8.136a.918.918 0 0 0-.001 1.302l.29.291a.921.921 0 0 0 1.303 0l3.136-3.136 3.135 3.133a.918.918 0 0 0 1.301.001l.291-.29A.902.902 0 0 0 10 8.786a.91.91 0 0 0-.269-.652H9.73z"
            fill={
                props.fill || "#424242"
            }
            fillRule="nonzero"
        />
    </svg>
);

export default CloseIcon;

