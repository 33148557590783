import React from "react";
import styled from "styled-components/macro";

import VendorProductTable from "../VendorProductTable/VendorProductTable";

const VendorProductWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;
const BaseStylesForInfoTable = styled.div`
    padding: 2rem 2%;
`;
const TableInfoWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`;
const ReviewLabel = styled(BaseStylesForInfoTable)`
    font-weight: bold;
    color: black;
`;
const ReviewValue = styled(BaseStylesForInfoTable)`
    font-weight: bold;
`;
const PriorLabel = styled(BaseStylesForInfoTable)`
    color: black;
`;

const data = [
    {
        vendor: {
            title: "DebtWire",
            link: "/vport/dashboard",
            data: {
                id: 1,
                totalAnnualCost: 40000,
                products: [
                    {
                        title: "DebtWire Europe",
                        annualCost: 20000,
                        annualCostPercent: 50,
                        contractEnd: "2023-11-30T08:15:00.000Z",
                        licenses: 50,
                    },
                    {
                        title: "DebtWire ABS",
                        annualCost: 20000,
                        annualCostPercent: 50,
                        contractEnd: "2023-11-30T08:15:00.000Z",
                        licenses: 50,
                    },
                ],
            },
        },
        product: {
            title: "Debtwire ABS",
            id: 1,
            vendor: {
                title: "DebtWire",
            },
            sme: "Bruno, Mike",
            marketDataContact: "Bruno, Mike",
        },
        annualCost: 60000,
        annualCostPercent: 60,
        licenses: 50,
        contractEnd: "2023-11-30T08:15:00.000Z",
        cancellationDate: "2023-11-30T08:15:00.000Z",
        reviewKickoffDate: "2023-11-30T08:15:00.000Z",
    },
    {
        vendor: {
            title: "DebtWire",
            link: "/vport/dashboard",
            data: {
                id: 2,
                totalAnnualCost: 40000,
                products: [
                    {
                        title: "DebtWire Europe",
                        annualCost: 20000,
                        annualCostPercent: 50,
                        contractEnd: "2023-11-30T08:15:00.000Z",
                        licenses: 50,
                    },
                    {
                        title: "DebtWire ABS",
                        annualCost: 20000,
                        annualCostPercent: 50,
                        contractEnd: "2023-11-30T08:15:00.000Z",
                        licenses: 50,
                    },
                ],
            },
        },
        product: {
            title: "Debtwire Europe",
            id: 1,
            vendor: {
                title: "DebtWire",
            },
            sme: "Bruno, Mike",
            marketDataContact: "Bruno, Mike",
        },
        annualCost: 40000,
        annualCostPercent: 40,
        licenses: 50,
        contractEnd: "2023-11-30T08:15:00.000Z",
        cancellationDate: "2023-11-30T08:15:00.000Z",
        reviewKickoffDate: "2023-11-30T08:15:00.000Z",
    },
];

export const VendorProductBlock = ({}) => {
    return (
        <VendorProductWrapper>
            <TableInfoWrapper>
                <ReviewLabel>Review period:</ReviewLabel>
                <ReviewValue>
                    <a href="#">60 days</a>
                </ReviewValue>
                <PriorLabel>prior to contract expiration</PriorLabel>
            </TableInfoWrapper>
            <VendorProductTable data={data} />
        </VendorProductWrapper>
    );
};

VendorProductBlock.propTypes = {};

export default VendorProductBlock;
