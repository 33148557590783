import { gql } from "@apollo/client";
export const updateAgreementMeta = /* GraphQL */ `
    mutation UpdateAgreementMeta($input: UpdateAgreementMetaInput!) {
        updateAgreementMeta(input: $input) {
            id
        }
    }
`;

export const updateProductAndPricingGroup = /* GraphQL */ `
    mutation UpdateProductAndPricingGroup($input: UpdateProductAndPricingGroupInput!) {
        updateProductAndPricingGroup(input: $input) {
            id
            productVSourceId
            productAndPricingGroupProductId
        }
    }
`;

export const createProductAndPricingGroup = /* GraphQL */ `
    mutation CreateProductAndPricingGroup($input: CreateProductAndPricingGroupInput!) {
        createProductAndPricingGroup(input: $input) {
            id
            productVSourceId
            productAndPricingGroupProductId
        }
    }
`;

export const deleteProductAndPricingGroup = /* GraphQL */ `
    mutation DeleteProductAndPricingGroup($input: DeleteProductAndPricingGroupInput!) {
        deleteProductAndPricingGroup(input: $input) {
            id
        }
    }
`;

export const updateAgreement = /* GraphQL */ `
    mutation UpdateAgreement($input: UpdateAgreementInput!) {
        updateAgreement(input: $input) {
            id
            vendorId
            vendorVSourceId
        }
    }
`;

export const deleteAgreementDocuments = /* GraphQL */ `
    mutation DeleteAgreementDocuments($input: DeleteAgreementDocumentsInput!) {
        deleteAgreementDocuments(input: $input) {
            id
        }
    }
`;

export const createAgreementDocuments = /* GraphQL */ `
    mutation CreateAgreementDocuments($input: CreateAgreementDocumentsInput!) {
        createAgreementDocuments(input: $input) {
            id
        }
    }
`;

export const deleteAgreement = /* GraphQL */ `
    mutation DeleteAgreement($input: DeleteAgreementInput!) {
        deleteAgreement(input: $input) {
            id
        }
    }
`;

export const insertVendorRevision = gql`
    mutation Mutation($vendorId: Int!, $input: InsertRevisionInput) {
        vlink {
            insertVendorRevision(vendorId: $vendorId, input: $input) {
                code
                success
                message
                vendorRevisionId
            }
        }
    }
`;

export const insertVendorRevisions = gql`
    mutation Mutation($vendorId: Int!, $input: [InsertRevisionInput]) {
        vlink {
            insertVendorRevisions(vendorId: $vendorId, input: $input) {
                code
                success
                message
                insertedVendorRevisions {
                    id
                    vendor_id
                }
            }
        }
    }
`;

export const insertProductRevisions = gql`
    mutation Mutation($productId: Int!, $input: [InsertRevisionInput]) {
        vlink {
            insertProductRevisions(productId: $productId, input: $input) {
                code
                success
                message
                insertedProductRevisions {
                    id
                    product_id
                }
            }
        }
    }
`;

export const insertProductRevision = gql`
    mutation Mutation($productId: Int!, $input: InsertRevisionInput) {
        vlink {
            insertProductRevision(productId: $productId, input: $input) {
                code
                success
                message
                productRevisionId
            }
        }
    }
`;

export const updateVendorRevision = gql`
    mutation Vlink($revisionId: Int!, $input: UpdateRevisionInput) {
        vlink {
            updateVendorRevision(revisionId: $revisionId, input: $input) {
                code
                success
                message
                vendorRevisionId
            }
        }
    }
`;

export const updateProductRevision = gql`
    mutation Vlink($revisionId: Int!, $input: UpdateRevisionInput) {
        vlink {
            updateProductRevision(revisionId: $revisionId, input: $input) {
                code
                success
                message
                productRevisionId
            }
        }
    }
`;

export const submitVendorRevision = gql`
    mutation Vlink($vendorId: Int!, $input: SubmitRevisionInput) {
        vlink {
            submitVendorRevision(vendorId: $vendorId, input: $input) {
                code
                success
                message
                # revisionId
            }
        }
    }
`;

export const submitProductRevision = gql`
    mutation Vlink($productId: Int!, $input: SubmitRevisionInput) {
        vlink {
            submitProductRevision(productId: $productId, input: $input) {
                code
                success
                message
            }
        }
    }
`;

export const uploadVlinkFile = gql`
    mutation Vlink($uploadVLinkPremiumFileId: Int!, $input: UploadVLinkPremiumFileInput) {
        vlink {
            uploadVLinkPremiumFile(id: $uploadVLinkPremiumFileId, input: $input) {
                code
                success
                message
                file {
                    type
                    fileName
                    description
                    fileMimeType
                }
            }
        }
    }
`;

export const uploadVendorLogo = gql`
    mutation Vlink($uploadLogoRequestId: Int!, $input: UploadVendorLogoInput) {
        vlink {
            uploadLogoRequest(id: $uploadLogoRequestId, input: $input) {
                code
                success
                message
                file {
                    fileName
                    url
                }
            }
        }
    }
`;

export const updateProductTags = gql`
    mutation Vsource($productId: Int!, $values: [UpdateProductTag]!) {
        vsource {
            updateProductTags(productId: $productId, values: $values) {
                code
                success
                message
            }
        }
    }
`;

export const uploadImageForArticleOrIndustryEvent = gql`
    mutation Mutation($imageType: String!) {
        uploadImageForArticleOrIndustryEvent(imageType: $imageType) {
            imageUrl
            uploadUrl
        }
    }
`;

export const addIndustryEvent = gql`
    mutation AddIndustryEvent($eventName: String!, $eventDate: Date!, $organizerName: String!, $url: String!) {
        addIndustryEvent(eventName: $eventName, eventDate: $eventDate, organizerName: $organizerName, url: $url)
    }
`;
export const removeIndustryEvent = gql`
    mutation RemoveIndustryEvent($removeIndustryEventId: ID!) {
        removeIndustryEvent(id: $removeIndustryEventId)
    }
`;

export const addArticle = gql`
    mutation SetArticleAsFeatured(
        $title: String!
        $previewText: String!
        $url: String!
        $thumbnailUrl: String
        $featuredImageUrl: String
    ) {
        addArticle(
            title: $title
            previewText: $previewText
            url: $url
            thumbnailUrl: $thumbnailUrl
            featuredImageUrl: $featuredImageUrl
        )
    }
`;

export const removeArticle = gql`
    mutation Mutation($removeArticleId: ID!) {
        removeArticle(id: $removeArticleId)
    }
`;

export const setArticleAsFeatured = gql`
    mutation SetArticleAsFeatured($setArticleAsFeaturedId: ID!, $featuredImageUrl: String) {
        setArticleAsFeatured(id: $setArticleAsFeaturedId, featuredImageUrl: $featuredImageUrl)
    }
`;

export const createProductVidsv = gql`
    mutation Vsource($productId: Int!, $input: CreateProductVidsvInput) {
        vsource {
            createProductVdisv(productId: $productId, input: $input) {
                code
                success
                message
            }
        }
    }
`;

export const updateProductVidsv = gql`
    mutation Vsource($updateProductVdisvId: Int!, $input: UpdateProductVidsvInput) {
        vsource {
            updateProductVdisv(id: $updateProductVdisvId, input: $input) {
                code
                success
                message
            }
        }
    }
`;

export const deleteProductVidsv = gql`
    mutation Vsource($deleteProductVidsvId: Int!) {
        vsource {
            deleteProductVidsv(id: $deleteProductVidsvId) {
                code
                success
                message
            }
        }
    }
`;

export const deleteVLinkPremiumFile = gql`
    mutation Vlink($id: Int!, $fileId: Int!, $type: String!) {
        vlink {
            deleteVlinkPremiumFile(id: $id, fileId: $fileId, type: $type) {
                code
                success
                message
            }
        }
    }
`;

export const updateVsourceProductField = gql`
    mutation Vlink($productId: Int!, $input: UpdateVsourceFieldInput) {
        vlink {
            updateVsourceProductField(productId: $productId, input: $input) {
                code
                value
                success
                message
            }
        }
    }
`;

export const updateVsourceVendorField = gql`
    mutation Vlink($vendorId: Int!, $input: UpdateVsourceFieldInput) {
        vlink {
            updateVsourceVendorField(vendorId: $vendorId, input: $input) {
                code
                value
                success
                message
            }
        }
    }
`;

export const insertVLinkNotification = gql`
    mutation Mutation($input: AddVLinkNotificationInput) {
        vlink {
            insertVLinkNotification(input: $input) {
                code
                success
                message
                notification_id
            }
        }
    }
`;

export const updateVlinkNotification = gql`
    mutation Mutation($updateVLinkNotificationId: ID!, $input: AddVLinkNotificationInput) {
        vlink {
            updateVLinkNotification(id: $updateVLinkNotificationId, input: $input) {
                code
                success
                message
            }
        }
    }
`;

export const deleteVLinkNotification = gql`
    mutation Mutation($deleteVLinkNotificationId: ID!) {
        vlink {
            deleteVLinkNotification(id: $deleteVLinkNotificationId) {
                code
                success
                message
            }
        }
    }
`;

export const updateVSourceVendorProduct = gql`
    mutation UpdateVendorProduct($productId: ID!, $input: UpdateVendorProductInput) {
        vsource {
            updateVendorProduct(productId: $productId, input: $input)
        }
    }
`;

export const uploadVKeyDocumentTrackerData = gql`
    mutation Mutation($fields: [String], $values: [[JSON]]) {
        vkey {
            uploadVKeyDocumentTrackerData(fields: $fields, values: $values) {
                code
                success
                message
                updatedItemsCounter
            }
        }
    }
`;

export const updateTerms = gql`
    mutation Mutation {
        updateTerms {
            time
        }
    }
`;

export const deleteDocument = gql`
    mutation Mutation($documentUuid: String) {
        vkey {
            deleteVKeyDocument(documentUUID: $documentUuid) {
                code
                success
                message
                updatedItemsCounter
            }
        }
    }
`;
