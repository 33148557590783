import _get from "lodash/get";
import API, { graphqlOperation } from "@aws-amplify/api";
import { deleteAgreement as _deleteAgreement } from "../../graphql/customMutations";

const deleteAgreement = async (agreementToDelete, { dispatch }) => {
    const agreementId = _get(agreementToDelete, "id", null);
    const agreementName = _get(agreementToDelete, "agreementName", "");

    const deleteAgreementResult = await API.graphql(
        graphqlOperation(_deleteAgreement, {
            input: {
                id: agreementId,
            },
        })
    );

    const agreementDeleted = _get(deleteAgreementResult, "data.deleteAgreement", null);

    if (agreementDeleted) {
        agreementDeleted.agreementName = agreementName;

        dispatch({ type: "AGREEMENT_DELETED", agreementDeleted });

        return agreementDeleted;
    }
};

export default deleteAgreement;
