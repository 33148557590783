import React, { useContext, useMemo } from "react";
import styled from "styled-components";
import styledMap from "styled-map";
import _get from "lodash/get";
import _groupBy from "lodash/groupBy"
import * as PropTypes from "prop-types";
import { format } from "date-fns";


import DocItem from "./DocItem";
import { AuthContext } from "../context/AuthContext";

const DocSectionHeader = styled.span`
    position: relative;
    text-transform: capitalize;
    font-size: 16px !important;
    color: #222;
    text-align: left;
    font-weight: 400 !important;
    margin: 15px 0;
`;
const DocList = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 auto;
    bottom: 0;
    width: 100%;

    & > * {
        margin: 0 45px 100px 0;
    }
`;
const DocSectionWrapper = styled.div`
    position: relative;
    display: flex;
    background: ${styledMap`
        primary: ${({ theme }) => theme.gray1};
        default: none;
        `};
    flex: 0 0 auto;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
`;
const DocSection = ({ docSectionKey, docs, primary, allowDeletingDoc, vendorsData }) => {
    return (
        <DocSectionWrapper primary={primary}>
            <DocSectionHeader>{docSectionKey}</DocSectionHeader>
            <DocList data-cy={`doc-list`}>
                {docs &&
                    docs.map((item) => {
                        const { id, description, vendExFileName, agreements, ...other } = item;
                        return (
                            <DocItem
                                tooltipProps={other}
                                key={id}
                                data-cy={`doc-item-${id}`}
                                id={id}
                                agreements={agreements}
                                description={description}
                                filename={vendExFileName}
                                allowDeletingDoc={allowDeletingDoc}
                                vendorsData={vendorsData}
                            />
                        );
                    })}
            </DocList>
        </DocSectionWrapper>
    );
};
DocSection.propTypes = {
    docSectionKey: PropTypes.string,
    primary: PropTypes.bool,
    docs: PropTypes.any,
};
const DocSectionScrollWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    height: auto;
    flex-direction: column;
    width: 100%;
`;
export const VaultFeed = ({ vendors, allowDocDelete, ...props }) => {
    const { context, isVKeySuperUser, isVKeyClientUserOnly } = useContext(AuthContext);
    const docList = useMemo(() =>
        _groupBy(props.data, (item) => format(item.createdAt, "dddd MMMM, Do YYYY")),
        [props.data]
    );

    const shouldAllowDocDelete = () => {
        // If the permission comes from props
        if(allowDocDelete !== undefined){
            if(allowDocDelete === true){
                return isVKeyClientUserOnly(context.user) || isVKeySuperUser(context.user)
            }
            return allowDocDelete;
        }

        return isVKeyClientUserOnly(context.user) || isVKeySuperUser(context.user)
    }
    return (
        <div
            style={{
                height: "100%",
                width: "100%",
            }}
            {...props}
        >
            <DocSectionScrollWrapper>
                {Object.keys(docList).map((docSectionKey) => {
                    const docs = _get(docList, `[${docSectionKey}]`, []);
                    return (
                        <DocSection
                            key={docSectionKey}
                            docSectionKey={docSectionKey}
                            docs={docs}
                            allowDeletingDoc={shouldAllowDocDelete()}
                            vendorsData={vendors}
                        />
                    );
                })}
            </DocSectionScrollWrapper>
        </div>
    );
};
