import React, { useState, useCallback, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import PdfViewer from "../components/PdfViewer";
import styled from "styled-components/macro";
import KeyMetaForm from "../components/forms/KeyMetaForm";
import { AuthContext } from "../context/AuthContext";
import { AgreementContext } from "../context/agreement/AgreementContext";
import loadingAnimData from "../anim/loading";
import Lottie from "react-lottie";

const PdfArea = styled.div`
    flex: 1;
`;

const MetaColumn = styled.div`
    flex: 0 0 400px;
    display: flex;
`;

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
`;

const LoadingWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
`;
const Loading = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAnimData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    return (
        <LoadingWrapper>
            <Lottie options={defaultOptions} height={300} width={300} />
        </LoadingWrapper>
    );
};

export const AgreementMetaPage = withRouter(({ match, history }) => {
    const { fetchAgreement, updateAgreementMeta, context } = useContext(AgreementContext);
    const {
        fetchDoc,
        context: { activeEntity },
    } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [saveError, setSaveError] = useState("");
    const [pdfLinks, setPdfLinks] = useState([]);
    const [documentTitles, setDocumentTitles] = useState([]);
    const [exit, setExit] = useState(false);

    const agreement = context.selectedAgreement;

    const goBack = useCallback(() => {
        history.goBack();
    }, []);

    const handleExit = useCallback(
        (check) => {
            setExit(check);
        },
        [exit]
    );

    useEffect(() => {
        fetchAgreement(match.params.id);
    }, []);

    useEffect(() => {
        async function fetchPdfLinks() {
            const downloadedPdfs = [];
            const documentTitles = [];

            for (let i = 0; i < agreement.documents.items.length; i++) {
                // Filter out the documents missing from database
                if (agreement.documents.items[i].document) {
                    const vendExFileName = agreement.documents.items[i].document.vendExFileName;
                    try {
                        const doc = await fetchDoc({
                            key: `vkey/contracts/${activeEntity.groupName}/${vendExFileName}`,
                        });
                        downloadedPdfs.push(doc);
                        documentTitles.push(vendExFileName);
                    } catch (err) {
                        console.log("err with doc fetch ", err);
                    }
                }
            }
            if (context.selectedAgreement) {
                setPdfLinks(downloadedPdfs);
                setDocumentTitles(documentTitles);
            }
        }

        fetchPdfLinks();
    }, [context.selectedAgreement]);

    const onUpdate = useCallback(
        (metaData) => {
            setLoading(true);
            updateAgreementMeta(agreement, metaData)
                .then(() => {
                    setLoading(false);
                    if (exit) {
                        history.push("/vkey/agreements");
                    } else {
                        fetchAgreement(match.params.id);
                    }
                })
                .catch((e) => {
                    setLoading(false);
                    setSaveError("Error saving meta. Please try again.");
                    console.error("Error updating", e);
                });
        },
        [agreement, exit]
    );

    return (
        <Wrapper>
            <PdfArea>
                <PdfViewer backButtonClick={goBack} pdfLinks={pdfLinks} documentTitles={documentTitles} />
            </PdfArea>
            <MetaColumn>
                {loading && <Loading />}
                {!loading && agreement && agreement.meta ? (
                    <KeyMetaForm
                        {...agreement.meta}
                        documentVendorName={agreement.documentVendorName} // Add Document Vendor Name from agreement data to metadata form
                        agreement={agreement}
                        handleExit={handleExit}
                        onUpdate={onUpdate}
                        saveError={saveError}
                    />
                ) : null}
            </MetaColumn>
        </Wrapper>
    );
});
