import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import SearchIcon from "./icons/SearchIcon";
import searchLoadingAnimData from "../anim/search-loading";
import Lottie from "react-lottie";
import { useDebounce } from "react-use";
import _get from "lodash/get";
import _isString from "lodash/isString";
import { AuthContext } from "../context/AuthContext";

const SearchInput = styled.input`
    position: relative;
    display: block;
    border: none;
    border-radius: 5px;
    height: 100%;
    flex: 2 0 0;
    padding: 8px 8px 8px 50px;
    font-size: 14px;
    font-weight: 500;
    background: #F6F8FA;

    &::placeholder {
        color: #898FA3;
    }
`;
const SearchWrapper = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 40px;
`;
const SearchIconWrapper = styled(SearchIcon)`
    position: absolute;
    display: block;
    top: 10px;
    left: 16px;
    z-index: 10;
    transform:scale(0.6);
`;

export const Search = ({ inputSearchText = '', onChange, placeholder, isStopped = true, ...props }) => {
    const [value, setValue] = useState('');
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: searchLoadingAnimData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };
    const onChangeValue = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }
    useEffect(() => {
        if (!inputSearchText) {
            setValue('');
            onChange('');
        }
    }, [inputSearchText]);
    return (
        <SearchWrapper {...props}>
            <SearchIconWrapper>
                <SearchIcon value={inputSearchText} />
            </SearchIconWrapper>
            <SearchInput value={value} placeholder={placeholder} onChange={onChangeValue} />
            <Lottie
                options={defaultOptions}
                height={20}
                width={20}
                isStopped={isStopped}
                style={{
                    position: "absolute",
                    zIndex: 400,
                    top: 10,
                    right: 16,
                }}
            />
        </SearchWrapper>
    );
};

const searchKeys = [
    'docVendorName',
    'docAgreementName',
    'docEndDate',
    'docNumberOfPages',
    'docProductNames',
    'vendExFileName',
]

export const DocSearch = (props) => {
    const { dispatch, context } = useContext(AuthContext);

    const [searching, setSearching] = useState(false);
    const [inputSearchText, setInputSearchText] = useState("");

    const resetSearch = () => {
        dispatch({ type: "DOC_SEARCH", docSearch: "", searchItems: [] });
    }

    useEffect(()=> {
        resetSearch();
    }, [])

    useDebounce(
        () => {
            setSearching(true);
            // setDebouncedValue(inputSearchText);
            const searchItems = [];
            context.docItems.forEach((docObj) => {
                searchKeys.some((key) => {
                    const value = String(_get(docObj, `[${key}]`, ''));
                    if (!!value && !!_isString(inputSearchText) && !!inputSearchText && value.toLowerCase().trim().includes(inputSearchText.trim().toLowerCase())) {
                        searchItems.push(docObj);
                        return true
                    } else {
                        return false
                    }
                });
            });

            dispatch({ type: "DOC_SEARCH", docSearch: inputSearchText, searchItems });
            setSearching(false)
            // fetchDocs({ searchTerm: inputSearchText, entityGroup: context.activeEntity?.groupName }).then(({docList, docItems}) => {
            //     dispatch({ type: "FETCH_DOCS", docList, docItems });
            // });
            //DOC_SEARCH
        },
        500,
        [inputSearchText]
    );
    useEffect(() => {
        if (!context.docSearch) {
            setInputSearchText("");
        }
    }, [
        context.docSearch
    ])
    return (
        <Search
            {...props}
            inputSearchText={inputSearchText}
            searching={searching}
            placeholder="Search"
            onChange={(value) => {
                setInputSearchText(value);
            }}
        />
    );
};

// DocSearch.propTypes = {
//     inputSearchText: PropTypes.string,
//     searching: PropTypes.bool,
//     onChange: PropTypes.func
// };
