import React, {useState, useContext, useEffect} from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { graphqlOperation } from '@aws-amplify/api-graphql';
import API from '@aws-amplify/api';

import { deleteVendorProduct } from "../../graphql/mutations";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";
import { useAlerts } from "../../hooks/useAlerts";
import { AuthContext } from "../../context/AuthContext";
import { ButtonWrapper } from "../../components/VendBtn";

const ProductEditButtonsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-right: 10px;
`;


const ProductEditButtons = ({context}) => {
    const [showProductDeleteModal, setShowProductDeleteModal] = useState(false);
	const [vendorId, setVendorId] = useState(null);

    const { context: {user}, isVSourceVendexSuperUser } = useContext(AuthContext);
    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const history = useHistory();
    const { id: productId } = useParams();

	// Fetch the vendor information
	useEffect(() => {
        const fetchProduct = async () => {
            const product = /* GraphQL */ `
                query GetProduct($id: ID!) {
                    product(id: $id) {
                        id
                        name
                        vendor {
                            id
                            full_legal_name
                        }
                    }
                }
            `;
            try {
                const { data } = await API.graphql(graphqlOperation(product, { id: productId }));
                if (data?.product?.vendor?.id) {
                    console.log("Product data: ", data.product);
                    setVendorId(data.product.vendor.id);
                }
            } catch (e) {
                console.log("Product data fetch error: ", e);
            }
        };
        
        if (productId) {
            fetchProduct();
        } else {
            newErrorAlert("Invalid URL");
        }
    }, [productId]);

    const isVSourcePage = window.location.pathname.includes(`/vsource/product/${productId}`);

    const onDeleteProduct = async () => {
		if (productId) {
			try {
				const { data } = await API.graphql(
					graphqlOperation(deleteVendorProduct, {
						id: productId,
					})
				).catch((e) => {
					console.log("GraphQL fetch error:", e);
					throw new Error(e.errors[0].message);
				});

				if (data && data.deleteVendorProduct) {
					newSuccessAlert(`Product deleted successfully`);
                    history.push(`/vsource/vendors/${vendorId}`);
				} else newErrorAlert("Failed to delete product");
			} catch (e) {
				console.log("Delete product errors: ", e);
				newErrorAlert(e.message ?? "Error deleting the product");
			}
		}
	};

    // Show the buttons only on VSource vendor page if the user is a Vendex-Super-User
    if(!(isVSourcePage && ['VENDOR_PREVIEW', 'PUBLIC'].includes(context) && isVSourceVendexSuperUser(user))){
        return null;
    }

    return(
        <React.Fragment>
        {showProductDeleteModal &&
			<LargeConfirmationModal
                    show={showProductDeleteModal}
                    onClose={() => setShowProductDeleteModal(false)}
                    onSubmit={() => {
                        setShowProductDeleteModal(false);
                        onDeleteProduct();
                    }}
                    onDiscard={() => setShowProductDeleteModal(false)}
                    title={"Delete Product?"}
                    description={"Once deleted, you will not be able to recover the Product"}
                />
			}
			<ProductEditButtonsWrapper>
					<ButtonWrapper
						space-between
						onClick={() => vendorId ? history.push(`/vsource/product/edit/${productId}?vendor=${vendorId}`) : null}
					>
						<span style={{marginRight: "4px"}}>Edit Product</span>
					</ButtonWrapper>
					<ButtonWrapper
						space-between
						onClick={() => setShowProductDeleteModal(true)}
					>
						Delete Product
					</ButtonWrapper>
			</ProductEditButtonsWrapper>
            </React.Fragment>
    )
}

export default ProductEditButtons;