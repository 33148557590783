import styled from "styled-components/macro";

export const OptionPanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    
    & > * {
      margin-bottom: 8px;
      text-align: center;
      align-items: center;
      justify-content: center;
    }
    
    & > *:last-child {
      margin-bottom: 0;
    }
`;