export const _searchPropInTree = (prop, tree, parentsOnly = false) => {
    let props = [];
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if(!parentsOnly || (item.children && item.children.length > 0)) {
            props.push(item[prop]);
        }
        
        if (item.children && item.children.length > 0) {
            let foundInChildren = _searchPropInTree(prop, item.children, parentsOnly);
            if (foundInChildren && foundInChildren.length) {
                props = props.concat(foundInChildren);
            }
        }
    }
    return props;
};