import React, { useContext, useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import _get from "lodash/get";

import styled from "styled-components/macro";

import { PageWrapper } from "../../components/PageWrapper";
import SummaryWidget from "./components/SummaryWidget/SummaryWidget";
import CostBlock from "./components/CostBlock/CostBlock";
import VendorProductBlock from "./components/VendorProductBlock/VendorProductBlock";
import { AuthContext } from "../../context/AuthContext";
import { getStorage, setStorage } from "../../utils/localStorage";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashBoardWrapper = styled.div`
    padding: 6% 2.2rem 2rem 2.2rem;
`;
const GridItem = styled.div`
    //border: 1px solid black;
`;

const GridItemTable = styled(GridItem)`
    color: blue;
    border-color: blueviolet;
`;

const FullWidthGrid = styled(ResponsiveGridLayout)`
    width: 100%;
    display: flex;
`;

const layoutCols = { lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 };
const defaultLayout = [
    { x: 0, y: 0, w: 0.64, h: 1 },
    { x: 1, y: 0, w: 1.35, h: 0.88 },
    { x: 0, y: 1, w: 2, h: 1 },
];

export const VPortDashboard = () => {
    const { context: authContext } = useContext(AuthContext);

    const [customerName, setCustomerName] = useState("");
    const [dashboardLayout, setDashboardLayout] = useState(null);
    const [loadingDashboard, setLoadingDashboard] = useState(true);

    const onLayoutChange = (layout) => {
        setStorage("dashboardLayout", layout);
    };
    useEffect(() => {
        const dashboardLayoutData = getStorage("dashboardLayout");
        setDashboardLayout(dashboardLayoutData);
        setLoadingDashboard(false);
    }, []);
    useEffect(() => {
        setCustomerName(authContext.user.attributes.email);
    }, [authContext.user.attributes.email]);

    return (
        <PageWrapper>
            <DashBoardWrapper>
                {!loadingDashboard && (
                    <FullWidthGrid
                        rowHeight={450}
                        cols={layoutCols}
                        onLayoutChange={onLayoutChange}
                        layout={dashboardLayout}
                        isResizable={false}
                    >
                        <GridItem key="a" data-grid={_get(dashboardLayout, `[0]`, defaultLayout[0])}>
                            <SummaryWidget username={customerName} totalSpeed="£0" />
                        </GridItem>
                        <GridItem key="b" data-grid={_get(dashboardLayout, `[1]`, defaultLayout[1])}>
                            <CostBlock />
                        </GridItem>
                        <GridItemTable key="c" data-grid={_get(dashboardLayout, `[2]`, defaultLayout[2])}>
                            <VendorProductBlock />
                        </GridItemTable>
                    </FullWidthGrid>
                )}
            </DashBoardWrapper>
        </PageWrapper>
    );
};
