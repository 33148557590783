import numeral from "numeral";

export const moneyConvert = (value, alternate = false) => {
    let res = numeral(value).format('(00,00.00)');
    if(alternate) {
        return res;
    }

    return res.replace(',',' ').replace('.', ',');
}

export const moneyConvertWithoutDecimal = (value, alternate = false) => {
    let res = numeral(value).format('(00,00)');
    if(alternate) {
        return res;
    }

    return res.replace(',',' ').replace('.', ',');
}

export const percentConvert = (value) => {
    return numeral(value).format('(0,0.00)').replace('.',',').replace('.', ',')
}
