import React from "react";
import styled from "styled-components";

const FeaturedArticleWrapper = styled.div`
    margin-bottom: 2rem;
    h4 {
      font-size: 14px!important;
      font-weight: 400;
      margin: 0.25rem 0 0.5rem 0;
      color: #393939;
    }
    p {
      font-size: 14px!important;
      color: #39393999;
      opacity: 0.6;
      margin-bottom: 0;
    }
    a {
      font-size: 14px!important;
      font-weight: 400;
      text-decoration: underline;
    }
    a:hover {
      text-decoration: none; 
    }
`
const FeaturedArticleImage =styled.img`
  max-width: 100%;
  border: 1px solid #b5b5c3;
`

const FeaturedArticle = ({
    img,
    title,
    text,
    url
}) => {

    return <FeaturedArticleWrapper>
        {
            !!img && <FeaturedArticleImage
                src={img}
                alt={title}
            />
        }
        {!!title && <h4>{title} {!!url && <a href={url} target="_blank">Read more</a>}</h4>}
    </FeaturedArticleWrapper>
};

export default React.memo(FeaturedArticle);
