import React, { useCallback, useContext, useEffect, useState } from "react";
import { Input } from "semantic-ui-react";
import { FormSection } from "../FormSection";
import { ErrorMessage } from "../ErrorMessage";
import { ButtonWrapper } from "../VendBtn";
import { AuthContext } from "../../context/AuthContext";
import { useFormInput } from "../../hooks/useFormInput";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify";
import { getUserRegistryData } from "../../utils/user-registry-utils";

export const VerifyTokenForm = withRouter(({ onSubmit, history }) => {
    const [loading, setLoading] = useState(false);

    const { context, dispatch, checkEntityAccess, isVKeyAdminUser, isVSourceAdminUser, getFreshUser } = useContext(
        AuthContext,
    );

    useEffect(() => {
        if (!context.user) {
            history.push("/login");
        }
    }, []);

    const code = useFormInput("");

    const handleSignIn = useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);

            if (!context.user) {
                throw new Error("User is not authenticated, please login again");
            }

            Auth.confirmSignIn(context.user, code.value, "SOFTWARE_TOKEN_MFA").then((user) => {
                if (user) {
                    getFreshUser().then((freshUser) => {
                        getUserRegistryData(freshUser).then((registryUser) => {
                            dispatch({ type: "UPDATE_USER", user: registryUser });
                            checkEntityAccess(registryUser).then(() => {
                                if (isVKeyAdminUser(registryUser) || isVSourceAdminUser(registryUser)) {
                                    history.push("/dashboard");
                                } else {
                                    history.push("/home");
                                }
                            });
                        });
                    });
                }
            }).catch((err) => {
                dispatch({ type: "SIGN_IN_FAILURE", message: err.message });
                setLoading(false);
            });
        },
        [code.value],
    );

    return (
        <form onSubmit={handleSignIn}>
            <FormSection style={{ marginTop: 40 }}>
                <Input placeholder="Six Digit Code">
                    <input id={"mfa-code"} name={"Authentication Code"} type={"text"} {...code}
                           autoComplete="off"
                           autoFocus
                    />
                </Input>
            </FormSection>

            <FormSection>
                {context.signInError !== "" ? <ErrorMessage>{context.signInError}</ErrorMessage> : null}
            </FormSection>

            <FormSection style={{ marginTop: 40 }}>
                <ButtonWrapper data-cy="sign-btn" type={"submit"}>
                    {!loading ? "Verify" : "Loading..."}
                </ButtonWrapper>
            </FormSection>
        </form>
    );
});
