import React, { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import { USER_ROLES } from "../../Constants";

export const useCheckAuth = () => {
    const {
        context: { user },
        userHavePermission,
        isVPortClientUserOnly,
        isVKeyAdminUser: _isVKeyAdminUser,
        isVKeySuperUser: _isVKeySuperUser,
        isVKeyClientUserOnly: _isVKeyClientUserOnly,
        isVPortAnalyst: _isVPortAnalyst,
        isVLinkAnalyst: _isVLinkAnalyst,
        isVLinkVendor: _isVLinkVendor,
        isVLinkSuperUser: _isVLinkSuperUser,
        isVKey2Analyst: _isVKey2Analyst,
        isVKey2ReadOnly: _isVKey2ReadOnly,
    } = useContext(AuthContext);

    const isVSourceSuperUser = userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER);
    const isVSourceAdminUser = userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_USER);
    const isVSourceUser = userHavePermission({ user }, USER_ROLES.VSOURCE_CLIENT_USER);
    const isVisibleVSource =
        isVPortClientUserOnly(user) ||
        _isVKeyClientUserOnly(user) ||
        isVSourceUser ||
        _isVLinkVendor(user) ||
        isVSourceAdminUser ||
        isVSourceSuperUser;
    const isUnlockedVSource = isVSourceUser || isVSourceAdminUser || isVSourceSuperUser;

    const isVKeyAnalyst = _isVKeySuperUser(user) || _isVKeyAdminUser(user);
    const isVKeyAdminUser = _isVKeyAdminUser(user);
    const isVKeyUser = _isVKeyClientUserOnly(user) || isVKeyAnalyst;
    const isVisibleVKey = isVPortClientUserOnly(user) || _isVKeyClientUserOnly(user) || isVSourceUser || isVKeyAnalyst;
    const isUnlockedVKey = isVKeyUser;

    const isVPortUser = _isVPortAnalyst(user) || isVPortClientUserOnly(user);
    const isVPortAnalyst = _isVPortAnalyst(user);
    const isVisibleVPort =
        isVPortClientUserOnly(user) || _isVKeyClientUserOnly(user) || isVSourceUser || _isVPortAnalyst(user);
    const isUnlockedVPort = isVPortUser;

    const isVLinkUser = _isVLinkAnalyst(user) || _isVLinkVendor(user) || _isVLinkSuperUser(user);
    const isVLinkAnalyst = _isVLinkAnalyst(user);
    const isVLinkVendor = _isVLinkVendor(user);
    const isVLinkSuperUser = _isVLinkSuperUser(user);
    const isVisibleVLink = isVLinkUser;
    const isUnlockedVLink = isVLinkUser;

    const isVisibleReg = isVPortClientUserOnly(user) || _isVKeyClientUserOnly(user) || isVSourceUser;

    const isVKey2User = _isVKey2Analyst(user) || _isVKey2ReadOnly(user);

    return {
        isVSourceAdminUser,
        isVSourceUser,
        isVisibleVSource,
        isVKeyUser,
        isVKeyAdminUser,
        isVKeyAnalyst,
        isVisibleVKey,
        isVPortUser,
        isVPortAnalyst,
        isVisibleVPort,
        isVLinkUser,
        isVLinkAnalyst,
        isVLinkVendor,
        isVLinkSuperUser,
        isVisibleVLink,
        isVisibleReg,
        isUnlockedVSource,
        isUnlockedVKey,
        isUnlockedVPort,
        isUnlockedVLink,
        isVKey2User,
    };
};
