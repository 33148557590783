import React, { useCallback, useContext } from "react";
import { FormSection } from "../FormSection";
import { Icon, Input } from "semantic-ui-react";
import { ButtonWrapper } from "../VendBtn";
import { useFormInput } from "../../hooks/useFormInput";
import { AuthContext } from "../../context/AuthContext";
import { withRouter } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";
import { Auth } from "aws-amplify";

export const ResetPasswordForm = withRouter(({ onSubmit, history, location }) => {
    const { context, dispatch, resetPassword, forgotPassword } = useContext(AuthContext);

    const code = useFormInput("");
    const newPassword = useFormInput("");
    const confirmPassword = useFormInput("");

    const searchParams = new URLSearchParams(location.search);
    const username = searchParams.get("username");

    const handleNewPasswordSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                if (newPassword.value !== confirmPassword.value) throw new Error("Passwords must match.");

                await resetPassword({ username, code: code.value, newPassword: newPassword.value });
                history.push("/login");
            } catch (err) {
                console.log("Something went wrong", err);
                dispatch({ type: "RESET_PASSWORD", resetError: err.message });
            }
        },
        [newPassword.value, confirmPassword.value]
    );

    const handleResendCode = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                await forgotPassword({ username });
            } catch (err) {
                console.log("Something went wrong", err);
                dispatch({ type: "RESET_PASSWORD", resetError: err.message });
            }
        },
        [username]
    );

    return (
        <form onSubmit={handleNewPasswordSubmit} autoComplete="off">
            <FormSection style={{ marginTop: 40 }}>Enter the verification code from your email</FormSection>
            <FormSection style={{ marginTop: 40 }}>
                <Input iconPosition="right" placeholder="Verification Code">
                    <input name={"code"} autoComplete="off" id={"reset-code"} data-cy={"reset-code"} {...code} />
                </Input>
                <FormSection style={{ marginTop: 40 }}>Enter your new password</FormSection>
                <Input iconPosition="right" placeholder="New Password">
                    <Icon name="hide" />
                    <input
                        autoComplete="off"
                        id={"reset-password"}
                        data-cy={"reset-password"}
                        name={"newPassword"}
                        type={"password"}
                        {...newPassword}
                    />
                </Input>
                <Input iconPosition="right" placeholder="Confirm New Password">
                    <Icon name="hide" />
                    <input
                        name={"confirmPassword"}
                        autoComplete="off"
                        id={"reset-password-confirm"}
                        data-cy={"reset-password-confirm"}
                        type={"password"}
                        {...confirmPassword}
                    />
                </Input>
            </FormSection>

            <FormSection>
                {context.resetError !== "" ? <ErrorMessage>{context.resetError}</ErrorMessage> : null}
            </FormSection>

            <FormSection style={{ marginTop: 40 }}>
                <ButtonWrapper type={"submit"}>Reset Password</ButtonWrapper>
                <ButtonWrapper onClick={handleResendCode}>Re-send Code</ButtonWrapper>
            </FormSection>
        </form>
    );
});
