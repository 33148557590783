import React from "react";
import ReactTable from "react-table";
import * as PropTypes from "prop-types";

import { CellWithDate, CellWithLink, TimeFrameCell } from '../Cells/Cells';

const defaultHeaderStyles = {
    width: "100%",
    height: "100%",
    fontSize: "1rem",
    fontWeight: "bold",
    color: "black",
    textAlign: "center",
    backgroundColor: "#F1F3F4",
    border: "1px solid #F1F3F4",
}

const columns = [
    {
        accessor: "endDate",
        headerStyle: {
            ...defaultHeaderStyles
        },
        Cell: TimeFrameCell,
        Header: () => {
            return (
                <div
                >
                    Timeframe
                </div>
            )
        }
    },
    {
        Header: "Contract End",
        accessor: "endDate",
        headerStyle: {
            ...defaultHeaderStyles
        },
        Cell: CellWithDate
    },
    {
        Header: "Vendor",
        accessor: "vendorName",
        headerStyle: {
            ...defaultHeaderStyles
        },
        Cell: CellWithLink
    },
    {
        Header: "Event",
        accessor: "eventName",
        headerStyle: {
            ...defaultHeaderStyles
        },
        Cell: CellWithLink
    },
]
export const CalendarTable = ({
    data = [],
}) => {
    return <ReactTable
        className="DashboardTable"
        data={data}
        columns={columns}
        showPagination={false}
        sortable={false}
        multiSort={false}
        resizable={false}
        showPageSizeOptions={false}
        minRows={0}
    />
};

CalendarTable.propTypes = {
    data: PropTypes.array,
};

export default CalendarTable;
