import { useMemo } from "react";
import { Popup } from "semantic-ui-react";
import styled from "styled-components";
import { PaleItalicText } from "./FormRow";

const VendorActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    // width: 180px;
    flex-shrink: 0;
    position: relative;

    &.location {
        width: 100%;
        justify-content: flex-end;

        & .undelete {
            transform: translateX(0);
            position: static;
        }
    }

    &.hidden {
        visibility: hidden;
    }

    &.response-actions {
        margin-bottom: 20px;
        align-self: flex-end;
    }

    & > div {
        cursor: pointer;
    }

    .ceritfy {
        display: flex;
        i {
            color: #4fac32;
            font-size: 14px;
        }
    }

    .edit {
        display: flex;
        i.selected {
            color: #ffa800;
            font-size: 14px;
        }
    }

    .undelete {
        color: #9f3f47;
        // position: absolute;
        // transform: translateX(-120%);
        font-weight: 600;
    }
`;

export default function VendorActions({
    row,
    onCertifyClick,
    onEditClick,
    onDuplicateClick,
    onUndeleteClick,
    className,
    group,
}) {
    const authenticateButton = useMemo(() => {
        let emptyElement = <PaleItalicText></PaleItalicText>;
        if (
            row.new &&
            (!row.responded || row.submittedRevision.vendexResponseTypeCode !== "REJECT_SUBMIT_TO_VSOURCE")
        ) {
            return emptyElement;
        }

        // if(group === 'foundational' && (
        //     process.env.REACT_APP_BUILD_ENV === 'demo' ||
        //     process.env.REACT_APP_BUILD_ENV === 'prod' ||
        //     process.env.REACT_APP_BUILD_ENV === 'staging'
        // )) {
        //     return emptyElement;
        // }

        if (row.submitted && row.certified) {
            return emptyElement;
        }

        if (row.responded && row.accepted) {
            return emptyElement;
        }

        return (
            <div className="ceritfy" onClick={onCertifyClick}>
                <i className={`circle ${row.certified ? "check" : "outline"} icon`}></i>
                <span>Authenticate</span>
            </div>
        );
    }, [group, onCertifyClick, row.accepted, row.certified, row.new, row.proposed, row.responded, row.submitted]);

    const editButton = useMemo(() => {
        let emptyElement = <PaleItalicText></PaleItalicText>;
        if (row.new && row.proposed && row.responded && row.submittedRevision.vendexActionTypeCode === "REJECTED") {
            return emptyElement;
        }

        return (
            <div className="edit" onClick={onEditClick}>
                <i className={`edit outline icon ${row.proposed ? "selected" : ""}`}></i>
                <span>Edit</span>
            </div>
        );
    }, [onEditClick, row.new, row.proposed, row.responded, row.submittedRevision?.vendexActionTypeCode]);

    const undeleteButton = useMemo(() => {
        let emptyElement = <PaleItalicText></PaleItalicText>;

        if (!row.deleted) {
            return emptyElement;
        }

        if (row.responded && row.submittedRevision.vendexActionTypeCode === "REJECTED") {
            return emptyElement;
        }

        return (
            <div className="undelete" onClick={onUndeleteClick}>
                <span>Undelete</span>
            </div>
        );
    }, [onUndeleteClick, row.deleted, row.responded, row.submittedRevision?.vendexActionTypeCode]);

    const duplicateButton = useMemo(() => {
        let emptyElement = <PaleItalicText></PaleItalicText>;

        if (row.tableName !== "product_vidsv") {
            return emptyElement;
        }

        return (
            <Popup
                trigger={
                    <div className="duplicate" onClick={onDuplicateClick}>
                        <i className={`copy outline icon`}></i>
                        <span></span>
                    </div>
                }
                content={row.tableName === "product_vidsv" ? "Duplicate Grouping" : "Duplicate"}
                position="top center"
                hoverable
            />
        );
    }, [onEditClick]);

    return (
        <VendorActionsWrapper className={className + " vendor-actions"}>
            {undeleteButton}
            {authenticateButton}
            {editButton}
            {duplicateButton}
        </VendorActionsWrapper>
    );
}
