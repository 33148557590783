import React, { useState, useContext, useEffect } from "react";
import { Search } from "semantic-ui-react";
import styled from "styled-components";
import { escapeTerm } from "../../utils/textConverters";
import { VsourceContext } from "../../context/vsource/vsourceContext";
import { useParams } from "react-router-dom";
import { ButtonWrapper } from "../../components/VendBtn";
import { useDebounce } from "react-use";

const SearchInputWrapper = styled.div`
    margin: 0.5rem 0.25rem;
    min-width: 84%;
    display: flex;
    align-items: center;

    button {
        background-color: #556983;
        margin-left: 0.5rem;
        margin-right: 0;
        white-space: nowrap;
        height: 38px;
        &:hover {
            opacity: 0.95;
        }
    }

    .vendor-searchbar {
        width: 100%;

        .input {
            width: 100%;
            input {
                border-radius: 3px;
            }
        }
    }
`;

export const VendorProductSearch = ({ handleSearchChange, products }) => {
    const [inputValue, setInputValue] = useState(null);
    let { id: vendorID } = useParams();

    const {
        context: { vendorProductSearchTerm, vendorProductSearchVendId },
        setVendorProductSearchTerm,
    } = useContext(VsourceContext);

    useEffect(() => {
        // Reload keyword and search results if user returns
        if (vendorProductSearchTerm && vendorID && vendorID === vendorProductSearchVendId) {
            setInputValue(vendorProductSearchTerm);
            search({ target: { value: vendorProductSearchTerm } });
        }
    }, []);

    useEffect(() => {
        // If products list changed and keyword is entered
        if (inputValue) {
            search({ target: { value: inputValue } });
        }
        // If search input is emtpy return all values
        else {
            handleSearchChange(products);
        }
    }, [products]);

    // If user enters something into the search input
    useDebounce(
        () => {
            if (inputValue) {
                search();
            } else {
                handleSearchChange(products);
            }
        },
        300,
        [inputValue]
    );

    const search = () => {
        const searchRegex = new RegExp(escapeTerm(inputValue), "i");
        const res = products.filter((product) => {
            return product.name.search(searchRegex) !== -1 || product.detailed_description.search(searchRegex) !== -1;
        });

        // Save keyword and vendor ID to store
        setVendorProductSearchTerm(inputValue, vendorID);
        // Rerender the search results
        handleSearchChange(res);
    };

    const clearSearch = () => {
        setVendorProductSearchTerm(null, null);
        setInputValue("");
        handleSearchChange(products);
    };

    return (
        <SearchInputWrapper>
            <Search
                className={"vendor-searchbar"}
                onSearchChange={(e) => setInputValue(e.target.value)}
                value={inputValue}
                open={false}
                placeholder={"Search Product"}
            />
            <ButtonWrapper space-between onClick={clearSearch}>
                Clear Search
            </ButtonWrapper>
        </SearchInputWrapper>
    );
};
