import React from "react";

const DeleteIcon = props => (
    <svg width={20} height={20} {...props} fill="none">
        <path d="M3.33398 5H16.6673L15.3506 16.85C15.3055 17.2578 15.1115 17.6346 14.8059 17.9083C14.5002 18.182 14.1043 18.3334 13.694 18.3333H6.30732C5.89701 18.3334 5.50112 18.182 5.19544 17.9083C4.88976 17.6346 4.69578 17.2578 4.65065 16.85L3.33398 5Z" stroke="#9F3F47" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.12083 2.62246C6.25563 2.3366 6.46892 2.09496 6.73584 1.92572C7.00275 1.75648 7.31229 1.66662 7.62833 1.66663H12.3717C12.6879 1.66646 12.9976 1.75625 13.2646 1.9255C13.5317 2.09474 13.7451 2.33647 13.88 2.62246L15 4.99996H5L6.12083 2.62246Z" stroke="#9F3F47" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.66699 5H18.3337" stroke="#9F3F47" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M8.33398 9.16663V13.3333" stroke="#9F3F47" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M11.667 9.16663V13.3333" stroke="#9F3F47" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>


);

export default DeleteIcon;

