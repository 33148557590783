import metaConfig from "../../config/metaConfig.json";

const fetchMetaCategories = (options, { dispatch }) => {
    const categories = Object.keys(metaConfig);

    /*
        Adds Documents tab to display document
     */
    categories.push("Documents");

    dispatch({ type: "META_CATEGORIES_FETCHED", categories });

    return categories;
};

export default fetchMetaCategories;
