import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";
import _cloneDeep from "lodash/cloneDeep";
import * as queries from "../../graphql/queries";
import { vendorQuery } from "../../graphql/customQueries";

export const fetchVendorsList = async (params, { dispatch, newErrorAlert, vendors }) => {
    dispatch({ type: "LOADING_VENDORSLIST" });

    const vendorsListQuery = /* GraphQL */ `
        query Vendors($limit: Int, $page: Int) {
            vendors(limit: $limit, page: $page) {
                data {
                    id
                    full_legal_name
                    brief_description
                    vendor_stage_code
                }
                meta {
                    count
                    page
                    pageSize
                    pageCount
                }
            }
        }
    `;

    if (!params) params = {};

    // In case of an error return the initial data
    let res = _cloneDeep(vendors);
    try {
        const { data } = await API.graphql(graphqlOperation(vendorsListQuery, params /* , { limit: 2000 } */));

        /* data.vendors.forEach((vend, i) => {
            // If vendor is already in list then update the data
            if (res[vend.id]) {
                res[vend.id] = {
                    ...res[vend.id],
                    ...data.vendors[vend.id],
                };
            }
            // Else add to list
            else {
                res[vend.id] = vend;
            }
        }); */
        console.log("meta:", data.vendors.meta);
        dispatch({
            type: "VENDORSLIST_FETCHED",
            vendorsList: data.vendors.data,
            vendorsListMeta: data.vendors.meta,
        });
    } catch (e) {
        console.log("Fetch vendors list error:", e);
        newErrorAlert(e.message);
        dispatch({ type: "VENDORSLIST_FETCHED", vendorsList: res });
    }
};

export const fetchVendor = async (vendorId, { dispatch, newErrorAlert }) => {
    dispatch({ type: "LOADING_VENDOR" });

    let vendor = {};
    try {
        const { data } = await API.graphql(graphqlOperation(vendorQuery, { id: vendorId }));
        if (data) {
            vendor = data.vendor;
            // console.log("Vendor data: ", vendor);
            fetchVendorLogo(vendor, dispatch);
        }
    } catch (e) {
        console.log("Vendor fetch error: ", e);
        newErrorAlert(e.message);
    } finally {
        dispatch({ type: "VENDOR_FETCHED", vendor });
    }
};

const fetchVendorLogo = (vendor, dispatch) => {
    // Fetch Logo url
    if (vendor.vendor_logo_filename) {
        Storage.get(`vsource/logos/${vendor.vendor_logo_filename}`, {
            level: "public",
        })
            .then((s3ImageUrl) => {
                const vend = {
                    ...vendor,
                    logo_url: s3ImageUrl,
                };
                dispatch({ type: "VENDOR_FETCHED", vendor: vend });
            })
            .catch((e) => console.error("Error fetching logo URL: ", e));
    }
};
