import { Switch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { VLinkRoute } from "./VLinkRoute";
import { VLinkDashboard } from "../pages/VLink/Dashboard";
import VendorDetailsPage from "../pages/VLink/VendorDetails";
import VendorDetailsPreviewPage from "../pages/VLink/VendorDetailsPreview";
import VendorDetailsPublicPage from "../pages/VLink/VendorDetailsPublic";
import ProductDetailsPage from "../pages/VLink/ProductDetails";
import ProductDetailsPreviewPage from "../pages/VLink/ProductDetailsPreview";
import ProductDetailsPublicPage from "../pages/VLink/ProductDetailsPublic";

export default function VlinkRoutes({match}){
    return (
        <Switch>
            <VLinkRoute path={`${match.url}/vendors/:id`} exact component={VendorDetailsPage} />
            <VLinkRoute path={`${match.url}/vendors/:id/preview`} exact component={VendorDetailsPreviewPage} />
            <VLinkRoute path={`${match.url}/vendors/:id/public`} exact component={VendorDetailsPublicPage} />
            <VLinkRoute path={`${match.url}/products/:id`} exact component={ProductDetailsPage} />
            <VLinkRoute path={`${match.url}/products/:id/preview`} exact component={ProductDetailsPreviewPage} />
            <PrivateRoute path={`${match.url}/products/:id/public`} exact component={ProductDetailsPublicPage} />
            <VLinkRoute path={`${match.url}/dashboard`} component={VLinkDashboard} />
        </Switch>
    );
}