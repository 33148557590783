import React, { useMemo } from "react";
import _sortBy from "lodash/sortBy";
import styled from "styled-components/macro";
// import * as PropTypes from "prop-types";
import CalendarTable from "../CalendarTable/CalendarTable";

const CalendarViewWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
`;

const CalendarTableWrapper = styled.div`
    width: 100%;
    overflow-y: auto;
`;

const CalendarTitle = styled.h5`
    width: 100%;
    margin-bottom: 5%;
    font-size: 1.3rem;
    color: #464e5f;
`;

const calendarMockData = [
    {
        endDate: "2023-11-30T08:15:00.000Z",
        vendorName: {
            title: "DebtWire",
            link: "/vport/dashboard",
        },
        eventName: {
            title: "DebtWire",
            link: "/vport/dashboard",
        },
    },
    {
        endDate: "2023-11-30T08:15:00.000Z",
        vendorName: {
            title: "DebtWire",
        },
        eventName: {
            title: "DebtWire",
        },
    },
    {
        endDate: "2023-12-05T08:15:00.000Z",
        vendorName: {
            title: "DebtWire",
            link: "/vport/dashboard",
        },
        eventName: {
            title: "DebtWire",
            link: "/vport/dashboard",
        },
    },
];

export const CalendarView = ({}) => {
    const sortedByDateData = useMemo(() => {
        return _sortBy(calendarMockData, (dateObj) => new Date(dateObj.endDate));
    }, [calendarMockData]);
    return (
        <CalendarViewWrapper>
            <CalendarTitle>Important Upcoming Dates</CalendarTitle>
            <CalendarTableWrapper>
                <CalendarTable data={sortedByDateData} />
            </CalendarTableWrapper>
        </CalendarViewWrapper>
    );
};

CalendarView.propTypes = {};

export default CalendarView;
