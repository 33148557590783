/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addVendor = /* GraphQL */ `
  mutation AddVendor(
    $full_legal_name: String!
    $hierarchy_code: String!
    $lei_code: String!
    $status_code: String!
    $company_type_code: String!
    $brief_description: String!
    $assigned_analyst: String!
  ) {
    addVendor(
      full_legal_name: $full_legal_name
      hierarchy_code: $hierarchy_code
      lei_code: $lei_code
      status_code: $status_code
      company_type_code: $company_type_code
      brief_description: $brief_description
      assigned_analyst: $assigned_analyst
    )
  }
`;
export const deleteVendor = /* GraphQL */ `
  mutation DeleteVendor($id: ID!) {
    deleteVendor(id: $id)
  }
`;
export const updateVendor = /* GraphQL */ `
  mutation UpdateVendor(
    $id: ID!
    $full_legal_name: String!
    $assigned_analyst: String
    $brief_description: String
    $detailed_description: String
    $status_code: String
    $company_type_code: String!
    $year_founded: Int
    $headquarters: String
    $number_of_offices: Int
    $next_review_at: String
    $next_review_in_days_code: String
    $market_segments: String!
    $company_size_code: String
    $last_reviewed_at: String
    $last_reviewed_by: String
    $last_published_by: String
    $last_published_at: String
    $hierarchy_code: String
    $immediate_parent_name: String
    $immediate_parent_vendor_id_code: String
    $immediate_parent_hierarchy_code: String
    $ultimate_parent_name: String
    $ultimate_parent_vendor_id_code: String
    $ultimate_parent_hierarchy_code: String
    $lei_code: String
    $url: String
    $historical_names: String
    $alias_names: String
    $corporate_actions: String
    $financials: String
    $tickers: String
    $social_media: String
    $investor_relations: String
    $vendor_logo_filename: String
    $officers: String
    $locations: String
    $vendor_stage_code: String!
    $publish_source: String!
  ) {
    updateVendor(
      id: $id
      full_legal_name: $full_legal_name
      assigned_analyst: $assigned_analyst
      brief_description: $brief_description
      detailed_description: $detailed_description
      status_code: $status_code
      company_type_code: $company_type_code
      year_founded: $year_founded
      headquarters: $headquarters
      number_of_offices: $number_of_offices
      next_review_at: $next_review_at
      next_review_in_days_code: $next_review_in_days_code
      market_segments: $market_segments
      company_size_code: $company_size_code
      last_reviewed_at: $last_reviewed_at
      last_reviewed_by: $last_reviewed_by
      last_published_by: $last_published_by
      last_published_at: $last_published_at
      hierarchy_code: $hierarchy_code
      immediate_parent_name: $immediate_parent_name
      immediate_parent_vendor_id_code: $immediate_parent_vendor_id_code
      immediate_parent_hierarchy_code: $immediate_parent_hierarchy_code
      ultimate_parent_name: $ultimate_parent_name
      ultimate_parent_vendor_id_code: $ultimate_parent_vendor_id_code
      ultimate_parent_hierarchy_code: $ultimate_parent_hierarchy_code
      lei_code: $lei_code
      url: $url
      historical_names: $historical_names
      alias_names: $alias_names
      corporate_actions: $corporate_actions
      financials: $financials
      tickers: $tickers
      social_media: $social_media
      investor_relations: $investor_relations
      vendor_logo_filename: $vendor_logo_filename
      officers: $officers
      locations: $locations
      vendor_stage_code: $vendor_stage_code
      publish_source: $publish_source
    )
  }
`;
export const addVendorProduct = /* GraphQL */ `
  mutation AddVendorProduct(
    $name: String!
    $detailed_description: String
    $url: String
    $demo_url: String
    $brief_description: String
    $status_code: String!
    $type_code: String
    $change_time: String
    $change_notes: String
    $vendor_id: ID!
  ) {
    addVendorProduct(
      name: $name
      detailed_description: $detailed_description
      url: $url
      demo_url: $demo_url
      brief_description: $brief_description
      status_code: $status_code
      type_code: $type_code
      change_time: $change_time
      change_notes: $change_notes
      vendor_id: $vendor_id
    ) {
      status
      product_id
    }
  }
`;
export const updateVendorProduct = /* GraphQL */ `
  mutation UpdateVendorProduct(
    $id: ID!
    $name: String!
    $detailed_description: String
    $url: String
    $demo_url: String
    $brief_description: String
    $status_code: String!
    $type_code: String
    $change_time: String
    $change_notes: String
    $vendor_id: ID!
  ) {
    updateVendorProduct(
      id: $id
      name: $name
      detailed_description: $detailed_description
      url: $url
      demo_url: $demo_url
      brief_description: $brief_description
      status_code: $status_code
      type_code: $type_code
      change_time: $change_time
      change_notes: $change_notes
      vendor_id: $vendor_id
    )
  }
`;
export const deleteVendorProduct = /* GraphQL */ `
  mutation DeleteVendorProduct($id: ID!) {
    deleteVendorProduct(id: $id)
  }
`;
export const sendVendorUpdateRequestEmail = /* GraphQL */ `
  mutation SendVendorUpdateRequestEmail(
    $user_email: String!
    $vendor_name: String!
    $categories: [String]
    $content: String
    $url: String
  ) {
    sendVendorUpdateRequestEmail(
      user_email: $user_email
      vendor_name: $vendor_name
      categories: $categories
      content: $content
      url: $url
    )
  }
`;
export const createAgreementMeta = /* GraphQL */ `
  mutation CreateAgreementMeta($input: CreateAgreementMetaInput!) {
    createAgreementMeta(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      contractEffectiveDate
      agreementType
      effectiveDateMsa
      executionDate
      licenseTypeDescription
      productServiceOrderNumber
      additionOfServices
      disputeHandling
      vendorWebSite
      definitions {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          term
          definition
          groupsCanAccess
        }
        nextToken
      }
      primarySubscriber {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      primaryVendor {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSalesRep {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      dataProviderChangeProvisions
      dataUsageLimit
      deliveryMethod
      electronicProductAddress
      notificationOfChangesTerms
      numberOfLicenses
      permittedUsesOfVendorProduct
      physicalProductAddress {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      prohibitedLocations
      prohibitedUses
      subscriberPostTerminationRightsAndObligations
      vendorPostTerminationRightsAndObligations
      thirdParty {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      dataRedistributionRights {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      cancellationNoticePeriod
      breachOfContractEvents
      cancellationNoticeDeadline
      contractEndDate
      earliestRenewalNoticeDate
      forceMajeureTermination
      terminationEvents
      lengthOfContract
      lengthOfContractBasis
      subscriberChangeInControl
      subscriberChangeInControlDate
      subscriberChangeInControlProductTerminationDate
      vendorChangeInControl
      vendorChangeInControlDate
      automaticRenewal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      indemnificationLanguage
      reciprocalIndemnification
      rightsAndObligationsForClaims
      subscriberIndemnification
      subscriberInjunctionRemedies
      subscriberLiabilityCap
      suscriberRestructuringProvisions
      thirdPartyRecourse
      vendorDataAccuracyRepresentations
      vendorIndemnification
      vendorInjunctionRemedies
      vendorLiabilityCap
      vendorRestructuringProvisions
      informationCollection
      ipInfringementRemediation
      sourceAttribution
      vendorUseOfDataRightsManagement
      usageInspection {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      creationOfBackupMaterials
      subscriberUsageReporting
      vendorInspectionPayment
      auditTerms {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      usageMonitoring {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      confidentialDataTreatment
      informationSecurity
      notificationOfChanges
      vendorAdvanceNoticeRequirements
      subscriberAdvanceNoticeRequirements
      advanceNoticeRequirements
      antiCorruptionAml
      forceMajeure
      tradeRestrictions
      jurisdiction
      productAndPricingGroups {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          product {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            productName
            briefDescription
            vendExProductId
            productDescription
            serviceType
            groupsCanAccess
          }
          vSourceProduct {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          productVSourceId
          productAndPricingGroupProductId
          quantity
          currency
          documentProductName
          licenseFee
          billingStartDate
          billingEndDate
          invoiceFrequency
          invoiceTerms
          additionalRecurringFeeS
          oneTimeFeeS
          totalFees
          licensedLocationS
          licensedUserS
          prohibitedLocationS
          prohibitedUsersS
          licenseType
          maximumDataUsage
          status
          generalTerms
          productStartDate
          productEndDate
          productOrServiceOrderNumber
          cancellationNoticePeriod
          cancellationNoticeDeadline
          earliestRenewalNoticeDate
          automaticRenewal
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const updateAgreementMeta = /* GraphQL */ `
  mutation UpdateAgreementMeta($input: UpdateAgreementMetaInput!) {
    updateAgreementMeta(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      contractEffectiveDate
      agreementType
      effectiveDateMsa
      executionDate
      licenseTypeDescription
      productServiceOrderNumber
      additionOfServices
      disputeHandling
      vendorWebSite
      definitions {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          term
          definition
          groupsCanAccess
        }
        nextToken
      }
      primarySubscriber {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      primaryVendor {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSalesRep {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      dataProviderChangeProvisions
      dataUsageLimit
      deliveryMethod
      electronicProductAddress
      notificationOfChangesTerms
      numberOfLicenses
      permittedUsesOfVendorProduct
      physicalProductAddress {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      prohibitedLocations
      prohibitedUses
      subscriberPostTerminationRightsAndObligations
      vendorPostTerminationRightsAndObligations
      thirdParty {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      dataRedistributionRights {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      cancellationNoticePeriod
      breachOfContractEvents
      cancellationNoticeDeadline
      contractEndDate
      earliestRenewalNoticeDate
      forceMajeureTermination
      terminationEvents
      lengthOfContract
      lengthOfContractBasis
      subscriberChangeInControl
      subscriberChangeInControlDate
      subscriberChangeInControlProductTerminationDate
      vendorChangeInControl
      vendorChangeInControlDate
      automaticRenewal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      indemnificationLanguage
      reciprocalIndemnification
      rightsAndObligationsForClaims
      subscriberIndemnification
      subscriberInjunctionRemedies
      subscriberLiabilityCap
      suscriberRestructuringProvisions
      thirdPartyRecourse
      vendorDataAccuracyRepresentations
      vendorIndemnification
      vendorInjunctionRemedies
      vendorLiabilityCap
      vendorRestructuringProvisions
      informationCollection
      ipInfringementRemediation
      sourceAttribution
      vendorUseOfDataRightsManagement
      usageInspection {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      creationOfBackupMaterials
      subscriberUsageReporting
      vendorInspectionPayment
      auditTerms {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      usageMonitoring {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      confidentialDataTreatment
      informationSecurity
      notificationOfChanges
      vendorAdvanceNoticeRequirements
      subscriberAdvanceNoticeRequirements
      advanceNoticeRequirements
      antiCorruptionAml
      forceMajeure
      tradeRestrictions
      jurisdiction
      productAndPricingGroups {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          product {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            productName
            briefDescription
            vendExProductId
            productDescription
            serviceType
            groupsCanAccess
          }
          vSourceProduct {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          productVSourceId
          productAndPricingGroupProductId
          quantity
          currency
          documentProductName
          licenseFee
          billingStartDate
          billingEndDate
          invoiceFrequency
          invoiceTerms
          additionalRecurringFeeS
          oneTimeFeeS
          totalFees
          licensedLocationS
          licensedUserS
          prohibitedLocationS
          prohibitedUsersS
          licenseType
          maximumDataUsage
          status
          generalTerms
          productStartDate
          productEndDate
          productOrServiceOrderNumber
          cancellationNoticePeriod
          cancellationNoticeDeadline
          earliestRenewalNoticeDate
          automaticRenewal
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const deleteAgreementMeta = /* GraphQL */ `
  mutation DeleteAgreementMeta($input: DeleteAgreementMetaInput!) {
    deleteAgreementMeta(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      contractEffectiveDate
      agreementType
      effectiveDateMsa
      executionDate
      licenseTypeDescription
      productServiceOrderNumber
      additionOfServices
      disputeHandling
      vendorWebSite
      definitions {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          term
          definition
          groupsCanAccess
        }
        nextToken
      }
      primarySubscriber {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      primaryVendor {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSalesRep {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      dataProviderChangeProvisions
      dataUsageLimit
      deliveryMethod
      electronicProductAddress
      notificationOfChangesTerms
      numberOfLicenses
      permittedUsesOfVendorProduct
      physicalProductAddress {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      prohibitedLocations
      prohibitedUses
      subscriberPostTerminationRightsAndObligations
      vendorPostTerminationRightsAndObligations
      thirdParty {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      dataRedistributionRights {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      cancellationNoticePeriod
      breachOfContractEvents
      cancellationNoticeDeadline
      contractEndDate
      earliestRenewalNoticeDate
      forceMajeureTermination
      terminationEvents
      lengthOfContract
      lengthOfContractBasis
      subscriberChangeInControl
      subscriberChangeInControlDate
      subscriberChangeInControlProductTerminationDate
      vendorChangeInControl
      vendorChangeInControlDate
      automaticRenewal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      indemnificationLanguage
      reciprocalIndemnification
      rightsAndObligationsForClaims
      subscriberIndemnification
      subscriberInjunctionRemedies
      subscriberLiabilityCap
      suscriberRestructuringProvisions
      thirdPartyRecourse
      vendorDataAccuracyRepresentations
      vendorIndemnification
      vendorInjunctionRemedies
      vendorLiabilityCap
      vendorRestructuringProvisions
      informationCollection
      ipInfringementRemediation
      sourceAttribution
      vendorUseOfDataRightsManagement
      usageInspection {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      creationOfBackupMaterials
      subscriberUsageReporting
      vendorInspectionPayment
      auditTerms {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      usageMonitoring {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      confidentialDataTreatment
      informationSecurity
      notificationOfChanges
      vendorAdvanceNoticeRequirements
      subscriberAdvanceNoticeRequirements
      advanceNoticeRequirements
      antiCorruptionAml
      forceMajeure
      tradeRestrictions
      jurisdiction
      productAndPricingGroups {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          product {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            productName
            briefDescription
            vendExProductId
            productDescription
            serviceType
            groupsCanAccess
          }
          vSourceProduct {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          productVSourceId
          productAndPricingGroupProductId
          quantity
          currency
          documentProductName
          licenseFee
          billingStartDate
          billingEndDate
          invoiceFrequency
          invoiceTerms
          additionalRecurringFeeS
          oneTimeFeeS
          totalFees
          licensedLocationS
          licensedUserS
          prohibitedLocationS
          prohibitedUsersS
          licenseType
          maximumDataUsage
          status
          generalTerms
          productStartDate
          productEndDate
          productOrServiceOrderNumber
          cancellationNoticePeriod
          cancellationNoticeDeadline
          earliestRenewalNoticeDate
          automaticRenewal
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const createProductAndPricingGroup = /* GraphQL */ `
  mutation CreateProductAndPricingGroup(
    $input: CreateProductAndPricingGroupInput!
  ) {
    createProductAndPricingGroup(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      product {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      vSourceProduct {
        id
        vendor_product_id
        name
        vendor_specific_product_details
        brief_description
        preview_brief_description
        detailed_description
        url
        demo_url
        status
        status_code_original
        product_id_code
        product_change {
          id
          change_time
          change_notes
          change_type
          change_type_original
        }
        vendor {
          id
          vendor_id_code
          last_reviewed_at
          last_reviewed_by
          last_reviewed_by_code
          last_published_at
          last_published_by
          last_published_by_code
          assigned_analyst
          assigned_analyst_code
          next_review_in_days
          next_review_in_days_code
          next_review_at
          full_legal_name
          historical_names
          alias_names
          url
          hierarchy_code
          status_code
          vendor_logo_filename
          ultimate_parent_id
          ultimate_parent_vendor_id_code
          ultimate_parent_hierarchy_code
          ultimate_parent_name
          immediate_parent_id
          immediate_parent_vendor_id_code
          immediate_parent_name
          immediate_parent_hierarchy_code
          brief_description
          preview_brief_description
          detailed_description
          year_founded
          company_type_code
          number_of_offices
          company_size_code
          headquarters
          lei_code
          created_by
          created_at
          updated_by
          updated_at
          products {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          financials {
            id
            year
            revenue
            period_end_date
            revenue_amount
            currency
            currency_code
            created_at
            updated_at
          }
          tickers {
            id
            stock_exchange
            ticker_symbol
            created_by
            created_at
            updated_by
            updated_at
          }
          officers {
            id
            business_role
            business_role_code
            full_name
            phone_number
            email_address
            linkedin_url
            comment
            business_role_additional
          }
          social_media {
            id
            social_media_type
            social_media_type_code
            url
          }
          locations {
            id
            name
            type
            type_code
            region
            region_code
            division
            city
            country
            country_code
            street_address_1
            street_address_2
            state_province
            postal_code
            phone_number
            customer_support {
              id
              phone_number
              support_hours
              email_address
              support_portal_url
              time_zone
              time_zone_code
            }
          }
          market_segments {
            code
            value
          }
          investor_relations {
            id
            contact_person
            phone_number
            email_address
            blog_url
          }
          corporate_actions {
            id
            type_code
            type
            action_time
            description
          }
          vendor_stage_code
          vendor_stage
          premium_benefit
        }
        premium_benefit
      }
      productVSourceId
      productAndPricingGroupProductId
      quantity
      currency
      documentProductName
      licenseFee
      billingStartDate
      billingEndDate
      invoiceFrequency
      invoiceTerms
      additionalRecurringFeeS
      oneTimeFeeS
      totalFees
      licensedLocationS
      licensedUserS
      prohibitedLocationS
      prohibitedUsersS
      licenseType
      maximumDataUsage
      status
      generalTerms
      productStartDate
      productEndDate
      productOrServiceOrderNumber
      cancellationNoticePeriod
      cancellationNoticeDeadline
      earliestRenewalNoticeDate
      automaticRenewal
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const updateProductAndPricingGroup = /* GraphQL */ `
  mutation UpdateProductAndPricingGroup(
    $input: UpdateProductAndPricingGroupInput!
  ) {
    updateProductAndPricingGroup(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      product {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      vSourceProduct {
        id
        vendor_product_id
        name
        vendor_specific_product_details
        brief_description
        preview_brief_description
        detailed_description
        url
        demo_url
        status
        status_code_original
        product_id_code
        product_change {
          id
          change_time
          change_notes
          change_type
          change_type_original
        }
        vendor {
          id
          vendor_id_code
          last_reviewed_at
          last_reviewed_by
          last_reviewed_by_code
          last_published_at
          last_published_by
          last_published_by_code
          assigned_analyst
          assigned_analyst_code
          next_review_in_days
          next_review_in_days_code
          next_review_at
          full_legal_name
          historical_names
          alias_names
          url
          hierarchy_code
          status_code
          vendor_logo_filename
          ultimate_parent_id
          ultimate_parent_vendor_id_code
          ultimate_parent_hierarchy_code
          ultimate_parent_name
          immediate_parent_id
          immediate_parent_vendor_id_code
          immediate_parent_name
          immediate_parent_hierarchy_code
          brief_description
          preview_brief_description
          detailed_description
          year_founded
          company_type_code
          number_of_offices
          company_size_code
          headquarters
          lei_code
          created_by
          created_at
          updated_by
          updated_at
          products {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          financials {
            id
            year
            revenue
            period_end_date
            revenue_amount
            currency
            currency_code
            created_at
            updated_at
          }
          tickers {
            id
            stock_exchange
            ticker_symbol
            created_by
            created_at
            updated_by
            updated_at
          }
          officers {
            id
            business_role
            business_role_code
            full_name
            phone_number
            email_address
            linkedin_url
            comment
            business_role_additional
          }
          social_media {
            id
            social_media_type
            social_media_type_code
            url
          }
          locations {
            id
            name
            type
            type_code
            region
            region_code
            division
            city
            country
            country_code
            street_address_1
            street_address_2
            state_province
            postal_code
            phone_number
            customer_support {
              id
              phone_number
              support_hours
              email_address
              support_portal_url
              time_zone
              time_zone_code
            }
          }
          market_segments {
            code
            value
          }
          investor_relations {
            id
            contact_person
            phone_number
            email_address
            blog_url
          }
          corporate_actions {
            id
            type_code
            type
            action_time
            description
          }
          vendor_stage_code
          vendor_stage
          premium_benefit
        }
        premium_benefit
      }
      productVSourceId
      productAndPricingGroupProductId
      quantity
      currency
      documentProductName
      licenseFee
      billingStartDate
      billingEndDate
      invoiceFrequency
      invoiceTerms
      additionalRecurringFeeS
      oneTimeFeeS
      totalFees
      licensedLocationS
      licensedUserS
      prohibitedLocationS
      prohibitedUsersS
      licenseType
      maximumDataUsage
      status
      generalTerms
      productStartDate
      productEndDate
      productOrServiceOrderNumber
      cancellationNoticePeriod
      cancellationNoticeDeadline
      earliestRenewalNoticeDate
      automaticRenewal
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const deleteProductAndPricingGroup = /* GraphQL */ `
  mutation DeleteProductAndPricingGroup(
    $input: DeleteProductAndPricingGroupInput!
  ) {
    deleteProductAndPricingGroup(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      product {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      vSourceProduct {
        id
        vendor_product_id
        name
        vendor_specific_product_details
        brief_description
        preview_brief_description
        detailed_description
        url
        demo_url
        status
        status_code_original
        product_id_code
        product_change {
          id
          change_time
          change_notes
          change_type
          change_type_original
        }
        vendor {
          id
          vendor_id_code
          last_reviewed_at
          last_reviewed_by
          last_reviewed_by_code
          last_published_at
          last_published_by
          last_published_by_code
          assigned_analyst
          assigned_analyst_code
          next_review_in_days
          next_review_in_days_code
          next_review_at
          full_legal_name
          historical_names
          alias_names
          url
          hierarchy_code
          status_code
          vendor_logo_filename
          ultimate_parent_id
          ultimate_parent_vendor_id_code
          ultimate_parent_hierarchy_code
          ultimate_parent_name
          immediate_parent_id
          immediate_parent_vendor_id_code
          immediate_parent_name
          immediate_parent_hierarchy_code
          brief_description
          preview_brief_description
          detailed_description
          year_founded
          company_type_code
          number_of_offices
          company_size_code
          headquarters
          lei_code
          created_by
          created_at
          updated_by
          updated_at
          products {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          financials {
            id
            year
            revenue
            period_end_date
            revenue_amount
            currency
            currency_code
            created_at
            updated_at
          }
          tickers {
            id
            stock_exchange
            ticker_symbol
            created_by
            created_at
            updated_by
            updated_at
          }
          officers {
            id
            business_role
            business_role_code
            full_name
            phone_number
            email_address
            linkedin_url
            comment
            business_role_additional
          }
          social_media {
            id
            social_media_type
            social_media_type_code
            url
          }
          locations {
            id
            name
            type
            type_code
            region
            region_code
            division
            city
            country
            country_code
            street_address_1
            street_address_2
            state_province
            postal_code
            phone_number
            customer_support {
              id
              phone_number
              support_hours
              email_address
              support_portal_url
              time_zone
              time_zone_code
            }
          }
          market_segments {
            code
            value
          }
          investor_relations {
            id
            contact_person
            phone_number
            email_address
            blog_url
          }
          corporate_actions {
            id
            type_code
            type
            action_time
            description
          }
          vendor_stage_code
          vendor_stage
          premium_benefit
        }
        premium_benefit
      }
      productVSourceId
      productAndPricingGroupProductId
      quantity
      currency
      documentProductName
      licenseFee
      billingStartDate
      billingEndDate
      invoiceFrequency
      invoiceTerms
      additionalRecurringFeeS
      oneTimeFeeS
      totalFees
      licensedLocationS
      licensedUserS
      prohibitedLocationS
      prohibitedUsersS
      licenseType
      maximumDataUsage
      status
      generalTerms
      productStartDate
      productEndDate
      productOrServiceOrderNumber
      cancellationNoticePeriod
      cancellationNoticeDeadline
      earliestRenewalNoticeDate
      automaticRenewal
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct($input: CreateProductInput!) {
    createProduct(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      productName
      briefDescription
      vendExProductId
      productDescription
      serviceType
      groupsCanAccess
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($input: UpdateProductInput!) {
    updateProduct(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      productName
      briefDescription
      vendExProductId
      productDescription
      serviceType
      groupsCanAccess
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($input: DeleteProductInput!) {
    deleteProduct(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      productName
      briefDescription
      vendExProductId
      productDescription
      serviceType
      groupsCanAccess
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact($input: CreateContactInput!) {
    createContact(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      name
      title
      phone
      email
      groupsCanAccess
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact($input: UpdateContactInput!) {
    updateContact(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      name
      title
      phone
      email
      groupsCanAccess
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact($input: DeleteContactInput!) {
    deleteContact(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      name
      title
      phone
      email
      groupsCanAccess
    }
  }
`;
export const createDefinitionTerm = /* GraphQL */ `
  mutation CreateDefinitionTerm($input: CreateDefinitionTermInput!) {
    createDefinitionTerm(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      term
      definition
      groupsCanAccess
    }
  }
`;
export const updateDefinitionTerm = /* GraphQL */ `
  mutation UpdateDefinitionTerm($input: UpdateDefinitionTermInput!) {
    updateDefinitionTerm(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      term
      definition
      groupsCanAccess
    }
  }
`;
export const deleteDefinitionTerm = /* GraphQL */ `
  mutation DeleteDefinitionTerm($input: DeleteDefinitionTermInput!) {
    deleteDefinitionTerm(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      term
      definition
      groupsCanAccess
    }
  }
`;
export const createAddress = /* GraphQL */ `
  mutation CreateAddress($input: CreateAddressInput!) {
    createAddress(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      address1
      address2
      zip
      city
      state
      country
      groupsCanAccess
    }
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      address1
      address2
      zip
      city
      state
      country
      groupsCanAccess
    }
  }
`;
export const deleteAddress = /* GraphQL */ `
  mutation DeleteAddress($input: DeleteAddressInput!) {
    deleteAddress(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      address1
      address2
      zip
      city
      state
      country
      groupsCanAccess
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      vendorName
      vendorId
      subscriberName
      customerFileName
      customerDescription
      documentUploadTimestamp
      uploadedBy
      documentDescription
      documentTitle
      docAgreementName
      docEndDate
      docNumberOfPages
      docProductNames
      docVendorName
      uploadDate
      documentCompletionTimestampByVendexDataAnalyst
      vendExDataAnalyst
      vendExApproverDateAndTimestamp
      vendExApprover
      serviceAttachmentInternalId
      thirdPartyDocumentId
      vendExDocumentId
      vendExFileName
      vendorOrderNumber
      dummy
      migrate
      id
      agreements {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      vendorName
      vendorId
      subscriberName
      customerFileName
      customerDescription
      documentUploadTimestamp
      uploadedBy
      documentDescription
      documentTitle
      docAgreementName
      docEndDate
      docNumberOfPages
      docProductNames
      docVendorName
      uploadDate
      documentCompletionTimestampByVendexDataAnalyst
      vendExDataAnalyst
      vendExApproverDateAndTimestamp
      vendExApprover
      serviceAttachmentInternalId
      thirdPartyDocumentId
      vendExDocumentId
      vendExFileName
      vendorOrderNumber
      dummy
      migrate
      id
      agreements {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument($input: DeleteDocumentInput!) {
    deleteDocument(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      vendorName
      vendorId
      subscriberName
      customerFileName
      customerDescription
      documentUploadTimestamp
      uploadedBy
      documentDescription
      documentTitle
      docAgreementName
      docEndDate
      docNumberOfPages
      docProductNames
      docVendorName
      uploadDate
      documentCompletionTimestampByVendexDataAnalyst
      vendExDataAnalyst
      vendExApproverDateAndTimestamp
      vendExApprover
      serviceAttachmentInternalId
      thirdPartyDocumentId
      vendExDocumentId
      vendExFileName
      vendorOrderNumber
      dummy
      migrate
      id
      agreements {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const createDataRedistributionRights = /* GraphQL */ `
  mutation CreateDataRedistributionRights(
    $input: CreateDataRedistributionRightsInput!
  ) {
    createDataRedistributionRights(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      dataRedistributionTerms
      dataRedistributionPricing
      groupsCanAccess
    }
  }
`;
export const updateDataRedistributionRights = /* GraphQL */ `
  mutation UpdateDataRedistributionRights(
    $input: UpdateDataRedistributionRightsInput!
  ) {
    updateDataRedistributionRights(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      dataRedistributionTerms
      dataRedistributionPricing
      groupsCanAccess
    }
  }
`;
export const deleteDataRedistributionRights = /* GraphQL */ `
  mutation DeleteDataRedistributionRights(
    $input: DeleteDataRedistributionRightsInput!
  ) {
    deleteDataRedistributionRights(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      dataRedistributionTerms
      dataRedistributionPricing
      groupsCanAccess
    }
  }
`;
export const createAuditTerms = /* GraphQL */ `
  mutation CreateAuditTerms($input: CreateAuditTermsInput!) {
    createAuditTerms(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      auditTerms
      auditFrequency
      groupsCanAccess
    }
  }
`;
export const updateAuditTerms = /* GraphQL */ `
  mutation UpdateAuditTerms($input: UpdateAuditTermsInput!) {
    updateAuditTerms(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      auditTerms
      auditFrequency
      groupsCanAccess
    }
  }
`;
export const deleteAuditTerms = /* GraphQL */ `
  mutation DeleteAuditTerms($input: DeleteAuditTermsInput!) {
    deleteAuditTerms(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      auditTerms
      auditFrequency
      groupsCanAccess
    }
  }
`;
export const createThirdParty = /* GraphQL */ `
  mutation CreateThirdParty($input: CreateThirdPartyInput!) {
    createThirdParty(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      thirdParty
      thirdPartyProviders
      thirdPartyObligations
      groupsCanAccess
    }
  }
`;
export const updateThirdParty = /* GraphQL */ `
  mutation UpdateThirdParty($input: UpdateThirdPartyInput!) {
    updateThirdParty(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      thirdParty
      thirdPartyProviders
      thirdPartyObligations
      groupsCanAccess
    }
  }
`;
export const deleteThirdParty = /* GraphQL */ `
  mutation DeleteThirdParty($input: DeleteThirdPartyInput!) {
    deleteThirdParty(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      thirdParty
      thirdPartyProviders
      thirdPartyObligations
      groupsCanAccess
    }
  }
`;
export const createUsageInspection = /* GraphQL */ `
  mutation CreateUsageInspection($input: CreateUsageInspectionInput!) {
    createUsageInspection(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageInspectionNotice
      usageInspectionFrequency
      groupsCanAccess
    }
  }
`;
export const updateUsageInspection = /* GraphQL */ `
  mutation UpdateUsageInspection($input: UpdateUsageInspectionInput!) {
    updateUsageInspection(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageInspectionNotice
      usageInspectionFrequency
      groupsCanAccess
    }
  }
`;
export const deleteUsageInspection = /* GraphQL */ `
  mutation DeleteUsageInspection($input: DeleteUsageInspectionInput!) {
    deleteUsageInspection(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageInspectionNotice
      usageInspectionFrequency
      groupsCanAccess
    }
  }
`;
export const createAutomaticRenewal = /* GraphQL */ `
  mutation CreateAutomaticRenewal($input: CreateAutomaticRenewalInput!) {
    createAutomaticRenewal(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const updateAutomaticRenewal = /* GraphQL */ `
  mutation UpdateAutomaticRenewal($input: UpdateAutomaticRenewalInput!) {
    updateAutomaticRenewal(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const deleteAutomaticRenewal = /* GraphQL */ `
  mutation DeleteAutomaticRenewal($input: DeleteAutomaticRenewalInput!) {
    deleteAutomaticRenewal(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const createReplacementContract = /* GraphQL */ `
  mutation CreateReplacementContract($input: CreateReplacementContractInput!) {
    createReplacementContract(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      replacementContractNumber
      groupsCanAccess
    }
  }
`;
export const updateReplacementContract = /* GraphQL */ `
  mutation UpdateReplacementContract($input: UpdateReplacementContractInput!) {
    updateReplacementContract(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      replacementContractNumber
      groupsCanAccess
    }
  }
`;
export const deleteReplacementContract = /* GraphQL */ `
  mutation DeleteReplacementContract($input: DeleteReplacementContractInput!) {
    deleteReplacementContract(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      replacementContractNumber
      groupsCanAccess
    }
  }
`;
export const createUsageMonitoring = /* GraphQL */ `
  mutation CreateUsageMonitoring($input: CreateUsageMonitoringInput!) {
    createUsageMonitoring(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageReportDates
      usageReportPeriods
      groupsCanAccess
    }
  }
`;
export const updateUsageMonitoring = /* GraphQL */ `
  mutation UpdateUsageMonitoring($input: UpdateUsageMonitoringInput!) {
    updateUsageMonitoring(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageReportDates
      usageReportPeriods
      groupsCanAccess
    }
  }
`;
export const deleteUsageMonitoring = /* GraphQL */ `
  mutation DeleteUsageMonitoring($input: DeleteUsageMonitoringInput!) {
    deleteUsageMonitoring(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageReportDates
      usageReportPeriods
      groupsCanAccess
    }
  }
`;
export const createAgreement = /* GraphQL */ `
  mutation CreateAgreement($input: CreateAgreementInput!) {
    createAgreement(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      vendorId
      masterProduct
      documents {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      meta {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      vendorName
      documentVendorName
      vendorVSourceId
      groupsCanAccess
    }
  }
`;
export const updateAgreement = /* GraphQL */ `
  mutation UpdateAgreement($input: UpdateAgreementInput!) {
    updateAgreement(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      vendorId
      masterProduct
      documents {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      meta {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      vendorName
      documentVendorName
      vendorVSourceId
      groupsCanAccess
    }
  }
`;
export const deleteAgreement = /* GraphQL */ `
  mutation DeleteAgreement($input: DeleteAgreementInput!) {
    deleteAgreement(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      vendorId
      masterProduct
      documents {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      meta {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      vendorName
      documentVendorName
      vendorVSourceId
      groupsCanAccess
    }
  }
`;
export const createAgreementDocuments = /* GraphQL */ `
  mutation CreateAgreementDocuments($input: CreateAgreementDocumentsInput!) {
    createAgreementDocuments(input: $input) {
      id
      document {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      agreement {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        vendorId
        masterProduct
        documents {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        meta {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          contractEffectiveDate
          agreementType
          effectiveDateMsa
          executionDate
          licenseTypeDescription
          productServiceOrderNumber
          additionOfServices
          disputeHandling
          vendorWebSite
          definitions {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              term
              definition
              groupsCanAccess
            }
            nextToken
          }
          primarySubscriber {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          primaryVendor {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSalesRep {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          dataProviderChangeProvisions
          dataUsageLimit
          deliveryMethod
          electronicProductAddress
          notificationOfChangesTerms
          numberOfLicenses
          permittedUsesOfVendorProduct
          physicalProductAddress {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            address1
            address2
            zip
            city
            state
            country
            groupsCanAccess
          }
          prohibitedLocations
          prohibitedUses
          subscriberPostTerminationRightsAndObligations
          vendorPostTerminationRightsAndObligations
          thirdParty {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            thirdParty
            thirdPartyProviders
            thirdPartyObligations
            groupsCanAccess
          }
          dataRedistributionRights {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            dataRedistributionTerms
            dataRedistributionPricing
            groupsCanAccess
          }
          cancellationNoticePeriod
          breachOfContractEvents
          cancellationNoticeDeadline
          contractEndDate
          earliestRenewalNoticeDate
          forceMajeureTermination
          terminationEvents
          lengthOfContract
          lengthOfContractBasis
          subscriberChangeInControl
          subscriberChangeInControlDate
          subscriberChangeInControlProductTerminationDate
          vendorChangeInControl
          vendorChangeInControlDate
          automaticRenewal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          indemnificationLanguage
          reciprocalIndemnification
          rightsAndObligationsForClaims
          subscriberIndemnification
          subscriberInjunctionRemedies
          subscriberLiabilityCap
          suscriberRestructuringProvisions
          thirdPartyRecourse
          vendorDataAccuracyRepresentations
          vendorIndemnification
          vendorInjunctionRemedies
          vendorLiabilityCap
          vendorRestructuringProvisions
          informationCollection
          ipInfringementRemediation
          sourceAttribution
          vendorUseOfDataRightsManagement
          usageInspection {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageInspectionNotice
            usageInspectionFrequency
            groupsCanAccess
          }
          creationOfBackupMaterials
          subscriberUsageReporting
          vendorInspectionPayment
          auditTerms {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            auditTerms
            auditFrequency
            groupsCanAccess
          }
          usageMonitoring {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageReportDates
            usageReportPeriods
            groupsCanAccess
          }
          confidentialDataTreatment
          informationSecurity
          notificationOfChanges
          vendorAdvanceNoticeRequirements
          subscriberAdvanceNoticeRequirements
          advanceNoticeRequirements
          antiCorruptionAml
          forceMajeure
          tradeRestrictions
          jurisdiction
          productAndPricingGroups {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productVSourceId
              productAndPricingGroupProductId
              quantity
              currency
              documentProductName
              licenseFee
              billingStartDate
              billingEndDate
              invoiceFrequency
              invoiceTerms
              additionalRecurringFeeS
              oneTimeFeeS
              totalFees
              licensedLocationS
              licensedUserS
              prohibitedLocationS
              prohibitedUsersS
              licenseType
              maximumDataUsage
              status
              generalTerms
              productStartDate
              productEndDate
              productOrServiceOrderNumber
              cancellationNoticePeriod
              cancellationNoticeDeadline
              earliestRenewalNoticeDate
              automaticRenewal
              automaticRenewalDate
              automaticRenewalNoticeDate
              automaticRenewalTerms
              groupsCanAccess
            }
            nextToken
          }
          groupsCanAccess
        }
        vendorName
        documentVendorName
        vendorVSourceId
        groupsCanAccess
      }
      createdAt
      updatedAt
      groupsCanAccess
    }
  }
`;
export const updateAgreementDocuments = /* GraphQL */ `
  mutation UpdateAgreementDocuments($input: UpdateAgreementDocumentsInput!) {
    updateAgreementDocuments(input: $input) {
      id
      document {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      agreement {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        vendorId
        masterProduct
        documents {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        meta {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          contractEffectiveDate
          agreementType
          effectiveDateMsa
          executionDate
          licenseTypeDescription
          productServiceOrderNumber
          additionOfServices
          disputeHandling
          vendorWebSite
          definitions {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              term
              definition
              groupsCanAccess
            }
            nextToken
          }
          primarySubscriber {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          primaryVendor {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSalesRep {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          dataProviderChangeProvisions
          dataUsageLimit
          deliveryMethod
          electronicProductAddress
          notificationOfChangesTerms
          numberOfLicenses
          permittedUsesOfVendorProduct
          physicalProductAddress {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            address1
            address2
            zip
            city
            state
            country
            groupsCanAccess
          }
          prohibitedLocations
          prohibitedUses
          subscriberPostTerminationRightsAndObligations
          vendorPostTerminationRightsAndObligations
          thirdParty {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            thirdParty
            thirdPartyProviders
            thirdPartyObligations
            groupsCanAccess
          }
          dataRedistributionRights {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            dataRedistributionTerms
            dataRedistributionPricing
            groupsCanAccess
          }
          cancellationNoticePeriod
          breachOfContractEvents
          cancellationNoticeDeadline
          contractEndDate
          earliestRenewalNoticeDate
          forceMajeureTermination
          terminationEvents
          lengthOfContract
          lengthOfContractBasis
          subscriberChangeInControl
          subscriberChangeInControlDate
          subscriberChangeInControlProductTerminationDate
          vendorChangeInControl
          vendorChangeInControlDate
          automaticRenewal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          indemnificationLanguage
          reciprocalIndemnification
          rightsAndObligationsForClaims
          subscriberIndemnification
          subscriberInjunctionRemedies
          subscriberLiabilityCap
          suscriberRestructuringProvisions
          thirdPartyRecourse
          vendorDataAccuracyRepresentations
          vendorIndemnification
          vendorInjunctionRemedies
          vendorLiabilityCap
          vendorRestructuringProvisions
          informationCollection
          ipInfringementRemediation
          sourceAttribution
          vendorUseOfDataRightsManagement
          usageInspection {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageInspectionNotice
            usageInspectionFrequency
            groupsCanAccess
          }
          creationOfBackupMaterials
          subscriberUsageReporting
          vendorInspectionPayment
          auditTerms {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            auditTerms
            auditFrequency
            groupsCanAccess
          }
          usageMonitoring {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageReportDates
            usageReportPeriods
            groupsCanAccess
          }
          confidentialDataTreatment
          informationSecurity
          notificationOfChanges
          vendorAdvanceNoticeRequirements
          subscriberAdvanceNoticeRequirements
          advanceNoticeRequirements
          antiCorruptionAml
          forceMajeure
          tradeRestrictions
          jurisdiction
          productAndPricingGroups {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productVSourceId
              productAndPricingGroupProductId
              quantity
              currency
              documentProductName
              licenseFee
              billingStartDate
              billingEndDate
              invoiceFrequency
              invoiceTerms
              additionalRecurringFeeS
              oneTimeFeeS
              totalFees
              licensedLocationS
              licensedUserS
              prohibitedLocationS
              prohibitedUsersS
              licenseType
              maximumDataUsage
              status
              generalTerms
              productStartDate
              productEndDate
              productOrServiceOrderNumber
              cancellationNoticePeriod
              cancellationNoticeDeadline
              earliestRenewalNoticeDate
              automaticRenewal
              automaticRenewalDate
              automaticRenewalNoticeDate
              automaticRenewalTerms
              groupsCanAccess
            }
            nextToken
          }
          groupsCanAccess
        }
        vendorName
        documentVendorName
        vendorVSourceId
        groupsCanAccess
      }
      createdAt
      updatedAt
      groupsCanAccess
    }
  }
`;
export const deleteAgreementDocuments = /* GraphQL */ `
  mutation DeleteAgreementDocuments($input: DeleteAgreementDocumentsInput!) {
    deleteAgreementDocuments(input: $input) {
      id
      document {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      agreement {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        vendorId
        masterProduct
        documents {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        meta {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          contractEffectiveDate
          agreementType
          effectiveDateMsa
          executionDate
          licenseTypeDescription
          productServiceOrderNumber
          additionOfServices
          disputeHandling
          vendorWebSite
          definitions {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              term
              definition
              groupsCanAccess
            }
            nextToken
          }
          primarySubscriber {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          primaryVendor {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSalesRep {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          dataProviderChangeProvisions
          dataUsageLimit
          deliveryMethod
          electronicProductAddress
          notificationOfChangesTerms
          numberOfLicenses
          permittedUsesOfVendorProduct
          physicalProductAddress {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            address1
            address2
            zip
            city
            state
            country
            groupsCanAccess
          }
          prohibitedLocations
          prohibitedUses
          subscriberPostTerminationRightsAndObligations
          vendorPostTerminationRightsAndObligations
          thirdParty {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            thirdParty
            thirdPartyProviders
            thirdPartyObligations
            groupsCanAccess
          }
          dataRedistributionRights {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            dataRedistributionTerms
            dataRedistributionPricing
            groupsCanAccess
          }
          cancellationNoticePeriod
          breachOfContractEvents
          cancellationNoticeDeadline
          contractEndDate
          earliestRenewalNoticeDate
          forceMajeureTermination
          terminationEvents
          lengthOfContract
          lengthOfContractBasis
          subscriberChangeInControl
          subscriberChangeInControlDate
          subscriberChangeInControlProductTerminationDate
          vendorChangeInControl
          vendorChangeInControlDate
          automaticRenewal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          indemnificationLanguage
          reciprocalIndemnification
          rightsAndObligationsForClaims
          subscriberIndemnification
          subscriberInjunctionRemedies
          subscriberLiabilityCap
          suscriberRestructuringProvisions
          thirdPartyRecourse
          vendorDataAccuracyRepresentations
          vendorIndemnification
          vendorInjunctionRemedies
          vendorLiabilityCap
          vendorRestructuringProvisions
          informationCollection
          ipInfringementRemediation
          sourceAttribution
          vendorUseOfDataRightsManagement
          usageInspection {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageInspectionNotice
            usageInspectionFrequency
            groupsCanAccess
          }
          creationOfBackupMaterials
          subscriberUsageReporting
          vendorInspectionPayment
          auditTerms {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            auditTerms
            auditFrequency
            groupsCanAccess
          }
          usageMonitoring {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageReportDates
            usageReportPeriods
            groupsCanAccess
          }
          confidentialDataTreatment
          informationSecurity
          notificationOfChanges
          vendorAdvanceNoticeRequirements
          subscriberAdvanceNoticeRequirements
          advanceNoticeRequirements
          antiCorruptionAml
          forceMajeure
          tradeRestrictions
          jurisdiction
          productAndPricingGroups {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productVSourceId
              productAndPricingGroupProductId
              quantity
              currency
              documentProductName
              licenseFee
              billingStartDate
              billingEndDate
              invoiceFrequency
              invoiceTerms
              additionalRecurringFeeS
              oneTimeFeeS
              totalFees
              licensedLocationS
              licensedUserS
              prohibitedLocationS
              prohibitedUsersS
              licenseType
              maximumDataUsage
              status
              generalTerms
              productStartDate
              productEndDate
              productOrServiceOrderNumber
              cancellationNoticePeriod
              cancellationNoticeDeadline
              earliestRenewalNoticeDate
              automaticRenewal
              automaticRenewalDate
              automaticRenewalNoticeDate
              automaticRenewalTerms
              groupsCanAccess
            }
            nextToken
          }
          groupsCanAccess
        }
        vendorName
        documentVendorName
        vendorVSourceId
        groupsCanAccess
      }
      createdAt
      updatedAt
      groupsCanAccess
    }
  }
`;
export const createEntities = /* GraphQL */ `
  mutation CreateEntities($input: CreateEntitiesInput!) {
    createEntities(input: $input) {
      id
      name
      clientID
      groupName
      logoUrl
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const updateEntities = /* GraphQL */ `
  mutation UpdateEntities($input: UpdateEntitiesInput!) {
    updateEntities(input: $input) {
      id
      name
      clientID
      groupName
      logoUrl
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const deleteEntities = /* GraphQL */ `
  mutation DeleteEntities($input: DeleteEntitiesInput!) {
    deleteEntities(input: $input) {
      id
      name
      clientID
      groupName
      logoUrl
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const createPost = /* GraphQL */ `
  mutation CreatePost($input: CreatePostInput!) {
    createPost(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      title
      description
      content
      id
      activeFrom
      activeTo
      type
    }
  }
`;
export const updatePost = /* GraphQL */ `
  mutation UpdatePost($input: UpdatePostInput!) {
    updatePost(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      title
      description
      content
      id
      activeFrom
      activeTo
      type
    }
  }
`;
export const deletePost = /* GraphQL */ `
  mutation DeletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      title
      description
      content
      id
      activeFrom
      activeTo
      type
    }
  }
`;
export const createUserStat = /* GraphQL */ `
  mutation CreateUserStat($input: CreateUserStatInput!) {
    createUserStat(input: $input) {
      id
      username
      userId
      updatedBy
      updatedAt
      createdBy
      createdAt
      type
      value
    }
  }
`;
export const updateUserStat = /* GraphQL */ `
  mutation UpdateUserStat($input: UpdateUserStatInput!) {
    updateUserStat(input: $input) {
      id
      username
      userId
      updatedBy
      updatedAt
      createdBy
      createdAt
      type
      value
    }
  }
`;
export const deleteUserStat = /* GraphQL */ `
  mutation DeleteUserStat($input: DeleteUserStatInput!) {
    deleteUserStat(input: $input) {
      id
      username
      userId
      updatedBy
      updatedAt
      createdBy
      createdAt
      type
      value
    }
  }
`;
