import React from "react";

const BuildingIcon = props => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.5 1.5H4.5C3.67275 1.5 3 2.17275 3 3V15.75C3 15.9489 3.07902 16.1397 3.21967 16.2803C3.36032 16.421 3.55109 16.5 3.75 16.5H14.25C14.4489 16.5 14.6397 16.421 14.7803 16.2803C14.921 16.1397 15 15.9489 15 15.75V3C15 2.17275 14.3273 1.5 13.5 1.5ZM13.5 15H4.5V3H13.5V15Z" fill="#9F3F47"/>
            <path d="M6 4.5H8.25V6H6V4.5ZM9.75 4.5H12V6H9.75V4.5ZM6 7.5H8.25V9H6V7.5ZM9.75 7.52325H12V9H9.75V7.52325ZM6 10.5H8.25V12H6V10.5ZM9.75 10.5H12V12H9.75V10.5Z" fill="#9F3F47"/>
        </svg>
    );
};

export default BuildingIcon;
