import API, { graphqlOperation } from "@aws-amplify/api";
import { agreementsByGroupAccess, vendorsBy } from "../../graphql/queries";
import { filter, orderBy, uniq } from "lodash";

const fetchSummaryList = async (options, { dispatch, activeEntity }) => {
    dispatch({ type: "FETCH_SUMMARY_LIST" });

    let vendorsList = [];
    let agreements = [];
    let errors = [];
    try {
        // Agreements list from VKey (DynamoDB)
        let {
            data: {
                agreementsByGroupAccess: { items: _agreements },
            },
        } = await API.graphql(
            graphqlOperation(agreementsByGroupAccess, { limit: 2000, groupsCanAccess: activeEntity?.groupName })
        );

        agreements = _agreements;

        // Vendors data from VSource
        const vSourceIDs = uniq(
            agreements
                .filter((el) => el.vendorVSourceId !== "" && el.vendorVSourceId !== null)
                .map((el) => el.vendorVSourceId)
        ).join(",");

        if (vSourceIDs.length > 0) {
            try {
                const {
                    data: {
                        vendorsBy: { data: _vendorsList },
                    },
                } = await API.graphql(
                    graphqlOperation(vendorsBy, {
                        param: "ids",
                        value: vSourceIDs,
                        orderBy: "full_legal_name",
                        order: "ASC",
                    })
                );

                vendorsList = _vendorsList;
            } catch (e) {
                console.log("Error fetching VSource vendors list:", e);
            }
        }
    } catch (e) {
        console.log("Fetch Summary List Error:", e);
        errors.push("Error fetching summary list");
    }

    vendorsList = vendorsList.reduce((memo, vendor) => {
        memo[vendor.id] = vendor;
        return memo;
    }, {});

    let missingLinksCount = 0;
    // Map VSource vendors data to the agreements
    agreements = agreements.map((agreement) => {
        // If the VSource link (ID) is missing then use empty or "0" values
        if (!agreement.vendorVSourceId) {
            console.log("Missing ID:", agreement);
            missingLinksCount += 1;
        }
        return {
            ...agreement,
            vendorName: vendorsList[agreement.vendorVSourceId]?.full_legal_name || "unknown",
            vendorId: vendorsList[agreement.vendorVSourceId]?.vendor_id_code || 0,
            vendorVSourceId: agreement.vendorVSourceId || 0,
        };
    });

    if (missingLinksCount > 0) {
        errors.push(
            `Missing VSource link for ${missingLinksCount} ${missingLinksCount === 1 ? "agreement" : "agreements"}`
        );
    }
    const totalAgreements = agreements.length;

    const totalDocuments = agreements.reduce((memo, a) => {
        memo += a.documents.items.length;
        return memo;
    }, 0);

    const totalVendors = Object.keys(vendorsList).length;

    const stats = {
        totalAgreements,
        totalDocuments,
        totalVendors,
    };

    const productsByVendor = agreements.reduce((memo, agreement) => {
        const { id, masterProduct, documents, meta } = agreement;
        if (memo[agreement.vendorVSourceId]) {
            const { products } = memo[agreement.vendorVSourceId];
            memo[agreement.vendorVSourceId] = {
                ...memo[agreement.vendorVSourceId],
                products: [
                    {
                        masterProduct,
                        documents,
                        meta,
                        id,
                    },
                    ...products,
                ],
                documents,
            };
        } else {
            memo[agreement.vendorVSourceId] = {
                vendorName: vendorsList[agreement.vendorVSourceId]?.full_legal_name || "",
                vendorId: vendorsList[agreement.vendorVSourceId]?.vendor_id_code || "",
                products: [{ masterProduct, documents, id, meta }],
                documents,
            };
        }
        return memo;
    }, {});

    const list = Object.keys(vendorsList).map((vendorID) => {
        const { full_legal_name, vendor_id_code } = vendorsList[vendorID];

        const documentsLength = productsByVendor[vendorID].products.reduce((memo, pItem) => {
            memo += pItem.documents.items.length;
            return memo;
        }, 0);

        const products = productsByVendor[vendorID].products.map((product) => {
            return {
                agreementId: filter(agreements, {
                    vendorVSourceId: vendorID,
                    masterProduct: product.masterProduct,
                })[0].id,
                ...product,
            };
        });

        return {
            vendorName: full_legal_name,
            vendorId: vendor_id_code,
            vendorVSourceID: vendorID,
            products,
            documents: documentsLength,
        };
    });

    console.log("options.sort : ", options.sort);

    const sortedList = orderBy(list, ["vendorName"], [options.sort ? "asc" : "desc"]);

    console.log("From fetchSummaryList vendorsList : ", stats, sortedList);

    const summaryList = {
        stats,
        list: sortedList,
        errors,
    };

    dispatch({ type: "SUMMARY_LIST_FETCHED", summaryList });

    return summaryList;
};

export default fetchSummaryList;
