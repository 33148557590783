import React, { useContext, useState, useEffect, useCallback } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";
import styled from "styled-components";
import { Card as SemanticCard, Icon, Popup } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import _orderBy from "lodash/orderBy";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { PageWrapper as _PageWrapper } from "../../components/PageWrapper";
import { truncateText } from "../../utils/textConverters";
import { useAlerts } from "../../hooks/useAlerts";
import { WarningBox } from "../../components/MessageBox";
import AddNewProductModal from "../../components/vlink/AddNewProductModal";
import DefaultLogo from "../../images/default_logo.svg";

import { AuthContext } from "../../context/AuthContext";
import getSortedClassifiersByType from "../../utils/getSortedClassifiersByType";

const PageWrapper = styled(_PageWrapper)`
    padding: 16px 40px;
    h1 {
        color: rgba(0, 0, 0, 0.87);
        font-size: 1.8rem !important;
        font-weight: 700;
    }

    h2 {
        font-size: 1.3rem !important;
        font-weight: 600;
        margin: calc(2rem - 0.14285714em) 0 1rem;
    }

    .block-header {
        margin: 5rem 0 1rem 0;
    }

    .vendor-cards {
        display: flex;
        margin-top: 1rem;
        font-size: 14px;
        div {
            margin: 0 0.8rem;
        }
    }

    .product-cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .add-product-body {
            width: 100%;
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .vendor-cards, .product-cards {
        .card-link {
            color: ${({ theme }) => theme.primaryBlue};
            cursor: pointer;
            text-align: center;
        }
    }
`;

const LogoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;

  img {
        width: 100%;
        height: auto;

      &.default-logo {
        height: 4.5rem;
      }
    }
`;

const vendorDataQuery = /* GraphQL */ `
    query vendor($id: ID!) {
        vendor(id: $id) {
            id
            full_legal_name
            vendor_id_code
            brief_description
            vendor_logo_filename
        }
    }
`;

const VendorAuthenticationQuery = gql`
    query VendorAuthenticationData($authenticationVendorStateId: ID!) {
        vlink {
            authenticationVendorState(id: $authenticationVendorStateId) {
                fieldGroupType
                authenticatedCount
                unauthenticatedCount
                pendingCount
                totalCount
            }
        }
    }
`;

const VendorProductsQuery = gql`
    query VendorProducts($productsAuthId: ID!) {
        vlink {
            productsAuth(id: $productsAuthId) {
                productId
                name
                briefDescription
                authenticatedCount
                unauthenticatedCount
                pendingCount
                status_code
            }
        }
    }
`;

const dotsTooltipTexts = {
    green: 'Authenticated by Vendor',
    red: 'Unauthenticated',
    yellow: 'In Progress'
};

export const VLinkDashboard = () => {
    const {
        context: { activeVendor },
    } = useContext(AuthContext);

    const [vendorData, setVendorData] = useState();
    const [vendorDataLoading, setVendorLoading] = useState(true);
    const [vendorProductsList, setVendorProductsList] = useState(null);
    const [filteredProductsList, setFilteredProductsList] = useState(null);
    const [authQueryError, setAuthQueryError] = useState(null);
    const [productsQueryError, setProductsQueryError] = useState(null);
    const [addProductModalOpened, setAddProductModalOpened] = useState(false);
    const [logoUrl, setLogoUrl] = useState(null);
    const [logoLoading, setLogoLoading] = useState(false);
    const [productStatusClassifiers, setProductStatusClassifiers] = useState([]);
    const [logoError, setLogoError] = useState(false);

    const { loading: authQueryLoading, error: _authQueryError, data: vendorAuthData } = useQuery(
        VendorAuthenticationQuery,
        {
            variables: { authenticationVendorStateId: activeVendor?.id },
            skip: !activeVendor?.id,
        }
    );

    const { loading: productsQueryLoading, error: _productsQueryError, data: vendorProductsData, refetch: refetchProductsList } = useQuery(
        VendorProductsQuery,
        {
            variables: { productsAuthId: activeVendor?.id },
            skip: !activeVendor?.id,
        }
    );

    const { newErrorAlert } = useAlerts();
    const history = useHistory();

    useEffect(() => {
        if (!activeVendor?.id) {
            return;
        }

        loadVendorData(activeVendor.id);
    }, [activeVendor, loadVendorData]);
    
    useEffect(() => {
        if(vendorData){
            // Fetch Logo url
            if (vendorData.vendor_logo_filename) {
                setLogoLoading(true);

                Storage.get(`vsource/logos/${vendorData.vendor_logo_filename}`, {
                    level: "public",
                })
                    .then((s3ImageUrl) => {
                        console.log('logo url:', s3ImageUrl);
                        setLogoUrl(s3ImageUrl);
                    })
                    .catch((e) => console.error("Error fetching logo URL: ", e));

                    setLogoLoading(false);
            } else{
                setLogoUrl(null);
            }
    }
    },[vendorData, logoUrl])

    useEffect(() => {
        if (!productsQueryLoading) {
            setVendorProductsList(vendorProductsData?.vlink?.productsAuth);
            setFilteredProductsList(_orderBy(
                vendorProductsData?.vlink?.productsAuth, [product => product.name.toLowerCase().trim()], ['asc']
            ));
        }
    }, [productsQueryLoading, vendorProductsData]);

    const loadVendorData = useCallback(
        async (id) => {
            try {
                setVendorLoading(true);

                const {
                    data: { vendor },
                } = await API.graphql(
                    graphqlOperation(vendorDataQuery, {
                        id,
                    })
                );

                setVendorData(vendor);
            } catch (e) {
                console.error("Error fetching vendor data from VSource:", e);
                newErrorAlert(e.message ?? "Error fetching vendor data from VSource");
            } finally {
                setVendorLoading(false);
            }
        },
        [newErrorAlert]
    );

    useEffect(() => {
        async function fetchProductStatusClassifiers() {
            try {
                const { data } = await getSortedClassifiersByType('PRODUCT_STATUS');
                if (data && data.listClassifiersByType) {
                   setProductStatusClassifiers(data.listClassifiersByType);
                }
            } catch (e) {
                console.log("Classifier fetch error: ", e);
            }
        }
        
        fetchProductStatusClassifiers();
    }, []);

    const productsAuthenticatedPercentage = () => {
        if (vendorProductsList) {
            const summary = vendorProductsList.reduce(
                (previous, current) => {
                    const res = previous;
                    if (current.pendingCount > 0) {
                        res.pending = res.pending + 1;
                    } else if (current.unauthenticatedCount === 0) {
                        res.authenticated = res.authenticated + 1;
                    } 
                    
                    return res;
                },
                { total: vendorProductsList.length, authenticated: 0, pending: 0 }
            );

            return {
                authenticated: summary.authenticated === 0 ? 0 : Math.ceil((parseInt(summary.authenticated, 10) / parseInt(summary.total, 10)) * 100),
                pending: summary.pending === 0 ? 0 : Math.ceil((parseInt(summary.pending, 10) / parseInt(summary.total, 10)) * 100),
            }
        }

        return null;
    };

    if (_authQueryError) {
        // If the same error is already thrown then skip it
        if (authQueryError !== _authQueryError) {
            const ErrorMsg = "Error fetching vendor authentication data";
            newErrorAlert(ErrorMsg);
            setAuthQueryError(ErrorMsg);
        }
    }
    if (_productsQueryError) {
        // If the same error is already thrown then skip it
        if (productsQueryError !== _productsQueryError) {
            const ErrorMsg = "Error fetching products authentication data";
            newErrorAlert(ErrorMsg);
            setProductsQueryError(ErrorMsg);
        }
    }

    if (!activeVendor?.id && !productsQueryLoading && !vendorDataLoading) {
        return <WarningBox message="You are not assigned to any vendor yet." />;
    }

    const onNewProductModalClose = () => {
        setAddProductModalOpened(false);
        refetchProductsList();
    }

    const getColorForProductCard = (product) => {
        if (product.pendingCount !== 0) {
            return "#FFC000"
        } else if (product.unauthenticatedCount !== 0) {
            return "#B00020";
        } else {
            return "green"
        }
    }
    const getTooltipTitle = (product) => {
        if (product.pendingCount !== 0) {
            return dotsTooltipTexts.yellow
        } else if (product.unauthenticatedCount !== 0) {
            return dotsTooltipTexts.red
        } else {
            return dotsTooltipTexts.green
        }
    }
    const filtrationCards = (status) => {
        switch (status) {
            case dotsTooltipTexts.yellow:
                setFilteredProductsList(vendorProductsData?.vlink?.productsAuth.filter((item) => {
                    return item.pendingCount !== 0;
                }))
                break;
            case dotsTooltipTexts.red:
                setFilteredProductsList(vendorProductsData?.vlink?.productsAuth.filter((item) => {
                    return item.unauthenticatedCount !== 0 && item.pendingCount === 0;
                }));
                break;
            default:
                setFilteredProductsList(vendorProductsData?.vlink?.productsAuth);
        }
    }

    return (
        <PageWrapper>
            <AddNewProductModal show={addProductModalOpened} onClose={onNewProductModalClose} vendorId={activeVendor?.id}/>
            <h1>Dashboard</h1>
            <div className="block-header">
                <h2>Vendor Profile</h2>
            </div>
            <div className="vendor-cards">
                <Card
                    body={
                        <LogoWrapper>
                            {
                                !logoError ? <img src={logoUrl ?? DefaultLogo}
                                                  className={logoUrl ? '' : 'default-logo'}
                                                  alt={`${vendorData?.full_legal_name} logo`} onError={() => {
                                    setLogoError(true)
                                }} /> : <img className="default-logo" src={DefaultLogo} alt="default-logo" />
                            }
                        </LogoWrapper>
                    }
                    height="300px"
                    loading={logoLoading}
                />
                <Card
                    header={<VendorCardHeader name={vendorData?.full_legal_name} logoUrl="" />}
                    body={
                        <Popup
                            trigger={<p>{truncateText(vendorData?.brief_description, 30)}</p>}
                            content={vendorData?.brief_description}
                            position="top center"
                            hoverable
                        />
                    }
                    height="300px"
                    loading={vendorDataLoading}
                />
                <Card
                    header="Vendor Authentication Status"
                    height="300px"
                    body={<VendorFieldsCardBody data={vendorAuthData?.vlink?.authenticationVendorState} />}
                    loading={authQueryLoading}
                    extra={
                        <div className="card-link">
                            <span onClick={() => history.push(`/vlink/vendors/${activeVendor?.id}`)}>
                                View and Edit Fields
                            </span>
                        </div>
                    }
                />
                <Card
                    header="Product Authentication Status"
                    height="300px"
                    contentHeight="180px"
                    body={<ProductsAuthenticatedBody percentage={productsAuthenticatedPercentage()} onClick={filtrationCards} />}
                    loading={productsQueryLoading}
                />
            </div>
            <div className="block-header">
                <h2>Products {filteredProductsList ? `(${filteredProductsList.length})` : ""}</h2>
            </div>
            <div className="product-cards">
                <Card
                    body={<div className={"add-product-body"}><AddRoundedIcon style={{ fontSize: 130, color: "#eee" }} /></div>}
                    height="300px"
                    width="250px"
                    dense="true"
                    color={"transparent"}
                    style={{ margin: "1rem" }}
                    onClick={() => setAddProductModalOpened(true)}
                    extra={
                        <div className="card-link">
                            <span onClick={() => setAddProductModalOpened(true)}>
                                Add New Product
                            </span>
                        </div>
                    }
                />
                {filteredProductsList && filteredProductsList.length > 0 && (
                    <>
                        {filteredProductsList.map((product, i) => (
                            <Card
                                key={`prod-${i}`}
                                header={
                                    <Popup
                                        trigger={
                                            <span>
                                                <VendorCardHeader
                                                    name={generateProductCardHeader(product, productStatusClassifiers)}
                                                    textTransform="none"
                                                />
                                            </span>
                                        }
                                        content={getTooltipTitle(product)}
                                        position="top center"
                                        hoverable
                                    />
                                }
                                body={
                                    <Popup
                                        trigger={<p>{truncateText(product?.briefDescription, 30) || ""}</p>}
                                        content={product?.briefDescription || ""}
                                        position="top center"
                                        hoverable
                                    />
                                }
                                height="300px"
                                width="250px"
                                contentHeight="160px"
                                dense
                                loading={productsQueryLoading}
                                color={getColorForProductCard(product)}
                                style={{ margin: "1rem" }}
                                onClick={() => history.push(`/vlink/products/${product?.productId}`)}
                                extra={
                                    <div className="card-link visible-on-hover">
                                        <span onClick={() => history.push(`/vlink/products/${product?.productId}`)}>
                                            View and Edit Fields
                                        </span>
                                    </div>
                                }
                            />
                        ))}
                    </>
                )}
            </div>
        </PageWrapper>
    );
};

const CardWrapper = styled.div`
    cursor: ${(props) => (props.onClick ? "pointer" : "initial")};
    .ui.card {
        &.dashboard-card {
            box-shadow: 7px 7px 17px 5px rgb(0 0 0 / 5%);
            width: ${(props) => (props.width ? props.width : "290px")};
            height: ${(props) => (props.height ? props.height : "auto")};
            border-top: ${(props) => (props.color ? `5px solid ${props.color}` : "none")};

            .visible-on-hover{
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.3s linear;
            }

            &:hover {
                .visible-on-hover{
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        & > .content:not(.extra) {
            padding: ${props => props.dense ? '1.5rem 1.5rem 0' : '2.5rem 1.5rem'};
            & > .header:not(.ui) {
                font-size: 16px;
                margin-bottom: 0;
            }
        }

        & > .content.extra {
            border-top: none !important;
        }
    }
`;

const CardPlaceHolder = styled.div`
    min-width: 290px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SemanticCardDescription = styled(SemanticCard.Description)`
    height: ${props => props.height || '140px'};
    overflow-y: hidden;
`

const Card = ({ width, dense, contentHeight, height, loading, href, header, body, extra, color, style, onClick }) => {
    if (loading) {
        return (
            <CardPlaceHolder>
                <Icon loading name="spinner" />
            </CardPlaceHolder>
        );
    }

    return (
        <CardWrapper
            width={width || "290px"}
            height={height || "auto"}
            color={color}
            style={style}
            onClick={onClick ? () => onClick() : null}
            dense={dense}
        >
            <SemanticCard className="dashboard-card" href={href} width={100}>
                <SemanticCard.Content>
                    <SemanticCard.Header>{header}</SemanticCard.Header>
                    <SemanticCardDescription height={contentHeight}>{body}</SemanticCardDescription>
                </SemanticCard.Content>
                {extra && <SemanticCard.Content extra>{extra}</SemanticCard.Content>}
            </SemanticCard>
        </CardWrapper>
    );
};

const VendorCardHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;

    div:first-child {
        display: flex;
        justify-content: center;

        .vendor-name {
            text-transform: ${(props) => props.textTransform ?? "uppercase"};
            font-size: 14px;
        }
        & > img {
            display: none;
        }
    }
    div:nth-child(2) {
        display: flex;
        justify-content: center;
        .line {
            display: block;
            width: 200px;
            height: 1px;
            border-bottom: 1px solid ${({ theme }) => theme.gray1};
            margin-top: 1.2rem;
        }
    }
`;

const VendorCardHeader = ({ name, logoUrl, textTransform }) => {
    return (
        <VendorCardHeaderWrapper textTransform={textTransform || "uppercase"}>
            <div>
                <span className="vendor-name">{name}</span>
                {/* <img className="vendor-logo" alt={`${name}-logo`} src={logoUrl} /> */}
            </div>
            <div>
                <span className="line" />
            </div>
        </VendorCardHeaderWrapper>
    );
};

const CircleWrapper = styled.div`
    margin-top: 2.5rem !important;
    .flex-wrapper {
        display: flex;
        flex-flow: row nowrap;
    }

    .single-chart {
        width: 100%;
        justify-content: space-around;
    }

    .circular-chart {
        display: block;
        margin: 10px auto;
        max-width: 80%;
        max-height: 250px;
    }

    .circle-bg {
        cursor:pointer;
        fill: none;
        stroke: ${({ theme }) => theme.error};
        stroke-width: 2.6;
    }

    .circle {
        cursor: pointer;
        fill: none;
        stroke-width: 2.8;
        stroke-linecap: round;
        animation: progress 1s ease-out forwards;
    }

    .circle.red {
        stroke: ${({ theme }) => theme.error};
    }

    .circle.yellow {
        stroke: ${({ theme }) => theme.yellow1};
    }

    .circle.green {
        stroke: green;
        stroke-width: 3;
    }

    @keyframes progress {
        0% {
            stroke-dasharray: 0 100;
        }
    }

    .circular-chart.orange .circle {
        stroke: #ff9f00;
    }

    .circular-chart.green .circle {
        stroke: green;
    }

    .circular-chart.blue .circle {
        stroke: #3c9ee5;
    }

    .percentage {
        font-size: 0.5em;
        text-anchor: middle;
    }
`;

const ProductsAuthenticatedBody = ({ percentage, onClick }) => {
    if (percentage == null) {
        return null;
    }

    return (
        <CircleWrapper>
            <div className="flex-wrapper">
                <div className="single-chart">
                    <svg viewBox="0 0 36 36" className="circular-chart">
                        <Popup

                            basic
                            hoverable
                            content={dotsTooltipTexts.red}
                            pinned
                            position="top left"
                            trigger={
                            <path
                                onClick={()=> {onClick(dotsTooltipTexts.red)}}
                                className="circle-bg"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                        } />
                        
                        {/* {!percentage.authenticated && !percentage.pending && (
                            <Popup basic hoverable content={dotsTooltipTexts.red} pinned position="top left" trigger={
                                <path
                                    className="circle red"
                                    strokeDasharray={`0 100`}
                                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                            } />
                        )} */}
                        {percentage.pending && (
                            <Popup basic hoverable content={dotsTooltipTexts.yellow} pinned position="top left" trigger={
                                <path
                                    onClick={()=> {onClick(dotsTooltipTexts.yellow)}}
                                    className="circle yellow"
                                    strokeDasharray={`${percentage.pending + percentage.authenticated} 100`}
                                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                            } />
                            
                        )}
                        {percentage.authenticated && (
                            <Popup basic hoverable content={dotsTooltipTexts.green} pinned position="top left" trigger={
                                <path
                                    onClick={()=> {onClick(dotsTooltipTexts.green)}}
                                    className="circle green"
                                    strokeDasharray={`${percentage.authenticated} 100`}
                                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                />
                            } />
                        )}
                        <text x="18" y="20.35" className="percentage">
                            {percentage.authenticated}%
                        </text>
                    </svg>
                </div>
            </div>
        </CircleWrapper>
    );
};

const VendorFieldsTable = styled.table`
    width: 100%;
    tr {
        td {
            padding: 0.5rem 0;
        }
    }
    .dot {
        height: 15px;
        width: 15px;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;

        &.green {
            background-color: ${({ theme }) => "green"};
        }
        &.yellow {
            background-color: ${({ theme }) => theme.yellow1};
        }
        &.red {
            background-color: ${({ theme }) => theme.error};
        }
    }
`;

const VendorFieldsCardBody = ({ data }) => {
    if (!data) {
        return null;
    }

    const auth = data.reduce((previous, current) => {
        const res = previous;
        res[current.fieldGroupType] = {};
        if (current.pendingCount !== 0) {
            res[current.fieldGroupType].authenticated = "yellow"
        } else if (current.unauthenticatedCount !== 0) {
            res[current.fieldGroupType].authenticated = "red"
        } else {
            res[current.fieldGroupType].authenticated = "green"
        }
        return res;
    }, {});

    return (
        <VendorFieldsTable>
            <tbody>
                <tr>
                    <td>Name</td>
                    <td>
                        <VendorAuthenticatedStatusIcon status={auth["NAME"].authenticated} />
                    </td>
                </tr>
                <tr>
                    <td>Brief Description</td>
                    <td>
                        <VendorAuthenticatedStatusIcon status={auth["BRIEF_DESC"].authenticated} />
                    </td>
                </tr>
                <tr>
                    <td>Full Description</td>
                    <td>
                        <VendorAuthenticatedStatusIcon status={auth["DETAILED_DESC"].authenticated} />
                    </td>
                </tr>
                <tr>
                    <td>Foundational Data</td>
                    <td>
                        <VendorAuthenticatedStatusIcon status={auth["FOUNDATIONAL_DATA"].authenticated} />
                    </td>
                </tr>
            </tbody>
        </VendorFieldsTable>
    );
};

const VendorAuthenticatedStatusIcon = ({ status = "green" }) => {
    return (
        <Popup basic hoverable content={dotsTooltipTexts[status]} position="top left" trigger={<div className={`dot ${status}`} />} />
    );
};

const generateProductCardHeader = (product, classifiers) => {
    if(!product?.name) return "";

    let classifier;
    if(classifiers.length) {
        classifier = classifiers.find(cl => cl.code === product?.status_code);

        if(classifier) {
            return `${product.name} (${classifier.value})`;
        }
    }
    
    
    return product.name;
}