/*
    Agreement terms Main Page
 */
import { useHistory, useRouteMatch, useParams, Link, useLocation } from "react-router-dom";
import { get, isObject } from "lodash";
import _toString from "lodash/toString";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { AgreementContext } from "../context/agreement/AgreementContext";
import { AuthContext } from "../context/AuthContext";
import metaConfig from "../config/metaConfig.json";
import { PageHeader } from "../components/PageHeader";
import { Dropdown } from "semantic-ui-react";
import styled from "styled-components/macro";
import styledMap from "styled-map";
import TermsTable from "../components/TermsTable";
import API, { graphqlOperation } from "@aws-amplify/api";
import { escapeTerm } from "../utils/textConverters";

const fieldsById = Object.keys(metaConfig).reduce((memo, sectionId) => {
    const section = metaConfig[sectionId];

    section.forEach((field) => (memo[field.id] = field));

    return memo;
}, {});

const getSummaryData = (category, meta, agreement) => {
    const { id, label, relatedField, type, valueAliases } = category;
    // Should the product information be fetched from VKey or VSource
    const isVSourceProduct = meta?.productVSourceId && meta.productVSourceId !== "-1";

    if (meta === null) {
        return null;
    }

    let rawValue = meta[id];

    // Values from the agreement object
    if (agreement) {
        if (id === "documentVendorName") {
            rawValue = agreement[id];
        }
    }

    switch (type) {
        case "currency":
            const relatedValue = meta[relatedField];
            const related = fieldsById[relatedField];
            const prefix = get(related, `valueAliases.${relatedValue}`, "");
            return {
                label,
                value: rawValue === null ? null : `${prefix}${rawValue.toLocaleString()}`,
                category,
            };
        case "boolean":
            return { label, value: rawValue ? "Yes" : "No", category };
        default:
            if (rawValue === null) {
                return { label, value: "", category };
            }

            // Handle the product information - use the VSource data, otherwise VKey data
            if (id === "productVSourceId") {
                let productName = "";
                if (isVSourceProduct) {
                    productName = meta?.vSourceProduct?.name || "-";
                } else {
                    productName = meta?.product?.productName || "-";
                }

                return {
                    label,
                    value: productName,
                    category,
                };
            }

            // Product & Pricing group product data
            if (category.productMeta) {
                let value = "";
                if (isVSourceProduct && meta?.vSourceProduct) {
                    value = meta.vSourceProduct[category.vsourceField] || "";
                } else {
                    value = meta.product ? meta.product[category.id] : "";
                }
                return {
                    label,
                    value,
                    category,
                };
            }

            return {
                label,
                value: valueAliases ? valueAliases[rawValue] : rawValue,
                category,
            };
    }
};

const checkExcludedFields = (id) => !["updatedBy", "updatedAt", "createdBy", "createdAt", "id", "Id"].includes(id);

const handleObjectInput = (memo, item) => {
    item.category.values
        .filter(({ id }) => checkExcludedFields(id))
        .forEach((category) => {
            const data = { ...category };

            //FIXME: GFH patch for contacts summary display.
            if (item.category.modelName === "contact") {
                data.label = `${item.label} ${category.label}`;
            }

            memo.push(getSummaryData(data, item.value, null));
        });
};

const handleListInput = (memo, item) => {
    const modelFields = item.category.values;
    item.value.items.forEach((listItem) => {
        modelFields
            .filter(({ id }) => checkExcludedFields(id))
            .forEach((category) => {
                const data = { ...category };
                if (data.type === "model") {
                    handleObjectInput(memo, {
                        category: data,
                        value: listItem[category.id],
                    });
                } else {
                    memo.push(getSummaryData(data, listItem, null));
                }
            });
    });
};

const processInput = (memo, item) => {
    if (get(item, "value.items")) {
        handleListInput(memo, item);
    } else if (get(item, "value") && isObject(item.value)) {
        handleObjectInput(memo, item);
    } else if (get(item, "label")) {
        if (item.label === "Id") return memo;
        memo.push(item);
    }
    return memo;
};

const ClientLogoImg = styled.img`
    position: relative;
    display: block;
    max-height: 50px;
    width: auto;
`;

const SidePanel = styled.div`
    position: relative;
    display: flex;
    height: auto;
    flex-direction: column;
    flex: 0 0 ${8 * 30}px;
    border-radius: 3px;
    border: 1px solid #c0c1c2;
`;

const SidePanelItem = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
    line-height: 1.5em;
    padding: ${8 * 2}px;
    cursor: pointer;
    color: #595973;
    background-color: ${styledMap`
            active: #f1f3f4;
            default: white;
        `};
    font-weight: ${styledMap`
            active: bold;
            default: normal;
        `};
`;

const AgreementTermsWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InnerPageWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    align-items: flex-start;
    flex-direction: row;
    padding: ${8 * 5}px;
`;
/*
       Extract Tables so that it can switch on the documents current category
     */

export const AgreementTerms = () => {
    const history = useHistory();

    const match = useRouteMatch();

    const location = useLocation();

    const { id, agreementId, product, vendorVSourceId } = useParams();

    const [vendorName, setVendorName] = useState(null);

    const currentCat = get(location, "hash", null);

    const {
        context: { isLoading, selectedMeta, selectedAgreement },
        fetchMetaCategories,
        fetchAgreement,
    } = useContext(AgreementContext);

    const {
        context: { activeEntity },
    } = useContext(AuthContext);

    const [categories, setCategories] = useState([]);

    const [currentCategory, setCurrentCategory] = useState(null);

    const [categoryMeta, setCategoryMeta] = useState({});

    const [categorySearchTerm, setCategorySearchTerm] = useState("");

    const [documents, setDocuments] = useState([]);

    const [allProducts, setAllProducts] = useState([]);

    let SummaryDetailData = [];

    const searchRegex = new RegExp(escapeTerm(categorySearchTerm), "i");

    // Load the agreement data
    useEffect(() => {
        // Fetch the agreement data
        fetchAgreement(agreementId)
            .then((agreement) => {
                // console.log("agreement:", agreement);
                const newDocs = get(agreement, "documents.items", null);

                setDocuments(newDocs ? newDocs : []);
                return agreement;
            })
            .then((agreement) => {
                if (agreement?.meta) {
                    // Update the category data in the state to display it
                    // The default category is "General"
                    updateCategoryData("General", setCategoryMeta, agreement.meta, agreement);
                } else {
                    console.error("Missing agreement.meta:", agreement);
                }

                return agreement;
            })
            .then((agreement) => {
                // Fetch the vendor name from VSource
                const vendorDataQuery = /* GraphQL */ `
                    query GetVendor($id: ID!) {
                        vendor(id: $id) {
                            id
                            full_legal_name
                        }
                    }
                `;
                try {
                    return API.graphql(
                        graphqlOperation(vendorDataQuery, {
                            id: agreement.vendorVSourceId,
                        })
                    );
                } catch (e) {
                    console.error("Error fetching vendor data from VSource:", e);
                }
            })
            .then((vendor) => {
                // console.log("Vendor:", vendor);
                setVendorName(vendor?.data?.vendor?.full_legal_name);
            })
            .catch((e) => {
                console.error("Failed to fetch agreement ", e);
            });
    }, []);

    useEffect(() => {
        // console.log("vendorName : ", vendorName);
        // console.log("vendorId : ", vendorVSourceId);
        // console.log("product : ", decodeURI(product));

        API.graphql(
            graphqlOperation(
                /* GraphQL */ `
                    query ListAgreements($filter: ModelAgreementFilterInput, $limit: Int, $nextToken: String) {
                        listAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
                            items {
                                id
                                masterProduct
                                vendorName
                                vendorVSourceId
                                meta {
                                    id
                                }
                            }
                            nextToken
                        }
                    }
                `,
                {
                    filter: {
                        vendorVSourceId: {
                            contains: vendorVSourceId,
                        },
                        groupsCanAccess: {
                            eq: activeEntity.groupName,
                        },
                    },
                    limit: 2000,
                }
            )
        ).then(
            ({
                data: {
                    listAgreements: { items: filteredAgreements },
                },
            }) => {
                const productsForList = filteredAgreements.map(({ masterProduct, meta, id }) => ({
                    key: `${masterProduct}-${id}`,
                    text: masterProduct,
                    value: masterProduct,
                    meta,
                    id,
                }));

                setAllProducts(productsForList);
            }
        );
    }, [vendorName, product, vendorVSourceId]);

    // This should be run only once when the component gets the category from the URL
    useEffect(() => {
        const categories = fetchMetaCategories({});
        // console.log("categories : ", categories);
        setCategories(categories);
        setCurrentCategory(currentCat);
    }, [currentCat]);

    useEffect(() => {
        let value = "";

        if (currentCat !== "") {
            //Trim of # from hash
            value = decodeURI(currentCat).substr(1);
        } else {
            value = "General";
        }

        // console.log("value : ", value);

        if (value === "Documents") {
            console.log("Documents should be shown");
        }
        // console.log("selectCategory value, selectedMeta : ", value, selectedMeta);
        setCurrentCategory(value);
        updateCategoryData(value, setCategoryMeta, selectedMeta, selectedAgreement);

        // console.log("agreementId : ", agreementId);
    }, [currentCategory]);

    const updateCategoryData = useCallback(
        (categoryId, setCategoryMeta, meta, agreement) => {
            if (metaConfig[categoryId]) {
                setCategoryMeta(metaConfig[categoryId].map((category) => getSummaryData(category, meta, agreement)));
            }
        },
        [categories]
    );

    const selectProduct = useCallback(
        (e, { value }) => {
            const selectedProduct = allProducts.find((prod) => {
                return prod.value === value;
            });

            if (!selectedProduct) {
                // console.error('No such product', value);
                return;
            }

            history.push(
                `/vkey/summary/AgreementTerms/${encodeURIComponent(value)}/${selectedProduct.meta.id}/${
                    selectedProduct.id
                }/${vendorVSourceId}`
            );
        },
        [vendorName, id, product, allProducts, history, vendorVSourceId]
    );

    const onSearchChange = useCallback(({ currentTarget }) => {
        if (currentTarget?.value){
            setCategorySearchTerm(currentTarget?.value.toString());
        } else {
            setCategorySearchTerm('');
        }
    }, []);

    // Process the agreement meta data to display. If the search term is present filter out the search results
    if (categoryMeta.length > 0) {
        SummaryDetailData = categoryMeta
            .reduce((memo, item) => {
                return processInput(memo, item);
            }, [])
            .filter((item) => {
                return (
                    item.label.search(searchRegex) !== -1 || (_toString(item.value) || "").search(searchRegex) !== -1
                );
            });
    }

    return (
        <AgreementTermsWrapper>
            <PageHeader title={vendorName}>
                <div style={{ marginRight: 8 }}>Other Agreements with Vendor :</div>
                <Dropdown
                    placeholder="Select other product"
                    style={{ height: "auto" }}
                    fluid
                    disabled={isLoading}
                    value={product}
                    search
                    selection
                    onChange={selectProduct}
                    options={allProducts}
                />
                <ClientLogoImg src={activeEntity.logoUrl} style={{ marginLeft: 16, height: 80, width: 80 }} />
            </PageHeader>

            <InnerPageWrapper>
                <SidePanel>
                    {categories.length > 0
                        ? categories.map((value) => {
                              return (
                                  <Link
                                      key={value}
                                      to={{
                                          pathname: `${match.url}`,
                                          hash: `#${value}`,
                                      }}
                                  >
                                      <SidePanelItem active={currentCategory === value}>{value}</SidePanelItem>
                                  </Link>
                              );
                          })
                        : null}
                </SidePanel>

                <TermsTable
                    categoryMeta={categoryMeta}
                    SummaryDetailData={SummaryDetailData}
                    documents={documents}
                    categorySearchTerm={categorySearchTerm}
                    currentCategory={currentCategory}
                    onSearchChange={onSearchChange}
                />
            </InnerPageWrapper>
        </AgreementTermsWrapper>
    );
};
