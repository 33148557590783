import React from "react";
import {Redirect, Route} from "react-router-dom";

export const BaseRoute = (props) => {
    const {...rest} = props;
    return (
        <Route
            {...rest}
            render={props => (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
            }
        />
    );
};