import { useContext } from "react";
import { AlertContext } from "../context/alert/AlertContext";

export const useAlerts = () => {
    const { dispatch } = useContext(AlertContext);
    const timestamp = new Date().getTime();

    const newErrorAlert = (message) => {
        dispatch({ type: "NEW_ERROR", payload: message, timestamp });
        setTimeout(() => {
            removeErrorAlert(timestamp);
        }, 5000);
    };

    const newAlert = (message) => {
        dispatch({ type: "NEW_ALERT", payload: message, timestamp });
        setTimeout(() => {
            removeAlert(timestamp);
        }, 5000);
    };

    const newSuccessAlert = (message) => {
        dispatch({ type: "NEW_SUCCESS", payload: message, timestamp });
        setTimeout(() => {
            removeSuccessAlert(timestamp);
        }, 5000);
    };

    const removeErrorAlert = (timestamp) => {
        console.log("remove error:", timestamp);
        dispatch({ type: "DELETE_ERROR", timestamp });
    };

    const removeAlert = (timestamp) => {
        dispatch({ type: "DELETE_ALERT", timestamp });
    };

    const removeSuccessAlert = (timestamp) => {
        dispatch({ type: "DELETE_SUCCESS", timestamp });
    };

    return {
        newErrorAlert,
        newAlert,
        newSuccessAlert,
        removeErrorAlert,
        removeAlert,
        removeSuccessAlert,
    };
};
