import styled from "styled-components/macro";

export const ConfiguratorBottom = styled.div`
  position: absolute;
  display: flex;
  left: 16px;
  right: 16px;
  bottom: 0;
  height: 80px;
  flex-direction: row;
  border-top: 1px solid #EEF2F6;
  align-items: center;
  justify-content: flex-end;
  padding: 0 40px;
`;