import React, { useState } from "react";
import { Button, Modal, Input, TextArea, Loader, Dimmer } from "semantic-ui-react";
import { useMutation } from "@apollo/client";
import { useAlerts } from "../../hooks/useAlerts";

import { uploadVlinkFile } from "../../graphql/customMutations";

import { FieldsWrapper, InputWrapper, Row } from "./FormRow";
import { InputError } from "./UpdateFieldsetModal";

const UploadDocumentModal = ({ show, onClose, uploadId, type }) => {
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [fileDescription, setFileDescription] = useState("");
    const [validationErrors, setValidationErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const [uploadFileMutation] = useMutation(uploadVlinkFile);

    if(!show){
        return null;
    }

    const closeModal = () => {
        setFileName("");
        setFileDescription("");
        setFile("");
        setValidationErrors([]);
        onClose();
    };

    const saveForm = async () => {
        if (fileName === "") {
            setValidationErrors({ ...validationErrors, fileName: "Filename is required" });
            return;
        }

        if (!file) {
            setValidationErrors({ ...validationErrors, file: "File is required" });
            return;
        }

        if (!uploadId) {
            if(type === "VENDOR"){
                newErrorAlert("Missing vendor ID");
            } else if(type === "PRODUCT"){
                newErrorAlert("Missing product ID");
            } else {
                newErrorAlert("Missing upload ID");
            }

            return;
        }

        if (fileName !== "" && uploadId && file) {
            try {
                setLoading(true);

                const res = await uploadFileMutation({
                    variables: {
                        uploadVLinkPremiumFileId: parseInt(uploadId, 10),
                        input: {
                            type, // "VENDOR" or "PRODUCT"
                            fileName,
                            description: fileDescription,
                            file,
                        },
                    },
                });
                console.log("File upload res:", res);

                if (res?.data?.vlink?.uploadVLinkPremiumFile?.success) {
                    newSuccessAlert("File uploaded successfully");
                } else {
                    newErrorAlert("Failed to upload a file");
                }
            } catch (e) {
                console.log("GraphQL fetch error:", e);
                newErrorAlert("Failed to add a new file");
                closeModal();
                // throw new Error(e.errors[0].message);
            } finally{
                setLoading(false)
            }
        }

        closeModal();
    };

    const onFileChange = (e) => {
        if (e?.target?.validity?.valid) {
            setFile(e.target.files[0]);
        } else {
            setValidationErrors({ ...validationErrors, file: "File is not valid" });
        }
    };

    return (
        <Modal closeIcon onClose={closeModal} open={show}>
            <Modal.Header>Upload Document</Modal.Header>
            <Modal.Content>
                <Row>
                    <FieldsWrapper>
                        <InputWrapper customWidth="100%">
                            <label>Document title:</label>
                            <Input value={fileName} onChange={(_, data) => setFileName(data.value)} />
                            <InputError>{validationErrors.fileName ? `${validationErrors.fileName}` : null}</InputError>
                        </InputWrapper>
                        <InputWrapper customWidth="100%">
                            <label>Document description:</label>
                            <TextArea value={fileDescription} onChange={(_, data) => setFileDescription(data.value)} />
                            <InputError>
                                {validationErrors.fileDescription ? `${validationErrors.fileDescription}` : null}
                            </InputError>
                        </InputWrapper>
                        <InputWrapper customWidth="100%">
                            <label>File:</label>
                            <Input onChange={onFileChange} type="file" />
                            <InputError>{validationErrors.file ? `${validationErrors.file}` : null}</InputError>
                        </InputWrapper>
                    </FieldsWrapper>
                </Row>
            </Modal.Content>
            <Modal.Actions>
                {loading && <Dimmer active inverted><Loader/></Dimmer>}
                <Button content="Save" color="yellow" onClick={saveForm} disabled={loading}/>
            </Modal.Actions>
        </Modal>
    );
};

export default UploadDocumentModal;
