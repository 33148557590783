const gridUnit = 8;

const Theme = {
    primary: "#FFC000", // yellow
    secondary: "#556984",
    accent: "#E8E8F0",
    accentDark: "#8F8FA1",
    primaryBlue: "#64ACFF",
    darkBlue: "#556983",
    error: "#B00020",
    success: "#26A69A",
    background: "#ffffff",
    gray1: "#F1F3F4",
    gray2: "#F1F3F9",
    gray3: "#afbdd3",
    gray4: "#F6F8FA",
    green1: "#4cc790",
    textGray: "#546982",
    shadow1: "box-shadow: 0 2px 4px 0 rgba(0,0,0,0.29)",
    text: "hsl(0, 0%, 20%)",
    yellow1: "#FFC000",
    yellow2: "#FFD966",
    yellowLight: "#FFF4DB",
    inputBackground: "#FBFCFD",
    buttonColor: "#6C8BFF",
    lgMetric: (num) => num * gridUnit,
    smMetric: (num) => num * (gridUnit / 2),



    newGray: '#E1E1E0',
    sideNavBackground: '#fff',
};

export default Theme;
