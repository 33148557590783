import {useCallback, useState} from "react";

export const useFormInput = (initialValue) => {

    const [value, setValue] = useState(initialValue);

    const handleChange = useCallback((e) => {
        setValue(e.target.value);
    }, [value]);

    return {
        value,
        onChange: handleChange
    }
};