import React from "react";
import { format } from "date-fns";
import styled from "styled-components";
import styledMap from "styled-map";

const Section = styled.div`
    margin-top: ${styledMap`
        first-item: 0;
        default: 1rem;
    `};

    & > p {
        margin-bottom: 0.5em;
    }
    a {
      font-size: 14px;
    }
`;

const EventsList = styled.div`
  width: 100%;
  border: 1px solid #b5b5c3;
  border-radius: 3px;
  padding: 1rem 2rem;
  max-height: 330px;
  overflow-y: auto;
  h3 {
    font-size: 16px!important;
    color: #5D6C84;
    font-weight: 600;
  }
`
const TitleSection = styled.div`
  display: flex;
  justify-content: flex-start;
  p {
    font-size: 12px;
    font-weight: 700;
    color: #464E5F;
    white-space: nowrap;
    margin-bottom: 0;
  }
  ul {
    padding-left: 1.5rem;
  }
  li {
    list-style-type: disc;
    font-size: 12px;
    font-weight: 400;
    color: #B5B5C3;
  }
`

export const EventsSection = ({ eventsList }) => {
    if (eventsList.length === 0) return null;

    return <>
        <EventsList>
            <h3>Industry Events</h3>
            {
                eventsList.map((el, i) => {
                    const postedOn = format(el?.eventDate, "DD-MMM-YYYY");
                    return (
                        <Section first-item={!i} key={el?.id}>
                            <TitleSection>
                                <p>{postedOn}</p>
                                <ul>
                                    <li>
                                        {el?.organizerName}
                                    </li>
                                </ul>
                            </TitleSection>
                            <a href={el?.url} target="_blank">
                                {el?.eventName}
                            </a>
                        </Section>
                    );
                })
            }
        </EventsList>
    </>
};
export default React.memo(EventsSection);