import React from "react";
import styled from "styled-components";
import { WidgetLabel } from "./WidgetLabel";

export const StatWrapper = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #f0f2f6;
    width: 350px;

    .label {
        font-size: 1.3rem;
        text-transform: capitalize;
        padding: 0 1em;
        opacity: 70%;
        font-weight: 400;
        font-family: Muli, sans-serif;
    }

    .stat {
        background: ${({ theme }) => theme.gray2};
        font-size: 2rem;
        padding: 1.3rem 2rem;
        min-width: 40%;
        text-align: center;
    }
`;

export const Stat = ({ label, stat }) => {
    return (
        <StatWrapper className={"item"}>
            <div className={"label"}>{label}</div>
            <div className={"stat"}>{stat}</div>
        </StatWrapper>
    );
};

const VendorStats = ({
    companyNum,
    productNum,
    serviceNum,
    children,
    ...props
}) => {
    return <div {...props}>{children}</div>;
};

export default styled(VendorStats)`
    display: flex;
    align-items: flex-start;
    //border: 1px solid #F0F2F6;
    margin: 0.6em 2.4em;
    border-radius: 3px;
`;
