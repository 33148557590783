import styled from "styled-components";
import React, { useContext, useEffect, useState } from "react";
import _sortBy from "lodash/sortBy";
import _orderBy from "lodash/orderBy";
import { AuthContext, AUTH_STATE } from "../context/AuthContext";
import { LoadingAnimation } from "../components/Loader";
import { AnalystDashboardHeader } from "../components/AnalystDashboardHeader";
import { format } from "date-fns";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import { useHistory } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { NewsSection } from "../components/NewsSection";

export const InnerPage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 2;
    h2 {
        font-weight: 700;
    }
`;

const Body = styled.div`
    display: flex;
    padding: 3rem;
    color: ${({ theme }) => theme.darkBlue};

    & > div {
        padding: 0 1rem;
        font-size: 18px;
    }

    & > div:first-child {
        p {
            margin: 2px 0;
        }
    }

    & > div:nth-child(2) {
        margin-left: 3rem;
    }

    .news {
        max-height: 60vh;
        overflow-y: auto;
        margin-top: 8rem;
    }

    ul {
        list-style-image: none;

        li {
            cursor: pointer;
            margin: 5px 0;

            i {
                font-size: 22px;
                margin-right: 5px;
            }
        }
    }
`;

export const AnalystDashboard = () => {
    const [entitiesList, setEntitiesList] = useState(null); // Initial, full list of entities
    const [filteredList, setFilteredList] = useState(null); // Filtered list, eg. using search functionality
    const [entitiesOrder, setEntitiesOrder] = useState("asc");
    const [inputSearchText, setInputSearchText] = useState("");

    const { context, dispatch } = useContext(AuthContext);
    const history = useHistory();

    useEffect(() => {
        const loadEntities = async () => {
            let {
                data: {
                    listEntitiess: { items },
                },
            } = await API.graphql(graphqlOperation(queries.listEntitiess, {}));

            items = _sortBy(items, ["name"]);

            setEntitiesList(items);
            setFilteredList(items);
        };
        loadEntities();
    }, []);

    const orderEntitiesList = () => {
        if (entitiesOrder === "asc") {
            setFilteredList(_orderBy(filteredList, ["name"], ["desc"]));
            setEntitiesOrder("desc");
        } else {
            setFilteredList(_orderBy(filteredList, ["name"], ["asc"]));
            setEntitiesOrder("asc");
        }
    };

    const onEntitySearch = (keyword) => {
        // If no keyword then set the initial results ordered correctly
        if (keyword === "") {
            setFilteredList(_orderBy(entitiesList, ["name"], entitiesOrder));
        } else {
            setFilteredList(filteredList.filter((el) => el.name?.toLowerCase().includes(keyword)));
        }
    };

    return (
        <InnerPage>
            {/* <AnalystDashboardHeader
                onSearch={onEntitySearch}
                inputSearchText={inputSearchText}
                onOrder={orderEntitiesList}
                listOrder={entitiesOrder}
            /> */}

            {context.authState !== AUTH_STATE.SIGNED_IN && <LoadingAnimation />}
            {context.authState === AUTH_STATE.SIGNED_IN && (
                <Body>
                    <div style={{ minWidth: "25%" }}>
                        <div style={{ height: "7rem" }}>
                            <p>Welcome Back: {context?.user?.attributes?.email}</p>
                            {context?.userLastLogin && (
                                <p>
                                    Last Login:{" "}
                                    {context?.userLastLogin && context.userLastLogin !== 0
                                        ? format(context.userLastLogin, "DD-MMM-YYYY h:mm A")
                                        : ""}
                                </p>
                            )}
                        </div>
                        <div style={{ marginTop: "1rem" }}>
                            <h2>VKey Client Partitions</h2>
                            <EntitiesList
                                entities={filteredList}
                                dispatch={dispatch}
                                history={history}
                                context={context}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="news">
                            <NewsSection />
                        </div>
                    </div>
                </Body>
            )}
        </InnerPage>
    );
};

const EntitiesList = ({ entities, dispatch, history, context }) => {
    const setEntity = (entity) => {
        dispatch({
            type: "SET_VKEY_ENTITY",
            activeEntity: {
                name: entity.name,
                id: entity.clientID,
                logoUrl: entity.logoUrl,
                groupName: entity.groupName,
            },
        });

        // Clear the summary and agreements lists
        dispatch({
            type: "SUMMARY_LIST_FETCHED",
            summaryList: {
                stats: {
                    totalAgreements: 0,
                    totalDocuments: 0,
                    totalVendors: 0,
                },
                list: [],
            },
        });

        dispatch({ type: "AGREEMENTS_FETCHED", agreements: [] });

        history.push(`/vkey/vault`);
    };

    if (entities && entities.length > 0) {
        return (
            <ul>
                {entities.map((el) => {
                    return (
                        <li onClick={() => setEntity(el)} key={el.id}>
                            {el.groupName === context.activeEntity.groupName ? (
                                <Icon name="folder open" />
                            ) : (
                                <Icon name="folder" />
                            )}
                            {el.name}
                        </li>
                    );
                })}
            </ul>
        );
    } else if (entities === null) {
        return <p>Loading...</p>;
    } else if (context.entities.length === 0) {
        return (
            <p>
                There are currently no VKey clients assigned to you. Please speak with your manager or a System
                Administrator.
            </p>
        );
    } else {
        return <p>No entities found</p>;
    }
};
