import React, { useCallback, useState, useContext, useEffect, useRef, createRef } from "react";
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import { uploadImageForArticleOrIndustryEvent, addArticle, addIndustryEvent } from "../../graphql/customMutations";
import { useHistory } from "react-router-dom";
const axios = require("axios");

const PostArticle = () => {
    const Container = styled.div`
        display: flex;
        padding: 32px;
        flex-direction: column;
        width: 40vw;
        min-width: 400px;
    `;
    const InputWrapper = styled.div`
        display: flex;
        flex-direction: row;
        margin: 1rem 0;
    `;
    const InputStyle = styled.input`
        border: 1px solid #E8E8F0;
        border-radius: 3px;
        padding: 3px;
        height: 24px;
        padding: 16px;
    `;
    const TextArea = styled.textarea`
        border: 1px solid #E8E8F0;
        border-radius: 3px;
        padding: 3px;
        height: 100px;
        padding: 16px;
        resize: none;
    `;
    const middle = {
        width: '50%',
        alignItems: 'center',
        display: 'flex',
    };

    const InputDescription = styled.div`
        width: 50%;
        align-items: center;
        display: flex;
        font-weight: bold;
    `;

    const SubmitButton = styled.button`
    align-items: center;
    flex: 0 0 auto;
    height: 42px;
    padding: 10px 24px;
    font-size: 16px;
    color: #FFFFFF;
    background: #6C8BFF;
    opacity: 1;
    border-color: #6C8BFF;
    border-width: 0px;
    border-style: solid;
    outline: none;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 11%);
    border-radius: 3px;
    cursor: pointer;
    margin-right: 1em;`

    const [type, setType] = useState('');
    const [error, setError] = useState([]);
    const [loading, setLoading] = useState(false);
    const thumbnailFileRef = createRef();
    const featureFileRef = createRef();
    const [onGetUploadLink] = useMutation(uploadImageForArticleOrIndustryEvent);
    const [onPostArticle] = useMutation(addArticle);
    const [onPostIndustryEvent] = useMutation(addIndustryEvent);

    const handleChangeRadio = (e) => {
        setType(e.target.value);
    }

    const submitForm = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            if (type === 'article') {
                const title = event.target.elements && event.target.elements.title && event.target.elements.title.value;
                const url = event.target.elements && event.target.elements.url && event.target.elements.url.value;
                const thumbnail = event.target.elements && event.target.elements.thumbnail && event.target.elements.thumbnail.value;
                const feature = event.target.elements && event.target.elements.feature && event.target.elements.feature.value;
                const previewText = event.target.elements && event.target.elements.previewText && event.target.elements.previewText.value;

                let thumbnailImageUrl;
                const thumbnailFile = thumbnailFileRef && thumbnailFileRef.current && thumbnailFileRef.current.files[0];
                const featureFile = featureFileRef && featureFileRef.current && featureFileRef.current.files[0];
                if (thumbnail) {
                    // Upload image
                    const file = thumbnailFile
                    const fileType = file && file.type.split('/')[1];
                    const response = await onGetUploadLink({
                        variables: {
                            imageType: fileType,
                        }
                    });
                    const { uploadUrl } = response.data.uploadImageForArticleOrIndustryEvent;
                    thumbnailImageUrl = response.data.uploadImageForArticleOrIndustryEvent.imageUrl;

                    const uploadImageResponse = await axios({
                        url: uploadUrl,
                        method: "PUT",
                        data: file,
                    });
                    if (uploadImageResponse.status !== 200) {
                        throw new Error('Upload image had an error.');
                    }
                }
                let featuredImageUrl;
                if (feature) {
                    // Upload image
                    const file = featureFile
                    const fileType = file && file.type.split('/')[1];
                    const response = await onGetUploadLink({
                        variables: {
                            imageType: fileType,
                        }
                    });
                    const { uploadUrl } = response.data.uploadImageForArticleOrIndustryEvent;
                    featuredImageUrl = response.data.uploadImageForArticleOrIndustryEvent.imageUrl;

                    const uploadImageResponse = await axios({
                        url: uploadUrl,
                        method: "PUT",
                        data: file,
                    });
                    if (uploadImageResponse.status !== 200) {
                        throw new Error('Upload image had an error.');
                    }
                }

                await onPostArticle({
                    variables: {
                        title,
                        url,
                        previewText,
                        thumbnailUrl: thumbnailImageUrl,
                        featuredImageUrl,
                    }
                });
            } else if(type === 'industryEvent') {
                const eventName = event.target.elements && event.target.elements.title && event.target.elements.title.value;
                const url = event.target.elements && event.target.elements.url && event.target.elements.url.value;
                const date = event.target.elements && event.target.elements.date && event.target.elements.date.value;
                const organizer = event.target.elements && event.target.elements.organizer && event.target.elements.organizer.value;
                await onPostIndustryEvent({
                    variables: {
                        eventName,
                        eventDate: date,
                        organizerName: organizer,
                        url,
                    }
                });
            }
        } catch (e) {
            console.log('Error: ', e);
            setError(error.push('400')); // TODO: Fix?
        } finally {
            setLoading(false);
        }
    }

    const showInput = () => {
        return (<form onSubmit={submitForm}>
        <InputWrapper>
            <InputDescription>{type === 'article' ? 'Title' : 'Event name'}</InputDescription>
            <InputStyle type="text" name="title" style={middle} required="required"/>
        </InputWrapper>
        <InputWrapper>
            <InputDescription>URL</InputDescription>
            <InputStyle type="url" name="url" style={middle} required="required"/>
        </InputWrapper>
        {type === 'industryEvent' ? (
            <div>
                <InputWrapper>
                    <InputDescription>Date</InputDescription>
                    <InputStyle type="date" name="date" style={middle} required="required"/>
                </InputWrapper>
                <InputWrapper>
                    <InputDescription>Organizer</InputDescription>
                    <InputStyle type="text" name="organizer" style={middle} required="required"/>
                </InputWrapper>
            </div>
            ) : ''}
        {type === 'article' ? (
            <div>
                <InputWrapper>
                    <InputDescription>Preview text</InputDescription>
                    <TextArea style={middle} name="previewText" required="required"/>
                </InputWrapper>

                <InputWrapper>
                    <InputDescription>Thumbnail (PNG, JPG)</InputDescription>
                    <input type="file" style={middle} accept="image/png, image/jpg, image/jpeg" name="thumbnail" ref={thumbnailFileRef}/>
                </InputWrapper>

                <InputWrapper>
                    <InputDescription>Feature image (PNG, JPG)</InputDescription>
                    <input type="file" style={middle} accept="image/png, image/jpg, image/jpeg" name="feature" ref={featureFileRef}/>
                </InputWrapper>
            </div>
            ) : ''}
        <SubmitButton disabled={loading} style={{ background: loading ? 'hotpink' : ''}}>{loading ? 'Loading...' : 'Submit'}</SubmitButton>
        </form>);
    }
    const history = useHistory();
    return (
    <Container>
        <h1>Publish</h1>
        {error.length > 0 ? (<div>
            An error occured
        </div>) : ''}
        <div style={{ cursor: 'pointer'}} onClick={() => history.push('/admin-list-article')}>List articles and industry events</div>
        <InputWrapper>
            <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', paddingRight: 12}}>
                    <div style={{ paddingRight: 12, fontWeight: 'bold' }}>Article</div>
                    <input type="radio" id="articleRadio" name="article" value="article" onChange={handleChangeRadio} checked={type === 'article'}/>
                </div>
                <div style={{ display: 'flex' }}>
                    <div style={{ paddingRight: 12, fontWeight: 'bold' }}>Industry event</div>
                    <input type="radio" id="industryEventRario" name="industryEvent" value="industryEvent" onChange={handleChangeRadio} checked={type === 'industryEvent'}/>
                </div>
            </div>
        </InputWrapper>
        {type ? showInput() : undefined}
    </Container>
    );
}

export default PostArticle;
