import React, { useMemo, useState, useEffect } from "react";
import * as PropTypes from "prop-types";
import { Modal } from "semantic-ui-react";
import _get from "lodash/get";
import { format } from "date-fns";
import styled from "styled-components/macro";

import {
    moneyConvert,
} from "../../../../utils/numberConverts";
import SelectBlockView from "../SelectBlockView/SelectBlockView";

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0.75rem;
`;

const Title = styled.div`
    width: 50%;
`;

const Description = styled.div`
    width: 30%;
`;

const percents = Array.from(Array(100).keys()).map((value) => (
    {
        label: `${value}%`,
        value: value
    }
)).slice(1);

export const ProductDetailsPopUp = ({
    data,
    isOpen = false,
    onChange = () => {},
}) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [percentValue, setPercentValue] = useState(1);
    const productName = useMemo(() => _get(data, 'value.title'), [data.value.title]);
    const productData = useMemo(() => _get(data, 'value'), [data.value]);
    const rowData = useMemo(() => _get(data, 'row'), [data.row]);
    const annualCost = useMemo(() => _get(data, 'row.annualCost'), [data.row.annualCost]);

    useEffect(() => {
        setShowModal(isOpen)
        onChange(isOpen)
    }, [isOpen]);

    return (
        <Modal
            closeIcon
            onClose={() => setShowModal(false)}
            onOpen={() => setShowModal(true)}
            open={showModal}
            style={{ maxWidth: '550px' }}
        >

            <Modal.Header>Product Detail: {productName}</Modal.Header>

            <Modal.Content style={{ padding: "2rem" }}>
                <Row>
                    <Title>
                        Product Name
                    </Title>
                    <Description style={{fontWeight: "bold"}}>
                        {
                            _get(productData, "title")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Product ID
                    </Title>
                    <Description>
                        {
                            _get(productData, "id")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Vendor Name
                    </Title>
                    <Description>
                        {
                            _get(productData, "vendor.title")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Total Annual Cost
                    </Title>
                    <Description>
                        £{
                            moneyConvert(_get(rowData, "annualCost", 0))
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Contract End
                    </Title>
                    <Description>
                        {
                            format(_get(rowData, "contractEnd"), "DD-MMM-YYYY")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Cancellation Notice Deadline
                    </Title>
                    <Description>
                        {
                            format(_get(rowData, "cancellationDate"), "DD-MMM-YYYY")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Licenses
                    </Title>
                    <Description>
                        {
                            _get(rowData, "licenses")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        SME
                    </Title>
                    <Description>
                        {
                            _get(productData, "sme")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Market Data Contact
                    </Title>
                    <Description>
                        {
                            _get(productData, "marketDataContact")
                        }
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Estimated Annual Cost Increase
                    </Title>
                    <Description>
                        <SelectBlockView
                            options={percents}
                            onChange={setPercentValue}
                        />
                    </Description>
                </Row>
                <Row>
                    <Title>
                        Estimated Total Cost Upon Renewal
                    </Title>
                    <Description>
                        £{
                            moneyConvert( annualCost + ((percentValue / 100) * annualCost))
                        }
                    </Description>
                </Row>
            </Modal.Content>

        </Modal>
    )
};

ProductDetailsPopUp.propTypes = {
    isOpen: PropTypes.bool,
    onChange: PropTypes.func,
};

export default ProductDetailsPopUp;
