export default function listToTree(list) {
    var map = {}, node, roots = [], i;

    for (i = 0; i < list.length; i += 1) {
        map[list[i].code] = i; // initialize the map
        list[i].children = []; // initialize the children
    }
    for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parent_classifier_code !== null) {

            list[map[node.parent_classifier_code]].children.push({
                ...node,
                id: node.code,
                label: node.value,
            });
        } else {
            roots.push({
                ...node,
                id: node.code,
                label: node.value,
            });
        }
    }
    return roots;
}