import React, { useContext } from "react";
import {DocSearch} from "./DocSearch";
import {VaultFeed} from "./VaultFeed";
import { AuthContext } from "../context/AuthContext";
import styled from "styled-components/macro";
import CloseIcon from "./icons/CloseIcon";

const SideBarModal = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
`;
const ModalCloseButton = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    height: 20px;
    width: 20px;
    
    top: 20px;
    right: 10px;
    
    z-index: 1000;
`;

const ModalClose = styled(CloseIcon)``;

const ModalVaultWrapper = styled.div`
  padding: 20px;
  height: 100%;
`;

export const ModalVault = ({onClose, children}) => {
    const { context, dispatch } = useContext(AuthContext);

    const closeModal = () => {
        // Clear the search
        dispatch({ type: "DOC_SEARCH", docSearch: "", searchItems: [] });
        if(onClose){
            onClose();
        }
        
    }

    return (
        <ModalVaultWrapper>
            <ModalCloseButton data-cy={'modal-close-btn'} onClick={closeModal}>
                <ModalClose>Close</ModalClose>
            </ModalCloseButton>
            <SideBarModal>
                <DocSearch style={{flex: `0 0 40px`}}/>
                <div style={{overflow: 'scroll'}}>
                    <VaultFeed data={!!context.docSearch ? context.searchItems : context.docItems} allowDocDelete={false}/>
                </div>
            </SideBarModal>
        </ModalVaultWrapper>
    );
};