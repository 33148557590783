import { useLazyQuery, useMutation, NetworkStatus } from "@apollo/client";
import React, { useEffect, useMemo, useState, useContext } from "react";
import _get from "lodash/get";
import { useHistory, useParams, useLocation } from "react-router-dom";
import OpenInNewTabIcon from "@material-ui/icons/OpenInNewRounded";
import styled from "styled-components";
import {
    insertProductRevisions,
    insertVendorRevisions,
    submitProductRevision,
    submitVendorRevision,
} from "../../graphql/customMutations";
import { getProductRevisionsQuery, getVendorRevisionsQuery } from "../../graphql/customQueries";
import {
    buildVendorFormData,
    buildProductFormData,
    buildInsertRevisionInput,
} from "../../helpers/vlink/revisionHelpers";
import RowList from "./FormRowList";
import RowListGroup from "./FormRowListGroup";
import { ButtonWrapper } from "../../components/VendBtn";
import Spinner from "./Spinner";
import { AuthContext } from "../../context/AuthContext";
import { useVsourceResource } from "./hooks/useVsourceResource";
import { Storage } from "aws-amplify";
import VendorProductsPreviewTab from "./VendorProductsPreviewTab";
import { useRouterQuery } from "./hooks/useRouterQuery";
import { useAlerts } from "../../hooks/useAlerts";
import VendorEditButtons from "./VendorEditButtons";
import ProductEditButtons from "./ProductEditButtons";
import RequestVendorUpdateModal from "./RequestVendorUpdateModal";
import { FeatureBtn } from "./FeatureBlock";
import { useCheckAuth } from "../Navigation/useCheckAuth";
import getSortedClassifiersByType from "../../utils/getSortedClassifiersByType";
import PublicFormRow from "../PublicFormRow";
import LockIcon from "../../components/icons/LockIcon";

const VendorUpdateWrapper = styled.div`
    display: flex;
    width: 100%;
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 3rem;
    top: 300px;
    right: 0;
    padding-right: 15px;
`;

const FieldsetTitle = styled.h5`
    color: #80808f;
    flex-shrink: 0;
    width: 150px;
    padding-left: 10px;
`;
// import { vendorRevisionData } from './sample/vendorRevisionQueryResponse';

const FormWrapper = styled.div`
    flex-grow: 1;
`;

const FormActions = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 10px;
`;

const FormSection = styled.div`
    padding: 25px;
    border: ${(props) => (props.noBorder ? "none" : "1px solid #b5b5c3")};
    border-radius: 12px;
    margin-top: ${(props) => (props.noMargin ? "0" : "20px")};

    h3 {
        margin-bottom: 30px;

        &.yellow {
            font-weight: 400;
            color: #ffc309;
        }
    }

    & > div:not(:first-of-type) {
        margin-top: 5px;
        padding-top: 5px;
        // border-top: 2px solid #b5b5c33c;
    }

    .location-card {
        flex-direction: column;
        width: 300px;
        border: 1px solid #e9e9ed;
        border-radius: 4px;
        padding: 15px;
    }
`;

const DebugBox = styled.div`
	position: fixed;
	z-index: 999;
	right: 20px;
	top: 100px;
	padding: 20px;
	min-width: 200px;
	background-color: #e7c7c7;
	box-shadow: 0px 0px 5px 2px grey;
	border-radius: 5px;
	height: ${(props) => (props.expandable ? "10%" : "auto")}

	&:hover {
		height: auto;

		.debug-content {
			display: flex;
		}
	}
	display: ${(props) => (props.show ? "inherit" : "none")}

	.debug-content {
		display: ${(props) => (props.expandable ? "none" : "flex")};
		flex-direction: column;
		gap: 10px;

		div {
			display:flex;
			justify-content: space-between;
			gap: 10px;
		}
	}
`;

const LocationWrapper = styled.div`
    .list {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
    }
`;

const PreviewLogoContainer = styled.div`
    min-height: 180px;
    position: relative;

    .content {
        padding: 20px;
        position: absolute;
        left: calc(50vw - 500px);
        top: -100px;
        box-sizing: border-box;
        width: 960px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background-color: #fff;
        border-radius: 4px;
        box-shadow: 0 4px 4px 0 rgb(0 0 0 / 25%);

        div {
            flex-shrink: 0;
            width: 280px;
            height: 120px;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
`;

const PreviewTabs = styled.div`
	ul {
		display: flex;
		gap: 25px;
		padding-left: 30px;
	}

	li {
		padding-bottom: 10px;
		font-size: 1.1rem;
		color: #595973;
		cursor: pointer;

		&.active {
			color: #2564BB
			border-bottom: 2px solid #2564BB;
		}
	}
`;

const ProductBreadcrumbWrapper = styled.div`
    display: flex;
    gap: 5px;
    margin-top: 10px;

    span:not(:last-child) {
        color: #2564bb;
        cursor: pointer;
    }
`;

const HeaderActionButtonsWrapper = styled.div`
    display: flex;
    max-width: 50%;
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 3rem;
    padding-right: 10px;
    right: 0;
`;

const classifierTypes = [
    "BUSINESS_ROLE",
    "SOCIAL_MEDIA_CHANNEL",
    "CURRENCY",
    "LOCATION_TYPE",
    "REGION",
    "COUNTRY",
    "REGION",
    "COMPANY_SIZE",
    "CORPORATE_ACTION",
    "TIME_ZONE",
    "VENDEX_REVISION_RESPONSE",
    "PRODUCT_STATUS",
    "COMPANY_TYPE",
    "PRODUCT_CHANGE",
    "JOB_ROLE",
    "REGULATION",
    "PRODUCT_SOLUTION",
    "PRODUCT_CLASSIFICATION",
    "PRODUCT_GEOGRAPHY",
    "DELIVERY_METHOD",
    "MARKET_SEGMENT",
];

const vsourceVendorFields = [
    {
        key: "last_reviewed_by",
        title: "Vendex Analyst Last Reviewed",
    },
    {
        key: "last_reviewed_at",
        title: "Last Reviewed Date and Time",
    },
    {
        key: "last_published_by",
        title: "VendEx Publisher Last Published",
    },
    {
        key: "last_published_at",
        title: "Last Published Date and Time",
    },
    {
        key: "next_review_in_days",
        title: "Next Review Number Of Days",
    },
    {
        key: "next_review_at",
        title: "Next Review Date",
    },
];

const messageBoxData = {
    message: "Thanks! Your answers have been submitted.",
    subMessage: "Your submission details are below.",
};

export default function RevisionForm({ context, level, setMessageBoxData, messageRef }) {
    const { id: targetId } = useParams();
    const [formData, setFormData] = useState(null);
    const [classifiers, setClassifiers] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [lockSubmit, setLockSubmit] = useState(false);
    const [autoCertifyGroup, setAutoCertifyGroup] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [logoUrl, setLogoUrl] = useState(null);
    const [logoLoading, setLogoLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [skip, setSkip] = useState(false);
    const [unlockData, setUnlockData] = useState({
        lastSubmittedAt: null,
        lastRespondedAt: null,
        lockedUntil: null,
        locked: false,
        unlocksIn: 0,
    });
    const { isVSourceUser, isVLinkUser } = useCheckAuth();
    const [showRequestUpdateModal, setRequestUpdateModal] = useState(false);

    const vsourceData = useVsourceResource({ level });
    const { pathname } = useLocation();
    const isVSourcePage = pathname.includes(`/vsource/`);

    const {
        isVLinkPremiumUser,
        isVSourceClientUserOnly,
        context: { user, availableVendors },
    } = useContext(AuthContext);
    const { isVSourceAdminUser, isVSourceVendexSuperUser } = useCheckAuth();
    const history = useHistory();
    const query = useRouterQuery();
    const { newErrorAlert } = useAlerts();

    // VENDOR: keep track of all current rows for vendor.
    // VENDEX: keep track of all rows that should be responded
    const [untouchedRows, setUntouchedRows] = useState([]); // [{rowName: 'website', rowIndex: 0}]

    const SUBMIT_INTERVAL_IN_MINUTES = 2;

    const [insertRevisions] = useMutation(level === "VENDOR" ? insertVendorRevisions : insertProductRevisions);

    const [getRevisionsMethod, { data: revisions, refetch: refetchRevisions, networkStatus, error }] = useLazyQuery(
        level === "VENDOR" ? getVendorRevisionsQuery : getProductRevisionsQuery,
        {
            variables: { [level === "VENDOR" ? "vendorRevisionsId" : "productRevisionsId"]: targetId },
            notifyOnNetworkStatusChange: true,
            displayName: "RevisionForm",
            fetchPolicy: "no-cache",
        }
    );

    const page = {
        isVendorPage: pathname.includes("/vlink/vendors/"),
        isProductPage: pathname.includes("/vlink/products/"),
        isVSourceVendorPage: pathname.includes("/vsource/vendors/"),
    };

    // If vendor or product are FORBIDDEN for edit redirect to prev page
    if (_get(error, `graphQLErrors[0].extensions.code`) === "FORBIDDEN") {
        console.error("RevisionForm error: Vendor or product is FORBIDDEN for edit");
        history.push("/vlink/dashboard");
    }

    useEffect(() => {
        setLoading(revisions === undefined || networkStatus !== NetworkStatus.ready);
    }, [networkStatus]);

    const [submitRevision] = useMutation(level === "VENDOR" ? submitVendorRevision : submitProductRevision);

    useEffect(() => {
        // if user has isVLinkUser and isVSourceUser permissions we have to check assigned vendors and products before open edit page
        if (
            level === "VENDOR" &&
            availableVendors.length &&
            (isVSourceUser || isVLinkUser) &&
            !page.isVSourceVendorPage
        ) {
            if (!availableVendors.some((item) => item?.id === targetId)) {
                const assignedVendorId = _get(availableVendors, "[0].id");
                if (assignedVendorId) {
                    history.push(`/vlink/vendors/${assignedVendorId}`);
                } else {
                    history.push(`/vlink/dashboard`);
                }
            }
        }

        getRevisionsMethod();
    }, [availableVendors]);

    useEffect(function fetchClassifiers() {
        let nextOptions = {};

        Promise.all(classifierTypes.map((type) => fetchClassifiersByType(type))).then(function storeData(data) {
            classifierTypes.forEach((type, index) => {
                nextOptions[type] = data[index];
            });
            setClassifiers(nextOptions);
        });
    }, []);

    useEffect(() => {
        if (classifiers["BUSINESS_ROLE"] && revisions) {
            if (level === "VENDOR") {
                setFormData(buildVendorFormData(revisions?.vlink?.vendorRevisions || [], classifiers, context));
            } else {
                // is product assigned to user. If user is isVSourceUser  isVLinkUser
                const vendorId = _get(revisions, `vlink.productRevisions[0].vendorId`);
                if (!isVSourcePage && vendorId && (isVSourceUser || isVLinkUser) && availableVendors.length) {
                    if (!availableVendors.some((item) => item?.id === String(vendorId))) {
                        history.push(`/vlink/dashboard`);
                    }
                }
                setFormData(buildProductFormData(revisions.vlink.productRevisions || [], classifiers, context));
            }
        }
    }, [classifiers, revisions, context, level, availableVendors]);

    useEffect(() => {
        if (submitting && !lockSubmit) {
            // if(context === 'VENDOR') {
            // 	certifyGroup('foundational')
            // 		.then(() => {
            // 			setAutoCertifyGroup('foundational');
            // 			submit();
            // 		})
            // } else {
            setLockSubmit(true);
            submit();
            // }
        }
    }, [context, lockSubmit, submitting]);

    useEffect(() => {
        if (vsourceData) {
            // Fetch Logo url
            if (vsourceData.vendor_logo_filename || vsourceData.vendor?.vendor_logo_filename) {
                setLogoLoading(true);

                Storage.get(
                    `vsource/logos/${vsourceData.vendor_logo_filename || vsourceData.vendor.vendor_logo_filename}`,
                    {
                        level: "public",
                    }
                )
                    .then((s3ImageUrl) => {
                        setLogoUrl(s3ImageUrl);
                    })
                    .catch((e) => console.error("Error fetching logo URL: ", e));

                setLogoLoading(false);
            } else {
                setLogoUrl(null);
            }
        }
    }, [vsourceData]);

    useEffect(() => {
        let tab = query.get("tab");

        if (tab) {
            setActiveTabIndex(tab - 1);
        }
    }, []);

    function submitHandler() {
        setSubmitting(true);
    }

    function submit() {
        submitRevision({
            variables: {
                [level === "VENDOR" ? "vendorId" : "productId"]: Number(targetId),
                input: { context: context },
            },
        })
            .then((res) => {
                setMessageBoxData(messageBoxData);
                refetchRevisions();
            })
            .catch((e) => {
                console.error(e);
                newErrorAlert(e.message ?? "Error submitting the revision");
            })
            .finally(() => {
                setSubmitting(false);
                setLockSubmit(false);
                messageRef.current && messageRef.current.previousElementSibling.scrollIntoView();
                //window.location.reload();
            });
    }

    const locked = useMemo(() => {
        switch (context) {
            case "VENDOR": {
                let maxSubmittedValue = 0;
                let maxRespondedValue = 0;
                for (const section in formData) {
                    const rowList = Array.isArray(formData[section].rowList)
                        ? formData[section].rowList
                        : [formData[section].rowList];

                    for (const data of rowList) {
                        if (
                            data.submitted &&
                            (!data.hasLatestRevision ||
                                data.latestRevision.revisionId === data.submittedRevision.revisionId)
                        ) {
                            let date = new Date(data.submittedAt);
                            maxSubmittedValue = Math.max(maxSubmittedValue, date.getTime());
                        }
                        if (data.respondedAt) {
                            let date = new Date(data.respondedAt);
                            maxRespondedValue = Math.max(maxRespondedValue, date.getTime());
                        }
                    }
                }

                let unlockAt = maxSubmittedValue + 60000 * SUBMIT_INTERVAL_IN_MINUTES;
                let userTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
                let isLocked = true;

                if (maxRespondedValue > maxSubmittedValue) {
                    isLocked = false;
                }

                setUnlockData({
                    lastSubmittedAt:
                        maxSubmittedValue > 0
                            ? new Date(maxSubmittedValue + userTimezoneOffset).toLocaleString()
                            : "No Data",
                    lastRespondedAt:
                        maxRespondedValue > 0
                            ? new Date(maxRespondedValue + userTimezoneOffset).toLocaleString()
                            : "No Data",
                    lockedUntil:
                        maxSubmittedValue > 0 ? new Date(unlockAt + userTimezoneOffset).toLocaleString() : "No Data",
                    unlocksIn: Math.round((unlockAt - Date.now()) / 1000),
                    locked: isLocked,
                });
                return unlockAt > Date.now() && isLocked;
            }
            case "VENDEX": {
                for (const section in formData) {
                    for (const row of formData[section].rowList) {
                        if (
                            row.submittedRevision &&
                            row.submittedRevision.vendorActionTypeCode === "PROPOSAL" &&
                            !row.submittedRevision.vendexRespondedAt
                        ) {
                            return false;
                        }
                    }
                }

                return true;
            }
            default: {
                return true;
            }
        }
    }, [formData, context]);

    // useEffect(function chanteTimerData() {
    // 	let timeout = setTimeout(() => {
    // 		setUnlockData({...unlockData, unlocksIn: unlockData.unlocksIn - 1});
    // 	}, 1000);

    // 	return () => clearTimeout(timeout);
    // });

    // Hide submit success message when vendor user's screen is unlocked
    // If context is vendex, VendorDetails.js will get rid of the message after 5 sec;
    useEffect(
        function toggleMessageBox() {
            if (context === "VENDOR") {
                if (locked) {
                    setMessageBoxData(messageBoxData);
                } else {
                    setMessageBoxData({});
                }
            }
        },
        [locked, setMessageBoxData, context]
    );

    function renderFieldset({ section, rowList, groupProps, expanded = true, onExpand, showTitle = true }) {
        let multiple = false;
        if (!rowList) {
            multiple = formData[section].rowList[0].itemTypeCode === "RECORD";
        } else {
            multiple = rowList[0].itemTypeCode === "RECORD";
        }
        return (
            <RowList
                key={section}
                level={level}
                vsourceData={vsourceData}
                title={showTitle ? formData[section].title : ""}
                subTitle={showTitle ? formData[section].subTitle : ""}
                rowName={section}
                rowListData={rowList || formData[section].rowList}
                targetId={Number(targetId)}
                context={context}
                locked={locked}
                untouchedRows={untouchedRows}
                setUntouchedRows={setUntouchedRows}
                classifiers={classifiers}
                show={expanded}
                onExpand={onExpand}
                multiple={multiple}
                {...groupProps}
            />
        );
    }

    function certifyGroup(group) {
        let mutationInput = [];

        untouchedRows.forEach((untouchedRow) => {
            if (formData[untouchedRow.rowName].group === group) {
                let row;
                if (untouchedRow.rowName === "customerSupport") {
                    row = formData.customerSupport.rowList.find((row) => row.itemId === untouchedRow.itemId);
                } else {
                    row = formData[untouchedRow.rowName].rowList[untouchedRow.rowIndex];
                }
                mutationInput.push(
                    buildInsertRevisionInput({ ...row, certified: true, proposed: false }, context, level)
                );
            }
        });

        if (mutationInput.length === 0) {
            return Promise.resolve();
        }

        return insertRevisions({
            variables: {
                [level === "VENDOR" ? "vendorId" : "productId"]: Number(targetId),
                input: mutationInput,
            },
        });
    }

    const showDebugBox = false && process.env.REACT_APP_BUILD_ENV === "localhost" || process.env.REACT_APP_BUILD_ENV === "test";
    const tabsList = ["Vendor Profile", "Products", "Location & Support"];
    return (
        <FormWrapper>
            {["VENDOR_PREVIEW", "PUBLIC"].includes(context) && (
                <>
                    {level === "VENDOR" && (
                        <HeaderActionButtonsWrapper>
                            <React.Fragment>
                                {isVSourceClientUserOnly(user) && (
                                    <React.Fragment>
                                        <div>
                                            <FeatureBtn onClick={() => setRequestUpdateModal(true)}>
                                                Request Vendor Update
                                            </FeatureBtn>
                                        </div>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                            <VendorEditButtons context={context} activeTab={tabsList[activeTabIndex]} />
                        </HeaderActionButtonsWrapper>
                    )}
                    {level === "PRODUCT" && (
                        <HeaderActionButtonsWrapper>
                            <ProductEditButtons context={context} />
                        </HeaderActionButtonsWrapper>
                    )}
                    {level === "VENDOR" ? (
                        <PreviewTabs>
                            <ul>
                                {tabsList.map((item, index) => {
                                    return (
                                        <li
                                            key={index}
                                            className={activeTabIndex === index ? "active" : ""}
                                            onClick={() => setActiveTabIndex(index)}
                                        >
                                            {item}
                                        </li>
                                    );
                                })}
                            </ul>
                        </PreviewTabs>
                    ) : (
                        <ProductBreadcrumb vsourceData={vsourceData} />
                    )}
                </>
            )}

            <React.Fragment>
                {showRequestUpdateModal && (
                    <RequestVendorUpdateModal vendor={vsourceData} onClose={() => setRequestUpdateModal(false)} />
                )}
            </React.Fragment>

            <DebugBox expandable={isVLinkPremiumUser} show={showDebugBox}>
                <h5>Debug Data:</h5>
                <div className="debug-content">
                    {context === "VENDOR" && (
                        <>
                            <div>
                                <h5>Current Time:</h5>
                                <span>
                                    {new Date(Date.now() + new Date().getTimezoneOffset() * 60000).toLocaleString()}{" "}
                                </span>
                            </div>
                            <div>
                                <h5>Last submitted at:</h5>
                                <span>{unlockData.lastSubmittedAt}</span>
                            </div>
                            <div>
                                <h5>Last responded at:</h5>
                                <span>{unlockData.lastRespondedAt}</span>
                            </div>
                            <div>
                                <h5>Locked Until:</h5>
                                <span>{unlockData.lockedUntil} </span>
                            </div>
                            <div>
                                <h5>Unlocks in:</h5>
                                <span>
                                    {unlockData.unlocksIn > 0 && unlockData.locked
                                        ? `${unlockData.unlocksIn} seconds`
                                        : "Unlocked"}{" "}
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </DebugBox>
            <Spinner loading={loading || submitting || logoLoading} />
            {formData && (
                <>
                    <div style={{ marginBottom: 6 }}>
                        {level === "VENDOR" ? (
                            activeTabIndex === 0 ? (
                                <>
                                    <FormSection>
                                        <h3>General Information</h3>
                                        {renderFieldset({ section: "fullLegalName" })}
                                        {!["PUBLIC", "VENDOR_PREVIEW"].includes(context) ? (
                                            <>
                                                {renderFieldset({ section: "vendorLogoFilename" })}
                                                {renderFieldset({ section: "briefDescription" })}
                                            </>
                                        ) : null}
                                        {renderFieldset({ section: "detailedDescription" })}
                                    </FormSection>
                                    <FormSection>
                                        <RowListGroup
                                            name="foundational"
                                            title="Foundational Data"
                                            context={context}
                                            locked={locked}
                                            autoCertifyGroup={autoCertifyGroup}
                                        >
                                            {(groupProps) => [
                                                renderFieldset({ section: "aliases", groupProps }),
                                                renderFieldset({ section: "historicalNames", groupProps }),
                                                renderFieldset({ section: "companyType", groupProps }),
                                                renderFieldset({ section: "marketSegments", groupProps }),
                                                renderFieldset({ section: "tickerData", groupProps }),
                                                renderFieldset({ section: "yearFounded", groupProps }),
                                                renderFieldset({ section: "headquarters", groupProps }),
                                                renderFieldset({ section: "numberOfOffices", groupProps }),
                                                renderFieldset({ section: "companySize", groupProps }),
                                                renderFieldset({ section: "website", groupProps }),
                                                renderFieldset({ section: "LEICode", groupProps }),
                                                renderFieldset({ section: "immediateParentName", groupProps }),
                                                renderFieldset({ section: "ultimateParentName", groupProps }),
                                                renderFieldset({ section: "corporateActions", groupProps }),
                                                renderFieldset({ section: "investorRelations", groupProps }),
                                                renderFieldset({ section: "socialMediaChannels", groupProps }),
                                                renderFieldset({ section: "financials", groupProps }),
                                                renderFieldset({ section: "officers", groupProps }),
                                                !["VENDOR_PREVIEW", "PUBLIC"].includes(context)
                                                    ? renderFieldset({ section: "locations", groupProps })
                                                    : null,
                                            ]}
                                        </RowListGroup>
                                    </FormSection>
                                    {["PUBLIC"].includes(context) &&
                                    (isVSourceAdminUser || isVSourceVendexSuperUser) ? (
                                        <FormSection>
                                            <h3>Audit Information</h3>
                                            {vsourceVendorFields.map(({ title, key }) => (
                                                <PublicFormRow
                                                    key={key}
                                                    title={title}
                                                    value={_get(vsourceData, `[${key}]`, "")}
                                                />
                                            ))}
                                        </FormSection>
                                    ) : null}
                                </>
                            ) : activeTabIndex === 1 ? (
                                <FormSection noBorder noMargin>
                                    <VendorProductsPreviewTab
                                        context={context}
                                        vsourceData={vsourceData}
                                        products={vsourceData?.products || []}
                                    />
                                </FormSection>
                            ) : activeTabIndex === 2 ? (
                                <>
                                    <FormSection noBorder>
                                        {renderFieldset({ section: "locations", showTitle: false })}
                                    </FormSection>
                                </>
                            ) : null
                        ) : (
                            <>
                                <FormSection>
                                    <h3>General Information</h3>
                                    {!["VENDOR_PREVIEW", "PUBLIC"].includes(context)
                                        ? renderFieldset({ section: "productID" })
                                        : null}
                                    {renderFieldset({ section: "name" })}
                                    {!["PUBLIC", "VENDOR_PREVIEW"].includes(context)
                                        ? renderFieldset({ section: "briefDescription" })
                                        : null}
                                    {renderFieldset({ section: "detailedDescription" })}
                                    {renderFieldset({ section: "deliveryMethods" })}
                                    {renderFieldset({ section: "statusCode" })}
                                    {renderFieldset({ section: "URL" })}
                                    {renderFieldset({ section: "demoURL" })}
                                    {renderFieldset({ section: "productChanges" })}
                                    {renderFieldset({ section: "jobRoles" })}
                                    {renderFieldset({ section: "regulations" })}
                                </FormSection>
                                <FormSection>
                                    <h3 className="yellow">Product Strengths to the Market</h3>
                                    {renderFieldset({ section: "vidsv" })}
                                </FormSection>
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            position: "sticky",
                            bottom: 0,
                            justifyContent: "flex-start",
                            padding: "2px 12px 12px 12px",
                            background: "white",
                            border: "1px solid #b5b5c3",
                            display: ["VENDOR_PREVIEW", "PUBLIC"].includes(context) ? "none" : "flex",
                        }}
                    >
                        <FormActions>
                            {!["VENDOR_PREVIEW", "PUBLIC"].includes(context) && context === "VENDOR" && (
                                <ButtonWrapper
                                    space-between
                                    onClick={() =>
                                        window
                                            .open(
                                                `/vlink/${
                                                    level === "VENDOR" ? "vendors" : "products"
                                                }/${targetId}/preview`,
                                                "_blank"
                                            )
                                            .focus()
                                    }
                                >
                                    <span style={{ marginRight: "4px" }}>Preview</span>
                                    <OpenInNewTabIcon style={{ fontSize: "14px", margin: 0 }} />
                                </ButtonWrapper>
                            )}
                            {!locked && (
                                <ButtonWrapper
                                    disabled={locked || (context === "VENDEX" && untouchedRows.length > 0)}
                                    yellow
                                    onClick={submitHandler}
                                >
                                    {context === "VENDEX" ? "Publish" : "Submit"}
                                </ButtonWrapper>
                            )}
                            {locked && context === "VENDOR" && (
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <LockIcon style={{ marginRight: 12 }} />
                                    <div>Unlocks {unlockData && unlockData.lockedUntil}</div>
                                </div>
                            )}
                            {locked && context === "VENDEX" && (
                                <div style={{ display: "flex", alignItems: "center", fontStyle: "italic" }}>
                                    No changes to review
                                </div>
                            )}
                        </FormActions>
                    </div>
                </>
            )}
        </FormWrapper>
    );
}

async function fetchClassifiersByType(type) {
    try {
        const { data } = await getSortedClassifiersByType(type);

        if (data && data.listClassifiersByType) {
            return data.listClassifiersByType.map((el) => ({
                ...el,
                text: el.value,
                value: el.code,
            }));
        }
    } catch (e) {
        console.log(`Classifier for ${type} fetch error: `, e);
    }
}

const ProductBreadcrumb = ({ vsourceData, context }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    const isVSourcePage = pathname.includes("/vsource/");
    const isVLinkPage = pathname.includes("/vlink/");

    if (!vsourceData?.vendor || (!isVSourcePage && !isVLinkPage)) {
        return null;
    }

    const handleVendorPageClick = () => {
        if (isVLinkPage) {
            history.push(`/vlink/vendors/${vsourceData.vendor?.id}/${context === "PUBLIC" ? "public" : "preview"}`);
        } else if (isVSourcePage) {
            history.push(`/vsource/vendors/${vsourceData.vendor?.id}`);
        }
    };

    const handleProductPageClick = () => {
        if (isVLinkPage) {
            history.push(
                `/vlink/vendors/${vsourceData.vendor?.id}/${context === "PUBLIC" ? "public" : "preview"}?tab=2`
            );
        } else if (isVSourcePage) {
            history.push(`/vsource/vendors/${vsourceData.vendor?.id}?tab=2`);
        }
    };

    return (
        <ProductBreadcrumbWrapper>
            <span onClick={handleVendorPageClick}>{vsourceData?.vendor?.full_legal_name} /</span>
            <span onClick={handleProductPageClick}>Products /</span>
            <span>{vsourceData?.name}</span>
        </ProductBreadcrumbWrapper>
    );
};
