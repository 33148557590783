import React, { useMemo } from "react";
import * as PropTypes from "prop-types";
import {
    differenceInCalendarMonths,
    differenceInSeconds,
    differenceInCalendarDays
} from "date-fns";
import styled from "styled-components/macro";

const TimeFrameBase = styled.div`
  display: inline-block;
  min-width: 130px;
  height: 100%;
  border-radius: 5px;
  padding: 0.5rem 0;
  font-weight: bold;
  color: #070201;
`;
const OverdueStyle = styled(TimeFrameBase)`
  background: #EB3423
`
const Yellow = styled(TimeFrameBase)`
  background: #FEFE53
`
const Green = styled(TimeFrameBase)`
    background: #A0CF63;
`
const Gray = styled(TimeFrameBase)`
    background: #F0F3F4;
`
const TimeFrame = ({ date }) => {
    const convertedDateToWithinMonth = useMemo(() => {
        const diffMonths = differenceInCalendarMonths(date, new Date());
        const diffDays = differenceInCalendarDays(date, new Date());
        const diffSeconds = differenceInSeconds(date, new Date());
        if (diffDays <= 0 && diffSeconds <= 0) {
            return <OverdueStyle>
                Overdue
            </OverdueStyle>
        } else if (diffDays <= 30) {
            return <Yellow>
                Within 1 Month
            </Yellow>
        } else if (diffDays <= 60) {
            return  <Green>
                Within 2 Months
            </Green>
        } else {
            return <Gray>
                Within {diffMonths} Months
            </Gray>
        }
    }, [date])
    return <>
        {convertedDateToWithinMonth}
    </>
}

TimeFrame.propTypes = {
    date: PropTypes.string.isRequired
};
export default TimeFrame;

