import React, { useCallback, useContext } from "react";
import { FormSection } from "../FormSection";
import { Icon, Input } from "semantic-ui-react";
import { ButtonWrapper } from "../VendBtn";
import { useFormInput } from "../../hooks/useFormInput";
import { AuthContext } from "../../context/AuthContext";
import { withRouter } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";

export const ForgotPasswordForm = withRouter(({ onSubmit, history }) => {
    const { context, dispatch, forgotPassword } = useContext(AuthContext);

    const username = useFormInput("");

    const handleNewPasswordSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            try {
                await forgotPassword({ username: username.value });
                dispatch({ type: "SEND_PASSWORD_RESET", username: username.value });
                history.push("/login/reset?username=" + encodeURIComponent(username.value));
            } catch (err) {
                console.log("Something went wrong", err);
                dispatch({ type: "SEND_PASSWORD_RESET", sendResetError: err.message });
            }
        },
        [username.value]
    );

    return (
        <form onSubmit={handleNewPasswordSubmit} autoComplete="off">
            <FormSection style={{ marginTop: 40 }}>Enter your username</FormSection>
            <FormSection style={{ marginTop: 40 }}>
                <Input iconPosition="right" placeholder="Username">
                    <input
                        autoComplete="off"
                        id={"forgot-password-username"}
                        data-cy={"forgot-password-username"}
                        name={"username"}
                        {...username}
                    />
                </Input>
            </FormSection>

            <FormSection>
                {context.sendResetError !== "" ? <ErrorMessage>{context.sendResetError}</ErrorMessage> : null}
            </FormSection>

            <FormSection style={{ marginTop: 40 }}>
                <ButtonWrapper type={"submit"}>Reset Password</ButtonWrapper>
            </FormSection>
        </form>
    );
});
