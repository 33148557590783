import React from "react";
import styled from "styled-components";

const CounterBoxWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 308px;
  margin-right: 25px;
  padding: 0 24px;
  margin-bottom: 10px;
  background: #FFFFFF;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
`;

const Title = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 2.4;
`;
const Value = styled.span`
  font-size: 16px;
  line-height: 2.4;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #1B2559;
`

export const CounterBox = ({ title, value }) => {
    return (
        <CounterBoxWrapper>
            <Title>{title}</Title>
            <Value>{value}</Value>
        </CounterBoxWrapper>
    );
};

export default CounterBox;