import { deleteAgreementDocuments, createAgreementDocuments } from "../../graphql/customMutations";
import getDocumentIdFromKey from "./getDocumentIdFromKey";
import { isArray } from "lodash";
import API, { graphqlOperation } from "@aws-amplify/api";

const synchronizeAgreementDocuments = async (agreementId, documents, activeEntity) => {
    if (!isArray(documents)) {
        return { id: null, value: null };
    }

    await Promise.all(
        documents.map(async (doc) => {
            let operation = null;
            let input = null;

            if (typeof doc !== "string") {
                if (doc.removed) {
                    operation = deleteAgreementDocuments;
                    input = {
                        id: doc.id,
                    };
                } else {
                    //TODO handle document meta updates?
                }
            } else {
                const docId = await getDocumentIdFromKey(doc, activeEntity);

                operation = createAgreementDocuments;
                input = {
                    agreementDocumentsDocumentId: docId,
                    agreementDocumentsAgreementId: agreementId,
                    groupsCanAccess: activeEntity?.groupName,
                };
            }

            if (operation === null) return null;

            return await API.graphql(
                graphqlOperation(operation, {
                    input,
                })
            );
        })
    );

    return { id: null, value: null };
};

export default synchronizeAgreementDocuments;
