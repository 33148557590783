import React, { useCallback, useState, useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { addVendor } from "../../graphql/mutations";
import { PageWrapper } from "../../components/PageWrapper";
import { PageHeader } from "../../components/PageHeader";
import Loader from "../../components/Loader";
import styled from "styled-components";
import { ButtonWrapper } from "../../components/VendBtn";
import Select from "react-select";
import { useAlerts } from "../../hooks/useAlerts";
import * as yup from "yup";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";

export const ContentWrapper = styled.div`
    display: flex;
    color: ${({ theme }) => theme.textGray};
    overflow: auto;
    margin-bottom: 5rem;
    & > div {
        width: 50%;
    }
    & > div:nth-child(2) {
        padding-top: 3rem;
        margin-top: 1rem;

        & > div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
                margin-right: 1rem;
                width: 60%;
                text-align: end;
            }
            .analyst-select {
                width: 40%;
            }
        }
    }
`;
export const FormWrapper = styled.form`
    padding: 3rem;
`;

export const InputRow = styled.div`
    display: flex;
    align-items: center;
    margin: 1rem 0;
    width: 100%;

    .input-dropdown {
        width: 80%;
    }
`;

export const InputTitle = styled.span`
    width: 13rem;
    min-width: 13rem;
    margin-right: 2rem;
    font-weight: bold;

    .required-field::after {
        content: "*";
        color: red;
        margin-left: 2px;
    }
`;

export const Input = styled.input`
    width: 80%;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    height: 24px;
    padding: 16px;
    border-radius: 3px;
    color: inherit;
`;

export const TextArea = styled.textarea`
    width: 80%;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    padding: 16px;
    border-radius: 3px;
    color: inherit;
    `;

let schema = yup.object().shape({
    full_legal_name: yup.string().required().label("Name"),
    hierarchy_code: yup.string().label("Hierarhcy Code"),
    lei_code: yup.string().label("LEI Code"),
    company_type_code: yup.string().label("Status Code"),
    brief_description: yup.string().label("Brief Description"),
    assigned_analyst: yup.string().required("Please select VendEx Analyst Assigned for this Vendor to Publish").label("Assigned Analyst"),
});

const AddNewVendorPage = ({ history }) => {
    const [loading, setLoading] = useState(true);
    const [analysts, setAnalysts] = useState([{ label: 'Select analysist', value: "" }]);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({
        full_legal_name: "",
        hierarchy_code: "",
        lei_code: "",
        company_type_code: "PUBLIC_COMPANY",
        brief_description: "",
        assigned_analyst: "",
    });

    useEffect(() => {
        fetchAnalysts();
    }, []);

    const fetchAnalysts = async () => {
        const query = /* GraphQL */ `
            query Vendors {
                listVendexAnalysts {
                    user_id_code
                    full_name
                    role_code
                }
            }
        `;
        try {
            const { data } = await API.graphql(graphqlOperation(query /* , { limit: 2000 } */));
            if (data && data.listVendexAnalysts) {
                const list = data.listVendexAnalysts
                    .filter((el) => el.role_code === "VENDEX_ANALYST")
                    .map((el) => {
                        return {
                            label: el.full_name,
                            value: el.user_id_code,
                        };
                    });
                setAnalysts([...analysts, ...list]);
                setInputValue("assigned_analyst", analysts[0].value);
            }
        } catch (e) {
            console.log("Analyst fetch error: ", e);
            newErrorAlert("Error fetching analysts data");
        } finally {
            setLoading(false);
        }
    };

    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const companyTypeOptions = [
        { label: "Public", value: "PUBLIC_COMPANY" },
        { label: "Private", value: "PRIVATE_COMPANY" },
        { label: "Non-Profit", value: "NON_PROIFIT_COMPANY" },
        { label: "Government", value: "GOVERNMENT" },
        { label: "N/A", value: "NOT_AVAILABLE" },
    ];

    const goBack = useCallback(() => {
        history.goBack();
    }, []);

    const setInputValue = (name, value) => {
        const data = { ...formData };
        data[name] = value;
        setFormData(data);
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            await schema.validate(formData).catch((err) => {
                // Yup validation errors
                console.log("Form validation error:", err);
                throw new Error(err.errors);
            });

            const { data } = await API.graphql(
                graphqlOperation(addVendor, {
                    assigned_analyst: formData.assigned_analyst,
                    brief_description: formData.brief_description,
                    company_type_code: formData.company_type_code,
                    full_legal_name: formData.full_legal_name,
                    hierarchy_code: formData.hierarchy_code,
                    lei_code: formData.lei_code,
                    status_code: "ACTIVE_VENDOR",
                })
            ).catch((e) => {
                console.error("GraphQL fetch error:", e);
                throw new Error(e.errors[0].message);
            });

            if (data && data.addVendor) {
                newSuccessAlert(`New vendor added successfully`);
                history.push(`/vsource/vendors`);
            } else newErrorAlert("Failed to add a new vendor");
        } catch (e) {
            console.log("Form errors: ", e);
            newErrorAlert(e.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageWrapper>
            <LargeConfirmationModal
                show={showModal}
                onClose={() => setShowModal(false)}
                onSubmit={() => {
                    setShowModal(false);
                    history.push(`/vsource/vendors`);
                }}
                onDiscard={() => setShowModal(false)}
                title={"Quit Adding Vendor?"}
                description={"Changes you made so far will not be published and cannot be recovered"}
            />
            <PageHeader title={"New Vendor"}>
                <ButtonWrapper space-between form={"newVendorForm"} type={"submit"}>
                    Publish
                </ButtonWrapper>
                <ButtonWrapper onClick={() => setShowModal(true)}>Exit</ButtonWrapper>
            </PageHeader>
            {loading && <Loader />}
            {!loading && (
                <ContentWrapper>
                    <FormWrapper id={"newVendorForm"} onSubmit={onFormSubmit}>
                        <InputRow>
                            <InputTitle>
                                Vendor name<span className={"required-field"}></span>
                            </InputTitle>
                            <Input
                                type="text"
                                name=""
                                id=""
                                value={formData["full_legal_name"]}
                                onChange={(e) => setInputValue("full_legal_name", e.target.value)}
                                required
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>Vendor Hierarchy Code</InputTitle>
                            <Input
                                type="text"
                                name=""
                                id=""
                                value={formData["hierarchy_code"]}
                                onChange={(e) => setInputValue("hierarchy_code", e.target.value)}
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>LEI Code</InputTitle>
                            <Input
                                type="text"
                                name=""
                                id=""
                                value={formData["lei_code"]}
                                onChange={(e) => setInputValue("lei_code", e.target.value)}
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>Public or Private Company</InputTitle>
                            <Select
                                options={companyTypeOptions}
                                onChange={(e) => setInputValue("company_type_code", e.value)}
                                defaultValue={companyTypeOptions[0]}
                                className={"input-dropdown"}
                            />
                        </InputRow>
                        <InputRow>
                            <InputTitle>Brief Vendor Description</InputTitle>
                            <TextArea
                                rows={5}
                                value={formData["brief_description"]}
                                onChange={(e) => setInputValue("brief_description", e.target.value)}
                            />
                        </InputRow>
                    </FormWrapper>
                    <div>
                        <div>
                            <InputTitle>
                                Vendex Analyst Assigned:<span className={"required-field"}></span>
                            </InputTitle>
                            <Select
                                options={analysts}
                                onChange={(e) => setInputValue("assigned_analyst", e.value)}
                                defaultValue={analysts[0]}
                                className={"analyst-select"}
                                required
                            />
                        </div>
                    </div>
                </ContentWrapper>
            )}
        </PageWrapper>
    );
};

export default AddNewVendorPage;
