import React, { useCallback } from "react";
import * as PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components/macro";

import VendorDetailsPopUp from "../VendorDetailsPopUp/VendorDetailsPopUp";

const Title = styled.span`
    color: #2d6fba;
    font-weight: bold;
    cursor: pointer;
`;

const VendorCell = ({ data, isOpen, onClick }) => {
    const title = _get(data, "row.vendor.title");
    const index = _get(data, "index");
    const onClickCallback = useCallback(() => {
        onClick(index, !isOpen);
    }, [index]);
    return (
        <div>
            <Title onClick={onClickCallback}>{title}</Title>
            <VendorDetailsPopUp isOpen={isOpen} data={data} />
        </div>
    );
};

VendorCell.propTypes = {
    data: PropTypes.any,
    onClick: PropTypes.func,
};
export default VendorCell;
