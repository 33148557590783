import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";

const fetchProduct = async (productId, { dispatch }) => {
    const {
        data: { getProduct: product },
    } = await API.graphql(
        graphqlOperation(queries.getProduct, {
            id: productId,
        })
    );

    dispatch({ type: "SELECT_PRODUCT", product });

    return product;
};

export default fetchProduct;
