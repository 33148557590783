import React, { memo, useEffect, useState, useContext, useRef, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Storage } from "aws-amplify";
import _findIndex from "lodash/findIndex";
import _without from "lodash/without";
import { format, getTime } from "date-fns";

import { marketSegments, listVendexAnalysts } from "../../../graphql/queries";
import styled from "styled-components";
import { updateVendor } from "../../../graphql/mutations";
import Dropdown from "../../../components/DropDown";
import { PageHeader } from "../../../components/PageHeader";
import { CompanyInfoSection } from "../../../components/CompanyInfoSection";
import { ButtonWrapper } from "../../../components/VendBtn";
import { AuthContext } from "../../../context/AuthContext";
import { useAlerts } from "../../../hooks/useAlerts";
import { VsourceContext } from "../../../context/vsource/vsourceContext";
import { USER_ROLES } from "../../../Constants";
import Tabs from "../../../components/Tabs";
import { FormTable } from "../../../components/Tables";
import { editPageVendorQuery } from "../../../graphql/customQueries";
import Loader from "../../../components/Loader";
import { SocialMediaTable } from "./SocialMediaTable";
import { OfficersTable } from "./OfficersTable";
import { LocationsTables } from "./LocationsTables";
import { ColVertical } from "../VendorProfilePage";
import CompanyHeader from "../../../components/CompanyHeader";
import VidSV from "../../../components/VID-SV";
import { LargeConfirmationModal } from "../../../components/ModalConfirmation";
import { moneyConvertWithoutDecimal } from "../../../utils/numberConverts";
import getSortedClassifiersByType from "../../../utils/getSortedClassifiersByType";
import _get from "lodash/get";

const Wrapper = styled.div`
    position: relative;
    display: flex;

    flex-direction: column;
    flex: 2;
    height: 100%;
    width: 100%;

    overflow-y: auto;
    align-items: flex-start;

    padding: 2em;
`;

const PageContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
`;

const CompanyHeaderRow = styled.div`
    min-height: 7em;
    padding: 1em 2em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Columns = styled.div`
    display: flex;
    & > div {
        flex-basis: 0;
        flex-grow: 1;
    }
`;

const ProfileCol = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 2 0 0;
`;

const ProfileRow = styled.div``;

const CompanyLogo = styled.div`
    display: flex;
    position: relative;
    background-image: url("data:image/png;base64, ${(props) => props.img}");
    height: 80px;
    width: 13rem;
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
`;

const AnalystLabel = styled.span`
    color: ${({ theme }) => theme.gray3};
    margin-right: 1em;
`;

export const Input = styled.input`
    width: 80%;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    border-radius: 3px;
    height: 45px;
    padding: 16px;
`;

const NewRowButton = styled.button`
    position: relative;
    top: -2rem;
`;

const VendorDescriptionInput = styled.textarea`
    width: 100%;
    height: 200px;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    border-radius: 3px;
    padding: 1rem;
`;

const VendorDescriptionsWrapper = styled.div`
    & > div {
        margin: 0;
    }
`;

export const vendorStageOptions = [
    { value: "STAGE_VENDOR_IDENTIFIED", label: "I - Vendor Identified" },
    { value: "STAGE_FOUNDATIONAL_DATA", label: "II - Foundational Data" },
    { value: "STAGE_VENDOR_CATALOGED", label: "III - Vendor Cataloged" },
    { value: "STAGE_VENDOR_CONTRIBUTED", label: "IV - Vendor Contributed" },
    { value: "STAGE_VENDOR_APPROVED", label: "V - Vendor Approved" },
    { value: "STAGE_VENDOR_VID_SV_ASSIGNED", label: "VI - VID Assigned" },
];

export const companySizeOptions = [
    { value: "NA", label: "N/A" },
    { value: "1_TO_10", label: "1 - 10" },
    { value: "10_TO_50", label: "10 - 50" },
    { value: "50_TO_100", label: "50 - 100" },
    { value: "100_TO_250", label: "100 - 250" },
    { value: "250_TO_500", label: "250 - 500" },
    { value: "500_TO_750", label: "500 - 750" },
    { value: "750_TO_1000", label: "750 - 1,000" },
    { value: "1000_TO_2500", label: "1,000 - 2,500" },
    { value: "2500_TO_5000", label: "2,500 - 5,000" },
    { value: "5000_TO_7500", label: "5,000 - 7,500" },
    { value: "7500_TO_10000", label: "7,500 - 10,000" },
    { value: "10000_TO_15000", label: "10,000 - 15,000" },
    { value: "15000_TO_25000", label: "15,000 - 25,000" },
    { value: "25000_TO_50000", label: "25,000 - 50,000" },
    { value: "50000_TO_100000", label: "50,000 - 100,000" },
    { value: "100000_TO_250000", label: "100,000 - 250,000" },
    { value: "250000_TO_500000", label: "250,000 - 500,000" },
    { value: "500000_TO_1000000", label: "500,000 - 1,000,000" },
    { value: "1000000_TO_2500000", label: "1,000,000 - 2,500,000" },
    { value: "2500000_TO_5000000", label: "2,500,000 - 5,000,000" },
    { value: "5000000+", label: "5,000,000 +" },
];

export const VendorConfiguratorPage = withRouter(
    memo(({ match }) => {
        const [loading, setLoading] = useState(true);
        const [activeTab, setActiveTab] = useState("overview");
        const history = useHistory();
        const [marketSegmentOptions, setMarketSegmentOptions] = useState([]);
        const [analysts, setAnalysts] = useState([]);
        const [nextReviewInDaysOptions, setNextReviewInDaysOpts] = useState([]);
        const [corporateActionsOptions, setCorporateActionsOpts] = useState([]);
        const [socialMediaChannelOptions, setSocialMediaChannelOpts] = useState([]);
        const [currencyOptions, setCurrencyOptions] = useState([]);
        const [officersOptions, setOfficersOptions] = useState([]);
        const [corporateActionsData, setCorporateActionsData] = useState([]);
        const [selectedLogoFile, setSelectedLogoFile] = useState();
        const [isLogoFileLoaded, setIsLogoFileLoaded] = useState(true);
        const [locationTypeOptions, setLocationTypeOpts] = useState([]);
        const [regionOptions, setRegionOptions] = useState([]);
        const [countryOptions, setCountryOptions] = useState([]);
        const [timeZoneOptions, setTimeZoneOptions] = useState([]);
        const [showModal, setShowModal] = useState(false);
        const [formData, setFormData] = useState({
            full_legal_name: "",
            assigned_analyst: "",
            detailed_description: "",
            status_code: "",
            market_segments: [],
            company_type_code: "",
            year_founded: "",
            headquarters: "",
            number_of_offices: "",
            company_size_code: "",
            next_review_at: "",
        });

        const {
            context: { user },
            userHavePermission,
        } = useContext(AuthContext);

        const logoFile = useRef(null);

        const { newErrorAlert, newSuccessAlert } = useAlerts();

        const { clearVendorData } = useContext(VsourceContext);

        const setInputValue = useCallback(
            (name, value) => {
                const data = { ...formData };
                data[name] = value;
                // console.log("new formdata:", data);
                setFormData(data);
            },
            [formData]
        );

        const setCorporateActionsValue = (name, value, id) => {
            const data = corporateActionsData.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...corporateActionsData];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setCorporateActionsData(rows);
        };

        const addNewCorporateActionsRow = () => {
            // Find smallest ID
            let id = 0;
            corporateActionsData.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setCorporateActionsData([
                ...corporateActionsData,
                {
                    id,
                    action_time: "",
                    description: "",
                    type_code: null,
                },
            ]);
        };

        const removeCorporateActionsRow = (id) => {
            const rows = [...corporateActionsData];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setCorporateActionsData(rows);
        };

        const parseCorporateActionsData = () => {
            // Validate and create JSON to send to the db

            let res = [];

            corporateActionsData.forEach((corporateAction) => {
                if (corporateAction.type_code && corporateAction.action_time) {
                    corporateAction.action_time = new Date(corporateAction.action_time).getTime() / 1000;
                    res.push(corporateAction);
                }
            });

            return JSON.stringify(res);
        };

        const addNewFinancialsRow = () => {
            // Find smallest ID
            let id = 0;
            formData.financials.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setFormData({
                ...formData,
                financials: [
                    ...formData.financials,
                    {
                        id,
                        period_end_date: "",
                        revenue_amount: "",
                        currency_code: null,
                    },
                ],
            });
        };

        const setFinancialsValue = (name, value, id) => {
            const data = formData.financials.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.financials];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                financials: rows,
            });
        };

        const removeFinancialsRow = (id) => {
            const rows = [...formData.financials];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                financials: rows,
            });
        };

        const parseFinancialsData = () => {
            // Validate and create JSON to send to the db

            let res = [];

            formData.financials.forEach((financials) => {
                if (financials.period_end_date && financials.currency_code && financials.revenue_amount) {
                    res.push(financials);
                }
            });

            return JSON.stringify(res);
        };
        const addNewTickerRow = () => {
            // Find smallest ID
            let id = 0;
            formData.tickers.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setFormData({
                ...formData,
                tickers: [
                    ...formData.tickers,
                    {
                        id,
                        stock_exchange: null,
                        ticker_symbol: null,
                    },
                ],
            });
        };
        const removeTickersRow = (id) => {
            const rows = [...formData.tickers];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                tickers: rows,
            });
        };
        const parseTickersData = () => {
            // Validate and create JSON to send to the db

            let res = [];

            formData.tickers.forEach((tickers) => {
                if (tickers.stock_exchange || tickers.ticker_symbol) {
                    res.push(tickers);
                }
            });

            return JSON.stringify(res);
        };
        const setTickersValue = (name, value, id) => {
            const data = formData.tickers.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.tickers];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                tickers: rows,
            });
        };
        const addNewSocialMediaRow = () => {
            // Find smallest ID
            let id = 0;
            formData.social_media.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setFormData({
                ...formData,
                social_media: [
                    ...formData.social_media,
                    {
                        id,
                        social_media_type: null,
                        url: "",
                    },
                ],
            });
        };
        const removeSocialMediaRow = (id) => {
            const rows = [...formData.social_media];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                social_media: rows,
            });
        };
        const parseSocialMediaData = () => {
            // Validate and create JSON to send to the db
            let res = [];

            formData.social_media.forEach((sm) => {
                if (sm.url && sm.social_media_type_code) {
                    res.push(sm);
                }
            });

            return JSON.stringify(res);
        };
        const setSocialMediaValue = (name, value, id) => {
            const data = formData.social_media.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.social_media];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                social_media: rows,
            });
        };
        const addNewInvestorRelationsRow = () => {
            // Find smallest ID
            let id = 0;
            formData.investor_relations.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setFormData({
                ...formData,
                investor_relations: [
                    ...formData.investor_relations,
                    {
                        id,
                        contact_person: "",
                        blog_url: "",
                        email_address: "",
                        phone_number: "",
                    },
                ],
            });
        };
        const removeInvestorRelationsRow = (id) => {
            const rows = [...formData.investor_relations];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                investor_relations: rows,
            });
        };
        const parseInvestorRelationsData = () => {
            // Validate and create JSON to send to the db
            let res = [];

            formData.investor_relations.forEach((ir) => {
                if (ir.blog_url || ir.email_address || ir.phone_number || ir.contact_person) {
                    res.push(ir);
                }
            });

            return JSON.stringify(res);
        };
        const setInvestorRelationsValue = (name, value, id) => {
            const data = formData.investor_relations.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.investor_relations];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                investor_relations: rows,
            });
        };
        const addNewOfficersRow = () => {
            // Find the smallest ID
            let id = 0;
            if (formData.officers) {
                formData.officers.forEach((el) => {
                    if (parseInt(el.id, 10) < id) {
                        id = el.id;
                    }
                });

                id -= 1;

                setFormData({
                    ...formData,
                    officers: [
                        ...formData.officers,
                        {
                            id,
                            business_role_code: null,
                            full_name: "",
                            business_role_additional: "",
                        },
                    ],
                });
            } else {
                setFormData({
                    ...formData,
                    officers: [
                        {
                            id: -1,
                            business_role_code: null,
                            full_name: "",
                            business_role_additional: "",
                        },
                    ],
                });
            }
        };
        const removeOfficersRow = (id) => {
            const rows = [...formData.officers];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                officers: rows,
            });
        };
        const parseOfficersData = () => {
            // Validate and create JSON to send to the db
            let res = [];

            formData.officers.forEach((el) => {
                if (el.business_role_code && el.full_name) {
                    res.push(el);
                }
            });
            return JSON.stringify(res);
        };
        const setOfficerValue = (name, value, id) => {
            const data = formData.officers.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.officers];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                officers: rows,
            });
        };
        const setLocationValue = (name, value, id) => {
            const data = formData.locations.filter((el) => el.id === id)[0];
            data[name] = value;
            const rows = [...formData.locations];
            // Find the index of the row
            const index = _findIndex(rows, { id });

            // Replace the row at index
            rows.splice(index, 1, data);
            setFormData({
                ...formData,
                locations: rows,
            });
        };
        const setCustomerSupportValue = (name, value, id, location_id) => {
            // Find the customer support row
            const locationsRow = formData.locations.filter((el) => el.id === location_id)[0];

            const data = locationsRow.customer_support.filter((customer_support) => customer_support.id === id)[0];
            data[name] = value;

            // Find the index of the customer support row
            const customerSupportIndex = _findIndex(locationsRow.customer_support, { id });
            // Replace the customer support row at index
            locationsRow.customer_support.splice(customerSupportIndex, 1, data);

            // Find the index of the location row
            const rows = [...formData.locations];
            const index = _findIndex(rows, { location_id });

            // Replace the row at index
            rows.splice(index, 1, locationsRow);
            setFormData({
                ...formData,
                locations: rows,
            });
        };
        const removeLocationRow = (id) => {
            const rows = [...formData.locations];
            // Find the index of the row
            const index = _findIndex(rows, { id });
            // Remove the row at the index
            rows.splice(index, 1);
            setFormData({
                ...formData,
                locations: rows,
            });
        };
        const removeCustomerSupportRow = (location_id, id) => {
            const rows = [...formData.locations];
            // Find the index of the location row
            const index = _findIndex(rows, { id: location_id });
            // Find the index of the customer support row
            const customerSupportIndex = _findIndex(rows[index].customer_support, { id });
            // Remove the row at the index
            rows[index].customer_support.splice(customerSupportIndex, 1);
            setFormData({
                ...formData,
                locations: rows,
            });
        };
        const addNewLocationRow = () => {
            // Find smallest ID
            let id = 0;
            formData.locations.forEach((el) => {
                if (parseInt(el.id, 10) < id) {
                    id = el.id;
                }
            });
            id -= 1;
            setFormData({
                ...formData,
                locations: [
                    ...formData.locations,
                    {
                        id,
                        city: "",
                        country_code: null,
                        division: "",
                        name: "",
                        phone_number: "",
                        postal_code: "",
                        region_code: null,
                        state_province: "",
                        street_address_1: "",
                        street_address_2: "",
                        type_code: locationTypeOptions[0].value,
                    },
                ],
            });
        };
        const addCustomerSupportRow = (location_id) => {
            // Find the index of the location row
            const rows = [...formData.locations];
            const index = _findIndex(rows, { id: location_id });
            // Find smallest ID
            let id = 0;
            if (rows[index].customer_support) {
                rows[index].customer_support.forEach((el) => {
                    if (parseInt(el.id, 10) < id) {
                        id = el.id;
                    }
                });
            } else {
                rows[index].customer_support = [];
            }

            id -= 1;

            rows[index].customer_support.push({
                id,
                email_address: "",
                phone_number: "",
                support_hours: "",
                support_portal_url: "",
                time_zone_code: null,
            });
            setFormData({
                ...formData,
                locations: [...formData.locations],
            });
        };
        const parseLocationsData = () => {
            let res = [];
            formData.locations.forEach((location) => {
                if (location.type_code && location.name) {
                    let customerSupportList = [];

                    if (location.customer_support) {
                        location.customer_support.forEach((cs) => {
                            cs.location_id = location.id;
                            customerSupportList.push(cs);
                        });

                        location.customer_support = customerSupportList;
                    } else {
                        location.customer_support = [];
                    }
                    res.push(location);
                } else {
                    newErrorAlert("Location Type and Name are Required. Location Data was not updated");
                }
            });

            return JSON.stringify(res);
        };

        const logoFileChangeHandler = async (event) => {
            const file = event.target.files[0];
            try {
                setIsLogoFileLoaded(false);
                const res = await Storage.put(`vsource/logos/${file.name}`, file, {
                    contentType: "image/png", // contentType is optional
                });

                if (res && res.key) {
                    newSuccessAlert("Logo file uploaded successfully");
                    setSelectedLogoFile(file);
                }
            } catch (error) {
                console.log("Error uploading file: ", error);
                newErrorAlert("Error uploading new logo file");
            } finally {
                setIsLogoFileLoaded(true);
            }
        };

        /* useEffect(() => {
            // Vendors list for immediate parent dropdown etc.
            if (!vendorsListLoading && Object.keys(vendors).length > 0) {
                let list = [];

                for (const key in vendors) {
                    // Filter out current vendor
                    if (vendors[key].id !== match.params.id) {
                        list.push({
                            label: vendors[key].full_legal_name,
                            value: vendors[key].id,
                        });
                    }
                }
                setVendorsList(list);
            } else if (
                !vendorsListLoading &&
                Object.keys(vendors).length === 0
            ) {
                fetchVendorsList({});
            }
        }, [vendors]); */

        useEffect(() => {
            const fetchVendor = async () => {
                let vendor = {};
                try {
                    const { data } = await API.graphql(
                        graphqlOperation(editPageVendorQuery, {
                            id: match.params.id,
                        })
                    );
                    if (data) {
                        vendor = data.vendor;
                        vendor.assigned_analyst = data.vendor.assigned_analyst_code;
                        vendor.market_segments = vendor.market_segments.map((el) => el.code);
                        vendor.historical_names = vendor.historical_names ?? "";
                        vendor.alias_names = vendor.alias_names ?? "";
                        vendor.locations = vendor.locations ?? [];
                        // Fetch Logo url
                        if (vendor.vendor_logo_filename) {
                            Storage.get(`vsource/logos/${vendor.vendor_logo_filename}`, {
                                level: "public",
                            })
                                .then((s3ImageUrl) => {
                                    vendor = {
                                        ...vendor,
                                        logo_url: s3ImageUrl,
                                    };
                                })
                                .catch((e) => console.error("Error fetching logo URL: ", e));
                        }

                        // console.log("Vendor data: ", vendor);
                        return vendor;
                    }
                } catch (e) {
                    console.log("Vendor fetch error: ", e);
                    newErrorAlert(e.message);
                }
            };

            const fetchMarketSegmentOptions = async () => {
                try {
                    const { data } = await API.graphql(graphqlOperation(marketSegments));
                    if (data && data.marketSegments) {
                        return data.marketSegments.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Market segments fetch error: ", e);
                    newErrorAlert("Error fetching market segments data");
                }
            };
            const fetchOfficersOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("BUSINESS_ROLE");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Officers options fetch error: ", e);
                }
            };
            const fetchAnalysts = async () => {
                try {
                    const { data } = await API.graphql(graphqlOperation(listVendexAnalysts));
                    if (data && data.listVendexAnalysts) {
                        return data.listVendexAnalysts
                            .filter((analyst) => analyst.role_code === "VENDEX_ANALYST")
                            .map((el) => ({
                                label: el.full_name,
                                value: el.user_id_code,
                            }));
                    }
                } catch (e) {
                    console.log("Analysts fetch error: ", e);
                    newErrorAlert("Error fetching analysts data");
                }
            };
            const fetchNextReviewInDaysOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("NEXT_REVIEW_TIME_IN_DAYS");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Next Review in Days fetch error: ", e);
                    newErrorAlert("Error fetching Next Review in Days data");
                }
            };
            const fetchCorporateActionsOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("CORPORATE_ACTION");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Corporate Actions fetch error: ", e);
                    newErrorAlert("Error fetching Corporate Actions data");
                }
            };
            const fetchSocialMediaOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("SOCIAL_MEDIA_CHANNEL");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Corporate Actions fetch error: ", e);
                    newErrorAlert("Error fetching Corporate Actions data");
                }
            };
            const fetchCurrencyCodes = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("CURRENCY");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Currency Codes fetch error: ", e);
                    newErrorAlert("Error fetching Currency Codes data");
                }
            };
            const fetchLocationTypes = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("LOCATION_TYPE");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Location Types fetch error: ", e);
                    newErrorAlert("Error fetching Location Types data");
                }
            };
            const fetchRegionOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("REGION");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Region Types fetch error: ", e);
                    newErrorAlert("Error fetching Region Types data");
                }
            };
            const fetchCountryOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("COUNTRY");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.value,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Country List fetch error: ", e);
                    newErrorAlert("Error fetching Country List data");
                }
            };
            const fetchTimezoneOptions = async () => {
                try {
                    const { data } = await getSortedClassifiersByType("TIME_ZONE");

                    if (data && data.listClassifiersByType) {
                        return data.listClassifiersByType.map((el) => ({
                            label: el.description,
                            value: el.code,
                        }));
                    }
                } catch (e) {
                    console.log("Time Zones fetch error: ", e);
                    newErrorAlert("Error fetching Time Zones data");
                }
            };

            const loadData = async () => {
                setLoading(true);
                // Load all of the data before showing the form
                try {
                    const res = await Promise.all([
                        fetchVendor(),
                        fetchMarketSegmentOptions(),
                        fetchOfficersOptions(),
                        fetchAnalysts(),
                        fetchNextReviewInDaysOptions(),
                        fetchCorporateActionsOptions(),
                        fetchSocialMediaOptions(),
                        fetchCurrencyCodes(),
                        fetchLocationTypes(),
                        fetchRegionOptions(),
                        fetchCountryOptions(),
                        fetchTimezoneOptions(),
                    ]);
                    console.log("res:", res);
                    setFormData({
                        ...formData,
                        ...res[0],
                    });
                    setMarketSegmentOptions(res[1]);
                    setAnalysts(res[3]);
                    setNextReviewInDaysOpts(res[4]);
                    setCorporateActionsData(res[0]?.corporate_actions || []);
                    setCorporateActionsOpts(res[5]);
                    setSocialMediaChannelOpts(res[6]);
                    setCurrencyOptions(res[7]);
                    setOfficersOptions(res[2]);
                    setLocationTypeOpts(res[8]);
                    setRegionOptions(res[9]);
                    setCountryOptions(res[10]);
                    setTimeZoneOptions(res[11]);
                } catch (e) {
                    console.log("Load data error: ", e);
                    newErrorAlert(e.message);
                } finally {
                    if (activeTab === "") {
                        setActiveTab("overview");
                    }
                    setLoading(false);
                }
            };

            loadData();
        }, [match.params.id]);

        const submitData = async () => {
            const parseDate = (date) => {
                if (!date) {
                    return null;
                }
                // to timestamp
                return new Date(date).getTime() / 1000;
            };

            try {
                setLoading(true);
                console.log("formData:", formData);
                const _formData = {
                    ...formData,
                    next_review_at: parseDate(formData.next_review_at),
                    last_reviewed_at: parseDate(formData.last_reviewed_at),
                    last_published_at: parseDate(formData.last_published_at),
                    historical_names: formData.historical_names
                        .split(",")
                        .map((value) => value.trim())
                        .join(","),
                    alias_names: formData.alias_names
                        .split(",")
                        .map((value) => value.trim())
                        .join(","),
                    corporate_actions: parseCorporateActionsData(),
                    financials: parseFinancialsData(),
                };

                const params = {
                    id: match.params.id,
                    full_legal_name: _formData.full_legal_name,
                    assigned_analyst: _formData.assigned_analyst,
                    brief_description: _formData.brief_description,
                    detailed_description: _formData.detailed_description,
                    status_code: _formData.status_code,
                    company_type_code: _formData.company_type_code,
                    year_founded: parseInt(_formData.year_founded, 10),
                    headquarters: _formData.headquarters,
                    number_of_offices: parseInt(_formData.number_of_offices, 10),
                    next_review_at: _formData.next_review_at,
                    next_review_in_days_code: _formData.next_review_in_days_code,
                    market_segments: _formData.market_segments.join(","),
                    company_size_code: _formData.company_size_code,
                    last_reviewed_at: _formData.last_reviewed_at,
                    last_reviewed_by: _formData.last_reviewed_by_code,
                    last_published_by: _formData.last_published_by_code,
                    last_published_at: _formData.last_published_at,
                    hierarchy_code: _formData.hierarchy_code,
                    immediate_parent_name: _formData.immediate_parent_name,
                    immediate_parent_vendor_id_code: _formData.immediate_parent_vendor_id_code,
                    immediate_parent_hierarchy_code: _formData.immediate_parent_hierarchy_code,
                    ultimate_parent_name: _formData.ultimate_parent_name,
                    ultimate_parent_vendor_id_code: _formData.ultimate_parent_vendor_id_code,
                    ultimate_parent_hierarchy_code: _formData.ultimate_parent_hierarchy_code,
                    lei_code: _formData.lei_code,
                    url: _formData.url,
                    historical_names: _formData.historical_names,
                    alias_names: _formData.alias_names,
                    corporate_actions: _formData.corporate_actions,
                    financials: _formData.financials,
                    tickers: parseTickersData(),
                    social_media: parseSocialMediaData(),
                    investor_relations: parseInvestorRelationsData(),
                    vendor_logo_filename: selectedLogoFile?.name ?? formData.vendor_logo_filename,
                    officers: parseOfficersData(),
                    locations: parseLocationsData(),
                    vendor_stage_code: _formData.vendor_stage_code,
                    publish_source: "VSOURCE"
                };
                console.log("params:", params);

                const { data } = await API.graphql(graphqlOperation(updateVendor, params)).catch((e) => {
                    console.error("GraphQL fetch error:", e);
                    throw new Error(e.errors[0].message);
                });

                if (data && data.updateVendor) {
                    newSuccessAlert(`Vendor updated successfully`);
                    // Clear vendor data from cache to fetch and display new data from db
                    clearVendorData(match.params.id);
                    history.push(`/vsource/vendors/${match.params.id}`);
                } else newErrorAlert("Failed to edit the vendor");
            } catch (e) {
                console.log("Form errors: ", e);
                newErrorAlert(e.message);
            } finally {
                setLoading(false);
            }
        };

        const tabsData = [
            {
                title: "Vendor Overview",
                onClick: () => setActiveTab("overview"),
                active: activeTab === "overview",
            },
            {
                title: "Products",
                onClick: () => console.log("products"),
                disabled: true,
                active: activeTab === "products",
            },
            {
                title: "Officers",
                onClick: () => setActiveTab("officers"),
                active: activeTab === "officers",
            },
            {
                title: "Locations & Support",
                onClick: () => setActiveTab("locations"),
                active: activeTab === "locations",
            },
        ];

        const VendorInfoTableData = () => {
            const tableData = [
                {
                    title: "Vendor Name",
                    value: formData.full_legal_name,
                    required: true,
                    onChange: (val) => setInputValue("full_legal_name", val),
                },
                {
                    title: "Vendor Hierarchy Code",
                    value: formData.hierarchy_code,
                    onChange: (val) => setInputValue("hierarchy_code", val),
                },
                {
                    title: "Aliases",
                    value: formData.alias_names,
                    onChange: (val) => setInputValue("alias_names", val),
                },
                {
                    title: "Historical Names",
                    value: formData.historical_names,
                    onChange: (val) => setInputValue("historical_names", val),
                },
                {
                    title: "Active/Historical",
                    value: [
                        { label: "Active", value: "ACTIVE_VENDOR" },
                        { label: "Historical", value: "HISTORICAL_VENDOR" },
                    ],
                    type: "dropdown",
                    props: { selected: formData.status_code },
                    onChange: (e) => setInputValue("status_code", e.value),
                },
                {
                    title: "Market segment",
                    value: marketSegmentOptions,
                    type: "checkboxes",
                    props: { selected: formData.market_segments },
                    onChange: (value) => {
                        // If market segment not in list add it there
                        if (!formData.market_segments.includes(value)) {
                            setInputValue("market_segments", [...formData.market_segments, value]);
                        }
                        // If market segment is in list remove it
                        else {
                            setInputValue("market_segments", _without(formData.market_segments, value));
                        }
                    },
                },
                {
                    title: "Public/Private",
                    name: "public_private",
                    value: [
                        { label: "Public", value: "PUBLIC_COMPANY" },
                        { label: "Private", value: "PRIVATE_COMPANY" },
                        { label: "Non-Profit", value: "NON_PROIFIT_COMPANY" },
                        { label: "Government", value: "GOVERNMENT" },
                        { label: "N/A", value: "NOT_AVAILABLE" },
                    ],
                    type: "dropdown",
                    props: { selected: formData.company_type_code },
                    onChange: (e) => setInputValue("company_type_code", e.value),
                },
                {
                    title: "",
                    value: "",
                    type: "button",
                    props: {
                        colSpan: true,
                        label: "Add New Ticker",
                        onClick: () => addNewTickerRow(),
                    },
                },
                {
                    title: "Year Founded",
                    value: formData.year_founded,
                    onChange: (value) => setInputValue("year_founded", value),
                },
                {
                    title: "Headquarters",
                    value: formData.headquarters,
                    onChange: (value) => setInputValue("headquarters", value),
                },
                {
                    title: "Number of Offices",
                    value: formData.number_of_offices,
                    onChange: (value) => setInputValue("number_of_offices", value),
                },
                {
                    title: "Number of Employees",
                    type: "dropdown",
                    props: { selected: formData.company_size_code },
                    value: companySizeOptions,
                    onChange: (e) => setInputValue("company_size_code", e.value),
                },
                /* { title: "Main Customer Service Portal", value: "" }, */
                {
                    title: "Website",
                    value: formData.url,
                    onChange: (value) => setInputValue("url", value),
                },
                {
                    title: "LEI Code",
                    value: formData.lei_code,
                    onChange: (value) => setInputValue("lei_code", value),
                },
            ];

            // Add tickers rows
            // Find the index of the "Public/Private" row
            const index = _findIndex(tableData, { name: "public_private" });
            let lastTickerRowId = 0;

            if (formData.tickers && formData.tickers.length > 0) {
                // Add new row at the index
                formData.tickers.forEach((ticker) => {
                    tableData.splice(index + lastTickerRowId + 1, 0, {
                        title: "Exchange",
                        value: ticker.stock_exchange,
                        onChange: (val) => setTickersValue("stock_exchange", val, ticker.id),
                        props: {
                            onRemove: () => removeTickersRow(ticker.id),
                        },
                    });
                    tableData.splice(index + lastTickerRowId + 2, 0, {
                        title: "Ticker",
                        value: ticker.ticker_symbol,
                        onChange: (val) => setTickersValue("ticker_symbol", val, ticker.id),
                    });

                    lastTickerRowId += 2;
                });
            }
            return tableData;
        };

        const CorporateHierarchyTableData = () => {
            return [
                {
                    title: "Immediate Parent",
                    value: formData.immediate_parent_name,
                    onChange: (value) => setInputValue("immediate_parent_name", value),
                },
                {
                    title: "Immediate Parent Vendor ID",
                    value: formData.immediate_parent_vendor_id_code,
                    onChange: (value) => setInputValue("immediate_parent_vendor_id_code", value),
                },
                {
                    title: "Immediate Parent Vendor Hierarchy Code",
                    value: formData.immediate_parent_hierarchy_code,
                    onChange: (value) => setInputValue("immediate_parent_hierarchy_code", value),
                },
                {
                    title: "Ultimate Parent",
                    value: formData.ultimate_parent_name,
                    onChange: (value) => setInputValue("ultimate_parent_name", value),
                },
                {
                    title: "Ultimate Parent Vendor ID",
                    value: formData.ultimate_parent_vendor_id_code,
                    onChange: (value) => setInputValue("ultimate_parent_vendor_id_code", value),
                },
                {
                    title: "Ultimate Parent Vendor Hierarchy Code",
                    value: formData.ultimate_parent_hierarchy_code,
                    onChange: (value) => setInputValue("ultimate_parent_hierarchy_code", value),
                },
            ];
        };

        const AuditInfoTableData = [
            {
                title: "Vendex Analyst Last Reviewed",
                value: analysts,
                type: "dropdown",
                onChange: (e) => setInputValue("last_reviewed_by_code", e.value),
                props: {
                    isClearable: true,
                    selected: formData.last_reviewed_by_code,
                },
            },
            {
                title: "Last Reviewed Date and Time",
                value: formData.last_reviewed_at,
                type: "date",
                dateFormat: "dd-MMM-yyyy",
                saveDateFormat: 'YYYY-MM-DD',
                formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
                onChange: (date) => setInputValue("last_reviewed_at", date),
            },
            {
                title: "VendEx Publisher Last Published",
                value: analysts,
                type: "dropdown",
                onChange: (e) => setInputValue("last_published_by_code", e.value),
                props: {
                    isClearable: true,
                    selected: formData.last_published_by_code,
                },
            },
            {
                title: "Last Published Date and Time",
                value: formData.last_published_at,
                type: "date",
                dateFormat: "dd-MMM-yyyy",
                saveDateFormat: 'YYYY-MM-DD',
                formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
                onChange: (date) => setInputValue("last_published_at", date),
            },
            {
                title: "Next Review Number Of Days",
                value: nextReviewInDaysOptions,
                type: "dropdown",
                onChange: (e) => setInputValue("next_review_in_days_code", e.value),
                props: {
                    isClearable: true,
                    selected: formData.next_review_in_days_code,
                },
            },
            {
                title: "Next Review Date",
                value: formData.next_review_at,
                type: "date",
                dateFormat: "dd-MMM-yyyy",
                saveDateFormat: 'YYYY-MM-DD',
                formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
                onChange: (date) => setInputValue("next_review_at", date),
            },
        ];

        const CorporateActionsTableData = (data) => {
            let tableData = [];
            if (data) {
                data.forEach((corporateAction) => {
                    tableData.push({
                        title: "Type",
                        value: corporateActionsOptions,
                        type: "dropdown",
                        onChange: (e) => setCorporateActionsValue("type_code", e.value, corporateAction.id),
                        props: {
                            selected: corporateAction.type_code || null,
                            isClearable: true,
                            onRemove: () => removeCorporateActionsRow(corporateAction.id),
                        },
                    });

                    tableData.push({
                        title: "Date",
                        value: corporateAction.action_time,
                        type: "date",
                        dateFormat: "dd-MMM-yyyy",
                        saveDateFormat: 'YYYY-MM-DD',
                        formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
                        onChange: (date) => setCorporateActionsValue("action_time", date, corporateAction.id),
                    });

                    tableData.push({
                        title: "Description",
                        value: corporateAction.description,
                        onChange: (val) => setCorporateActionsValue("description", val, corporateAction.id),
                    });
                });
            }
            return tableData;
        };

        const FinancialsTableData = (data) => {
            let tableData = [];
            if (data) {
                data.sort((a, b) => getTime(_get(a, `period_end_date`)) > getTime(_get(b, `period_end_date`)) ? 1 : -1).forEach((financials) => {
                    tableData.push({
                        title: "Financial Year Ending",
                        value: financials.period_end_date,
                        type: "date",
                        dateFormat: "dd-MMM-yyyy",
                        saveDateFormat: 'YYYY-MM-DD',
                        formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
                        onChange: (val) => {
                            setFinancialsValue("period_end_date", val, financials.id)
                        },
                        props: {
                            onRemove: () => removeFinancialsRow(financials.id),
                        },
                    });

                    tableData.push({
                        title: "Revenue Amount",
                        type: "number",
                        value: financials.revenue_amount,
                        formatedValue: (value) => moneyConvertWithoutDecimal(value, true),
                        onChange: (val) => setFinancialsValue("revenue_amount", val, financials.id),
                    });

                    tableData.push({
                        title: "Currency",
                        value: currencyOptions,
                        type: "dropdown",
                        onChange: (e) => setFinancialsValue("currency_code", e.value, financials.id),
                        props: {
                            selected: financials.currency_code || null,
                        },
                    });
                });
            }

            return tableData;
        };

        const InvestorRelationsTableData = (data) => {
            let tableData = [];
            if (data) {
                data.forEach((row) => {
                    tableData.push({
                        title: "Contact Person",
                        value: row.contact_person,
                        onChange: (val) => setInvestorRelationsValue("contact_person", val, row.id),
                        props: {
                            onRemove: () => removeInvestorRelationsRow(row.id),
                        },
                    });

                    tableData.push({
                        title: "Email Address",
                        value: row.email_address,
                        onChange: (val) => setInvestorRelationsValue("email_address", val, row.id),
                    });

                    tableData.push({
                        title: "Phone Number",
                        value: row.phone_number,
                        onChange: (val) => setInvestorRelationsValue("phone_number", val, row.id),
                    });
                    tableData.push({
                        title: "Blog URL",
                        value: row.blog_url,
                        onChange: (val) => setInvestorRelationsValue("blog_url", val, row.id),
                    });
                });
            }

            return tableData;
        };

        if (loading /* || vendorsList.length === 0 */) {
            return <Loader />;
        }

        return (
            <Wrapper key={"conf-page-wrapper"}>
                <LargeConfirmationModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={() => {
                        setShowModal(false);
                        history.push(`/vsource/vendors/${match.params.id}`);
                    }}
                    onDiscard={() => setShowModal(false)}
                    title={"Quit Editing?"}
                    description={"Changes you made so far will not be published and cannot be recovered"}
                />
                <PageHeader title="Vendor Profile">
                    {userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER) && (
                        <React.Fragment>
                            <ButtonWrapper
                                space-between
                                onClick={() => {
                                    // `current` points to the mounted file input element
                                    logoFile.current.click();
                                }}
                            >
                                {isLogoFileLoaded ? "Upload Logo" : "Uploading..."}
                            </ButtonWrapper>
                            <input
                                type="file"
                                id="file"
                                ref={logoFile}
                                style={{ display: "none" }}
                                onChange={logoFileChangeHandler}
                            />
                            <ButtonWrapper
                                space-between
                                onClick={isLogoFileLoaded ? submitData : null}
                                disabled={!isLogoFileLoaded}
                            >
                                Publish
                            </ButtonWrapper>
                            <ButtonWrapper onClick={() => setShowModal(true)}>Exit</ButtonWrapper>
                        </React.Fragment>
                    )}
                </PageHeader>
                <CompanyHeaderRow>
                    <div style={{ width: "300px" }}>
                        <Input
                            placeholder="Full Legal Name"
                            value={formData.full_legal_name}
                            onChange={(e) => setInputValue("full_legal_name", e.target.value)}
                        />
                    </div>
                    {/* <CompanyLogo img={selectedVendor.vendorLogo} /> */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            height: "100%",
                        }}
                    >
                        <VidSV value={`${formData.vendor_id_code}-${formData.hierarchy_code}`} />
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AnalystLabel>VendEx Analyst Assigned </AnalystLabel>
                            <div style={{ minWidth: "200px" }}>
                                <Dropdown
                                    data={analysts}
                                    defaultValue={
                                        analysts.filter((el) => el.value === formData.assigned_analyst_code)[0]
                                    }
                                    onChange={(e) => setInputValue("assigned_analyst", e.value)}
                                    value={analysts.filter((el) => el.value === formData.assigned_analyst_code)[0]}
                                />
                            </div>
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <AnalystLabel>Vendor Stage </AnalystLabel>
                            <div style={{ minWidth: "200px" }}>
                                <Dropdown
                                    data={vendorStageOptions}
                                    defaultValue={
                                        vendorStageOptions.filter((el) => el.value === formData.vendor_stage_code)[0]
                                    }
                                    onChange={(e) => setInputValue("vendor_stage_code", e.value)}
                                    value={
                                        vendorStageOptions.filter((el) => el.value === formData.vendor_stage_code)[0]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </CompanyHeaderRow>
                <div style={{ margin: "0 2em 2em 2em" }}>
                    <Tabs tabs={tabsData} />
                </div>
                <PageContainer>
                    {activeTab === "overview" && (
                        <React.Fragment key={"overview-tab-block"}>
                            <ProfileRow>
                                <CompanyInfoSection>
                                    <VendorDescriptionsWrapper>
                                        <CompanyHeader title={"Brief Vendor Description"} />
                                        <VendorDescriptionInput
                                            placeholder={"Brief Vendor Description"}
                                            value={formData.brief_description}
                                            style={{ height: "100px" }}
                                            onChange={(e) => setInputValue("brief_description", e.target.value)}
                                        />
                                        <CompanyHeader title={"Detailed Description"} />
                                        <VendorDescriptionInput
                                            placeholder={"Detailed Description"}
                                            value={formData.detailed_description}
                                            onChange={(e) => setInputValue("detailed_description", e.target.value)}
                                        />
                                    </VendorDescriptionsWrapper>
                                </CompanyInfoSection>
                            </ProfileRow>
                            <Columns>
                                <FormTable title={"Vendor Information"} data={VendorInfoTableData()} />
                                <ColVertical>
                                    <FormTable title={"Corporate Hierarchy"} data={CorporateHierarchyTableData()} />
                                    <div key={"sm-table-block"}>
                                        <SocialMediaTable
                                            data={formData.social_media}
                                            socialMediaChannelOptions={socialMediaChannelOptions}
                                            removeSocialMediaRow={removeSocialMediaRow}
                                            setSocialMediaValue={setSocialMediaValue}
                                            key={"social-media-table"}
                                        />
                                        <div style={{ paddingLeft: "2rem" }}>
                                            <NewRowButton onClick={() => addNewSocialMediaRow()}>
                                                Add Social Media Data
                                            </NewRowButton>
                                        </div>
                                    </div>
                                </ColVertical>
                            </Columns>
                            <Columns>
                                <div>
                                    <FormTable
                                        title={"Corporate Actions"}
                                        data={CorporateActionsTableData(corporateActionsData)}
                                    />
                                    <div style={{ paddingLeft: "2rem" }}>
                                        <NewRowButton onClick={() => addNewCorporateActionsRow()}>
                                            Add Corporate Action
                                        </NewRowButton>
                                    </div>
                                </div>
                                <div>
                                    <FormTable title="Financials" data={FinancialsTableData(formData.financials)} />
                                    <div style={{ paddingLeft: "2rem" }}>
                                        <NewRowButton onClick={() => addNewFinancialsRow()}>
                                            Add Financials
                                        </NewRowButton>
                                    </div>
                                </div>
                            </Columns>
                            <Columns>
                                <div>
                                    <FormTable
                                        title={"Investor Relations"}
                                        data={InvestorRelationsTableData(formData.investor_relations)}
                                    />
                                    <div style={{ paddingLeft: "2rem" }}>
                                        <NewRowButton onClick={() => addNewInvestorRelationsRow()}>
                                            Add Investor Relations
                                        </NewRowButton>
                                    </div>
                                </div>
                                <FormTable title={"Audit Information"} data={AuditInfoTableData} />
                            </Columns>
                        </React.Fragment>
                    )}
                    {activeTab === "officers" && (
                        <ProfileRow>
                            <div style={{ width: "80%" }}>
                                <OfficersTable
                                    data={formData.officers}
                                    options={officersOptions}
                                    removeRow={removeOfficersRow}
                                    setValue={setOfficerValue}
                                    key={"officers-table"}
                                />
                                <div style={{ paddingLeft: "2rem" }}>
                                    <button onClick={() => addNewOfficersRow()}>Add New Officer</button>
                                </div>
                            </div>
                        </ProfileRow>
                    )}
                    {activeTab === "locations" && (
                        <ProfileRow>
                            <LocationsTables
                                data={formData.locations}
                                locationTypes={locationTypeOptions}
                                countries={countryOptions}
                                regions={regionOptions}
                                timeZones={timeZoneOptions}
                                setLocationValue={setLocationValue}
                                setCustomerSupportValue={setCustomerSupportValue}
                                removeLocationRow={removeLocationRow}
                                removeCustomerSupportRow={removeCustomerSupportRow}
                                addCustomerSupportRow={addCustomerSupportRow}
                            />
                            <div style={{ paddingLeft: "2rem" }}>
                                <button onClick={() => addNewLocationRow()}>Add Location</button>
                            </div>
                        </ProfileRow>
                    )}
                </PageContainer>
            </Wrapper>
        );
    })
);
