import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import updateAgreement from "./updateAgreement";
import getDocumentIdFromKey from "./getDocumentIdFromKey";

/**
 *
 * @param agreementId
 * @param documentKey
 * @param {object} options
 * @param {function} options.dispatch
 * @param {object} options.user
 * @returns {Promise<void>}
 */
const addDocumentToAgreement = async (agreementId, documentKey, { dispatch, user, noUpdate, activeEntity }) => {
    const docId = await getDocumentIdFromKey(documentKey, activeEntity);

    if (!docId) throw new Error(`Document ${documentKey} not found in system.`);
    try {
        await API.graphql(
            graphqlOperation(mutations.createAgreementDocuments, {
                input: {
                    agreementDocumentsDocumentId: docId,
                    agreementDocumentsAgreementId: agreementId,
                    groupsCanAccess: activeEntity?.groupName,
                },
            })
        );

        if (noUpdate) return;

        await updateAgreement(
            {
                id: agreementId,
            },
            { dispatch, user }
        );
    } catch (e) {
        console.log("Error adding document to the agreement:", e);
    }
};

export default addDocumentToAgreement;
