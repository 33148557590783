import React, { useCallback } from "react";
import * as PropTypes from "prop-types";
import _get from "lodash/get";
import styled from "styled-components/macro";

import ProductDetailsPopUp from "../ProductDetailsPopUp/ProductDetailsPopUp";

const Title = styled.span`
    color: #2D6FBA;
    font-weight: bold;
    cursor: pointer;
`;

const ProductCell = ({ data, isOpen, onClick }) => {
    const title = _get(data, "value.title");
    const index = _get(data, "index");
    const onClickCallback = useCallback(() => {
        onClick(index, !isOpen);
    }, [index]);
    return <div>
        <Title onClick={onClickCallback}>{title}</Title>
        <ProductDetailsPopUp
            isOpen={isOpen}
            data={data}
        />
    </div>
}

ProductCell.propTypes = {
    data: PropTypes.any,
    onClick: PropTypes.func,
};
export default ProductCell;
