import * as mutations from "../../graphql/mutations";
import {
    updateAgreementMeta as _updateAgreementMeta,
    updateProductAndPricingGroup,
    createProductAndPricingGroup,
    deleteProductAndPricingGroup,
} from "../../graphql/customMutations";

import upsertObject from "./upsertObject";
import API, { graphqlOperation } from "@aws-amplify/api";
import updateAgreement from "./updateAgreement";
import synchronizeDefinitionTerms from "./synchronizeDefinitionTerms";
import synchronizeProductAndPricingGroups from "./synchronizeProductAndPricingGroups";
import stringToEnum from "./stringToEnum";
import metaConfig from "../../config/metaConfig";
import { chunk } from "lodash";

const metaFieldMap = Object.keys(metaConfig)
    .map((sectionId) => {
        return metaConfig[sectionId].reduce((memo, item) => {
            if (item.id === "id") return memo;

            if (item.modelName) {
                memo[item.id] = item.modelName;
            } else if (item.type === "multi") {
                memo[item.id] = "enum";
            } else {
                memo[item.id] = "metaProperty";
            }

            return memo;
        }, {});
    })
    .reduce((memo, field) => ({ ...memo, ...field }), {});

/* If the data is not from "AgreementMeta" table then call the update mutation,
     otherwise return the object for updating the "AgreementMeta" table values */
export const handleDataProperty = (metaId, propertyName, data, user, activeEntity) => {
    const property = data[propertyName];
    switch (metaFieldMap[propertyName]) {
        case "metaProperty":
            return { id: propertyName, value: property };
        case "enum":
            return {
                id: propertyName,
                value: property ? stringToEnum(property) : null,
            };
        case "definitionTerm":
            const terms = data[propertyName].items;

            return synchronizeDefinitionTerms(
                metaId,
                terms,
                mutations.createDefinitionTerm,
                mutations.updateDefinitionTerm,
                mutations.deleteDefinitionTerm,
                { user, activeEntity }
            );
        case "contact":
            return upsertObject(property, mutations.createContact, mutations.updateContact, propertyName, activeEntity);
        case "address":
            return upsertObject(property, mutations.createAddress, mutations.updateAddress, propertyName, activeEntity);
        case "thirdParty":
            return upsertObject(
                property,
                mutations.createThirdParty,
                mutations.updateThirdParty,
                propertyName,
                activeEntity
            );
        case "dataRedistributionRights":
            return upsertObject(
                property,
                mutations.createDataRedistributionRights,
                mutations.updateDataRedistributionRights,
                propertyName,
                activeEntity
            );
        case "automaticRenewal":
            return upsertObject(
                property,
                mutations.createAutomaticRenewal,
                mutations.updateAutomaticRenewal,
                propertyName,
                activeEntity
            );
        case "usageInspection":
            return upsertObject(
                property,
                mutations.createUsageInspection,
                mutations.updateUsageInspection,
                propertyName,
                activeEntity
            );
        case "auditTerms":
            return upsertObject(
                property,
                mutations.createAuditTerms,
                mutations.updateAuditTerms,
                propertyName,
                activeEntity
            );
        case "usageMonitoring":
            return upsertObject(
                property,
                mutations.createUsageMonitoring,
                mutations.updateUsageMonitoring,
                propertyName,
                activeEntity
            );
        case "productAndPricingGroup":
            const groups = data[propertyName].items;

            return synchronizeProductAndPricingGroups(
                metaId,
                groups,
                createProductAndPricingGroup,
                updateProductAndPricingGroup,
                deleteProductAndPricingGroup,
                { user, activeEntity }
            );
        default:
            return { id: propertyName, value: data[propertyName] };
    }
};

// Update "AgreementMeta" table in chunks
// TODO: Update all at once?
const chunkedUpdate = async (metaId, input) => {
    const inputKeyChunks = chunk(Object.keys(input), 10);

    return await Promise.all(
        inputKeyChunks.map((keys) => {
            return API.graphql(
                graphqlOperation(_updateAgreementMeta, {
                    input: keys.reduce(
                        (memo, key) => {
                            memo[key] = input[key];
                            return memo;
                        },
                        { id: metaId }
                    ),
                })
            );
        })
    );
};

const updateAgreementMeta = async (agreement, data, { dispatch, user, activeEntity }) => {
    console.log("data:", data);
    /* "data" attribute includes all of the values from the agreement meta form.
        "handleDataProperty" function makes update mutation for all of the values other than "AgreementMeta" table and returns input for "AgreementMeta" table update mutation
    */

    const metaId = agreement.meta.id;

    // Remove "Document Vendor Name" value because this is saved directly to the "Agreement" table
    const documentVendorName = data["documentVendorName"];
    delete data["documentVendorName"];

    const object = await Promise.all(
        Object.keys(data).map((propertyName) => handleDataProperty(metaId, propertyName, data, user, activeEntity))
    );

    const input = object.reduce((memo, { id, value }) => {
        if (id) memo[id] = value;

        return memo;
    }, {});

    // Update agreement meta in chunks
    const updatedMeta = await chunkedUpdate(metaId, input);

    // Update the Agreement table data
    await updateAgreement(
        {
            id: agreement.id,
            documentVendorName,
        },
        { dispatch, user, activeEntity }
    );

    dispatch({ type: "UPDATE_AGREEMENT_META", meta: updatedMeta });
};

export default updateAgreementMeta;
