import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { QRCodeCanvas } from "qrcode.react";
import styled from 'styled-components';
import { Button } from "semantic-ui-react";

const MFASetupWrapper = styled.div`
  padding: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
  canvas {
    margin: 24px auto;
  }
  
  code {
    background: #eee;
    padding: 4px 6px;
    border-radius: 4px;
    display: inline-block;
    color: #242424;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
`;

const CodeInput = styled.input`
  outline: none;
  margin: 12px 0;
  border: 2px solid #eee;
  font-size: 24px;
  text-align: center;
  border-radius: 6px;
  padding: 8px 16px;

  &.error {
    border-color: #b61515;
  }
`;

const ErrorMessage = styled.span`
  color: #b61515;
  font-style: italic;
`;

const SetupMFA = props => {
    const { context, setupMFA, verifyTotpToken, getNonCachedUser } = useContext(AuthContext);
    const [qrToken, setQrToken] = useState();
    const [success, setSuccess] = useState(context.user.preferredMFA !== 'NOMFA');
    const [inputError, setInputError] = useState({ error: false });
    const inputRef = useRef();

    const checkMFAOnCognito = useCallback(async () => {
        const user = await getNonCachedUser();
        setSuccess(user.preferredMFA !== 'NOMFA');
    });

    const fetchMFAToken = useCallback(async () => {
        const token = await setupMFA({ user: context.user });
        setQrToken(token);
    }, [setupMFA, context.user])

    const verifyMFAToken = useCallback(async () => {
        const input = inputRef.current.value;

        if (input && input.length === 6) {
            try {
                const resp = await verifyTotpToken({
                    user: context.user,
                    code: input
                });

                if (resp === true) {
                    setSuccess(true);
                }
            } catch (e) {
                setInputError({ error: true, message: 'Code could not be verified, please try again.' })
            }
        }
    }, [verifyTotpToken, context.user]);

    useEffect(() => {
        try {
            fetchMFAToken();
            checkMFAOnCognito();
        } catch (e) {
            console.error(e);
        }
    }, []);

    if (!success) {
        return <>
            <MFASetupWrapper>
                <h2>Setup Multi-Factor Authentication</h2>
                <p>To activate multi-factor authentication using TOTP on your Vendex account, please scan the QR code below
                    using your preferred authentication app.</p>
                <QRCodeCanvas
                    value={`otpauth://totp/${context.user.attributes.email}?secret=${qrToken}&issuer=Vendex+Solutions`}
                    size={300}
                    imageSettings={{
                        src: "https://app.vendexsolutions.com/favicon.ico",
                        x: undefined,
                        y: undefined,
                        height: 24,
                        width: 24,
                        excavate: true,
                    }}
                />

                <p>If you are unable to scan the QR code, you can use the code below to manually set up your
                authenticator.</p>

                <code>{qrToken?.match(/.{1,4}/g).join('-')}</code>

                {inputError.error && inputError.message && <ErrorMessage>{inputError.message}</ErrorMessage>}
                <CodeInput placeholder="Your six digit code" ref={inputRef} className={inputError.error && 'error'}/>
                <Button style={{ margin: 0 }} color="blue" onClick={verifyMFAToken}>Next</Button>
            </MFASetupWrapper>
        </>;
    } else {
        return <MFASetupWrapper>
            <h2>You're All Set!</h2>
            <p>You have multi-factor authentication setup on this account!</p>
        </MFASetupWrapper>
    }

};

export default SetupMFA;