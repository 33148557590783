import React, { useMemo, useState, useEffect } from "react";
import { Modal } from "semantic-ui-react";
import _get from "lodash/get";
import styled from "styled-components/macro";
import * as PropTypes from "prop-types";

import ValueCard from "../ValueCard/ValueCard";
import VendorDetailsTable from "../VendorDetailsTable/VendorDetailsTable";

const CardWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const TableWrapper = styled.div`
  width: 100%;
  display: flex;
`;
export const VendorDetailsPopUp = ({
    data,
    isOpen = false,
    onChange = () => {}
}) => {
    const [showModal, setShowModal] = useState(isOpen);
    const vendorName = useMemo(() => _get(data, 'value.title'), [data.value.title])
    const vendorData = useMemo(() => _get(data, 'value.data'), [data.value.data])
    useEffect(() => {
        setShowModal(isOpen)
        onChange(isOpen)
    }, [isOpen]);

    return (
        <Modal
            closeIcon
            onClose={() => setShowModal(false)}
            onOpen={() => setShowModal(true)}
            open={showModal}
            style={{ maxWidth: '700px' }}
        >

            <Modal.Header>Vendor Detail: {vendorName}</Modal.Header>

            <Modal.Content style={{ padding: '2rem' }}>
                <CardWrapper>
                    <ValueCard
                        title="Vendor ID"
                        value={vendorData?.id}
                        size="medium"
                    />
                    <ValueCard
                        title="Total Annual Cost"
                        value={vendorData?.totalAnnualCost}
                        size="medium"
                    />
                    <ValueCard
                        title="Products"
                        value={vendorData.products.length}
                        size="medium"
                    />
                </CardWrapper>
                <TableWrapper>
                    <VendorDetailsTable data={vendorData?.products} />
                </TableWrapper>
            </Modal.Content>

        </Modal>
    )
};

VendorDetailsPopUp.propTypes = {
    isOpen: PropTypes.bool,
    onChange: PropTypes.func,
};

export default VendorDetailsPopUp;
