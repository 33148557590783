import { useCallback, useEffect, useMemo, useState } from "react";
import { getFieldValueWithDiff, getRowClassNames } from "../../helpers/vlink/revisionHelpers";
import VendexActions from "./VendexActions";
import VendorActions from "./VendorActions";
import styled from "styled-components";
import _get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import { format } from "date-fns";
import UpdateModal from "./UpdateFieldsetModal";
import ImageField from "./ImageField";
import ConfirmationDialog from "../ConfirmationDialog";

import LocationIcon from "../icons/LocationIcon";
import PhoneIcon from "../icons/PhoneIcon";
import EnvelopeIcon from "../icons/EnvelopeIcon";
import BuildingIcon from "../icons/BuildingIcon";
import ClockIcon from "../icons/ClockIcon";
import VendexLogoWithoutCaption from "../VendexLogoWithoutCaption";
import FormRowList from "./FormRowList";

export const RowWrapper = styled.div`
    &.response {
        background-color: #fff2cc;
        border-radius: 10px;
        padding: 20px 10px;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;

    &.p0 {
        padding: 0;
    }

    button.button {
        font-size: 14px;
        padding: 6px 12px;
        align-self: flex-end;
    }

    .hidden {
        display: none;
    }

    &.deleted p:not(.no-data) {
        color: #9f3f47;
        text-decoration: line-through;
    }

    &.new {
        background-color: #dcf2d9;
        // span {
        // 	background-color: transparent !important;
        // 	text-decoration: none !important;
        // }
    }

    p.multiselect_tree {
        display: grid;
        grid-column-gap: 0.5em;
        grid-row-gap: 0.5em;
        grid-template-columns: repeat(3, 1fr);

        .ms-column {
            > span {
                display: block;
            }
        }
    }
`;

export const PaleItalicText = styled.span`
    font-style: italic;
    color: lightgray;

    &.white {
        color: #a19b9b;
    }
`;

const ResponseBox = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;

    h6 {
        font-weight: bold;
    }

    .header {
        display: flex;
        justify-content: space-between;

        .left {
            display: flex;
            align-items: center;
            gap: 5px;

            .logo {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px;
                height: 36px;
                background-color: white;
                border-radius: 50%;
                font-size: 7px;
                color: white;
            }

            h4 {
                margin: 0;
                padding: 0;
            }

            span {
                color: #787885;
                font-size: 12px;
            }
        }

        .right {
            span {
                color: #2872eb;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .comment-box {
        display: flex;
        padding: 10px 0;
        gap: 10px;
        border-bottom: 1px solid rgba(181, 181, 195, 0.6);
    }

    .final-title {
        margin-top: 10px;
    }
`;

const CardRow = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 8px 0;

    div {
        flex-grow: 1;

        div {
            display: flex;
            align-items: center;
            gap: 5px;
        }
    }

    p {
        margin: 0;
    }

    span {
        flex-shrink: 0;
    }

    i {
        color: #babac7;
    }
`;

const LocationContent = styled.div`
    width: 100%;

    svg path {
        fill: #b5b5c3;
    }

    .no-data {
        margin: 10px 0 0;
        padding-top: 10px;
        text-align: center;
        color: #b5b5c3;
        font-style: italic;
        background-color: inherit;
    }

    .support-overview {
        margin-top: 10px;
        display: flex;
        gap: 2px;
        color: #3699ff;
        cursor: pointer;

        i {
            font-size: 16px;
            color: #3699ff;
        }

        p {
            margin: 0;
        }
    }
`;

const CustomerSupportContent = styled.div`
    margin: 8px 0;
    width: 100%;

    .hidden {
        display: none;
    }

    svg path {
        fill: #b5b5c3;
    }

    .support-list {
        &.hide {
            display: none;
        }
    }

    .support-item {
        margin-bottom: 10px;
        padding: 0 5px;

        & > div {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 2px 0;
        }

        & > div.vendor-actions {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            gap: 10px;
            margin-top: 10px;

            & .undelete {
                transform: translateX(-290%);
            }
        }
    }
`;

export const FieldsWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: ${(props) => (props.narrow ? "10px" : "20px")};

    &.vidsv {
        flex-direction: column;
    }

    p {
        width: 100%;
        white-space: pre-line;
        overflow-wrap: break-word;

        span.added {
            background-color: #e6ffe6;
        }

        span.removed {
            background-color: #ffe6e6;
            text-decoration: line-through;
        }
    }

    &.location {
        width: 100%;
    }

    button {
        padding: 6px 12px;
    }
`;

export const InputWrapper = styled.div`
    position: relative;
    width: ${(props) => (props.singleRow ? "100%" : `calc(${props.customWidth} - 15px)` || "300px")};
    min-width: 200px;
    /* Chrome, Safari, Edge, Opera */
    & input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    & input[type="number"] {
        -moz-appearance: textfield;
    }
    &.hidden {
        display: none;
    }

    p {
        width: ${(props) => (props.singleRow ? `calc(${props.customWidth} - 15px)` || "300px" : "100%")};
        white-space: pre-line;

        span.added {
            background-color: #e6ffe6;
        }

        span.removed {
            background-color: #ffe6e6;
            text-decoration: line-through;
        }
    }

    label {
        display: block;
        margin: 5px 0;
        font-size: 16px;
        font-weight: bold;
    }

    .ui {
        width: 100%;
        &.input {
            width: ${(props) => (props.singleRow ? `calc(${props.customWidth} - 15px)` || "300px" : "100%")};
            > input {
                padding: 10px;
            }
        }
    }

    .react-datepicker-wrapper {
        input {
            margin: 0;
            max-width: 100%;
            flex: 1 0 auto;
            outline: 0;
            text-align: left;
            line-height: 1.21428571em;
            padding: 0.67857143em 1em;
            background: #fff;
            border: 1px solid rgba(34, 36, 38, 0.15);
            color: rgba(0, 0, 0, 0.87);
            border-radius: 0.28571429rem;
            transition: border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
            transition: box-shadow 0.1s ease, border-color 0.1s ease;
            transition: box-shadow 0.1s ease, border-color 0.1s ease, -webkit-box-shadow 0.1s ease;
            box-shadow: none;
        }
    }
    .react-datepicker__navigation {
        padding: 0;
    }
    textarea {
        width: 100%;
        background: #fbfcfd;
        border: 1px solid #e8e8f0;
        padding: 16px;
        border-radius: 3px;
        color: inherit;
        resize: vertical;
    }

    .error {
        color: red;
    }
`;

export default function FormRow({
    rowList,
    vsourceData,
    row,
    rowIndex,
    context,
    level,
    locked,
    dispatch,
    requestQueue,
    setRequestQueue,
    group,
    autoCertifyGroup,
    targetId,
    withActions = true,
    responseRow,
    untouchedRows,
    setUntouchedRows,
    rowName,
    classifiers,
    onExpand,
    title,
}) {
    const [confirmationModalRowIndex, setConfirmationModalRowIndex] = useState(null);
    const [showRow, setShowRow] = useState(row.tableName === "location" && row.supportLocations.length < 2);

    const certifyConfirmHandler = useCallback(
        (rowIndex) => {
            dispatch({
                type: "CHANGE_ROW_VALUE",
                rowIndex,
                key: ["certified", "proposed", "hasLatestRevision", "submitted"],
                value: [!row.certified, false, true, false],
            });

            if (rowList[rowIndex].proposed) {
                dispatch({ type: "RESET_ROW", rowIndex });
            }

            setRequestQueue([
                ...requestQueue,
                {
                    type:
                        (rowList[rowIndex].latestRevision != null &&
                            rowList[rowIndex].latestRevision.vendorSubmittedAt == null) ||
                        context === "VENDEX"
                            ? "UPDATE"
                            : "CREATE",
                    rowIndex,
                },
            ]);
        },
        [rowList, dispatch, requestQueue, setRequestQueue, row.certified, context]
    );

    const certifyClickHandler = useCallback(
        (rowIndex) => {
            if (rowList[rowIndex].proposed && !responseRow) {
                setConfirmationModalRowIndex(rowIndex);
                return;
            }

            certifyConfirmHandler(rowIndex);
        },
        [certifyConfirmHandler, rowList, responseRow]
    );

    const acceptHandler = useCallback(
        (rowIndex) => {
            dispatch({
                type: "CHANGE_ROW_VALUE",
                rowIndex,
                key: "accepted",
                value: rowList[rowIndex].accepted ? null : true,
            });

            if (rowList[rowIndex].accepted === false) {
                dispatch({ type: "RESET_ROW", rowIndex, context });
            }
            setRequestQueue([...requestQueue, { type: rowList[rowIndex].revisionId ? "UPDATE" : "CREATE", rowIndex }]);
        },
        [rowList, dispatch, requestQueue, setRequestQueue, context]
    );

    const duplicateClickHandler = useCallback(
        (rowIndex) => {
            let fieldsClone = cloneDeep(rowList[rowIndex].fields);
            if (rowList[rowIndex].tableName === "product_vidsv") {
                let nameField = fieldsClone.find((field) => field.name === "name");
                let name = (nameField.latestNewValue ?? nameField.vsourceValue) + " copy #" + (rowList.length + 1);
                nameField.vsourceValue = name;
            }
            dispatch({ type: "ADD_ROW", fields: fieldsClone });
        },
        [dispatch, rowList]
    );

    const updateModalCloseHandler = useCallback(
        (rowIndex) => {
            dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "status", value: "CREATED" });
            if (rowList[rowIndex].status === "CREATING") {
                dispatch({ type: "DELETE_ROW", rowIndex, level: "hard" });
            }
        },
        [rowList, dispatch]
    );

    const updateModalDeleteHandler = useCallback(
        (rowIndex) => {
            let afterDeleteCallback = null;
            if (rowList[rowIndex].new && !rowList[rowIndex].submitted) {
                dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "revisionStatusTypeCode", value: "DELETED" });
                afterDeleteCallback = () => dispatch({ type: "DELETE_ROW", rowIndex, level: "hard" });
            } else {
                dispatch({ type: "DELETE_ROW", rowIndex });
            }
            setRequestQueue([
                ...requestQueue,
                {
                    type:
                        (rowList[rowIndex].latestRevision != null &&
                            rowList[rowIndex].latestRevision.vendorSubmittedAt == null) ||
                        context === "VENDEX"
                            ? "UPDATE"
                            : "CREATE",
                    rowIndex,
                    cb: afterDeleteCallback,
                },
            ]);
        },
        [rowList, dispatch, requestQueue, setRequestQueue, context]
    );

    const updateModalSaveHandler = useCallback(
        (rowIndex, values, comment, vendexResponseType) => {
            values.forEach((value, fieldIndex) => {
                if (context === "VENDOR") {
                    const isImage = _get(rowList, `[${rowIndex}].fields[0].type`) === "image";
                    if (isImage) {
                        dispatch({
                            type: "CHANGE_FIELD_VALUE",
                            context,
                            rowIndex,
                            fieldIndex,
                            key: "value",
                            value: value,
                        });
                    } else {
                        dispatch({
                            type: "CHANGE_FIELD_VALUE",
                            context,
                            rowIndex,
                            fieldIndex,
                            key: "value",
                            value,
                        });
                    }
                } else {
                    if (vendexResponseType === "REJECT_WITHOUT_EDITING") {
                        dispatch({
                            type: "CHANGE_FIELD_VALUE",
                            context,
                            rowIndex,
                            fieldIndex,
                            key: "submittedVendexValue",
                            value: row.fields[fieldIndex].vsourceValue,
                        });
                    } else {
                        dispatch({
                            type: "CHANGE_FIELD_VALUE",
                            context,
                            rowIndex,
                            fieldIndex,
                            key: "submittedVendexValue",
                            value,
                        });
                    }
                }
            });

            if (context === "VENDOR") {
                dispatch({
                    type: "CHANGE_ROW_VALUE",
                    rowIndex,
                    key: ["certified", "proposed", "hasLatestRevision"],
                    value: [false, true, true],
                });
                if (!row.latestRevision) {
                    dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: ["latestRevision"], value: [{}] });
                }
            } else {
                dispatch({
                    type: "CHANGE_ROW_VALUE",
                    context,
                    rowIndex,
                    key: ["declineComment", "vendexResponseType", "accepted"],
                    value: [comment, vendexResponseType, false],
                });
            }
            if (row.status === "CREATING") {
                setShowRow(true);
            }
            dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "status", value: "CREATED" });
            const revisionExists =
                (rowList[rowIndex].latestRevision != null &&
                    rowList[rowIndex].latestRevision.vendorSubmittedAt == null) ||
                context === "VENDEX";
            setRequestQueue([
                ...requestQueue,
                {
                    type: revisionExists ? "UPDATE" : "CREATE",
                    rowIndex,
                    cb: (revisionId) => {
                        if (!revisionExists && row.tableName === "location") {
                            dispatch({
                                type: "CHANGE_ROW_VALUE",
                                rowIndex,
                                key: "supportLocations",
                                value: [{ ...row.supportLocations[0], parentItemId: -revisionId }],
                            });
                        }
                    },
                },
            ]);
        },
        [rowList, context, dispatch, requestQueue, setRequestQueue, row.certified, row.proposed, group, row.new]
    );

    const undeleteClickHandler = useCallback(
        (rowIndex) => {
            rowList[rowIndex].fields.forEach(function (field, fieldIndex) {
                dispatch({
                    type: "CHANGE_FIELD_VALUE",
                    context,
                    rowIndex,
                    fieldIndex,
                    key: "value",
                    value: field.vsourceValue,
                });
            });

            if (context === "VENDOR") {
                dispatch({
                    type: "CHANGE_ROW_VALUE",
                    rowIndex,
                    key: ["certified", "proposed", "deleted"],
                    value: [false, true, false],
                });
            }

            dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "status", value: "CREATED" });
            setRequestQueue([
                ...requestQueue,
                {
                    type: "UPDATE",
                    rowIndex,
                },
            ]);
        },
        [rowList, context, dispatch, setRequestQueue, requestQueue]
    );

    function dismissClickHandler() {
        dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "revisionStatusTypeCode", value: "CLOSED" });
        setRequestQueue([
            ...requestQueue,
            {
                type: "UPDATE",
                rowIndex,
                cb: () => dispatch({ type: "DELETE_ROW", rowIndex, level: "hard" }),
            },
        ]);
    }

    useEffect(() => {
        if (row.tableName === "customer_support") {
        }
    });

    useEffect(
        function collectUntouchedRows() {
            if (context === "VENDEX") {
                if (row.submittedRevision && row.submittedRevision.vendorActionTypeCode === "PROPOSAL") {
                    setUntouchedRows((latestUntouchedRows) => {
                        let currentRowIndex = latestUntouchedRows.findIndex((untouchedRow) => {
                            return (
                                untouchedRow.rowName === rowName &&
                                untouchedRow.rowIndex === rowIndex &&
                                untouchedRow.itemId === row.itemId
                            );
                        });

                        if (row.accepted == null) {
                            if (currentRowIndex === -1) {
                                return [...latestUntouchedRows, { rowName, rowIndex, itemId: row.itemId }];
                            }

                            return latestUntouchedRows;
                        } else {
                            if (currentRowIndex > -1) {
                                let nextUntouchedRows = [...latestUntouchedRows];
                                nextUntouchedRows.splice(currentRowIndex, 1);
                                return nextUntouchedRows;
                            }

                            return latestUntouchedRows;
                        }
                    });
                }
            } else {
                if (group && !row.new && !responseRow) {
                    setUntouchedRows((latestUntouchedRows) => {
                        let currentRowIndex = latestUntouchedRows.findIndex((untouchedRow) => {
                            return (
                                untouchedRow.rowName === rowName &&
                                untouchedRow.rowIndex === rowIndex &&
                                untouchedRow.itemId === row.itemId
                            );
                        });

                        if (!row.certified && !row.proposed) {
                            if (currentRowIndex === -1) {
                                return [...latestUntouchedRows, { rowName, rowIndex, itemId: row.itemId }];
                            }

                            return latestUntouchedRows;
                        } else {
                            if (currentRowIndex > -1) {
                                let nextUntouchedRows = [...latestUntouchedRows];
                                nextUntouchedRows.splice(currentRowIndex, 1);
                                return nextUntouchedRows;
                            }

                            return latestUntouchedRows;
                        }
                    });
                }
            }
        },
        [
            group,
            responseRow,
            row.certified,
            row.submittedRevision,
            row.accepted,
            row.itemId,
            row.new,
            row.proposed,
            rowIndex,
            rowName,
            setUntouchedRows,
            context,
        ]
    );

    useEffect(
        function autoTriggerCertify() {
            if (autoCertifyGroup && autoCertifyGroup === group && !row.new) {
                rowList.forEach((row, rowIndex) => {
                    if (!row.certified && !row.proposed) {
                        dispatch({
                            type: "CHANGE_ROW_VALUE",
                            rowIndex,
                            key: ["certified", "proposed"],
                            value: [true, false],
                        });
                    }
                });
            }
        },
        [autoCertifyGroup, dispatch, group, row.new, rowList]
    );

    const locationAndSupportFields = useMemo(() => {
        if (row.tableName !== "location" && row.tableName !== "customer_support") {
            return [];
        }

        let res = [];
        row.fields.forEach((field) => {
            res[field.name] = { ...field };
        });

        return res;
    }, [row.fields, row.tableName]);

    const renderVendorActions = () => {
        return (
            <>
                {!row.isReadonly && (
                    <>
                        {context === "VENDOR" && !locked && withActions && (
                            <VendorActions
                                className={row.tableName === "location" ? "location" : ""}
                                row={row}
                                // Hide the 'Authenticate" button for the foundational block in demo and prod
                                group={group}
                                onUndeleteClick={() => undeleteClickHandler(rowIndex)}
                                onCertifyClick={() => certifyClickHandler(rowIndex)}
                                onEditClick={() =>
                                    dispatch({ type: "CHANGE_ROW_VALUE", rowIndex, key: "status", value: "EDITING" })
                                }
                                onDuplicateClick={() => duplicateClickHandler(rowIndex)}
                            />
                        )}

                        {context === "VENDEX" &&
                            row.submittedRevision &&
                            !row.submittedRevision.vendexRespondedAt &&
                            row.submittedRevision.vendorActionTypeCode === "PROPOSAL" && (
                                <VendexActions
                                    row={row}
                                    onAcceptClick={() => acceptHandler(rowIndex)}
                                    onRejectClick={() =>
                                        dispatch({
                                            type: "CHANGE_ROW_VALUE",
                                            rowIndex,
                                            key: "status",
                                            value: "EDITING",
                                        })
                                    }
                                />
                            )}
                    </>
                )}
            </>
        );
    };

    function renderField(field) {
        let diff = getFieldValueWithDiff(field, row, context, responseRow ? "response" : "main");

        if (diff.toString()[0] === "[" && field.type !== "image") {
            return null;
            // return <PaleItalicText className={(row.new || responseRow) ? 'white' : ''}>{diff}</PaleItalicText>
        }

        switch (field.type) {
            case "image":
                return <ImageField diff={diff} />;
            case "url": {
                if (Array.isArray(diff) && diff.length === 1) {
                    return <a href={diff[0]} target="_blank" dangerouslySetInnerHTML={{ __html: diff[0] }}></a>;
                }
                break;
            }
            default:
                break;
        }

        return <p className={field.type} style={field.style || {}} dangerouslySetInnerHTML={{ __html: diff }}></p>;
    }

    const rowClassNames = useMemo(() => getRowClassNames(row, context, responseRow), [row, context, responseRow]);

    return (
        <RowWrapper
            className={[
                responseRow ? "response" : "",
                row.itemTypeCode === "RECORD" ? "record" : "",
                showRow && row.tableName !== "location" ? "show-border" : "",
            ]}
        >
            {responseRow && (
                <ResponseBox>
                    <div className="header">
                        <div className="left">
                            <div className="logo">
                                <VendexLogoWithoutCaption />
                            </div>
                            <div className="info">
                                <h4>VendEx</h4>
                                <span>{format(new Date(row.respondedAt), "H:mm DD-MMM-YYYY")}</span>
                            </div>
                        </div>
                        <div className="right">
                            {row.new &&
                                row.responded &&
                                row.accepted === false &&
                                row.submittedRevision.vendexResponseTypeCode === "REJECT_WITHOUT_EDITING" && (
                                    <span onClick={dismissClickHandler}>Dismiss</span>
                                )}
                        </div>
                    </div>
                    <div className="comment-box">
                        <h6>Comment:</h6>
                        <p>{row.submittedRevision?.vendexDeclineComment}</p>
                    </div>
                    <div className="final-title">
                        <h6>Final Value:</h6>
                    </div>
                </ResponseBox>
            )}
            <Row key={rowIndex} className={rowClassNames}>
                {/* <FieldsWrapper className={row.tableName === 'location' ? 'location' : ''}> */}
                <FieldsWrapper narrow={row.tableName === "product_vidsv"}>
                    {row.tableName !== "location" && row.tableName !== "customer_support" ? (
                        <>
                            {row.fields.map((field, fieldIndex) => (
                                <InputWrapper
                                    key={fieldIndex}
                                    singleRow={!!field.singleRow}
                                    customWidth={
                                        field.widthRatio
                                            ? field.widthRatio * 100 + "%"
                                            : (field.type === "select" ? "200px" : 1 / row.fields.length) * 100 + "%"
                                    }
                                >
                                    {!["hidden", "modal-only"].includes(field.visibility) && renderField(field)}
                                </InputWrapper>
                            ))}
                        </>
                    ) : (
                        <>
                            {row.tableName === "location" ? (
                                <LocationContent>
                                    <CardRow>
                                        <i className="building outline icon"></i>
                                        <div>
                                            <div>
                                                {renderField(locationAndSupportFields.name)}
                                                {renderField(locationAndSupportFields.typeCode)}
                                            </div>
                                        </div>
                                    </CardRow>
                                    <CardRow>
                                        <LocationIcon />
                                        <div>
                                            <div>
                                                {renderField(locationAndSupportFields.streetAddress1)}
                                                {renderField(locationAndSupportFields.streetAddress2)}
                                            </div>
                                            <div>
                                                {renderField(locationAndSupportFields.city)}
                                                {renderField(locationAndSupportFields.stateProvince)}
                                                {renderField(locationAndSupportFields.postalCode)}
                                            </div>
                                            <div>
                                                {renderField(locationAndSupportFields.countryCode)}
                                                {renderField(locationAndSupportFields.regionCode)}
                                            </div>
                                            <div>{renderField(locationAndSupportFields.division)}</div>
                                        </div>
                                    </CardRow>
                                    <CardRow>
                                        <PhoneIcon />
                                        {renderField(locationAndSupportFields.phoneNumber)}
                                    </CardRow>

                                    {renderVendorActions()}

                                    <>
                                        {row.supportLocations.length < 2 ? (
                                            <p className="no-data">No customer support added</p>
                                        ) : (
                                            <div className="support-overview" onClick={() => setShowRow(!showRow)}>
                                                <p>Customer support ({row.supportLocations.length - 1})</p>
                                                <i className={`icon angle ${showRow ? "up" : "down"}`}></i>
                                            </div>
                                        )}
                                        <FormRowList
                                            level={level}
                                            vsourceData={vsourceData}
                                            title=""
                                            subTitle=""
                                            rowListData={row.supportLocations}
                                            targetId={targetId}
                                            key={0}
                                            context={context}
                                            locked={locked}
                                            untouchedRows={untouchedRows}
                                            setUntouchedRows={setUntouchedRows}
                                            rowName="customerSupport"
                                            classifiers={classifiers}
                                            onExpand={onExpand}
                                            multiple={true}
                                            group={group}
                                            autoCertifyGroup={autoCertifyGroup}
                                            show={showRow}
                                        />
                                    </>
                                </LocationContent>
                            ) : (
                                <CustomerSupportContent className="support-row">
                                    {
                                        // <div className={`support-list ${showSupportList ? '' : 'hide'}`}>
                                        <div>
                                            <div className="support-item">
                                                <CardRow>
                                                    <PhoneIcon />
                                                    <div>{renderField(locationAndSupportFields.phoneNumber)}</div>
                                                </CardRow>
                                                <CardRow>
                                                    <EnvelopeIcon />
                                                    <div>{renderField(locationAndSupportFields.emailAddress)}</div>
                                                </CardRow>
                                                <CardRow>
                                                    <BuildingIcon />
                                                    <div>{renderField(locationAndSupportFields.supportPortalUrl)}</div>
                                                </CardRow>
                                                <CardRow>
                                                    <ClockIcon />
                                                    <div>
                                                        <div>
                                                            {renderField(locationAndSupportFields.supportHours)}
                                                            {renderField(locationAndSupportFields.timeZoneCode)}
                                                        </div>
                                                    </div>
                                                </CardRow>
                                                {renderVendorActions()}
                                            </div>
                                        </div>
                                    }
                                </CustomerSupportContent>
                            )}
                        </>
                    )}
                </FieldsWrapper>

                {row.tableName !== "location" && row.tableName !== "customer_support" && renderVendorActions()}

                {["CREATING", "EDITING"].includes(row.status) ? (
                    <UpdateModal
                        context={context}
                        level={level}
                        vsourceData={vsourceData}
                        open={true}
                        rowList={rowList}
                        row={row}
                        onCloseClick={() => updateModalCloseHandler(rowIndex)}
                        onSaveClick={(values, comment, vendexResponseType) =>
                            updateModalSaveHandler(rowIndex, values, comment, vendexResponseType)
                        }
                        onDeleteClick={() => updateModalDeleteHandler(rowIndex)}
                        classifiers={classifiers}
                        title={title}
                    />
                ) : null}

                <ConfirmationDialog
                    open={confirmationModalRowIndex === rowIndex}
                    onCloseClick={() => setConfirmationModalRowIndex(null)}
                    onSubmitClick={() => certifyConfirmHandler(rowIndex)}
                    message="Are you Sure? All of your changes will reset"
                    closeOnSubmit
                />
            </Row>
        </RowWrapper>
    );
}
