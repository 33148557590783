/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_content_delivery_bucket": "vend-client-20190423135308-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "http://vend-client-20190423135308-hostingbucket-staging.s3-website.us-east-2.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-2:69d1d5b9-828c-4e38-b09c-5ffaf0788d26",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_it83BwLlC",
    "aws_user_pools_web_client_id": "74012rujuota819bt7jms5mb7t",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vend-repo-storage-staging",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://zp3vxhjzo5hynjhqopkhyblnpy.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
