import styled from "styled-components";

const BoxWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    color: #595973;
    max-width: 50vw;
`;

const MessageWrapper = styled.div`
    border: 1px solid ${({ theme }) => theme.yellow1};
    padding: 4rem 6rem;
`;

export const WarningBox = ({ message }) => {
    return (
        <BoxWrapper>
            <MessageWrapper>{message}</MessageWrapper>
        </BoxWrapper>
    );
};
