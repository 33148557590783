import React, { useState } from "react";
import { Search, Modal, Button } from "semantic-ui-react";
import styled from "styled-components";
import ReviewStars from "../ReviewStars";
import DropDown from "../DropDown";
import ReviewListItem from "./ReviewListItem";
import CreateReview from "./CreateReview";

const ReviewsWrapper = styled.div`
    margin: 20px 40px;
`;

const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 25px;

    h2 {
        margin: 0
    }
`;

const Details = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const SubReviews = styled.div`
    display: flex
    flex-direction: column;
    width: 50%;
    max-width: 500px;
    gap: 5px;
    border: 1px solid #efefef;
`;

const SubReviewBar = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px;
    height: 30px;
    width: ${props => props.value * 20}%;
    color: #464E5F;
    background-color: hsl(${props => Math.floor(45 - props.value)}, 100%, ${props => 50 + 6 * (5 - props.value)}%);
`;

const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
    max-width: 350px;
`;

const SearchInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;

    .searchbar {
        width: 100%;

        .input {
            width: 100%;
            input {
                border-radius: 3px;
            }
        }
    }
`;

const SortWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    &>div {
        width: 200px;
    }
`;

const ReviewListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 40px;
`;

const ActionsArea = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
`;

const sortOptions = [
    { label: "Recommended", value: "recommended" },
    { label: "Recent", value: "recent" },
];

const subReviewsSample = [
    { id: 1, name: 'Reliant', value: 5 },
    { id: 2, name: 'Customer Service', value: 4 },
    { id: 3, name: 'Helpful', value: 3.8 },
    { id: 4, name: 'Fast', value: 2 },
    { id: 5, name: 'Friendly', value: 1 },
];

const reviewListSample = [
    {
        id: 1,
        user_name: 'Joseph Azevedo',
        headline_text: 'Quick and easy service!',
        comment_text: 'Get a lot of bad press, but in my experience customer services has been nothing short of excellent. Even when there was a problem with paperwork the employees continued helping us and fixed the issue. Very patient workers and quick, easy and painless process.',
        rating: null,
        updated_at: '2021-07-05 22:02',
        avatar: null,
        sub_reviews: subReviewsSample
    },
    {
        id: 2,
        user_name: 'Ashea Almond',
        headline_text: 'High level customer service!',
        comment_text: 'The (CN) team represents among the highest levels of customer service I have experienced. Information was accurate, responses to queries were turned around very fast. Answers were clear and where necessary detailed enough for us to make informed decisions quickly, minimising the end to end time to process complex transactions among a number of parties.',
        rating: 4,
        updated_at: '2021-08-06 15:25',
        avatar: null,
        sub_reviews: subReviewsSample
    },
    {
        id: 3,
        user_name: 'Joseph Azevedo',
        headline_text: 'Quick and easy service!',
        comment_text: 'Get a lot of bad press, but in my experience customer services has been nothing short of excellent. Even when there was a problem with paperwork the employees continued helping us and fixed the issue. Very patient workers and quick, easy and painless process.',
        rating: null,
        updated_at: '2021-07-05 22:02',
        avatar: null,
        sub_reviews: subReviewsSample
    }
];

const Reviews = () => {
    const [totalNotes, setTotalNotes] = useState(182);
    const [subReviews, setSubReviews] = useState(subReviewsSample);
    const [searchText, setSearchText] = useState('');
    const [sortOption, setSortOption] = useState(sortOptions[0]);
    const [reviewList, setReviewList] = useState(reviewListSample);
    const [showModal, setShowModal] = useState(false);
    const [selectedReview, setSelectedReview] = useState(null);

    const createReviewHandler = review => {
        setReviewList([...reviewList, review]);
        setShowModal(false);
    }

    return (
        <ReviewsWrapper>
            <Header>
                <h2>{totalNotes} notes</h2>
                <ReviewStars value={4} />
            </Header>
            <Details>
                <SubReviews>
                    {subReviews.map(item => <SubReviewBar key={item.id} value={item.value}> {item.name} </SubReviewBar>)}
                </SubReviews>
                <ControlsWrapper>
                    <SearchInputWrapper>
                        <Search
                            className="searchbar"
                            onSearchChange={e => setSearchText(e.target.value)}
                            value={searchText}
                            open={false}
                            placeholder="Search for notes that mention"
                        />
                    </SearchInputWrapper>
                    <SortWrapper>
                        <span>Sort by:</span>
                        <DropDown
                            isMulti={false}
                            data={sortOptions}
                            onChange={selectedOption => setSortOption(sortOptions.find(opt => opt.value === selectedOption.value))}
                            value={sortOption}
                            defaultValue={sortOption}
                        />
                    </SortWrapper>
                </ControlsWrapper>
            </Details>
            <ReviewListWrapper>
                {
                    reviewList.map(review => (
                        <ReviewListItem key={review.id} {...review} />
                    ))
                }
            </ReviewListWrapper>
            <ActionsArea>
                <Modal
                    closeIcon
                    onClose={() => setShowModal(false)}
                    onOpen={() => setShowModal(true)}
                    open={showModal}
                    trigger={<Button color="yellow">Write a note</Button>}
                    style={{ width: '90%', maxWidth: '1200px' }}
                >
                    <Modal.Header>{selectedReview ? 'Update' : 'Create'} a note: VendEx Solutions, Inc.</Modal.Header>
                    <Modal.Content style={{ padding: '2rem' }}>
                        <CreateReview
                            selectedReview={selectedReview}
                            subReviews={subReviewsSample.map(review => ({ id: review.id, name: review.name }))}
                            onSubmit={createReviewHandler}
                        />
                    </Modal.Content>
                </Modal>
            </ActionsArea>
        </ReviewsWrapper>
    )
}

export default Reviews;