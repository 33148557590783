import styled from "styled-components/macro";
import theme from "../styles/theme";
import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";

const ModalBg = styled.div`
    position: absolute;
    display: flex;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: flex-end;
    align-items: flex-end;
    background-color: rgba(0,0,0,0.5);
`;

const VModal = styled.div`
    position: relative;
    display: inline-block;
    border-radius: 3px;
    height: 100%;
    width: 400px;
    background-color: ${theme.gray1};
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    justify-self: center;
    
    transform: ${({move}) => `translate(${move}px, 0)`};
    transition: all 250ms ease-in-out;
`;

export const Modal = ({onClose, children}) => {

    const [move, setMove] = useState(400);

    useEffect(() => {
        let id = setInterval(() => {
            setMove(0);
        }, 300);
        return () => clearInterval(id);
    });

    return ReactDOM.createPortal(
        <ModalBg onClick={onClose}>
            <VModal move={move} onClick={(e) => e.stopPropagation()}>
                {children}
            </VModal>
        </ModalBg>,
        document.getElementById('vault-portal'),
    )
};