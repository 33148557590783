import styled from "styled-components";

export const FieldSection = styled.div`
    position: relative;
    display: flex;
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 16px 0;
`;