import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { format } from "date-fns";

import CompanyHeader from "../components/CompanyHeader";
import { Input } from "../pages/VSource/VendorConfiguratorPage/VendorConfiguratorPage";

import "react-datepicker/dist/react-datepicker-cssmodules.css";

export const TableComponent = styled.div`
    padding: 0 2rem;
    margin-bottom: 3rem;
`;

export const Table = styled.table`
    
    flex: 2 0 0;
    table-layout: fixed;
    width: 100%;
    border: none;
    color: #455a87;
    
    td {
      width: 25%;
      word-break: break-word;
    }
    
    & > th, td {
      border: none;
    }
    
    tr {
      text-align: left;
      height: 2.2em;
      font-size: 1.1rem;
      line-height: 2.2em;
      
      & > td, th {
        padding-left: 1em;
        overflow: visible;
        ul > li {
            list-style: disc;
            margin-left: 1rem;
        }
      }
      
    }
    
    tr:nth-child(odd){
      background-color: #F0F2F6;
    }

    thead tr {
        border-top: 1px solid ${({ theme }) => theme.gray3};
        border-bottom: 1px solid ${({ theme }) => theme.gray3};

        th {
            font-weight: bold;
        }
    }
    
    //tbody tr th:first-child {
    //  font-weight: bold;
    //}
    
    tbody tr td:nth-child(odd) {
      font-weight: bold;
    }
    
    tbody tr td:nth-child(even) {
      /* white-space: nowrap; */
      /*overflow: hidden;*/
      text-overflow: ellipsis;
      padding-right: 1em;
      
      a {
        color: #009DA3 !important;
      }
      
    }
    .required-field::after {
        content: "*";
        color: red;
        margin-left: 2px;
    }
  
  }`;

const LinkTableComponent = styled(Table)`
    table-layout: auto;
    cursor: pointer;

    td {
        width: auto;
    }

    thead > tr {
        background-color: ${({ theme }) => theme.yellowLight} !important;
        cursor: default;
    }

    tbody tr {
        &:hover {
            background-color: ${({ theme }) => theme.gray3};
        }
        td:nth-child(odd) {
            font-weight: normal;
        }
    }

    tbody tr td:nth-child(even) {
        white-space: normal;
        text-overflow: ellipsis;
    }
`;

const DatePickerWrapper = styled.div`
    input {
        height: 45px;
        width: 100%;
        background: ${({ theme }) => theme.inputBackground};
        border: 1px solid ${({ theme }) => theme.accent}
        border-radius: 3px;
        padding: 16px;
        margin: 5px 0;
    }
    .react-datepicker-wrapper {
      width: 100%;
    }
`;

export const CloseIcon = styled.span`
    cursor: pointer;
    margin-right: 0.5rem;
    font-size: 20px;
    color: ${({ theme }) => theme.error};
    &:hover {
        position: relative;
        top: -1px;
    }
`;

export const TextArea = styled.textarea`
    width: 80%;
    background: ${({ theme }) => theme.inputBackground};
    border: 1px solid ${({ theme }) => theme.accent}
    padding: 16px;
    border-radius: 3px;
    color: inherit;
    margin: 5px 0;
    `;


// Generic table with static data
// Titlemargin will leave empty space above the table instead of the real title
export const InfoTable = ({ title, data, titleMargin, style, className }) => {
    const renderValue = (value, type, props) => {
        if (value === null) return "";
        else if (typeof value === "string") {
            let res = value;

            if (type === "url")
                res = (
                    <a href={value} target="_blank" rel="noreferrer">
                        {value}
                    </a>
                );
            else if (type === "datetime") {
                if (props?.dateTimeFormat) {
                    return format(value, props.dateTimeFormat);
                }
                res = format(value, "DD-MMM-YYYY");
            } else if (type === "html") {
                res = (
                    <div
                        dangerouslySetInnerHTML={{
                            __html: value,
                        }}
                    />
                );
            } else {
                res = <span title={value}>{value}</span>;
            }

            return res;
        } else if (Array.isArray(value)) {
            if (value.length === 0) return null;
            return (
                <ul>
                    {value.map((el, i) => (
                        <li key={`${el.substr(1, 2)}-${i}`}>{el}</li>
                    ))}
                </ul>
            );
        } else {
            return JSON.stringify(value);
        }
    };

    if (!data || data.length === 0) return null;

    return (
        <TableComponent style={style}>
            {title && <CompanyHeader title={title} />}
            {titleMargin && (
                <div style={{ marginTop: "0.9em" }}>
                    <CompanyHeader title={""} />
                </div>
            )}
            <Table className={className}>
                <tbody>
                    {data.map((row, i) => {
                        if (row.hide) return null;
                        return (
                            <tr key={`${row.title ? row.title.substr(1, 3) : "rt4"}-${i}`}>
                                <td title={row.title ?? ""}>{row.title ?? ""}</td>
                                <td>
                                    {renderValue(row.value, row.type ? row.type : null, row.props ? row.props : null)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </TableComponent>
    );
};

// Generic table with linked rows
export const LinkTable = ({ title, headers, data, className }) => {
    if (!data || data.length === 0) return null;

    return (
        <TableComponent>
            {title && <CompanyHeader title={title} />}
            <LinkTableComponent className={className}>
                {headers && (
                    <thead>
                        <tr>
                            {headers.map((el, i) => (
                                <th
                                    key={`${title ? title.substr(1, 2) : ""}-${
                                        data[0]?.value ? data[0].value.substr(1, 3) : "hr"
                                    }-${i}`}
                                >
                                    {el}
                                </th>
                            ))}
                        </tr>
                    </thead>
                )}
                <tbody>
                    {data.map((row, i) => {
                        if (row.hide) return null;
                        return (
                            <tr
                                onClick={row.onClick ?? null}
                                key={`${row.value ? row.value.substr(1, 2) : "row"}-${
                                    row.title ? row.title.substr(1, 2) : "t"
                                }-${i}`}
                            >
                                <td title={row.title ?? ""}>{row.title ?? ""}</td>
                                <td>{row.value ?? ""}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </LinkTableComponent>
        </TableComponent>
    );
};

// Generic table with label and form input
// Titlemargin will leave empty space above the table instead of the real title

export const FormInput = styled((props) => <Input {...props} />)`
    margin: 5px 0;
    width: 100% !important;
    height: 45px !important;
  
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }
    
    /* Firefox */
    & [type=number] {
    -moz-appearance: textfield;
    }
`;

const CheckBoxElementWrapper = styled.div`
    width: 50%;
    display: inline-block;
    padding-right: 2rem;
`;

const CheckboxFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

// TODO need to move to utils or theme folder
export const _dropDownStyles = (themeContext) => ({
    option: (styles, state) => {
        const background = () => {
            if (state.isSelected) return themeContext.gray3;
            else if (state.isFocused) return "transparent";
            return styles.backgroundColor;
        };
        return {
            ...styles,
            "&:hover": {
                backgroundColor: themeContext.gray1,
            },
            backgroundColor: background(),
            color: "hsl(0, 0%, 20%)",
        };
    },
    control: (styles, state) => ({
        ...styles,
        borderRadius: "3px",
        boxShadow: "none",
        borderColor: themeContext.accent,
        "&:hover": {
            borderColor: "hsl(0, 0%, 80%)",
        },
        backGround: themeContext.inputBackground,
        width: "100%",
        margin: "5px 0",
    }),
});

const TableField = (
    {
        value,
        type,
        _onChange,
        props,
        dateFormat,
        saveDateFormat
    }
) => {
    const themeContext = useContext(ThemeContext);
    const dropDownStyles = _dropDownStyles(themeContext);

    if (type === "text") return <FormInput value={value} onChange={(e) => _onChange(e.target.value)} />;
    if (type === "number") return <FormInput value={value} type="number" onChange={(e) => _onChange(e.target.value)} />;
    if (type === "date" && dateFormat) {
        return (
            <DatePickerWrapper>
                <DatePicker
                    selected={value ? new Date(value) : null}
                    onChange={(value) => {
                        return _onChange(format(value, saveDateFormat))
                    }}
                    dateFormat={dateFormat}
                />
            </DatePickerWrapper>
        );
    }
    if (type === "dropdown") {
        return (
            <Select
                options={value}
                onChange={(e) => _onChange(e ?? { value: null })}
                defaultValue={props?.selected ? value.filter((el) => el.value === props.selected)[0] : null}
                styles={dropDownStyles}
                isClearable={props?.isClearable ?? false}
                key={new Date().getTime().toString()}
            />
        );
    }
    if (type === "checkboxes") {
        if (!value) return null;
        return (
            <div>
                {value.map((el, i) => {
                    //console.log("element: ", el);
                    return (
                        <CheckBoxElementWrapper key={`${el.value ? el.value.substr(1, 2) : "ch"}-${i}`}>
                            <CheckboxFlex>
                                <span style={{ fontWeight: "normal" }}>{el.label}</span>
                                <input
                                    type="checkbox"
                                    value={el.value}
                                    checked={props?.selected.includes(el.value)}
                                    onChange={(e) => _onChange(el.value)}
                                />
                            </CheckboxFlex>
                        </CheckBoxElementWrapper>
                    );
                })}
            </div>
        );
    }
    if (type === "button") {
        return <button onClick={props.onClick}>{props.label}</button>;
    }
    if (type === "textarea"){
        return <TextArea
            rows={props?.rows ?? 23}
            value={value}
            onChange={(e) => _onChange(e.target.value)}
        />
    }

    return null;
}
export const FormTable = ({ title, data, titleMargin }) => {
    return (
        <TableComponent>
            {title && <CompanyHeader title={title} />}
            {titleMargin && (
                <div style={{ marginTop: "0.9em" }}>
                    <CompanyHeader title={""} />
                </div>
            )}
            {data && data.length > 0 && (
                <Table>
                    <tbody>
                        {data.map((row, i) => {
                            if (row.hide) return null;
                            if (row.type === "checkboxes") {
                                return (
                                    <React.Fragment key={`${row.title ? row.title.substr(1, 4) : "ti"}-${i}`}>
                                        <tr>
                                            <td title={row.title ?? ""} colSpan={2}>
                                                {row.title ?? ""}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <TableField
                                                    value={row.value}
                                                    type={row.type}
                                                    _onChange={row.onChange}
                                                    props={row.props}
                                                    dateFormat={row.dateFormat}
                                                    saveDateFormat={row.saveDateFormat}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            }
                            if (row.props?.colSpan === true) {
                                return (
                                    <tr key={`${row.title ? row.title.substr(1, 5) : "rt"}-${i}`}>
                                        <td colSpan={2}>
                                            <TableField
                                                value={row.value}
                                                type={row.type}
                                                _onChange={row.onChange}
                                                props={row.props}
                                                dateFormat={row.dateFormat}
                                                saveDateFormat={row.saveDateFormat}
                                            />
                                        </td>
                                    </tr>
                                );
                            }
                            return (
                                <tr key={`${row.title ? row.title.substr(1, 5) : "rt2"}-${i}`}>
                                    <td title={row.title}>
                                        {row.props?.onRemove ? (
                                            <CloseIcon title={"Delete"} onClick={row.props.onRemove}>
                                                &times;
                                            </CloseIcon>
                                        ) : null}
                                        <span className={row.required ? "required-field" : ""}>{row.title}</span>
                                    </td>
                                    <td>
                                        <TableField
                                            value={row.value}
                                            type={row.type ? row.type : "text"}
                                            _onChange={row.onChange}
                                            props={row.props}
                                            dateFormat={row.dateFormat}
                                            saveDateFormat={row.saveDateFormat}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            )}
        </TableComponent>
    );
};
