import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import _get from "lodash/get";
import _orderBy from "lodash/orderBy";
import _filter from "lodash/filter";

import { getEventsAndArticles } from "../graphql/customQueries";

import { FlippingCard } from "../components/FlipCard";
import EventsSection from "../components/EventsSection";
import FeaturedArticle from "../components/FeaturedArticle";
import ArticlesSection from "../components/ArticlesSection";
import VSourceIcon from "../components/icons/VSourceIcon";
import IntegrateIcon from "../components/icons/IntegrateIcon";
import SafeIcon from "../components/icons/SafeIcon";
import RegIcon from "../components/icons/RegIcon";
import LockIcon from "../components/icons/LockIcon";
import { useCheckAuth } from "../components/Navigation/useCheckAuth";

import VKey2Icon from "../images/VKey2.svg";

const Icon = styled.img`
    height: 2rem;
`;

const Body = styled.div`
    display: grid;
    grid-template-columns: 20% 35% 40%;
    justify-content: space-between;
    height: 100%;
    padding: 2rem 4rem 0 0;
    color: ${({ theme }) => theme.darkBlue};
    font-size: 18px;

    @media only screen and (max-height: 800px) {
        padding: 2rem 4rem 0 0;
    }

    h2 {
        font-weight: 700;
    }

    .column.column-left {
        padding-right: 5rem;
    }
    .column {
        display: flex;
        flex-direction: column;

        .cards-block {
            display: grid;
            margin-top: 1rem;
            padding-left: 1rem;
            width: 100%;
            .flip-card .card-container {
                margin-bottom: 1rem;
            }
            @media only screen and (max-height: 800px) {
                .flip-card .card-container {
                    height: 90px;
                }
            }
        }
    }

    .text-center {
        text-align: center;
    }
    .text-left {
        width: 100%;
        text-align: left;
    }
`;

const UserBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-column: 2/4;

    img {
        margin-left: 2rem;
        max-height: 50px;
        width: auto;
    }
    p {
        margin: 0;
    }
`;

const ServicesBlock = styled.div`
    margin-bottom: 0;
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media only screen and (max-height: 800px) {
        margin-bottom: 0;
        padding-left: 1rem;
    }
    & h2 {
        margin-top: 1rem;
    }
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin: 3rem 0;
    grid-column: 1/4;

    & > div {
        width: 50%;
    }

    & > div:nth-child(2) {
    }

    @media only screen and (max-height: 800px) {
        margin: 1rem 0;
    }
`;

const CardFront = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .title-first {
        color: ${({ theme }) => theme.primary};
    }
    .title-small {
        font-size: 17px;
    }

    /* Logo */
    & > div:first-child {
        margin: 0 1rem;
    }

    & > div:nth-child(2) {
        flex: 2;

        /*Title*/
        & > div:first-child {
            display: flex;
            justify-content: space-between;
            h2 {
                font-size: 20px;
                margin-bottom: 0.5rem;
            }
            svg {
                transform: scale(1.3);
            }
        }
        p {
            font-size: 16px;
            margin: 0;

            @media only screen and (max-height: 800px) {
                font-size: 14px;
            }
        }
    }
`;

const CardBack = styled.div`
    font-size: 16px;
    padding: 10px;
`;

const ProfileWrapper = styled.div`
    & > div {
        max-width: 300px;
        position: relative;
        bottom: 0;
        roght: 0;
        left: 0;
    }
`;

const InfoText = styled.div`
    font-size: 14px;
`;

const Message = styled.div`
    background: #fff2cc;
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    border: 1px solid #ffca29;
    padding: 1rem;
    margin-bottom: 2rem;

    h3 {
        font-size: 15px !important;
        margin-bottom: 10px;
        font-weight: bold;
        color: #464e5f;
    }
    p {
        font-size: 15px !important;
        color: #595973;
    }
`;

export const ClientDashboard = () => {
    const [articlesList, setArticlesList] = useState([]);
    const [eventsList, setEventsList] = useState([]);

    const { isUnlockedVSource, isUnlockedVKey, isUnlockedVPort, isUnlockedVLink, isVKey2User } = useCheckAuth();

    const history = useHistory();

    const [getEventsAndArticlesMethod, { loading, data, error }] = useLazyQuery(getEventsAndArticles, {
        notifyOnNetworkStatusChange: true,
    });

    useEffect(() => {
        if (!loading && data && !error) {
            const articles = _get(data, "general.articles", []);
            const events = _get(data, "general.industryEvents", []);

            setEventsList(_orderBy(events, ["eventDate"], ["asc"]));
            setArticlesList(_orderBy(articles, ["isFeatured", "articleDate"], ["desc", "desc"]));
        }
    }, [data, loading, error]);

    useEffect(() => {
        getEventsAndArticlesMethod();
    }, []);

    const featuredArticle = _get(articlesList, "[0]");
    const articles = _filter(articlesList, (item) => !item.isFeatured);

    const VSourceCard = (
        <FlippingCard
            front={
                <Front
                    title="VSource"
                    text={["Digital Vendor", "Services Directory"]}
                    icon={<VSourceIcon />}
                    locked={!isUnlockedVSource}
                />
            }
            back={<Back text={"Extensive catalogue of market data vendor products & services"} />}
            onClick={isUnlockedVSource ? () => history.push("/vsource/vendors") : null}
            key="vsource"
        />
    );
    const VKeyCard = (
        <FlippingCard
            front={
                <Front
                    title="VKey"
                    text={["Digitizing Market Data", "Contracts"]}
                    icon={<SafeIcon />}
                    locked={!isUnlockedVKey}
                />
            }
            back={<Back text={"Digitized contracts with key terms & data usage rights"} />}
            onClick={isUnlockedVKey ? () => history.push("/vkey/vault") : null}
            key="vkey"
        />
    );

    const VPortCard = (
        <FlippingCard
            front={
                <Front
                    title="VPort"
                    text={["Integrated Vendor", "Management Portal"]}
                    icon={<IntegrateIcon />}
                    locked={!isUnlockedVPort}
                />
            }
            back={<Back text={"Vendor management portal – analyze & manage renewals, costs, usage and payments"} />}
            onClick={isUnlockedVPort ? () => history.push("/vport") : null}
            key="vport"
        />
    );

    const VLinkCard = (
        <FlippingCard
            front={
                <Front
                    title="VLink"
                    text={["Market Data", "Customer Connection"]}
                    icon={<VSourceIcon />}
                    locked={!isUnlockedVLink}
                />
            }
            back={<Back text={"Promote products & services, connect to clients directly with updates & alerts"} />}
            onClick={isUnlockedVLink ? () => history.push("/vlink/dashboard") : null}
            key="vlink"
        />
    );

    const VRegCard = (
        <FlippingCard
            front={<Front title="VReg" text={["Market Data", "Regulatory Mapping"]} icon={<RegIcon />} locked />}
            back={<Back text={"Maps a client’s vendor reliance by regional regulation and instance"} />}
            key="vreg"
        />
    );

    const VIDsvCard = (
        <FlippingCard
            front={
                <Front
                    title={
                        <>
                            <span className={"title-first"}>V</span>ID-<span className={"title-small"}>SV</span>
                        </>
                    }
                    text={["VendEx Identifier -", "Services"]}
                    icon={<IntegrateIcon />}
                    locked
                />
            }
            back={<Back text={"Alphanumeric taxonomy applied to services"} />}
            key="vid-sv"
        />
    );

    const VIDduCard = (
        <FlippingCard
            front={
                <Front
                    title={
                        <>
                            <span className={"title-first"}>V</span>ID-<span className={"title-small"}>DU</span>
                        </>
                    }
                    text={["VendEx Identifier -", "Data Usage"]}
                    icon={<IntegrateIcon />}
                    locked
                />
            }
            back={<Back text={"Alphanumeric taxonomy applied to data usage rights"} />}
            key="vid-du"
        />
    );

    const Subscriptions = [];
    const NonSubscriptions = [VRegCard, VIDsvCard, VIDduCard];

    // Set the card locations
    if (isUnlockedVSource) {
        Subscriptions.push(VSourceCard);
    } else {
        NonSubscriptions.unshift(VSourceCard);
    }

    if (isUnlockedVKey) {
        Subscriptions.push(VKeyCard);
    } else {
        NonSubscriptions.unshift(VKeyCard);
    }

    if (isUnlockedVPort) {
        Subscriptions.push(VPortCard);
    } else {
        NonSubscriptions.unshift(VPortCard);
    }

    if (isUnlockedVLink) {
        Subscriptions.push(VLinkCard);
    } else {
        NonSubscriptions.unshift(VLinkCard);
    }

    if (isVKey2User) {
        const VKey2Card = (
            <FlippingCard
                front={
                    <Front
                        title="VKey2"
                        text={["Digitizing Market Data", "Contracts"]}
                        icon={<Icon src={VKey2Icon} />}
                        locked={!isVKey2User}
                    />
                }
                back={<Back text={"Digitized contracts with key terms & data usage rights"} />}
                onClick={isVKey2User ? () => history.push("/vkey2") : null}
                key="vkey2"
            />
        );
        Subscriptions.push(VKey2Card);
    }

    return (
        <Body>
            <div className="column">
                <ServicesBlock>
                    <h2 className="text-left">Your Subscriptions</h2>
                    {Subscriptions.length > 0 && <div className="cards-block">{Subscriptions}</div>}
                    {Subscriptions.length === 0 && (
                        <InfoText className="text-center">
                            You are not currently entitled to any VendEx subscriptions. Please speak to your account
                            representative about enabling one of the products below.
                        </InfoText>
                    )}
                </ServicesBlock>
                <ServicesBlock>
                    <h2 className="text-left">VendEx Ecosystem</h2>
                    <div className="cards-block">{NonSubscriptions}</div>
                </ServicesBlock>
            </div>
            <div className="column">
                {!!featuredArticle && (
                    <FeaturedArticle
                        img={featuredArticle.featuredImageUrl}
                        title={featuredArticle.title}
                        text={featuredArticle.previewText}
                        url={featuredArticle.url}
                    />
                )}
                <EventsSection eventsList={eventsList} />
            </div>
            <div className=" column">
                <Message>
                    <h3>Welcome to the VendEx Ecosystem</h3>
                    <p>
                        The VendEx ecosystem gives market data vendors and market data consumers digital tools to
                        eliminate or minimize frictions. The company continues to light up new tools in the ecosystem,
                        creating the centralized hub for the market data industry.
                    </p>
                    <p>
                        <a href="https://vendexsolutions.com/welcome-to-the-vendex-ecosystem/" target="_blank">
                            Read more
                        </a>
                    </p>
                </Message>
                <ArticlesSection articlesList={articles} />
            </div>
            <Footer>
                <ProfileWrapper>{/* <Profile /> */}</ProfileWrapper>
                <div>
                    <p>
                        Need Help?: <a href="mailto:support@vendexsolutions.com">support@vendexsolutions.com</a>
                    </p>
                </div>
            </Footer>
        </Body>
    );
};

const UserInfo = ({ username, lastLogin, logoUrl }) => {
    return (
        <UserBlock>
            <div>
                <p>Welcome Back: {username}</p>
                {lastLogin && <p>Last Login: {lastLogin}</p>}
            </div>
            <div>{logoUrl && <img src={logoUrl} alt="logo" />}</div>
        </UserBlock>
    );
};

const Front = ({ title, text, icon, locked }) => {
    const Lock = styled.div`
        margin-right: 1rem;
        @media only screen and (max-height: 800px) {
            margin-top: 0.5rem;
        }
    `;

    const renderTitle = () => {
        if (typeof title === "string") {
            return (
                <>
                    <span className={"title-first"}>{title[0]}</span>
                    {title.substring(1)}
                </>
            );
        } else return title;
    };

    return (
        <CardFront>
            <div>{icon}</div>
            <div>
                <div>
                    <h2>{renderTitle()}</h2>
                    {locked && (
                        <Lock>
                            <LockIcon />
                        </Lock>
                    )}
                </div>
                {text && (
                    <div>
                        {text.map((row, i) => (
                            <p key={i}>{row}</p>
                        ))}
                    </div>
                )}
            </div>
        </CardFront>
    );
};

const Back = ({ text }) => {
    return <CardBack>{text && <p>{text}</p>}</CardBack>;
};
