import React, { useCallback, useContext, useEffect, useState } from "react";
import { get } from "lodash";
import { ButtonWrapper } from "../components/VendBtn";
import ReactTable from "react-table";
import TableIcon from "../components/icons/TableIcon";
import { Switch, withRouter } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import { Modal as VModel } from "../components/VaultPanel";
import { PrivateRoute } from "../components/PrivateRoute";
import { ModalPdfViewer } from "../components/ModalPdfViewer";
import { ModalVault } from "../components/ModalVault";
import { AgreementContext } from "../context/agreement/AgreementContext";
import { AuthContext } from "../context/AuthContext";
import { Modal, Popup } from "semantic-ui-react";
import cogoToast from "cogo-toast";
import { TableContainer } from "../components/TableContainer";
import { PageWrapper } from "../components/PageWrapper";
import { OptionPanel } from "../components/OptionPanel";
import { WarningButtonPanel } from "../components/WarningButtonPanel";
import { WarningBox } from "../components/MessageBox";

const { formatToTimeZone } = require("date-fns-timezone");

export const AgreementsPage = withRouter(({ history, match }) => {
    const {
        context: { agreements, agreementDeleteCheck, agreementToDelete, agreementDeleted },
        fetchAgreements,
        addDocumentToAgreement,
        deleteAgreement,
        selectAgreementForDeletion,
        deselectAgreementForDeletion,
        clearAgreementDeleted,
    } = useContext(AgreementContext);

    const {
        context: { entities },
    } = useContext(AuthContext);

    useEffect(() => {
        fetchAgreements({});
    }, []);

    useEffect(() => {
        if (agreementDeleted.agreementName) {
            cogoToast.success(`${get(agreementDeleted, "agreementName", null)} was successfully deleted`).then(() => {
                clearAgreementDeleted();
            });
        }
    }, [agreementDeleted.agreementName]);

    const [showDocModal, setShowDocModal] = useState(false);

    const [selectedAgreementId, setSelectedAgreementId] = useState(null);

    const createAgreement = useCallback(() => {
        history.push("/vkey/agreements/agreement-configurator/new");
    }, []);

    const handleShowModal = useCallback(() => {
        setShowDocModal(true);
    }, []);

    const handleCloseDocModal = useCallback(() => {
        setSelectedAgreementId(null);
        setShowDocModal(false);
    }, []);

    const modalCallBack = useCallback(
        (documentId) => {
            addDocumentToAgreement(selectedAgreementId, documentId).then(() => fetchAgreements({}));
        },
        [selectedAgreementId]
    );

    const editAgreementCallback = useCallback((agreementId) => {
        history.push(`/vkey/agreements/agreement-configurator/${agreementId}`);
    }, []);

    const deleteAgreementCallback = useCallback(async () => {
        // console.log(`Delete Agreement`, agreementToDelete);
        await deleteAgreement(agreementToDelete);
        fetchAgreements({});
    }, [agreementToDelete]);

    const deselectAgreementForDeletionCallback = useCallback(() => {
        deselectAgreementForDeletion();
    }, []);

    const selectAgreementForDeletionCallback = useCallback((agreement) => {
        // console.log("selectAgreementForDeletionCallback : ", agreement);
        selectAgreementForDeletion(agreement);
    }, []);

    if (entities.length === 0) {
        return (
            <PageWrapper style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <WarningBox
                    message={`You are permissioned as a VKey User but have not been assigned to a client firm. Please contact
                        your VendEx Sales Representative or Account Manager.`}
                />
            </PageWrapper>
        );
    }

    return (
        <PageWrapper>
            <PageHeader title={"Agreements"}>
                <ButtonWrapper data-cy={"create-agreement-btn"} onClick={createAgreement}>
                    Add Agreement
                </ButtonWrapper>
            </PageHeader>

            <Modal open={agreementDeleteCheck} basic onClose={deselectAgreementForDeletionCallback} size="small">
                <Modal.Content>
                    <h5>Are you sure you want to delete agreement?</h5>
                    <h3>{get(agreementToDelete, "agreementName", null)}</h3>
                    <WarningButtonPanel>
                        <ButtonWrapper negative-halo onClick={deselectAgreementForDeletionCallback}>
                            No
                        </ButtonWrapper>
                        <ButtonWrapper onClick={deleteAgreementCallback}>Yes</ButtonWrapper>
                    </WarningButtonPanel>
                </Modal.Content>
            </Modal>

            <TableContainer>
                <ReactTable
                    data={agreements}
                    className="-striped -highlight"
                    columns={[
                        {
                            Header: "Agreement Name",
                            width: 350,
                            accessor: "agreementName",
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Created by",
                            minWidth: 180,
                            accessor: "createdBy",
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Creation Date",
                            minWidth: 100,
                            headerStyle: {
                                fontSize: 16,
                            },
                            Cell: (row) =>
                                formatToTimeZone(new Date(row.original.createdAt), "D-MMM-YYYY", { timeZone: "GMT" }),
                        },
                        {
                            Header: "Updated By",
                            minWidth: 180,
                            headerStyle: {
                                fontSize: 16,
                            },
                            accessor: "updatedBy",
                        },
                        {
                            Header: "Updated Date",
                            minWidth: 100,
                            headerStyle: {
                                fontSize: 16,
                            },
                            Cell: (row) =>
                                formatToTimeZone(new Date(row.original.updatedAt), "D-MMM-YYYY", { timeZone: "GMT" }),
                        },
                        {
                            Header: "Documents",
                            minWidth: 130,
                            accessor: "documents.items.length",
                            headerStyle: {
                                textAlign: "center",
                                fontSize: 16,
                            },
                            style: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            },
                        },
                        {
                            Header: "Meta",
                            minWidth: 100,
                            accessor: "Meta",
                            headerStyle: {
                                textAlign: "center",
                                fontSize: 16,
                            },
                            Cell: (row) => {
                                const docCount = get(row, "original.documents.items.length", 0);

                                const handleClick = () => {
                                    if (docCount < 1) return;
                                    history.push(`/vkey/agreements/meta/${row.original.id}`);
                                };

                                return (
                                    <div
                                        onClick={handleClick}
                                        title={
                                            docCount > 0
                                                ? "Enter metadata"
                                                : "An agreement must have at least one document to enter metadata."
                                        }
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: docCount > 0 ? "pointer" : "not-allowed",
                                        }}
                                    >
                                        <TableIcon />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "Upload Doc",
                            minWidth: 100,
                            headerStyle: {
                                textAlign: "center",
                                fontSize: 16,
                            },
                            Cell: (row) => {
                                const handleClick = () => {
                                    setSelectedAgreementId(row.original.id);
                                    handleShowModal();
                                };

                                return (
                                    <div
                                        onClick={handleClick}
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <TableIcon />
                                    </div>
                                );
                            },
                        },
                        {
                            Header: "Options",
                            minWidth: 100,
                            headerStyle: {
                                textAlign: "center",
                                fontSize: 16,
                            },
                            Cell: (row) => {
                                return (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Popup
                                            content={() => {
                                                return (
                                                    <OptionPanel>
                                                        <ButtonWrapper
                                                            halo
                                                            onClick={() => editAgreementCallback(row.original.id)}
                                                        >
                                                            Edit
                                                        </ButtonWrapper>
                                                        <ButtonWrapper
                                                            negative
                                                            onClick={() =>
                                                                selectAgreementForDeletionCallback(row.original)
                                                            }
                                                        >
                                                            Delete
                                                        </ButtonWrapper>
                                                    </OptionPanel>
                                                );
                                            }}
                                            on="click"
                                            pinned={true}
                                            position="bottom right"
                                            trigger={<TableIcon />}
                                        />
                                    </div>
                                );
                            },
                        },
                    ]}
                />
                {showDocModal && (
                    <VModel onClose={handleCloseDocModal}>
                        <Switch>
                            <PrivateRoute
                                path={`${match.url}/pdf-viewer/:id`}
                                component={ModalPdfViewer}
                                closeModal={handleCloseDocModal}
                                callbackFn={modalCallBack}
                            />
                            <PrivateRoute path={`${match.url}`} onClose={handleCloseDocModal} component={ModalVault} />
                        </Switch>
                    </VModel>
                )}
            </TableContainer>
        </PageWrapper>
    );
});
