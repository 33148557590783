import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import addMetaToAgreement from "./addMetaToAgreement";
import { get } from "lodash";

const fetchAgreement = async (agreementId, { dispatch, activeEntity }) => {
    dispatch({ type: "CLEAR_SELECTED_AGREEMENT" });

    const {
        data: { getAgreement: agreement },
    } = await API.graphql(
        graphqlOperation(queries.getAgreement, {
            id: agreementId,
        })
    );

    let fullAgreement;

    // If agreement meta is missing in the database then create a new empty entry
    // TODO: This should not be done while fetching the agreement. This should be done maybe when editing the agreement meta.
    if (!agreement.meta) {
        fullAgreement = await addMetaToAgreement(agreement, activeEntity);
    } else {
        fullAgreement = agreement;
    }

    const definitionItems = get(fullAgreement, "meta.definitions.items", []);
    definitionItems.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    dispatch({ type: "SELECT_AGREEMENT", agreement: fullAgreement });

    return agreement;
};

export default fetchAgreement;
