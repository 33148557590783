import { API, graphqlOperation } from "aws-amplify";
import { listClassifiersByType } from "../graphql/queries";
import sortClassifiers from "./sortClassifiers";

export default async function getSortedClassifiersByType(type) {
    const response = await API.graphql(
        graphqlOperation(listClassifiersByType, {
            type: type,
        })
    );

    if(response.data && response.data.listClassifiersByType) {
        response.data.listClassifiersByType.sort(sortClassifiers);
    }
    
    return response;
}