import React from "react";
import styled from "styled-components";
import { format } from "date-fns";

import { Icon } from "./Navigation/Sidenav";
import DefaultIcon from "../images/articleImageDefault.svg";

const Section = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 1rem;
  
    & > p {
        margin-bottom: 0.5em;
    }
    a {
      font-size: 14px;
    }
    p {
        font-size: 14px!important;
        color: #39393999;
        text-align: justify;
        margin-bottom: 0;
    }
    a {
        font-size: 14px!important;
        text-decoration: underline;
    }
    a:hover {
        text-decoration: none;
    }
`;
const TitleHolder = styled.div`
  h4 {
    font-size: 14px!important;
    font-weight: 400;
    margin: 0.25rem 2rem 0.25rem 0;
    color: #393939;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const ArticleDate = styled.span`
  font-size: 14px;
  color: #595973;
  white-space: nowrap;
`
const ImageHolder = styled.div`
  min-width: 45px;
  width: 45px;
  margin-right: 2rem;
  & img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`
const ArticlesList = styled.div`
  width: 100%;
`

export const ArticlesSection = ({ articlesList }) => {
    if (articlesList.length === 0) return null;

    return <ArticlesList>
        {
            articlesList.map((el, i) => {
                return (
                    <Section first-item={!i} key={el?.id}>
                        <ImageHolder>
                            {
                                !!el.thumbnailUrl ? (
                                    <img src={el?.thumbnailUrl} alt={el?.title} />
                                ) : <Icon src={DefaultIcon} />
                            }
                        </ImageHolder>
                        <div>
                            <TitleHolder>
                                <h4>{el?.title}</h4>
                                {el?.articleDate && (<ArticleDate>{format(el?.articleDate, 'DD-MMM-YYYY')}</ArticleDate>)}
                            </TitleHolder>
                            <p>{el?.previewText} <a href={el?.url} target="_blank">
                                Read more
                            </a></p>
                        </div>
                    </Section>
                );
            })
        }
    </ArticlesList>
};

export default React.memo(ArticlesSection);