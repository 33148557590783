import styled from 'styled-components';
import theme from '../../styles/theme';
import bgGreyImage from '../../images/GGB-hero_grey_1950x1201_copy-compressor-1.png';

export const LoginWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
`;

export const LoginGraphic = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    justify-content: center;
     
    //background: ${theme.secondary};
    flex: 2;
    height: 100%;
    background: url("${bgGreyImage}") no-repeat center center fixed;
    background-size: cover;
`;

// TODO: move to common
export const VModal = styled.div`
    position: relative;
    display: inline-block;

    padding: 20px;

    background: white;

    border-radius: 3px;

    margin: 1rem;

    min-height: 300px;
    min-width: 300px;
  
    max-width: 95vw;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    justify-self: center;
`;

export const FrameWrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;