import { useState, useEffect } from "react";
import { Loader, Dimmer } from "semantic-ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams, useLocation } from "react-router-dom";
import Vimeo from "@u-wave/react-vimeo";

import { FeatureBlock } from "./FeatureBlock";
import { FilesListWrapper, DocumentThumbnail } from "./DocumentsBlock";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";
import UploadVideoModal from "./UploadVideoModal";
import { useAlerts } from "../../hooks/useAlerts";
import { VendorFilesListQuery, ProductFilesListQuery, VimeoVideoQuery } from "../../graphql/customQueries";
import { deleteVLinkPremiumFile } from "../../graphql/customMutations";

export const VideosBlock = ({ permissions, page, showButtons }) => {
    const { id: paramsId } = useParams();
    const { newSuccessAlert } = useAlerts();

    const [filesList, setFilesList] = useState(undefined);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [deleteFileModalOpened, setFileDeleteModalOpened] = useState(false);
    const [uploadDocModalOpened, setUploadDocModalOpened] = useState(false);
    const [videoPlayerOpened, setVideoPlayerOpened] = useState(false);

    let formType = undefined;
    let filesQuery = VendorFilesListQuery;
    let queryVariables = {
        type: "VLINK_VIMEO_VIDEO",
    };

    const isVendorPage = page.isVendorPage || page.isVSourceVendorPage;
    const isProductPage = page.isProductPage || page.isVSourceProductPage;

    if (isVendorPage) {
        formType = "VENDOR";
        filesQuery = VendorFilesListQuery;
        queryVariables.vendor_id = paramsId;
    } else if (isProductPage) {
        formType = "PRODUCT";
        filesQuery = ProductFilesListQuery;
        queryVariables.product_id = paramsId;
    }

    const { loading: filesListLoading, error: _filesListError, data: filesListRes, refetch } = useQuery(filesQuery, {
        variables: queryVariables,
        fetchPolicy: 'network-only',
        skip: filesList !== undefined && formType !== undefined,
    });

    const [deleteVLinkPremiumFileMutation, { loading: deleteFileLoading, error }] = useMutation(deleteVLinkPremiumFile);

    useEffect(() => {
        console.log("filesList:", filesListRes);
        if (!filesListLoading && filesListRes && !_filesListError) {
            if (isVendorPage) {
                setFilesList(filesListRes?.vlink?.vendorFiles);
            } else if (isProductPage) {
                setFilesList(filesListRes?.vlink?.productFiles);
            }
        }
    }, [filesListLoading, filesListRes, _filesListError]);

    useEffect(() => {
        if (filesList === undefined && filesListRes) {
            refetch();
        }
    }, [filesList]);

    const onUploadDocumentModalClose = () => {
        setUploadDocModalOpened(false);
        setFilesList(undefined);
    };

    const onDelete = async () => {
        const res = await deleteVLinkPremiumFileMutation({
            variables: {
                id: parseInt(paramsId, 10),
                fileId: parseInt(selectedFileId, 10),
                type: formType, // "VENDOR" or "PRODUCT"
            },
        });

        const {
            data: {
                vlink: {
                    deleteVlinkPremiumFile: { success },
                },
            },
        } = res;

        if (success) {
            newSuccessAlert("Video deleted successfully");
        }

        setFilesList(undefined);
        setSelectedFileId(null);
    };

    const closeDeleteModal = () => {
        setFileDeleteModalOpened(false);
        setSelectedFileId(null);
    };

    const openVideoPlayer = (fileID) => {
        setVideoPlayerOpened(true);
        setSelectedFileId(fileID);
    };

    const closeVideoPlayer = () => {
        setVideoPlayerOpened(false);
        setSelectedFileId(null);
    };

    return (
        <FeatureBlock
            title="Videos"
            headerButtonValue={showButtons ? "Upload video" : null}
            onTitleBtnClick={() => setUploadDocModalOpened(true)}
        >
            <LargeConfirmationModal
                show={deleteFileModalOpened}
                onClose={closeDeleteModal}
                onSubmit={() => {
                    closeDeleteModal();
                    onDelete();
                }}
                onDiscard={closeDeleteModal}
                title="Delete Document?"
                description="Once deleted, you will not be able to recover the document"
            />
            {videoPlayerOpened &&
                <LightBoxPlayer
                    active={videoPlayerOpened}
                    handleClose={closeVideoPlayer}
                    fileID={parseInt(selectedFileId, 10)}

                    formType={formType}
                />
            }
            {uploadDocModalOpened && (
                <UploadVideoModal
                    show={uploadDocModalOpened}
                    onClose={onUploadDocumentModalClose}
                    uploadId={paramsId}
                    type={formType}
                />
            )}
            <FilesListWrapper>
                {(filesListLoading || deleteFileLoading) && (
                    <Dimmer active inverted inline="centered" style={{ height: "100%" }}>
                        <Loader />
                    </Dimmer>
                )}
                {filesList !== undefined &&
                    Array.isArray(filesList) &&
                    filesList.map((file) => {
                        return (
                            <DocumentThumbnail
                                type="video"
                                file={file}
                                key={file.id}
                                allowDelete={permissions.isVLinkPremiumUser}
                                onDelete={() => {
                                    setSelectedFileId(file.id);
                                    setFileDeleteModalOpened(true);
                                }}
                                onClick={() => openVideoPlayer(file.id)}
                            />
                        );
                    })}
            </FilesListWrapper>
        </FeatureBlock>
    );
};

const LightBoxPlayer = ({ active, handleClose, fileID, formType }) => {
    const [video, setSelectedVideoData] = useState(null);

    const { newErrorAlert } = useAlerts();
    const { id: paramsId } = useParams();

    const
        { loading: vimeoVideoDataLoading, error: vimeoVideoDataErr, data: vimeoVideoData } = useQuery(VimeoVideoQuery,{
        variables: {
            id: paramsId,
            videoID: fileID,
            type: formType,
        },
    });

    useEffect(() => {
        if (vimeoVideoData) {
            setSelectedVideoData(vimeoVideoData?.vlink?.vimeoVideo);
        }
        if (vimeoVideoDataErr) {
            newErrorAlert("Error receiving video data");
            handleClose();
        }
    }, [vimeoVideoData, vimeoVideoDataErr, newErrorAlert]);

    useEffect(()=>{
        console.log('vimeoVideoDataLoading:', vimeoVideoDataLoading);
        console.log('video:', video);
    },[video, vimeoVideoDataLoading])

    if (!active) {
        return null;
    }

    if (video && video.status && video.status !== "available") {
        newErrorAlert("This video is not yet available");
        handleClose();
        return null;
    }

    return (
        <Dimmer active={true} onClickOutside={handleClose} page>
            {vimeoVideoDataLoading && <Loader />}
            {!vimeoVideoDataLoading && video && (
                <Vimeo
                    video={video?.url}
                    autoplay
                    autopause
                    width={800}
                    height={600}
                    color={"#ffc309"}
                    onError={(err) => {
                        console.error("Video playback error:", err);
                        newErrorAlert("Video playback error");
                        handleClose();
                    }}
                />
            )}
        </Dimmer>
    );
};
