import React from "react";

const BackIcon = props => (
    <svg width={20} height={17} {...props}>
        <path
            d="M19.692 8.311a1.269 1.269 0 0 0-1.27-1.27H4.495L9.658 2.23A1.27 1.27 0 1 0 7.924.375L.403 7.38a1.273 1.273 0 0 0 0 1.858l7.521 7.009a1.27 1.27 0 1 0 1.734-1.858L4.495 9.58h13.927a1.269 1.269 0 0 0 1.27-1.27z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
);

export default BackIcon;

