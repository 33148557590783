import React from "react";
import styled from "styled-components/macro";
import * as PropTypes from "prop-types";
import { format } from "date-fns";

import ValueCard from "../ValueCard/ValueCard";

const SummaryWidgetWrapper = styled.div`
    padding-right: 5%;
`;
const WelcomeTitle = styled.h5`
    margin-bottom: 1.6rem;
    text-align: left;
    font-size: 2rem;
    color: black;
    @media only screen and (max-width: 1440px) {
        font-size: 1.9rem;
    }
`;
const DateTitle = styled.p`
    margin-bottom: 10%;
    text-align: left;
    font-size: 1.3rem;
    color: #acb1b6;
    @media only screen and (max-width: 1440px) {
        font-size: 1.2rem;
    }
`;

export const SummaryWidget = ({ username = "", totalSpend = "100 000,00", vendorCount = 1, productCount = 2 }) => {
    return (
        <SummaryWidgetWrapper>
            <WelcomeTitle>Welcome back {username}!</WelcomeTitle>
            <DateTitle>Today's date: {format(new Date(), "dddd, Do of MMMM, YYYY ")}</DateTitle>
            <ValueCard title="Total Spend" value={totalSpend} size="large" widthTitle="53%" />
            <ValueCard title="Vendor Count" value={vendorCount} size="large" widthTitle="53%" />
            <ValueCard title="Product Count" value={productCount} size="large" widthTitle="53%" />
        </SummaryWidgetWrapper>
    );
};

SummaryWidget.propTypes = {
    username: PropTypes.string,
    totalSpeed: PropTypes.string,
    vendorCount: PropTypes.number,
    productCount: PropTypes.number,
};

export default SummaryWidget;
