import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";

/**
 *
 * @param {object} agreement
 * @returns {Promise<void>}
 */
const addMetaToAgreement = async (agreement, activeEntity) => {
    const {
        data: { createAgreementMeta: newMeta },
    } = await API.graphql(
        graphqlOperation(mutations.createAgreementMeta, {
            input: {
                groupsCanAccess: activeEntity?.groupName,
            },
        })
    );

    const {
        data: { updateAgreement: updatedAgreement },
    } = await API.graphql(
        graphqlOperation(mutations.updateAgreement, {
            input: {
                id: agreement.id,
                agreementMetaId: newMeta.id,
            },
        })
    );
    return updatedAgreement;
};

export default addMetaToAgreement;
