import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import userEmail from "../../utils/userEmail";

const updateProduct = async (updatedProduct, { dispatch, user }) => {
    dispatch({ type: "UPDATE_PRODUCT" });

    console.log("updatedProduct function : ", updatedProduct);

    await API.graphql(
        graphqlOperation(mutations.updateProduct, {
            input: {
                ...updatedProduct,
                updatedBy: userEmail(user),
            },
        })
    );

    dispatch({ type: "PRODUCT_UPDATED" });
};

export default updateProduct;
