export const _findInTree = (id, tree, path = "") => {
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if (item.id === id) {
            return {
                id: item.id,
                label: item.label,
                path: !!path ? `${path} > ${item.label}` : `${item.label}`
            };
        } else if (item.children && item.children.length > 0) {
            let foundInChildren = _findInTree(id, item.children, !!path ? `${path} > ${item.label}` : `${item.label}`);
            if (foundInChildren) {
                return {
                    id: foundInChildren.id,
                    label: foundInChildren.label,
                    path: foundInChildren.path
                };
            }
        }
    }
};