export default {
    A10014P11: "ABB Enterprise Software Inc",
    A10024P11: "Accsys",
    A10052P11: "Alba Soluzioni",
    A10062P11: "Allocation Specialists LTD (ASL)",
    A10073P11: "American Chemistry Council",
    A10074P11: "American Fuel & Petrochemical Manufacturers (AFPM)",
    A10089P11: "APX Group",
    A10097P11: "Argus Media, Ltd.",
    A10106P11: "Asian Feed",
    A10113P11: "ASX Energy Ltd",
    B10181P11: "BNamericas",
    B10207P11: "BT",
    C10234P11: "Carbon Pulse",
    C10239P11: "Catalyst Commodities Ltd.",
    C10262P11: "Chemical Data L.P.",
    C10263P11: "Chesler Analytics LLC",
    C10271P11: "CITAC Africa Ltd",
    C10272P11: "Citi",
    C10282P11: "Climate Connect Ltd.",
    C10284P11: "ClipperData LLC",
    C10295P11: "Coaldesk, LLC",
    C10306P11: "Commodity 3",
    C10307P11: "Commodity Advisory Corporation of Texas",
    C10310P11: "Commodity Weather Group",
    C10348P11: "C-Scape Consulting Corp.",
    C10353P11: "Customized Energy Solutions Ltd.",
    C10355P11: "Czech Financial Benchmark Facility s.r.o.",
    D10371P11: "Dataminr, Inc.",
    E10466P11: "Energy Aspects",
    E10467P11: "Energy GPS",
    E10469P11: "Energy News Today",
    E10470P11: "Energy NewsData",
    E10472P11: "Enterprise Singapore",
    E10509P11: "European Energy Exchange",
    E10511P11: "European Money Markets Institute (EMMI)",
    F10559P11: "FGE Group",
    F10593P11: "Fitch",
    F10601P11: "FlightAware",
    F10605P11: "Forrester Research Ltd",
    F10614P11: "Frontier Myanmar Research Ltd",
    F10618P11: "FTSE International Limited",
    G10637P11: "Genscape",
    G10640P11: "Gestore Mercati Energetici",
    G10655P11: "GlobalView Software, Inc.",
    G10669P11: "Green Energy Markets Pty Ltd",
    G10671P11: "Greenfact AS",
    H10706P11: "Houston BioFuels Consultants",
    H10707P11: "HPDI",
    I10722P11: "IHS Global Limited",
    I10723P11: "IHS Markit",
    I10742P11: "Industrial Information Resources",
    I10751P11: "Informa Economics",
    I10757P11: "Infospectrum",
    I10760P11: "Inframation",
    I10763P11: "Innovation Tap LLC",
    I10767P11: "Insight Kurdistan",
    I10778P11: "Intercontinental Exchange, Inc.",
    I10780P11: "Interfax Europe Ltd",
    I10777P11: "Interactive Data Corporation",
    I10781P11: "International Energy Agency",
    I10788P11: "INTL FCStone Ltd",
    I10826P11: "ITRS Europe Ltd",
    J10830P11: "Advisian",
    J10835P11: "JLC Network Technology Co., Ltd",
    K10844P11: "Kayrros",
    K10845P11: "KEMA Retail Advisory",
    K10853P11: "Korea National Oil Corporation",
    K10855P11: "Kpler",
    L10860P11: "LAC Group",
    L10869P11: "Leonard B. Levine & Associates",
    L10872P11: "LexisNexis",
    L10887P11: "Lloyd's List",
    M10913P11: "Marine Exchange of the San Francisco Bay Region",
    M10914P11: "Market Data Services Limited",
    M10931P11: "Maytech Communications Ltd",
    M10936P11: "MDA Federal Inc.",
    M10941P11: "Melita Weather Associates Inc.",
    M10952P11: "Meteologica S. A",
    M10972P11: "Mizuho Securities USA Inc.",
    M10978P11: "Montel AS",
    M10982P11: "Morningstar Inc",
    N11009P11: "Navarik Corp.",
    N11018P11: "New Power",
    N11029P11: "NGI Natural Gas Intelligence",
    N11052P11: "NYSE",
    N11055P11: "NZ Financial Markets Association Inc",
    O11060P11: "Oceaneering",
    O11063P11: "Oil World",
    O11065P11: "OMIP",
    O11068P11: "OMR",
    O11081A11: "OPIS Energy Group",
    O11089P11: "Orbital Insight",
    O11104P11: "Oxford Economics Ltd",
    P11126P11: "PetroChem Wire, LLC.",
    P11127P11: "Petroleum Institute of Thailand",
    P11128P11: "Petro-Logistics",
    P11138P11: "PJK International",
    P11140P11: "Planetary Data",
    P11141P11: "PLS Inc.",
    P11148P11: "PortVision",
    P11149P11: "Poten & Partners, Inc.",
    P11158P11: "Prima Regulated Markets Limited",
    P11159P11: "Prime Analytics",
    R11206P11: "R.J. O'Brien & Associates LLC",
    R11210P11: "RANSQUAWK",
    R11217P11: "RBN Energy LLC",
    R11225P11: "Reed Business Information",
    R11239P11: "RIM Intelligence Co.",
    S11258P11: "S&P Global",
    S11260A11: "S&P Global Platts",
    S11286P11: "Seeking Alpha",
    S11311P11: "Singtel",
    S11317P11: "Skipping Stone",
    S11325P11: "Snake Hill Energy Resources, Inc.",
    S11327P11: "SNL Financial",
    S11348P11: "SparkSpread",
    S11372P11: "Stillwater Publications",
    S11376P11: "Stormpulse",
    S11381P11: "Stratfor LLC",
    S11397P11: "Swaps Monitor Publications",
    T11411P11: "TankTerminals.com",
    T11428P11: "Telvent DTN, Inc.",
    T11432P11: "Tesla Forecasting Solutions",
    F11444P11: "The Foster Report",
    G11445P11: "The Gartman Letter",
    J11446P11: "The Jacobsen Publishing Company",
    S11452P11: "The Schork Group",
    W11455P11: "The Weather Company",
    T11462P11: "Thomson Reuters",
    T11475P11: "TMX Group",
    T11497P11: "Tradesignal",
    T11508P11: "Trayport",
    U11524P11: "UBS Securities",
    U11526P11: "UK Met Office - ECMWF",
    U11538P11: "Ursa Space Systems Inc.",
    V11546P11: "Vaisala Inc.",
    W11583P11: "Water Street Partners, LLC",
    W11599P11: "Wood MacKenzie Limited",
    W11600P11: "WoodMackenzie NA Gas Retainer",
    Y11624P11: "YES Energy, LLC",
    Z11635P11: "Zhejiang Huarui Information Consulting Co., Ltd.",
    B10175P11: "Bloomberg L.P.",
    F10560P11: "FIA Technology Services, Inc",
    W11642P11: "WSI Corp",
    L11643P11: "Logical Information Machines",
    C10334P11: "CQG, Inc.",
    C10266P11: "CME Group",
    K90001P11: "KRM22",
    D90002P11: "DOT Imaging, S.L.U.",
    D90003P11: "DrillingInfo",
    E90004P11: "EAC",
    I90005P11: "ISTA Mielke GmbH",
    L90006P11: "Library Assoc., Inc.",
    M90007P11: "MDA Information Systems, LLC",
    N90008P11: "NewsData Corp.",
    R90009P11: "Reuters Limited",
    R90010P11: "RIM Publications",
    V90011P11: "VendEx Solutions",
    K90012P11: "KEMA, Inc.",
    F90013P11: "Futuresource Consulting",
    E90014P11: "eSignal",
    M90015P11: "Morningstar Commodity Data, Inc.",
    I90016P11: "ITRS Group, Ltd.",
    D90017P11: "Deimos Imaging S.L.U.",
    O90018P11: "1Derrick",
    T90019P11: "3 Tier, Inc.",
    T90020P11: "3sun Group Limited",
    A90021P11: "Activistmonitor",
    A90022P11: "Acuris",
    A90023P11: "Acuris Risk Intelligence",
    A90024P11: "Acuris Studios",
    A90025P11: "Agricultural Press Farms Limited",
    A90026P11: "Alberdon International",
    A90027P11: "Alpha Exchange Inc.",
    A90028P11: "Alpha Trading Systems Ltd.",
    A90029P11: "Anti-Corruption Report",
    A90030P11: "Approved Index Limited",
    A90031P11: "Ascend China Holding Limited",
    A90032P11: "ASX Benchmark Pty Limited",
    A90033P11: "ASX Group",
    a90034P11: "automotiveMastermind",
    A90035P11: "AVCJ",
    A90036P11: "Aviation Systems Maintenance, Inc",
    B90037P11: "Bankers Almanac",
    B90038P11: "Blackpeak",
    B90039P11: "Brook Hunt & Associates Ltd.",
    C90040P11: "Cambridge Energy Research Associates (CERA)",
    C90041P11: "Canadian Depository for Securities",
    C90042P11: "Capital Profile",
    C90043P11: "Carbon Market Pulse Limited",
    C90044P11: "Census Commodity Data Limited",
    C90045P11: "Centre for Asia Pacific Aviation Pty",
    C90046P11: "Citeline Inc.",
    C90047P11: "Concentric Energy Publications, Inc",
    C90048P11: "Corporate Fundamentals, Inc.",
    C90049P11: "Cortex Business Solutions",
    C90050P11: "Creative Analytics",
    C90051P11: "Creditflux",
    C90052P11: "Cross - Border Information (London) Ltd",
    C90053P11: "Crown Jewel Consultants Ltd.",
    C90054P11: "Customized Energy Solutions India Private Limited",
    C90055P11: "Cybersecurity Law Report",
    D90056P11: "Data Transmission Network",
    D90057P11: "DataGenic",
    D90058P11: "Dataline",
    D90059P11: "Datamonitor",
    D90060P11: "Dealreporter",
    D90061P11: "Debtwire",
    D90062P11: "Decyfer Ltd",
    D90063P11: "DeriveXperts",
    D90064P11: "DeWitt & Co",
    D90065P11: "Downstream",
    D90066P11: "DTN",
    E90067P11: "Elecnor, S.A.",
    E90068P11: "Electrobras Cepel",
    E90069P11: "Electron Financial Publishing Limited",
    E90070P11: "Emerging Portfolio Fund Research, Inc.",
    E90071P11: "Energy Census",
    E90072P11: "Energy Management Institute",
    E90073P11: "Energy Market Consultants Ltd.",
    E90074P11: "Energyst Media Ltd",
    E90075P11: "Enverus",
    E90076P11: "EPEX SPOT",
    E90077P11: "eSignal Europe",
    F90078P11: "FACTS Global Energy Ltd",
    F90079P11: "Farm Progress",
    F90080P11: "Farmade Management Systems Ltd",
    F90081P11: "Fastmarkets",
    F90082P11: "Ferecon Limited",
    F90083P11: "FlightGlobal Limited",
    F90084P11: "FMB Consultants Ltd (FMB)",
    F90085P11: "Fundalytics",
    G90086P11: "Genstar Capital",
    G90087P11: "Geosys Inc.",
    G90088P11: "Global Energy Decisions, LLC",
    G90089P11: "Great North Road Media Inc.",
    G90090P11: "Greentech Media",
    G90091P11: "Grexel",
    H90092P11: "Hedge Fund Law Report",
    H90093P11: "HelloWallet Holdings, Inc.",
    H90094P11: "Highway Information Systems, Inc",
    I90095P11: "I&E Systems Pty Limited",
    I90096P11: "IHS CERA",
    I90097P11: "IHS Markit Agribusiness UK Limited",
    I90098P11: "IIR Energy",
    i90099P11: "iMoneyNet, Inc",
    I90100P11: "Independent Commodity Intelligence Services (ICIS)",
    I90101P11: "Infield Systems Limited",
    I90102P11: "Informa Business Information, Inc.",
    I90103P11: "Informa Group Ltd",
    I90104P11: "Insight Iraq Limited",
    I90105P11: "Insights Global",
    I90106P11: "Institute for International Research",
    I90107P11: "INTECSEA, Inc",
    I90108P11: "Integer Research",
    I90109P11: "Intelligence Press, Inc.",
    I90110P11: "International Customer Management Institute",
    I90111P11: "InvestSoft Technology, Inc.",
    I90112P11: "Ipreo",
    I90113P11: "Irisium Ltd.",
    I90114P11: "Iron Mountain Intellectual Property Management",
    J90115P11: "Jacobs Consultancy, Inc.",
    J90116P11: "Jacobs Engineering Group",
    J90117P11: "Jim Jordan & Associates",
    J90118P11: "John Wilson & Partners",
    K90119P11: "K-Patents OY",
    K90120P11: "KRM Central Limited",
    K90121P11: "KRM22 ProOpticus LLC",
    L90122P11: "Leosphere, Inc.",
    L90123P11: "LexisNexis Risk Solutions",
    L90124P11: "Light Reading",
    L90125P11: "Lloyd's List Intelligence",
    L90126P11: "Lloyd's Maritime Academy",
    L90127P11: "London Stock Exchange PLC",
    M90128P11: "Macroeconomic Advisors",
    M90129P11: "Mardev",
    M90130P11: "MarketView Software, Inc.",
    M90131P11: "Markit Ltd",
    M90132P11: "MCG Energy Solutions",
    M90133P11: "Mergermarket Limited",
    M90134P11: "Metalprices.com, Inc.",
    M90135P11: "MetDesk Limited",
    M90136P11: "MeteoGroup",
    M90137P11: "MineralSoft",
    M90138P11: "Ministry Of Trade and Industry",
    M90139P11: "Monument Consulting, LLC",
    N90140P11: "National Petrochemical & Refiners Association",
    N90141P11: "Navx",
    N90142P11: "NEWSQUAWK",
    N90143P11: "Nodal Exchange",
    N90144P11: "Novation Analytics",
    N90145P11: "Nu Metrics Inc",
    O90146P11: "Object+Financial Services BV",
    O90147P11: "Oil Price Information Service",
    O90148P11: "Oildex",
    O90149P11: "Oil-Law Records Corp",
    O90150P11: "Omdia",
    P90151P11: "Paragon Energy Solutions",
    P90152P11: "PaRR",
    P90153P11: "Pattern Recognition Technologies",
    P90154P11: "PCI Group, Inc",
    P90155P11: "Perfect Information",
    P90156P11: "Petroleum Argus Ltd",
    P90157P11: "Pitchbook Data, Inc.",
    P90158P11: "PortStorage Group B.V.",
    P90159P11: "Powerit Solutions, LLC",
    P90160P11: "Powernext",
    P90161P11: "PraxSoft, Inc.",
    P90162P11: "Prism Valuation",
    P90163P11: "PXE",
    R90164P11: "Refinitiv",
    R90165P11: "RELX Group",
    R90166P11: "RequiSight, LLC",
    R90167P11: "Revco, LLC",
    R90168P11: "Rosenberg WorleyParsons AS",
    R90169P11: "Routledge",
    R90170P11: "RS Energy Group",
    R90171P11: "Rystad Energy Limited",
    S90172P11: "Schneider Electric",
    S90173P11: "Second Wind",
    S90174P11: "Shorcan Brokers Limited",
    S90175P11: "Sigmet, Inc",
    S90176P11: "Singapore Telecommunications Limited",
    S90177P11: "Site-Specific Technology Development",
    S90178P11: "Snowflake Software Limited",
    S90179P11: "Sparks Companies, Inc",
    S90180P11: "Spensa Technologies",
    S90181P11: "StoneX Group Inc.",
    S90182P11: "Strategy Execution",
    S90183P11: "Surface Systems, Inc.",
    S90184P11: "Sustainalytics",
    T90185P11: "TABrewer Consulting",
    T90186P11: "Taylor & Francis",
    T90187P11: "TBG AG",
    T90188P11: "TBG Energy Italy S.p.A.",
    T90189P11: "The National Petroleum Refiners Association",
    T90190P11: "TIM",
    T90191P11: "TMX Technology Solutions Inc.",
    T90192P11: "Tradition Market Data",
    T90193P11: "Traffic Tech (Gulg) W.L.L",
    T90194P11: "TSX Trust",
    U90195P11: "UBM plc",
    U90196P11: "Unquote",
    U90197P11: "UrtheCast Corp",
    V90198P11: "Vaisala China Ltd",
    V90199P11: "Ventyx Inc.",
    V90200P11: "Versify Solutions",
    V90201P11: "Verteq Instuments Inc.",
    W90202P11: "Wealthmonitor",
    W90203P11: "Weather Decision Technologies, Inc.",
    W90204P11: "Weatherzone",
    W90205P11: "Weft, Inc",
    W90206P11: "Wilkens Weather Technologies, L.P.",
    W90207P11: "Worley",
    X90208P11: "Xtract Research",
    I90209P11: "ICE Data, LLP",
    I90210P11: "Intercontinental Exchange Holdings, Inc.",
    I90211P11: "Interactive Data (Europe) Limited",
    I90212P11: "ICE Benchmark Administration Limited",
    I90213P11: "Interactive Data Desktop Solutions (Europe)",
    G90214P11: "Genscape International, Inc.",
    G90215P11: "Genscape, Inc.",
};
