import React, { useCallback, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import LogoXLarge from "../components/LogoXLarge";
import theme from "../styles/theme";
import { CopyRight } from "../components/CopyRight";
import { StyledScrollbar } from "../components/StyledScrollbar";
import { FormSection } from "../components/FormSection";
import { FooterLinks } from "../components/FooterLinks";
import { InlineLink } from "../components/InlineLink";
import { SignInForm } from "../components/forms/SignInForm";
import { NewPasswordForm } from "../components/forms/NewPasswordForm";
import { ForgotPasswordForm } from "../components/forms/ForgotPasswordForm";
import { ResetPasswordForm } from "../components/forms/ResetPasswordForm";

import { Route, Switch } from "react-router-dom";

import bgGreyImage from "../images/GGB-hero_grey_1950x1201_copy-compressor-1.png";

import Iframe from "react-iframe";

import CloseIcon from "../components/icons/CloseIcon";
import { VerifyTokenForm } from "../components/forms/VerifyTokenForm";

const LoginWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: stretch;
    height: 100%;
`;

const LoginGraphic = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
   
    align-items: center;
    justify-content: center;
     
    //background: ${theme.secondary};
    flex: 2;
    height: 100%;
    background: url("${bgGreyImage}") no-repeat center center fixed;
    background-size: cover;
`;

const LoginPanel = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 500px;
    height: 100%;
`;

const Slogan = styled.div`
    position: relative;
    display: block;
    font-size: 32px;
    font-weight: normal;
    color: white;
    text-align: center;
    line-height: 1.2;
    width: 70%;
`;

const LoginMessage = styled.div`
    position: relative;
    display: block;
    font-size: 16px;
    color: #595973;
    letter-spacing: -0.01px;
    text-align: left;
`;

const LoginPanelWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: auto;
    padding-bottom: 100px;

    justify-content: center;
    align-items: stretch;
`;

const ModalBg = styled.div`
    position: absolute;
    display: grid;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
`;

const VModal = styled.div`
    position: relative;
    display: inline-block;

    padding: 20px;

    background: white;

    border-radius: 3px;

    margin: 1rem;

    min-height: 300px;
    min-width: 300px;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

    justify-self: center;
`;

const ButtonInline = styled.span`
    position: relative;
    display: inline-block;
    font-size: 16px;
    color: #595973;
    letter-spacing: -0.01px;
    text-align: center;
    font-weight: normal;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const ModalClose = styled(CloseIcon)`
    position: absolute;
    display: block;

    height: 20px;
    width: 20px;

    top: 20px;
    right: 10px;
`;

const ModalContent = styled.div`
    position: relative;
    display: block;
    margin-top: 20px;
`;

const Modal = ({ onClose, children }) => {
    return ReactDOM.createPortal(
        <ModalBg onClick={onClose}>
            <VModal>
                <ModalClose data-cy={"modal-close-btn"} onClick={onClose}>
                    Close
                </ModalClose>
                <ModalContent>{children}</ModalContent>
            </VModal>
        </ModalBg>,
        document.getElementById("portal")
    );
};

const LogoLargeWrapper = styled.div`
    position: relative;
    display: flex;

    align-items: center;
    justify-content: center;
`;

const LoginPage = ({ history }) => {
    const tocIUrl = "https://app.termly.io/document/terms-of-use-for-saas/1c8b3515-63da-4722-b065-541c9dab16e5";
    const privacyUrl = "https://app.termly.io/document/privacy-policy/ee41e683-e2e2-40c2-8f28-9e8751bd8e3e";

    const [showModal, setShowModal] = useState(false);
    const [currentUrl, setCurrentUrl] = useState(tocIUrl);

    const handleShowModal = useCallback(
        (url) => {
            // console.log("url", url);
            setCurrentUrl(url);
            setShowModal(true);
        },
        [currentUrl]
    );

    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);

    return (
        <LoginWrapper>
            <LoginGraphic>
                <FormSection>
                    <LogoLargeWrapper>
                        <LogoXLarge textColor={"#fff"} />
                    </LogoLargeWrapper>
                    <Slogan style={{ marginTop: 40 }}>
                        The centralized hub for the financial services market data industry
                    </Slogan>
                </FormSection>
            </LoginGraphic>
            <LoginPanel>
                <StyledScrollbar>
                    <LoginPanelWrapper>
                        <FormSection style={{ marginTop: 180 }}>
                            <h1 style={{ fontWeight: 500 }}>Login</h1>
                        </FormSection>

                        <Switch>
                            <Route path={"/login"} exact render={() => <SignInForm />} />
                            <Route path={"/login/verify-token"} render={() => <VerifyTokenForm />} />
                            <Route path={"/login/new-pass"} render={() => <NewPasswordForm />} />
                            <Route path={"/login/forgot"} render={() => <ForgotPasswordForm />} />
                            <Route path={"/login/reset"} render={() => <ResetPasswordForm />} />
                        </Switch>

                        <FormSection style={{ marginTop: 80 }}>
                            <LoginMessage>
                                Need help? <InlineLink to={""}>support@vendexsolutions.com</InlineLink>
                            </LoginMessage>
                            <ButtonInline onClick={() => history.push("/login/forgot")}>Forgot Password?</ButtonInline>
                        </FormSection>

                        <FooterLinks>
                            <ButtonInline onClick={() => handleShowModal(tocIUrl)}>Terms of use</ButtonInline>
                            <ButtonInline onClick={() => handleShowModal(privacyUrl)}>Privacy</ButtonInline>
                            {showModal && (
                                <Modal onClose={handleCloseModal}>
                                    <Iframe
                                        url={currentUrl}
                                        width="450px"
                                        height="450px"
                                        display="initial"
                                        position="relative"
                                    />
                                </Modal>
                            )}
                        </FooterLinks>

                        <CopyRight>VendEx Solutions © {new Date().getFullYear()}</CopyRight>
                    </LoginPanelWrapper>
                </StyledScrollbar>
            </LoginPanel>
        </LoginWrapper>
    );
};

export default LoginPage;
