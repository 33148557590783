import { useContext, useEffect, useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { PageHeader } from "../../components/PageHeader";
import { PageWrapper } from "../../components/PageWrapper";
import PremiumForm from "../../components/vlink/PremiumForm";
import RevisionForm from "../../components/vlink/RevisionForm";
import Breadcrumb from "../../components/breadcrumb/VendexBreadcrumb";
import { AuthContext } from "../../context/AuthContext";

export const VendorFormsWrapper = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
`;

export const MessageBox = styled.div`
    margin: 10px;
    > div {
        padding: 30px;
        background-color: rgba(80, 188, 64, 0.2);
    }

    h3 {
        font-size: 24px;
        font-weight: normal;
    }

    p {
        font-size: 16px;
        color: #595973;
    }
`;

export const initialMessageBoxData = {
    message: null,
    subMessage: null,
};

export default function VendorDetails() {
    const [messageBoxData, setMessageBoxData] = useState(initialMessageBoxData);
    const messageRef = useRef(null);
    const history = useHistory();

    const {
        context: { user },
        isVLinkAnalyst,
        isVLinkVendor,
        isVLinkSuperUser,
    } = useContext(AuthContext);

    let context = isVLinkVendor(user) ? "VENDOR" : isVLinkAnalyst(user) || isVLinkSuperUser(user) ? "VENDEX" : null;

    const breadcrumbSections = [
        { key: "Vendor Dashboard", content: "Vendor Dashboard", onClick: () => history.push("/vlink/dashboard") },
        { key: "Vendor-Profile", content: "Vendor Profile", active: false },
    ];

    useEffect(
        function hideMessageBox() {
            if (context === "VENDEX") {
                let timeout = null;
                if (messageBoxData.message) {
                    timeout = setTimeout(setMessageBoxData, 5000, initialMessageBoxData);
                }

                return () => timeout && clearTimeout(timeout);
            }
        },
        [messageBoxData, setMessageBoxData, context]
    );

    if (context == null) {
        return <Redirect to="/vlink/dashboard" />;
    }

    return (
        <PageWrapper>
            {context === "VENDEX" && (
                <div
                    style={{
                        background: "#FFC30B",
                        color: "white",
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "center",
                        padding: 6,
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        textTransform: "uppercase",
                        fontSize: 14,
                        fontWeight: 600,
                    }}
                >
                    VendEx analyst view
                </div>
            )}
            <Breadcrumb sections={breadcrumbSections} />
            <PageHeader title="Vendor Profile"></PageHeader>
            <MessageBox ref={messageRef}>
                {messageBoxData.message && (
                    <div>
                        <h3>{messageBoxData.message}</h3>
                        <p>{messageBoxData.subMessage}</p>
                    </div>
                )}
            </MessageBox>
            <VendorFormsWrapper>
                <RevisionForm
                    context={context}
                    level="VENDOR"
                    setMessageBoxData={setMessageBoxData}
                    messageRef={messageRef}
                />
                <PremiumForm context={context} level="VENDOR" />
            </VendorFormsWrapper>
        </PageWrapper>
    );
}
