import React from "react";

const LocationIcon = props => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.089 5.48267C11.6329 5.48267 12.1646 5.64395 12.6168 5.94612C13.0691 6.2483 13.4215 6.67779 13.6297 7.18029C13.8378 7.68278 13.8923 8.23572 13.7862 8.76916C13.6801 9.30261 13.4182 9.79261 13.0336 10.1772C12.649 10.5618 12.159 10.8237 11.6255 10.9298C11.0921 11.0359 10.5391 10.9815 10.0366 10.7733C9.53414 10.5652 9.10465 10.2127 8.80248 9.76048C8.5003 9.30825 8.33902 8.77656 8.33902 8.23267C8.33985 7.50358 8.62985 6.80458 9.14539 6.28904C9.66094 5.77349 10.3599 5.4835 11.089 5.48267ZM11.089 9.60767C11.361 9.60767 11.6268 9.52702 11.8529 9.37594C12.079 9.22485 12.2553 9.0101 12.3594 8.75886C12.4634 8.50761 12.4907 8.23114 12.4376 7.96442C12.3845 7.69769 12.2536 7.45269 12.0613 7.26039C11.869 7.0681 11.624 6.93714 11.3573 6.88409C11.0905 6.83103 10.8141 6.85826 10.5628 6.96233C10.3116 7.0664 10.0968 7.24264 9.94575 7.46876C9.79466 7.69487 9.71402 7.96072 9.71402 8.23267C9.71444 8.59721 9.85944 8.9467 10.1172 9.20447C10.375 9.46224 10.7245 9.60724 11.089 9.60767Z" fill="#B5B5C3"/>
            <path d="M5.80466 2.94393C7.11123 1.6377 8.85533 0.86174 10.7004 0.765823C12.5454 0.669905 14.3606 1.26082 15.7955 2.42453C17.2305 3.58824 18.1835 5.24224 18.4707 7.06731C18.7579 8.89238 18.3589 10.7591 17.3506 12.3073L12.1554 20.2828C12.0398 20.4603 11.8817 20.6061 11.6955 20.7071C11.5093 20.808 11.3008 20.8609 11.089 20.8609C10.8772 20.8609 10.6688 20.808 10.4825 20.7071C10.2963 20.6061 10.1383 20.4603 10.0226 20.2828L4.82725 12.3073C3.89172 10.8711 3.47828 9.15707 3.65623 7.45235C3.83418 5.74764 4.5927 4.1559 5.80466 2.94393ZM5.97933 11.5568L11.089 19.4007L16.1987 11.5568C17.7628 9.1557 17.4273 5.9425 15.4011 3.91618C14.8348 3.3499 14.1626 2.9007 13.4227 2.59423C12.6828 2.28776 11.8898 2.13002 11.089 2.13002C10.2882 2.13002 9.49518 2.28776 8.75531 2.59423C8.01544 2.9007 7.34319 3.3499 6.77692 3.91618C4.75069 5.9425 4.41527 9.1557 5.97933 11.5568Z" fill="#B5B5C3"/>
        </svg>
    );
};

export default LocationIcon;
