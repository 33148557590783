import styled from "styled-components";
import styledMap from "styled-map";

export const ButtonWrapper = styled.button`
    position: relative;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    height: 42px;
    padding: 10px 24px;
    font-size: 16px;
    color: ${styledMap`
        negative: #FFFFFF;
        halo: #6C8BFF;
        negative-halo: #DB2828;
        default: #FFFFFF;
    `};
    background: ${styledMap`
        halo: none;
        negative: #DB2828;
        negative-halo: none;
        none: none;
        yellow: #fbbd08;
        default: #6C8BFF;
    `};
    opacity: ${styledMap`
        disabled: 0.5;
        default: 1;
    `}
    border-color: ${styledMap`
        negative: #DB2828;
        negative-halo: #DB2828;
        none: none;
        default: #6C8BFF;
    `};
    border-width: ${styledMap`
        halo: 1px;
        negative-halo: 1px;
        default: 0px;
    `};
    border-style: solid;
    outline: none;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.11);
    border-radius: 3px;
    cursor: ${styledMap`
        disabled: not-allowed;
        default: pointer;
    `}

    &:hover{
        opacity: ${styledMap`
            disabled: 0.5;
            default: 0.95;
    `}
    }
    
    & > * {
      margin-right: 16px;
    }

    margin-right: ${styledMap`
        space-between: 1em;
        default: 0;
    `}
`;
