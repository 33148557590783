import { useMemo } from "react";
import { useState } from "react"
import styled from "styled-components";

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: bold;

    & .ceritfy {
        cursor: pointer;
        i {
            color: #4FAC32;
            font-size: 14px;    
        }    
    }
`;

export default function FormRowListGroup({name, title, children, context, locked, autoCertifyGroup }) {
    // const [certifyAll, setCertifyAll] = useState(false);

    // function certifyAllClickHandler() {
    //     // if (!certifyAll) {
    //         setCertifyAll(!certifyAll);
    //     // }
    // }

    const groupProps = useMemo(
        () => ({ group: name, autoCertifyGroup }), 
        [name, autoCertifyGroup]
    );

    return (
        <div>
            <Header>
                <h3>{title}</h3>
                {/* {context === 'VENDOR' && !locked &&
                    <div className='ceritfy' onClick={certifyAllClickHandler}>
                        <i className={`circle ${certifyAll ? 'check' : 'outline'} icon`}></i>
                        <span>Authenticate All</span>
                    </div>
                } */}
            </Header>
            {children(groupProps)}
        </div>
    )
}