import { Dropdown } from "semantic-ui-react";
import React from "react";

export const VendDropDown = ({ field, form, values, options, onChange, placeholder, ...props }) => {
    const defaultHandler = (e, data) => {
        form.setFieldValue(field.name, data.value);
    };
    const onChangeHandler = onChange || defaultHandler;
    return (
        <Dropdown
            placeholder={placeholder || `Select`}
            fluid
            data-cy={`vendor-selector-${field.name}`}
            value={field.value}
            onBlur={field.onBlur}
            onChange={onChangeHandler}
            {...props}
            search
            selection
            clearable
            options={options}
        />
    );
};
