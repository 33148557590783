// Replace \n with <br> tags for html
export const newLinesToBreaks = (text) => {
    if (!text) {
        return "";
    }
    const res = text ? text.replace(/(?:\r\n|\r|\n)/g, "<br>") : "";
    return res;
};

export const escapeTerm = (string) => {
    if (!string) {
        return "";
    }
    // eslint-disable-next-line no-useless-escape
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

export const highlightText = (text, keyword) => {
    const regex = RegExp(keyword, "gi"); // case insensitive
    const res = text ? text.replace(regex, `<mark>${keyword}</mark>`) : "";
    return res;
};

// Shorten long text to the specific number of words and add "..." to the end of the text
export const truncateText = (input, length) => {
    if (typeof input !== "string") {
        return input;
    }

    if (input.split(" ").length <= length) {
        return input;
    }

    return `${input.split(" ").splice(0, length).join(" ")} ...`;
};
