import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Select from "react-select";

const Dropdown = ({ data, onChange, defaultValue }) => {
    const themeContext = useContext(ThemeContext);

    const dropDownStyles = {
        option: (styles, state) => {
            const background = () => {
                if (state.isSelected) return themeContext.gray3;
                else if (state.isFocused) return "transparent";
                return styles.backgroundColor;
            };
            return {
                ...styles,
                "&:hover": {
                    backgroundColor: themeContext.gray1,
                },
                backgroundColor: background(),
                color: "hsl(0, 0%, 20%)",
            };
        },
        control: (styles, state) => ({
            ...styles,
            borderRadius: "3px",
            boxShadow: "none",
            borderColor: themeContext.accent,
            "&:hover": {
                borderColor: "hsl(0, 0%, 80%)",
            },
            backGround: themeContext.inputBackground,
            width: "100%",
            margin: "5px 0",
        }),
    };

    return <Select options={data} onChange={(e) => onChange(e)} defaultValue={defaultValue} styles={dropDownStyles} />;
};

export default Dropdown;
