import { gql } from "@apollo/client";
export const vendorQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_hierarchy_code
            immediate_parent_name
            brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            vendor_stage_code
            market_segments {
                value
            }
            created_by
            created_at
            updated_by
            financials {
                year
                period_end_date
                revenue_amount
                currency
            }
            tickers {
                stock_exchange
                ticker_symbol
            }
            social_media {
                social_media_type
                url
            }
            investor_relations {
                contact_person
                phone_number
                email_address
                blog_url
            }
            corporate_actions {
                type
                action_time
                description
            }
        }
    }
`;

// Query that is initiated from the vendor editing page
export const editPageVendorQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_hierarchy_code
            immediate_parent_name
            brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            vendor_stage_code
            market_segments {
                code
                value
            }
            financials {
                id
                period_end_date
                revenue_amount
                currency
                currency_code
            }
            tickers {
                id
                stock_exchange
                ticker_symbol
            }
            social_media {
                id
                social_media_type
                social_media_type_code
                url
            }
            investor_relations {
                id
                contact_person
                phone_number
                email_address
                blog_url
            }
            corporate_actions {
                id
                type_code
                type
                action_time
                description
            }
            officers {
                id
                business_role
                business_role_code
                full_name
                business_role_additional
            }
            locations {
                id
                name
                type
                type_code
                region
                region_code
                division
                city
                country
                country_code
                street_address_1
                street_address_2
                state_province
                postal_code
                phone_number
                customer_support {
                    id
                    phone_number
                    support_hours
                    email_address
                    support_portal_url
                    time_zone
                    time_zone_code
                }
            }
        }
    }
`;

const MARKET_SEGMETS = gql`
    query ExampleQuery($id: ID!) {
        marketSegments(id: $id) {
            code
            value
        }
    }
`;

export const getVendorRevisionsQuery = gql`
    query getVendorRevisions($vendorRevisionsId: ID!) {
        vlink {
            vendorRevisions(id: $vendorRevisionsId) {
                key
                vendorId
                tableName
                fieldName
                itemTypeCode
                itemId
                parentItemId
                isReadonly
                vsourceFieldValue
                vsourceRecordValueJson
                latestRevision {
                    revisionId
                    oldFieldValue
                    newFieldValue
                    vendorSubmittedAt
                    vendexRespondedAt
                    oldRecordValueJson
                    newRecordValueJson
                    recordActionType
                    vendorActionTypeCode
                    vendexActionTypeCode
                    vendexDeclineComment
                    vendexResponseTypeCode
                    vendexResponseTypeValue
                }
                submittedRevision {
                    revisionId
                    oldFieldValue
                    newFieldValue
                    vendorSubmittedAt
                    vendexRespondedAt
                    vendexNewFieldValue
                    oldRecordValueJson
                    newRecordValueJson
                    vendexNewRecordValuesJson
                    recordActionType
                    vendorActionTypeCode
                    vendexActionTypeCode
                    vendexDeclineComment
                    vendexResponseTypeCode
                    vendexResponseTypeValue
                }
                isCertified
                valueMatch
            }
        }
    }
`;
export const getVsourceVendor = gql`
    query getVsourceVendor($id: ID!) {
        vsource {
            vendor(id: $id) {
                id
                brief_description
                vendor_id_code
                hierarchy_code
                assigned_analyst
                vendor_stage_value
                preview_brief_description
                preview_vendor_logo_filename
                products {
                    id
                    name
                    brief_description
                    preview_brief_description
                },
            }
        }
    }
`;


export const getProductRevisionsQuery = gql`
    query getProductRevisions($productRevisionsId: ID!) {
        vlink {
            productRevisions(id: $productRevisionsId) {
                key
                vendorId
                productId
                tableName
                fieldName
                itemTypeCode
                isReadonly
                itemId
                vsourceFieldValue
                vsourceRecordValueJson
                latestRevision {
                    revisionId
                    oldFieldValue
                    newFieldValue
                    vendorSubmittedAt
                    vendexRespondedAt
                    oldRecordValueJson
                    newRecordValueJson
                    recordActionType
                    vendorActionTypeCode
                    vendexActionTypeCode
                    vendexResponseTypeCode
                    vendexDeclineComment
                    vendexResponseTypeValue
                }
                submittedRevision {
                    revisionId
                    oldFieldValue
                    newFieldValue
                    vendorSubmittedAt
                    vendexRespondedAt
                    vendexNewFieldValue
                    oldRecordValueJson
                    newRecordValueJson
                    vendexNewRecordValuesJson
                    vendorActionTypeCode
                    vendexActionTypeCode
                    vendexDeclineComment
                    vendexResponseTypeCode
                    vendexResponseTypeValue
                }
                isCertified
                valueMatch
            }
        }
    }
`;

export const getProductTags = gql`
    query getProductTags($productTagsId: ID!, $types: [String]) {
        vsource {
            productTags(id: $productTagsId, types: $types) {
                type
                tags {
                    id
                    label
                }
            }
        }
    }
`;

export const getVsourceProduct = gql`
    query getVsourceProduct($id: ID!) {
        vsource {
            product(id: $id) {
                id
                vendor_product_id
                name
                vendor_specific_product_details
                brief_description
                preview_brief_description
                detailed_description
                url
                demo_url
                status
                status_code_original
                product_id_code
                change_time
                change_notes
                change_type
                change_type_original
                product_solution_type_code_array
                product_classificator_type_code_array
                vendor {
                    vendor_id_code
                    assigned_analyst
                    assigned_analyst_code
                    hierarchy_code
                    vendor_logo_filename
                    brief_description
                }
            }
        }
    }
`;

// VLink Premium files queries
export const VendorFilesListQuery = gql`
    query Query($vendor_id: ID!, $type: String!) {
        vlink {
            vendorFiles(vendor_id: $vendor_id, type: $type) {
                id
                original_name
                file_url
                title
                thumbnail_url
                description
            }
        }
    }
`;

export const ProductFilesListQuery = gql`
    query Query($product_id: ID!, $type: String!) {
        vlink {
            productFiles(product_id: $product_id, type: $type) {
                id
                original_name
                file_url
                title
                thumbnail_url
                description
            }
        }
    }
`;

export const VimeoVideoQuery = gql`
    query Query($id: ID!, $videoID: ID!, $type: String!) {
        vlink {
            vimeoVideo(id: $id, videoID: $videoID, type: $type) {
                videoID
                url
                title
                description
                status
            }
        }
    }
`;

export const getProductVidsv = gql`
    query getProductVidsv($productVidsvId: ID!) {
        vsource {
            productVidsv(id: $productVidsvId) {
                id
                productId
                name
                productGeographyTypeCodeArray {
                    id
                    label
                }
                productSolutionTypeCodeArray {
                    id
                    label
                }
                productClassificatorTypeCodeArray {
                    id
                    label
                }
            }
        }
    }
`;

export const getVlinkNotifications = gql`
    query Query($userNotificationsId: ID, $type: VendorOrProduct) {
        vlink {
            userNotifications(id: $userNotificationsId, type: $type) {
                id
                vendor_id
                product_id
                notification_source_type
                source_id
                notification_audience_type
                title
                description
                alert_timestamp
                created_by
                created_at
                updated_by
                updated_at
            }
        }
    }
`;

export const getNotificationsQuery = gql`
    query Query {
        vlink {
            notifications {
                product {
                    id
                    name
                }
                vendor {
                    id
                    name
                }
                type
                title
                description
                alertSince
            }
        }
    }

`;


export const getAnalystData = gql`
    query AnalystQuery {
        vlink {
            analystData {
                email
                assignedVendors {
                    id
                    full_legal_name
                    vendor_id_code
                }
            }
        }
    }
`;

export const getEventsAndArticles = gql`
    query GetEventsAndArticlesQuery {
        general {
            industryEvents {
                id
                eventName
                eventDate
                organizerName
                url
            }
            articles {
                id
                title
                previewText
                url
                thumbnailUrl
                featuredImageUrl
                isFeatured
                articleDate
                createdAt
                createdBy
                updatedAt
                updatedBy
            }
        }
    }
`

export const GET_DOCUMENT_DATA = gql`
    query Document($key: String!) {
        vkey {
            document(key: $key) {
                documentData {
                    chunk
                }
            }
        }
    }
`;