import API, { graphqlOperation } from "@aws-amplify/api";
import { updateAgreement as _updateAgreement } from "../../graphql/customMutations";
import synchronizeAgreementDocuments from "./synchronizeAgreementDocuments";
import userEmail from "../../utils/userEmail";

const updateAgreement = async (updatedAgreement, { dispatch, user, activeEntity }) => {
    dispatch({ type: "UPDATE_AGREEMENT" });

    const { vendorId, vendorName, masterProduct, id, docs, documentVendorName, vendorVSourceId } = updatedAgreement;

    await API.graphql(
        graphqlOperation(_updateAgreement, {
            input: {
                id,
                vendorId,
                /* vendorName, */
                updatedBy: userEmail(user),
                masterProduct,
                documentVendorName,
                vendorVSourceId,
            },
        })
    );

    await synchronizeAgreementDocuments(id, docs, activeEntity);

    dispatch({ type: "AGREEMENT_UPDATED" });
};

export default updateAgreement;
