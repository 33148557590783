import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { AuthContext } from "../context/AuthContext";
import PdfViewerComponent from "../components/PdfViewer";

const Wrapper = styled.div`
    position: relative;
    display: flex;

    flex-direction: row;
    flex: 2;
    height: 1200px;
    width: 100%;

    overflow-y: hidden;
    align-items: center;
`;

const PdfViewer = ({ history, match }) => {
    const {
        fetchDoc,
        context: { activeEntity },
    } = useContext(AuthContext);

    const [pdfLink, setPdfLink] = useState(null);

    useEffect(() => {
        fetchDoc({ key: `vkey/contracts/${activeEntity.groupName}/${match.params.id}`})
            .then((downloadedPdf) => {
                setPdfLink(downloadedPdf);
            })
            .catch((err) => {
                console.error("err with doc fetch ", err);
            });
    }, [pdfLink]);

    const goBack = useCallback(() => {
        history.goBack();
    }, []);

    return (
        <Wrapper>
            <PdfViewerComponent
                backButtonClick={goBack}
                pdfLinks={[pdfLink]}
                documentTitles={[match.params.id]}
            />
        </Wrapper>
    );
};

export default PdfViewer;
