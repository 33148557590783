import React, { useCallback, useContext, useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import { FormSection } from "../FormSection";
import { ErrorMessage } from "../ErrorMessage";
import { ButtonWrapper } from "../VendBtn";
import * as PropTypes from "prop-types";
import { AuthContext } from "../../context/AuthContext";
import { useFormInput } from "../../hooks/useFormInput";
import { withRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { getUserRegistryData } from "../../utils/user-registry-utils";

export const SignInForm = withRouter(({ onSubmit, history }) => {
    const [loading, setLoading] = useState(false);

    const { context, dispatch, signIn, checkEntityAccess, isVKeyAdminUser, isVSourceAdminUser } = useContext(
        AuthContext,
    );
    const email = useFormInput("");
    const password = useFormInput("");

    const handleSignIn = useCallback(
        (e) => {
            e.preventDefault();
            setLoading(true);
            signIn({ email: email.value, password: password.value }).then((user) => {
                dispatch({ type: "SIGN_IN", user });

                if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
                    history.push("/login/new-pass");
                    return;
                }

                if (user && user.challengeName && user.challengeName === "SOFTWARE_TOKEN_MFA") {
                    history.push("/login/verify-token");
                    return;
                }

                Sentry.setUser({ email: user?.attributes?.email || "unknown" });
                checkEntityAccess(user).then(() => {
                    // TODO: VDX-29 Enable terms again
                    /*if (!user?.attributes['custom:terms']) {
                        history.push("/accept-terms");
                    } else*/
                    if (isVKeyAdminUser(user) || isVSourceAdminUser(user)) {
                        history.push("/dashboard");
                    } else {
                        history.push("/home");
                    }
                });
            }).catch((err) => {
                dispatch({ type: "SIGN_IN_FAILURE", message: err.message });
                setLoading(false);
            });
        },
        [email.value, password.value],
    );

    return (
        <form onSubmit={handleSignIn}>
            <FormSection style={{ marginTop: 40 }}>
                <Input placeholder="Username">
                    <input id={"sign-in-email"} name={"Email"} data-cy={"sign-in-email"} type={"text"} {...email} />
                </Input>
                <Input icon placeholder="Password">
                    <Icon name="hide" />
                    <input
                        id={"sign-in-pass"}
                        name={"password"}
                        data-cy={"sign-in-pass"}
                        type={"password"}
                        {...password}
                    />
                </Input>
            </FormSection>

            <FormSection>
                {context.signInError !== "" ? <ErrorMessage>{context.signInError}</ErrorMessage> : null}
            </FormSection>

            <FormSection style={{ marginTop: 40 }}>
                <ButtonWrapper data-cy="sign-btn" type={"submit"}>
                    {!loading ? "Sign in" : "Loading..."}
                </ButtonWrapper>
            </FormSection>
        </form>
    );
});

SignInForm.propTypes = {
    onSubmit: PropTypes.func,
    email: PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.any }),
    password: PropTypes.shape({ onChange: PropTypes.func, value: PropTypes.any }),
    authorized: PropTypes.bool,
};
