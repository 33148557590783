import React from "react";
import styled from "styled-components";
import { Modal } from "semantic-ui-react";
import { WarningButtonPanel } from "./WarningButtonPanel";
import { ButtonWrapper } from "./VendBtn";

export const ModalTextLargeCenter = styled.div`
    text-align: center;
    color: #fff;
    padding: 0 3rem;
    h1 {
        color: inherit;
        margin-bottom: 3rem;
        font-size: 24px;
    }
    p {
        font-size: 18px;
    }
`;

export const ModalContentWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const LargeConfirmationModal = ({
    show,
    onClose,
    onSubmit,
    onDiscard,
    title,
    description,
}) => (
    <Modal open={show} basic onClose={onClose} size="small">
        <Modal.Content>
            <ModalContentWrapper>
                <ModalTextLargeCenter>
                    <h1>{title}</h1>
                    <p>{description}</p>
                </ModalTextLargeCenter>
                <WarningButtonPanel>
                    <ButtonWrapper negative-halo onClick={onDiscard}>
                        No
                    </ButtonWrapper>
                    <ButtonWrapper onClick={onSubmit}>Yes</ButtonWrapper>
                </WarningButtonPanel>
            </ModalContentWrapper>
        </Modal.Content>
    </Modal>
);
