import { unionBy } from "lodash";

const emptyAgreement = {
    documents: {
        items: [],
    },
};

export default (draft, action) => {
    if (!action || typeof action !== "object") throw new Error("action must be an object");
    if (!action.type) throw new Error("action must have a type property");
    if (typeof action.type !== "string") throw new Error("action.type must be a string");

    switch (action.type) {
        case "CREATE_AGREEMENT":
            draft.isLoading = true;
            break;
        case "AGREEMENT_CREATED":
            draft.isLoading = false;
            draft.agreements = unionBy(draft.agreements, [action.agreement], "id");
            break;
        case "UPDATE_AGREEMENT":
            draft.isLoading = true;
            break;
        case "AGREEMENT_UPDATED":
            draft.isLoading = false;
            break;
        case "SELECT_AGREEMENT_FOR_DELETION":
            draft.agreementDeleteCheck = true;
            draft.agreementToDelete = action.agreementToDelete;
            break;
        // case 'DELETE_AGREEMENT':
        case "DESELECT_AGREEMENT_FOR_DELETION":
            draft.agreementDeleteCheck = false;
            draft.agreementToDelete = {};
            break;
        case "AGREEMENT_DELETED":
            draft.agreementDeleteCheck = false;
            draft.agreementToDelete = {};
            draft.agreementDeleted = action.agreementDeleted;
            break;
        case "CLEAR_AGREEMENT_DELETED":
            draft.agreementDeleted = {};
            break;
        case "FETCH_AGREEMENTS":
            draft.isLoading = true;
            break;
        case "FETCH_SUMMARY_LIST":
            draft.isLoading = true;
            break;
        case "SUMMARY_LIST_FETCHED":
            draft.isLoading = false;
            draft.summaryList = action.summaryList;
            break;
        case "META_CATEGORIES_FETCHED":
            // draft.isLoading = false;
            draft.categories = action.categories;
            break;
        case "AGREEMENTS_FETCHED":
            draft.isLoading = false;
            draft.agreements = action.agreements;
            break;
        case "FETCH_AGREEMENT_META":
            draft.isLoading = true;
            break;
        case "AGREEMENT_META_FETCHED":
            draft.isLoading = false;
            draft.selectedMeta = action.agreementMeta;
            break;
        case "SELECT_AGREEMENT":
            draft.isLoading = false;
            draft.selectedAgreement = action.agreement;
            draft.selectedMeta = action.agreement.meta;
            break;
        case "SELECT_VENDOR":
            draft.vendorName = action.vendorName;
            break;
        case "CLEAR_SELECTED_AGREEMENT":
            draft.isLoading = true;
            draft.selectedAgreement = emptyAgreement;
            draft.selectedMeta = {};
            break;
        case "UPDATE_AGREEMENT_META":
            draft.selectedMeta = action.meta;
            break;
        default:
            break;
    }
};
