// Search and return new tree of of items
// where leafs contain passed value
export const _searchLeavesInTree = (value, tree) => {
    let foundNodes = [];
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if (!item.children || item.children.length === 0) {
            if (item.value.toLowerCase().trim().includes(value)) {
                foundNodes.push({...item, children: [], hasChildren: item.children.length > 0})
            } 
        } else {
            let foundInChildren = _searchLeavesInTree(value, item.children);
            if (foundInChildren && foundInChildren.length) {
                foundNodes.push({
                    ...item,
                    children: [
                        ...foundInChildren
                    ],
                    hasChildren: true
                })
            }
        }
    }
    return foundNodes;
};