import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReviewStars from '../ReviewStars';
import { formatToTimeZone } from 'date-fns-timezone';

const ItemWrapper = styled.div`
    font-size: 16px;
    color: #787885;

    .user {
        display: flex;
        align-items: center;

        .avatar {
            width: 40px;
        }

        i {
            margin: 0;
            font-size: 30px;
        }

        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            vertical-align: middle;
        }
    }

    .headling {
        display: flex;
        margin: 10px 0 0 40px;
        font-weight: 600;

        >div {
            color: #FFA800;
            width: 120px;
            
            .icon {
                font-size: 15px;
            }
        }
        >span {
            color: #000;
        }
    }

    .date {
        margin: 10px 0 0 40px;
    }

    .comment {
        margin: 15px 0 0 40px;
    }
`;

const ReviewListItem = ({ user_name, headline_text, comment_text, rating, updated_at, avatar }) => {
    return (
        <ItemWrapper>
            <div className="user">
                <div className="avatar">
                    {avatar
                        ? <img src={avatar} alt={user_name + ' ' + avatar} />
                        : <i className="user circle icon"></i>
                    }
                </div>
                <span>{user_name}</span>
            </div>
            <div className="headling">
                {rating ? <ReviewStars value={rating} /> : <div className="no-rating">No Rating</div>}
                <span>{headline_text}</span>
            </div>
            <div className="date">written {formatToTimeZone(new Date(updated_at), "MMMM, YYYY", { timeZone: "GMT" })}</div>
            <div className="comment">{comment_text}</div>
        </ItemWrapper>
    )
}

ReviewListItem.propTypes = {
    user_name: PropTypes.string.isRequired,
    headline_text: PropTypes.string.isRequired,
    comment_text: PropTypes.string.isRequired,
    rating: PropTypes.number,
    updated_at: PropTypes.string.isRequired
};

export default ReviewListItem;
