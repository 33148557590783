import React from "react";

const DownloadPauseIcon = props => (
    <svg width={8} height={8} {...props}>
        <path
            d="M1 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V1a1 1 0 0 1 1-1z"
            fill="#64ACFF"
            fillRule="evenodd"
        />
    </svg>
);

export default DownloadPauseIcon;

