// receives codes of leaves and returns codes of all parents
// returns unique list
export const _searchParentClassifierCodes = (codes, classifiers) => {
    let parentCodes = [];

    function findParent(classifier) {
        return classifiers.find(el => el.code === classifier.parent_classifier_code);
    }

    codes.forEach(code => {
        let currentClassifier = classifiers.find(el => el.code === code);
        if(currentClassifier) {
            let parent = findParent(currentClassifier);
            while(parent && !parentCodes.includes(parent.code)) {
                parentCodes.push(parent.code);
                parent = findParent(parent);
            }
        }
    });

    return parentCodes;
};