import React, { useContext } from "react";
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from "@apollo/client/link/error";
import { createUploadLink } from "apollo-upload-client";

import { AuthContext } from "../context/AuthContext";

const ApolloProviderWrapper = (props) => {
    const { context, dispatch, getCurrentJWT, logOut } = useContext(AuthContext);

    const apiUrl = process?.env?.REACT_APP_VPORT_API_URL;

    if (!apiUrl) {
        console.error(
            `Error getting API Url (${process.env.REACT_APP_BUILD_ENV}): `,
            process?.env?.REACT_APP_VPORT_API_URL
        );
    }

    const httpLink = createUploadLink({
        uri: apiUrl,
    });

    // Add Authentication header and JWT
    const authMiddleware = setContext(async (operation) =>{
        const JWT = await getCurrentJWT(context.user);
        return {
          headers: {
            Authentication: `Bearer ${JWT}` || null,
          },
        };
    });

    // Handle Apollo GraphQL errors
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path, extensions }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            
            // Hide the additional descriptions in prod
            if(process.env.REACT_APP_BUILD_ENV !== 'prod'){
                console.log(`[GraphQL error]: Extensions: `, extensions);
            }
            
            // If the server responds with AuthenticationError log the user out
            if(extensions?.code === 'UNAUTHENTICATED'){
                logOut();
                dispatch({ type: "LOGOUT" });  
            }
        });
        console.log(`[Network error]: ${networkError}`);
        if (networkError) {
            if (networkError === 'No current user') {
                logOut();
                dispatch({ type: "LOGOUT" });
                throw networkError;
            }
        }
      });

    const client = new ApolloClient({
        link: from([errorLink, authMiddleware, httpLink]),
        cache: new InMemoryCache(),
    });

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
