import React, { useState, useContext } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import Select from "react-select";
import styled, { ThemeContext } from "styled-components";

import { sendVendorUpdateRequestEmail } from "../../graphql/mutations";
import { useAlerts } from "../../hooks/useAlerts";
import { SendEmailPopup } from "../../components/SendEmailPopup";
import { _dropDownStyles } from "../../components/Tables";

import { AuthContext } from "../../context/AuthContext";

const RequestVendorUpdateModal = ({ vendor, onClose }) => {
    const [requestUpdateInput, setRequestUpdateInput] = useState("");
    const [requestUpdateLoading, setRequestUpdateLoading] = useState(false);
    const [requestModalCategories, setRequestModalCategories] = useState([]);

    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const {
        context: { user },
    } = useContext(AuthContext);

    const updateRequestUpdateTextField = (text) => {
        if (text.length < 200) {
            setRequestUpdateInput(text);
        } else if (text.length > 200) {
            setRequestUpdateInput(text.substring(0, 199));
        }
    };

    const closeRequestUpdateModal = () => {
        setRequestUpdateInput("");
        onClose();
        setRequestModalCategories([]);
    };

    const sendRequestUpdateEmail = async () => {
        if (!user?.attributes?.email) {
            newErrorAlert("Missing user email, please refresh the page and try again!");
            return;
        }

        try {
            setRequestUpdateLoading(true);
            const { data } = await API.graphql(
                graphqlOperation(sendVendorUpdateRequestEmail, {
                    user_email: user?.attributes?.email,
                    vendor_name: vendor?.full_legal_name || window.location.href,
                    categories: requestModalCategories.map((el) => el.label),
                    content: requestUpdateInput,
                    url: window.location.href,
                })
            ).catch((e) => {
                console.error("GraphQL fetch error:", e);
                throw new Error(e.errors[0].message);
            });

            if (data && data.sendVendorUpdateRequestEmail) {
                newSuccessAlert(`Request sent successfully`);
                closeRequestUpdateModal();
            } else newErrorAlert("Failed to send request, please try again later!");
        } catch (e) {
            console.log("Send request email errors: ", e);
            newErrorAlert(e.message);
        } finally {
            setRequestUpdateLoading(false);
        }
    };

    return (
        <SendEmailPopup
            show={true}
            onCancel={closeRequestUpdateModal}
            title={"Vendor Update Request"}
            onSubmit={sendRequestUpdateEmail}
            submitDisabled={requestUpdateInput.length === 0}
            loading={requestUpdateLoading}
        >
            <RequestVendorUpdateContent
                email={user?.attributes?.email || ""}
                vendorName={vendor?.full_legal_name || ""}
                inputValue={requestUpdateInput}
                onInputChange={(e) => updateRequestUpdateTextField(e.target.value)}
                onCategoryChange={setRequestModalCategories}
                selectedCategories={requestModalCategories}
            />
        </SendEmailPopup>
    );
};

const UpdateModalBody = styled.div`
    font-size: 16px;
    .title {
        font-weight: 700;
    }

    p {
        margin: 0 0 1em;
    }

    textarea {
        width: 100%;
        height: 150px;
        padding: 0.5rem;
    }

    .categories {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        & > div {
            width: 100%;
            margin-left: 5px;
        }
    }
`;

export const RequestVendorUpdateContent = ({
    email,
    vendorName,
    inputValue,
    onInputChange,
    onCategoryChange,
    selectedCategories,
}) => {
    const themeContext = useContext(ThemeContext);
    const dropDownStyles = _dropDownStyles(themeContext);

    const categoryOptions = [
        { label: "Vendor Overview", value: "overview" },
        { label: "Products", value: "products" },
        { label: "Officers", value: "officers" },
        { label: "Locations & Support", value: "locations" },
        { label: "Product Detail page", value: "product_detail" },
    ];

    return (
        <UpdateModalBody>
            <p>
                <span className="title">UserName: </span>
                {email}
            </p>
            <p>
                <span className="title">Vendor: </span>
                {vendorName}
            </p>
            <div className="categories">
                <span className="title">Category: </span>
                <Select
                    options={categoryOptions}
                    value={selectedCategories}
                    onChange={(e) => onCategoryChange(e ?? { value: null })}
                    defaultValue={null}
                    styles={dropDownStyles}
                    isClearable
                    key={new Date().getTime().toString()}
                    isMulti
                />
            </div>
            <p>Please tell us what information you would like updated (200 characters max)</p>
            <textarea
                value={inputValue}
                onChange={onInputChange}
                placeholder="Type message here. Send button is disabled until a message is entered."
            />
        </UpdateModalBody>
    );
};

export default RequestVendorUpdateModal;
