import React, { useCallback, useState, useContext, useEffect, useRef, createRef } from "react";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import { AuthContext } from "../../context/AuthContext";
import { getEventsAndArticles } from "../../graphql/customQueries";
import { removeArticle, setArticleAsFeatured, removeIndustryEvent } from "../../graphql/customMutations";
import { useHistory } from "react-router-dom";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";

const ListArticles = () => {
    const Container = styled.div`
        display: flex;
        padding: 32px;
        flex-direction: column;
        width: 100vw;
        min-width: 400px;
    `;

    const [deleteEntry, setDeleteEntry] = useState({ type: undefined, id: undefined });
    let { loading, error, data } = useQuery(getEventsAndArticles);

    const [onRemoveArticle] = useMutation(removeArticle);
    const [onSetArticleAsFeatured] = useMutation(setArticleAsFeatured);
    const sendRemoveArticle = async (id) => {
        await onRemoveArticle({
            variables: {
                removeArticleId: id,
            }
        });
        window.location.reload(); // TODO: Fix this - but my react is RUSTY
    };
    const [onRemoveIndustryEvent] = useMutation(removeIndustryEvent);
    const sendRemoveIndustryEvent = async (id) => {
        await onRemoveIndustryEvent({
            variables: {
                removeIndustryEventId: id,
            }
        });
        window.location.reload(); // TODO: Fix this - but my react is RUSTY
    };
    const sendSetArticleAsFeatured = async (id, thumbnailUrl) => {
        await onSetArticleAsFeatured({
            variables: {
                setArticleAsFeaturedId: id,
                featuredImageUrl: thumbnailUrl
            }
        });
        window.location.reload(); // TODO: Fix this - but my react is RUSTY
    };

    const renderArticle = (article) => {
        const { isFeatured, id, title, createdAt, url, thumbnailUrl, featuredImageUrl } = article;
        return (

            <div style={{ display: 'flex', flexDirection: 'row', padding: '6px 0' }} key={id}>
                {thumbnailUrl ? <div style={{width: '60px'}}><img src={thumbnailUrl} style={{ maxWidth: '60px', maxHeight: '60px',}}/></div> : <div style={{width: '60px'}}/>}
                <div style={{ display: 'flex', flexDirection: 'column', padding: '0 12px' }} key={id}>
                    <div style={{ fontWeight: 'bold'}}>{title ? title : (<div style={{fontStyle: 'italic'}}>No title</div>)}</div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ paddingRight: '12px' }}><a href={url} target="_blank">Link</a></div>
                        <div style={{ paddingRight: '12px' }}>Created: {createdAt.slice(0, 10)}</div>
                        <div style={{ paddingRight: '12px' }}>Featured: {isFeatured ? '✅' : '❌'}</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4px'}}>
                        {featuredImageUrl ? (
                            <div style={{ marginRight: '12px', cursor: 'pointer' }} onClick={async () => await sendSetArticleAsFeatured(id, featuredImageUrl)}>Set as featured</div>
                        ) : (
                            <div style={{ marginRight: '12px', fontStyle: 'italic' }}>Need thumbnail to be set as featured</div>
                        )}
                        <div style={{ marginRight: '12px', cursor: 'pointer' }} onClick={() => setDeleteEntry({ type: 'article', id })}>Remove</div>
                    </div>
                </div>
            </div>
        );
    }

    const renderIndustryEvents = (ie) => {
        const { id, eventName, eventDate, organizerName, url } = ie
        return (
            <div style={{ display: 'flex', flexDirection: 'column', padding: '6px 12px 6px 72px' }} key={id}>
                <div style={{ fontWeight: 'bold'}}>{eventName ? eventName : (<div style={{fontStyle: 'italic'}}>No name</div>)}</div>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <div style={{ paddingRight: '12px' }}><a href={url} target="_blank">Link</a></div>
                    <div style={{ paddingRight: '12px' }}>Date: {eventDate}</div>
                    <div style={{ paddingRight: '12px' }}>Organizer: {organizerName}</div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '4px'}}>
                    <div style={{ marginRight: '12px', cursor: 'pointer' }} onClick={() => setDeleteEntry({ type: 'industryEvent', id })}>Remove</div>
                </div>
            </div>
        )
    }

    const articles = data && data.general && data.general.articles && data.general.articles.slice().sort((a, b) => (new Date(b.createdAt) - new Date(a.createdAt)));
    const industryEvents = data && data.general && data.general.industryEvents && data.general.industryEvents.slice().sort((a, b) => (new Date(b.eventDate) - new Date(a.eventDate)));
    const history = useHistory();
    return (
    <Container>
        <LargeConfirmationModal
            show={deleteEntry.id && deleteEntry.type}
            onClose={() => setDeleteEntry({})}
            onSubmit={async () => {
                if (deleteEntry.type === 'article') {
                    await sendRemoveArticle(deleteEntry.id);
                } else if (deleteEntry.type === 'industryEvent') {
                    await sendRemoveIndustryEvent(deleteEntry.id);
                }
                setDeleteEntry({});
            }}
            onDiscard={() => setDeleteEntry({})}
            title={deleteEntry.type === 'article' ? 'Delete article?' : 'Delete industry event?'}
            description="Once deleted, you will not be able to recover the item"
        />
        {loading ? 'Loading...': (
            <div>
                <div style={{ cursor: 'pointer'}} onClick={() => history.push('/admin-post-article')}>Post article or industry event</div>
                 <h2>Articles</h2>
                <div>{articles && articles.map(a => renderArticle(a))}</div>
                <h2>Industry Events</h2>
                <div>{industryEvents && industryEvents.map(ie => renderIndustryEvents(ie))}</div>
            </div>
        )}

    </Container>
    );
}

export default ListArticles;
