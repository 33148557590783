import { useContext } from "react";
import { Redirect } from "react-router-dom";
import { PageWrapper } from "../../components/PageWrapper";
import PremiumForm from "../../components/vlink/PremiumForm";
import RevisionForm from "../../components/vlink/RevisionForm";
import { AuthContext } from "../../context/AuthContext";
import { VendorFormsWrapper } from "./VendorDetails";
import ProfileHeader from "../../components/ProfileHeader";

export default function ProductDetailsPreview() {
    const {
        context: { user },
        isVLinkVendor,
    } = useContext(AuthContext);

    let context = isVLinkVendor(user) ? 'VENDOR_PREVIEW' : null;

    if(context == null) {
        return <Redirect to="/vlink/dashboard" />
    }


    return (
        <PageWrapper>
            <ProfileHeader context={context} level='PRODUCT' />
            <VendorFormsWrapper>
                <RevisionForm 
                    context={context}
                    level='PRODUCT'
                />
                <PremiumForm context={context} level='PRODUCT'/>
            </VendorFormsWrapper>
        </PageWrapper>
    )
}