import userEmail from "../../utils/userEmail";
import { isArray } from "lodash";
import API, { graphqlOperation } from "@aws-amplify/api";

const synchronizeDefinitionTerms = async (
    metaId,
    terms,
    createMutation,
    updateMutation,
    deleteMutation,
    { user, activeEntity }
) => {
    if (!isArray(terms)) {
        return { id: null, value: null };
    }

    await terms.reduce(async (prev, term) => {
        await prev;
        let operation = createMutation;
        let input = {
            id: term.id,
            term: term.term,
            definition: term.definition,
            agreementMetaDefinitionsId: metaId,
            createdBy: userEmail(user),
            groupsCanAccess: activeEntity?.groupName,
        };

        if (term.id) {
            if (term.removed) {
                operation = deleteMutation;
                input = {
                    id: input.id,
                };
            } else {
                operation = updateMutation;
                delete input.createdBy;
                input.updatedBy = userEmail(user);
            }
        } else {
            if (term.removed) return null;
        }

        return await API.graphql(
            graphqlOperation(operation, {
                input,
            })
        );
    }, Promise.resolve());

    return { id: null, value: null };
};

export default synchronizeDefinitionTerms;
