import React, { useContext } from "react";
import styled from "styled-components/macro";
import { ThemeContext } from "styled-components";
import * as PropTypes from "prop-types";
import Select from "react-select";
import _get from "lodash/get";
import { _dropDownStyles } from "../../../../components/Tables";

const SelectBlockViewWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const SelectLabel = styled.div`
  color: #8E929D;
  font-size: 1.3rem;
  margin-right: 0.5rem;
`;

const SelectWrapper = styled.div`
  width: 80%;
`;

export const SelectBlockView = ({
    options = [],
    onChange = () => {},
    defaultValue,
    label,
}) => {
    const themeContext = useContext(ThemeContext);
    const dropDownStyles = _dropDownStyles(themeContext);
    const onSelectView = (e) => {
        const value = _get(e, 'value');
        onChange(value);
    }
    return <SelectBlockViewWrapper>
        {
            !!label && (
                <SelectLabel>
                    {label}
                </SelectLabel>
            )
        }
        {
            !!options && (
                <SelectWrapper>
                    <Select
                        options={options}
                        onChange={onSelectView}
                        defaultValue={defaultValue || options[0].value}
                        styles={dropDownStyles}
                        placeholder={defaultValue || options[0].label}
                    />
                </SelectWrapper>
            )
        }
    </SelectBlockViewWrapper>
};

SelectBlockView.propTypes = {
    options:  PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
    })),
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
};

export default SelectBlockView;
