import React, { useState } from "react";
import styled from "styled-components";

const TabsContainer = styled.div`
    display: flex;
`;

const Title = styled.span`
    position: relative;
    font-size: 16px;
    margin: 0.3em 0 !important;
    color: ${({ theme }) => theme.darkBlue};
    text-transform: ${(props) => (props.upper ? `uppercase` : `none`)};
`;

const Tab = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    background-color: ${(props) =>
        props.active ? props.theme.yellow1 : props.theme.yellow2};
    border-radius: 0px 24px 0px 0px;
    margin-right: 0px;
    padding: 0.5rem 1.5rem;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: ${(props) => (props.active ? "bold" : "normal")}

    &:hover {
        background-color: ${({ theme }) => theme.yellow1};
    }
`;

/*
    Props:
    [{
        title,
        onClick,
        disabled
    },
    ...]
*/
const Tabs = ({ tabs }) => {
    const [active, setActive] = useState(0);

    return (
        <TabsContainer>
            {tabs.map((el, i) => {
                return (
                    <TabComponent
                        title={el.title}
                        onClick={() => {
                            if (!el.disabled) el.onClick();
                        }}
                        index={i}
                        key={`tab-${i}`}
                        active={el.active}
                        disabled={el.disabled}
                    />
                );
            })}
        </TabsContainer>
    );
};

const TabComponent = ({ title, onClick, active, disabled }) => (
    <Tab onClick={onClick} active={active} disabled={disabled}>
        <Title>{title}</Title>
    </Tab>
);

export default Tabs;
