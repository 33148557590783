import React from "react";
import styled from "styled-components/macro";
import { Field, FieldArray, withFormik, ErrorMessage as FormikErrorMessage } from "formik";
import _get from "lodash/get";
import _startCase from "lodash/startCase";
import _lowerCase from "lodash/lowerCase"
import * as Yup from "yup";

import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from "react-accessible-accordion";

import { StyledScrollbar } from "../StyledScrollbar";
import { ButtonWrapper } from "../VendBtn";
import { FieldSection } from "./FieldSection";
import { FieldStyled } from "./FieldStyled";
import { NumberFormatStyled } from "./NumberFormatStyled";
import VendNumberFormat from "./VendNumberFormat";
import { LabelStyled } from "./LabelStyled";
import { ToggleStyled } from "./ToggleStyled";
import { FormWrapper } from "./FormWrapper";
import { ErrorMessage } from "../ErrorMessage";
import { VendDropDown } from "./VendDropDown";
import VendAsyncDropDown from "./VendAsyncDropDown";

import { ReactComponent as ErrorImage } from "../../images/error.svg";

import "react-accessible-accordion/dist/fancy-example.css";
import "../../styles/react-accessible-accordion-dist/fancy-example.css";
import theme from "../../styles/theme";


import metaConfig from "../../config/metaConfig.json";

const pluralize = require("pluralize");

const fieldsById = Object.keys(metaConfig).reduce((memo, sectionId) => {
    const section = metaConfig[sectionId];

    section.forEach((field) => (memo[field.id] = field));

    return memo;
}, {});

const getYupSchemaForField = (field) => {
    const type = _get(field, "type", "text");

    switch (type) {
        case "timestamp":
            return Yup.date();
        case "multi":
            return Yup.string().typeError("No Option Selected");
        case "date":
            return Yup.date();
        case "boolean":
            return Yup.boolean();
        case "url":
            return Yup.string();
        case "phone-number":
            return Yup.string();
        case "email":
            return Yup.string();
        case "number":
            return Yup.number().typeError("Must be a number");
        case "currency":
            return Yup.number().typeError("Must be a number");
        case "alphanumeric":
            return Yup.string().typeError("Required field");
        case "text":
            return Yup.string().typeError("Required field");
        case "id":
            return Yup.string();
        case "model":
            if (field.isList) {
                return Yup.object({
                    items: Yup.array().of(
                        Yup.object(
                            field.values.reduce((schema, subField) => {
                                const name = _get(subField, "id");
                                const readOnly = _get(field, "readOnly", false);
                                const subType = _get(subField, "type", "text");

                                if (subType === "id" || readOnly) return schema;

                                schema[name] = getYupRequiredSchema(subField);

                                return schema;
                            }, {})
                        )
                    ),
                });
            }

            if (field.remote) {
                return Yup.object({
                    id: Yup.string().typeError("Selection Required").required("Selection Required"),
                });
            }

            return Yup.object(
                field.values.reduce((schema, subField) => {
                    const name = _get(subField, "id");
                    const readOnly = _get(field, "readOnly", false);
                    const subType = _get(subField, "type", "text");

                    if (subType === "id" || readOnly) return schema;

                    schema[name] = getYupRequiredSchema(subField);

                    return schema;
                }, {})
            );
        default:
            return Yup.mixed();
    }
};

const getRequiredErrorMessage = (field) => {
    return field.type === "multi" ? "Selection Required" : "Required";
};

const getYupRequiredSchema = (field) => {
    const isRequired = _get(field, "required", false);

    return isRequired
        ? getYupSchemaForField(field).when("removed", {
              is: true,
              then: (field) => field.nullable(),
              otherwise: (yumField) => yumField.required(getRequiredErrorMessage(field)),
          })
        : getYupSchemaForField(field).nullable();
};

const yupSchema = Yup.object(
    Object.keys(metaConfig).reduce((memo, sectionKey) => {
        const sectionFields = metaConfig[sectionKey];
        sectionFields.forEach((field) => {
            const fieldSchema = getYupRequiredSchema(field);
            const name = _get(field, "id");

            memo[name] = fieldSchema;
        });
        return memo;
    }, {})
);

const FormSectionLabel = styled.div`
    position: relative;
    display: block;

    font-size: 16px;
    font-weight: lighter;

    color: #595973;
    text-align: left;

    //margin: 20px 0;

    padding: 20px 16px 20px 16px;
`;

const BottomFormBar = styled.div`
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    //height: 50px;

    padding: 6px;

    border-top: 1px solid #f1f1f1;

    bottom: 0;
    right: 8px;
    left: 8px;

    background: white;
`;

const EntrySection = styled.div`
    position: relative;
    display: block;

    flex: 2;
    width: 100%;

    margin-bottom: 50px;
`;

const enumToMulti = (str) => _startCase(_lowerCase(str));

const ListItemContainer = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    width: 100%;
    flex-direction: column;

    & > button {
        width: 100%;
    }
`;

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex: 2;
    width: 100%;
`;

const DefinitionTermContainer = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    padding: 0 4px 16px 0;
    margin-bottom: 16px;
    border-bottom: 1px solid #f1f1f1;

    & > * {
        margin-bottom: 16px;
    }
`;

const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
`;

const MetaFormField = ({ field, values: initialValues, errors, agreement, group }) => {
    const type = _get(field, "type", "text");
    const name = _get(field, "id");
    const initialValue = _get(initialValues, name);

    switch (type) {
        case "timestamp":
            return <FieldStyled type={type} name={name} />;
        case "multi":
            const values = _get(field, "values");
            const options = values.map((value) => {
                return {
                    key: value,
                    value,
                    text: _get(field, `valueAliases.${value}`, enumToMulti(value)),
                };
            });
            return <Field name={name} options={options} component={VendDropDown} value={initialValue} />;
        case "date":
            return <FieldStyled type={type} name={name} />;
        case "boolean":
            return <FieldStyled type={type} name={name} component={ToggleStyled} value={initialValue} />;
        case "url":
            return <FieldStyled type={type} name={name} />;
        case "phone-number":
            return <NumberFormatStyled format="+1 (###) ###-####" mask="_" name={name} />;
        case "email":
            return <FieldStyled type={type} name={name} />;
        case "number":
            return <FieldStyled component={VendNumberFormat} name={name} value={initialValue} />;
        case "currency":
            const relatedField = fieldsById[field.relatedField];
            const relatedValue = _get(initialValues, `${field.relatedField}`, "");
            const prefix = _get(relatedField, `valueAliases.${relatedValue}`, "");

            return <FieldStyled prefix={prefix} component={VendNumberFormat} name={name} value={initialValue} />;
        case "alphanumeric":
            if (field.isProductList) {
                // Product & pricing group product field
                return (
                    <>
                        <Field
                            name={name}
                            component={VendAsyncDropDown}
                            value={initialValue}
                            agreement={agreement}
                            group={group}
                        />
                        <ErrorMessage>{_get(errors, name, "")}</ErrorMessage>
                    </>
                );
            }
            return <FieldStyled type={type} name={name} />;
        case "text":
            return <FieldStyled type={type} name={name} size={20 * 8} component={"textarea"} />;
        case "id":
            return <FieldStyled type={type} name={name} disabled />;
        case "model":
            if (field.isList) {
                const listTypeLabel = pluralize.singular(_get(field, "label", ""));
                return (
                    <FieldArray
                        name={`${field.id}.items`}
                        render={(arrayHelpers) => (
                            <ListItemContainer>
                                {initialValues[field.id] &&
                                    Array.isArray(initialValues[field.id].items) &&
                                    initialValues[field.id].items.map((term, index) =>
                                        term.removed ? null : (
                                            <ListItem key={index}>
                                                <DefinitionTermContainer>
                                                    {field.values.map((subField, subIndex) => {
                                                        const id = `${field.id}.items[${index}].${subField.id}`;
                                                        const type = _get(subField, "type", "text");
                                                        const readOnly = _get(subField, "readOnly", false);
                                                        const deprecated = _get(subField, "deprecated", false);
                                                        if (type === "id" || readOnly || deprecated) return null;

                                                        const listField = {
                                                            ...subField,
                                                            id,
                                                        };

                                                        return (
                                                            <FieldSection key={subIndex}>
                                                                <LabelStyled>
                                                                    {listField.required
                                                                        ? `${listField.label} *`
                                                                        : listField.label}
                                                                </LabelStyled>
                                                                <MetaFormField
                                                                    field={listField}
                                                                    values={initialValues}
                                                                    errors={errors}
                                                                    agreement={agreement}
                                                                    group={term}
                                                                />
                                                                {listField.remote ? (
                                                                    <ErrorMessage>
                                                                        {_get(errors, `${id}.id`, "")}
                                                                    </ErrorMessage>
                                                                ) : (
                                                                    <ErrorMessage>{_get(errors, id, "")}</ErrorMessage>
                                                                )}
                                                            </FieldSection>
                                                        );
                                                    })}
                                                </DefinitionTermContainer>
                                                <button
                                                    type="button"
                                                    data-cy={`${field.id}.items[${index}]-remove-${listTypeLabel}-btn`}
                                                    onClick={() => {
                                                        arrayHelpers.form.setFieldValue(
                                                            `${field.id}.items[${index}].removed`,
                                                            true
                                                        );
                                                        // Set product.id to -1 to prevent validation errors
                                                        arrayHelpers.form.setFieldValue(
                                                            `${field.id}.items[${index}].product.id`,
                                                            -1
                                                        );
                                                    }}
                                                >
                                                    x
                                                </button>
                                            </ListItem>
                                        )
                                    )}
                                <button
                                    type="button"
                                    data-cy={`add-${listTypeLabel}-btn`}
                                    onClick={() => arrayHelpers.push({})}
                                >
                                    Add {listTypeLabel}
                                </button>
                            </ListItemContainer>
                        )}
                    />
                );
            }

            /* if (field.remote) {
                // if (field.deprecated) return null; // Don't display the old link to the VKey product
                const remoteIdName = `${name}`;
                console.log("Initial values:", initialValues);
                return (
                    <Field
                        name={remoteIdName}
                        component={VendAsyncDropDown}
                        value={_get(initialValues, remoteIdName)}
                        agreement={agreement}
                    />
                );
            } */

            return (
                <>
                    {field.values.map((subField) => {
                        return renderSection(
                            {
                                ...subField,
                                id: `${field.id}.${subField.id}`,
                            },
                            initialValues,
                            errors,
                            agreement
                        );
                    })}
                </>
            );
        default:
            return <FieldStyled type={type} name={name} />;
    }
};

const renderSection = (field, values, errors, agreement) => {
    const id = _get(field, "id");
    const name = _get(field, "label");
    const type = _get(field, "type", "text");
    const readOnly = _get(field, "readOnly", false);

    if (id === "totalFees") {
        console.log("field", field);
    }

    if (type === "id" || readOnly) return null;

    return (
        <FieldSection key={id}>
            <LabelStyled>{field.required ? `* ${name}` : name}</LabelStyled>
            <MetaFormField field={field} values={values} errors={errors} agreement={agreement} />
            <ErrorMessage>
                <FormikErrorMessage name={name} />
            </ErrorMessage>
        </FieldSection>
    );
};

const getSectionErrorCount = (sectionKey, errors) => {
    return metaConfig[sectionKey].reduce((total, field) => {
        let newCount = 0;
        if (!errors[field.id]) {
            return total;
        }

        if (field.isList) {
            newCount = errors[field.id].items.reduce((count, item) => {
                if (!item) return count;
                return Object.keys(item).length + count;
            }, 0);
        } else {
            newCount = 1;
        }

        return total + newCount;
    }, 0);
};

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

const SectionErrorIndicator = styled.div`
    color: ${theme.error};
    padding-right: ${theme.smMetric(1)}px;
`;

const SectionErrorContainer = styled.div`
    font-size: 12px;
    color: ${theme.error};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const SectionLabel = ({ sectionKey, errorCount }) => {
    return (
        <LabelContainer>
            <div>{sectionKey}</div>
            <SectionErrorContainer>
                {errorCount > 0 ? (
                    <SectionErrorIndicator>
                        <ErrorImage />
                    </SectionErrorIndicator>
                ) : null}
                {errorCount > 0 ? <div>{errorCount}</div> : null}
            </SectionErrorContainer>
        </LabelContainer>
    );
};

const KeyMetaForm = ({ values, omittedType = ["id"], submitForm, saveError, handleExit, errors, agreement }) => {
    return (
        <FormContainer>
            <FormWrapper>
                <EntrySection>
                    <StyledScrollbar>
                        <Accordion data-cy={"accordion-comp"} allowZeroExpanded>
                            {Object.keys(metaConfig).map((sectionKey) => {
                                return (
                                    <AccordionItem data-cy={`accordion-item-${sectionKey}`} key={sectionKey}>
                                        <AccordionItemHeading>
                                            <AccordionItemButton>
                                                <FormSectionLabel>
                                                    <SectionLabel
                                                        sectionKey={sectionKey}
                                                        errorCount={getSectionErrorCount(sectionKey, errors)}
                                                    />
                                                </FormSectionLabel>
                                            </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                            {metaConfig[sectionKey]
                                                // Filter out the fields that should not be displayed in the form
                                                .filter((field) => {
                                                    const type = _get(field, "type", "text");
                                                    return !omittedType.includes(type) || field.deprecated;
                                                })
                                                .map((field) => {
                                                    return renderSection(field, values, errors, agreement);
                                                })}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </StyledScrollbar>
                </EntrySection>
            </FormWrapper>
            <BottomFormBar>
                <ButtonWrapper
                    onClick={() => {
                        handleExit(false);
                        submitForm();
                    }}
                    data-cy={"save-btn"}
                >
                    Save
                </ButtonWrapper>
                <ButtonWrapper
                    onClick={() => {
                        handleExit(true);
                        submitForm();
                    }}
                    data-cy={"save-exit-btn"}
                >
                    Save and Exit
                </ButtonWrapper>
                {saveError !== "" ? <ErrorMessage>{saveError}</ErrorMessage> : null}
            </BottomFormBar>
        </FormContainer>
    );
};

const convertEmptyStringsToNull = (values) => {
    // Make a copy of values object because the Formik object is immutable
    values = { ...values };

    Object.keys(values).forEach((key) => {
        const value = values[key];
        switch (typeof value) {
            case "string":
                if (value.length === 0) {
                    values[key] = null;
                }
                break;
            case "object":
                if (value) {
                    convertEmptyStringsToNull(value);
                }
                break;
            default:
                break;
        }
    });
};

export default withFormik({
    displayName: "KeyMetaForm",
    mapPropsToValues: (props) => {
        return Object.keys(props).reduce((memo, key) => {
            if (typeof props[key] === "function" || key === "saveError" || key === "agreement") {
                return memo;
            }

            memo[key] = props[key];

            return memo;
        }, {});
    },
    handleSubmit: (values, { props: { onUpdate } }) => {
        convertEmptyStringsToNull(values);
        if (onUpdate) {
            onUpdate(values);
        }
    },
    validationSchema: yupSchema,
})(KeyMetaForm);
