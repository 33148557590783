import _get from "lodash/get";
import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";

const deleteProduct = async (productToDelete, { dispatch }) => {
    const productId = _get(productToDelete, "id", null);

    const deleteAgreementResult = await API.graphql(
        graphqlOperation(mutations.deleteProduct, {
            input: {
                id: productId,
            },
        })
    );

    const productDeleted = _get(deleteAgreementResult, "data.deleteProduct", null);

    if (productDeleted) {
        dispatch({ type: "PRODUCT_DELETED", productDeleted });
        return productDeleted;
    }
};

export default deleteProduct;
