import API, { graphqlOperation } from "@aws-amplify/api";
import { createProduct } from "../../graphql/mutations";
import userEmail from "../../utils/userEmail";

export default async (productData, { dispatch, user }) => {
    dispatch("CREATING_PRODUCT");

    const product = await API.graphql(
        graphqlOperation(createProduct, {
            input: {
                ...productData,
                createdBy: userEmail(user),
            },
        })
    );

    dispatch({ type: "PRODUCT_CREATED", product });
};
