import styled from "styled-components";
import {Field} from "formik";
import styledMap from "styled-map";

export const FieldStyled = styled(Field)`
    position: relative;
    display: block;
    background: ${styledMap`
        disabled: #E8E8F0;
        default: #FBFCFD;
    `};
    height: ${(props) => props.size ? `${props.size}px`  : `24px`};
    width: 100%;
    padding: 16px;
    border: 1px solid #E8E8F0;
    //background: #E8E8F0;
    //background: #FBFCFD;
    //border: none;
    border-radius: 3px;
`;