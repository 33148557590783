import React, { useState } from "react";
import styled from "styled-components";
import styledMap from "styled-map";
import { Input } from "semantic-ui-react";
import InfiniteScroll from "react-infinite-scroll-component";
import onClickOutside from "react-onclickoutside";

const DropdownWrapper = styled.div`
    width: 100%;
    position: relative;

    input {
        &:focus {
            border-radius: 4px 4px 0 0 !important;
            border-bottom: none !important;
            color: #fff;
        }
    }

    .infinite-scroll {
        position: absolute;
        width: 100%;
        z-index: 999;
        border: 1px solid #85b7d9;
        border-radius: 0 0 4px 4px;
        border-top: none;
        background: #fff;
        color: rgba(0, 0, 0, 0.8);
    }
`;

export const ListElement = styled.div`
    cursor: pointer;
    padding: 0.78571429rem 1.14285714rem !important;
    white-space: normal;
    z-index: 1000;
    text-align: ${styledMap`
        text-center: center;
        default: left;
    `};

    &.active {
        background: rgba(0, 0, 0, 0.03);
        color: rgba(0, 0, 0, 0.95);
    }

    &:hover,
    &.no-results {
        background: rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.95);
    }
    &.no-results {
        cursor: default;
    }
`;

/**
 *
 * @param data: {
 *                  id: string,
 *                  value: string,
 *                  onClick: function
 *              }
 * @returns
 */

const InfiniteDropdown = (props) => {
    const [showList, setShowList] = useState(false);

    InfiniteDropdown.handleClickOutside = () => setShowList(false);

    const Loader = ({ dataLength, inputValue }) => {
        // If the user is not searching
        if (dataLength !== 0 && inputValue === "") {
            return <ListElement text-center>Loading...</ListElement>;
        }
        return <ListElement text-center>No results</ListElement>;
    };

    const onInputFocus = () => {
        setShowList(true);

        // If the input is empty make a full search
        if (props.inputValue !== "") {
            console.log("Search on focus:", props.inputValue);
            props.onSearch(props.inputValue);
        }
    };

    return (
        <DropdownWrapper>
            <Input
                placeholder="Select"
                disabled={false}
                loading={props.dataLoading}
                onChange={(e, data) => props.onSearch(data.value)}
                onFocus={onInputFocus}
                // onBlur={() => setTimeout(() => setShowList(false), 100)} // Wait for the list click event to occur
                value={props.inputValue}
                fluid
            />
            {showList && props.dataLength !== 0 && (
                <InfiniteScroll
                    className={"infinite-scroll"}
                    dataLength={props.dataLength} //This is important field to render the next data
                    next={props.loadNextBatch}
                    hasMore={props.hasMore}
                    height={200}
                    loader={<Loader dataLength={props.dataLength} inputValue={props.inputValue} />}
                    endMessage={
                        <ListElement text-center>
                            <b>- {props.inputValue === "" ? `No more results found` : ""} -</b>
                        </ListElement>
                    }
                >
                    {props.data.map((el) => (
                        <ListElement
                            key={el.id}
                            onClick={() => {
                                props.onSelect(el);
                                setShowList(false);
                            }}
                        >
                            {el.value}
                        </ListElement>
                    ))}
                </InfiniteScroll>
            )}
            {showList && props.dataLength === 0 && (
                <div className={"infinite-scroll no-results"}>
                    <ListElement
                        className={"no-results"}
                        onClick={() => {
                            props.onSelect({ id: 0, value: "" });
                            setShowList(false);
                        }}
                    >
                        No results
                    </ListElement>
                </div>
            )}
        </DropdownWrapper>
    );
};

const clickOutsideConfig = {
    handleClickOutside: () => InfiniteDropdown.handleClickOutside,
};

export default onClickOutside(InfiniteDropdown, clickOutsideConfig);
