import API, { graphqlOperation } from "@aws-amplify/api";
import { agreementsByGroupAccess, vendorsBy } from "../../graphql/queries";
import { generateAgreementName } from "./agreementVSourceData";
import { uniq } from "lodash";

const fetchAgreements = async (options, { dispatch, activeEntity }) => {
    dispatch({ type: "FETCH_AGREEMENTS" });
    try {
        let {
            data: {
                agreementsByGroupAccess: { items: agreements },
            },
        } = await API.graphql(
            graphqlOperation(agreementsByGroupAccess, { limit: 2000, groupsCanAccess: activeEntity?.groupName })
        );
        // console.log("agreements:", agreements);

        // Vendors data from VSource
        const vSourceIDs = uniq(
            agreements
                .filter((el) => el.vendorVSourceId !== "" && el.vendorVSourceId !== null)
                .map((el) => el.vendorVSourceId)
        ).join(",");

        let vendorsList = [];

        if (vSourceIDs.length !== 0) {
            try {
                let {
                    data: {
                        vendorsBy: { data: _vendorsList },
                    },
                } = await API.graphql(
                    graphqlOperation(vendorsBy, {
                        param: "ids",
                        value: vSourceIDs,
                        orderBy: "full_legal_name",
                        order: "ASC",
                    })
                );

                vendorsList = _vendorsList;
            } catch (e) {
                console.log("Error fetching vendors data from VSource:", e);
            }
        }

        vendorsList = vendorsList.reduce((memo, vendor) => {
            memo[vendor.id] = vendor;
            return memo;
        }, {});

        // Map VSource vendors data to the agreements
        agreements = agreements.map((agreement) => {
            return {
                ...agreement,
                vendorName: vendorsList[agreement.vendorVSourceId]?.full_legal_name || "unknown",
                vendorId: vendorsList[agreement.vendorVSourceId]?.vendor_id_code || "",
                agreementName: generateAgreementName(
                    agreement,
                    vendorsList[agreement.vendorVSourceId]?.full_legal_name || "",
                    activeEntity
                ),
            };
        });

        dispatch({ type: "AGREEMENTS_FETCHED", agreements });

        return agreements;
    } catch (e) {
        console.log("fetchAgreements error:", e);
    }
};
export default fetchAgreements;
