import React from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const TooltipWrapper = styled.div`
  .documentTooltip {
    opacity: 1!important;
    padding: 0;
    background: #ffffff;
    color: #212121;
    font-size: 14px;
    border-radius: 8px;
    z-index: 9999999;
    border: none;
    ul {
      box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
      border: 1px solid #AFAFAF;
      border-radius: 8px;
    }
  }
`
const Tooltip = ({id, children, minWidth= "320px"}) => (
    <TooltipWrapper>
        <ReactTooltip
            id={id}
            className="documentTooltip"
            style={{
                minWidth,
            }}
            arrowColor="#ffffff"
        >
            {children}
        </ReactTooltip>
    </TooltipWrapper>
);

export default Tooltip;