import React, { useState } from "react";
import { Button, Modal, Input } from "semantic-ui-react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useAlerts } from "../../hooks/useAlerts";

import { addVendorProduct } from "../../graphql/mutations";

import { FieldsWrapper, InputWrapper, Row } from "./FormRow";
import { InputError } from "./UpdateFieldsetModal";

const AddNewProductModal = ({ show, onClose, vendorId }) => {
    const [productName, setProductName] = useState("");
    const [validationErrors, setValidationErrors] = useState({});

    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const closeModal = () => {
        setProductName("");
        setValidationErrors([]);
        onClose();
    };

    const saveProduct = async () => {
        if (productName === "") {
            setValidationErrors({ ...validationErrors, productName: "Product name is required" });
            return;
        }

        if (!vendorId) {
            newErrorAlert("Missing vendor ID");
            return;
        }

        if (productName !== "" && vendorId) {
            try {
                const { data } = await API.graphql(
                    graphqlOperation(addVendorProduct, {
                        name: productName,
                        status_code: "PRODUCT_DRAFT",
                        type_code: "NONE",
                        vendor_id: vendorId,
                    })
                );

                if (data && data.addVendorProduct && data.addVendorProduct.status) {
                    newSuccessAlert(`New product added successfully`);
                } else newErrorAlert("Failed to add a new product");
            } catch (e) {
                console.log("GraphQL fetch error:", e);
                newErrorAlert("Failed to add a new product");
                // throw new Error(e.errors[0].message);
            }
        }

        closeModal();
    };

    return (
        <Modal
            closeIcon
            onClose={closeModal}
            // onOpen={() => setOpen(true)}
            open={show}
        >
            <Modal.Header>Add product</Modal.Header>
            <Modal.Content>
                <Row>
                    <FieldsWrapper>
                        <InputWrapper customWidth="100%">
                            <label>Product name:</label>
                            <Input value={productName} onChange={(_, data) => setProductName(data.value)} />
                            <InputError>
                                {validationErrors.productName ? `${validationErrors.productName}` : null}
                            </InputError>
                        </InputWrapper>
                    </FieldsWrapper>
                </Row>
            </Modal.Content>
            <Modal.Actions>
                <Button content="Save" color="yellow" onClick={saveProduct} />
            </Modal.Actions>
        </Modal>
    );
};

export default AddNewProductModal;
