import API, { graphqlOperation } from "@aws-amplify/api";
import { get } from "lodash";

const query = `
    query ListAgreements(
  $filter: ModelAgreementFilterInput
  $limit: Int
  $nextToken: String
) {
  listAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      vendorId
      masterProduct
      meta {
        id
        productAndPricingGroups {
          items {
            product {
                id 
            }
          }
          nextToken
        }
      }
      vendorName
    }
    nextToken
  }
}
`;

const getProductAgreements = async (productId) => {
    const {
        data: { listAgreements },
    } = await API.graphql(
        graphqlOperation(query, {
            limit: 1000,
        })
    );

    return listAgreements.items.filter((agreement) => {
        const agreementProductPricingGroups = get(agreement, "meta.productAndPricingGroups.items", []);

        let agreementHasProduct = false;
        agreementProductPricingGroups.forEach((group) => {
            if (get(group, "product.id") === productId) {
                agreementHasProduct = true;
            }
        });

        return agreementHasProduct;
    });
};

export default getProductAgreements;
