import React from "react";

const BurgerMenuIcon = ({ fill = '#708EFC', ...props}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props} fill="none">
            <rect width="26" height="6" rx="1" fill={fill}/>
            <rect y="10" width="26" height="6" rx="1" fill={fill}/>
            <rect y="20" width="26" height="6" rx="1" fill={fill}/>
    </svg>
);

export default BurgerMenuIcon;