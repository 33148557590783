import React, { useContext } from "react";
import Select from "react-select";
import styled, { ThemeContext } from "styled-components";
import CompanyHeader from "../../../components/CompanyHeader";
import {
    TableComponent,
    Table,
    CloseIcon,
    FormInput,
    _dropDownStyles,
} from "../../../components/Tables";

const TableBlock = styled((props) => <Table {...props} />)`
    tr {
        td:first-child {
            span {
                display: inline-block;
                width: 10%;
            }
            & > div {
                display: inline-block;
                width: 85%;
                font-weight: normal !important;
            }
        }
    }
`;
export const SocialMediaTable = ({
    data,
    removeSocialMediaRow,
    socialMediaChannelOptions,
    setSocialMediaValue,
}) => {
    const themeContext = useContext(ThemeContext);
    const dropDownStyles = _dropDownStyles(themeContext);

    return (
        <TableComponent key={"sm-table"}>
            <CompanyHeader title={"Social Media"} />
            {data && data.length > 0 && (
                <TableBlock>
                    <tbody>
                        {data.map((row, i) => {
                            return (
                                <tr key={`sm-row-${i}-${row.id}`}>
                                    <td>
                                        <CloseIcon
                                            title={"Delete"}
                                            onClick={() =>
                                                removeSocialMediaRow(row.id)
                                            }
                                        >
                                            &times;
                                        </CloseIcon>
                                        <Select
                                            options={socialMediaChannelOptions}
                                            onChange={(e) =>
                                                setSocialMediaValue(
                                                    "social_media_type_code",
                                                    e.value,
                                                    row.id
                                                )
                                            }
                                            defaultValue={
                                                socialMediaChannelOptions.filter(
                                                    (el) =>
                                                        el.value ===
                                                        row.social_media_type_code
                                                )[0] ?? null
                                            }
                                            styles={dropDownStyles}
                                            key={`sm-select-${i}-${row.id}`}
                                        />
                                    </td>
                                    <td>
                                        <FormInput
                                            value={row.url}
                                            onChange={(e) =>
                                                setSocialMediaValue(
                                                    "url",
                                                    e.target.value,
                                                    row.id
                                                )
                                            }
                                            key={`sm-url-${i}-${row.id}`}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </TableBlock>
            )}
        </TableComponent>
    );
};
