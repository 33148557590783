import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
// import * as PropTypes from "prop-types";
import CostNameList from '../CostNameList/CostNameList';
import CustomPieChart from '../../../../components/CustomPieChart';
import SelectBlockView from '../SelectBlockView/SelectBlockView';
import CalendarView from '../CalendarView/CalendarView';

const CostBlockWrapper = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 2.2rem 2rem;
    border-radius: 1rem;
    border: 1px solid gray;
`;
const PieChartWrapper = styled.div`
    width: 33%;
    max-width: 270px;
`;
const SelectBlockWrapper = styled.div`
  position: absolute;
  right: 3%;
  top: 7%;
  min-width: 15rem;
`;
const mocksForChart = [
    { name: 'Debtwire ABS', value: 10, color: '#FDFF65' },
    { name: 'Debtwire Europe', value: 20, color: '#F64E60' },
    { name: 'Platts Global Alert- Oil', value: 30, color:'#0fb683' },
    { name: 'Argus Air Daily', value: 20, color: '#FFC309' },
    { name: 'PoweRT Europe', value: 10, color: '#3699FE' },
    { name: 'Tullet Prebon Credit Trading', value: 20, color: '#8951FC' },
];

const selectData = [
    {
        label: "Cost Chart",
        value: "cost_chart"
    },
    {
        label: "Calendar View",
        value: "calendar_view"
    }
]

export const CostBlock = ({

}) => {
    const [selectedView, setSelectedView] = useState(selectData[0].value);
    const onChangeView = (value) => {
        setSelectedView(value);
    }
    const renderSelectedView = useMemo(() => {
        if (selectedView === selectData[0].value || !selectedView) {
            return (
                <>
                    <CostNameList
                        list={mocksForChart}
                    />
                    <PieChartWrapper>
                        <CustomPieChart
                            data={mocksForChart}
                            totalValue="£412 500"
                        />
                    </PieChartWrapper>
                </>
            )
        } else if (selectedView === selectData[1].value) {
            return (
                <CalendarView />
            )
        }
    }, [selectedView])
    return <CostBlockWrapper>
        {
            renderSelectedView
        }
        <SelectBlockWrapper>
            <SelectBlockView
                label="Show:"
                options={selectData}
                onChange={onChangeView}
            />
        </SelectBlockWrapper>
    </CostBlockWrapper>
};

CostBlock.propTypes = {

};

export default CostBlock;
