export default function VendexLogoWithotCaption() {
    return (
        <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.711 23.9436H12.155L2.40039 2.67676L3.19593 2.69772C6.27532 9.4114 9.35472 16.1247 12.4341 22.8377C14.9464 17.3646 17.4584 11.8911 19.97 6.41694H20.8107L12.711 23.9436Z" fill="#FFC000" />
            <path d="M21.5704 23.9436L13.6772 6.34857H14.5691L22.3979 23.9326L21.5704 23.9436Z" fill="#FFC000" />
            <path d="M11.2807 7.1704H10.565V6.42578H11.2807V7.1704ZM9.79426 7.1704H9.02349V6.42578H9.79426V7.1704ZM8.25273 7.1704H7.48196V6.42578H8.25273V7.1704ZM6.7112 7.1704H5.94043V6.42578H6.7112V7.1704Z" fill="#557093" />
            <path d="M13.1184 11.0644H12.4027V10.3198H13.1184V11.0644ZM11.6319 11.0644H10.8611V10.3198H11.6319V11.0644ZM10.0904 11.0644H9.31961V10.3198H10.0904V11.0644ZM8.54884 11.0644H7.77808V10.3198H8.54884V11.0644Z" fill="#557093" />
            <path d="M15.0012 14.9155H14.2855V14.1709H15.0012V14.9155ZM13.5147 14.9155H12.744V14.1709H13.5147V14.9155ZM11.9732 14.9155H11.2024V14.1709H11.9732V14.9155ZM10.4317 14.9155H9.66089V14.1709H10.4317V14.9155Z" fill="#557093" />
        </svg>
    );
}