import React, { useMemo, useState } from "react";
import { Button, Dropdown, Input, Modal, Radio, TextArea } from "semantic-ui-react";
import styled from "styled-components";
import HelpOutline from "@material-ui/icons/HelpOutline";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import { vidsvMultiTypes } from "../../Constants";
import HelpVidsvText from "./HelpVidsvText";
import ImageField from "../vlink/ImageField";

import { getFieldValueWithDiff } from "../../helpers/vlink/revisionHelpers";
import listToTree from "../../utils/listToTree";
import validate from "../../utils/validate";
import HierarchicalSelectBox from "../hierarchical-selectbox/HierarchicalSelectBox";
import UpdateImage from "./UpdateImage";
import SelectedVidsvTable from "../SelectedVidsvTable";
import { FieldsWrapper, InputWrapper, Row, RowWrapper } from "./FormRow";
import OldHierarchicalSelectBox from "../hierarchical-selectbox/OldHierarchicalSelectBox";
import { FormHelperText } from '@material-ui/core';


export const StyledModal = styled(Modal)`
    resize: ${props => props.resizable ? 'both' : 'none'};
    overflow-x: hidden;
    max-width: ${props => props.resizable ? 'none' : (props.wider ? '1280px' : '1000px')};
    min-width: ${props => props.wider ? '80%' : 'auto'};
    max-height: 90vh;
    overflow-y: scroll;

    &.ui>.close.icon {
        top: -0.5rem;
        right: -0.5rem;
        color: #000;
    }
`;

export const InputError = styled.span`
	position: ${props => props.static ? 'static' : 'absolute'};
	left: 5px;
	bottom: -18px;
	font-size: 12px;
	color: red;
`;

const StyledModalContent = styled(Modal.Content)`
    min-height: 300px;
    .info {
        margin-top: -20px;
        font-style: italic;
    }
`;

const OldValueContainer = styled.div`
    margin-bottom: 20px;

    input, textarea, select {
        background-color: #e8e8e8 !important;
    }

    textarea {
        resize: none;
    }
`;

const VendexResponseTypeActions = styled.div`
    display: flex;
    margin: 20px 0;
    gap: 30px;

    .ui {
        width: auto!
    }
`;
const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.25rem 1.5rem;
  border-bottom: 1px solid rgba(34,36,38,.15);
`;

const CollapseWrapper = styled.div`
  margin-left: 5px;
  transform: ${(props) => (props.showHelpText ? "rotate(270deg) translateX(2px)" : "rotate(90deg) translateX(5px)")};
  transform-origin: center center;
  svg {
    margin: 0!important;
    font-size: 1.2rem;
  }
`
const HelpLink = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
  svg {
    margin-right: 5px;
  }
`
const HelpHeader = styled.div`
  display: flex;
  justify-content: flex-start;
`
const HelpInfoWrapper = styled.div`
  padding: 3.25rem 3rem;
  background: #FFF2CC;
`
export default function UpdateFieldsetModal({title, context, level, vsourceData, open, rowList, row, onCloseClick, onSaveClick, onDeleteClick, classifiers }) {
    const [values, setValues] = useState(row.fields.map(field => {
        if(context === 'VENDEX') {
            return field.submittedVendexValue || field.submittedNewValue || field.vsourceValue
        } 

        if(row.proposed) {
            return field.latestNewValue || field.submittedNewValue || field.vsourceValue;
        }

        return field.vendexValue || field.vsourceValue;
        
    }));

    const [selectedVidsvValues, setSelectedVidsvValues] = useState(null);

    // const oldValues = useMemo(() => row.fields.map(field => field.latestOldValue || field.submittedOldValue || field.vsourceValue), [row]);

    const [comment, setComment] = useState(row.declineComment || '');
    const [showHelpText, setShowHelpText] = useState(false);
    const [vendexResponseType, setVendexResponseType] = useState(row.vendexResponseType || 'REJECT_WITHOUT_EDITING');
    const [validationErrors, setValidationErrors] = useState(Array(row.fields.length).fill([]));
    const [rowValidationErrors, setRowValidationErrors] = useState([]);
    const [hasComment, setHasComment] = useState(true);

    function changeValue(value, index) {
        setValues(latestValues => {
            let nextValues = [...latestValues];
            nextValues.splice(index, 1, value != null && value !== '' ? value : null);
            return nextValues;
        });
    }

    function saveHandler() {
        let hasErrors = false;
        let errors = validate(null, row.rules || [], values, null, row, rowList, context);
        if(errors.length) {
            hasErrors = true;
        }
        setRowValidationErrors(errors);

        row.fields.forEach(function getValidationErrors(field, index) {
            let errors = validate(values[index], field.rules || [], values, field, row, rowList, context);
            if (errors.length) {
                hasErrors = true;
            }
            
            setValidationErrors(latestValidationErrors => {
                let nextValidationErrors = [...latestValidationErrors];
                nextValidationErrors.splice(index, 1, errors);
                return nextValidationErrors;
            });
        });

        if(!hasErrors && context === 'VENDEX' && !comment) {
            setHasComment(false);
            hasErrors = true;
        }

        if (!hasErrors) {
            onSaveClick(values, comment, vendexResponseType);
        }
    }

    function deleteHandler() {
        onCloseClick();
        onDeleteClick();
    }

    function toggleShowText() {
        setShowHelpText(!showHelpText)
    }

    // const toggleShowText = () => useCallback(() => {
    //     setShowHelpText(!showHelpText)
    // }, [showHelpText])

    const headerText = useMemo(() => {
        let text = 'Edit ' + title + ' Information: ';
        // if(context === 'VENDOR' && vsourceData) {
            text += level === 'VENDOR' ? vsourceData.full_legal_name : vsourceData.name;
        // } else {
        //     text += 'VendEx Solutions, Inc.';
        // }

        return text;
    }, [context, level, title, vsourceData]);

    return (
        <StyledModal
            resizable={Number(['product_vidsv'].includes(rowList[0].tableName))}
            wider={Number(['product_vidsv', 'product_tag'].includes(rowList[0].tableName))}
            closeIcon
            closeOnDimmerClick={false}
            onClose={() => onCloseClick()}
            open={open}
        >
            <Modal.Header>
                {headerText}
            </Modal.Header>
            {
                row?.tableName?.includes('vidsv') &&
                <HeaderWrapper>
                    <HelpLink onClick={toggleShowText}>
                        <HelpOutline />
                        {
                            showHelpText ? "Hide Instructions" : "Show Instructions for Creating Your Groupings"
                        }
                        <CollapseWrapper
                            showHelpText={showHelpText}
                        >
                            <ArrowBackIos />
                        </CollapseWrapper>
                    </HelpLink>
                </HeaderWrapper>
            }
            {
                row?.tableName?.includes('vidsv') && showHelpText && <HelpInfoWrapper>
                    <HelpVidsvText />
                </HelpInfoWrapper>
            }
            <StyledModalContent style={{ padding: '2rem' }}>
                {rowList[0].modalInfo && <p className="info">* {rowList[0].modalInfo}</p>}
                <RowWrapper>
                    <Row>
                    <FieldsWrapper>
                        {row.fields.map(function renderInputByType(field, fieldIndex) {
                            let customWidth = (field.widthRatio || 1 / row.fields.length) * 100 + '%';
                            let diff = getFieldValueWithDiff(field, row, context, 'vendex-modal');
                            return context === 'VENDEX' || row.submittedRevision ? 
                                <InputWrapper singleRow={!!field.singleRow} customWidth={customWidth} key={fieldIndex} >
                                        {field.label ? <label>{field.label}:</label> : null}
                                        { field.type === 'vidsv' && diff != null ?
                                            diff.split(', ').map(d => (
                                            <>
                                                <p className={field.type} dangerouslySetInnerHTML={{__html: d}}></p>
                                            </>
                                        )) : field.type === 'image' ?
                                            <ImageField  diff={diff} /> :
                                            <p className={field.type} dangerouslySetInnerHTML={{__html: diff}}></p>
                                        }
                                        
                                </InputWrapper> :
                                null
                        })}
                    </FieldsWrapper>
                    </Row>
                    <div style={{marginTop: 20}}>
                    { context === 'VENDEX' && (
                        <>
                            <Row>
                                <InputWrapper  customWidth='100%'>
                                    <TextAreaField
                                        label='Decline Comment'
                                        value={comment}
                                        onChange={(_, data) => setComment(data.value)}
                                        rows={4}
                                        error={!hasComment && !comment.length && 'Decline comment is required!'}
                                    />
                                </InputWrapper>
                            </Row>
                            <VendexResponseTypeActions>
                                {
                                    classifiers['VENDEX_REVISION_RESPONSE'].map((classifier, index) => 
                                        <Radio
                                            key={index}
                                            label={classifier.text}
                                            name='vendex_revision_response'
                                            value={classifier.value}
                                            checked={vendexResponseType === classifier.value}
                                            onChange={() => setVendexResponseType(classifier.value)}
                                        />
                                    )
                                }
                            </VendexResponseTypeActions>
                        </>
                    )}
                    </div>
                    { row.tableName === 'product_vidsv' &&  (context === 'VENDOR' || vendexResponseType !== 'REJECT_WITHOUT_EDITING') && (
                        <SelectedVidsvTable selectedVidsv={selectedVidsvValues}/>
                    )}
                    
                    <Row>
                    <FieldsWrapper >
                        {row.fields.map(function renderInputByType(field, fieldIndex) {
                            // condition for checking additional officer value
                            let isShowRoleAdditionalInput = row.tableName === 'officer_information'
                                && values[0] === 'ADDITIONAL_OFFICER'
                                && field.name === 'businessRoleAdditional';
                            let customWidth = (field.widthRatio || 1 / row.fields.length) * 100 + '%';
                            if (field.name === 'businessRoleAdditional' && (context === 'VENDOR' || vendexResponseType !== 'REJECT_WITHOUT_EDITING')) {
                                return isShowRoleAdditionalInput ?
                                <InputWrapper 
                                    singleRow={!!field.singleRow}
                                    customWidth={customWidth} 
                                    key={fieldIndex} 
                                    className={[row.tableName === 'product_vidsv' ? 'vidsv' : '', field.visibility].join(' ')}
                                >
                                    <ModalField
                                        fieldIndex={fieldIndex}
                                        type={field.type}
                                        options={field.options}
                                        dateFormat={field.dateFormat}
                                        rows={field.rows}
                                        context={context}
                                        label={field.label}
                                        helpText={field.helpText}
                                        value={values[fieldIndex] ?? ''}
                                        formatedValue={field.formatedValue}
                                        saveDateFormat={field.saveDateFormat}
                                        // oldValue={oldValues[fieldIndex]}
                                        onChange={(_, data) => changeValue(data.value, fieldIndex)}
                                        error={validationErrors[fieldIndex].length}
                                    />
                                    <InputError>
                                        {validationErrors[fieldIndex].length ?
                                            `${validationErrors[fieldIndex][0].message}` :
                                            null
                                        }
                                    </InputError>
                                </InputWrapper> : null
                            } else if (context === 'VENDOR' || vendexResponseType !== 'REJECT_WITHOUT_EDITING') {
                                return (
                                    <InputWrapper 
                                        singleRow={!!field.singleRow}
                                        customWidth={customWidth} 
                                        key={fieldIndex} 
                                        className={[row.tableName === 'product_vidsv' ? 'vidsv' : '', field.visibility].join(' ')}
                                    >
                                        <ModalField
                                            fieldIndex={fieldIndex}
                                            type={field.type}
                                            options={field.options}
                                            dateFormat={field.dateFormat}
                                            allVidsvOptions={field.allVidsvOptions}
                                            rows={field.rows}
                                            context={context}
                                            label={field.label}
                                            helpText={field.helpText}
                                            value={values[fieldIndex] ?? ''}
                                            formatedValue={field.formatedValue}
                                            saveDateFormat={field.saveDateFormat}
                                            // oldValue={oldValues[fieldIndex]}
                                            onChange={field.type === 'image' ? (file) => changeValue(file, fieldIndex) : (_, data) => changeValue(data.value, fieldIndex)}
                                            setSelectedVidsvValues={setSelectedVidsvValues}
                                            error={Boolean(validationErrors[fieldIndex].length)}
                                        />
                                        <InputError>
                                            {validationErrors[fieldIndex].length ?
                                                `${validationErrors[fieldIndex][0].message}` :
                                                null
                                            }
                                        </InputError>
                                    </InputWrapper>
                                )
                            }
                            return null
                        })}
                        <>
                        { row.itemTypeCode === 'RECORD' && (
                            (context === 'VENDOR' && row.status === 'EDITING') ||
                            (context === 'VENDEX' &&  vendexResponseType !== 'REJECT_WITHOUT_EDITING')
                        ) ?
                            <Button onClick={deleteHandler}>Remove</Button> :
                            null
                        }
                        </>
                    </FieldsWrapper>
                    </Row>
                    <Row>
                        {rowValidationErrors.map(err => (
                            <InputError static={1}>
                                {err.message}
                            </InputError>
                        ))}
                    </Row>
                </RowWrapper>
            </StyledModalContent>
            <Modal.Actions>
                {/* <Button onClick={() => onCloseClick()}>
                    Decline
                </Button> */}
                <Button color='yellow' onClick={saveHandler}>
                    Save
                </Button>
            </Modal.Actions>
        </StyledModal>
    );
}

function ModalField({
    fieldIndex,
    type,
    options,
    allVidsvOptions,
    rows,
    error,
    setSelectedVidsvValues,
    dateFormat,
    saveDateFormat,
    formatedValue,
    ...commonProps
}) {
    const multiselectData = useMemo(() => {
        let data = {};

        if ((type === 'multiselect_tree' || type === 'vidsv') && options && options.length) {
            data = listToTree(options.map(option => ({...option, value: option.text})));
        } 

        return data;
    }, [options, type]);

    const multiselectInitialData = useMemo(() => {
        let initData = [];
        
        if ((type === 'multiselect_tree' || type === 'vidsv') && options && options.length) {
            initData = options
                .filter(option => {
                    if(commonProps.value != null) {
                        return commonProps.value.split(' ').includes(option.value)
                    }
                    return false;
                })
                .map(option => ({label: option.label, id: option.code}));
        }

        return initData;
    }, [options, type]);

    function vidsvSelectHandler(arr) {
        commonProps.onChange(null, {value: arr.map(val => val.id).join(' ')});
        setSelectedVidsvValues(latestVidsvValues => {
            return {
                ...latestVidsvValues,
                [vidsvMultiTypes[fieldIndex - 1]]: arr
            }
        });
    }

    switch (type) {
        case 'textarea':
            return <TextAreaField
                rows={rows || 8}
                error={error}
                {...commonProps}
            />
        case 'select':
            return <SelectField
                options={options}
                {...commonProps}
            />
        case 'image':
            return <UpdateImage
                {...commonProps}
            />
        case 'date':
            return <>
                {commonProps.label ? <label>{commonProps.label}:</label> : null}
                <DatePicker
                    selected={commonProps.value ? new Date(commonProps.value) : null}
                    onChange={(value) => commonProps.onChange(null, { value: format(value, saveDateFormat) })}
                    dateFormat={dateFormat}
                />
            </>
        case 'multiselect_tree':
            return <OldHierarchicalSelectBox
                template={multiselectData}
                defaultSelectedValues={multiselectInitialData}
                isMultiselect
                lastLevelSelectable={true}
                showValueWithPath
                onSelect={(arr) => commonProps.onChange(null, {value: arr.map(el => el.id).join(' ')})}
            />
        case 'vidsv':
            return <HierarchicalSelectBox
                template={multiselectData}
                defaultSelectedValues={multiselectInitialData}
                isMultiselect
                lastLevelSelectable={false}
                showValueWithPath
                onSelect={vidsvSelectHandler}
                nonSelectableParents
                showSelectedItemsBar={false}
            />
        default:
            return <InputField
                type={type}
                error={error}
                {...commonProps}
            />
    }
}

function InputField(props) {
    return (
        <>
            {props.label ? <label>{props.label}:</label> : null}
            <Input {...props} label={false} />
            {props.helpText ? <FormHelperText>{props.helpText}</FormHelperText> : null}
        </>
    );
}

function SelectField(props) {
    return (
        <>
            {props.label ? <label>{props.label}:</label> : null}
            <Dropdown label='Plese Select' search selection {...props} />
            {props.helpText ? <FormHelperText>{props.helpText}</FormHelperText> : null}
        </>
    );
}

function TextAreaField(props) {
    return (
        <>
            {props.label ? <label>{props.label}:</label> : null}
            <TextArea {...props} />
            {props.helpText ? <FormHelperText>{props.helpText}</FormHelperText> : null}
            <span className="error">{props.error}</span>
        </>
    );
}