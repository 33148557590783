import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
const Chance = require("chance");
const chance = new Chance();

const fetchProducts = async (params, { dispatch }) => {
    dispatch({ type: "LOADING_PRODUCTS" });

    const {
        data: {
            listProducts: { items },
        },
    } = await API.graphql(
        graphqlOperation(queries.listProducts, {
            limit: 1000,
        })
    );

    console.log("listProducts : ", items);

    dispatch({ type: "PRODUCTS_FETCHED", products: items });
};

export default fetchProducts;
