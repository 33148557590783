import React, {
    useReducer,
    useState,
    useMemo,
    useEffect,
    useCallback,
    useRef
} from "react";
import styled from "styled-components";
import _get from "lodash/get";
import _find from "lodash/find";
import _findIndex from "lodash/findIndex";

import ChevronRight from "../../components/icons/ChevronRight";
import CloseIcon from "../../components/icons/CloseIcon";
import Theme from "../../styles/theme";
import { StyledSearch } from "../VendorSearchBar";
import { _findInTree } from "../../utils/findInTree";
import { _searchInTree } from "../../utils/searchInTree";
import sortClassifiers from "../../utils/sortClassifiers";
import { _searchLeavesInTree } from "../../utils/searchLeavesInTree";
import { _searchPropInTree } from "../../utils/searchPropInTree";
import { _searchParentClassifierCodes } from "../../utils/searchParentClassifierCodes";

const DropdownWrapper = styled.div`
    margin-top: 10px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
`;

const DropDownElementWrapper = styled.div`
    min-width: auto;
    padding: 4px 15px;
    margin-right: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: left;
    border-radius: 5px;
    &:hover {
        background-color: ${Theme.gray1};
    }

    & > span {
        display: flex;
        align-items: center;
    }
    background: ${props => props.active || props.checked ? Theme.gray1 : 'none'}
`;

const Column = styled.div`
    width: ${props => props.level > 0 ? 'calc(100% - 35px)' : 'auto'};
    display: block;
    margin-left: ${props => props.level > 0 ? 35 + 'px' : '0'};
    // max-height: ${props => props.level > 0 && props.count > 14 ? '400px' : 'none'};
    // overflow-y: ${props => props.level > 0 && props.count > 14 ? 'scroll' : 'auto'};
`;

const InputWrapper = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: 4px;
`

const DropDownElementClickArea = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
      & > .label {
        margin-left: ${props => props.nonSelectable ? '23px' : '10px'};
        display: flex;
        word-break: break-word;
      }
    
      & > .arrow-right {
        display: flex;
        margin-left: auto;

        &.active {
            transform: rotate(90deg);
        }
      }
`

const Input = styled.input`
  cursor: pointer;
`

const SelectedItemsBar = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 5px 0 5px;
  margin-bottom: 10px;
  border: 1px solid #347FF6;
  border-radius: 5px;
`
const ColumnsWrapper = styled.div`
    width: 100%;
    display: flex;
    border: 1px solid #B5B5C3;
    padding: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    align-content: flex-start;
    height: 60vh;
    overflow-y: scroll;
    overflow-x: hidden;

    &::-webkit-scrollbar {
        background-color: transparent;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
    

  > .selectBoxSearch {
      width: 100%;
      margin-bottom: 20px;
      .input {
        width: 100%;
        input {
          border-radius: 5px;
        }
      }
    }
`
const ResultsWrapper = styled.div`
  width: 100%;
  display: flex;
`
const SelectedItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: #347FF6;
    background: #ECF5FE;
    border: 1px solid #347FF6;
    margin-right: 5px;
    margin-bottom: 5px;
  > .closeArea {
    cursor: pointer;
    padding: 5px;
    border-right: 1px solid #347FF6;
  }
  > .label {
    padding: 5px;
  }
`



// const template = [
//     {
//         label: "Buy Side",
//         id: 123,
//         children: [
//             {
//                 label: "Front office",
//                 id: 2,
//                 children: [
//                     { label: "Portfolio Management", id: 21 },
//                     { label: "Research and Analsys", id: 22 },
//                     { label: "Sales", id: 23 },
//                 ],
//             },
//             {
//                 label: "Middle office",
//                 id: 3,
//                 children: [
//                     { label: "Pricing/Valuation", id: 31 },
//                     { label: "Legal", id: 32 },
//                     { label: "Risk", id: 33 },
//                 ],
//             },
//             {
//                 label: "Back office",
//                 id: 4,
//                 children: [
//                     { label: "Trade Reconciliation", id: 41 },
//                     { label: "Settlements", id: 42 },
//                     { label: "Audit", id: 43 },
//                 ],
//             },
//         ],
//     },
//     {
//         label: "Sell Side",
//         id: 234,
//         children: [
//             {
//                 label: "Front office",
//                 id: 12,
//                 children: [
//                     { label: "Trading", id: 11111 },
//                     { label: "Syndicate", id: 2222 },
//                     { label: "Sales", id: 3333 },
//                     { label: "Treasury", id: 4444 },
//                 ],
//             },
//             {
//                 label: "Middle office",
//                 id: 23,
//                 children: [
//                     { label: "Pricing/Valuation", id: 5555 },
//                     { label: "Trade Processing", id: 6666 },
//                     { label: "Risk", id: 7777 },
//                     { label: "Compliance", id: 8888 },
//                 ],
//             },
//             {
//                 label: "Back office",
//                 id: 34,
//                 children: [
//                     { label: "Trade Reconciliation", id: 99999 },
//                     { label: "Settlements", id: 101010 },
//                     { label: "Audit", id: 111111111 },
//                     { label: "Operations", id: 12121212 },
//                 ],
//             },
//         ],
//     },
// ];

function fillDefaultSelectedNavItems(ids, classifiers) {
    if(ids.length === 0) {
        return [];
    }
    return _searchParentClassifierCodes(ids, classifiers);
}

const defaultSelectedItems = [];
const selectedItemsReducer = (state, action) => {
    if (action.type === "CHECK") {
        const updatedArray = [...state];
        updatedArray.splice(updatedArray.length + 1, 0, {
            id: action.id,
            label: action.label,
            path: action.path,
            last: action.last
        });
        return updatedArray;
    } else if (action.type === "CHECK_ONLY_ONE") {
        const updatedArray = [...state];
        updatedArray.splice(0, 1, {
            id: action.id,
            label: action.label,
            path: action.path,
            last: action.last
        });
        return updatedArray;
    }
    else if (action.type === "UNCHECK") {
        const updatedArray = [...state];
        const index = _findIndex(updatedArray, ['id', action.id]);
        updatedArray.splice(index, 1);
        return updatedArray;
    } else if (action.type === "RESTORE") {
        return [...action.data];
    } else {
        return state;
    }
}
const selectedNavItemsReducer = (state, action) => {
    if (action.type === "UPDATE") {
        const nextState = [...state];
        let currentIndex = nextState.indexOf(action.id);
        if(currentIndex > -1) {
            // unselect if the item is already selected
            nextState.splice(currentIndex, 1);
        } else {
            nextState.push(action.id);
        }
       
        return nextState;
    } else if (action.type === "FORCE_UPDATE") {
        return action.ids;
    } else if (action.type === "CLEAR") {
        return [];
    } else {
        return state;
    }
}
const HierarchicalSelectBox = ({
    flatTemplate = [],
    template,
    defaultSelectedValues = [],
    lastLevelSelectable,
    isMultiselect,
    showValueWithPath,
    onSelect,
    nonSelectableParents = false,
    showSelectedItemsBar = true
}) => {
    const [templateState, setTemplateState] = useState(template);
    const [selectedNavItems, dispatchSelectedNavItems] = useReducer(
        selectedNavItemsReducer, 
        fillDefaultSelectedNavItems(defaultSelectedValues.map(val => val.id), flatTemplate)
    );
    const [selectedItems, dispatchSelectedItems] = useReducer(selectedItemsReducer, defaultSelectedItems);
    const [searchValue, setSearchValue] = useState('');

    const beforeSearchStateRef = useRef({templateState, selectedNavItems, selectedItems});
    
    const handleItemClick = (id) => {
        dispatchSelectedNavItems({
            type: "UPDATE",
            id: id,
        });
    }

    const onChange = (checked, id, label, path, last) => {
        if (isMultiselect) {
            if (checked) {
                dispatchSelectedItems({
                    type: "CHECK",
                    id,
                    label,
                    path,
                });
            } else {
                dispatchSelectedItems({
                    type: "UNCHECK",
                    id,
                });
            }
        } else {
            if (checked) {
                dispatchSelectedItems({
                    type: "CHECK_ONLY_ONE",
                    id,
                    label,
                    path,
                    last,
                });
            } else {
                dispatchSelectedItems({
                    type: "UNCHECK",
                    id,
                });
            }
        }
    }
    const onDelete = (id) => {
        dispatchSelectedItems({
            type: "UNCHECK",
            id,
        });
    }
    const onSearch = useCallback((e) => {
        if(searchValue.trim() === '') {
            beforeSearchStateRef.current.selectedNavItems = selectedNavItems;
        }

        setSearchValue(e.target.value)
        const value = e.target.value.toLowerCase().trim();
        if (!value) {
            setTemplateState(template);
            dispatchSelectedNavItems({type: 'FORCE_UPDATE', ids: beforeSearchStateRef.current.selectedNavItems});
        } else {
            // const newTemplate = _searchInTree(value, template, selectedNavItems);
            const newTemplate = _searchLeavesInTree(value, template, selectedNavItems);
            // if (newTemplate && newTemplate.length) {
                const ids = _searchPropInTree('id', newTemplate, true);
                dispatchSelectedNavItems({type: 'FORCE_UPDATE', ids: ids});
                setTemplateState(newTemplate);
            // }
        }
    }, [
        template,
        selectedNavItems,
    ])
    const isLastColumn = (children) => {
        let isLastColumnInTree = children.some((item) => item.children && !!item.children.length)
        return !isLastColumnInTree;
    }

    // callback selected items
    useEffect(() => {
        onSelect(selectedItems)
    }, [selectedItems]);

    useEffect(() => {
        if (defaultSelectedValues && defaultSelectedValues.length) {
            const restoreItems = [];
            defaultSelectedValues.forEach((item) => {
                const foundItem = _findInTree(item.id, template);
                if (foundItem) {
                    restoreItems.push(foundItem)
                }
            });
            dispatchSelectedItems({
                type: "RESTORE",
                data: restoreItems,
            });
        }
    }, [defaultSelectedValues, template]);

    const recursionFunc = (menuItem, level, path) => {
        const childrenItems = _get(menuItem, 'children', []);
        const isLast = isLastColumn(childrenItems);
        const nonSelectable = !!lastLevelSelectable
            ? !isLast
            : false;
        const newPath = !!showValueWithPath && path ? `${path} > ${menuItem.label}` : _get(menuItem, 'label', '');
        const isSelected = (childrenItem) => {
            return _find(selectedItems, ['id', childrenItem.id]);
        }

        if (menuItem && childrenItems && childrenItems.length) {
            let selectedChildren = childrenItems.filter(child => selectedNavItems.includes(child.id));
            return (
                <>
                    <Column level={level + 1} count={childrenItems.length}>
                        {
                            childrenItems.map((childItem) => (
                                <React.Fragment key={childItem.id}>
                                    <DropDownElement
                                        nonSelectable={nonSelectable || (nonSelectableParents && childItem.children.length > 0)}
                                        isLast={isLastColumn([childItem])}
                                        onChange={!!nonSelectable || (nonSelectableParents && childItem.children.length > 0) ? () => {} : onChange}
                                        checked={isSelected(childItem)}
                                        key={childItem.id}
                                        id={`${childItem.id}`}
                                        label={childItem.label}
                                        path={`${newPath} ${newPath ? '>' : ''} ${childItem.label}`}
                                        active={selectedNavItems.includes(childItem.id)}
                                        onClick={handleItemClick}
                                    />
                                {
                                    selectedChildren.includes(childItem)
                                    && recursionFunc(
                                        childItem,
                                        level + 1,
                                        newPath
                                    )
                                }
                                </React.Fragment>
                            ))
                        }
                    </Column>
                </>
            )
        } else {
            return null
        }
    }
    return (
        <DropdownWrapper>
            {
                showSelectedItemsBar && selectedItems && !!selectedItems.length &&
                (
                    <SelectedItemsBar>
                        {
                            selectedItems.map((item) => (
                                <SelectedItem
                                    key={item.id}
                                    id={item.id}
                                    label={showValueWithPath ? `${item.path}` : item.label}
                                    onDelete={onDelete}
                                />
                            ))
                        }
                    </SelectedItemsBar>
                )
            }
            <ColumnsWrapper>
                {process.env.ENV !== 'prod' &&
                <StyledSearch
                    open={false}
                    showNoResults={false}
                    onSearchChange={onSearch}
                    value={searchValue}
                    className="selectBoxSearch"
                    placeholder='Search'
                />
                }
                <ResultsWrapper>
                    {
                        templateState && !!templateState.length
                        && recursionFunc({
                            children: [...templateState]
                        }, -1, '')
                    }
                </ResultsWrapper>
            </ColumnsWrapper>
        </DropdownWrapper>
    );
};

const DropDownElement = ({ label, id, path, active, checked, isLast, nonSelectable, onClick, onChange }) => {
    const onChangeCallback = (e) => {
        const checkedValue = _get(e, 'target.checked');
        // here we can get tree index
        onChange(checkedValue, id, label, path);
    }
    return (
        <DropDownElementWrapper checked={checked} active={active}>
           <InputWrapper>
               {
                   !nonSelectable && <Input
                       type="checkbox"
                       value={id}
                       checked={!!checked}
                       onChange={onChangeCallback}
                   />
               }
           </InputWrapper>
            <DropDownElementClickArea onClick={() => onClick(id)} nonSelectable={nonSelectable}>
                <div className="label">{label}</div>
                <div className={`${(active ? 'active ' : '') + 'arrow-right'}`}>
                    {!isLast && <ChevronRight width={20} height={20} />}
            </div>
            </DropDownElementClickArea>
        </DropDownElementWrapper> 
    );
};

const SelectedItem = ({ id, label, onDelete }) => {
    return <SelectedItemWrapper>
        <div className="closeArea" onClick={() => onDelete(id)}>
            <CloseIcon width={10} height={10} fill="#347FF6"/>
        </div>
        <div className="label">{label}</div>
    </SelectedItemWrapper>
}

export default HierarchicalSelectBox;
