import React, { useContext } from "react";
import styled from "styled-components";
import _sortBy from "lodash/sortBy";
import CloseIcon from "./icons/CloseIcon";
import { useAlerts } from "../hooks/useAlerts";
import { AlertContext } from "../context/alert/AlertContext";


const AlertsWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 10000;
`;

const ErrorMessage = styled.div`
    position: relative;
    display: block;
    text-align: center;
    color: ${({ theme }) => theme.textGray};
    font-size: 14px;
    border: 1px solid #f5c2c7;
    background-color: #f8d7da;
    margin-top: 5px;
    width: 50%;
    border-radius: 0.25rem;
    padding: 1rem;
    opacity: 0.98;
`;

const AlertMessage = styled(ErrorMessage)`
    border: 1px solid #ffecb5;
    background-color: #fff3cd;
`;

const SuccessMessage = styled(ErrorMessage)`
    border: 1px solid #badbcc;
    background-color: #d1e7dd;
`;

const CloseButton = styled.div`
    cursor: pointer;
    position: absolute;
    right: 10px;
`;

const CloseBtn = ({ onClick }) => {
    return (
        <CloseButton onClick={onClick}>
            <CloseIcon />
        </CloseButton>
    );
};
export const AlertsComponent = () => {
    const { context } = useContext(AlertContext);
    const { removeErrorAlert, removeAlert, removeSuccessAlert } = useAlerts();

    let messages = [];
    // Iterate over alerts context
    Object.keys(context).forEach((type) => {
        // Errors, alerts, success
        if (context[type].length > 0) {
            // Add message to list
            context[type].forEach((msg) => {
                messages.push({
                    message: msg.message,
                    type,
                    timestamp: msg.timestamp,
                });
            });
        }
    });

    // Sort messages by timestamp
    messages = _sortBy(messages, [
        function (o) {
            return o.timestamp;
        },
    ]);

    const ErrorAlert = ({ id, children }) => {
        return (
            <ErrorMessage>
                <CloseBtn onClick={() => removeErrorAlert(id)} />
                {children}
            </ErrorMessage>
        );
    };

    const Alert = ({ id, children }) => {
        return (
            <AlertMessage>
                <CloseBtn onClick={() => removeAlert(id)} />
                {children}
            </AlertMessage>
        );
    };

    const SuccessAlert = ({ id, children }) => {
        return (
            <SuccessMessage>
                <CloseBtn onClick={() => removeSuccessAlert(id)} />
                {children}
            </SuccessMessage>
        );
    };

    // Return message components
    const alerts = messages.map((msg, i) => {
        if (msg.type === "errors")
            return (
                <ErrorAlert id={msg.timestamp} key={`alert-${i}`}>
                    {msg.message}
                </ErrorAlert>
            );
        else if (msg.type === "alerts")
            return (
                <Alert id={msg.timestamp} key={`alert-${i}`}>
                    {msg.message}
                </Alert>
            );
        else if (msg.type === "success")
            return (
                <SuccessAlert id={msg.timestamp} key={`alert-${i}`}>
                    {msg.message}
                </SuccessAlert>
            );
        else return null;
    });

    return <AlertsWrapper>{alerts}</AlertsWrapper>;
};
