import React, { useState, useEffect, useCallback } from "react";
import { Button, Modal, Input, TextArea, Loader, Dimmer, Progress } from "semantic-ui-react";
import { gql, useMutation, useLazyQuery } from "@apollo/client";
import * as tus from "tus-js-client";
import { v4 as uuidv4 } from "uuid";

import { useAlerts } from "../../hooks/useAlerts";

import { uploadVlinkFile } from "../../graphql/customMutations";

import { FieldsWrapper, InputWrapper, Row } from "./FormRow";
import { InputError } from "./UpdateFieldsetModal";

const VimeoUploadUrlQuery = gql`
    query Query($id: ID!, $type: FileUploadType!, $fileSize: Int!, $fileName: String!, $fileDescription: String) {
        vlink {
            videoUploadRequest(
                id: $id
                type: $type
                fileSize: $fileSize
                fileName: $fileName
                fileDescription: $fileDescription
            ) {
                success
                status
                upload_url
                vimeo_upload_meta
            }
        }
    }
`;

const StoreVimeoVideoMetaMutation = gql`
    mutation Mutation($id: ID!, $input: StoreVLinkPremiumVideoMetaInput) {
        vlink {
            storeVideoMeta(id: $id, input: $input) {
                success
            }
        }
    }
`;

const UploadVideoModal = ({ show, onClose, uploadId, type }) => {
    const [file, setFile] = useState("");
    const [fileTitle, setFileTitle] = useState("");
    const [fileDescription, setFileDescription] = useState("");
    // Unique filename with the uuid suffix
    const [newFileName, setNewFileName] = useState();
    const [validationErrors, setValidationErrors] = useState({});
    // Vimeo response when the video file is uploaded
    const [vimeoUploadResponse, setVimeoUploadResponse] = useState();
    const [loading, setLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState();

    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const closeModal = useCallback(() => {
        setFileTitle("");
        setFileDescription("");
        setFile("");
        setValidationErrors([]);
        onClose();
    }, [onClose]);

    const [
        getUploadUrl,
        { loading: uploadUrlLoading, error: uploadUrlError, data: uploadUrlRes, refetch },
    ] = useLazyQuery(VimeoUploadUrlQuery);

    const [storeVimeoVideoMeta, { loading: storeVideoMetaLoading }] = useMutation(StoreVimeoVideoMetaMutation);

    useEffect(() => {
        if (uploadUrlError) {
            console.log("uploadUrlError:", uploadUrlError);
            newErrorAlert(uploadUrlError?.message || "Error uploading a video file");
            closeModal();
        }
    }, [uploadUrlError, type, newErrorAlert, closeModal]);

    // Upload video file to Vimeo
    useEffect(() => {
        console.log("uploadUrlRes:", uploadUrlRes);
        if (!uploadUrlLoading && uploadUrlRes) {
            const {
                vlink: {
                    videoUploadRequest: { upload_url },
                },
            } = uploadUrlRes;

            // Create the tus upload similar to the example from above
            var upload = new tus.Upload(file, {
                uploadUrl: upload_url,
                onError: function (error) {
                    console.log("Failed because: " + error);
                    newErrorAlert("Failed to upload video: ", error);
                },
                onProgress: function (bytesUploaded, bytesTotal) {
                    var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(0);
                    // console.log(bytesUploaded, bytesTotal, percentage + "%")
                    setUploadProgress(percentage);
                },
                onSuccess: function () {
                    console.log("Download %s from %s", upload.file.name, upload.url);
                    console.log("upload:", upload);
                    setVimeoUploadResponse(JSON.stringify(upload));
                },
            });

            // Start the upload
            upload.start();
        }
    }, [uploadUrlRes, uploadUrlLoading]);

    useEffect(() => {
        console.log("vimeoUploadResponse changed, starting to send the store request");
        if (vimeoUploadResponse && file) {
            console.log("Sending the storeMeta request");
            async function storeMeta() {
                let storedMetaRes;

                try {
                    storedMetaRes = await storeVimeoVideoMeta({
                        variables: {
                            id: uploadId,
                            input: {
                                type, // "VENDOR" or "PRODUCT"
                                fileTitle,
                                description: fileDescription,
                                fileName: newFileName,
                                originalName: file.name,
                                fileSize: file?.size,
                                fileMimeType: file?.type,
                                vimeoResponse: vimeoUploadResponse,
                                vimeoUploadMeta: uploadUrlRes?.vlink?.videoUploadRequest?.vimeo_upload_meta,
                            },
                        },
                    });
                } catch (e) {
                    console.error("Store video meta data request error: ", e);
                    newErrorAlert("Error uploading the video (metadata)!");
                    closeModal();
                }

                console.log("storedMetaResponse:", storedMetaRes);
                if (storedMetaRes?.data?.vlink?.storeVideoMeta?.success) {
                    newSuccessAlert("Video uploaded successfully. It might take some time to transcode.");
                } else {
                    newErrorAlert("Error uploading the video (metadata)!");
                }
                closeModal();
            }

            storeMeta();
        }
    }, [vimeoUploadResponse, file]);

    if (!show) {
        console.log("Not showing the modal");
        return null;
    }

    if (!type || (type !== "VENDOR" && type !== "PRODUCT")) {
        newErrorAlert("Missing or invalid upload type");
        return null;
    }

    const saveForm = async () => {
        if (fileTitle === "") {
            setValidationErrors({ ...validationErrors, fileName: "Filename is required" });
            return;
        }

        if (!file) {
            setValidationErrors({ ...validationErrors, file: "File is required" });
            return;
        }

        if (!uploadId) {
            if (type === "VENDOR") {
                newErrorAlert("Missing vendor ID");
            } else if (type === "PRODUCT") {
                newErrorAlert("Missing product ID");
            } else {
                newErrorAlert("Missing upload ID");
            }

            return;
        }

        const newFileName = `${file.name}-${uuidv4()}`;
        setNewFileName(newFileName);

        try {
            setLoading(true);

            // Get the upload URL
            getUploadUrl({
                variables: {
                    id: uploadId,
                    type,
                    fileSize: file?.size,
                    fileName: newFileName,
                    fileDescription,
                },
            });
        } catch (e) {
            console.log("GraphQL fetch error:", e);
            newErrorAlert("Failed to add a new file");
            closeModal();
        }
    };

    const onFileChange = (e) => {
        if (e?.target?.validity?.valid) {
            setFile(e.target.files[0]);
            console.log("file:", e.target.files[0]);
        } else {
            setValidationErrors({ ...validationErrors, file: "File is not valid" });
        }
    };

    return (
        <Modal closeIcon onClose={closeModal} open={show}>
            <Modal.Header>Video Upload</Modal.Header>
            <Modal.Content>
                <Row>
                    <FieldsWrapper>
                        <InputWrapper customWidth="100%">
                            <label>Video title:</label>
                            <Input value={fileTitle} onChange={(_, data) => setFileTitle(data.value)} />
                            <InputError>{validationErrors.fileName ? `${validationErrors.fileName}` : null}</InputError>
                        </InputWrapper>
                        <InputWrapper customWidth="100%">
                            <label>Video description:</label>
                            <TextArea value={fileDescription} onChange={(_, data) => setFileDescription(data.value)} />
                            <InputError>
                                {validationErrors.fileDescription ? `${validationErrors.fileDescription}` : null}
                            </InputError>
                        </InputWrapper>
                        <InputWrapper customWidth="100%">
                            <label>File:</label>
                            <Input onChange={onFileChange} type={"file"} />
                            <InputError>{validationErrors.file ? `${validationErrors.file}` : null}</InputError>
                        </InputWrapper>
                    </FieldsWrapper>
                </Row>
            </Modal.Content>
            <Modal.Actions>
                {loading && (
                    <Dimmer active inverted>
                        <Loader />
                    </Dimmer>
                )}
                {uploadProgress && <Progress progress="percent" percent={uploadProgress} success/>}
                <Button content="Save" color="yellow" onClick={saveForm} disabled={loading} />
            </Modal.Actions>
        </Modal>
    );
};

export default UploadVideoModal;
