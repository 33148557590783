import React, { useCallback, useEffect, useState } from "react";
import { Storage } from "aws-amplify";
import styled from "styled-components";

import DefaultLogo from "../../images/default_logo.svg";

const LogosList = styled.div`
  display: flex;
`
const LogoWrapper = styled.div`
  text-align: center;
  width: 200px;
  margin-right: 70px;
  img {
    max-width: 100%;
    height: auto;
  }
`;

const Icon = styled.img`
  height: 2rem;
`;

const LogoBorder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 8px 8px 5px 8px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23E5E5E5FF' stroke-width='3' stroke-dasharray='7%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  min-height: 125px;
`;

const ImageField = ({ diff = [] }) => {
    const [logos, setLogos] = useState([]);
    const [logosErrorList, setLogosErrorList] = useState([]);
    const onSetLogosErrorList = useCallback((index) => {
        setLogosErrorList([
            ...logosErrorList,
            index
        ])
    }, [logosErrorList]);
    const updateLogos = (logos) => {
        if (logos.length) {
            const logoStack = [];

            logos.forEach && logos.forEach((deffValue) => {
                if (!deffValue?.size) {
                    if (deffValue !== null) {
                        logoStack.push(
                            Storage.get(`vsource/logos/${deffValue}`, {
                                level: "public",
                            })
                        );
                    } else {
                        logoStack.push(null);
                    }

                }
            });
            Promise.all(logoStack).then((res) => {
                setLogos(res)
            }).catch((e) => {
                console.error(e);
            });
        }
    }
    useEffect( () => {
        updateLogos(diff);
    },[diff]);

    return <LogosList>
        {
            !!logos.length && logos.map((logo, index) =>
                <LogoWrapper key={`${diff[index]}_${logo}`}>
                    <h5>{(diff.length > 1 && !index) || !index ? 'Original logo' : 'New logo'}</h5>
                    <LogoBorder>
                        {!logosErrorList.includes(index) && logo !== null ? <img src={logo} alt={diff[index]} onError={() => {
                            onSetLogosErrorList(index)
                        }} /> : <Icon src={DefaultLogo} /> }
                    </LogoBorder>
                </LogoWrapper>
            )
        }
        {
            logos.length === 0 && (
                <LogoWrapper>
                    <h5>Original logo</h5>
                    <LogoBorder>
                        <Icon src={DefaultLogo} />
                    </LogoBorder>
                </LogoWrapper>
            )
        }
    </LogosList>
}

export default ImageField;