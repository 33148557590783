import React from 'react';
import styled from "styled-components";
import * as Sentry from "@sentry/react";

import { Icon } from "./Navigation/Sidenav";
import ErrImage from "../images/oops.svg";
import { useHistory } from "react-router-dom";
import { useCheckAuth } from "./Navigation/useCheckAuth";

const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;
`
const MessageHolder = styled.div`
  max-width: 75%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`
const TextHolder = styled.div`
`
const ImageHolder = styled.div`
    .errImage {
      width: 333px;
      height: 267px;
    }
`
const ErrorTitle = styled.h1`
  font-size: 84px;
  font-weight: 400;
`
const ErrorDescr = styled.p`
  font-size: 24px;
  font-weight: 400;
`
const ErrorRedirect = styled.p`
  font-size: 16px;
  font-weight: 400;
`
const Link = styled.a`
  cursor: pointer;
`
const ErrorMessage = ({
    title,
    descr
}) => {
    const history = useHistory();
    const {
        isVSourceAdminUser,
        isVLinkAnalyst,
        isVKeyAdminUser,
    } = useCheckAuth();

    const dashboardLink = () => {
        if (isVKeyAdminUser || isVSourceAdminUser || isVLinkAnalyst) {
            return "/dashboard";
        }
        return "/home";
    };
    const reload = () => {
        history.push(dashboardLink());
        window.location.reload();
    }
    return (
        <ErrorWrapper>
            <MessageHolder>
                <TextHolder>
                    <ErrorTitle>
                        {title}
                    </ErrorTitle>
                    <ErrorDescr>
                        {descr}
                    </ErrorDescr>
                    <ErrorRedirect>
                        Maybe you will find something suitable from the sidemenu or from the <Link onClick={reload}>dashboard</Link>
                    </ErrorRedirect>
                </TextHolder>
                <ImageHolder>
                    <Icon className="errImage" src={ErrImage} />
                </ImageHolder>
            </MessageHolder>
        </ErrorWrapper>
    )
}

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
      console.error("Error from ErrorBoundary: ", error);
      console.error("ErrorInfo: ", errorInfo)
    }
  
    // You can render any custom fallback UI
    render() {
      return(
        <Sentry.ErrorBoundary
            fallback={
                <ErrorMessage
                    title="Something went wrong"
                />
            }
            showDialog
        >
          {this.props.children}
        </Sentry.ErrorBoundary>
      )
      }
  }

  export default ErrorBoundary;