import React, { useState, useEffect, useContext, useMemo } from "react";
import { Storage } from "aws-amplify";
import styled from "styled-components";
import { useParams, useLocation } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";

import lockIcon from "../../images/ei_lock.svg";
import alertIcon from "../../images/alerts.svg";
import videoIcon from "../../images/video.svg";
import highlightIcon from "../../images/highlight.svg";
import hiddenFormPNG from "../../images/premium-content.png";
import uploadDocumentIcon from "../../images/UploadDocument.svg";
import { DocumentsBlock } from "./DocumentsBlock";
import { VideosBlock } from "./VideosBlock";
import PremiumBenefitBlock from "./PremiumBenefitBlock";
import NotificationsBlock from "./NotificationsBlock";
import { useVsourceResource } from "./hooks/useVsourceResource";

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    width: 25%;
    min-width: 420px;
    padding: ${(props) => (props.locked ? "20px" : "0px 0px")};
    flex-shrink: 0;
    position: relative;
    border: ${(props) => (props.locked ? "1px solid #B5B5C3" : "none")};
    border-radius: 12px;
    margin-top: ${(props) => props.withOffset ? '50px' : '20px'}
    background-color: ${(props) => (props.locked ? "#f1f1f1" : "transparent")};

    img {
        width: 100%;
    }
`;

const FormOverlay = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    padding-top: 100px;
    background-color: rgba(241, 243, 244, 0.8);
`;

const OverlayContent = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    width: 350px;
    margin: 0 auto;
    text-align: center;

    .lock {
        width: 80px;
    }

    h5 {
        margin: 0;
        font-size: 24px;
        text-align: center;
        span {
            color: #ffc30b;
        }
    }

    a {
        display: inline-block;
        margin: 20px 0 30px;
        text-decoration: underline;
    }
`;

const OverlayBenefits = styled.div`
    padding: 0 20px;
    font-size: 20px;
    div {
        display: flex;
        align-items: center;
        gap: 20px;
        margin: 20px 0;

        img {
            width: 24px;
        }

        span {
            font-size: 20px;
        }
    }
`;

const PremiumStyleWrapper = styled.div`
  position: relative;
  padding: 15px;
  border: 1px solid #FFC30B;
  border-radius: 12px;
  box-shadow: 0 16px 32px rgba(0, 0, 0, 0.08);
  overflow: hidden;
`;
const YellowBorder = styled.div`
  width: 100%;
  height: 7px;
  background-color: #FFC30B;
  position: absolute;
  top: 0;
  left: 0;
`
const PremiumTitle = styled.h4`
  display: flex;
  width: 100%;
  font-size: 20px;
  margin: 0 0 45px 0;
  padding: 10px 5px;
  border-bottom: 1px solid #FFC30B;
`

export default function PremiumForm({context, level}) {
    const { pathname } = useLocation();
    const {
        context: { user },
        isVLinkPremiumUser: _isVLinkPremiumUser,
        isVLinkAnalyst: _isVLinkAnalyst,
        isVLinkSuperUser: _isVLinkSuperUser,
        isVSourceUser: _isVSourceUser
    } = useContext(AuthContext);

    const permissions = {
        isVLinkPremiumUser: _isVLinkPremiumUser(user),
        isVLinkAnalyst: _isVLinkAnalyst(user),
        isVLinkSuperUser: _isVLinkSuperUser(user),
        isVSourceUser: _isVSourceUser(user)
    };

    const page = {
        isVendorPage: pathname.includes("/vlink/vendors/"),
        isProductPage: pathname.includes("/vlink/products/"),
        isVSourceVendorPage: pathname.includes("/vsource/vendors/"),
        isVSourceProductPage: pathname.includes("/vsource/product/"),
        isPreviewOrPublic: pathname.includes("/preview") || pathname.includes("/public")
    }

    const vsourceData = useVsourceResource(
        {level, premium: true}, 
        [permissions.isVLinkPremiumUser || permissions.isVLinkAnalyst || permissions.isVLinkSuperUser]
    );

    const isPremiumFormUnlocked = 
    (permissions.isVLinkPremiumUser || permissions.isVLinkAnalyst || permissions.isVLinkSuperUser) ||
    (context === 'PUBLIC' && permissions.isVSourceUser)
    ;

    const showNotifications = useMemo(() => {
        return ['VENDOR', 'VENDEX'].includes(context);
    }, [context])
    
    const showButtons = permissions.isVLinkPremiumUser && !page.isVSourceVendorPage && !page.isVSourceProductPage && !page.isPreviewOrPublic;
    if (isPremiumFormUnlocked) {
        return (
            <FormWrapper locked={false} withOffset={['PUBLIC', 'VENDOR_PREVIEW'].includes(context)}>
                <PremiumStyleWrapper>
                    <YellowBorder />
                    <PremiumTitle>
                        Vendor Content
                    </PremiumTitle>
                    <PremiumBenefitBlock
                        permissions={permissions}
                        level={level}
                        vsourceData={vsourceData}
                        page={page}
                        showButtons={showButtons}
                    />
                    <DocumentsBlock permissions={permissions} page={page} showButtons={showButtons}/>
                    <VideosBlock permissions={permissions} page={page} showButtons={showButtons}/>
                    {showNotifications && <NotificationsBlock
                        permissions={permissions}
                        level={level}
                        vsourceData={vsourceData}
                        page={page}
                    />}
                </PremiumStyleWrapper>
            </FormWrapper>
        );
    }

    return (
        <FormWrapper locked={true}>
            <LockedForm />
        </FormWrapper>
    );
}

const LockedForm = () => {
    const [marketingFileUrl, setMarketingFileUrl] = useState();

    useEffect(() => {
        Storage.get(`vlink/VendEx Solutions - VLink Overview.pdf`, {
            level: "public",
            expires: 60 * 60
        })
            .then((s3FileUrl) => {
                setMarketingFileUrl(s3FileUrl);
            })
            .catch((e) => console.error("Error fetching marketing PDF URL: ", e));
    }, []);

    return (
        <>
            <img src={hiddenFormPNG} alt="Premium Features" />
            <FormOverlay>
                <OverlayContent>
                    <img className="lock" src={lockIcon} alt="lock" />
                    <h5>
                        Unlock <span>V</span>Link Premium
                    </h5>
                    <OverlayBenefits>
                        <div>
                            <img src={alertIcon} alt="alert" />
                            <span>Company & Product Alerts</span>
                        </div>
                        <div>
                            <img src={uploadDocumentIcon} alt="upload document" />
                            <span>Upload documents</span>
                        </div>
                        <div>
                            <img src={videoIcon} alt="alert" />
                            <span>Upload videos</span>
                        </div>
                        <div>
                            <img src={highlightIcon} alt="alert" />
                            <span>Highlight benefits</span>
                        </div>
                    </OverlayBenefits>
                    {marketingFileUrl && (
                        <a href={marketingFileUrl} target={"_blank"} rel="noreferrer">
                            Read more about the membership
                        </a>
                    )}
                    <p style={{ color: "#2564BB", fontWeight: 600 }}>Contact your VendEx Representative for access.</p>
                </OverlayContent>
            </FormOverlay>
        </>
    );
};
