import React, { useCallback, useContext, useState, useEffect, useMemo } from "react";
import styled, { ThemeContext } from "styled-components";
import Select from "react-select";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import _get from "lodash/get";
import { Button, Modal, Popup } from "semantic-ui-react";
import { useQuery, gql } from "@apollo/client";

import VendexLogo from "../VendexLogo";
import Theme from "../../styles/theme";

import { AuthContext } from "../../context/AuthContext";
import { VsourceContext } from "../../context/vsource/vsourceContext";

import KebabMenu from "../../images/KebabMenu.svg";
import ProfileIcon from "../icons/ProfileIcon";

import { useCheckAuth } from "./useCheckAuth";
import Notification from "../Notification";
import SetupMFA from "../profile/SetupMFA";
import CloseIcon from "../icons/CloseIcon";

export const Icon = styled.img`
    height: 2rem;
    margin-right: 1rem;
`;

const Topbar = styled.div`
    height: 5rem;
    width: 100%;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: 1px solid ${Theme.gray1};
    padding-right: 20px;
`;

export const LogoWrapper = styled.div`
    margin: 10px 0 0 10px;
    height: 40px;
    cursor: pointer;
`;

export const TopWrapper = styled.div`
    height: 70px;
    display: flex;
`;

const ProfileWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: ${({ theme }) => theme.smMetric(4)}px;
    font-size: 16px;
    width: auto;
`;
const ProfileText = styled.span`
    width: 100%;
    white-space: nowrap;
`;

export const ElementsWrapper = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
`;

/* const Dropdown = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`; */

const DropdownItem = styled.div`
    font-weight: 600;
    font-size: 1.2rem;
    color: black;
    width: 100%;
    height: 3rem;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    &:hover {
        background-color: ${Theme.gray4};
    }
`;

const DescribedDropdownDiv = styled.div`
    display: flex;
    align-items: center;
    margin-right: 1rem;

    & > h4 {
        margin: 0 1rem;
    }
`;

const DropdownName = styled.div`
    font-weight: 600;
    font-size: 1.2rem;
    color: black;
    margin-right: 1rem;
`;

/*
const AccountSettingModal = styled.div`
  width: 400px;
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

 */

export const AccountSettingModal = styled(Modal)`
    position: fixed;
    resize: ${props => props.resizable ? 'both' : 'none'};
    overflow-x: hidden;
    max-width: ${props => props.resizable ? 'none' : (props.wider ? '1280px' : '1000px')};
    min-width: ${props => props.wider ? '80%' : 'auto'};
    max-height: 90vh;
    overflow-y: scroll;

    &.ui>.close.icon {
        top: -0.5rem;
        right: -0.5rem;
        color: #000;
    }
`;

const ModalCloseButton = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    
    height: 20px;
    width: 20px;
    
    top: 20px;
    right: 10px;
    
    z-index: 1000;
`;

export const Profile = withRouter(({ history, context, dispatch, logOut }) => {
    const { setVendorProductSearchTerm, clearVendorSearch } = useContext(VsourceContext);

    const handleLogOut = useCallback(async () => {
        try {
            const data = await logOut();
            dispatch({ type: "LOGOUT" });
            history.push("/login");
            console.log("handleLogOut data ", data);
        } catch (err) {
            console.log("handleLogOut err ", err);
        } finally {
            // Clear vendor product search input
            setVendorProductSearchTerm(null, null);
            clearVendorSearch();
        }
    }, [context.user]);

    const [showMFAModal, setShowMFAModal] = useState(false);

    const userNameValue = useMemo(() => {
        const familyName = _get(context, "user.attributes.family_name", "");
        const givenName = _get(context, "user.attributes.given_name", "");
        const email = _get(context, "user.attributes.email", "");
        if (!!familyName && !!givenName) {
            return `${givenName} ${familyName}`
        }
        return !!givenName || !!familyName ? `${givenName} ${familyName}` : email;
    }, [context.user]);

    const style = {
        zIndex: 9999,
    };

    return (
        <ProfileWrapper>
            <Popup
                trigger={
                    <span style={{cursor: "pointer", display: "flex" }}>
                        <ProfileIcon style={{ marginRight: 16, flex: `0 0 24px` }} />
                        <ProfileText>
                            {userNameValue}
                        </ProfileText>
                    </span>
                }
                content={<>
                    <Button color="blue" content="Setup MFA" onClick={() => setShowMFAModal(true)} />
                    <Button color="red" content="Sign out" onClick={handleLogOut} />
                </>}
                on="click"
                position="bottom center"
                style={style}
            />
            <AccountSettingModal open={showMFAModal} size="small">
                <ModalCloseButton onClick={() => setShowMFAModal(false)}>
                    <CloseIcon />
                </ModalCloseButton>
                <SetupMFA />
            </AccountSettingModal>
        </ProfileWrapper>
    );
});

const DescribedDropdown = ({ name, options, selectedValue, dispatch, defaultValue, data, history }) => {
    const themeContext = useContext(ThemeContext);

    const _defaultValue = useMemo(() => {
        const vendor = options.filter((opt) => opt.value === defaultValue);
        if (vendor.length > 0) {
            return vendor[0];
        }
        return {};
    }, [defaultValue, options]);

    // const _value = useMemo(()=>{
    //     return options.find((option) => option.value === selectedValue)
    // },[selectedValue, options])

    const onVendorChange = (selection) => {
        const value = _get(selection, "value", '');
        const selected = data.filter((vendor) => {
            return vendor.id === value
        });

        if (selected.length > 0) {
            dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: selected[0] });
            // If the user is not on the dashboard then redirect back to VLink dashboard
            if (history?.location?.pathname !== "/vlink/dashboard") {
                history.push("/vlink/dashboard");
            }
        }
    };

    const dropDownStyles = {
        option: (styles, state) => {
            const background = () => {
                if (state.isSelected) return themeContext.gray3;
                else if (state.isFocused) return "transparent";
                return styles.backgroundColor;
            };
            return {
                ...styles,
                "&:hover": {
                    backgroundColor: themeContext.gray1,
                },
                backgroundColor: background(),
                color: "hsl(0, 0%, 20%)",
            };
        },
        control: (styles, state) => ({
            ...styles,
            minWidth: "200px",
            border: "none",
            boxShadow: "none",
        }),
        singleValue: (styles) => ({
            ...styles,
            transform: "none",
            position: "relative",
        }),
        menu: (styles) => ({
            ...styles,
            minWidth: "200px",
            maxWidth: "200px",
        }),
    };

    if (!defaultValue) {
        return null;
    }

    return (
        <DescribedDropdownDiv>
            <h4>{name}:</h4>
            <Select
                options={options}
                onChange={(e) => onVendorChange(e)}
                isClearable
                defaultValue={_defaultValue}
                styles={dropDownStyles}
                // value={_value}
            />
        </DescribedDropdownDiv>
    );
};

export const Topnav = ({ children }) => {
    return <Topbar>{children}</Topbar>;
};

const VendorByProductQuery = gql`
    query Query($productId: ID!) {
    vsource {
      product(id: $productId) {
        id
        vendor {
          id
          full_legal_name
        }
      }
    }
  }
`;

export const TopNavigation = () => {
    const { context, dispatch, logOut } = useContext(AuthContext);

    const [vendorList, setVendorList] = useState(null);
    const [selectedVendor, setSelectedVendor] = useState(context?.activeVendor?.id);

    const { isVKeyAdminUser, isVSourceAdminUser, isVLinkAnalyst, isVLinkSuperUser } = useCheckAuth();
    const history = useHistory();
    const { pathname } = useLocation();

    // Get the (product or vendor) ID from the URL param (e.g. /vlink/products/8)
    const getParamId = () => {
        const pathArray = pathname.split('/')
        const Id = Array.isArray(pathArray) ? pathArray[pathArray.length - 1] : undefined;
        return Id;
    }

    const isVlinkAnalystOrSuperUser = () => {
        const regex = /\/vlink\/.*/;
        return regex.test(pathname) && (!!isVLinkAnalyst || !!isVLinkSuperUser);
    }

    // Query to get the vendor by product ID.
    // Used only to get the vendor data when opening a product page with a direct URL
    const { loading: vendorByProductLoading, error: vendorByProductError, data: vendorByProduct } = useQuery(VendorByProductQuery, {
        skip: !pathname.includes('/vlink/products/') || context?.availableVendors?.length === 0 || !isVlinkAnalystOrSuperUser(),
        variables: { productId: getParamId() }
    });

    if(vendorByProductError){
        console.error("Error fetching vendor by product: ", vendorByProductError);
    }


    useEffect(()=>{
        const Id = getParamId();

        // When using the direct URL use the vendor ID from Url param and update the vendors dropdown selection
        if(pathname.includes('/vlink/vendors/') && context?.availableVendors?.length > 0){
            if(!Id || context?.activeVendor?.id === Id){
                return;
            }

            const selected = context.availableVendors.find((vendor) => {
                return vendor?.id === Id;
            });

            if(selected){
                dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: selected });
            }
        }
    },[pathname, context])

    useEffect(()=>{
        const Id = getParamId();

        if(!Id){
            return;
        }

        // When using the direct URL use the product ID from Url param and update the vendors dropdown selection
        if(
            pathname.includes('/vlink/products/') &&
            context?.availableVendors?.length > 0 &&
            !vendorByProductLoading
            ){

            const vendorRes = vendorByProduct?.vsource?.product?.vendor;

            // If this vendor is already selected
            if(context?.activeVendor?.id === vendorRes?.id){
                return;
            }

            const selected = context.availableVendors.find((vendor) => {
                return vendor?.id === vendorRes?.id;
            });

            if(selected){
                dispatch({ type: "SET_ACTIVE_VENDOR", activeVendor: selected });
            }
        }
    },[pathname, context, vendorByProductLoading, vendorByProduct])

    useEffect(() => {
        const vendors = context.availableVendors;
        setVendorList(
            vendors.map((vendor) => {
                return {
                    key: vendor.id,
                    text: vendor.full_legal_name,
                    value: vendor.id,
                    label: vendor.full_legal_name,
                };
            })
        );
    }, [context]);

    // Set the default value once the vendorList is set
    useEffect(() => {
        if (context.activeVendor !== selectedVendor && vendorList !== null) {
            setSelectedVendor(context?.activeVendor?.id);
        }
    }, [vendorList, context, selectedVendor]);

    const dashboardLink = () => {
        if (isVKeyAdminUser || isVSourceAdminUser || isVLinkAnalyst) {
            return "/dashboard";
        }
        return "/home";
    };

    return (
        <Topnav>
            {
                <>
                    <TopWrapper>
                        <LogoWrapper onClick={() => history.push(dashboardLink())}>
                            <VendexLogo />
                        </LogoWrapper>
                    </TopWrapper>
                    <ElementsWrapper>
                        {isVlinkAnalystOrSuperUser() && vendorList !== null && (
                            <DescribedDropdown
                                name="Vendor"
                                options={vendorList}
                                dispatch={dispatch}
                                defaultValue={selectedVendor}
                                data={context.availableVendors}
                                history={history}
                                selectedValue={selectedVendor}
                            />
                        )}
                        <Notification />
                        <Profile context={context} dispatch={dispatch} logOut={logOut} />
                    </ElementsWrapper>
                </>
            }
        </Topnav>
    );
};
