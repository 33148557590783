import { useMutation } from '@apollo/client';
import React, { useState, useReducer, useEffect, useCallback } from 'react';
import { Button } from 'semantic-ui-react';
import styled from 'styled-components';
import _get from 'lodash/get';
import { getTime } from 'date-fns';


import {
	insertProductRevision,
	insertVendorRevision,
	updateProductRevision,
	updateVendorRevision,
	uploadVendorLogo
} from '../../graphql/customMutations';
import rowReducer from './reducers/FormRowReducer';
import Spinner from './Spinner';
import { buildInsertRevisionInput, buildUpdateRevisionInput, shouldDisplayRow } from '../../helpers/vlink/revisionHelpers';
import { useAlerts } from '../../hooks/useAlerts';
import FormRow from './FormRow';

export const FieldsetWrapper = styled.div`
	position: relative;
	padding: 5px 0;
	background-color: inherit;
	display: flex;
	flex-direction: ${props => props.labelLeft ? 'row' : 'column'};
	// justify-content: space-between;
	align-items: flex-start;

	fieldset {
		display:flex;
		gap: 50px;
		padding: 0;
		width: 100%;
	}

	.hidden {
		display: none;
	}
`;

export const FieldsetTitle = styled.div`
	color: #80808F;
	flex-shrink: 0;
	padding: 5px 2px 2px;
	margin: 0;
	margin-left: -2px;
	width: 200px;
	position: relative;
	margin-bottom: ${props => props.hasSubtitle ? '20px' : '15px'};

	h5  {
		margin-bottom: ${props => props.hasSubtitle ? '5px' : '0'};
	}

	span {
		position: absolute;
		width: 500px;
		font-size: 12px;
		font-style: italic;
	}

`;

export const FieldsetContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${props => props.narrow ? '10px' : '20px'};
	flex-grow: 1;

	& div.record.show-border {
		border-bottom: 1px solid rgba(181, 181, 196, 0.6);
	}

	&.location {
		flex-direction: row;
		flex-wrap: wrap;
	}

	.location-card {
		flex-direction: column;
		width: 300px !important;
		border: 1px solid #E9E9ED;
		border-radius: 4px;
		padding: 15px;
	}
`;

const FieldsetActions = styled.div`
	display: flex;
	justify-content: flex-end;
	align-self: flex-end;
	// border-top: 2px solid #B5B5C33C;
	padding-top: 10px;


	button {
		padding: 6px 12px !important;
		&.white {
			background-color: white;
			border: 1px solid #D9D9D9;

			&:hover {
				color: #FFA800;
				border-color: #FFA800;
				background-color: white;
			}
		}
	}
`;

export default function FormRowList({ 
	targetId,
	level,
	vsourceData,
	title = '',
	subTitle = '',
	rowListData,
	multiple = false,
	group,
	context,
	locked,
	autoCertifyGroup,
	untouchedRows,
	setUntouchedRows,
	rowName,
	classifiers,
	onExpand,
	show
}) {
	const [rowList, dispatch] = useReducer(rowReducer, buildInitialState(rowListData));
	const [requestQueue, setRequestQueue] = useState([]); //{type: "CREATE", rowIndex: 0}
	const [loading, setLoading] = useState(false);
	const { newErrorAlert } = useAlerts();
	// const [addable, setAddable] = useState(multiple);

	const [insertRevision] = useMutation(level === 'VENDOR' ? insertVendorRevision : insertProductRevision);
	const [updateRevision] = useMutation(level === 'VENDOR' ? updateVendorRevision : updateProductRevision);
	const [uploadLogoMutation] = useMutation(uploadVendorLogo);
	const uploadNewLogo = useCallback(async(file, index) => {
		try {
			const vendorId = _get(rowList, `[${index}].vendorId`);
			const res = await uploadLogoMutation({
				variables: {
					uploadLogoRequestId: vendorId,
					input: {
						file,
					},
				},
			});
			return _get(res, 'data.vlink.uploadLogoRequest.file.fileName');
		} catch (e) {
			throw new Error(e);
		}
	},
[
		rowList
	]
	);
	useEffect(() => {
		if(context === 'VENDEX') {
			dispatch({type: 'UPDATE_FIELDSET', data: buildInitialState(rowListData)});
		} else {
			if(rowListData[0].tableName === 'customer_support') {
				dispatch({type: 'UPDATE_FIELDSET', data: buildInitialState(rowListData)});
			}
		}
	}, [rowListData, context]);

	useEffect(() => {
			if (requestQueue.length) {
				setLoading(true);
				requestQueue.forEach(async (action) => {
					let revisionId;
					const isImage = _get(rowList, `[${action.rowIndex}].fields[0].type`) === 'image';
					try {
						switch (action.type) {
							case 'CREATE': {
								let fileName;
								let row = {
									...rowList[action.rowIndex]
								}
								if (isImage) {
									const file = _get(rowList, `[${action.rowIndex}].fields[0].latestNewValue`);
									if (file && file.name) {
										fileName = await uploadNewLogo(file, action.rowIndex);
										row.fields[0].latestNewValue = fileName
									}
								}
								let res = await insertRevision({
									variables: {
										[level === 'VENDOR' ? 'vendorId' : 'productId']: targetId,
										input: buildInsertRevisionInput(row, context, level)
									}
								})
								revisionId = level === 'VENDOR' ?
									res.data.vlink.insertVendorRevision.vendorRevisionId :
									res.data.vlink.insertProductRevision.productRevisionId;
								
								dispatch({
									type: 'CHANGE_ROW_VALUE',
									rowIndex: action.rowIndex,
									key: ['revisionId', 'latestRevision.revisionId', 'latestRevision.vendorSubmittedAt'],
									value: [
										Array(2).fill(revisionId),
											null
										].flat()
										});

								// return revisionId;
								break;
							}
							case 'UPDATE': {
								let fileName;
								let row = {
									...rowList[action.rowIndex]
								}
								if (isImage) {
									const lastNewValue = _get(rowList, `[${action.rowIndex}].fields[0].latestNewValue`);
									const submittedVendexValue = _get(rowList, `[${action.rowIndex}].fields[0].submittedVendexValue`);
									const latestNewValue = _get(rowList, `[${action.rowIndex}].fields[0].latestNewValue`);

									const file = submittedVendexValue && submittedVendexValue.size ? submittedVendexValue : lastNewValue;
									if ((submittedVendexValue && submittedVendexValue.size)) {
										fileName = await uploadNewLogo(file, action.rowIndex);
										row.fields[0].submittedVendexValue = fileName
									} else if (latestNewValue && latestNewValue.size) {
										fileName = await uploadNewLogo(file, action.rowIndex);
										row.fields[0].latestNewValue = fileName
									}
								}
								await updateRevision({
									variables: {
										revisionId: rowList[action.rowIndex].revisionId,
										input: buildUpdateRevisionInput(row, context)
									}
								})
								break;
							}
							default: {
								await updateRevision({
									variables: {
										revisionId: rowList[action.rowIndex].revisionId,
										input: buildUpdateRevisionInput(rowList[action.rowIndex], context)
									}
								})
								break;
							}
						}
						if(rowList[action.rowIndex].certified) {
							dispatch({ type: 'RESET_ROW', rowIndex: action.rowIndex })
						}
						action.cb && action.cb(revisionId);
					} catch (e) {
						newErrorAlert(e.message ?? `Error ${action.type === 'UPDATE' ? 'updating' : 'creating'} the revision`);
						console.log(e.message);
						dispatch({ type: 'RESET_ROW', rowIndex: action.rowIndex })
					} finally {
						setLoading(false);
					};
			})	
			setRequestQueue([]);
		}
	}, [targetId, level, rowList, requestQueue, context, insertRevision, updateRevision]);

	const renderSort = (rowList) => {
		const template = rowList.find(row => row.status === 'TEMPLATE');
		const sortArrayConditions = template?.sortByField;
		const sortedRowList = rowList.map((row, rowIndex) => ({ key: rowIndex, data: row }));

		if (sortArrayConditions && sortArrayConditions.length) {
			sortedRowList.sort((a, b) => {
				let compareLeftValue;
				let compareRightValue;

				sortArrayConditions.forEach((condition) => {
					let leftValue = _get(a, `data.${condition}`);
					let rightValue = _get(b, `data.${condition}`);

					if (leftValue && !compareLeftValue) {
						compareLeftValue = leftValue;
					}

					if (rightValue && !compareRightValue) {
						compareRightValue = rightValue;
					}
				})

				return getTime(compareLeftValue) < getTime(compareRightValue) ? 1 : -1;
			});
		}

		return sortedRowList.map((row) => (
			shouldDisplayRow(row.data, context) &&
			<div
				key={row.key}
				style={{width: '100%'}}
				className={rowList[0].tableName === 'location' ? 'location-card' : ''}
			>
				<FormRow
					rowList={rowList}
					vsourceData={vsourceData}
					row={row.data}
					rowIndex={row.key}
					context={context}
					locked={locked}
					dispatch={dispatch}
					requestQueue={requestQueue}
					setRequestQueue={setRequestQueue}
					group={group}
					autoCertifyGroup={autoCertifyGroup}
					untouchedRows={untouchedRows}
					setUntouchedRows={setUntouchedRows}
					rowName={rowName}
					classifiers={classifiers}
					onExpand={onExpand}
					level={level}
					title={title}
					targetId={targetId}
				/>
				{
					context === 'VENDOR' &&
					row.data.submittedRevision?.vendexRespondedAt &&
					row.data.submittedRevision?.vendexActionTypeCode === 'REJECTED' &&
					<FormRow
						rowList={rowList}
						vsourceData={vsourceData}
						row={row.data}
						rowIndex={row.key}
						context={context}
						locked={locked}
						dispatch={dispatch}
						requestQueue={requestQueue}
						setRequestQueue={setRequestQueue}
						untouchedRows={untouchedRows}
						setUntouchedRows={setUntouchedRows}
						withActions={false}
						responseRow={true}
						onExpand={onExpand}
						level={level}
						title={title}
						group={group}
						targetId={targetId}
						autoCertifyGroup={autoCertifyGroup}
					/>
				}
			</div>
		))
	}

	const emptyData = !rowListData[1]?.vsourceFieldValue && rowListData.length <= 2;

	return (
		<FieldsetWrapper labelLeft={rowList[0].labelLeft} className={`${show ? '' : 'hidden'} ${emptyData ? 'empty-data' : 'has-data'}`}>
			<Spinner loading={loading} />
			{/* {rowList[0].tableName !== 'location' && rowList[0].tableName !== 'customer_support' && */}
				<FieldsetTitle hasSubtitle={context === 'VENDOR' && subTitle}>
					<h5>{title}</h5>
					<span>{context === 'VENDOR' && subTitle}</span>
				</FieldsetTitle>
			{/* } */}
			<fieldset>
				<FieldsetContent narrow={rowList[0].tableName !== 'product_vidsv'} className={rowList[0].tableName === 'location' ? 'location' : ''}>
					{renderSort(rowList)}
				</FieldsetContent>
			</fieldset>
			{multiple && context === 'VENDOR' && !locked &&
				<FieldsetActions>
					<Button onClick={() => dispatch({ type: 'ADD_ROW' })} className='white'>
						{rowList[0].tableName === 'customer_support' ? 'Add Support Info' : rowList[0].tableName === 'product_vidsv' ? 'Add Grouping' :'Add New Row'}
					</Button>
				</FieldsetActions> 
			}
		</FieldsetWrapper>
	)
}

function buildInitialState(rowListData) {
	return rowListData.map(function deepClone(data) {
		let nextData =  {
			...data,
			status: data.status || 'CREATED',
			fields: data.fields.map(field => ({ ...field })),
			latestRevision: data.latestRevision && JSON.parse(JSON.stringify(data.latestRevision)),
			submittedRevision: data.submittedRevision && JSON.parse(JSON.stringify(data.submittedRevision)),
		}

		return nextData;
	});
}



