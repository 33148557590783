import {useApolloClient} from "@apollo/client";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { List } from "semantic-ui-react";
import styled from "styled-components";
import { NotificationContext } from "../context/notification/NotificationContext";
import NotificationIcon from "../images/Bell.svg";


const NotificationWrapper = styled.div`
    height: 2rem;
    margin-right: 1rem;
    position: relative;
`;

const NotificationsHeader = styled.div`
    position: relative;
    cursor: pointer;
    span {
        position: absolute;
        right: -50%;
        top: -30%;
        display: inline-block;
        padding: 0.1rem 0.5rem;
        background-color: green;
        color: white;
        font-size: 0.8rem;
        border-radius: 50%;
    }
`;

const Icon = styled.img`
    width: 100%;
`;

const StyledList = styled(List)`
    position: absolute;
    top: 2rem;
    right: 0;
    width: 30rem;
    padding: 1.5rem !important;
    min-height: 13rem;
    max-height: 39rem;
    border: 1px solid lightgray;
    border-radius: 5px;
    background-color: #fff;
    overflow-y: scroll;

    display: none;
    &.open {
        display: block;
    }

    i {
        color: #556983;
    }
`;

const StyledDescription = styled(List.Description)`
    & div {
        margin-top: 0.5rem;
        color: black;
    }
    &>div:last-child {
        display: flex;
        justify-content: space-between;
        color: gray;
        font-size: 0.9rem;
    }
`;

const NoNotifications = styled.div`
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
`;

export default function Notification() {
    const [opened, setOpened] = useState(false);
    const disabled = process.env.REACT_APP_BUILD_ENV === 'demo' || 
        process.env.REACT_APP_BUILD_ENV === 'prod';

    const {
        context: { notifications, newNotificationsCount },
        dispatch,
        fetchNotifications
    } = useContext(NotificationContext);

    function headerClickHandler() {
        setOpened(!opened);
        if(newNotificationsCount) {
            dispatch({type: 'SET_NEW_NOTIFICATIONS_COUNT', payload: 0})
        }
    }

    useEffect(() => {
        if(disabled) return;

        fetchNotifications();
    }, []);

    useEffect(() => {
        if(disabled) return;
        
        let interval = setInterval(() => {
            fetchNotifications()
        }, 300000)
        
        return () => clearTimeout(interval);
    });

    return (
        <NotificationWrapper>
            {!disabled && 
                <NotificationsHeader onClick={headerClickHandler}>
                    <Icon src={NotificationIcon} />
                    <span>{newNotificationsCount}</span>
                </NotificationsHeader>
            }
            <StyledList divided relaxed className={opened ? 'open' : ''}>
                {notifications.map((notification, index) => (
                     <List.Item key={index}>
                        <List.Icon name='inbox' size='large'/>
                        <List.Content>
                            <List.Header>{notification.title}</List.Header>
                            <StyledDescription>
                                <div>
                                    {notification.description}
                                </div>
                                <div>
                                    <span>{notification.product.name || notification.vendor.name}</span>
                                    <span>{notification.alertSince && format(new Date(notification.alertSince) || new Date(), 'DD-MMM-YYYY H:mm')}</span>
                                </div>
                            </StyledDescription>
                        </List.Content>
                    </List.Item>
                ))}

                {notifications.length === 0 && <NoNotifications>No notifications</NoNotifications>} 
            </StyledList>
        </NotificationWrapper>
    )
}