import React, { useCallback, useState, useContext, useEffect, useRef } from "react";
import styled from 'styled-components';
import { AuthContext } from "../../context/AuthContext";

const Iframe = styled.iframe`
  width: 100%;
`;

const USER_REGISTRY_URL = process?.env?.REACT_APP_USER_REGISTRY_URL || null;

const UserRegistry = () => {
    const iframe = useRef();

    const { getCurrentJWT } = useContext(AuthContext);

    const sendToken = useCallback(() => {
        getCurrentJWT().then(t => {
            if (iframe.current?.contentWindow) {
                iframe.current.contentWindow.postMessage({
                    token: `Bearer ${t}`
                }, '*');
            }
        });
    });

    const handleMessage = useCallback(event => {
        const registryUrl = new URL(USER_REGISTRY_URL);
        const originUrl = new URL(event.origin);

        if (event.data === 'tokenRequest' && originUrl.origin === registryUrl.origin) {
            sendToken();
        }
    }, []);

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        }
    }, []);

    if (USER_REGISTRY_URL === null) {
        return <span>No URL defined {process.env.USER_REGISTRY_URL}</span>
    }

    return <Iframe ref={iframe} src={USER_REGISTRY_URL} />
}

export default UserRegistry;