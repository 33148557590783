import React from "react";
import styled from "styled-components";
import { isValid, format } from "date-fns";
import Spinner from "./vlink/Spinner";
import { FieldsetWrapper, FieldsetTitle, FieldsetContent } from "../components/vlink/FormRowList"


const ValueStyles = styled.div`
  display: flex;
  gap: 50px;
  width: 100%;
  padding: 5px 2px 2px;
`;

const PublicFormRow = ({
    title,
    value,
    show,
    loading,
}) => {

    const emptyData = !value;

    return (
        <FieldsetWrapper labelLeft  className={`${show ? '' : 'hidden'} ${emptyData ? 'empty-data' : 'has-data'}`}>
            <Spinner loading={loading} />
            <FieldsetTitle>
                <h5>{title}</h5>
            </FieldsetTitle>
            <fieldset>
                <FieldsetContent narrow={true}>
                    <ValueStyles>
                        {isValid(new Date(value)) && value ? format(value, 'DD-MMM-YYYY') : value}
                    </ValueStyles>
                </FieldsetContent>
            </fieldset>
        </FieldsetWrapper>
    )
}

export default PublicFormRow;