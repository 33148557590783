/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const vendors = /* GraphQL */ `
  query Vendors(
    $limit: Int
    $page: Int
    $cataloged: Boolean
    $orderBy: String
    $order: String
  ) {
    vendors(
      limit: $limit
      page: $page
      cataloged: $cataloged
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        vendor_id_code
        last_reviewed_at
        last_reviewed_by
        last_reviewed_by_code
        last_published_at
        last_published_by
        last_published_by_code
        assigned_analyst
        assigned_analyst_code
        next_review_in_days
        next_review_in_days_code
        next_review_at
        full_legal_name
        historical_names
        alias_names
        url
        hierarchy_code
        status_code
        vendor_logo_filename
        ultimate_parent_id
        ultimate_parent_vendor_id_code
        ultimate_parent_hierarchy_code
        ultimate_parent_name
        immediate_parent_id
        immediate_parent_vendor_id_code
        immediate_parent_name
        immediate_parent_hierarchy_code
        brief_description
        preview_brief_description
        detailed_description
        year_founded
        company_type_code
        number_of_offices
        company_size_code
        headquarters
        lei_code
        created_by
        created_at
        updated_by
        updated_at
        products {
          id
          vendor_product_id
          name
          vendor_specific_product_details
          brief_description
          preview_brief_description
          detailed_description
          url
          demo_url
          status
          status_code_original
          product_id_code
          product_change {
            id
            change_time
            change_notes
            change_type
            change_type_original
          }
          vendor {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_name
            immediate_parent_hierarchy_code
            brief_description
            preview_brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            created_by
            created_at
            updated_by
            updated_at
            products {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            financials {
              id
              year
              revenue
              period_end_date
              revenue_amount
              currency
              currency_code
              created_at
              updated_at
            }
            tickers {
              id
              stock_exchange
              ticker_symbol
              created_by
              created_at
              updated_by
              updated_at
            }
            officers {
              id
              business_role
              business_role_code
              full_name
              phone_number
              email_address
              linkedin_url
              comment
              business_role_additional
            }
            social_media {
              id
              social_media_type
              social_media_type_code
              url
            }
            locations {
              id
              name
              type
              type_code
              region
              region_code
              division
              city
              country
              country_code
              street_address_1
              street_address_2
              state_province
              postal_code
              phone_number
            }
            market_segments {
              code
              value
            }
            investor_relations {
              id
              contact_person
              phone_number
              email_address
              blog_url
            }
            corporate_actions {
              id
              type_code
              type
              action_time
              description
            }
            vendor_stage_code
            vendor_stage
            premium_benefit
          }
          premium_benefit
        }
        financials {
          id
          year
          revenue
          period_end_date
          revenue_amount
          currency
          currency_code
          created_at
          updated_at
        }
        tickers {
          id
          stock_exchange
          ticker_symbol
          created_by
          created_at
          updated_by
          updated_at
        }
        officers {
          id
          business_role
          business_role_code
          full_name
          phone_number
          email_address
          linkedin_url
          comment
          business_role_additional
        }
        social_media {
          id
          social_media_type
          social_media_type_code
          url
        }
        locations {
          id
          name
          type
          type_code
          region
          region_code
          division
          city
          country
          country_code
          street_address_1
          street_address_2
          state_province
          postal_code
          phone_number
          customer_support {
            id
            phone_number
            support_hours
            email_address
            support_portal_url
            time_zone
            time_zone_code
          }
        }
        market_segments {
          code
          value
        }
        investor_relations {
          id
          contact_person
          phone_number
          email_address
          blog_url
        }
        corporate_actions {
          id
          type_code
          type
          action_time
          description
        }
        vendor_stage_code
        vendor_stage
        premium_benefit
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const vendor = /* GraphQL */ `
  query Vendor($id: ID!) {
    vendor(id: $id) {
      id
      vendor_id_code
      last_reviewed_at
      last_reviewed_by
      last_reviewed_by_code
      last_published_at
      last_published_by
      last_published_by_code
      assigned_analyst
      assigned_analyst_code
      next_review_in_days
      next_review_in_days_code
      next_review_at
      full_legal_name
      historical_names
      alias_names
      url
      hierarchy_code
      status_code
      vendor_logo_filename
      ultimate_parent_id
      ultimate_parent_vendor_id_code
      ultimate_parent_hierarchy_code
      ultimate_parent_name
      immediate_parent_id
      immediate_parent_vendor_id_code
      immediate_parent_name
      immediate_parent_hierarchy_code
      brief_description
      preview_brief_description
      detailed_description
      year_founded
      company_type_code
      number_of_offices
      company_size_code
      headquarters
      lei_code
      created_by
      created_at
      updated_by
      updated_at
      products {
        id
        vendor_product_id
        name
        vendor_specific_product_details
        brief_description
        preview_brief_description
        detailed_description
        url
        demo_url
        status
        status_code_original
        product_id_code
        product_change {
          id
          change_time
          change_notes
          change_type
          change_type_original
        }
        vendor {
          id
          vendor_id_code
          last_reviewed_at
          last_reviewed_by
          last_reviewed_by_code
          last_published_at
          last_published_by
          last_published_by_code
          assigned_analyst
          assigned_analyst_code
          next_review_in_days
          next_review_in_days_code
          next_review_at
          full_legal_name
          historical_names
          alias_names
          url
          hierarchy_code
          status_code
          vendor_logo_filename
          ultimate_parent_id
          ultimate_parent_vendor_id_code
          ultimate_parent_hierarchy_code
          ultimate_parent_name
          immediate_parent_id
          immediate_parent_vendor_id_code
          immediate_parent_name
          immediate_parent_hierarchy_code
          brief_description
          preview_brief_description
          detailed_description
          year_founded
          company_type_code
          number_of_offices
          company_size_code
          headquarters
          lei_code
          created_by
          created_at
          updated_by
          updated_at
          products {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          financials {
            id
            year
            revenue
            period_end_date
            revenue_amount
            currency
            currency_code
            created_at
            updated_at
          }
          tickers {
            id
            stock_exchange
            ticker_symbol
            created_by
            created_at
            updated_by
            updated_at
          }
          officers {
            id
            business_role
            business_role_code
            full_name
            phone_number
            email_address
            linkedin_url
            comment
            business_role_additional
          }
          social_media {
            id
            social_media_type
            social_media_type_code
            url
          }
          locations {
            id
            name
            type
            type_code
            region
            region_code
            division
            city
            country
            country_code
            street_address_1
            street_address_2
            state_province
            postal_code
            phone_number
            customer_support {
              id
              phone_number
              support_hours
              email_address
              support_portal_url
              time_zone
              time_zone_code
            }
          }
          market_segments {
            code
            value
          }
          investor_relations {
            id
            contact_person
            phone_number
            email_address
            blog_url
          }
          corporate_actions {
            id
            type_code
            type
            action_time
            description
          }
          vendor_stage_code
          vendor_stage
          premium_benefit
        }
        premium_benefit
      }
      financials {
        id
        year
        revenue
        period_end_date
        revenue_amount
        currency
        currency_code
        created_at
        updated_at
      }
      tickers {
        id
        stock_exchange
        ticker_symbol
        created_by
        created_at
        updated_by
        updated_at
      }
      officers {
        id
        business_role
        business_role_code
        full_name
        phone_number
        email_address
        linkedin_url
        comment
        business_role_additional
      }
      social_media {
        id
        social_media_type
        social_media_type_code
        url
      }
      locations {
        id
        name
        type
        type_code
        region
        region_code
        division
        city
        country
        country_code
        street_address_1
        street_address_2
        state_province
        postal_code
        phone_number
        customer_support {
          id
          phone_number
          support_hours
          email_address
          support_portal_url
          time_zone
          time_zone_code
        }
      }
      market_segments {
        code
        value
      }
      investor_relations {
        id
        contact_person
        phone_number
        email_address
        blog_url
      }
      corporate_actions {
        id
        type_code
        type
        action_time
        description
      }
      vendor_stage_code
      vendor_stage
      premium_benefit
    }
  }
`;
export const vendorsBy = /* GraphQL */ `
  query VendorsBy(
    $param: String!
    $value: String!
    $limit: Int
    $page: Int
    $cataloged: Boolean
    $orderBy: String
    $order: String
  ) {
    vendorsBy(
      param: $param
      value: $value
      limit: $limit
      page: $page
      cataloged: $cataloged
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        full_legal_name
        brief_description
        vendor_stage
        vendor_id_code
        assigned_analyst
        last_published_at
        last_reviewed_at
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const product = /* GraphQL */ `
  query Product($id: ID!) {
    product(id: $id) {
      id
      vendor_product_id
      name
      vendor_specific_product_details
      brief_description
      preview_brief_description
      detailed_description
      url
      demo_url
      status
      status_code_original
      product_id_code
      product_change {
        id
        change_time
        change_notes
        change_type
        change_type_original
      }
      vendor {
        id
        vendor_id_code
        last_reviewed_at
        last_reviewed_by
        last_reviewed_by_code
        last_published_at
        last_published_by
        last_published_by_code
        assigned_analyst
        assigned_analyst_code
        next_review_in_days
        next_review_in_days_code
        next_review_at
        full_legal_name
        historical_names
        alias_names
        url
        hierarchy_code
        status_code
        vendor_logo_filename
        ultimate_parent_id
        ultimate_parent_vendor_id_code
        ultimate_parent_hierarchy_code
        ultimate_parent_name
        immediate_parent_id
        immediate_parent_vendor_id_code
        immediate_parent_name
        immediate_parent_hierarchy_code
        brief_description
        preview_brief_description
        detailed_description
        year_founded
        company_type_code
        number_of_offices
        company_size_code
        headquarters
        lei_code
        created_by
        created_at
        updated_by
        updated_at
        products {
          id
          vendor_product_id
          name
          vendor_specific_product_details
          brief_description
          preview_brief_description
          detailed_description
          url
          demo_url
          status
          status_code_original
          product_id_code
          product_change {
            id
            change_time
            change_notes
            change_type
            change_type_original
          }
          vendor {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_name
            immediate_parent_hierarchy_code
            brief_description
            preview_brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            created_by
            created_at
            updated_by
            updated_at
            products {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            financials {
              id
              year
              revenue
              period_end_date
              revenue_amount
              currency
              currency_code
              created_at
              updated_at
            }
            tickers {
              id
              stock_exchange
              ticker_symbol
              created_by
              created_at
              updated_by
              updated_at
            }
            officers {
              id
              business_role
              business_role_code
              full_name
              phone_number
              email_address
              linkedin_url
              comment
              business_role_additional
            }
            social_media {
              id
              social_media_type
              social_media_type_code
              url
            }
            locations {
              id
              name
              type
              type_code
              region
              region_code
              division
              city
              country
              country_code
              street_address_1
              street_address_2
              state_province
              postal_code
              phone_number
            }
            market_segments {
              code
              value
            }
            investor_relations {
              id
              contact_person
              phone_number
              email_address
              blog_url
            }
            corporate_actions {
              id
              type_code
              type
              action_time
              description
            }
            vendor_stage_code
            vendor_stage
            premium_benefit
          }
          premium_benefit
        }
        financials {
          id
          year
          revenue
          period_end_date
          revenue_amount
          currency
          currency_code
          created_at
          updated_at
        }
        tickers {
          id
          stock_exchange
          ticker_symbol
          created_by
          created_at
          updated_by
          updated_at
        }
        officers {
          id
          business_role
          business_role_code
          full_name
          phone_number
          email_address
          linkedin_url
          comment
          business_role_additional
        }
        social_media {
          id
          social_media_type
          social_media_type_code
          url
        }
        locations {
          id
          name
          type
          type_code
          region
          region_code
          division
          city
          country
          country_code
          street_address_1
          street_address_2
          state_province
          postal_code
          phone_number
          customer_support {
            id
            phone_number
            support_hours
            email_address
            support_portal_url
            time_zone
            time_zone_code
          }
        }
        market_segments {
          code
          value
        }
        investor_relations {
          id
          contact_person
          phone_number
          email_address
          blog_url
        }
        corporate_actions {
          id
          type_code
          type
          action_time
          description
        }
        vendor_stage_code
        vendor_stage
        premium_benefit
      }
      premium_benefit
    }
  }
`;
export const products = /* GraphQL */ `
  query Products($limit: Int, $page: Int, $orderBy: String, $order: String) {
    products(limit: $limit, page: $page, orderBy: $orderBy, order: $order) {
      data {
        name
        product_id
        full_legal_name
        vendor_id
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const productsByName = /* GraphQL */ `
  query ProductsByName($name: String, $limit: Int, $page: Int) {
    productsByName(name: $name, limit: $limit, page: $page) {
      data {
        name
        product_id
        full_legal_name
        vendor_id
        brief_description
        vendor_stage_code
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const vendorsByProductName = /* GraphQL */ `
  query VendorsByProductName(
    $name: String
    $limit: Int
    $page: Int
    $cataloged: Boolean
    $orderBy: String
    $order: String
  ) {
    vendorsByProductName(
      name: $name
      limit: $limit
      page: $page
      cataloged: $cataloged
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        full_legal_name
        brief_description
        vendor_stage_code
        vendor_stage
        assigned_analyst
        last_published_at
        last_reviewed_at
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const listVendexAnalysts = /* GraphQL */ `
  query ListVendexAnalysts {
    listVendexAnalysts {
      user_id_code
      full_name
      email_address
      role_code
    }
  }
`;
export const listClassifiersByType = /* GraphQL */ `
  query ListClassifiersByType($type: String!) {
    listClassifiersByType(type: $type) {
      code
      type
      value
      description
      level
      order_num
      parent_classifier_code
      valid_until
      created_by
      created_at
      updated_by
      updated_at
    }
  }
`;
export const listClassifiersByCode = /* GraphQL */ `
  query ListClassifiersByCode($code: String!) {
    listClassifiersByCode(code: $code) {
      code
      type
      value
      description
      level
      order_num
      parent_classifier_code
      valid_until
      created_by
      created_at
      updated_by
      updated_at
    }
  }
`;
export const stats = /* GraphQL */ `
  query Stats {
    stats {
      total_number_of_products
      number_of_products_cataloged
      total_number_of_vendors
      number_of_vendors_cataloged
    }
  }
`;
export const marketSegments = /* GraphQL */ `
  query MarketSegments {
    marketSegments {
      code
      value
    }
  }
`;
export const listVendorsByMarketSegments = /* GraphQL */ `
  query ListVendorsByMarketSegments(
    $market_segments: AWSJSON!
    $limit: Int
    $page: Int
    $cataloged: Boolean
    $orderBy: String
    $order: String
  ) {
    listVendorsByMarketSegments(
      market_segments: $market_segments
      limit: $limit
      page: $page
      cataloged: $cataloged
      orderBy: $orderBy
      order: $order
    ) {
      data {
        id
        full_legal_name
        brief_description
        assigned_analyst
        vendor_stage
        last_published_at
        last_reviewed_at
      }
      meta {
        count
        page
        pageSize
        pageCount
        orderBy
        order
      }
    }
  }
`;
export const getAgreementMeta = /* GraphQL */ `
  query GetAgreementMeta($id: ID!) {
    getAgreementMeta(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      contractEffectiveDate
      agreementType
      effectiveDateMsa
      executionDate
      licenseTypeDescription
      productServiceOrderNumber
      additionOfServices
      disputeHandling
      vendorWebSite
      definitions {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          term
          definition
          groupsCanAccess
        }
        nextToken
      }
      primarySubscriber {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      primaryVendor {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberSignatory {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      vendorSalesRep {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBillingInvoice {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberBusiness {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberLegal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberNotices {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      subscriberTechnical {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      dataProviderChangeProvisions
      dataUsageLimit
      deliveryMethod
      electronicProductAddress
      notificationOfChangesTerms
      numberOfLicenses
      permittedUsesOfVendorProduct
      physicalProductAddress {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      prohibitedLocations
      prohibitedUses
      subscriberPostTerminationRightsAndObligations
      vendorPostTerminationRightsAndObligations
      thirdParty {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      dataRedistributionRights {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      cancellationNoticePeriod
      breachOfContractEvents
      cancellationNoticeDeadline
      contractEndDate
      earliestRenewalNoticeDate
      forceMajeureTermination
      terminationEvents
      lengthOfContract
      lengthOfContractBasis
      subscriberChangeInControl
      subscriberChangeInControlDate
      subscriberChangeInControlProductTerminationDate
      vendorChangeInControl
      vendorChangeInControlDate
      automaticRenewal {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      indemnificationLanguage
      reciprocalIndemnification
      rightsAndObligationsForClaims
      subscriberIndemnification
      subscriberInjunctionRemedies
      subscriberLiabilityCap
      suscriberRestructuringProvisions
      thirdPartyRecourse
      vendorDataAccuracyRepresentations
      vendorIndemnification
      vendorInjunctionRemedies
      vendorLiabilityCap
      vendorRestructuringProvisions
      informationCollection
      ipInfringementRemediation
      sourceAttribution
      vendorUseOfDataRightsManagement
      usageInspection {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      creationOfBackupMaterials
      subscriberUsageReporting
      vendorInspectionPayment
      auditTerms {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      usageMonitoring {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      confidentialDataTreatment
      informationSecurity
      notificationOfChanges
      vendorAdvanceNoticeRequirements
      subscriberAdvanceNoticeRequirements
      advanceNoticeRequirements
      antiCorruptionAml
      forceMajeure
      tradeRestrictions
      jurisdiction
      productAndPricingGroups {
        items {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          product {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            productName
            briefDescription
            vendExProductId
            productDescription
            serviceType
            groupsCanAccess
          }
          vSourceProduct {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          productVSourceId
          productAndPricingGroupProductId
          quantity
          currency
          documentProductName
          licenseFee
          billingStartDate
          billingEndDate
          invoiceFrequency
          invoiceTerms
          additionalRecurringFeeS
          oneTimeFeeS
          totalFees
          licensedLocationS
          licensedUserS
          prohibitedLocationS
          prohibitedUsersS
          licenseType
          maximumDataUsage
          status
          generalTerms
          productStartDate
          productEndDate
          productOrServiceOrderNumber
          cancellationNoticePeriod
          cancellationNoticeDeadline
          earliestRenewalNoticeDate
          automaticRenewal
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const listAgreementMetas = /* GraphQL */ `
  query ListAgreementMetas(
    $filter: ModelAgreementMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgreementMetas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getProductAndPricingGroup = /* GraphQL */ `
  query GetProductAndPricingGroup($id: ID!) {
    getProductAndPricingGroup(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      product {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      vSourceProduct {
        id
        vendor_product_id
        name
        vendor_specific_product_details
        brief_description
        preview_brief_description
        detailed_description
        url
        demo_url
        status
        status_code_original
        product_id_code
        product_change {
          id
          change_time
          change_notes
          change_type
          change_type_original
        }
        vendor {
          id
          vendor_id_code
          last_reviewed_at
          last_reviewed_by
          last_reviewed_by_code
          last_published_at
          last_published_by
          last_published_by_code
          assigned_analyst
          assigned_analyst_code
          next_review_in_days
          next_review_in_days_code
          next_review_at
          full_legal_name
          historical_names
          alias_names
          url
          hierarchy_code
          status_code
          vendor_logo_filename
          ultimate_parent_id
          ultimate_parent_vendor_id_code
          ultimate_parent_hierarchy_code
          ultimate_parent_name
          immediate_parent_id
          immediate_parent_vendor_id_code
          immediate_parent_name
          immediate_parent_hierarchy_code
          brief_description
          preview_brief_description
          detailed_description
          year_founded
          company_type_code
          number_of_offices
          company_size_code
          headquarters
          lei_code
          created_by
          created_at
          updated_by
          updated_at
          products {
            id
            vendor_product_id
            name
            vendor_specific_product_details
            brief_description
            preview_brief_description
            detailed_description
            url
            demo_url
            status
            status_code_original
            product_id_code
            product_change {
              id
              change_time
              change_notes
              change_type
              change_type_original
            }
            vendor {
              id
              vendor_id_code
              last_reviewed_at
              last_reviewed_by
              last_reviewed_by_code
              last_published_at
              last_published_by
              last_published_by_code
              assigned_analyst
              assigned_analyst_code
              next_review_in_days
              next_review_in_days_code
              next_review_at
              full_legal_name
              historical_names
              alias_names
              url
              hierarchy_code
              status_code
              vendor_logo_filename
              ultimate_parent_id
              ultimate_parent_vendor_id_code
              ultimate_parent_hierarchy_code
              ultimate_parent_name
              immediate_parent_id
              immediate_parent_vendor_id_code
              immediate_parent_name
              immediate_parent_hierarchy_code
              brief_description
              preview_brief_description
              detailed_description
              year_founded
              company_type_code
              number_of_offices
              company_size_code
              headquarters
              lei_code
              created_by
              created_at
              updated_by
              updated_at
              vendor_stage_code
              vendor_stage
              premium_benefit
            }
            premium_benefit
          }
          financials {
            id
            year
            revenue
            period_end_date
            revenue_amount
            currency
            currency_code
            created_at
            updated_at
          }
          tickers {
            id
            stock_exchange
            ticker_symbol
            created_by
            created_at
            updated_by
            updated_at
          }
          officers {
            id
            business_role
            business_role_code
            full_name
            phone_number
            email_address
            linkedin_url
            comment
            business_role_additional
          }
          social_media {
            id
            social_media_type
            social_media_type_code
            url
          }
          locations {
            id
            name
            type
            type_code
            region
            region_code
            division
            city
            country
            country_code
            street_address_1
            street_address_2
            state_province
            postal_code
            phone_number
            customer_support {
              id
              phone_number
              support_hours
              email_address
              support_portal_url
              time_zone
              time_zone_code
            }
          }
          market_segments {
            code
            value
          }
          investor_relations {
            id
            contact_person
            phone_number
            email_address
            blog_url
          }
          corporate_actions {
            id
            type_code
            type
            action_time
            description
          }
          vendor_stage_code
          vendor_stage
          premium_benefit
        }
        premium_benefit
      }
      productVSourceId
      productAndPricingGroupProductId
      quantity
      currency
      documentProductName
      licenseFee
      billingStartDate
      billingEndDate
      invoiceFrequency
      invoiceTerms
      additionalRecurringFeeS
      oneTimeFeeS
      totalFees
      licensedLocationS
      licensedUserS
      prohibitedLocationS
      prohibitedUsersS
      licenseType
      maximumDataUsage
      status
      generalTerms
      productStartDate
      productEndDate
      productOrServiceOrderNumber
      cancellationNoticePeriod
      cancellationNoticeDeadline
      earliestRenewalNoticeDate
      automaticRenewal
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const listProductAndPricingGroups = /* GraphQL */ `
  query ListProductAndPricingGroups(
    $filter: ModelProductAndPricingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductAndPricingGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        product {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          productName
          briefDescription
          vendExProductId
          productDescription
          serviceType
          groupsCanAccess
        }
        vSourceProduct {
          id
          vendor_product_id
          name
          vendor_specific_product_details
          brief_description
          preview_brief_description
          detailed_description
          url
          demo_url
          status
          status_code_original
          product_id_code
          product_change {
            id
            change_time
            change_notes
            change_type
            change_type_original
          }
          vendor {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_name
            immediate_parent_hierarchy_code
            brief_description
            preview_brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            created_by
            created_at
            updated_by
            updated_at
            products {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            financials {
              id
              year
              revenue
              period_end_date
              revenue_amount
              currency
              currency_code
              created_at
              updated_at
            }
            tickers {
              id
              stock_exchange
              ticker_symbol
              created_by
              created_at
              updated_by
              updated_at
            }
            officers {
              id
              business_role
              business_role_code
              full_name
              phone_number
              email_address
              linkedin_url
              comment
              business_role_additional
            }
            social_media {
              id
              social_media_type
              social_media_type_code
              url
            }
            locations {
              id
              name
              type
              type_code
              region
              region_code
              division
              city
              country
              country_code
              street_address_1
              street_address_2
              state_province
              postal_code
              phone_number
            }
            market_segments {
              code
              value
            }
            investor_relations {
              id
              contact_person
              phone_number
              email_address
              blog_url
            }
            corporate_actions {
              id
              type_code
              type
              action_time
              description
            }
            vendor_stage_code
            vendor_stage
            premium_benefit
          }
          premium_benefit
        }
        productVSourceId
        productAndPricingGroupProductId
        quantity
        currency
        documentProductName
        licenseFee
        billingStartDate
        billingEndDate
        invoiceFrequency
        invoiceTerms
        additionalRecurringFeeS
        oneTimeFeeS
        totalFees
        licensedLocationS
        licensedUserS
        prohibitedLocationS
        prohibitedUsersS
        licenseType
        maximumDataUsage
        status
        generalTerms
        productStartDate
        productEndDate
        productOrServiceOrderNumber
        cancellationNoticePeriod
        cancellationNoticeDeadline
        earliestRenewalNoticeDate
        automaticRenewal
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      productName
      briefDescription
      vendExProductId
      productDescription
      serviceType
      groupsCanAccess
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      name
      title
      phone
      email
      groupsCanAccess
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getDefinitionTerm = /* GraphQL */ `
  query GetDefinitionTerm($id: ID!) {
    getDefinitionTerm(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      term
      definition
      groupsCanAccess
    }
  }
`;
export const listDefinitionTerms = /* GraphQL */ `
  query ListDefinitionTerms(
    $filter: ModelDefinitionTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDefinitionTerms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        term
        definition
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      address1
      address2
      zip
      city
      state
      country
      groupsCanAccess
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      vendorName
      vendorId
      subscriberName
      customerFileName
      customerDescription
      documentUploadTimestamp
      uploadedBy
      documentDescription
      documentTitle
      docAgreementName
      docEndDate
      docNumberOfPages
      docProductNames
      docVendorName
      uploadDate
      documentCompletionTimestampByVendexDataAnalyst
      vendExDataAnalyst
      vendExApproverDateAndTimestamp
      vendExApprover
      serviceAttachmentInternalId
      thirdPartyDocumentId
      vendExDocumentId
      vendExFileName
      vendorOrderNumber
      dummy
      migrate
      id
      agreements {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      groupsCanAccess
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getDataRedistributionRights = /* GraphQL */ `
  query GetDataRedistributionRights($id: ID!) {
    getDataRedistributionRights(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      dataRedistributionTerms
      dataRedistributionPricing
      groupsCanAccess
    }
  }
`;
export const listDataRedistributionRightss = /* GraphQL */ `
  query ListDataRedistributionRightss(
    $filter: ModelDataRedistributionRightsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDataRedistributionRightss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getAuditTerms = /* GraphQL */ `
  query GetAuditTerms($id: ID!) {
    getAuditTerms(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      auditTerms
      auditFrequency
      groupsCanAccess
    }
  }
`;
export const listAuditTermss = /* GraphQL */ `
  query ListAuditTermss(
    $filter: ModelAuditTermsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAuditTermss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getThirdParty = /* GraphQL */ `
  query GetThirdParty($id: ID!) {
    getThirdParty(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      thirdParty
      thirdPartyProviders
      thirdPartyObligations
      groupsCanAccess
    }
  }
`;
export const listThirdPartys = /* GraphQL */ `
  query ListThirdPartys(
    $filter: ModelThirdPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThirdPartys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getUsageInspection = /* GraphQL */ `
  query GetUsageInspection($id: ID!) {
    getUsageInspection(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageInspectionNotice
      usageInspectionFrequency
      groupsCanAccess
    }
  }
`;
export const listUsageInspections = /* GraphQL */ `
  query ListUsageInspections(
    $filter: ModelUsageInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsageInspections(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getAutomaticRenewal = /* GraphQL */ `
  query GetAutomaticRenewal($id: ID!) {
    getAutomaticRenewal(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      automaticRenewalDate
      automaticRenewalNoticeDate
      automaticRenewalTerms
      groupsCanAccess
    }
  }
`;
export const listAutomaticRenewals = /* GraphQL */ `
  query ListAutomaticRenewals(
    $filter: ModelAutomaticRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAutomaticRenewals(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getReplacementContract = /* GraphQL */ `
  query GetReplacementContract($id: ID!) {
    getReplacementContract(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      replacementContractNumber
      groupsCanAccess
    }
  }
`;
export const listReplacementContracts = /* GraphQL */ `
  query ListReplacementContracts(
    $filter: ModelReplacementContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReplacementContracts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        replacementContractNumber
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getUsageMonitoring = /* GraphQL */ `
  query GetUsageMonitoring($id: ID!) {
    getUsageMonitoring(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      usageReportDates
      usageReportPeriods
      groupsCanAccess
    }
  }
`;
export const listUsageMonitorings = /* GraphQL */ `
  query ListUsageMonitorings(
    $filter: ModelUsageMonitoringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsageMonitorings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getAgreement = /* GraphQL */ `
  query GetAgreement($id: ID!) {
    getAgreement(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      id
      vendorId
      masterProduct
      documents {
        items {
          id
          document {
            updatedBy
            updatedAt
            createdBy
            createdAt
            vendorName
            vendorId
            subscriberName
            customerFileName
            customerDescription
            documentUploadTimestamp
            uploadedBy
            documentDescription
            documentTitle
            docAgreementName
            docEndDate
            docNumberOfPages
            docProductNames
            docVendorName
            uploadDate
            documentCompletionTimestampByVendexDataAnalyst
            vendExDataAnalyst
            vendExApproverDateAndTimestamp
            vendExApprover
            serviceAttachmentInternalId
            thirdPartyDocumentId
            vendExDocumentId
            vendExFileName
            vendorOrderNumber
            dummy
            migrate
            id
            agreements {
              nextToken
            }
            groupsCanAccess
          }
          agreement {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            vendorId
            masterProduct
            documents {
              nextToken
            }
            meta {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              contractEffectiveDate
              agreementType
              effectiveDateMsa
              executionDate
              licenseTypeDescription
              productServiceOrderNumber
              additionOfServices
              disputeHandling
              vendorWebSite
              dataProviderChangeProvisions
              dataUsageLimit
              deliveryMethod
              electronicProductAddress
              notificationOfChangesTerms
              numberOfLicenses
              permittedUsesOfVendorProduct
              prohibitedLocations
              prohibitedUses
              subscriberPostTerminationRightsAndObligations
              vendorPostTerminationRightsAndObligations
              cancellationNoticePeriod
              breachOfContractEvents
              cancellationNoticeDeadline
              contractEndDate
              earliestRenewalNoticeDate
              forceMajeureTermination
              terminationEvents
              lengthOfContract
              lengthOfContractBasis
              subscriberChangeInControl
              subscriberChangeInControlDate
              subscriberChangeInControlProductTerminationDate
              vendorChangeInControl
              vendorChangeInControlDate
              indemnificationLanguage
              reciprocalIndemnification
              rightsAndObligationsForClaims
              subscriberIndemnification
              subscriberInjunctionRemedies
              subscriberLiabilityCap
              suscriberRestructuringProvisions
              thirdPartyRecourse
              vendorDataAccuracyRepresentations
              vendorIndemnification
              vendorInjunctionRemedies
              vendorLiabilityCap
              vendorRestructuringProvisions
              informationCollection
              ipInfringementRemediation
              sourceAttribution
              vendorUseOfDataRightsManagement
              creationOfBackupMaterials
              subscriberUsageReporting
              vendorInspectionPayment
              confidentialDataTreatment
              informationSecurity
              notificationOfChanges
              vendorAdvanceNoticeRequirements
              subscriberAdvanceNoticeRequirements
              advanceNoticeRequirements
              antiCorruptionAml
              forceMajeure
              tradeRestrictions
              jurisdiction
              groupsCanAccess
            }
            vendorName
            documentVendorName
            vendorVSourceId
            groupsCanAccess
          }
          createdAt
          updatedAt
          groupsCanAccess
        }
        nextToken
      }
      meta {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      vendorName
      documentVendorName
      vendorVSourceId
      groupsCanAccess
    }
  }
`;
export const listAgreements = /* GraphQL */ `
  query ListAgreements(
    $filter: ModelAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgreements(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        vendorId
        masterProduct
        documents {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        meta {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          contractEffectiveDate
          agreementType
          effectiveDateMsa
          executionDate
          licenseTypeDescription
          productServiceOrderNumber
          additionOfServices
          disputeHandling
          vendorWebSite
          definitions {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              term
              definition
              groupsCanAccess
            }
            nextToken
          }
          primarySubscriber {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          primaryVendor {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSalesRep {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          dataProviderChangeProvisions
          dataUsageLimit
          deliveryMethod
          electronicProductAddress
          notificationOfChangesTerms
          numberOfLicenses
          permittedUsesOfVendorProduct
          physicalProductAddress {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            address1
            address2
            zip
            city
            state
            country
            groupsCanAccess
          }
          prohibitedLocations
          prohibitedUses
          subscriberPostTerminationRightsAndObligations
          vendorPostTerminationRightsAndObligations
          thirdParty {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            thirdParty
            thirdPartyProviders
            thirdPartyObligations
            groupsCanAccess
          }
          dataRedistributionRights {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            dataRedistributionTerms
            dataRedistributionPricing
            groupsCanAccess
          }
          cancellationNoticePeriod
          breachOfContractEvents
          cancellationNoticeDeadline
          contractEndDate
          earliestRenewalNoticeDate
          forceMajeureTermination
          terminationEvents
          lengthOfContract
          lengthOfContractBasis
          subscriberChangeInControl
          subscriberChangeInControlDate
          subscriberChangeInControlProductTerminationDate
          vendorChangeInControl
          vendorChangeInControlDate
          automaticRenewal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          indemnificationLanguage
          reciprocalIndemnification
          rightsAndObligationsForClaims
          subscriberIndemnification
          subscriberInjunctionRemedies
          subscriberLiabilityCap
          suscriberRestructuringProvisions
          thirdPartyRecourse
          vendorDataAccuracyRepresentations
          vendorIndemnification
          vendorInjunctionRemedies
          vendorLiabilityCap
          vendorRestructuringProvisions
          informationCollection
          ipInfringementRemediation
          sourceAttribution
          vendorUseOfDataRightsManagement
          usageInspection {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageInspectionNotice
            usageInspectionFrequency
            groupsCanAccess
          }
          creationOfBackupMaterials
          subscriberUsageReporting
          vendorInspectionPayment
          auditTerms {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            auditTerms
            auditFrequency
            groupsCanAccess
          }
          usageMonitoring {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageReportDates
            usageReportPeriods
            groupsCanAccess
          }
          confidentialDataTreatment
          informationSecurity
          notificationOfChanges
          vendorAdvanceNoticeRequirements
          subscriberAdvanceNoticeRequirements
          advanceNoticeRequirements
          antiCorruptionAml
          forceMajeure
          tradeRestrictions
          jurisdiction
          productAndPricingGroups {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productVSourceId
              productAndPricingGroupProductId
              quantity
              currency
              documentProductName
              licenseFee
              billingStartDate
              billingEndDate
              invoiceFrequency
              invoiceTerms
              additionalRecurringFeeS
              oneTimeFeeS
              totalFees
              licensedLocationS
              licensedUserS
              prohibitedLocationS
              prohibitedUsersS
              licenseType
              maximumDataUsage
              status
              generalTerms
              productStartDate
              productEndDate
              productOrServiceOrderNumber
              cancellationNoticePeriod
              cancellationNoticeDeadline
              earliestRenewalNoticeDate
              automaticRenewal
              automaticRenewalDate
              automaticRenewalNoticeDate
              automaticRenewalTerms
              groupsCanAccess
            }
            nextToken
          }
          groupsCanAccess
        }
        vendorName
        documentVendorName
        vendorVSourceId
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const getEntities = /* GraphQL */ `
  query GetEntities($id: ID!) {
    getEntities(id: $id) {
      id
      name
      clientID
      groupName
      logoUrl
      groupsCanAccess
      createdAt
      updatedAt
    }
  }
`;
export const listEntitiess = /* GraphQL */ `
  query ListEntitiess(
    $filter: ModelEntitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEntitiess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        clientID
        groupName
        logoUrl
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      updatedBy
      updatedAt
      createdBy
      createdAt
      title
      description
      content
      id
      activeFrom
      activeTo
      type
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        title
        description
        content
        id
        activeFrom
        activeTo
        type
      }
      nextToken
    }
  }
`;
export const getUserStat = /* GraphQL */ `
  query GetUserStat($id: ID!) {
    getUserStat(id: $id) {
      id
      username
      userId
      updatedBy
      updatedAt
      createdBy
      createdAt
      type
      value
    }
  }
`;
export const listUserStats = /* GraphQL */ `
  query ListUserStats(
    $filter: ModelUserStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserStats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        userId
        updatedBy
        updatedAt
        createdBy
        createdAt
        type
        value
      }
      nextToken
    }
  }
`;
export const agreementMetaByGroupAccess = /* GraphQL */ `
  query AgreementMetaByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelAgreementMetaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agreementMetaByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        contractEffectiveDate
        agreementType
        effectiveDateMsa
        executionDate
        licenseTypeDescription
        productServiceOrderNumber
        additionOfServices
        disputeHandling
        vendorWebSite
        definitions {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            term
            definition
            groupsCanAccess
          }
          nextToken
        }
        primarySubscriber {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        primaryVendor {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberSignatory {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        vendorSalesRep {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBillingInvoice {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberBusiness {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberLegal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberNotices {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        subscriberTechnical {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          name
          title
          phone
          email
          groupsCanAccess
        }
        dataProviderChangeProvisions
        dataUsageLimit
        deliveryMethod
        electronicProductAddress
        notificationOfChangesTerms
        numberOfLicenses
        permittedUsesOfVendorProduct
        physicalProductAddress {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          address1
          address2
          zip
          city
          state
          country
          groupsCanAccess
        }
        prohibitedLocations
        prohibitedUses
        subscriberPostTerminationRightsAndObligations
        vendorPostTerminationRightsAndObligations
        thirdParty {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          thirdParty
          thirdPartyProviders
          thirdPartyObligations
          groupsCanAccess
        }
        dataRedistributionRights {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          dataRedistributionTerms
          dataRedistributionPricing
          groupsCanAccess
        }
        cancellationNoticePeriod
        breachOfContractEvents
        cancellationNoticeDeadline
        contractEndDate
        earliestRenewalNoticeDate
        forceMajeureTermination
        terminationEvents
        lengthOfContract
        lengthOfContractBasis
        subscriberChangeInControl
        subscriberChangeInControlDate
        subscriberChangeInControlProductTerminationDate
        vendorChangeInControl
        vendorChangeInControlDate
        automaticRenewal {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          automaticRenewalDate
          automaticRenewalNoticeDate
          automaticRenewalTerms
          groupsCanAccess
        }
        indemnificationLanguage
        reciprocalIndemnification
        rightsAndObligationsForClaims
        subscriberIndemnification
        subscriberInjunctionRemedies
        subscriberLiabilityCap
        suscriberRestructuringProvisions
        thirdPartyRecourse
        vendorDataAccuracyRepresentations
        vendorIndemnification
        vendorInjunctionRemedies
        vendorLiabilityCap
        vendorRestructuringProvisions
        informationCollection
        ipInfringementRemediation
        sourceAttribution
        vendorUseOfDataRightsManagement
        usageInspection {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageInspectionNotice
          usageInspectionFrequency
          groupsCanAccess
        }
        creationOfBackupMaterials
        subscriberUsageReporting
        vendorInspectionPayment
        auditTerms {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          auditTerms
          auditFrequency
          groupsCanAccess
        }
        usageMonitoring {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          usageReportDates
          usageReportPeriods
          groupsCanAccess
        }
        confidentialDataTreatment
        informationSecurity
        notificationOfChanges
        vendorAdvanceNoticeRequirements
        subscriberAdvanceNoticeRequirements
        advanceNoticeRequirements
        antiCorruptionAml
        forceMajeure
        tradeRestrictions
        jurisdiction
        productAndPricingGroups {
          items {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            product {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productName
              briefDescription
              vendExProductId
              productDescription
              serviceType
              groupsCanAccess
            }
            vSourceProduct {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            productVSourceId
            productAndPricingGroupProductId
            quantity
            currency
            documentProductName
            licenseFee
            billingStartDate
            billingEndDate
            invoiceFrequency
            invoiceTerms
            additionalRecurringFeeS
            oneTimeFeeS
            totalFees
            licensedLocationS
            licensedUserS
            prohibitedLocationS
            prohibitedUsersS
            licenseType
            maximumDataUsage
            status
            generalTerms
            productStartDate
            productEndDate
            productOrServiceOrderNumber
            cancellationNoticePeriod
            cancellationNoticeDeadline
            earliestRenewalNoticeDate
            automaticRenewal
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const productAndPricingGroupByGroupAccess = /* GraphQL */ `
  query ProductAndPricingGroupByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductAndPricingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productAndPricingGroupByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        product {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          productName
          briefDescription
          vendExProductId
          productDescription
          serviceType
          groupsCanAccess
        }
        vSourceProduct {
          id
          vendor_product_id
          name
          vendor_specific_product_details
          brief_description
          preview_brief_description
          detailed_description
          url
          demo_url
          status
          status_code_original
          product_id_code
          product_change {
            id
            change_time
            change_notes
            change_type
            change_type_original
          }
          vendor {
            id
            vendor_id_code
            last_reviewed_at
            last_reviewed_by
            last_reviewed_by_code
            last_published_at
            last_published_by
            last_published_by_code
            assigned_analyst
            assigned_analyst_code
            next_review_in_days
            next_review_in_days_code
            next_review_at
            full_legal_name
            historical_names
            alias_names
            url
            hierarchy_code
            status_code
            vendor_logo_filename
            ultimate_parent_id
            ultimate_parent_vendor_id_code
            ultimate_parent_hierarchy_code
            ultimate_parent_name
            immediate_parent_id
            immediate_parent_vendor_id_code
            immediate_parent_name
            immediate_parent_hierarchy_code
            brief_description
            preview_brief_description
            detailed_description
            year_founded
            company_type_code
            number_of_offices
            company_size_code
            headquarters
            lei_code
            created_by
            created_at
            updated_by
            updated_at
            products {
              id
              vendor_product_id
              name
              vendor_specific_product_details
              brief_description
              preview_brief_description
              detailed_description
              url
              demo_url
              status
              status_code_original
              product_id_code
              premium_benefit
            }
            financials {
              id
              year
              revenue
              period_end_date
              revenue_amount
              currency
              currency_code
              created_at
              updated_at
            }
            tickers {
              id
              stock_exchange
              ticker_symbol
              created_by
              created_at
              updated_by
              updated_at
            }
            officers {
              id
              business_role
              business_role_code
              full_name
              phone_number
              email_address
              linkedin_url
              comment
              business_role_additional
            }
            social_media {
              id
              social_media_type
              social_media_type_code
              url
            }
            locations {
              id
              name
              type
              type_code
              region
              region_code
              division
              city
              country
              country_code
              street_address_1
              street_address_2
              state_province
              postal_code
              phone_number
            }
            market_segments {
              code
              value
            }
            investor_relations {
              id
              contact_person
              phone_number
              email_address
              blog_url
            }
            corporate_actions {
              id
              type_code
              type
              action_time
              description
            }
            vendor_stage_code
            vendor_stage
            premium_benefit
          }
          premium_benefit
        }
        productVSourceId
        productAndPricingGroupProductId
        quantity
        currency
        documentProductName
        licenseFee
        billingStartDate
        billingEndDate
        invoiceFrequency
        invoiceTerms
        additionalRecurringFeeS
        oneTimeFeeS
        totalFees
        licensedLocationS
        licensedUserS
        prohibitedLocationS
        prohibitedUsersS
        licenseType
        maximumDataUsage
        status
        generalTerms
        productStartDate
        productEndDate
        productOrServiceOrderNumber
        cancellationNoticePeriod
        cancellationNoticeDeadline
        earliestRenewalNoticeDate
        automaticRenewal
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const productByGroupAccess = /* GraphQL */ `
  query ProductByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        productName
        briefDescription
        vendExProductId
        productDescription
        serviceType
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const contactByGroupAccess = /* GraphQL */ `
  query ContactByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contactByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        name
        title
        phone
        email
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const definitionTermByGroupAccess = /* GraphQL */ `
  query DefinitionTermByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelDefinitionTermFilterInput
    $limit: Int
    $nextToken: String
  ) {
    definitionTermByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        term
        definition
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const addressByGroupAccess = /* GraphQL */ `
  query AddressByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    addressByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        address1
        address2
        zip
        city
        state
        country
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const documentsByDate = /* GraphQL */ `
  query DocumentsByDate(
    $dummy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByDate(
      dummy: $dummy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const documentsByGroupAccessAndDate = /* GraphQL */ `
  query DocumentsByGroupAccessAndDate(
    $groupsCanAccess: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByGroupAccessAndDate(
      groupsCanAccess: $groupsCanAccess
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        vendorName
        vendorId
        subscriberName
        customerFileName
        customerDescription
        documentUploadTimestamp
        uploadedBy
        documentDescription
        documentTitle
        docAgreementName
        docEndDate
        docNumberOfPages
        docProductNames
        docVendorName
        uploadDate
        documentCompletionTimestampByVendexDataAnalyst
        vendExDataAnalyst
        vendExApproverDateAndTimestamp
        vendExApprover
        serviceAttachmentInternalId
        thirdPartyDocumentId
        vendExDocumentId
        vendExFileName
        vendorOrderNumber
        dummy
        migrate
        id
        agreements {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const dataRedistributionRightsByGroupAccess = /* GraphQL */ `
  query DataRedistributionRightsByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelDataRedistributionRightsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dataRedistributionRightsByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        dataRedistributionTerms
        dataRedistributionPricing
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const auditTermsByGroupAccess = /* GraphQL */ `
  query AuditTermsByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelAuditTermsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditTermsByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        auditTerms
        auditFrequency
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const thirdPartyByGroupAccess = /* GraphQL */ `
  query ThirdPartyByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelThirdPartyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    thirdPartyByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        thirdParty
        thirdPartyProviders
        thirdPartyObligations
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const usageInspectionByGroupAccess = /* GraphQL */ `
  query UsageInspectionByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelUsageInspectionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usageInspectionByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageInspectionNotice
        usageInspectionFrequency
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const automaticRenewalByGroupAccess = /* GraphQL */ `
  query AutomaticRenewalByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelAutomaticRenewalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    automaticRenewalByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        automaticRenewalDate
        automaticRenewalNoticeDate
        automaticRenewalTerms
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const replacementContractByGroupAccess = /* GraphQL */ `
  query ReplacementContractByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelReplacementContractFilterInput
    $limit: Int
    $nextToken: String
  ) {
    replacementContractByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        replacementContractNumber
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const usageMonitoringByGroupAccess = /* GraphQL */ `
  query UsageMonitoringByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelUsageMonitoringFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usageMonitoringByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        usageReportDates
        usageReportPeriods
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const agreementsByGroupAccess = /* GraphQL */ `
  query AgreementsByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelAgreementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    agreementsByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        id
        vendorId
        masterProduct
        documents {
          items {
            id
            document {
              updatedBy
              updatedAt
              createdBy
              createdAt
              vendorName
              vendorId
              subscriberName
              customerFileName
              customerDescription
              documentUploadTimestamp
              uploadedBy
              documentDescription
              documentTitle
              docAgreementName
              docEndDate
              docNumberOfPages
              docProductNames
              docVendorName
              uploadDate
              documentCompletionTimestampByVendexDataAnalyst
              vendExDataAnalyst
              vendExApproverDateAndTimestamp
              vendExApprover
              serviceAttachmentInternalId
              thirdPartyDocumentId
              vendExDocumentId
              vendExFileName
              vendorOrderNumber
              dummy
              migrate
              id
              groupsCanAccess
            }
            agreement {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              vendorId
              masterProduct
              vendorName
              documentVendorName
              vendorVSourceId
              groupsCanAccess
            }
            createdAt
            updatedAt
            groupsCanAccess
          }
          nextToken
        }
        meta {
          updatedBy
          updatedAt
          createdBy
          createdAt
          id
          contractEffectiveDate
          agreementType
          effectiveDateMsa
          executionDate
          licenseTypeDescription
          productServiceOrderNumber
          additionOfServices
          disputeHandling
          vendorWebSite
          definitions {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              term
              definition
              groupsCanAccess
            }
            nextToken
          }
          primarySubscriber {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          primaryVendor {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberSignatory {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          vendorSalesRep {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBillingInvoice {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberBusiness {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberLegal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberNotices {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          subscriberTechnical {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            name
            title
            phone
            email
            groupsCanAccess
          }
          dataProviderChangeProvisions
          dataUsageLimit
          deliveryMethod
          electronicProductAddress
          notificationOfChangesTerms
          numberOfLicenses
          permittedUsesOfVendorProduct
          physicalProductAddress {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            address1
            address2
            zip
            city
            state
            country
            groupsCanAccess
          }
          prohibitedLocations
          prohibitedUses
          subscriberPostTerminationRightsAndObligations
          vendorPostTerminationRightsAndObligations
          thirdParty {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            thirdParty
            thirdPartyProviders
            thirdPartyObligations
            groupsCanAccess
          }
          dataRedistributionRights {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            dataRedistributionTerms
            dataRedistributionPricing
            groupsCanAccess
          }
          cancellationNoticePeriod
          breachOfContractEvents
          cancellationNoticeDeadline
          contractEndDate
          earliestRenewalNoticeDate
          forceMajeureTermination
          terminationEvents
          lengthOfContract
          lengthOfContractBasis
          subscriberChangeInControl
          subscriberChangeInControlDate
          subscriberChangeInControlProductTerminationDate
          vendorChangeInControl
          vendorChangeInControlDate
          automaticRenewal {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            automaticRenewalDate
            automaticRenewalNoticeDate
            automaticRenewalTerms
            groupsCanAccess
          }
          indemnificationLanguage
          reciprocalIndemnification
          rightsAndObligationsForClaims
          subscriberIndemnification
          subscriberInjunctionRemedies
          subscriberLiabilityCap
          suscriberRestructuringProvisions
          thirdPartyRecourse
          vendorDataAccuracyRepresentations
          vendorIndemnification
          vendorInjunctionRemedies
          vendorLiabilityCap
          vendorRestructuringProvisions
          informationCollection
          ipInfringementRemediation
          sourceAttribution
          vendorUseOfDataRightsManagement
          usageInspection {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageInspectionNotice
            usageInspectionFrequency
            groupsCanAccess
          }
          creationOfBackupMaterials
          subscriberUsageReporting
          vendorInspectionPayment
          auditTerms {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            auditTerms
            auditFrequency
            groupsCanAccess
          }
          usageMonitoring {
            updatedBy
            updatedAt
            createdBy
            createdAt
            id
            usageReportDates
            usageReportPeriods
            groupsCanAccess
          }
          confidentialDataTreatment
          informationSecurity
          notificationOfChanges
          vendorAdvanceNoticeRequirements
          subscriberAdvanceNoticeRequirements
          advanceNoticeRequirements
          antiCorruptionAml
          forceMajeure
          tradeRestrictions
          jurisdiction
          productAndPricingGroups {
            items {
              updatedBy
              updatedAt
              createdBy
              createdAt
              id
              productVSourceId
              productAndPricingGroupProductId
              quantity
              currency
              documentProductName
              licenseFee
              billingStartDate
              billingEndDate
              invoiceFrequency
              invoiceTerms
              additionalRecurringFeeS
              oneTimeFeeS
              totalFees
              licensedLocationS
              licensedUserS
              prohibitedLocationS
              prohibitedUsersS
              licenseType
              maximumDataUsage
              status
              generalTerms
              productStartDate
              productEndDate
              productOrServiceOrderNumber
              cancellationNoticePeriod
              cancellationNoticeDeadline
              earliestRenewalNoticeDate
              automaticRenewal
              automaticRenewalDate
              automaticRenewalNoticeDate
              automaticRenewalTerms
              groupsCanAccess
            }
            nextToken
          }
          groupsCanAccess
        }
        vendorName
        documentVendorName
        vendorVSourceId
        groupsCanAccess
      }
      nextToken
    }
  }
`;
export const entitiesByGroupAccess = /* GraphQL */ `
  query EntitiesByGroupAccess(
    $groupsCanAccess: String
    $sortDirection: ModelSortDirection
    $filter: ModelEntitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByGroupAccess(
      groupsCanAccess: $groupsCanAccess
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        clientID
        groupName
        logoUrl
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const entitiesByGroupName = /* GraphQL */ `
  query EntitiesByGroupName(
    $groupName: String
    $sortDirection: ModelSortDirection
    $filter: ModelEntitiesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    entitiesByGroupName(
      groupName: $groupName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        clientID
        groupName
        logoUrl
        groupsCanAccess
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByDate = /* GraphQL */ `
  query PostsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        updatedBy
        updatedAt
        createdBy
        createdAt
        title
        description
        content
        id
        activeFrom
        activeTo
        type
      }
      nextToken
    }
  }
`;
export const userStatsByDate = /* GraphQL */ `
  query UserStatsByDate(
    $type: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByDate(
      type: $type
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        userId
        updatedBy
        updatedAt
        createdBy
        createdAt
        type
        value
      }
      nextToken
    }
  }
`;
export const userStatsByTypeByDate = /* GraphQL */ `
  query UserStatsByTypeByDate(
    $userId: String
    $typeCreatedAt: ModelUserStatUserStatsByTypeByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserStatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userStatsByTypeByDate(
      userId: $userId
      typeCreatedAt: $typeCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        userId
        updatedBy
        updatedAt
        createdBy
        createdAt
        type
        value
      }
      nextToken
    }
  }
`;
