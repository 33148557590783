import styled from "styled-components";

const VendorActionsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
    width: 160px;
    flex-shrink: 0;
	
	&>div {
		cursor: pointer;
	}

    .accept {
        i {
            color: #4FAC32;
            font-size: 14px;    
        }    
    }
    
    .reject {
        i {
            color: #F64E60;
            font-size: 14px;
        }    
    }
`;

export default function VendexActions({ row, onAcceptClick, onRejectClick }) {
    return (
        <VendorActionsWrapper>
                <div className='accept' onClick={onAcceptClick}>
                    <i className={`circle ${row.accepted === true ? 'check' : 'outline'} icon`}></i>
                    <span>Accept</span>
                </div>
                <div className='reject' onClick={onRejectClick}>
                    <i className={`circle ${row.accepted === false ? 'times' : 'outline'} icon`}></i>
                    <span>Modify</span>
                </div>
        </VendorActionsWrapper>
    )
}