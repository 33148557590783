import styled from "styled-components";

const FeatureBlockWrapper = styled.div`
    /*min-width: 350px;*/
    border: 1px solid #f4f4f4;
    border-radius: 8px;
    margin-bottom: 40px;
    background-color: rgba(244, 244, 244);
    padding: 15px;
    position: relative;

    .feature-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;
        .feature-title {
          font-size: 16px!important;
          margin-bottom: 0!important;
        }
    }
`;

export const FeatureBtn = styled.button`
  font-size: 16px;
  background: none;
  border: none;
  margin-bottom: 0;
  cursor: pointer;
  color: #2C6FBA;
`


export const FeatureBlock = ({ title, headerButtonValue, onTitleBtnClick, children }) => {
    return (
        <FeatureBlockWrapper>
            <div className="feature-header">
                <h3 className="feature-title">{title}</h3>
                {headerButtonValue && <FeatureBtn onClick={onTitleBtnClick}>{headerButtonValue}</FeatureBtn>}
            </div>
            <div>{children}</div>
        </FeatureBlockWrapper>
    );
};