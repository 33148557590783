import React from "react";

const SearchIcon = props => (
    <svg width={20} height={20} {...props}>
        <path
            d="M7.945 0C3.567 0 0 3.567 0 7.945s3.567 7.945 7.945 7.945a7.9 7.9 0 0 0 5-1.78l5.377 5.376a.824.824 0 0 0 1.164 0 .814.814 0 0 0 0-1.156l-5.376-5.376a7.907 7.907 0 0 0 1.78-5.009C15.89 3.567 12.323 0 7.945 0zm0 1.644a6.289 6.289 0 0 1 6.302 6.301 6.289 6.289 0 0 1-6.302 6.302 6.289 6.289 0 0 1-6.301-6.302 6.289 6.289 0 0 1 6.301-6.301z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

export default SearchIcon;

