import React, { useEffect, useCallback, useContext } from "react";
import ReactTable from "react-table";
import { PageHeader } from "../../components/PageHeader";
import { TableContainer } from "../../components/TableContainer";
import { PageWrapper } from "../../components/PageWrapper";
import { ButtonWrapper } from "../../components/VendBtn";
import { VsourceContext } from "../../context/vsource/vsourceContext";
import { AuthContext } from "../../context/AuthContext";
import metaConfig from "../../config/metaConfig.json";
import { Modal, Popup } from "semantic-ui-react";
import TableIcon from "../../components/icons/TableIcon";
import { OptionPanel } from "../../components/OptionPanel";
import { get } from "lodash";
import { WarningButtonPanel } from "../../components/WarningButtonPanel";
import cogoToast from "cogo-toast";

import { USER_ROLES } from "../../Constants";

const { formatToTimeZone } = require("date-fns-timezone");

export const ProductsPage = ({ history, match }) => {
    const {
        context: {
            products,
            productDeleteCheck,
            productToDelete,
            productDeleted,
            productToDeleteAgreements,
            checkingProductToDeleteAgreements,
        },
        fetchProducts,
        deleteProduct,
        selectProductForDeletion,
        deselectProductForDeletion,
        clearProductDeleted,
    } = useContext(VsourceContext);

    const {
        context: { user },
        userHavePermission,
    } = useContext(AuthContext);

    const options = metaConfig["Product & Pricing"][0]["values"]
        .find((item) => item.id === "product")
        ["values"].find((item) => item.id === "serviceType")["valueAliases"];

    useEffect(() => {
        fetchProducts({});
    }, []);

    useEffect(() => {
        if (productDeleted.productName) {
            cogoToast.success(`${get(productDeleted, "productName", null)} was successfully deleted`).then(() => {
                clearProductDeleted();
            });
        }
    }, [productDeleted.productName]);

    const createProduct = useCallback(() => {
        history.push("/vsource/products/product-configurator/new");
    }, []);

    const editProductCallback = useCallback((productId) => {
        history.push(`/vsource/products/product-configurator/${productId}`);
    }, []);

    const deleteProductCallback = useCallback(async () => {
        await deleteProduct(productToDelete);
        fetchProducts({});
    }, [productToDelete]);

    const deselectProductForDeletionCallback = useCallback(() => {
        deselectProductForDeletion();
    }, []);

    const selectProductForDeletionCallback = useCallback((product) => {
        selectProductForDeletion(product);
    }, []);

    const renderDeleteContents = () => {
        if (productToDeleteAgreements.length > 0) {
            return (
                <>
                    <h5>
                        Cannot delete <i>{get(productToDelete, "productName", null)}</i> because it is still associated
                        with the following Agreements:
                    </h5>

                    {productToDeleteAgreements.map((agreement, index) => (
                        <h3 key={index}>{get(agreement, "agreementName", null)}</h3>
                    ))}

                    <WarningButtonPanel>
                        <ButtonWrapper negative-halo onClick={deselectProductForDeletionCallback}>
                            Cancel
                        </ButtonWrapper>
                    </WarningButtonPanel>
                </>
            );
        }

        return (
            <>
                <h5>Are you sure you want to delete product?</h5>
                <h3>{get(productToDelete, "productName", null)}</h3>
                <WarningButtonPanel>
                    <ButtonWrapper negative-halo onClick={deselectProductForDeletionCallback}>
                        No
                    </ButtonWrapper>
                    <ButtonWrapper onClick={deleteProductCallback}>Yes</ButtonWrapper>
                </WarningButtonPanel>
            </>
        );
    };

    return (
        <PageWrapper>
            <PageHeader title={"Products"}>
                {userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER) && (
                    <ButtonWrapper data-cy={"create-product-btn"} onClick={createProduct}>
                        Add Product
                    </ButtonWrapper>
                )}
            </PageHeader>
            <Modal open={productDeleteCheck} basic onClose={deselectProductForDeletionCallback} size="small">
                <Modal.Content>
                    {checkingProductToDeleteAgreements ? (
                        <>
                            <h3>
                                Checking if {get(productToDelete, "productName", null)} is associated with any
                                agreements
                            </h3>
                        </>
                    ) : (
                        renderDeleteContents()
                    )}
                </Modal.Content>
            </Modal>
            <TableContainer>
                <ReactTable
                    className="-striped -highlight"
                    columns={[
                        {
                            Header: "Product ID",
                            width: 130,
                            accessor: "vendExProductId",
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Product Name",
                            width: 260,
                            accessor: "productName",
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Service Type",
                            width: 200,
                            accessor: "serviceType",
                            headerStyle: {
                                fontSize: 16,
                            },
                            Cell: (row) => options[row.original.serviceType] || "",
                        },
                        {
                            Header: "Created By",
                            accessor: "createdBy",
                            width: 200,
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Created At",
                            accessor: "createdAt",
                            width: 120,
                            headerStyle: {
                                fontSize: 16,
                            },
                            Cell: (row) =>
                                formatToTimeZone(new Date(row.original.createdAt), "DD-MMM-YYYY", { timeZone: "GMT" }),
                        },
                        {
                            Header: "Updated By",
                            accessor: "updatedBy",
                            width: 200,
                            headerStyle: {
                                fontSize: 16,
                            },
                        },
                        {
                            Header: "Updated At",
                            accessor: "updatedAt",
                            width: 120,
                            headerStyle: {
                                fontSize: 16,
                            },
                            Cell: (row) =>
                                formatToTimeZone(new Date(row.original.updatedAt), "DD-MMM-YYYY", { timeZone: "GMT" }),
                        },
                        {
                            Header: "Options",
                            minWidth: 100,
                            headerStyle: {
                                textAlign: "center",
                                fontSize: 16,
                            },
                            Cell: (row) => {
                                return (
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Popup
                                            content={() => {
                                                return (
                                                    <OptionPanel>
                                                        <ButtonWrapper
                                                            halo
                                                            onClick={() => editProductCallback(row.original.id)}
                                                        >
                                                            Edit
                                                        </ButtonWrapper>
                                                        <ButtonWrapper
                                                            negative
                                                            onClick={() =>
                                                                selectProductForDeletionCallback(row.original)
                                                            }
                                                        >
                                                            Delete
                                                        </ButtonWrapper>
                                                    </OptionPanel>
                                                );
                                            }}
                                            on="click"
                                            pinned={true}
                                            position="bottom right"
                                            trigger={<TableIcon />}
                                        />
                                    </div>
                                );
                            },
                        },
                    ]}
                    data={products}
                />
            </TableContainer>
        </PageWrapper>
    );
};
