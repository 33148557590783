import React, { useState, useEffect, useContext } from "react";
import { VendDropDown } from "./VendDropDown";
import { VsourceContext } from "../../context/vsource/vsourceContext";
import API, { graphqlOperation } from "@aws-amplify/api";
import InfiniteScroll from "react-infinite-scroll-component";
import { Dropdown, Icon } from "semantic-ui-react";
import { ListElement } from "../InfiniteDropDown";
import { products as allProductsQuery, productsByName } from "../../graphql/queries";
import styled from "styled-components";
import styledMap from "styled-map";
import { useDebounce } from "react-use";
import onClickOutside from "react-onclickoutside";

const MultiDropDown = styled.div`
    display: block;
    width: 100%;
    min-width: 0;
    cursor: pointer;
    line-height: 1em;
    background-color: #fff;

    border-radius: 0.28571429rem;
    height: 100%;
    padding: 1px;
    color: rgba(0, 0, 0, 0.87);
    font-size: 14px;

    border: 1px solid rgba(34, 36, 38, 0.15);
    transition: box-shadow 0.1s ease, width 0.1s ease;
    box-shadow: none;

    &:hover {
        border-color: rgba(34, 36, 38, 0.35);
        box-shadow: none;
    }

    &:focus {
        border-color: #96c8da;
        box-shadow: none;
    }
`;

const SearchBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    input {
        line-height: 1.21428571em;
        border: none;
        cursor: text;
        font-family: inherit;
        padding: 0.5rem 0 0.5rem 0.5rem;
        width: 100%;
        &::placeholder {
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .search-clear-icon {
        color: rgba(0, 0, 0, 0.87);
        font-size: 12px;
        padding-right: 1.2rem;
    }
`;

const DropDownArrow = styled.div`
    padding: 1rem;
    .search-arrow-down {
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid rgba(0, 0, 0, 0.87);
    }
`;

const VendorProductsList = styled.div`
    max-height: 200px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const vendorProductsQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            full_legal_name
            products {
                id
                name
            }
        }
    }
`;

const productQuery = /* GraphQL */ `
    query Product($id: ID!) {
        product(id: $id) {
            id
            name
            vendor {
                full_legal_name
            }
        }
    }
`;

/*
 * Dropdown component for KeyMetaForm to edit the "productVSourceId" field in ProductAndPricingGroup model
 */

const VendAsyncDropDown = ({ field, form, values, onChange, placeholder, agreement, group, ...props }) => {
    const [vendorProducts, setVendorProducts] = useState([]); // Initial, full list of results
    const [vendorProductsList, setVendorProductsList] = useState([]); // List to display on the dropdown
    const [allProducts, setAllProducts] = useState([]);
    const [vendorProductsLoading, setVendorProductsLoading] = useState(undefined);
    const [allProductsLoading, setAllProductsLoading] = useState(undefined);
    const [initialProductLoading, setInitialProductLoading] = useState(false);
    const [searchInputValue, setSearchInputValue] = useState("");
    const [hasMore, setHasMore] = useState(false); // Only for the all-products list. Vendor products list is always fully loaded
    const [showList, setShowList] = useState(false);
    const [allProductsListMeta, setAllProductsListMeta] = useState({});

    VendAsyncDropDown.handleClickOutside = () => closeDropdown();

    const {
        context: { products },
        fetchProducts,
    } = useContext(VsourceContext);

    useEffect(() => {
        fetchVendorProducts();
        fetchInitialAllProductsList();
        fetchInitialProduct();
        if (!field.value || field.value === "-1") {
            fetchProducts({});
        }
    }, []);

    useEffect(() => {
        // If the product is linked only to the VKey product data
        if ((!field.value || field.value === "-1") && products && products.length > 0) {
            const productsList = products.filter((item) => item.id === group?.productAndPricingGroupProductId);
            if (productsList.length > 0) {
                setSearchInputValue(productsList[0].productName);
                form.setFieldValue(field.name, -1);
            }
        }
    }, [products]);

    // If user enters something into the search input
    useDebounce(
        () => {
            if (searchInputValue && searchInputValue !== "") {
                allProductsSearch(searchInputValue);
            }
        },
        300,
        [searchInputValue]
    );

    const generateProductsList = (data) => {
        /* Generate the list from DB response for the dropdown
            {
                id: string
                value: string
            } 
        */
        if (!data) return [];
        return data.map((el) => ({ id: el.id, value: el.name }));
    };

    const generateAllProductsList = (data) => {
        /* Generate the list from DB response for the dropdown
            {
                id: string
                value: string
            } 
        */
        if (!data) return [];
        return data.map((el) => ({ id: el.product_id, value: `${el.full_legal_name} - ${el.name}` }));
    };

    // Fetch all of the products of the vendor
    const fetchVendorProducts = async () => {
        if (agreement?.vendorVSourceId) {
            try {
                setVendorProductsLoading(true);
                const { data } = await API.graphql(
                    graphqlOperation(vendorProductsQuery, {
                        id: agreement.vendorVSourceId,
                    })
                );
                if (data && data.vendor) {
                    setVendorProducts(generateProductsList(data?.vendor?.products));
                    setVendorProductsList(generateProductsList(data?.vendor?.products));
                }
            } catch (e) {
                console.log("Vendor products fetch error: ", e);
            } finally {
                setVendorProductsLoading(false);
            }
        }
    };

    // Fetch all products from all vendors
    const fetchAllProducts = async (page) => {
        try {
            setAllProductsLoading(true);
            const { data } = await API.graphql(
                graphqlOperation(allProductsQuery, {
                    limit: 20,
                    page,
                    orderBy: "full_legal_name,name",
                    order: "ASC,ASC",
                })
            );
            if (data && data.products) {
                return data.products;
            }
            return {};
        } catch (e) {
            console.log("All products fetch error: ", e);
        } finally {
            setAllProductsLoading(false);
        }
    };

    // Fetch the currently selected product data
    const fetchInitialProduct = async () => {
        if (field?.value && field.value !== "-1") {
            try {
                setInitialProductLoading(true);
                const { data } = await API.graphql(
                    graphqlOperation(productQuery, {
                        id: field.value,
                    })
                );

                if (data && data.product) {
                    setSearchInputValue(
                        `${data.product.vendor?.full_legal_name ? data.product.vendor?.full_legal_name + " -" : ""} ${
                            data.product.name
                        }`
                    );
                }
            } catch (e) {
                console.log("Product fetch error: ", e);
            } finally {
                setInitialProductLoading(false);
            }
        }
    };

    // Fetch the vendors list from the first page
    const fetchInitialAllProductsList = async () => {
        const list = await fetchAllProducts(1);

        setAllProducts(generateAllProductsList(list?.data));
        setAllProductsListMeta(list?.meta);
        setHasMore(list?.meta?.page && list?.meta?.page !== list?.meta?.pageCount);
    };

    const fetchMoreAllProducts = async () => {
        const list = await fetchAllProducts(allProductsListMeta?.page + 1);

        setAllProducts([...allProducts, ...generateAllProductsList(list?.data)]);
        setAllProductsListMeta(list?.meta);
        setHasMore(list?.meta?.page && list?.meta?.page !== list?.meta?.pageCount);
    };

    // Fetch the product search results
    const allProductsSearch = async (text) => {
        try {
            //setProductDataLoading(true);
            // setResultsLoading(true);
            const { data } = await API.graphql(
                graphqlOperation(productsByName, {
                    name: text,
                    limit: 20,
                })
            );
            if (data && data.productsByName && data.productsByName.data) {
                setAllProducts(generateAllProductsList(data.productsByName.data));
                setAllProductsListMeta(data.productsByName.meta);
                setHasMore(false);
            }
            return null;
        } catch (e) {
            console.log("Products fetch error: ", e);
            //newErrorAlert("Error fetching products data");
        } finally {
            //setProductDataLoading(false);
            //setResultsLoading(false);
        }
    };

    const closeDropdown = () => {
        setShowList(false);
    };

    const clearSearchInput = () => {
        setSearchInputValue("");
        form.setFieldValue(field.name, "");
        resetVendorProductList();
        fetchInitialAllProductsList();
    };

    const resetVendorProductList = () => {
        setVendorProductsList(vendorProducts);
    };

    const onProductSearch = (e) => {
        const searchString = e?.target?.value;

        if (searchString || searchString === "") {
            setSearchInputValue(e.target.value);
            if (searchString === "") {
                resetVendorProductList();
                fetchInitialAllProductsList();
            } else {
                setVendorProductsList(
                    vendorProducts.filter((product) => {
                        const productVal = product?.value?.toLowerCase() || "";
                        return productVal.includes(searchString.toLowerCase());
                    })
                );
            }
        } else {
            console.error("No search string found: ", searchString);
        }
    };

    const onProductSelect = (product) => {
        setSearchInputValue(product.value);
        form.setFieldValue(field.name, product.id);
        closeDropdown();
    };

    const Loader = ({ dataLength, inputValue }) => {
        // If the user is not searching
        if (dataLength !== 0 && inputValue === "") {
            return <ListElement text-center>Loading...</ListElement>;
        }
        return <ListElement text-center>No results</ListElement>;
    };

    return (
        <MultiDropDown>
            <SearchBlock>
                <input
                    type="text"
                    value={searchInputValue}
                    placeholder={`Select`}
                    onFocus={() => setShowList(true)}
                    onChange={onProductSearch}
                    disabled={initialProductLoading}
                />
                {!initialProductLoading && (
                    <>
                        {showList === true && searchInputValue !== "" ? (
                            <Icon name="close" className={"search-clear-icon"} onClick={clearSearchInput} />
                        ) : (
                            <DropDownArrow onClick={() => setShowList(!showList)}>
                                <div className="search-arrow-down"></div>
                            </DropDownArrow>
                        )}
                    </>
                )}
            </SearchBlock>
            {showList && (
                <>
                    {vendorProductsList.length > 0 ? (
                        <>
                            <VendorProductsList>
                                {vendorProductsList.map((product) => (
                                    <ListElement key={`vend-${product.id}`} onClick={() => onProductSelect(product)}>
                                        {product.value || "-"}
                                    </ListElement>
                                ))}
                            </VendorProductsList>
                        </>
                    ) : (
                        <ListElement>No results for vendor products</ListElement>
                    )}
                    <hr />
                    {allProducts.length !== 0 && (
                        <InfiniteScroll
                            className={"infinite-scroll"}
                            dataLength={allProducts.length} //This is important field to render the next data
                            next={fetchMoreAllProducts}
                            hasMore={hasMore}
                            height={200}
                            loader={<Loader dataLength={allProducts.length} inputValue={searchInputValue} />}
                            endMessage={
                                <ListElement text-center>
                                    <b>- {props.inputValue === "" ? `No more results found` : ""} -</b>
                                </ListElement>
                            }
                        >
                            {allProducts.map((prod) => (
                                <ListElement key={prod.id} onClick={() => onProductSelect(prod)}>
                                    {prod.value}
                                </ListElement>
                            ))}
                        </InfiniteScroll>
                    )}
                </>
            )}
        </MultiDropDown>
    );
};

const clickOutsideConfig = {
    handleClickOutside: () => VendAsyncDropDown.handleClickOutside,
};

export default onClickOutside(VendAsyncDropDown, clickOutsideConfig);
