export const VPortApiUrl = {
    test: "https://dev-api.vendexsolutions.com/graphql",
    staging: "https://staging-api.vendexsolutions.com/graphql",
};

// For local development
// export const VPortApiUrl = "http://localhost:5000/graphql";

export const USER_ROLES = {
    VENDEX_SUPER_USER: "VendEx-Super-User",
    VSOURCE_VENDEX_SUPER_USER: "VSource-VendEx-Super-User",
    VSOURCE_VENDEX_USER: "VSource-VendEx-User",
    VSOURCE_CLIENT_USER: "VSource-Client-User",
    VKEY_VENDEX_SUPER_USER: "VKey-VendEx-Super-User",
    VKEY_CLIENT_USER: "VKey-Client-User",
    VKEY_CLIENT_SECURE_VAULT_USER: "VKey-Client-Secure-Vault-User",
    VKEY_VENDEX_ANALYST: "VKey-VendEx-Analyst",
    VPORT_CLIENT_USER: "VPort-Client-User",
    VPORT_VENDEX_ANALYST: "VPort-VendEx-Analyst",
    VLINK_VENDOR_USER: "VLink-Vendor-User",
    VLINK_VENDEX_ANALYST: "VLink-VendEx-Analyst",
    VLINK_SUPER_USER: "VLink-VendEx-Super-User",
    VLINK_PREMIUM_USER: "VLink-Premium-User",
    VKEY2_ANALYST: "VKey2-Analyst",
    VKEY2_READ_ONLY: "VKey2-Read-Only",
};

export const USER_STATS_TYPES = {
    LOGIN: "LOGIN",
    SECURE_VAULT_UPLOAD: "SECURE_VAULT_UPLOAD",
    SECURE_VAULT_REMOVE_FILE: "SECURE_VAULT_REMOVE_FILE",
};

export const LOCATIONS_ORDER = [
    "Headquarters",
    "Regional Headquarters",
    "Customer Support Location",
    "Office",
    "Line of Business",
];

export const ACTIVE_VLINK_VENDOR = "ACTIVE_VLINK_VENDOR";

// vidsvTypes constants (don't change order if you are not sure what you are doing!). 
// If you want to add one more than add to the end of array
export const vidsvTypes = ['JOB_ROLE', 'REGULATION', 'DELIVERY_METHOD'];
export const vidsvMultiTypes = ['PRODUCT_SOLUTION', 'PRODUCT_CLASSIFICATION', 'PRODUCT_GEOGRAPHY']

export const vidsvLabels = ['Target Audience', 'Map Product to Regulations', 'Delivery Methods'];
export const vidsvMultiLabels = ['Solutions', 'Classifications', 'Geographies'];

export const vidsvMultiKeys = ['productSolutionTypeCodeArray', 'productClassificatorTypeCodeArray', 'productGeographyTypeCodeArray'];