import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
import { getVsourceProduct, getVsourceVendor } from "../../../graphql/customQueries";

// fetch if all dependencies are true
export function useVsourceResource({level, id = null, premium = false}, dependencies = []) {
    const [data, setData] = useState(null);
    const { id: targetId } = useParams();

    let query;
    let headerQuery;
    if(level === 'VENDOR') {
        query = premium ? VendorPremiumQuery : VendorQuery;
        headerQuery = getVsourceVendor;
    } else {
        query = premium ? ProductPremiumQuery : ProductQuery;
        headerQuery = getVsourceProduct;
    }
    const [fetchVsourceProductData, { loading, data: getVsourceProductData, error, }] =
        useLazyQuery(headerQuery , {
            variables: {
                id: targetId,
            },
            notifyOnNetworkStatusChange: true,
        });

    useEffect( () => {
        const fetchData = async () => {
            try {
                const { data } = await API.graphql(graphqlOperation(query, { id: targetId }));
                if (data && data[level.toLowerCase()]) {
                    setData(data[level.toLowerCase()]);
                }
            } catch (e) {
                console.log("Product fetch error: ", e);
            }
        }
        
        dependencies.forEach(dep => {
            if(dep === false) {
                return;
            }
        })
        console.log('fetch data premium is ' + premium);
        fetchVsourceProductData();
        fetchData();
        
    }, [level, targetId]);

    return {
        ...data,
        ...getVsourceProductData,
    };
}

const VendorQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            id,
            full_legal_name,
            vendor_logo_filename,
            brief_description
            products {
                id
                name
                brief_description
                preview_brief_description
            },
            last_reviewed_by,
            last_reviewed_at,
            last_published_by,
            last_published_at,
            next_review_in_days,
            next_review_in_days_code,
            next_review_at
        }
    }
`;

const VendorPremiumQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            premium_benefit,
            corporate_actions {
                id
                type_code
                type
                action_time
                description
            },
            last_reviewed_by,
            last_reviewed_at,
            last_published_by,
            last_published_at,
            next_review_in_days,
            next_review_in_days_code,
            next_review_at
        }
    }
`;

const ProductQuery = /* GraphQL */ `
    query GetProduct($id: ID!) {
        product(id: $id) {
            id,
            name,
            brief_description
            vendor {
                id
                full_legal_name
                vendor_logo_filename
                brief_description
                preview_brief_description
            }
        }
    }
`;

const ProductPremiumQuery = /* GraphQL */ `
    query GetProduct($id: ID!) {
        product(id: $id) {
            premium_benefit,
            product_change {
                id
                change_time
                change_notes
                change_type
                change_type_original
            }
        }
    }
`;

