import React from 'react'
import { Breadcrumb } from 'semantic-ui-react';
import styled from "styled-components";


const BreadcrumbWrapper = styled.div`
    width: 100%;
    padding: 40px 40px 10px 40px;
    color: ${({ theme }) => theme.darkBlue};
    
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.darkBlue} !important;
    }
`;

// Sections prop example:
/*
  const sections = [
    { key: 'Dashboard', content: 'Home', onClick: () => history.push("/vlink/dashboard") },
    { key: 'Vendor', content: 'Vendor', link: true },
    { key: 'Product', content: 'Product', active: true },
  ]
*/

const VendexBreadcrumb = ({sections}) => (
  <BreadcrumbWrapper>
    <Breadcrumb icon='right angle' sections={sections}/>
  </BreadcrumbWrapper>
)

export default VendexBreadcrumb
