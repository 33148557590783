import React from "react";
import styled from "styled-components/macro";

const PageTitle = styled.h1`
    position: relative;
    display: block;
    font-size: 30px;
`;

export const Wrapper = styled.div`
    position: relative;
    display: flex;
    padding: 16px 40px;
    border-bottom: #eef2f6 1px solid;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 30;
`;

const HeaderControls = styled.div`
    position: relative;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: ${(reverse) => (reverse ? `flex-end` : `flex-start`)};
`;

const Links = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 1.4rem;

    li {
        display: flex;
        align-items: center;
        float: left;
        margin-left: 1em;
    }

    li:last-child {
        margin: 0;
    }

    li:last-child a {
        color: #546982;
        cursor: text;
    }

    li a {
        display: block;
        text-align: center;
        //padding: 14px 16px;
        text-decoration: none;
        cursor: pointer;
        margin-left: 1em;
    }

    li + li::before {
        content: "/"; /* Insert content that looks like bullets */
        margin-left: 1em;
        color: blue; /* Or a color you prefer */
    }
`;

export const PageHeader = ({ title, links, reverse, children, className }) => {
    return (
        <Wrapper className={className}>
            {title && (
                <PageTitle>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: title,
                        }}
                    />
                </PageTitle>
            )}
            <Links>
                {links &&
                    links.map((link, i) => {
                        console.log(i + 1);
                        console.log(links.length);

                        if (links.length === i + 1) {
                            return (
                                <li key={link.label}>
                                    <a>{link.label}</a>
                                </li>
                            );
                        }

                        return (
                            <li key={link.label}>
                                <a onClick={link.link}>{link.label}</a>
                            </li>
                        );
                    })}
            </Links>
            <HeaderControls reverse={reverse}>{children}</HeaderControls>
        </Wrapper>
    );
};
