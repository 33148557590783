import React from "react";
import styled from "styled-components";
import theme from "../styles/theme";
import { generateAgreementName } from "../context/agreement/agreementVSourceData";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: ${theme.accentDark};
    border-radius: 6px;
    width: 600px;
    padding: 30px;
`;
export const WizzySection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 16px;
`;
export const WizzyLabel = styled.h5`
    font-weight: bold;
    opacity: 0.2;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 0;
    //margin-bottom: 16px;
`;
export const WizzyLabelValue = styled.h5`
    font-family: "Muli Light";
    font-weight: 100;
    color: white;
    opacity: 0.2;
    font-size: 40px;
    text-transform: capitalize;
    margin: 0;
`;

export default ({ agreement, selectedVendor, activeEntity }) => {
    return (
        <Wrapper>
            <WizzySection style={{ minHeight: 180 }}>
                <WizzyLabel>Agreement Name</WizzyLabel>
                <WizzyLabelValue>
                    {generateAgreementName(
                        { masterProduct: agreement.masterProductName },
                        selectedVendor,
                        activeEntity
                    )}
                </WizzyLabelValue>
            </WizzySection>

            <WizzySection>
                <WizzyLabel>Client ID</WizzyLabel>
                <WizzyLabelValue>{agreement.customerId}</WizzyLabelValue>
            </WizzySection>
        </Wrapper>
    );
};
