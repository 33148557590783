import styled from "styled-components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactTable from "react-table";
import { Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { read, utils } from "xlsx";
import API, { graphqlOperation } from "@aws-amplify/api";
import { formatToTimeZone } from "date-fns-timezone";
import _get from "lodash/get";
import _uniq from "lodash/uniq";
import cogoToast from "cogo-toast";
import { AuthContext } from "../../context/AuthContext";
import { DocSearch } from "../../components/DocSearch";
import CounterBox from "../../components/CounterBox";
import { VaultFeed } from "../../components/VaultFeed";
import { PrivateRoute } from "../../components/PrivateRoute";
import PdfViewer from "../PdfViewer";
import { WarningBox } from "../../components/MessageBox";
import { createUserStat } from "../../graphql/mutations";
import { USER_STATS_TYPES } from "../../Constants";
import { ButtonWrapper } from "../../components/VendBtn";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";
import UploadIcon from "../../components/icons/UploadIcon";
import DeleteIcon from "../../components/icons/DeleteIcon";
import ArrowIcon from "../../components/icons/ArrowIcon";
import BurgerMenuIcon from "../../components/icons/BurgerMenuIcon";
import GalleryIcon from "../../components/icons/GalleryIcon";
import { useAlerts } from "../../hooks/useAlerts";
import { uploadVKeyDocumentTrackerData, deleteDocument } from "../../graphql/customMutations";

import { generateAgreementName, getVSourceVendorsByID } from "../../context/agreement/agreementVSourceData";
import format from "date-fns/format";

export const InnerPage = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 2;
`;

const LoadingWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;

const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
`;

const HeaderColumn = styled.div``;

const ClientLogoImg = styled.img`
    position: relative;
    display: block;
    max-height: 50px;
    width: auto;
`;

const VaultWrapper = styled.div`
    padding: 2% 2.2rem 2rem 2.2rem;
`;
const PageTitle = styled.h2`
    font-weight: 400 !important;
    margin: 0 0 8px 0;
    padding: 0;
`;
const DocCounter = styled.p`
    margin: 0 0 20px 0;
    padding: 0;
    font-size: 16px !important;
    color: #80808f;
`;
const PageSubtitle = styled.h3`
    margin: 0 0 20px 0;
    padding: 0;
`;
const CountersWrapper = styled.div`
    margin-bottom: 40px;
`;
const SearchWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`;
const SearchHolder = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
`;
const ModeSelectionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
`;
const IconHolder = styled.div`
    margin-right: 10px;
    cursor: pointer;
`;
const DocumentButton = styled(ButtonWrapper)`
    font-size: 12px;
    margin-left: 13px;
`;

const DragRejectedText = styled.span`
    font-weight: lighter;
    font-size: 24px;
    padding: 20px;
    color: #e53935;
`;
const RejectedFileWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
const BtnWrapper = styled.button`
    background: transparent;
    border: none;
    cursor: pointer;
`;
const OpenPdf = styled.span`
    cursor: pointer;
    color: #2564bb;
    white-space: pre-wrap;
`;

const flexStyles = {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
};

const CounterDescription = styled.p`
    font-weight: bold;
    margin-right: auto;
`;

const DownloadResultsButton = styled.span`
    margin-left: 1em;
    color: #6c8bff;
    cursor: pointer;
    padding: 4px 6px;
    border-radius: 6px;
    transition: background-color 200ms ease-in-out;

    &:hover {
        background: #eef1ff;
    }
`;

const DownloadCell = ({ row, isVKeySuperUser }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const filename = _get(row, "original.vendExFileName")?.replace(/%(?!\d|[ABCDEF]+)/g, "%25");

    const handleDocOpen = useCallback(() => {
        const uri = `${match.url.endsWith("/") ? match.url : match.url + "/"}pdf-viewer/${_get(
            row,
            "original.vendExFileName"
        )}`;
        history.push(encodeURI(uri));
    }, []);

    const handleDocOpenTextract = useCallback(() => {
        const uri = `${match.url.endsWith("/") ? match.url : match.url + "/"}document/${_get(
            row,
            "original.vendExFileName"
        )}`;
        history.push(encodeURI(uri));
    }, []);

    return (
        <React.Fragment>
            <OpenPdf onClick={handleDocOpen}>{decodeURIComponent(filename)}</OpenPdf>
        </React.Fragment>
    );
};
const Table = ({ data = [], removeDocument, isVKeySuperUser }) => {
    const [confirmationModalRowIndex, setConfirmationModalRowIndex] = useState(null);

    const defaultHeaderStyles = {
        width: "100%",
        height: "100%",
        fontSize: "1rem",
        lineHeight: 2,
        fontWeight: "bold",
        color: "black",
        textAlign: "center",
        backgroundColor: "#F1F3F4",
        border: "1px solid #F1F3F4",
    };
    const onRemoveRow = (row) => {
        setConfirmationModalRowIndex(null);
        removeDocument(row);
    };

    return (
        <ReactTable
            className="DashboardTable DocumentTable"
            showPagination
            sortable
            multiSort={false}
            resizable={false}
            minRows={0}
            defaultPageSize={20}
            columns={[
                {
                    Header: () => (
                        <div className="sortableTh">
                            Upload Date
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "uploadDate",
                    headerStyle: {
                        ...defaultHeaderStyles,
                        textAlign: "center",
                    },
                    style: {
                        textAlign: "center",
                    },
                    Cell: (row) =>
                        row.original?.createdAt
                            ? formatToTimeZone(new Date(row.original?.createdAt), "DD-MMM-YYYY", { timeZone: "GMT" })
                            : "",
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            Vendor Name
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "docVendorName",
                    headerStyle: {
                        ...defaultHeaderStyles,
                    },
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            Agreement Name
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "docAgreementName",
                    headerStyle: {
                        ...defaultHeaderStyles,
                    },
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            End Date
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "docEndDate",
                    headerStyle: {
                        ...defaultHeaderStyles,
                        textAlign: "center",
                    },
                    style: {
                        textAlign: "center",
                    },
                    Cell: (row) =>
                        row.original?.docEndDate
                            ? formatToTimeZone(new Date(row.original?.docEndDate), "DD-MMM-YYYY", { timeZone: "GMT" })
                            : "",
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            # of Pages
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "docNumberOfPages",
                    width: 100,
                    headerStyle: {
                        ...defaultHeaderStyles,
                        textAlign: "center",
                    },
                    style: {
                        textAlign: "center",
                    },
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            Product(s)
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "docProductNames",
                    headerStyle: {
                        ...defaultHeaderStyles,
                    },
                },
                {
                    Header: () => (
                        <div className="sortableTh">
                            File Name
                            <ArrowIcon className="arrowIcon" />
                        </div>
                    ),
                    accessor: "vendExFileName",
                    headerStyle: {
                        ...defaultHeaderStyles,
                    },
                    Cell: (row) => <DownloadCell isVKeySuperUser={isVKeySuperUser} row={row} />,
                },
                {
                    Header: "Actions",
                    headerStyle: {
                        ...defaultHeaderStyles,
                    },
                    Cell: (row) => {
                        return (
                            <div style={flexStyles}>
                                <BtnWrapper onClick={() => setConfirmationModalRowIndex(row.index)} type="button">
                                    <DeleteIcon />
                                </BtnWrapper>
                                <LargeConfirmationModal
                                    show={row.index === confirmationModalRowIndex}
                                    onClose={() => setConfirmationModalRowIndex(null)}
                                    onSubmit={() => {
                                        onRemoveRow(row);
                                    }}
                                    onDiscard={() => {
                                        console.log("NO!");
                                        setConfirmationModalRowIndex(null);
                                    }}
                                    title="Delete Document?"
                                    description="Once deleted, you will not be able to recover the document"
                                />
                            </div>
                        );
                    },
                },
            ]}
            data={data}
        />
    );
};

export const Vault = ({ match }) => {
    const [docNum, setDocNum] = useState(0);
    const [onUploadVKeyDocumentTrackerData] = useMutation(uploadVKeyDocumentTrackerData);

    const {
        context,
        context: {
            activeEntity: { logoUrl: customerLogo, name: activeEntityName },
        },
        dispatch,
        fetchDocs,
        uploadDoc,
        isVKeySuperUser,
        isVKeyClientUserOnly,
    } = useContext(AuthContext);
    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const [isLoadingExcelFile, setIsLoadingExcelFile] = useState(false);
    const [documentViewType, setDocumentViewType] = useState("table");
    const [vendors, setVendors] = useState({});

    const onDrop = useCallback((acceptedFiles) => {
        handleUploadDoc(acceptedFiles);
    }, []);

    useEffect(() => {
        const num = Object.keys(context.docList).reduce((memo, docSectionKey) => {
            const sectionLength = context.docList[docSectionKey]["docs"].length;
            memo += sectionLength;
            return memo;
        }, 0);

        setDocNum(num);
    }, [context.docList]);
    const [deleteDocumentMutation] = useMutation(deleteDocument);

    const removeDocument = useCallback(
        (row) => {
            const id = _get(row, "original.id");
            const filename = _get(row, "original.vendExFileName");
            const agreements = _get(row, "original.agreements.items", []);
            if (agreements.length > 0) {
                const single = `Warning, this file is attached to an Agreement, please disassociate this file from the Agreement before you delete it.`;
                const multiple = `Warning, this file is attached to ${agreements.length} Agreements, please disassociate this file from the Agreements before you delete it.`;

                const headerText = agreements.length > 1 ? multiple : single;

                const { hide } = cogoToast.error(
                    <>
                        <h5>{headerText}</h5>
                        {agreements.map((agreementItem, index) => {
                            const agreement = _get(agreementItem, "agreement");
                            const vendorVSourceId = _get(agreement, "vendorVSourceId");
                            const full_legal_name = _get(vendors, `[${vendorVSourceId}].full_legal_name`);

                            return (
                                !!agreement && (
                                    <div key={index}>
                                        {generateAgreementName(agreement, full_legal_name, context.activeEntity)}
                                    </div>
                                )
                            );
                        })}
                    </>,

                    {
                        hideAfter: 0,
                        onClick: () => {
                            hide();
                        },
                    }
                );

                return;
            }

            // Log the file remove information to the DB
            API.graphql(
                graphqlOperation(createUserStat, {
                    input: {
                        username: _get(context.user, "attributes.email"),
                        userId: _get(context.user, "username"),
                        type: USER_STATS_TYPES.SECURE_VAULT_REMOVE_FILE,
                        value: JSON.stringify({
                            name: filename,
                        }),
                    },
                })
            );

            deleteDocumentMutation({ variables: { documentUuid: id } })
                .then((response) => {
                    refreshDocs();
                })
                .catch((err) => console.error(err));
        },
        [vendors]
    );

    const onChangeDocumentViewType = (type) => {
        setDocumentViewType(type);
        refreshDocs();
    };

    const {
        acceptedFiles,
        getInputProps: getPdfProps,
        open: openPdf,
    } = useDropzone({
        accept: ".pdf,application/pdf",
        noDragEventsBubbling: true,
        onDrop,
        noClick: true,
    });

    const onDropCallback = useCallback(async (acceptedFiles) => {
        try {
            setIsLoadingExcelFile(true);
            const buffer = await acceptedFiles[0].arrayBuffer();
            const wb = read(buffer);
            const trackerTable = _get(wb, `Sheets[${_get(wb, "SheetNames[6]")}]`);
            let data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[6]], { header: 1 });
            if (data && Array.isArray(data)) {
                // Filter out the rows without the data (except the first two rows).
                // Filtered by the 'Doc Name' column
                data = data.filter((row, index) => index === 0 || index === 1 || row[1] !== undefined);
            }

            if (!!trackerTable) {
                const values = data.slice(2);
                const encoded = values.map((value) => {
                    const [, docName] = value;
                    const newValues = [...value];
                    newValues[1] = encodeURIComponent(docName?.trim());
                    return newValues;
                });

                const { data: dataResp } = await onUploadVKeyDocumentTrackerData({
                    variables: {
                        fields: _get(data, `[1]`, []),
                        values: encoded,
                    },
                });
                const successStatus = _get(dataResp, "vkey.uploadVKeyDocumentTrackerData.success");
                if (successStatus) {
                    newSuccessAlert("Document data updated successfully");
                } else {
                    newErrorAlert("Error uploading excel file");
                }
            } else {
                newErrorAlert("The uploaded file is invalid");
            }
        } catch (e) {
            newErrorAlert("Error uploading excel file");
            console.log(e);
        } finally {
            setIsLoadingExcelFile(false);
        }
    }, []);

    const { getInputProps: getXlsProps, open: openXlsx } = useDropzone({
        accept: ".xlsx, .xls",
        noClick: true,
        onDrop: onDropCallback,
        noKeyboard: true,
    });

    useEffect(() => {
        if (!isLoadingExcelFile) {
            refreshDocs();
        }
    }, [isLoadingExcelFile]);

    const refreshDocs = useCallback(async () => {
        dispatch({ type: "LOADING_DOCS" });
        // Load vendors data from VSource
        async function getVendors(ids) {
            const res = await getVSourceVendorsByID(ids);
            setVendors(res);
        }

        fetchDocs({ entityGroup: context.activeEntity?.groupName })
            .then(({ docList, docItems }) => {
                dispatch({ type: "FETCH_DOCS", docList, docItems });
                // Get the VSource vendor IDs of every agreement
                let IDs = [];
                Object.values(docList).forEach((docs) => {
                    const list = docs.docs;
                    list.forEach((el) => {
                        if (el.agreements.length > 0) {
                            el.agreements.forEach((agreement) => {
                                IDs.push(agreement?.vendorVSourceId);
                            });
                        }
                    });
                });

                IDs = _uniq(IDs).join(",");
                getVendors(IDs);
            })
            .catch((e) => {
                console.error(e);
                dispatch({ type: "FETCH_DOCS", docList: {} });
            });
    }, []);

    const handleUploadDoc = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((acceptedFile) => {
                try {
                    // Log the file upload information to the DB
                    API.graphql(
                        graphqlOperation(createUserStat, {
                            input: {
                                username: _get(context.user, "attributes.email"),
                                userId: _get(context.user, "username"),
                                type: USER_STATS_TYPES.SECURE_VAULT_UPLOAD,
                                value: JSON.stringify({
                                    name: acceptedFile.name,
                                    type: acceptedFile.type,
                                    size: acceptedFile.size,
                                    last_modified: acceptedFile.lastModifiedDate,
                                }),
                            },
                        })
                    );
                } catch (e) {
                    console.error("Error sending user stat:", e);
                }

                uploadDoc(
                    acceptedFile,
                    {
                        progressCallback: (progress) => {
                            const payload = {
                                type: "ADD_DOWNLOAD",
                                downloadList: {
                                    ...context.downloadList,
                                },
                            };
                            // If the file is small and uploaded in one part then there is no progress and no key
                            if (progress.key) {
                                payload.downloadList[progress.key.split("/").splice(1).join("/")] = {
                                    name: acceptedFile.name,
                                    loaded: progress.loaded,
                                    total: progress.total,
                                };
                            }
                            dispatch(payload);
                        },
                    },
                    context.activeEntity.groupName
                )
                    .then((result) => {
                        // console.log("after upload result ", result);
                        dispatch({ type: "REMOVE_DOWNLOAD", key: result.key });
                        refreshDocs();
                    })
                    .catch((err) => {
                        console.error("after upload error ", err);
                    });
            });
        },
        [acceptedFiles]
    );

    const handleDownloadSearch = useCallback(() => {
        console.log(context?.searchItems);

        const rows = [
            ["Upload Date", "Vendor Name", "Agreement Name", "End Date", "Number of Pages", "Product(s)", "File Name"],
        ];

        context?.searchItems.forEach((row) => {
            const uploadDate = row.createdAt || row.uploadDate || null;
            const endDate = row.docEndDate || null;

            const formattedUploadDate = uploadDate
                ? formatToTimeZone(new Date(uploadDate), "DD-MMM-YYYY", { timeZone: "GMT" })
                : "";
            const formattedEndDate = endDate
                ? formatToTimeZone(new Date(endDate), "DD-MMM-YYYY", { timeZone: "GMT" })
                : "";

            rows.push([
                formattedUploadDate,
                row.docVendorName,
                row.docAgreementName,
                formattedEndDate,
                row.docNumberOfPages,
                row.docProductNames,
                decodeURIComponent(row.vendExFileName),
            ]);
        });

        const csvContent = rows
            .map((e) => {
                const values = e.map((value) => (value ? `"${value}"` : '""'));
                return values.join(",");
            })
            .join("\n");

        const fileName = `secure-vault-${formatToTimeZone(new Date(), "DD-MMM-YYYY", { timeZone: "GMT" })}.csv`;
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const href = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", href);
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }, [context]);

    const clearSearch = () => {
        dispatch({ type: "CLEAR_DOCS" });
    };

    useEffect(() => {
        refreshDocs();
        // Reset the search
        dispatch({ type: "DOC_SEARCH", docSearch: "", searchItems: [] });
    }, []);

    const showUploadBtn =
        context.entities?.length !== 0 && (isVKeySuperUser(context.user) || isVKeyClientUserOnly(context.user));
    const showExcelBtn = context.entities?.length !== 0 && isVKeySuperUser(context.user);

    if (context.entities?.length === 0) {
        return (
            <InnerPage>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                    <WarningBox
                        message={`You are permissioned as a VKey User but have not been assigned to a client firm. Please contact
                        your VendEx Sales Representative or Account Manager.`}
                    />
                </div>
            </InnerPage>
        );
    }

    return (
        <InnerPage>
            <VaultWrapper>
                <HeaderRow>
                    <HeaderColumn>
                        <PageTitle>Secure Vault</PageTitle>
                        <DocCounter>{docNum} files</DocCounter>
                    </HeaderColumn>
                    <HeaderColumn>
                        <ClientLogoImg src={customerLogo} />
                    </HeaderColumn>
                </HeaderRow>
                <PageSubtitle>File Overview</PageSubtitle>
                <CountersWrapper>
                    <CounterBox title="Files Uploaded" value={docNum} />
                </CountersWrapper>
                <SearchWrapper>
                    <SearchHolder>
                        <DocSearch />
                        <DocumentButton onClick={() => clearSearch()}>Clear Search</DocumentButton>
                    </SearchHolder>
                    {showUploadBtn && (
                        <DocumentButton onClick={() => openPdf()}>
                            <input {...getPdfProps()} />
                            <UploadIcon />
                            File Upload
                        </DocumentButton>
                    )}
                    {showExcelBtn && (
                        <>
                            <DocumentButton disabled={isLoadingExcelFile} onClick={openXlsx}>
                                <input {...getXlsProps()} />
                                <UploadIcon />
                                Excel Upload
                            </DocumentButton>
                        </>
                    )}
                </SearchWrapper>
                <ModeSelectionWrapper>
                    <CounterDescription>
                        {context.docSearch ? (
                            <>
                                {`Showing ${context?.searchItems?.length} result(s) for keyword ${context.docSearch}.`}
                                {context?.searchItems?.length > 0 && (
                                    <DownloadResultsButton onClick={handleDownloadSearch}>
                                        Download Results
                                    </DownloadResultsButton>
                                )}
                            </>
                        ) : (
                            `Showing ${context?.docItems?.length} results.`
                        )}
                    </CounterDescription>
                    <IconHolder onClick={() => onChangeDocumentViewType("gallery")}>
                        <GalleryIcon fill={documentViewType === "gallery" ? "#6C8BFF" : "#C8C8C8"} />
                    </IconHolder>
                    <IconHolder onClick={() => onChangeDocumentViewType("table")}>
                        <BurgerMenuIcon fill={documentViewType === "table" ? "#6C8BFF" : "#C8C8C8"} />
                    </IconHolder>
                </ModeSelectionWrapper>
                <Switch>
                    <PrivateRoute path={`${match.url}/pdf-viewer/:id`} component={PdfViewer} />
                    <PrivateRoute
                        path={`${match.url}`}
                        component={documentViewType === "table" ? Table : VaultFeed}
                        vendors={vendors}
                        data={!!context.docSearch ? context.searchItems : context.docItems}
                        removeDocument={removeDocument}
                        isVKeySuperUser={isVKeySuperUser(context.user)}
                    />
                </Switch>
            </VaultWrapper>
        </InnerPage>
    );
};
