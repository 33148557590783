import React from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import { ButtonWrapper } from "./VendBtn";
import { Icon } from "semantic-ui-react";

const PopUp = styled.div`
    min-width: 300px;
    max-width: 400px;
    min-height: 400px;
    border-radius: 4px;
    z-index: 1000;
    background-color: white;
    position: absolute;
    top: 20vh;
    left: calc(50% - 300px);
    box-shadow: 2px 2px 20px 1px #888888;

    .handle {
        color: white;
        background-color: #6c8bff;
        cursor: move;
        text-align: center;
        padding: 10px 0;
        font-weight: 700;
        font-size: 16px;
        border-radius: 4px 4px 0 0;
    }

    .content {
        padding: 1rem;
    }

    .buttonsRow {
        padding: 0 1rem 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
            font-size: 26px;
            margin-right: 2rem;
            color: ${({ theme }) => theme.buttonColor};
        }
    }
`;

export const SendEmailPopup = ({ show, onSubmit, onCancel, title, submitDisabled, children, loading }) => {
    if (!show) return null;

    return (
        <Draggable handle=".handle" bounds={"body"}>
            <PopUp>
                <div className="handle">{title}</div>
                <div className="content">{children}</div>
                <div className="buttonsRow">
                    <ButtonWrapper onClick={onCancel}>Cancel</ButtonWrapper>
                    {!loading && (
                        <ButtonWrapper onClick={onSubmit} disabled={submitDisabled}>
                            Send
                        </ButtonWrapper>
                    )}
                    {loading && <Icon loading name="spinner" />}
                </div>
            </PopUp>
        </Draggable>
    );
};
