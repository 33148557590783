import React from "react";
import _sortBy from "lodash/sortBy";
import _orderBy from "lodash/orderBy";

import { FormTable } from "../../../components/Tables";
import { Columns } from "./VendorConfiguratorPage";
import { LOCATIONS_ORDER } from "../../../Constants";

export const LocationsTables = ({
    data = [],
    locationTypes,
    regions,
    timeZones,
    countries,
    setLocationValue,
    setCustomerSupportValue,
    removeLocationRow,
    removeCustomerSupportRow,
    addCustomerSupportRow,
}) => {
    const sortedLocationTypes = _sortBy(_orderBy(locationTypes, ["label"], ["asc"]), (item) => {
        return LOCATIONS_ORDER.indexOf(item.label) !== -1 ?
            LOCATIONS_ORDER.indexOf(item.label) :
            locationTypes.length;
    })
    return data.map((location, i) => {
        const data = [
            {
                title: "Location Name",
                value: location.name,
                onChange: (val) => setLocationValue("name", val, location.id),
                props: {
                    onRemove: () => removeLocationRow(location.id),
                },
            },
            {
                title: "Location Type",
                type: "dropdown",
                value: sortedLocationTypes,
                props: {
                    selected: location.type_code || locationTypes[0].value,
                },
                onChange: (e) =>
                    setLocationValue("type_code", e.value, location.id),
            },
            {
                title: "Region",
                type: "dropdown",
                value: regions,
                props: { selected: location.region_code, isClearable: true },
                onChange: (e) =>
                    setLocationValue("region_code", e.value, location.id),
            },
            {
                title: "Division",
                value: location.division,
                onChange: (val) =>
                    setLocationValue("division", val, location.id),
            },
            {
                title: "Country",
                type: "dropdown",
                value: countries,
                props: { selected: location.country_code, isClearable: true },
                onChange: (e) =>
                    setLocationValue("country_code", e.value, location.id),
            },
            {
                title: "Street Address 1",
                value: location.street_address_1,
                onChange: (val) =>
                    setLocationValue("street_address_1", val, location.id),
            },
            {
                title: "Street Address 2",
                value: location.street_address_2,
                onChange: (val) =>
                    setLocationValue("street_address_2", val, location.id),
            },
            {
                title: "City",
                value: location.city,
                onChange: (val) => setLocationValue("city", val, location.id),
            },
            {
                title: "State/Province",
                value: location.state_province,
                onChange: (val) =>
                    setLocationValue("state_province", val, location.id),
            },
            {
                title: "Postal Code",
                value: location.postal_code,
                onChange: (val) =>
                    setLocationValue("postal_code", val, location.id),
            },
            {
                title: "Phone Number",
                value: location.phone_number,
                onChange: (val) =>
                    setLocationValue("phone_number", val, location.id),
            },
        ];

        const customerSupportBlock = () => {
            if (
                location.customer_support &&
                location.customer_support.length > 0
            ) {
                return location.customer_support.map((cs, a) => {
                    const customerSupportData = [
                        {
                            title: "Customer Support Phone",
                            value: cs.phone_number,
                            onChange: (val) =>
                                setCustomerSupportValue(
                                    "phone_number",
                                    val,
                                    cs.id,
                                    location.id
                                ),
                            props: {
                                onRemove: () =>
                                    removeCustomerSupportRow(
                                        location.id,
                                        cs.id
                                    ),
                            },
                        },
                        {
                            title: "Customer Support Time Zone",
                            type: "dropdown",
                            value: timeZones,
                            props: {
                                selected: cs.time_zone_code,
                                isClearable: true,
                            },
                            onChange: (e) =>
                                setCustomerSupportValue(
                                    "time_zone_code",
                                    e.value,
                                    cs.id,
                                    location.id
                                ),
                        },
                        {
                            title: "Customer Support Hours",
                            value: cs.support_hours,
                            onChange: (val) =>
                                setCustomerSupportValue(
                                    "support_hours",
                                    val,
                                    cs.id,
                                    location.id
                                ),
                        },
                        {
                            title: "Customer Support Email",
                            value: cs.email_address,
                            onChange: (val) =>
                                setCustomerSupportValue(
                                    "email_address",
                                    val,
                                    cs.id,
                                    location.id
                                ),
                        },
                        {
                            title: "Customer Service Portal",
                            value: cs.support_portal_url,
                            onChange: (val) =>
                                setCustomerSupportValue(
                                    "support_portal_url",
                                    val,
                                    cs.id,
                                    location.id
                                ),
                        },
                    ];
                    return (
                        <>
                            <FormTable
                                data={customerSupportData}
                                title={"Customer Support"}
                                titleMargin={false}
                                key={`cs-${a}`}
                            />
                            <div style={{ paddingLeft: "2rem" }}>
                                <button
                                    onClick={() =>
                                        addCustomerSupportRow(location.id)
                                    }
                                >
                                    Add Customer Support Information
                                </button>
                            </div>
                        </>
                    );
                });
            } else {
                return (
                    <div
                        style={{
                            paddingLeft: "2rem",
                            top: "10%",
                            position: "relative",
                        }}
                    >
                        <button
                            onClick={() => addCustomerSupportRow(location.id)}
                        >
                            Add Customer Support Information
                        </button>
                    </div>
                );
            }
        };

        return (
            <Columns key={`loc-${location.id}`}>
                <FormTable title="Location" titleMargin={false} data={data} />
                <div style={{ display: "block" }}>{customerSupportBlock()}</div>
            </Columns>
        );
    });
};
