// Check if the browser supports localstorage
const isLocalStorageAvailabe = () => {
    const res = 'localStorage' in window && window['localStorage'] !== null;
    if(!res){
        console.error("LocalStorage not available");
    }
    return res;
}

export const setStorage = (key, data) => {
    if(!isLocalStorageAvailabe()){
        return
    }

    try {
        if (key && data) {
            localStorage.setItem(key, JSON.stringify(data));
        } else {
            console.error("Error storing to LocalStorage: key or data is missing");
            console.log("key:", key);
            console.log("data:", data);
        }
    } catch (e) {
        console.error("Error storing to LocalStorage: ", e);
    }
};

export const getStorage = (key) => {
    if(!isLocalStorageAvailabe()){
        return
    }

    try {
        if (!localStorage.getItem(key)) {
            return undefined;
        }
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        console.error("Error parsing JSON: ", e);
        console.log("Value: ", localStorage.getItem(key));
    }
};

export const removeStorage = (key) => {
    if(!isLocalStorageAvailabe()){
        return
    }

    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.error("Error removing element from LocalStorage: ", e);
    }
};
