import styled from "styled-components";
import DownloadPauseIcon from "./icons/DownloadPauseIcon";
import DownloadStopIcon from "./icons/DownloadStopIcon";
import * as PropTypes from "prop-types";
import React from "react";

const DownloadItemWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 16px;
`;
const DownloadSeparator = styled.div`
    position: absolute;
    display: block;
    height: 1px;
    background: #EDEDED;
    bottom: 0;
    right: 16px;
    left: 16px;
`;
const DownloadTitle = styled.span`
    position: relative;
    display: block;
    font-size: 18px;
    color: #3D3D3D;
    padding: 8px 0;
`;
const BitsLabel = styled.span`
    position: relative;
    display: block;
    font-size: 12px;
    color: #B7B7B7;
`;
const ProgressBar = styled.div`
    flex: 2;
    position: relative;
    display: block;
    
    background: #D8D8D8;
    border-radius: 4px;
`;
const Progress = styled.div`
    position: relative;
    display: block;
    height: 100%;
    width: ${({progress}) => progress}%;
    background: #64ACFF;
    border-radius: 4px;
`;
const EstimatedTime = styled.div`
    position: absolute;
    display: block;
    font-size: 10px;
    color: #B7B7B7;
    text-align: right;
    top: 16px;
    right: 16px;
`;
const DownloadControls = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 16px 0;
    
    & > * {
      margin-right: 16px;
    }
`;
export const DownloadItem = (props) => {

    const formatBytes = (bytes,decimals) => {
        if(bytes === 0) return '0 Bytes';
        const k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    };

    return (
        <DownloadItemWrapper>
            <DownloadTitle>{props.name}</DownloadTitle>
            <BitsLabel>{`${formatBytes(props.loaded)} of ${formatBytes(props.total)}`}</BitsLabel>
            <EstimatedTime>{`${props.estimated}`}</EstimatedTime>
            <DownloadControls>
                <ProgressBar>
                    <Progress progress={(props.loaded / props.total * 100)}/>
                </ProgressBar>
                <DownloadPauseIcon/>
                <DownloadStopIcon/>
            </DownloadControls>
            <DownloadSeparator/>
        </DownloadItemWrapper>
    );
};

DownloadItem.propTypes = {
    name: PropTypes.any,
    loaded: PropTypes.any,
    total: PropTypes.any,
    estimated: PropTypes.any
};