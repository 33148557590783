import React, { useCallback, useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { AgreementContext } from "../context/agreement/AgreementContext";
import { get } from "lodash";
import { AuthContext } from "../context/AuthContext";
import { PageHeader } from "../components/PageHeader";
import { WarningBox } from "../components/MessageBox";
import { useAlerts } from "../hooks/useAlerts";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";

import AddSharp from "@material-ui/icons/AddSharp";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";

const StyledTableRow = withStyles((theme) => ({
    root: {},
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#eef2f6",
        color: "#455a87",
        // height: 20
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableHead = withStyles((theme) => ({
    root: {
        height: 20,
    },
}))(TableHead);

const HeaderButton = withStyles((theme) => ({
    root: {},
    label: {
        textAlign: "left",
        textTransform: "capitalize",
    },
    text: {
        textAlign: "left",
    },
}))(Button);

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        minWidth: 300,
        width: "100%",
    },
    tableRow: {
        // '&:nth-of-type(odd)': {
        //     backgroundColor: "#FFF4DB",
        // },
    },
    table: {
        minWidth: 650,
    },
}));

const ClientLogoImg = styled.img`
    position: relative;
    display: block;
    max-height: 50px;
    width: auto;
`;

const useAgreementSummaryNav = (vendorName) => {
    let history = useHistory();

    let location = useLocation();

    const goToAgreementTerms = useCallback(
        (id, product, agreementId, vId) => {
            // dispatch({type: "SELECT_VENDOR", vendorName});
            // console.log("agreementId : ", agreementId);
            console.log("goToAgreementTerms vendorId : ", vId);
            history.push(
                `${location.pathname}/AgreementTerms/${encodeURIComponent(product)}/${id}/${agreementId}/${vId}`
            );
        },
        [vendorName]
    );

    return {
        goToAgreementTerms,
    };
};

const AgreementSummaryButton = (props) => {
    // console.log("AgreementSummaryButton vendorName : ", props);

    const { metaId, masterProduct, agreementId, vendorId, vendorName } = props;

    const { goToAgreementTerms } = useAgreementSummaryNav(vendorName);

    return (
        <div
            style={{ cursor: metaId ? "pointer" : "not-allowed" }}
            onClick={() => {
                goToAgreementTerms(metaId, masterProduct, agreementId, vendorId);
            }}
        >
            {masterProduct}
        </div>
    );
};

const InnerRow = withRouter((props) => {
    const { row, vendorVSourceID } = props;

    const vendorName = get(row, "vendorName");
    const products = get(row, "products");
    const agreementId = get(row, "agreementId");

    return (
        <TableContainer elevation={0} component={Paper}>
            <Table size="small" aria-label="products">
                {/*<StyledTableHead style={{display: 'none'}}>*/}
                {/*    <TableRow>*/}
                {/*        <StyledTableCell style={{ width: 10 }}/>*/}
                {/*        <StyledTableCell align="left">*/}
                {/*            <Typography fullWidth>*/}
                {/*                Vendor*/}
                {/*            </Typography>*/}
                {/*        </StyledTableCell>*/}
                {/*        <StyledTableCell align="left">*/}
                {/*            <Typography fullWidth>*/}
                {/*                Vendor ID*/}
                {/*            </Typography>*/}
                {/*        </StyledTableCell>*/}
                {/*        <StyledTableCell align="left">*/}
                {/*            <Typography fullWidth>*/}
                {/*                Product(s)*/}
                {/*            </Typography>*/}
                {/*        </StyledTableCell>*/}
                {/*        <StyledTableCell align="left">*/}
                {/*            <Typography fullWidth>*/}
                {/*                Documents*/}
                {/*            </Typography>*/}
                {/*        </StyledTableCell>*/}
                {/*        <StyledTableCell align="left">*/}
                {/*            <Typography fullWidth>*/}
                {/*                Product/Service Order*/}
                {/*            </Typography>*/}
                {/*        </StyledTableCell>*/}
                {/*    </TableRow>*/}
                {/*</StyledTableHead>*/}
                <TableBody style={{ padding: 0 }}>
                    {products.map((product) => (
                        <TableRow key={product.id}>
                            <StyledTableCell style={{ width: 60 }} />
                            <TableCell
                                component="th"
                                scope="row"
                                align={"left"}
                                style={
                                    {
                                        // width: widths.vendor
                                    }
                                }
                            >
                                <Typography>{vendorName}</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" align={"left"}>
                                <Typography>-</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" align={"left"}>
                                {product.meta && get(product.meta, "id") ? (
                                    <AgreementSummaryButton
                                        metaId={product.meta.id}
                                        vendorId={vendorVSourceID}
                                        agreementId={product.agreementId}
                                        masterProduct={product.masterProduct}
                                    />
                                ) : null}
                            </TableCell>
                            <TableCell component="th" scope="row" align="left">
                                <Typography>{product.documents.items.length}</Typography>
                            </TableCell>
                            <TableCell component="th" scope="row" align={"left"}>
                                {product.meta && get(product.meta, "productServiceOrderNumber") ? (
                                    <Typography>{product.meta.productServiceOrderNumber}</Typography>
                                ) : (
                                    "-"
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
});

const AgreementStats = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    min-height: ${8 * 14}px;
    background: #eef2f6;
    width: 100%;
    border-radius: 3px;
    margin: ${8 * 2}px 0;
    padding: ${8 * 2}px;
`;

const AgreementStat = styled.div`
    position: relative;
    display: flex;
    border: 1px solid #455a87;
    border-radius: 3px;
    min-width: ${8 * 9}px;
    //padding: ${8 * 2}px;
    margin: 0 ${8 * 2}px 0 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const AgreementStatTitle = styled.div`
    position: relative;
    display: block;
    font-size: 24px;
    font-weight: lighter;
    color: #455a87;
    flex: 0 0 ${8 * 10}px;
    line-height: 28px;
    margin-left: ${8 * 2}px;
`;

const AgreemnentStatNum = styled.div`
    position: relative;
    display: flex;
    flex: 0 0 30px;
    font-size: 30px;
    font-weight: bold;
    color: #455a87;
    //width: 40px;
    line-height: 28px;
    margin-right: ${8 * 2}px;
    justify-content: center;
    align-items: center;
`;

/*
    Summary Page
 */
const SummaryList = () => {
    const [open, setOpen] = useState(null);
    const [sort, setSort] = useState(true);
    const [stats, setStats] = useState({});
    const [summaryList, setSummaryList] = useState([]);

    const { fetchSummaryList } = useContext(AgreementContext);
    const {
        context: {
            activeEntity: { logoUrl: customerLogo, name: activeEntityName },
            entities,
        },
    } = useContext(AuthContext);

    const { newErrorAlert } = useAlerts();

    useEffect(() => {
        // If user has no access to any partition then the request should not be made
        if (activeEntityName && entities?.length > 0) {
            fetchSummaryList({ sort }).then(({ stats, list, errors }) => {
                setStats(stats);
                setSummaryList(list);

                if (errors.length > 0) {
                    errors.forEach((err) => newErrorAlert(err));
                }
            });
        }
    }, [sort]);

    if (entities?.length === 0) {
        return (
            <>
                <PageHeader title={"Summary"}></PageHeader>
                <WarningBox
                    message={`You are permissioned as a VKey User but have not been assigned to a client firm. Please contact
                        your VendEx Sales Representative or Account Manager.`}
                />
            </>
        );
    }

    return (
        <>
            <PageHeader title={"Summary"}>
                <ClientLogoImg src={customerLogo} />
            </PageHeader>

            <AgreementStats style={{ flex: "0 0 40px" }}>
                <AgreementStat>
                    <AgreementStatTitle>Total Vendors</AgreementStatTitle>
                    <AgreemnentStatNum>{stats.totalVendors}</AgreemnentStatNum>
                </AgreementStat>

                <AgreementStat>
                    <AgreementStatTitle>Total Agreements</AgreementStatTitle>
                    <AgreemnentStatNum>{stats.totalAgreements}</AgreemnentStatNum>
                </AgreementStat>

                <AgreementStat>
                    <AgreementStatTitle>Total Documents</AgreementStatTitle>
                    <AgreemnentStatNum>{stats.totalDocuments}</AgreemnentStatNum>
                </AgreementStat>
            </AgreementStats>

            <TableContainer elevation={0} component={Paper}>
                <Table size="small" aria-label="a dense table">
                    <StyledTableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: 10 }} />
                            <StyledTableCell align="left">
                                <ButtonBase
                                    onClick={() => setSort(!sort)}
                                    focusRipple
                                    style={{
                                        width: "100%",
                                        textAlign: "left",
                                        justifyContent: "space-between",
                                        padding: ".3em 1em",
                                    }}
                                >
                                    <Typography>Vendor</Typography>
                                    {sort ? (
                                        <ArrowDownwardIcon style={{ fontSize: 14 }} />
                                    ) : (
                                        <ArrowUpward style={{ fontSize: 14 }} />
                                    )}
                                </ButtonBase>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography>Vendor ID</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography>Product(s)</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography>Documents</Typography>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                                <Typography>Product/Service Order</Typography>
                            </StyledTableCell>
                        </TableRow>
                    </StyledTableHead>
                    <TableBody>
                        {summaryList.map((vendor, vendorNum) => {
                            // console.log("vendor : ", vendor);

                            const { vendorName, vendorId, products, documents, vendorVSourceID } = vendor;

                            // console.log("products : ", products);

                            return (
                                <React.Fragment key={vendorVSourceID}>
                                    <StyledTableRow
                                        style={{
                                            background: vendorNum % 2 ? "none" : "#FFF4DB",
                                        }}
                                    >
                                        <StyledTableCell>
                                            {products.length > 1 ? (
                                                <IconButton
                                                    aria-label="expand row"
                                                    size="small"
                                                    onClick={() => {
                                                        if (open === vendorVSourceID) {
                                                            setOpen(null);
                                                        } else {
                                                            setOpen(vendorVSourceID);
                                                        }
                                                    }}
                                                >
                                                    {open === vendorVSourceID ? (
                                                        <RemoveIcon
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        />
                                                    ) : (
                                                        <AddSharp
                                                            style={{
                                                                fontSize: 14,
                                                            }}
                                                        />
                                                    )}
                                                </IconButton>
                                            ) : (
                                                ""
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {vendorName}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {vendorId}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {products.length === 1 && get(products[0].meta, "id") ? (
                                                <AgreementSummaryButton
                                                    metaId={products[0].meta.id}
                                                    agreementId={products[0].agreementId}
                                                    vendorId={vendorVSourceID}
                                                    vendorName={vendorName}
                                                    masterProduct={products[0].masterProduct}
                                                />
                                            ) : (
                                                "-"
                                            )}
                                        </StyledTableCell>
                                        <StyledTableCell>{documents}</StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {products.length === 1 && get(products[0].meta, "productServiceOrderNumber")
                                                ? products[0].meta.productServiceOrderNumber
                                                : "-"}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                    <StyledTableRow>
                                        <StyledTableCell component="th" style={{ padding: 0 }} colSpan={6}>
                                            <Collapse in={open === vendorVSourceID} timeout="auto" unmountOnExit>
                                                <InnerRow row={vendor} vendorVSourceID={vendorVSourceID} />
                                            </Collapse>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default SummaryList;
