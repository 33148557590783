import React from "react";

const LockIcon = props => (
    <svg width={12} height={17} {...props}>
        <path
            d="M6 5.29a6.12 6.12 0 0 1 2.539.547v-1.88c0-3.236-5.079-3.237-5.079 0v1.88A6.135 6.135 0 0 1 6 5.291zm4.11 1.583A5.726 5.726 0 0 1 12 11.116c0 3.217-2.686 5.825-6 5.825-3.313 0-6-2.608-6-5.825 0-1.61.683-3.143 1.89-4.243V3.958c0-5.277 8.22-5.277 8.22 0v2.915zm-3.126 3.283c0-1.264-1.969-1.264-1.969 0v1.987c0 1.265 1.97 1.265 1.97 0v-1.987z"
            fill="#222"
            fillRule="nonzero"
        />
    </svg>
);

export default LockIcon;

