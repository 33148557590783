import { Button, Modal } from "semantic-ui-react";

export default function ConfirmationDialog({ open, onCloseClick, onSubmitClick, closeOnSubmit, message }) {
    function submitHandler() {
        onSubmitClick();
        if (closeOnSubmit) {
            onCloseClick();
        }
    }

    return (
        <Modal
            closeIcon
            onClose={onCloseClick}
            open={open}
            style={{ width: '500px' }}
        >
            <Modal.Header>Alert</Modal.Header>
            <Modal.Content style={{ padding: '2rem' }}>
                {message || 'Are you sure?'}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onCloseClick}>
                    Cancel
                </Button>
                <Button color='yellow' onClick={submitHandler}>
                    Yes
                </Button>
            </Modal.Actions>
        </Modal>
    );
}