import React from "react";
import styled from "styled-components/macro";
import { Switch, useRouteMatch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import SummaryList from "./SummaryList";
import { AgreementTerms } from "./AgreementTermsPage";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
    width: 100%;
    overflow-y: auto;
`;

/*
    Main Router
 */
export const Summary = () => {
    const match = useRouteMatch();

    return (
        <Wrapper>
            <Switch>
                <PrivateRoute
                    path={`${match.url}/AgreementTerms/:product/:id/:agreementId/:vendorVSourceId`}
                    component={AgreementTerms}
                />

                <PrivateRoute path={`${match.url}`} component={SummaryList} />
            </Switch>
        </Wrapper>
    );
};
