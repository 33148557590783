import React, { useEffect, useContext } from 'react';
import { Switch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { VKeyAdminRoute } from "./VKeyAdminRoute";

import { Vault } from "../pages/VKey/Vault";
import { Summary } from "../pages/Summary";

import { AgreementsPage } from "../pages/AgreementsPage";
import { AgreementConfiguratorPage } from "../pages/AgreementConfiguratorPage";
import { AgreementMetaPage } from "../pages/AgreementMetaPage";

import { AuthContext } from "../context/AuthContext";
import DocumentNotes from "../pages/VKey/DocumentNotes";

export default function VKeyRoutes({match}){
    const {
        context,
        dispatch,
        fetchDocs
    } = useContext(AuthContext);

    // Load the documents for VKey
    useEffect(()=>{
        dispatch({ type: "LOADING_DOCS" });

        fetchDocs({ entityGroup: context.activeEntity?.groupName })
            .then(({ docList, docItems }) => {
                dispatch({ type: "FETCH_DOCS", docList, docItems });
            })
            .catch((e) => {
                console.error(e)
                dispatch({ type: "FETCH_DOCS", docList: {} });
            });
    },[])

    return (
        <Switch>
            <PrivateRoute path={`${match.url}/vault/document/:id`} component={DocumentNotes} />
            <PrivateRoute path={`${match.url}/vault`} component={Vault} />
            <PrivateRoute path={`${match.url}/summary/`} component={Summary} />
            <VKeyAdminRoute
                path={`${match.url}/agreements/agreement-configurator/:id`}
                component={AgreementConfiguratorPage}
            />
            <VKeyAdminRoute path={`${match.url}/agreements/meta/:id`} component={AgreementMetaPage} />
            <VKeyAdminRoute path={`${match.url}/agreements`} component={AgreementsPage} />

        </Switch>
    );
}