import React, { useCallback, useContext } from "react";
import { FormSection } from "../FormSection";
import { Icon, Input } from "semantic-ui-react";
import { ButtonWrapper } from "../VendBtn";
import { useFormInput } from "../../hooks/useFormInput";
import { AuthContext } from "../../context/AuthContext";
import { withRouter } from "react-router-dom";
import { ErrorMessage } from "../ErrorMessage";
import { Auth } from "aws-amplify";
import { getUserRegistryData } from "../../utils/user-registry-utils";

/** This is a form to generate a password after the initial user creation */
/** Password reset is available on /login/reset URL (ForgotPasswordForm.js) */

export const NewPasswordForm = withRouter(({ onSubmit, history }) => {
    const { context, dispatch, changePassword, checkEntityAccess } = useContext(AuthContext);

    const newPassword = useFormInput("");
    const confirmPassword = useFormInput("");

    const handleNewPasswordSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            // console.log("handleNewPasswordSubmit e ", e);
            try {
                // Check if the user is signed in
                if (!context.user) {
                    throw new Error("User is not authenticated, please login again");
                }

                if (newPassword.value !== confirmPassword.value) throw new Error("Passwords must match.");
                if (newPassword.value === "" || confirmPassword.value === "") throw new Error("Password is required");

                await changePassword({
                    user: context.user,
                    newPassword: newPassword.value,
                    requiredAttributes: [],
                });

                let user = await Auth.currentAuthenticatedUser();

                if (user) {
                    user = await getUserRegistryData(user);
                    dispatch({ type: "UPDATE_USER", user });
                    await checkEntityAccess(user);
                    history.push("/home");
                } else {
                    throw new Error("User is not authenticated, please login again");
                }
            } catch (err) {
                console.log("Something went wrong", err);
                dispatch({ type: "CHANGE_PASSWORD", changePasswordError: err });
            }
        },
        [newPassword.value, confirmPassword.value]
    );

    return (
        <form onSubmit={handleNewPasswordSubmit} autoComplete="off">
            <FormSection style={{ marginTop: 40 }}>Change Password</FormSection>
            <FormSection style={{ marginTop: 40 }}>
                <Input iconPosition="right" placeholder="New Password">
                    <Icon name="hide" />
                    <input
                        autoComplete="off"
                        id={"new-password"}
                        data-cy={"new-password"}
                        name={"newPassword"}
                        type={"password"}
                        {...newPassword}
                    />
                </Input>
                <Input iconPosition="right" placeholder="Confirm New Password">
                    <Icon name="hide" />
                    <input
                        autoComplete="off"
                        name={"confirmPassword"}
                        id={"new-password-confirm"}
                        data-cy={"new-password-confirm"}
                        type={"password"}
                        {...confirmPassword}
                    />
                </Input>
            </FormSection>

            <FormSection>
                {context.changePasswordError !== "" ? <ErrorMessage>{context.changePasswordError}</ErrorMessage> : null}
            </FormSection>

            <FormSection style={{ marginTop: 40 }}>
                <ButtonWrapper type={"submit"}>Confirm new password</ButtonWrapper>
            </FormSection>
        </form>
    );
});
