import PropTypes from 'prop-types';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    gap: 0.2em;
    .icon {
        font-size: 20px;
        height: auto;
        margin: 0;
        color: ${props => props.color};
    }
`;

const ReviewStars = ({ value, count = 5, editable = false, onChange, color = '#FFA800' }) => {
    const selectedValue = useRef(0);
    const [currentRating, setCurrentRating] = useState(selectedValue.current);

    // Save last passed value prop, in order to reset when mouse leaves the stars without clicking on them
    useEffect(() => {
        selectedValue.current = Math.round(value);
        setCurrentRating(Math.round(value));
    }, [value])

    // Create array of booleans where true is filled star icon and false is outlined ex: [true, true, true, false, false]
    const stars = useMemo(() => Object.assign(Array(count).fill(false), Array(currentRating).fill(true)), [count, currentRating]);

    const clickHandler = i => {
        if (!editable) {
            return;
        }

        selectedValue.current = i;
        onChange && onChange(i);
    }

    return (
        <Wrapper color={color}>
            {stars.map((filled, i) => (
                <i
                    key={i}
                    className={['icon star', filled ? '' : 'outline'].join(' ')}
                    onMouseEnter={() => editable && setCurrentRating(i + 1)}
                    onMouseLeave={() => editable && setCurrentRating(selectedValue.current)}
                    onClick={() => clickHandler(i + 1)}
                ></i>
            ))}
        </Wrapper>
    )
}

ReviewStars.defaultProps = {
    count: 5,
    editable: false
};

ReviewStars.propTypes = {
    value: PropTypes.number.isRequired,
    count: PropTypes.number,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    color: PropTypes.string,
    compareProps: ({ value, count }) => {
        if (value > count) {
            throw new Error("The value can't be more than the count");
        }
    },
    checkOnChange: ({ editable, onChange }) => {
        if (!editable && onChange) {
            throw new Error("Only editable ReviewStars component can have onChange handler");
        }
    },
};

export default ReviewStars;