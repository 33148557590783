import React, { useContext, useEffect, useState } from "react";
import VLogo from "../images/VLogo.svg";
import { ButtonWrapper } from "./VendBtn";
import UploadIcon from "./icons/UploadIcon";
import * as PropTypes from "prop-types";
import styled from "styled-components";
import SortIcon from "./icons/SortIcon";
import SortAscIcon from "./icons/SortAscIcon";
import RefreshIcon from "./icons/RefreshIcon";
import { AuthContext, DOC_LIST_SORT } from "../context/AuthContext";
import { Search } from "./DocSearch";
import { USER_ROLES } from "../Constants";

const HeaderButtonWrapper = styled.div`
    position: relative;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: center;
    background: none;
    box-shadow: none;
    border-radius: 3px;
    //flex: 0 0 ${({ theme }) => theme.lgMetric(1)}px;
    cursor: pointer;
    
    transition: background-color 200ms linear;
    
    &:hover {
      background: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(218,218,218,0.50);
    }
`;
const RefreshHeaderButton = (props) => {
    return (
        <HeaderButtonWrapper {...props}>
            <RefreshIcon />
        </HeaderButtonWrapper>
    );
};
const SortHeaderButton = ({ sort, ...rest }) => {
    return <HeaderButtonWrapper {...rest}>{sort === "asc" ? <SortAscIcon /> : <SortIcon />}</HeaderButtonWrapper>;
};
const DocHeaderSeparator = styled.div`
    position: absolute;
    display: block;
    height: 1px;
    background: #dedede;
    bottom: 0;
    right: 8px;
    left: 8px;
`;
const DocHeaderControls = styled.div`
    flex: 0 0 auto;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    & > * {
        margin-right: 10px;
    }

    & > *:last-child {
        margin-right: 20px;
        margin-left: 20px;
    }
`;
const DocHeaderWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.gray1};
    flex: 0 0 ${({ theme }) => theme.lgMetric(8)}px;
    width: 100%;
    //overflow: hidden;
    overflow: visible;
`;
const ClientLogoWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const ClientLogoImg = styled.img`
    position: relative;
    display: block;
    margin: 16px;
    max-height: 32px;
    width: auto;
`;

const ClientLogoInfo = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
`;

const ClientLogoLabel = styled.div`
    position: relative;
    display: block;
    font-size: 20px;
    color: #595973;
    text-align: left;
    margin-bottom: 4px;
`;

const DocNumLabel = styled.div`
    position: relative;
    display: block;
    color: #545454;
`;

const ClientLogo = ({ entityNum, customerLogo }) => {
    return (
        <ClientLogoWrapper>
            <ClientLogoImg src={customerLogo} />
            <ClientLogoInfo>
                <ClientLogoLabel>Analyst Dashboard</ClientLogoLabel>
                <DocNumLabel>{entityNum} entities</DocNumLabel>
            </ClientLogoInfo>
        </ClientLogoWrapper>
    );
};

export const AnalystDashboardHeader = ({ onSearch, inputSearchText, onOrder, listOrder }) => {
    const { context } = useContext(AuthContext);
    const [entityNum, setEntityNum] = useState(0);
    const [searching, setIsSearching] = useState(false);

    useEffect(() => {
        setEntityNum(context.entities.length || 0);
    }, [context.entities]);

    //const isVKeySuperUser = userHavePermission({ user: context.user }, USER_ROLES.VKEY_VENDEX_SUPER_USER);

    return (
        <DocHeaderWrapper>
            <ClientLogo entityNum={entityNum} customerLogo={VLogo} />

            <Search
                inputSearchText={inputSearchText}
                searching={searching}
                placeholder={"Search Entities …"}
                onChange={({ currentTarget }) => {
                    setIsSearching(true);
                    onSearch(currentTarget.value);
                }}
            />

            <DocHeaderControls>
                {/* <RefreshHeaderButton title={"refresh"} onClick={handleRefresh} /> */}

                <SortHeaderButton title={"sort"} sort={listOrder} onClick={onOrder} />
            </DocHeaderControls>

            <DocHeaderSeparator />
        </DocHeaderWrapper>
    );
};

AnalystDashboardHeader.propTypes = { onChange: PropTypes.func };
