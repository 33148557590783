import React from "react";

const UploadIcon = props => (
    <svg width={20} height={15} {...props}>
        <path
            d="M19.5 7.18c.275 0 .5.213.5.475v5.702c0 .787-.673 1.426-1.5 1.426h-17c-.827 0-1.5-.64-1.5-1.426V7.655c0-.262.224-.475.5-.475s.5.213.5.475v5.702a.49.49 0 0 0 .5.475h17a.49.49 0 0 0 .5-.475V7.655c0-.262.225-.475.5-.475zM7.823 3.304a.52.52 0 0 1-.718.001.484.484 0 0 1 0-.697L9.64.144a.519.519 0 0 1 .718 0l2.536 2.464a.484.484 0 0 1 0 .697.515.515 0 0 1-.718 0l-1.67-1.622v6.694A.5.5 0 0 1 10 8.87a.5.5 0 0 1-.507-.493V1.683l-1.67 1.62z"
            fill="#FFF"
            fillRule="nonzero"
        />
    </svg>
);

export default UploadIcon;

