import React, { memo, useEffect, useState, useContext, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";
import API, { graphqlOperation } from "@aws-amplify/api";
import queryString from "query-string";
import _sortBy from "lodash/sortBy";
import _orderBy from "lodash/orderBy";
import styled from "styled-components";

import { PageHeader } from "../../components/PageHeader";
import CompanyHeader from "../../components/CompanyHeader";
import { CompanyInfoSection } from "../../components/CompanyInfoSection";
import { DetailedDescription } from "../../components/DetailedDescription";
import { ButtonWrapper } from "../../components/VendBtn";
import Tabs from "../../components/Tabs";
import { InfoTable, LinkTable, _dropDownStyles } from "../../components/Tables";
import { useAlerts } from "../../hooks/useAlerts";
import Loader from "../../components/Loader";
import VidSV from "../../components/VID-SV";
import { VendorProductSearch } from "../../components/vsource/productSearch";
import { LargeConfirmationModal } from "../../components/ModalConfirmation";
import { SendEmailPopup } from "../../components/SendEmailPopup";
import { RequestVendorUpdateContent } from "../../components/vlink/RequestVendorUpdateModal";

import { AuthContext } from "../../context/AuthContext";
import { VsourceContext } from "../../context/vsource/vsourceContext";

import { USER_ROLES, LOCATIONS_ORDER } from "../../Constants";

import { companySizeOptions, vendorStageOptions } from "./VendorConfiguratorPage/VendorConfiguratorPage";

import { newLinesToBreaks } from "../../utils/textConverters";

import { deleteVendor, sendVendorUpdateRequestEmail } from "../../graphql/mutations";


const Wrapper = styled.div`
    position: relative;
    display: flex;

    flex-direction: column;
    flex: 2;
    height: 100%;
    width: 100%;

    overflow-y: auto;
    align-items: flex-start;

    padding: 2em;
`;

const PageContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
`;

export const CompanyHeaderRow = styled.div`
    min-height: 7em;
    padding: 0 2em;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Columns = styled.div`
    display: flex;
    flex-wrap: wrap;
    & > div {
        max-width: 50%;
    }
`;

export const ColVertical = styled.div`
    display: block;
`;

const ProfileCol = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    flex: 2 0 0;
`;

const ProfileRow = styled.div``;

const ProductsTable = styled.div`
    table tr {
        td:first-child {
            width: 25%;
        }
    }
`;

export const TextCenter = styled.div`
    width: 100%;
    padding: 2em;
    text-align: center;
    color: ${({ theme }) => theme.darkBlue};
`;

export const CompanyLogo = styled.div`
    display: flex;
    position: relative;
    width: 13rem;
    img {
        max-width: 100%;
        height: auto;
    }
`;

export const AnalystLabel = styled.span`
    color: ${({ theme }) => theme.gray3};
    margin-right: 1em;
`;

export const AnalystName = styled.span`
    color: ${({ theme }) => theme.darkBlue};
`;

const VendorProfilePage = withRouter(
    memo(({ match, location }) => {
        const [selectedVendor, setSelectedVendor] = useState(null);
        const [officersData, setOfficersData] = useState([]);
        const [locationData, setLocationData] = useState([]);
        const [products, setProducts] = useState([]);
        const [activeTab, setActiveTab] = useState("overview");
        const [loading, setLoading] = useState(false);
        const [productsLoading, setProductsLoading] = useState(false);
        const [productsTableData, setProductsTableData] = useState([]);
        const [showModal, setShowModal] = useState(false);
        const [showRequestUpdateModal, setRequestUpdateModal] = useState(false);
        const [requestUpdateInput, setRequestUpdateInput] = useState("");
        const [requestUpdateLoading, setRequestUpdateLoading] = useState(false);
        const [requestModalCategories, setRequestModalCategories] = useState([]);

        const history = useHistory();
        const queryParams = queryString.parse(location.search);

        const { newErrorAlert, newSuccessAlert } = useAlerts();

        const {
            context: { vendors, vendorLoading },
            fetchVendor,
        } = useContext(VsourceContext);

        const {
            context: { user },
            userHavePermission,
            isVSourceClientUserOnly,
        } = useContext(AuthContext);

        const isVSourceAdmin = userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER);

        const parseProductsTableData = useCallback(
            (_products) => {
                if (products) {
                    const list = _products.map((product) => {
                        return {
                            title: product.status_code_original === "PRODUCT_DRAFT" ? product.name + " (draft)" : product.name,
                            value: product.brief_description,
                            link: `/vsource/product/${product.id}?vendor=${selectedVendor.id}`,
                            onClick: () =>
                                history.push(`/vsource/product/${product.id}?vendor=${selectedVendor.id}`),
                        };
                    });
                    setProductsTableData(list);
                } else {
                    return [];
                }
            },
            [selectedVendor, history]
        );

        useEffect(() => {
            // If tab is set in url open the tab
            if (queryParams.tab) {
                let tab;
                switch (queryParams.tab) {
                    case "overview":
                        tab = "overview";
                        break;
                    case "products":
                        tab = "products";
                        break;
                    case "officers":
                        tab = "officers";
                        break;
                    case "locations":
                        tab = "locations";
                        break;
                    default:
                        tab = "overview";
                }
                setActiveTab(tab);
            }
        }, []);

        useEffect(() => {
            const vendorData = vendors[match.params.id];
            // If vendor data is not yet loaded then show the loader
            if ((Object.keys(vendors).length === 0 && vendorLoading) || !vendorData || !vendorData.vendor_id_code)
                setLoading(true);
            else {
                setSelectedVendor(vendorData ? vendorData : null);
            }
            fetchVendor(match.params.id);
        }, [match.params.id]);

        useEffect(() => {
            const vendorData = vendors[match.params.id];
            if (vendorData && vendorData.vendor_id_code) {
                setSelectedVendor(vendorData ? vendorData : null);
                setLoading(false);
            }
        }, [vendors, match.params.id]);

        useEffect(() => {
            if (selectedVendor === null) return;

            const fetchVendorProducts = async () => {
                const vendorProducts = /* GraphQL */ `
                    query GetVendor($id: ID!, $includeDrafts: Boolean) {
                        vendor(id: $id) {
                            products(includeDrafts: $includeDrafts) {
                                id
                                name
                                brief_description
                                detailed_description
                                status_code_original
                            }
                        }
                    }
                `;
                try {
                    setProductsLoading(true);
                    const { data } = await API.graphql(
                        graphqlOperation(vendorProducts, {
                            id: match.params.id,
                            includeDrafts: isVSourceAdmin
                        })
                    );
                    if (data) {
                        setProducts(data.vendor.products);
                    }
                } catch (e) {
                    console.log("Vendor products fetch error: ", e);
                } finally {
                    setProductsLoading(false);
                }
            };

            const fetchVendorOfficers = async () => {
                const vendorOfficers = /* GraphQL */ `
                    query GetVendor($id: ID!) {
                        vendor(id: $id) {
                            officers {
                                business_role
                                full_name
                                business_role_additional
                            }
                        }
                    }
                `;
                try {
                    const { data } = await API.graphql(
                        graphqlOperation(vendorOfficers, {
                            id: match.params.id,
                        })
                    );
                    if (data?.vendor?.officers) {
                        const officers = data.vendor.officers;

                        // Sorting the additional officers: first by the officer title then by the full name

                        let additional_officers = officers.filter((el, i) => el.business_role === "Additional Officer");
                        additional_officers = _sortBy(additional_officers, ["business_role_additional", "full_name"]);

                        const res = [];
                        let additional_officer_added = 0; // Index of the additional_officers to use next

                        // Rebuild the results array using the sorted additional officers list
                        officers.forEach((el) => {
                            if (el.business_role === "Additional Officer") {
                                res.push(additional_officers[additional_officer_added]);
                                additional_officer_added++;
                            } else {
                                res.push(el);
                            }
                        });

                        setOfficersData(res);
                    }
                } catch (e) {
                    console.log("Officers fetch error: ", e);
                }
            };

            const fetchVendorLocations = async () => {
                const vendorLocations = /* GraphQL */ `
                    query GetVendor($id: ID!) {
                        vendor(id: $id) {
                            locations {
                                id
                                name
                                type
                                region
                                division
                                city
                                country
                                street_address_1
                                street_address_2
                                state_province
                                postal_code
                                phone_number
                                customer_support {
                                    phone_number
                                    support_hours
                                    email_address
                                    support_portal_url
                                    time_zone
                                }
                            }
                        }
                    }
                `;
                try {
                    const { data } = await API.graphql(
                        graphqlOperation(vendorLocations, {
                            id: match.params.id,
                        })
                    );
                    if (data) {
                        // console.log("Vendor location data: ", data.vendor);
                        const orderedLocations = _orderBy(data.vendor.locations, ['name'], ['asc']);
                        const sortedData = _sortBy(_orderBy(orderedLocations, ['name'], ['asc']), (item) => {
                            return LOCATIONS_ORDER.indexOf(item.type) !== -1 ?
                                LOCATIONS_ORDER.indexOf(item.type) :
                                orderedLocations.length;
                        })
                        setLocationData(sortedData);
                    }
                } catch (e) {
                    console.log("Location fetch error: ", e);
                }
            };
            fetchVendorProducts();
            fetchVendorOfficers();
            fetchVendorLocations();
        }, [match.params.id, selectedVendor]);

        const onDeleteVendor = async () => {
            if (selectedVendor && selectedVendor.id) {
                try {
                    setLoading(true);
                    const { data } = await API.graphql(
                        graphqlOperation(deleteVendor, {
                            id: selectedVendor.id,
                        })
                    ).catch((e) => {
                        console.error("GraphQL fetch error:", e);
                        throw new Error(e.errors[0].message);
                    });

                    if (data && data.deleteVendor) {
                        newSuccessAlert(`Vendor deleted successfully`);
                        history.push(`/vsource/vendors`);
                    } else newErrorAlert("Failed to delete vendor");
                } catch (e) {
                    console.log("Delete vendor errors: ", e);
                    newErrorAlert(e.message);
                } finally {
                    setLoading(false);
                }
            }
        };

        if (!selectedVendor) {
            return <Loader />;
        }

        const tabsData = [
            {
                title: "Vendor Overview",
                onClick: () => setActiveTab("overview"),
                active: activeTab === "overview",
            },
            {
                title: "Products",
                onClick: () => setActiveTab("products"),
                active: activeTab === "products",
            },
            {
                title: "Officers",
                onClick: () => {
                    setActiveTab("officers");
                },
                active: activeTab === "officers",
            },
            {
                title: "Locations & Support",
                onClick: () => setActiveTab("locations"),
                active: activeTab === "locations",
            },
        ];

        const renderTickerValues = () => {
            if (!selectedVendor.tickers) {
                return "";
            }

            if (selectedVendor.tickers.length === 1) {
                return `${selectedVendor.tickers[0].stock_exchange}:${selectedVendor.tickers[0].ticker_symbol}`;
            } else if (selectedVendor.tickers.length > 1) {
                return selectedVendor.tickers.map((el) => `${el.stock_exchange}:${el.ticker_symbol}`);
            } else {
                return "";
            }
        };

        const renderCompanyType = () => {
            if (!selectedVendor.company_type_code) {
                return "";
            }

            switch (selectedVendor.company_type_code) {
                case "PRIVATE_COMPANY":
                    return "Private";
                case "PUBLIC_COMPANY":
                    return "Public";
                case "NON_PROIFIT_COMPANY":
                    return "Non-Profit";
                case "NOT_AVAILABLE":
                    return "";
                case "GOVERNMENT":
                    return "Government";
                default:
                    return "";
            }
        };

        const VendorInfoTableData = [
            { title: "Vendor Name", value: selectedVendor.full_legal_name },
            {
                title: "Vendor ID",
                value: selectedVendor.vendor_id_code,
            },
            {
                title: "Vendor Hierarchy Code",
                value: selectedVendor.hierarchy_code,
            },
            {
                title: "Aliases",
                value: `${selectedVendor.alias_names || ""}`,
            },
            {
                title: "Historical Names",
                value: `${selectedVendor.historical_names || ""}`,
                hide: !isVSourceAdmin,
            },
            {
                title: "Active/Historical",
                value: selectedVendor.status_code === "ACTIVE_VENDOR" ? "Active" : "Historical",
            },
            {
                title: "Market Segment",
                value: selectedVendor.market_segments.map((el) => el.value),
            },
            {
                title: "Public/Private",
                value: renderCompanyType(),
            },
            {
                title: "Exchange/Ticker Symbol",
                value: renderTickerValues(),
            },
            { title: "Year Founded", value: selectedVendor.year_founded },
            { title: "Headquarters", value: selectedVendor.headquarters },
            {
                title: "Number of Offices",
                value: selectedVendor.number_of_offices,
            },
            {
                title: "Number of Employees",
                value: selectedVendor.company_size_code
                    ? companySizeOptions.filter((opt) => opt.value === selectedVendor.company_size_code)[0].label || ""
                    : "",
            },
            { title: "Website", value: selectedVendor.url, type: "url" },
            { title: "LEI Code", value: selectedVendor.lei_code },
            //TODO: ?
            /* { title: "Main Customer Service Portal", value: "" }, */ // TODO: ?
        ];

        const corporateHierarchyTableData = [
            {
                title: "Immediate Parent",
                value: selectedVendor.immediate_parent_name,
            },
            {
                title: "Immediate Parent Vendor ID",
                value: selectedVendor.immediate_parent_vendor_id_code,
            },
            {
                title: "Immediate Parent Hierarchy Code",
                value: selectedVendor.immediate_parent_hierarchy_code,
            },
            {
                title: "Ultimate Parent",
                value: selectedVendor.ultimate_parent_name,
            },
            {
                title: "Ultimate Parent Vendor ID",
                value: selectedVendor.ultimate_parent_vendor_id_code,
            },
            {
                title: "Ultimate Parent  Hierarchy Code",
                value: selectedVendor.ultimate_parent_hierarchy_code,
            },
        ];

        const FinancialsTableData = () => {
            let res = [];
            if (selectedVendor.financials) {
                selectedVendor.financials.forEach((year, i) => {
                    // Add empty row
                    if (i > 0) {
                        res.push({ title: "", value: "" });
                    }

                    res.push({ title: "Financial Year Ending", value: year.period_end_date });
                    res.push({
                        title: "Revenue Amount",
                        value: year.revenue_amount,
                    });
                    res.push({ title: "Currency", value: year.currency });
                });
            }

            return res;
        };

        const AuditInfoTableData = userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER)
            ? [
                  {
                      title: "VendEx Analyst Last Reviewed",
                      value: selectedVendor.last_reviewed_by,
                  },
                  {
                      title: "Last Reviewed Date and Time",
                      value: selectedVendor.last_reviewed_at,
                      type: "datetime",
                  },
                  {
                      title: "VendEx Publisher Last Published",
                      value: selectedVendor.last_published_by,
                  },
                  {
                      title: "Last Published Date and Time",
                      value: selectedVendor.last_published_at,
                      type: "datetime",
                  },
                  {
                      title: "Next Review Number of Days",
                      value: selectedVendor.next_review_in_days,
                  },
                  {
                      title: "Next Review Date",
                      value: selectedVendor.next_review_at,
                      type: "datetime",
                  },
              ]
            : [];

        const officerData = officersData
            ? officersData.map((officer) => {
                  return {
                      title: officer.business_role_additional
                          ? officer.business_role_additional
                          : officer.business_role,
                      value: officer.full_name,
                  };
              })
            : [];

        const socialMediaData = selectedVendor.social_media
            ? selectedVendor.social_media.map((el) => {
                  return {
                      title: el.social_media_type,
                      value: el.url,
                      type: "url",
                  };
              })
            : [];

        const investorRelationsData = () => {
            if (
                !selectedVendor.investor_relations ||
                selectedVendor.investor_relations.length === 0 ||
                !userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER)
            ) {
                return [];
            }

            const rows = [];

            selectedVendor.investor_relations.forEach((relation, i) => {
                if (i > 0) {
                    // Empty row between blocks
                    rows.push({ title: " ", value: " " });
                }
                rows.push({
                    title: "Contact Person",
                    value: relation.contact_person,
                });
                rows.push({
                    title: "Email Address",
                    value: relation.email_address,
                });
                rows.push({
                    title: "Phone Number",
                    value: relation.phone_number,
                });
                rows.push({
                    title: "Blog URL",
                    value: relation.blog_url,
                    type: "url",
                });
            });

            return rows;
        };

        const corporateActionsData = () => {
            if (!userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER)) {
                return null;
            }

            if (!selectedVendor.corporate_actions || selectedVendor.corporate_actions.length === 0) {
                return [
                    { title: "Type", value: "" },
                    { title: "Date", value: "" },
                    { title: "Description", value: "" },
                ];
            }

            const rows = [];

            selectedVendor.corporate_actions.forEach((action, i) => {
                if (i > 0) {
                    // Empty row between blocks
                    rows.push({ title: " ", value: " " });
                }
                rows.push({
                    title: "Type",
                    value: action.type,
                });
                rows.push({
                    title: "Date",
                    value: action.action_time,
                    type: "datetime",
                });
                rows.push({
                    title: "Description",
                    value: action.description,
                });
            });

            return rows;
        };

        const locationsTables = locationData
            ? locationData.map((location, i) => {
                  const data = [
                      {
                          title: "Location Type",
                          value: location.type,
                      },
                      { title: "Region", value: location.region },
                      { title: "Division", value: location.division },
                      { title: "Country", value: location.country },
                      {
                          title: "Street Address 1",
                          value: location.street_address_1,
                      },
                      {
                          title: "Street Address 2",
                          value: location.street_address_2,
                      },
                      { title: "City", value: location.city },
                      {
                          title: "State/Province",
                          value: location.state_province,
                      },
                      { title: "Postal Code", value: location.postal_code },
                      { title: "Phone Number", value: location.phone_number },
                  ];

                  const customerSupportBlock = location.customer_support
                      ? location.customer_support.map((cs, a) => {
                            const customerSupportData = [
                                {
                                    title: "Customer Support Phone",
                                    value: cs.phone_number,
                                },
                                {
                                    title: "Customer Support Time Zone",
                                    value: cs.time_zone,
                                },
                                {
                                    title: "Customer Support Hours",
                                    value: cs.support_hours,
                                },
                                {
                                    title: "Customer Support Email",
                                    value: cs.email_address,
                                },
                                {
                                    title: "Customer Service Portal",
                                    value: cs.support_portal_url,
                                },
                            ];
                            return (
                                <InfoTable
                                    data={customerSupportData}
                                    title={"Customer Support"}
                                    titleMargin={false}
                                    key={`cs-${a}`}
                                />
                            );
                        })
                      : null;

                  return (
                      <Columns key={`loc-${location.id}`}>
                          <InfoTable title={`${location.name}`} titleMargin={false} data={data} />
                          <ColVertical>{customerSupportBlock}</ColVertical>
                      </Columns>
                  );
              })
            : null;

        const updateRequestUpdateTextField = (text) => {
            if (text.length < 200) {
                setRequestUpdateInput(text);
            } else if (text.length > 200) {
                setRequestUpdateInput(text.substring(0, 199));
            }
        };

        const closeRequestUpdateModal = () => {
            setRequestUpdateInput("");
            setRequestUpdateModal(false);
            setRequestModalCategories([]);
        };

        const sendRequestUpdateEmail = async () => {
            if (!user?.attributes?.email) {
                newErrorAlert("Missing user email, please refresh the page and try again!");
                return;
            }

            try {
                setRequestUpdateLoading(true);
                const { data } = await API.graphql(
                    graphqlOperation(sendVendorUpdateRequestEmail, {
                        user_email: user?.attributes?.email,
                        vendor_name: selectedVendor?.full_legal_name || window.location.href,
                        categories: requestModalCategories.map((el) => el.label),
                        content: requestUpdateInput,
                        url: window.location.href,
                    })
                ).catch((e) => {
                    console.error("GraphQL fetch error:", e);
                    throw new Error(e.errors[0].message);
                });

                if (data && data.sendVendorUpdateRequestEmail) {
                    newSuccessAlert(`Request sent successfully`);
                    closeRequestUpdateModal();
                } else newErrorAlert("Failed to send request, please try again later!");
            } catch (e) {
                console.log("Send request email errors: ", e);
                newErrorAlert(e.message);
            } finally {
                setRequestUpdateLoading(false);
            }
        };

        return (
            <Wrapper>
                <LargeConfirmationModal
                    show={showModal}
                    onClose={() => setShowModal(false)}
                    onSubmit={() => {
                        setShowModal(false);
                        onDeleteVendor();
                    }}
                    onDiscard={() => setShowModal(false)}
                    title="Delete Vendor?"
                    description="Once deleted, you will not be able to recover the Vendor"
                />
                <SendEmailPopup
                    show={showRequestUpdateModal}
                    onCancel={closeRequestUpdateModal}
                    title="Vendor Update Request"
                    onSubmit={sendRequestUpdateEmail}
                    submitDisabled={requestUpdateInput.length === 0}
                    loading={requestUpdateLoading}
                >
                    <RequestVendorUpdateContent
                        email={user?.attributes?.email || ""}
                        vendorName={selectedVendor?.full_legal_name || ""}
                        inputValue={requestUpdateInput}
                        onInputChange={(e) => updateRequestUpdateTextField(e.target.value)}
                        onCategoryChange={setRequestModalCategories}
                        selectedCategories={requestModalCategories}
                    />
                </SendEmailPopup>
                <PageHeader title="Vendor Profile">
                    {userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER) && (
                        <React.Fragment>
                            {activeTab === "products" && (
                                <ButtonWrapper
                                    space-between
                                    onClick={() => history.push(`/vsource/product/add?vendor=${match.params.id}`)}
                                >
                                    Add Product
                                </ButtonWrapper>
                            )}
                            <ButtonWrapper
                                space-between
                                onClick={() =>
                                    history.push(`/vsource/vendors/vendor-configurator/${match.params.id}`)
                                }
                            >
                                Edit Vendor
                            </ButtonWrapper>
                            <ButtonWrapper
                                space-between
                                onClick={() => setShowModal(true)}
                            >
                                Delete Vendor
                            </ButtonWrapper>
                            <ButtonWrapper
                                space-between={isVSourceClientUserOnly(user) ? true : false}
                                onClick={() =>
                                    window.open(`/vlink/vendors/${match.params.id}/public`, '_blank').focus()
                                }
                            >
                                New Public Profile
                            </ButtonWrapper>
                        </React.Fragment>
                    )}
                    {isVSourceClientUserOnly(user) && (
                        <ButtonWrapper onClick={() => setRequestUpdateModal(true)}>Request Vendor Update</ButtonWrapper>
                    )}
                </PageHeader>
                <CompanyHeaderRow>
                    <CompanyHeader title={selectedVendor?.full_legal_name || ""} style={{ margin: 0 }} />
                    <CompanyLogo>
                        {selectedVendor?.logo_url && (
                            <img src={selectedVendor?.logo_url} alt={`${selectedVendor.full_legal_name} logo`} />
                        )}
                    </CompanyLogo>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-end",
                            }}
                        >
                            <VidSV value={`${selectedVendor?.vendor_id_code}-${selectedVendor?.hierarchy_code}`} />
                            {userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER) && (
                                <>
                                    <div
                                        style={{
                                            marginBottom: "5px",
                                            marginTop: "5px",
                                        }}
                                    >
                                        <AnalystLabel>VendEx Analyst Assigned </AnalystLabel>
                                        <AnalystName>{selectedVendor?.assigned_analyst || ""}</AnalystName>
                                    </div>
                                    <div>
                                        <AnalystLabel>Vendor Stage </AnalystLabel>
                                        <AnalystName>
                                            {vendorStageOptions.filter(
                                                (el) => el.value === selectedVendor?.vendor_stage_code
                                            )[0]?.label || ""}
                                        </AnalystName>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </CompanyHeaderRow>
                <div style={{ margin: "0 2em 2em 2em" }}>
                    <Tabs tabs={tabsData} />
                </div>
                <PageContainer>
                    {loading && <Loader />}
                    {activeTab === "overview" && (
                        <>
                            <ProfileRow>
                                <CompanyInfoSection>
                                    <DetailedDescription
                                        dangerouslySetInnerHTML={{
                                            __html: newLinesToBreaks(selectedVendor.detailed_description),
                                        }}
                                    />
                                </CompanyInfoSection>
                            </ProfileRow>
                            <Columns>
                                <InfoTable title="Vendor Information" data={VendorInfoTableData} />
                                <ColVertical>
                                    <InfoTable title="Corporate Hierarchy" data={corporateHierarchyTableData} />
                                    <InfoTable title="Social Media" data={socialMediaData} />
                                </ColVertical>
                            </Columns>
                            <Columns>
                                <InfoTable title="Corporate Actions" data={corporateActionsData()} />
                                <InfoTable title="Financials" data={FinancialsTableData()} />
                            </Columns>
                            <Columns>
                                <InfoTable title="Investor Relations" data={investorRelationsData()} />
                                <InfoTable title="Audit Information" data={AuditInfoTableData} />
                            </Columns>
                        </>
                    )}
                    {activeTab === "products" && (
                        <ProfileRow>
                            <ProfileCol>
                                <div style={{ padding: "0 1.75rem" }}>
                                    <VendorProductSearch
                                        products={products}
                                        handleSearchChange={parseProductsTableData}
                                    />
                                </div>
                                <ProductsTable>
                                    {productsTableData.length === 0 && !productsLoading && (
                                        <TextCenter>
                                            <p>No Product Data</p>
                                        </TextCenter>
                                    )}
                                    {productsLoading && <Loader />}
                                    <LinkTable
                                        headers={["Product Name", "Brief Product Description"]}
                                        className={"vendor-products"}
                                        data={productsTableData}
                                    />
                                </ProductsTable>
                            </ProfileCol>
                        </ProfileRow>
                    )}
                    {activeTab === "officers" && (
                        <ProfileRow>
                            <div style={{ width: "50%" }}>
                                <InfoTable title={"Officer Information"} data={officerData} />
                            </div>
                        </ProfileRow>
                    )}
                    {activeTab === "locations" && <>{locationsTables}</>}
                </PageContainer>
            </Wrapper>
        );
    })
);

export default VendorProfilePage;
