import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { gql, useQuery, useMutation } from "@apollo/client";
import { Loader, Dimmer } from "semantic-ui-react";
import styled from "styled-components";
import _truncate from "lodash/truncate";

import { LargeConfirmationModal } from "../../components/ModalConfirmation";

import UploadDocumentModal from "./UploadDocumentModal";
import { FeatureBlock } from "./FeatureBlock";
import { ButtonWrapper } from "../../components/VendBtn";
import Tooltip from "../Tooltip";

import { useAlerts } from "../../hooks/useAlerts";

import { deleteVLinkPremiumFile } from "../../graphql/customMutations";
import { VendorFilesListQuery, ProductFilesListQuery } from "../../graphql/customQueries";
import documentIconBackground from "../../images/FileDescriptionBackground.svg";

const Thumbnail = styled.div`
    width: 100px;
    height: 155px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 7px;
    padding: 2px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #B5B5C3;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 11%);
    cursor: pointer;

    .document-thumbnail {
        background-image: url(${(props) => props.image}), url(${documentIconBackground});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        width: 100%;
        height: 100%;
    }

    .document-title {
        width: 100%;
        color: #212121;
        border-top: 1px solid #f1f3f4;
        text-align: left;
        padding: 3px 10px;
        font-size: 12px;
        line-height: 1;
    }

    .delete-btn {
        width: 100%;
        position: absolute;
        bottom: 5px;
        justify-content: center;
        display: none;
        z-index: 10;
    }

    &:hover .delete-btn {
        display: inherit;
    }
    &.video{
      height: 80px;
    }
`;

export const FilesListWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 5px;

    .placeholder {
        display: flex;
        align-items: center;
        background-color: #fff;
        padding: 20px;
        margin: 10px;
        height: 100%;
    }
`;

const ViewMoreBtn = styled.button`
  display: flex;
  position: relative;
  font-size: 16px;
  color: #595973;
  background: transparent;
  border: none;
  font-weight: 500;
  cursor: pointer;
  margin: 15px 0 15px auto;
  padding: 0;
`

const DescrItem = styled.li`
  padding: 1rem;
`

export const DocumentsBlock = ({ permissions, page, showButtons }) => {
    const showMoreItems = 3;
    const { id: paramsId } = useParams();

    const [uploadDocModalOpened, setUploadDocModalOpened] = useState(false);
    const [filesList, setFilesList] = useState(undefined);
    const [deleteFileModalOpened, setFileDeleteModalOpened] = useState(false);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [showItemsCounter, setShowItemsCounter] = useState(showMoreItems);

    let formType = undefined;
    let filesQuery = VendorFilesListQuery;
    let queryVariables = {
        type: 'VLINK_DOCUMENT'
    };

    const isVendorPage = page.isVendorPage || page.isVSourceVendorPage;
    const isProductPage = page.isProductPage || page.isVSourceProductPage;

    if (isVendorPage) {
        formType = "VENDOR";
        filesQuery = VendorFilesListQuery;
        queryVariables.vendor_id = paramsId;
    } else if (isProductPage) {
        formType = "PRODUCT";
        filesQuery = ProductFilesListQuery;
        queryVariables.product_id = paramsId;
    }

    const { loading: filesListLoading, error: _filesListError, data: filesListRes, refetch } = useQuery(filesQuery, {
        variables: queryVariables,
        fetchPolicy: 'network-only',
        skip: filesList !== undefined && formType !== undefined,
    });
    const onCollapse = useCallback(() => {
        if (!!filesList && !!filesList.length && (filesList.length > showItemsCounter)) {
            setShowItemsCounter(showItemsCounter + showMoreItems);
        } else if (filesList.length - 1 <= showItemsCounter) {
            setShowItemsCounter(showMoreItems);
        }
    }, [
        showItemsCounter,
        filesList,
        showMoreItems
    ])
    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const [deleteVLinkPremiumFileMutation, { loading: deleteFileLoading, error }] = useMutation(deleteVLinkPremiumFile);

    useEffect(() => {
        if (!filesListLoading && filesListRes && !_filesListError) {
            if (isVendorPage) {
                setFilesList(filesListRes?.vlink?.vendorFiles);
            } else if (isProductPage) {
                setFilesList(filesListRes?.vlink?.productFiles);
            }
        }
    }, [filesListLoading, filesListRes, _filesListError]);

    // If there was an error loading the files list
    useEffect(() => {
        if (_filesListError) {
            newErrorAlert(_filesListError?.message);
        }
    }, [_filesListError, newErrorAlert]);

    useEffect(() => {
        if (filesList === undefined && filesListRes) {
            refetch();
        }
    }, [filesList]);

    const onUploadDocumentModalClose = () => {
        setUploadDocModalOpened(false);
        setFilesList(undefined);
    };

    const onDelete = async () => {
        const res = await deleteVLinkPremiumFileMutation({
            variables: {
                id: parseInt(paramsId, 10),
                fileId: parseInt(selectedFileId, 10),
                type: formType, // "VENDOR" or "PRODUCT"
            },
        });

        const {
            data: {
                vlink: {
                    deleteVlinkPremiumFile: { success },
                },
            },
        } = res;
        console.log("success:", success);

        if(success){
            newSuccessAlert("Document deleted successfully")
        }

        setFilesList(undefined);
        setSelectedFileId(null);
    };

    const closeDeleteModal = () => {
        setFileDeleteModalOpened(false);
        setSelectedFileId(null);
    };

    return (
        <FeatureBlock
            title="Documents"
            headerButtonValue={showButtons ? "Upload document" : null}
            onTitleBtnClick={() => setUploadDocModalOpened(true)}
        >
            <LargeConfirmationModal
                show={deleteFileModalOpened}
                onClose={closeDeleteModal}
                onSubmit={() => {
                    closeDeleteModal();
                    onDelete();
                }}
                onDiscard={closeDeleteModal}
                title="Delete Document?"
                description="Once deleted, you will not be able to recover the document"
            />
            <UploadDocumentModal
                show={uploadDocModalOpened}
                onClose={onUploadDocumentModalClose}
                uploadId={paramsId}
                type={formType}
            />
            <FilesListWrapper>
                {(filesListLoading || deleteFileLoading) && (
                    <Dimmer active inverted inline="centered" style={{ height: "100%" }}>
                        <Loader />
                    </Dimmer>
                )}
                {filesList !== undefined &&
                    Array.isArray(filesList) &&
                    filesList.map((file, index) => {
                        return showItemsCounter >= index + 1 ? (
                            <DocumentThumbnail
                                file={file}
                                key={file.id}
                                allowDelete={permissions.isVLinkPremiumUser}
                                onDelete={() => {
                                    setSelectedFileId(file.id);
                                    setFileDeleteModalOpened(true);
                                }}
                            />
                        ) : null;
                    })}
            </FilesListWrapper>
            {
                !!filesList && !!filesList.length && filesList.length > showMoreItems ?
                    <ViewMoreBtn onClick={onCollapse} type="button">
                        {
                            filesList.length > showItemsCounter ? "Show more" :
                            filesList.length - 1 <= showItemsCounter ? "Show less": null
                        }
                    </ViewMoreBtn> : null
            }
        </FeatureBlock>
    );
};

export const DocumentThumbnail = ({ type, file, allowDelete, onDelete, onClick }) => {
    const { newErrorAlert } = useAlerts();

    const checkFileLink = async (link, fileName) => {
        // Check if the download link is working
        const check = await fetch(link);

        if (check?.ok) {
            const a = document.createElement('a');
            a.href = link;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            newErrorAlert("We're sorry, this link is broken");
        }
    };

    const openFileLink = async (link, fileName) => {
        checkFileLink(link, fileName);
    };
    const title = useMemo(() => {
        const format = file.original_name.split('.');
        return `${_truncate(
            file?.title || '', {
                'length': 8,
                'separator': '...'
            }
        )}.${format[format.length - 1] || ''}`
    }, [file]);
    return (
        <Thumbnail image={file?.thumbnail_url} className={type} data-tip data-for={`thumb_${file.id}`}>
            <div
                className="document-thumbnail"
                onClick={onClick ? onClick : () => openFileLink(file?.file_url, file?.original_name)}
            />
            <span className="document-title">{title}</span>
            {allowDelete && (
                <div className="delete-btn">
                    <ButtonWrapper negative onClick={onDelete}>
                        Delete
                    </ButtonWrapper>
                </div>
            )}
            {file.description && (
                <Tooltip
                    id={`thumb_${file.id}`}
                    minWidth="200px"
                >
                    <ul>
                        <DescrItem>{file.description}</DescrItem>
                    </ul>
                </Tooltip>
            )}
        </Thumbnail>
    );
};
