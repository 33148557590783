import { deleteDocument as deleteDocumentCustomMutation } from "../../graphql/customMutations";
import { get } from "lodash";
import updateAgreement from "./updateAgreement";
import { ApolloClient, InMemoryCache, gql } from "@apollo/client";

const deleteDocument = async (docId, agreementId, { dispatch, user }) => {
    const client = new ApolloClient({
        uri: process?.env?.REACT_APP_VPORT_API_URL,
        cache: new InMemoryCache(),
    });
    const resultDeletedDoc = await client.mutate({
        mutation: deleteDocumentCustomMutation,
        variables: { documentUuid: docId },
    });

    const deletedDoc = get(resultDeletedDoc, "data.deleteDocument", null);

    if (deletedDoc) {
        await updateAgreement(
            {
                id: agreementId,
            },
            { dispatch, user }
        );
        return deletedDoc;
    }
};

export default deleteDocument;
