import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { Card as SemanticCard, Icon, Input, Popup } from "semantic-ui-react";
import _orderBy from "lodash/orderBy";
import { truncateText } from "../../utils/textConverters";
import { useMemo, useState } from "react";

const ProductCardsWrapper = styled.div`
    .cards {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 25px;
        margin-top: 20px;
    }

    .no-data {
        text-align: center;
        padding: 20px;
        font-size: 1.2rem;
    }
`;

const CardWrapper = styled.div`
    cursor: pointer;
    
    .ui.card {
        &.dashboard-card {
            box-shadow: 7px 7px 17px 5px rgb(0 0 0 / 5%);
            width: 260px;
            height: 320px;
            border-radius: 8px;
            border: 1px solid #B5B5C3;
        }

        & > .content{
            overflow: hidden;
            overflow-wrap: break-word;
            padding: 2.5rem 1.5rem;
            & > .header:not(.ui) {
                font-size: 16px;
                margin-bottom: 0;
            }
        }
    }
`;

const VendorCardHeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;

    div:first-child {
        display: flex;
        justify-content: center;

        .vendor-name {
            font-size: 14px;
        }
        & > img {
            display: none;
        }
    }
    div:nth-child(2) {
        display: flex;
        justify-content: center;
        .line {
            display: block;
            width: 200px;
            height: 1px;
            border-bottom: 1px solid ${({ theme }) => theme.gray1};
            margin-top: 1rem;
        }
    }
`;

const Card = ({ href, header, body, onClick }) => {
    return (
        <CardWrapper
            onClick={onClick ? () => onClick() : null}
        >
            <SemanticCard className="dashboard-card" href={href} width={100}>
                <SemanticCard.Content>
                    <SemanticCard.Header>{header}</SemanticCard.Header>
                    <SemanticCard.Description>{body}</SemanticCard.Description>
                </SemanticCard.Content>
            </SemanticCard>
        </CardWrapper>
    );
};

const VendorCardHeader = ({ name }) => {
    return (
        <VendorCardHeaderWrapper>
            <div>
                <span className="vendor-name">{name}</span>
            </div>
            <div>
                <span className="line" />
            </div>
        </VendorCardHeaderWrapper>
    );
};

export default function VendorProductsPreviewTab({products, context, vsourceData}) {
    const history = useHistory();
    const { pathname } = useLocation();
    const [searchValue, setSearchValue] = useState('');

    const filteredProducts = useMemo(() => {
        const orderedProducts = _orderBy(products, [product => product.name.toLowerCase().trim()], ['asc'])
        if(searchValue === '') {
            return orderedProducts;
        }
        return orderedProducts.filter(product => product.name.toLowerCase().includes(searchValue.trim().toLowerCase()));
    }, [searchValue, products])

    const handleOnClick = (product) => {
        // VSource page
        if(pathname.includes("/vsource/vendors/")){
            history.push(`/vsource/product/${product?.id}`)
        } 
        // VLink page
        else{
            history.push(`/vlink/products/${product?.id}/${context === 'PUBLIC' ? 'public' : 'preview'}`)
        }
    }

    console.log('filteredProducts', filteredProducts);

    return <ProductCardsWrapper>
        <Input icon placeholder='Search...'>
            <input value={searchValue} onChange={e => setSearchValue(e.target.value)}/>
            <Icon name='search' />
        </Input>
        {filteredProducts && filteredProducts.length > 0 ? (
            <>
            <div className="cards">
                {filteredProducts.map((product, i) => {
                    const description = context === 'VENDOR_PREVIEW' ? product?.preview_brief_description || product?.brief_description : product?.brief_description;

                    return (
                        <Card
                            key={product.id}
                            header={<VendorCardHeader name={product.name || ''} />}
                            body={
                                <Popup
                                    trigger={<span>{truncateText(description, 45) || ""}</span>}
                                    content={description || ""}
                                    position="bottom center"
                                />
                            }
                            onClick={() => handleOnClick(product)}
                        />)
                })}
            </div>
            </>
        ) : <div className='no-data'>No data</div>}
    </ProductCardsWrapper>
}