import API, { graphqlOperation } from "@aws-amplify/api";
import * as mutations from "../../graphql/mutations";
import addMetaToAgreement from "./addMetaToAgreement";
import userEmail from "../../utils/userEmail";
import addDocumentToAgreement from "./addDocumentToAgreement";

/**
 *
 * @param agreementData
 * @param {object} options
 * @param {function} options.dispatch
 * @param {object} options.user
 * @returns {Promise<object>}
 */
const createNewAgreement = async (agreementData, { dispatch, user, activeEntity }) => {
    dispatch({ type: "CREATE_AGREEMENT" });

    const { vendorId, vendorName, masterProduct, vendorVSourceId } = agreementData;

    const {
        data: { createAgreement: newAgreement },
    } = await API.graphql(
        graphqlOperation(mutations.createAgreement, {
            input: {
                vendorId,
                /* vendorName, */
                createdBy: userEmail(user),
                masterProduct,
                groupsCanAccess: activeEntity?.groupName,
                vendorVSourceId,
            },
        })
    );

    await Promise.all(
        agreementData.docs.map((docId) =>
            addDocumentToAgreement(newAgreement.id, docId, {
                dispatch,
                user,
                noUpdate: true,
                activeEntity,
            })
        )
    );

    const fullAgreement = await addMetaToAgreement(newAgreement, activeEntity);

    dispatch({ type: "AGREEMENT_CREATED", agreement: fullAgreement });
    return newAgreement;
};

export default createNewAgreement;
