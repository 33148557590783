import React, { useCallback, useReducer, useMemo } from "react";
import { Grid, List, Modal } from "semantic-ui-react";
import _get from "lodash/get";
import styled from "styled-components";
import { vidsvMultiLabels, vidsvMultiTypes } from "../Constants";

const TableWrapper = styled(Grid)`
    margin: 0;
    padding-bottom: 3rem !important;

    .row {
        padding: 10px !important;
        font-weight: 500;

        &:first-child {
            font-weight: bold;
            background-color: #F1F3F4;
            border-radius: 10px 10px 0 0;
        } 
    }
`;


export default function SelectedVidsvTable({selectedVidsv}){
    return (
        <TableWrapper columns={3}>
            <Grid.Row>
                {vidsvMultiLabels.map((label, index) => (
                    <Grid.Column key={index}>
                        {label}
                    </Grid.Column>
                ))}
            </Grid.Row>

            <Grid.Row>
                {
                    vidsvMultiTypes.map(type => (
                        <Grid.Column key={type}>
                            <List>
                            {
                            selectedVidsv && selectedVidsv[type] && selectedVidsv[type].map((data, index) => (
                                <List.Item key={index}>{data.path}</List.Item>    
                            ))
                            }
                            </List>
                        </Grid.Column>
                    ))
                }
            </Grid.Row>
        </TableWrapper>
    );
};