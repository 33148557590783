import React, { useState, useCallback } from "react";
import { ButtonWrapper } from "./VendBtn";
import BackIcon from "./icons/BackIcon";
import PDFIcon from "./PDFIcon";
import styled from "styled-components";

const PdfViewerWrapper = styled.div`
	position: relative;
	display: flex;
	background: #1b1c1d;

	flex-direction: column;
	flex: 2;
	height: 100%;

	overflow-y: hidden;
	align-items: center;
`;

const PdfContainer = styled.div`
	position: relative;
	display: flex;
	height: 100%;
	flex-direction: column;
	justify-content: flex-start;
	overflow-y: scroll;
	align-items: center;

	width: 100%;
`;

const PdfHeader = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	flex: 0 0 ${({ theme }) => theme.lgMetric(8)}px;

	align-items: center;
	width: 100%;
	overflow: hidden;
`;

const PdfHeaderControls = styled.div`
	flex: 2;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: white;

	& > * {
		margin-right: 10px;
	}

	& > *:last-child {
		margin-right: 20px;
		margin-left: 20px;
	}
`;

const DocNavWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export default ({ backButtonClick, pdfLinks, documentTitles }) => {
	const isMultiple = documentTitles.length > 1;
	const [currentDocIndex, setCurrentDocIndex] = useState(0);

	const handleDocumentBackClick = useCallback(() => {
		setCurrentDocIndex(Math.max(currentDocIndex - 1, 0));
	}, [currentDocIndex]);

	const handleDocumentForwardClick = useCallback(() => {
		setCurrentDocIndex(Math.min(currentDocIndex + 1, documentTitles.length));
	}, [currentDocIndex, documentTitles]);

	const fileNameFix = decodeURIComponent(documentTitles[currentDocIndex]?.replace(/%(?!\d|[ABCDEF]+)/g, '%25'));

	return (
		<PdfViewerWrapper id={"PdfViewerWrapper"}>
			<PdfHeader>
				<PdfHeaderControls>
					<ButtonWrapper none onClick={backButtonClick}>
						<BackIcon />
						<PDFIcon fill={"#CE5858"} />
						<span style={{ whiteSpace: 'pre-wrap' }}>{fileNameFix}</span>
					</ButtonWrapper>
					{isMultiple ? (
						<DocNavWrapper>
							{currentDocIndex + 1} of {documentTitles.length}
							<ButtonWrapper none onClick={handleDocumentBackClick}>
								Back
							</ButtonWrapper>
							<ButtonWrapper none onClick={handleDocumentForwardClick}>
								Forward
							</ButtonWrapper>
						</DocNavWrapper>
					) : null}
				</PdfHeaderControls>
			</PdfHeader>
			{pdfLinks[currentDocIndex] ? (
				<PdfContainer id={"PdfContainer"}>
					<embed
						src={pdfLinks[currentDocIndex]}
						width={"100%"}
						height={"100%"}
						type="application/pdf"
					/>
				</PdfContainer>
			) : null}
		</PdfViewerWrapper>
	);
};
