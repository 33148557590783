import React from 'react';
import styled from "styled-components/macro";
import {FormWrapper} from "./FormWrapper";
import {LabelStyled} from "./LabelStyled";
import {FieldStyled} from "./FieldStyled";
import {FieldSection} from "./FieldSection";
import {Field, withFormik} from "formik";
import {VendDropDown} from "./VendDropDown";
import {ConfiguratorBottom} from "../ConfiguratorBottom";
import {ButtonWrapper} from "../VendBtn";
import metaConfig from '../../config/metaConfig.json';
import * as Yup from "yup";

const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px;
`;

const checkMissing = (values, key) => {
    return !values[key] || values[key].length <= 0;
};

const CreateProductForm = ({values, match, submitForm, setFieldValue, isEditing}) => {

    const options = metaConfig["Product & Pricing"][0]["values"].find(item => item.id === "product")["values"].find(item => item.id === "serviceType")["valueAliases"];



    const disableSubmission = () => {
        return checkMissing(values, 'vendExProductId') || checkMissing(values, 'productName');
    };

    return (
        <>
            <FormContainer>
                <FormWrapper>
                    <FieldSection>
                        <LabelStyled>Product ID *</LabelStyled>
                        <FieldStyled data-cy={"master-product-id-input"}
                                     type={"text"}
                                     name={"vendExProductId"}/>
                    </FieldSection>
                    <FieldSection>
                        <LabelStyled>Product Name *</LabelStyled>
                        <FieldStyled data-cy={"master-product-name-input"}
                                     type={"text"}
                                     name={"productName"}/>
                    </FieldSection>
                    <FieldSection>
                        <LabelStyled>Brief Description</LabelStyled>
                        <FieldStyled data-cy={"master-product-brief-description"}
                                     type={"text"}
                                     name={"briefDescription"}/>
                    </FieldSection>
                    <FieldSection>
                        <LabelStyled>Product Description</LabelStyled>
                        <FieldStyled data-cy={"master-product-description-input"}
                                     type={"text"}
                                     name={"productDescription"}
                                     size={20 * 8}
                                     component={'textarea'}/>
                    </FieldSection>
                    <FieldSection>
                        <LabelStyled>Service Type</LabelStyled>
                        <Field name={"serviceType"}
                               values={values}
                               component={VendDropDown}
                               options={Object.keys(options).map(key => {
                                   return {
                                       key: `${key}`,
                                       value: `${key}`,
                                       text: `${options[key]}`
                                   }
                               })}
                               onChange={(e, {value, options}) => {
                                   const {key: serviceType} = options.find(opt => opt.value === value);
                                   console.log("onChange options ", options);
                                   setFieldValue('serviceType', serviceType);
                               }}/>
                    </FieldSection>
                </FormWrapper>
            </FormContainer>
            <ConfiguratorBottom>
                <ButtonWrapper onClick={() => {
                    console.log('Starting submission');
                    submitForm();
                }} disabled={disableSubmission()}>
                    Submit Product
                </ButtonWrapper>
            </ConfiguratorBottom>
        </>
    )
};

export default withFormik({
    displayName: 'CreateProductForm',
    mapPropsToValues: ({productName, vendExProductId, serviceType, productDescription, briefDescription}) => {
        return {
            productName: productName || '',
            productDescription: productDescription || '',
            vendExProductId: vendExProductId || '',
            serviceType: serviceType || '',
            briefDescription: briefDescription || ''
        }
    },
    handleSubmit: ({productId, ...values}, {props: {onSubmit}}) => {
        onSubmit({
            ...values
        });
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
        productName: Yup.string().required(),
        vendExProductId: Yup.string().required()
    })
})(CreateProductForm);