import { getNotificationsQuery } from "../../graphql/customQueries";

const fetchNotifications = async (query, dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });

    try {
        const res = await query({query: getNotificationsQuery});
        dispatch({ type: "SET_NOTIFICATIONS", payload: res.data.vlink.notifications});
    } catch (err) {
        console.log("notifications fetch err ", err);
        dispatch({ type: "SET_ERROR", payload: 'Failed to fetch notifications'});
    } finally {
        dispatch({ type: "SET_LOADING", payload: false });
    }
};

export default fetchNotifications;
