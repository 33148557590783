import styled from "styled-components";

export const FormSection = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 70px;
        
    & > * {
      margin-bottom: 24px;
      width: 100%;
    }
`;