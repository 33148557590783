import React, { useCallback, useContext, useState, useEffect } from "react";
import styled from "styled-components/macro";
import { FieldArray, withFormik } from "formik";
import * as Yup from "yup";
import API, { graphqlOperation } from "@aws-amplify/api";
import { useDebounce } from "react-use";

import { FieldSection } from "./FieldSection";
import { FieldStyled } from "./FieldStyled";
import { LabelStyled } from "./LabelStyled";
import { FormWrapper } from "./FormWrapper";
import theme from "../../styles/theme";
import Wizzy from "../Wizzy";
import AddIcon from "../icons/AddIcon";
import { PrivateRoute } from "../PrivateRoute";
import { Switch } from "react-router-dom";
import { Modal } from "../VaultPanel";
import { ModalPdfViewer } from "../ModalPdfViewer";
import { ModalVault } from "../ModalVault";

import { ButtonWrapper } from "../VendBtn";
import InfiniteDropDown from "../InfiniteDropDown";

import { AuthContext } from "../../context/AuthContext";
import { ConfiguratorBottom } from "../ConfiguratorBottom";
import { AgreementDoc } from "../AgreementDoc";
import { fetchVendorsBy } from "../../pages/VSource/VendorsPage";

const Viz = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const FormViz = styled(Viz)`
    position: relative;
    display: block;
    flex: 2;
    padding: 30px 100px;
    justify-content: flex-start;
    align-items: flex-start;
`;

const WizContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 30px;
`;

const DocsFormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    background: ${theme.accent};
    width: 100%;
    border-radius: 3px;
    align-items: center;
    padding: 0 20px;
    overflow-x: auto;
    //height: 100px;
    flex-wrap: wrap;
    max-height: 330px;
`;

const AddDocBtn = styled.div`
    position: relative;
    display: flex;
    height: 80px;
    width: 60px;
    border: 1px ${theme.accentDark} dashed;
    border-radius: 3px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
`;

const DocSeparator = styled.div`
    position: relative;
    display: block;
    background: #aeaebd;
    width: 1px;
    height: 88px;
    margin: 0 24px;
`;

const vendorsListQuery = /* GraphQL */ `
    query Vendors($limit: Int, $page: Int) {
        vendors(limit: $limit, page: $page) {
            data {
                id
                full_legal_name
            }
            meta {
                count
                page
                pageSize
                pageCount
            }
        }
    }
`;
const vendorQuery = /* GraphQL */ `
    query Vendor($id: ID!) {
        vendor(id: $id) {
            id
            full_legal_name
        }
    }
`;

const CreateAgreementForm = ({ values, match, submitForm, setFieldValue, isEditing }) => {
    const [showDocModal, setShowDocModal] = useState(false);
    const [vendorsList, setVendorsList] = useState([]);
    const [vendorsListMeta, setVendorsListMeta] = useState({});
    const [vendorsListLoading, setVendorsListLoading] = useState(true);
    const [selectedVendor, setSelectedVendor] = useState("");
    const [vendorInputValue, setVendorInputValue] = useState("");
    const [hasMore, setHasMore] = useState(false);

    const VENDORS_LIST_LENGTH = 20;

    const {
        context: { activeEntity },
    } = useContext(AuthContext);

    useEffect(() => {
        // Get the initial vendors list
        async function getVendorsList() {
            try {
                // Initial list of the vendors
                fetchInitialVendorsList();
            } catch (e) {
                console.log("Error fetching vendors list:", e);
            } finally {
                setVendorsListLoading(false);
            }
        }
        getVendorsList();
    }, []);

    useEffect(() => {
        // Get the selected vendor data if the user is editing the agreement
        async function getVendor() {
            try {
                // Value from the DB
                if (values.vendorVSourceId) {
                    const {
                        data: { vendor },
                    } = await API.graphql(
                        graphqlOperation(vendorQuery, {
                            id: values.vendorVSourceId,
                        })
                    );

                    setSelectedVendor({ id: vendor.id, value: vendor.full_legal_name });
                    setVendorInputValue(vendor.full_legal_name);
                }
            } catch (e) {
                console.log("Error getting vendor data:", e);
            }
        }
        getVendor();
    }, [values]);

    // If user enters something into the search input
    useDebounce(
        () => {
            searchVendors(vendorInputValue);
        },
        300,
        [vendorInputValue]
    );

    const generateVendorsList = (data) => {
        /* Generate list from DB response for the dropdown
        {
            id: string
            value: string
        }
    */
        if (!data) return [];
        return data.map((el) => ({ id: el.id, value: el.full_legal_name }));
    };

    const handleShowModal = useCallback(() => {
        setShowDocModal(true);
    }, []);

    const handleCloseDocModal = useCallback(() => {
        setShowDocModal(false);
    }, []);

    const disableSubmission = () => {
        return (
            values.vendorVSourceId.length <= 0 ||
            values.masterProductName.length <= 0 ||
            values.customerName.length <= 0 ||
            vendorInputValue === "" ||
            !selectedVendor.id
        );
    };

    const disableDataEntry = () => {
        const removedCount = values.docs.reduce((memo, doc) => (doc.removed ? memo + 1 : memo), 0);

        return disableSubmission() || values.docs.length <= removedCount;
    };

    // Fetch the vendors list from the first page
    const fetchInitialVendorsList = async () => {
        const list = await fetchVendorsList(1);

        setVendorsList(generateVendorsList(list?.data));
        setVendorsListMeta(list?.meta);
        setHasMore(list?.meta?.page && list?.meta?.page !== list?.meta?.pageCount);
    };

    const fetchVendorsList = async (page) => {
        try {
            // Initial list of the vendors
            const { data } = await API.graphql(
                graphqlOperation(vendorsListQuery, {
                    limit: VENDORS_LIST_LENGTH,
                    page,
                    orderBy: "full_legal_name",
                    order: "ASC",
                })
            );
            // console.log("Vendors:", data.vendors);

            return data?.vendors || {};
        } catch (e) {
            console.log("Error fetching vendors list:", e);
        }
    };

    const fetchMoreVendors = async () => {
        const list = await fetchVendorsList(vendorsListMeta?.page + 1);

        setVendorsList([...vendorsList, ...generateVendorsList(list?.data)]);
        setVendorsListMeta(list?.meta);
        setHasMore(list?.meta?.page && list?.meta?.page !== list?.meta?.pageCount);
    };

    const searchVendors = async (keyword) => {
        // setVendorInputValue(keyword);

        if (keyword !== "") {
            // If the user changed the input value reset the selected vendor
            if (keyword !== selectedVendor.value) {
                setSelectedVendor("");
            }
            const list = await fetchVendorsBy("name", keyword, VENDORS_LIST_LENGTH, 1);

            setVendorsList(generateVendorsList(list?.data));
            setVendorsListMeta(list?.meta);
            setHasMore(false);
        } else {
            setSelectedVendor("");
            setFieldValue("vendorVSourceId", "");
            fetchInitialVendorsList();
        }
    };

    return (
        <>
            <WizContainer>
                <FormViz>
                    <FormWrapper>
                        <FieldSection>
                            <LabelStyled>Customer</LabelStyled>
                            <FieldStyled type={"text"} name={"customerName"} disabled />
                        </FieldSection>
                        {/* <FieldSection>
                            <LabelStyled>Vendor</LabelStyled>
                            <Field
                                name={"vendorName"}
                                values={values}
                                component={VendDropDown}
                                options={Object.keys(options).map((key) => {
                                    return {
                                        key: `${key}`,
                                        value: `${options[key]}`,
                                        text: `${options[key]}`,
                                    };
                                })}
                                onChange={(e, { value, options }) => {
                                    const { key: vendorId } = options.find((opt) => opt.value === value);
                                    setFieldValue("vendorName", value);
                                    setFieldValue("vendorId", vendorId);
                                }}
                            />
                        </FieldSection> */}
                        <FieldSection>
                            <LabelStyled>Vendor</LabelStyled>
                            <InfiniteDropDown
                                dataLoading={vendorsListLoading}
                                data={vendorsList}
                                inputValue={vendorInputValue}
                                dataLength={vendorsList.length}
                                hasMore={hasMore}
                                loadNextBatch={fetchMoreVendors}
                                onSelect={(e) => {
                                    setSelectedVendor(e);
                                    setVendorInputValue(e.value);
                                    setFieldValue("vendorVSourceId", e.id);
                                }}
                                onSearch={setVendorInputValue}
                            />
                        </FieldSection>
                        <FieldSection>
                            <LabelStyled>Master Product</LabelStyled>
                            <FieldStyled
                                data-cy={"master-product-name-input"}
                                type={"text"}
                                name={"masterProductName"}
                            />
                        </FieldSection>
                        <FieldSection>
                            <LabelStyled>Documents</LabelStyled>
                            <FieldArray
                                name="docs"
                                render={(arrayHelpers) => (
                                    <DocsFormContainer>
                                        <AddDocBtn
                                            data-cy={"add-agreement-btn"}
                                            onClick={() => {
                                                handleShowModal();
                                            }}
                                        >
                                            <AddIcon style={{ fill: theme.accentDark }} />
                                        </AddDocBtn>
                                        <DocSeparator />
                                        {values.docs.map((docTitle, index) => {
                                            let contractKey = "";
                                            //FixMe: This is a patch because this object changes types depending on what calls it.
                                            if (typeof docTitle === "string") {
                                                contractKey = docTitle;
                                            } else {
                                                if (docTitle.document && docTitle.document.vendExFileName) {
                                                    contractKey = docTitle.document.vendExFileName;
                                                }
                                                if (docTitle.removed) return null;
                                            }

                                            return (
                                                <AgreementDoc
                                                    key={index}
                                                    contractKey={contractKey}
                                                    title={contractKey}
                                                    close={() => {
                                                        if (typeof docTitle === "string") {
                                                            arrayHelpers.remove(index);
                                                        } else {
                                                            arrayHelpers.form.setFieldValue(
                                                                `docs[${index}].removed`,
                                                                true
                                                            );
                                                        }

                                                        if (isEditing) {
                                                            console.log("This agreement's edit mode is : ", isEditing);
                                                            console.log("doc title : ", contractKey);
                                                        }
                                                    }}
                                                ></AgreementDoc>
                                            );
                                        })}
                                        {/* TODO: Extract component */}
                                        {showDocModal && (
                                            <Modal onClose={handleCloseDocModal}>
                                                <Switch>
                                                    <PrivateRoute
                                                        path={`${match.url}/pdf-viewer/:id`}
                                                        component={ModalPdfViewer}
                                                        closeModal={handleCloseDocModal}
                                                        callbackFn={(id) => {
                                                            arrayHelpers.push(id);
                                                        }}
                                                        arrayHelpers={arrayHelpers}
                                                    />

                                                    <PrivateRoute path={`${match.url}`} component={ModalVault} />
                                                </Switch>
                                            </Modal>
                                        )}
                                    </DocsFormContainer>
                                )}
                            />
                        </FieldSection>
                    </FormWrapper>
                </FormViz>
                <Viz style={{ flex: 2 }}>
                    <Wizzy
                        agreement={values}
                        activeEntity={activeEntity}
                        selectedVendor={selectedVendor?.value || ""}
                    />
                </Viz>
            </WizContainer>
            <ConfiguratorBottom>
                <ButtonWrapper
                    data-cy={"enter-meta-data-btn"}
                    onClick={() => {
                        setFieldValue("navTo", "dataEntry", false);
                        submitForm();
                    }}
                    disabled={disableDataEntry()}
                    style={{ marginRight: 8 }}
                >
                    Enter Meta Data
                </ButtonWrapper>
                <ButtonWrapper
                    onClick={() => {
                        setFieldValue("navTo", "agreements", false);
                        submitForm();
                    }}
                    disabled={disableSubmission()}
                >
                    Submit Agreement
                </ButtonWrapper>
            </ConfiguratorBottom>
        </>
    );
};

export default withFormik({
    displayName: "CreateAgreementForm",
    mapPropsToValues: ({
        customerName,
        customerId,
        masterProductName,
        vendorName,
        vendorId,
        docs,
        vendorVSourceId,
    }) => {
        return {
            customerName: customerName || "",
            customerId: customerId || "",
            masterProductName: masterProductName || "",
            vendorName: vendorName || "",
            vendorId: vendorId || "",
            docs: docs || [],
            vendorVSourceId: vendorVSourceId || "",
        };
    },
    handleSubmit: (values, { props: { onSubmit } }) => {
        console.log("Handling submit", values.docs);
        const newAgreement = {
            vendorName: values.vendorName,
            vendorId: values.vendorId,
            masterProduct: values.masterProductName,
            docs: values.docs,
            vendorVSourceId: values.vendorVSourceId,
        };
        // console.log('Submitting', newAgreement, values.navTo);
        onSubmit(newAgreement, values.navTo);
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
        customerName: Yup.string().required(),
        vendorVSourceId: Yup.string().required,
    }),
})(CreateAgreementForm);
