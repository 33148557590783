import React, { useEffect, useContext, useState, useRef } from "react";
import { vendorsBy, listVendorsByMarketSegments, vendorsByProductName } from "../../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";
import { PageHeader } from "../../components/PageHeader";
import { TableContainer } from "../../components/TableContainer";
import ReactTable from "react-table";
import { PageWrapper } from "../../components/PageWrapper";
import { Link } from "react-router-dom";
import VendorStats, { Stat } from "../../components/VendorStats";
import { AuthContext } from "../../context/AuthContext";
import { VsourceContext } from "../../context/vsource/vsourceContext";
import { ButtonWrapper } from "../../components/VendBtn";
import SearchBar from "../../components/VendorSearchBar";
import Loader from "../../components/Loader";
import { USER_ROLES } from "../../Constants";
import { useAlerts } from "../../hooks/useAlerts";
import Tabs from "../../components/Tabs";
import styled from "styled-components";
const { formatToTimeZone } = require("date-fns-timezone");

const TabsBlock = styled.div`
    margin: 30px 32px -10px 32px;
`;

const VerticalStats = styled.div`
    display: flex;
    flex-direction: column;
    .item:first-child {
        margin-bottom: 5px;
    }
`;

const VerticalLine = styled.div`
    height: 90%;
    border-left: 1px solid #f0f2f6;
    margin: 10px 30px;
`;

const CellContents = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const catalogedValues = [
    "STAGE_VENDOR_CATALOGED",
    "STAGE_VENDOR_CONTRIBUTED",
    "STAGE_VENDOR_APPROVED",
    "STAGE_VENDOR_VID_SV_ASSIGNED",
];

const filterOptions = [
    { label: "Vendor", value: "vendor" },
    { label: "Product", value: "product" },
    { label: "Market Segment", value: "market_segment" },
];

export const fetchVendorsBy = async (param, value, limit, page, cataloged, orderBy, order) => {
    const { data } = await API.graphql(
        graphqlOperation(vendorsBy, {
            param,
            limit: limit ? limit : null,
            value,
            page,
            cataloged: cataloged ? cataloged : null,
            orderBy: orderBy ? orderBy : "full_legal_name",
            order: order ? order : "ASC",
        })
    );
    if (data && data.vendorsBy && data.vendorsBy.data) {
        return data.vendorsBy;
    }
    return null;
};

export const VendorsPage = ({ history }) => {
    // const [searchInputValue, setSearchVal] = useState("");
    // const [selectedFilter, setSelectedFilter] = useState(filterOptions[0].value);
    // const [selectedMarketSegments, setMarketSegments] = useState("");
    const [showTable, toggleShowTable] = useState(true);
    const [showCatalogedTable, toggleShowCatalogedTable] = useState(true);

    const [stats, setStats] = useState({});
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState("cataloged");

    // All results variables
    const [resultsList, setResultsList] = useState({});
    const [allResultsTablePage, setAllResultsTablePage] = useState(0);
    const [allResultsTablePageSize, setAllResultsTablePageSize] = useState(100);
    const [allResultsTableLoading, setAllResultsTableLoading] = useState(false);
    const [allResultsTableMeta, setAllResultsTableMeta] = useState({});
    const [allResultsOrderBy, setAllOrderBy] = useState("full_legal_name");
    const [allResultsOrder, setAllOrder] = useState("ASC");

    // Cataloged results variables
    const [catalogedResultsList, setCatalogedResultsList] = useState([]);
    const [catalogedResultsTablePage, setCatalogedResultsTablePage] = useState(0);
    const [catalogedResultsTablePageSize, setCatalogedResultsTablePageSize] = useState(100);
    const [catalogedResultsTableLoading, setCatalogedResultsTableLoading] = useState(false);
    const [catalogedResultsTableMeta, setCatalogedResultsTableMeta] = useState({});
    const [catalogedOrderBy, setCatalogedOrderBy] = useState("full_legal_name");
    const [catalogedOrder, setCatalogedOrder] = useState("ASC");
    const firstUpdate = useRef(true);

    const {
        context: { user },
        userHavePermission,
        isVKeyAdminUser,
    } = useContext(AuthContext);

    const {
        context: {
            vendorSearchTerm: searchInputValue,
            vendorSelectedFilter: selectedFilter,
            vendorSelectedMarketSegments: selectedMarketSegments
        },
        setVendorSearchTerm: setSearchVal,
        setVendorSelectedFilter: setSelectedFilter,
        setVendorSelectedMarketSegments: setMarketSegments,
        setVendorProductSearchTerm
    } = useContext(VsourceContext);

    const { newErrorAlert } = useAlerts();

    useEffect(() => {
        if (selectedFilter === '') {
            setSelectedFilter(filterOptions[0].value)
        }
    }, []);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchVendorsList = async (params) => {
        if (!resultsList[params.page]) {
            setAllResultsTableLoading(true);
        }
        const vendorsListQuery = /* GraphQL */ `
            query Vendors($limit: Int, $page: Int, $orderBy: String, $order: String) {
                vendors(limit: $limit, page: $page, orderBy: $orderBy, order: $order) {
                    data {
                        id
                        full_legal_name
                        brief_description
                        vendor_stage_code
                        last_published_at
                        last_reviewed_at
                        assigned_analyst
                        vendor_stage
                    }
                    meta {
                        count
                        page
                        pageSize
                        pageCount
                    }
                }
            }
        `;

        if (!params) params = {};

        try {
            const {
                data: { vendors },
            } = await API.graphql(graphqlOperation(vendorsListQuery, params /* , { limit: 2000 } */));

            let res = vendors.data;

            if (vendors.data.length > 0) {
                res = vendors.data.map((vendor) => ({
                    ...vendor,
                    vendorId: vendor.id,
                }));
            }
            const list = { ...resultsList };
            list[vendors.meta.page] = res;
            setResultsList(list);
            setAllResultsTableMeta(vendors.meta);
        } catch (e) {
            console.log("Fetch vendors list error:", e);
            newErrorAlert(e.message ?? "Vendors list loading failed");
        } finally {
            setAllResultsTableLoading(false);
        }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchCatalogedVendorsList = async (params) => {
        if (!catalogedResultsList[params.page]) {
            setCatalogedResultsTableLoading(true);
        }
        const vendorsListQuery = /* GraphQL */ `
            query Vendors($limit: Int, $page: Int, $cataloged: Boolean, $orderBy: String, $order: String) {
                vendors(limit: $limit, page: $page, cataloged: $cataloged, orderBy: $orderBy, order: $order) {
                    data {
                        id
                        full_legal_name
                        brief_description
                        vendor_stage_code
                        last_published_at
                        last_reviewed_at
                        assigned_analyst
                        vendor_stage
                    }
                    meta {
                        count
                        page
                        pageSize
                        pageCount
                    }
                }
            }
        `;

        if (!params) params = {};

        try {
            const {
                data: { vendors },
            } = await API.graphql(graphqlOperation(vendorsListQuery, params));

            let res = vendors.data;

            if (vendors.data.length > 0) {
                res = vendors.data.map((vendor) => ({
                    ...vendor,
                    vendorId: vendor.id,
                }));
            }
            const list = { ...catalogedResultsList };
            list[vendors.meta.page] = res;
            setCatalogedResultsList(list);
            setCatalogedResultsTableMeta(vendors.meta);
        } catch (e) {
            console.log("Fetch cataloged vendors list error:", e);
            newErrorAlert(e.message);
        } finally {
            setCatalogedResultsTableLoading(false);
        }
    };

    useEffect(() => {
        const fetchStats = async () => {
            const query = /* GraphQL */ `
                query Statistics {
                    stats {
                        total_number_of_vendors
                        number_of_products_cataloged
                        number_of_vendors_cataloged
                    }
                }
            `;
            try {
                const { data } = await API.graphql(graphqlOperation(query));
                if (data && data.stats) {
                    setStats(data.stats);
                }
            } catch (e) {
                console.log("Stats fetch error: ", e);
                newErrorAlert("Error fetching statistics");
            } finally {
                setLoading(false);
                firstUpdate.current = false;
            }
        };
        // Clear the vendor product search
        setVendorProductSearchTerm(null, null);
        fetchStats();
        // Fetch first page results
        if (!searchInputValue && selectedMarketSegments === "") {
            fetchVendorsList({
                limit: allResultsTablePageSize,
                page: allResultsTablePage + 1,
                orderBy: allResultsOrderBy,
                order: allResultsOrder,
            });
            fetchCatalogedVendorsList({
                limit: catalogedResultsTablePageSize,
                page: catalogedResultsTablePage + 1,
                cataloged: true,
                orderBy: catalogedOrderBy,
                order: catalogedOrder,
            });
        } else {
            setAllResultsTableLoading(true);
            setCatalogedResultsTableLoading(true);
            if (selectedFilter === "vendor") {
                loadVendorsByName(allResultsTablePage + 1);
                loadCatalogedVendorsByName(catalogedResultsTablePage + 1);
            } else if (selectedFilter === "product") {
                loadVendorsByProductName(allResultsTablePage + 1);
                loadCatalogedVendorsByProductName(catalogedResultsTablePage + 1);
            } else if (selectedFilter === "market_segment") {
                loadVendorsByMarketSegment(allResultsTablePage + 1);
                loadCatalogedVendorsByMarketSegment(catalogedResultsTablePage + 1);
            }
        }

        // Fetch second page results
        /* fetchVendorsList({
            limit: allResultsTablePageSize,
            page: allResultsTablePage + 2,
        }); */
    }, []);

    const refreshTable = () => {
        if (allResultsTablePage === 0) {
            toggleShowTable(false);
            setTimeout(() => toggleShowTable(true), 0.1);
        }
    };

    const refreshCatalogedTable = () => {
        if (catalogedResultsTablePage === 0) {
            toggleShowCatalogedTable(false);
            setTimeout(() => {
                toggleShowCatalogedTable(true);
            }, 0.1);
        }
    };
    useEffect(refreshTable, [allResultsTablePage]);
    useEffect(refreshCatalogedTable, [catalogedResultsTablePage]);

    // If market segments filter updated
    useEffect(() => {
        if (firstUpdate.current) return;

        setAllResultsTableLoading(true);
        setCatalogedResultsTableLoading(true);
        if (selectedMarketSegments.length > 0) {
            loadVendorsByMarketSegment(1);
            loadCatalogedVendorsByMarketSegment(1);
        }
        // With empty segments list fetch all vendors
        else {
            fetchVendorsList({
                limit: allResultsTablePageSize,
                page: allResultsTablePage + 1,
                orderBy: allResultsOrderBy,
                order: allResultsOrder,
            });
            fetchCatalogedVendorsList({
                limit: catalogedResultsTablePageSize,
                page: catalogedResultsTablePage + 1,
                cataloged: true,
                orderBy: catalogedOrderBy,
                order: catalogedOrder,
            });
        }
    }, [selectedMarketSegments]);

    // If full table ordering is changed
    useEffect(() => {
        if (firstUpdate.current) return;

        setAllResultsTableLoading(true);
        if (!searchInputValue && selectedMarketSegments === "") {
            fetchVendorsList({
                limit: allResultsTablePageSize,
                page: allResultsTablePage + 1,
                orderBy: allResultsOrderBy,
                order: allResultsOrder,
            });
        } else {
            if (selectedFilter === "vendor") {
                loadVendorsByName(allResultsTablePage + 1);
            } else if (selectedFilter === "product") {
                loadVendorsByProductName(allResultsTablePage + 1);
            } else if (selectedFilter === "market_segment") {
                loadVendorsByMarketSegment(allResultsTablePage + 1);
            }
        }
    }, [allResultsOrderBy, allResultsOrder]);

    // If cataloged table ordering is changed
    useEffect(() => {
        if (firstUpdate.current) return;

        setCatalogedResultsTableLoading(true);
        if (!searchInputValue && selectedMarketSegments === "") {
            fetchCatalogedVendorsList({
                limit: catalogedResultsTablePageSize,
                page: catalogedResultsTablePage + 1,
                cataloged: true,
                orderBy: catalogedOrderBy,
                order: catalogedOrder,
            });
        } else {
            if (selectedFilter === "vendor") {
                loadCatalogedVendorsByName(catalogedResultsTablePage + 1);
            } else if (selectedFilter === "product") {
                loadCatalogedVendorsByProductName(catalogedResultsTablePage + 1);
            } else if (selectedFilter === "market_segment") {
                loadCatalogedVendorsByMarketSegment(catalogedResultsTablePage + 1);
            }
        }
    }, [catalogedOrderBy, catalogedOrder]);

    const loadVendorsByName = async (page) => {
        try {
            const vendors = await fetchVendorsBy(
                "name",
                searchInputValue,
                allResultsTablePageSize,
                page,
                false,
                allResultsOrderBy,
                allResultsOrder
            );

            let res = vendors.data;
            if (vendors.data.length > 0) {
                res = vendors.data.map((vendor) => ({
                    ...vendor,
                    vendorId: vendor.id,
                }));
            }

            const list = page === 1 ? {} : { ...resultsList };
            list[vendors.meta.page] = res;

            setResultsList(list);
            setAllResultsTableMeta(vendors.meta);
            if (page === 1) {
                setAllResultsTablePage(0);
            }
        } catch (e) {
            console.log("Fetch vendors list error:", e);
            newErrorAlert(e.message ?? "Vendors list loading failed");
        } finally {
            setAllResultsTableLoading(false);
        }
    };
    const loadCatalogedVendorsByName = async (page) => {
        try {
            const vendors = await fetchVendorsBy(
                "name",
                searchInputValue,
                catalogedResultsTablePageSize,
                page,
                true,
                catalogedOrderBy,
                catalogedOrder
            );

            let res = vendors.data;

            if (vendors.data.length > 0) {
                res = vendors.data.map((vendor) => ({
                    ...vendor,
                    vendorId: vendor.id,
                }));
            }

            const list = page === 1 ? {} : { ...catalogedResultsList };
            list[vendors.meta.page] = res;
            setCatalogedResultsList(list);
            setCatalogedResultsTableMeta(vendors.meta);
            if (page === 1) {
                setCatalogedResultsTablePage(0);
            }
        } catch (e) {
            console.log("Fetch cataloged vendors list error:", e);
            newErrorAlert(e.message);
        } finally {
            setCatalogedResultsTableLoading(false);
        }
    };

    const loadCatalogedVendorsByProductName = async (page) => {
        try {
            const { data: catalogedResults } = await API.graphql(
                graphqlOperation(vendorsByProductName, {
                    name: searchInputValue,
                    limit: catalogedResultsTablePageSize,
                    page,
                    cataloged: true,
                    orderBy: catalogedOrderBy,
                    order: catalogedOrder,
                })
            );

            if (
                catalogedResults &&
                catalogedResults.vendorsByProductName &&
                catalogedResults.vendorsByProductName.data
            ) {
                const list = page === 1 ? {} : { ...catalogedResultsList };

                list[catalogedResults.vendorsByProductName.meta.page] = catalogedResults.vendorsByProductName.data;

                setCatalogedResultsList(list);
                setCatalogedResultsTableMeta(catalogedResults.vendorsByProductName.meta);
                if (page === 1) {
                    setCatalogedResultsTablePage(0);
                }
            }
            return null;
        } catch (e) {
            console.log("Cataloged Vendors by product name fetch error: ", e);
            newErrorAlert("Error fetching cataloged vendors data by product name");
        } finally {
            setCatalogedResultsTableLoading(false);
        }
    };

    const loadVendorsByProductName = async (page) => {
        try {
            const { data } = await API.graphql(
                graphqlOperation(vendorsByProductName, {
                    name: searchInputValue,
                    limit: allResultsTablePageSize,
                    page,
                    orderBy: allResultsOrderBy,
                    order: allResultsOrder,
                })
            );

            if (data && data.vendorsByProductName && data.vendorsByProductName.data) {
                const list = page === 1 ? {} : { ...resultsList };
                list[data.vendorsByProductName.meta.page] = data.vendorsByProductName.data;
                setResultsList(list);
                setAllResultsTableMeta(data.vendorsByProductName.meta);
                if (page === 1) {
                    setAllResultsTablePage(0);
                }
            }
            return null;
        } catch (e) {
            console.log("Vendors by product name fetch error: ", e);
            newErrorAlert("Error fetching products data");
        } finally {
            setAllResultsTableLoading(false);
        }
    };

    const loadVendorsByMarketSegment = async (page) => {
        try {
            const { data } = await API.graphql(
                graphqlOperation(listVendorsByMarketSegments, {
                    market_segments: JSON.stringify(selectedMarketSegments), //[{ value: "ESG" },{ value: "ENERGY" }]
                    limit: allResultsTablePageSize,
                    page,
                    orderBy: allResultsOrderBy,
                    order: allResultsOrder,
                })
            );
            if (data && data.listVendorsByMarketSegments) {
                const list = page === 1 ? {} : { ...resultsList };
                list[data.listVendorsByMarketSegments.meta.page] = data.listVendorsByMarketSegments.data;
                setResultsList(list);
                setAllResultsTableMeta(data.listVendorsByMarketSegments.meta);
                if (page === 1) {
                    setAllResultsTablePage(0);
                }
            }
        } catch (e) {
            console.log("Vendors by Market segments fetch error: ", e);
            newErrorAlert("Error fetching Vendors by market segment data");
        } finally {
            setAllResultsTableLoading(false);
        }
    };

    const loadCatalogedVendorsByMarketSegment = async (page) => {
        try {
            const { data } = await API.graphql(
                graphqlOperation(listVendorsByMarketSegments, {
                    market_segments: JSON.stringify(selectedMarketSegments),
                    limit: catalogedResultsTablePageSize,
                    page,
                    cataloged: true,
                    orderBy: catalogedOrderBy,
                    order: catalogedOrder,
                })
            );
            if (data && data.listVendorsByMarketSegments) {
                const list = page === 1 ? {} : { ...catalogedResultsList };
                list[data.listVendorsByMarketSegments.meta.page] = data.listVendorsByMarketSegments.data;
                setCatalogedResultsList(list);
                setCatalogedResultsTableMeta(data.listVendorsByMarketSegments.meta);
                if (page === 1) {
                    setCatalogedResultsTablePage(0);
                }
            }
        } catch (e) {
            console.log("Cataloged Vendors by Market segments fetch error: ", e);
            newErrorAlert("Error fetching cataloged Vendors by market segment data");
        } finally {
            setCatalogedResultsTableLoading(false);
        }
    };

    // If "all results" table page size changed
    useEffect(() => {
        if (firstUpdate.current) return;

        if (allResultsTableMeta.pageSize !== allResultsTablePageSize) {
            setResultsList({});
            setAllResultsTableLoading(true);
            if (!searchInputValue && selectedFilter !== "market_segment") {
                fetchVendorsList({
                    limit: allResultsTablePageSize,
                    page: allResultsTablePage + 1,
                    orderBy: allResultsOrderBy,
                    order: allResultsOrder,
                });
            } else {
                setAllResultsTableLoading(true);
                if (selectedFilter === "vendor") {
                    loadVendorsByName(allResultsTablePage + 1);
                } else if (selectedFilter === "product") {
                    loadVendorsByProductName(allResultsTablePage + 1);
                } else if (selectedFilter === "market_segment") {
                    loadVendorsByMarketSegment(allResultsTablePage + 1);
                }
            }
        }
    }, [allResultsTablePageSize]);

    // If "cataloged results" table page size changed
    useEffect(() => {
        if (firstUpdate.current) return;

        if (catalogedResultsTableMeta.pageSize !== catalogedResultsTablePageSize) {
            setCatalogedResultsList({});
            setCatalogedResultsTableLoading(true);
            if (!searchInputValue && selectedFilter !== "market_segment") {
                fetchCatalogedVendorsList({
                    limit: catalogedResultsTablePageSize,
                    page: catalogedResultsTablePage + 1,
                    cataloged: true,
                    orderBy: catalogedOrderBy,
                    order: catalogedOrder,
                });
            } else {
                setCatalogedResultsTableLoading(true);
                if (selectedFilter === "vendor") {
                    loadCatalogedVendorsByName(catalogedResultsTablePage + 1);
                } else if (selectedFilter === "product") {
                    loadCatalogedVendorsByProductName(catalogedResultsTablePage + 1);
                } else if (selectedFilter === "market_segment") {
                    loadCatalogedVendorsByMarketSegment(catalogedResultsTablePage + 1);
                }
            }
        }
    }, [catalogedResultsTablePageSize]);

    // If "all results" table page changed
    useEffect(() => {
        if (firstUpdate.current) return;

        if (allResultsTableMeta.page !== allResultsTablePage + 1) {
            // If no results yet show loader
            if (!resultsList[allResultsTablePage + 1]) {
                setAllResultsTableLoading(true);
            }

            // With empty search input fetch all of the vendors
            if (!searchInputValue && selectedFilter !== "market_segment") {
                fetchVendorsList({
                    limit: allResultsTablePageSize,
                    page: allResultsTablePage + 1,
                    orderBy: allResultsOrderBy,
                    order: allResultsOrder,
                });
            }
            // If search keyword is present use this and filter value to fetch
            // the results
            else {
                setAllResultsTableLoading(true);

                if (selectedFilter === "vendor") {
                    loadVendorsByName(allResultsTablePage + 1);
                } else if (selectedFilter === "product") {
                    loadVendorsByProductName(allResultsTablePage + 1);
                } else if (selectedFilter === "market_segment") {
                    loadVendorsByMarketSegment(allResultsTablePage + 1);
                }
            }

            //TODO: Fetch next (and previous) page results
        }
    }, [allResultsTablePage]);

    // If "cataloged results" table page changed
    useEffect(() => {
        if (firstUpdate.current) return;

        if (catalogedResultsTableMeta.page !== catalogedResultsTablePage + 1) {
            // If no results yet show loader
            if (!catalogedResultsList[catalogedResultsTablePage + 1]) {
                setCatalogedResultsTableLoading(true);
            }

            // With empty search input fetch all of the vendors
            if (!searchInputValue && selectedFilter !== "market_segment") {
                fetchCatalogedVendorsList({
                    limit: catalogedResultsTablePageSize,
                    page: catalogedResultsTablePage + 1,
                    cataloged: true,
                    orderBy: catalogedOrderBy,
                    order: catalogedOrder,
                });
            }
            // If search keyword is present use this and filter value to fetch
            // the results
            else {
                setCatalogedResultsTableLoading(true);

                if (selectedFilter === "vendor") {
                    loadCatalogedVendorsByName(catalogedResultsTablePage + 1);
                } else if (selectedFilter === "product") {
                    loadCatalogedVendorsByProductName(catalogedResultsTablePage + 1);
                } else if (selectedFilter === "market_segment") {
                    loadCatalogedVendorsByMarketSegment(catalogedResultsTablePage + 1);
                }
            }

            //TODO: Fetch next (and previous) page results
        }
    }, [catalogedResultsTablePage]);

    const onCatalogedResultsTablePageChangeHandler = (val) => {
        setCatalogedResultsTablePage(val);
        document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
    };

    const onAllResultsTablePageChangeHandler = (val) => {
        setAllResultsTablePage(val);
        document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
    };

    const tabsData = [
        {
            title: `Vendors in Directory ${allResultsTableMeta.count !== undefined ? "(" + numberWithCommas(allResultsTableMeta.count) + ")" : ""
                }`,
            onClick: () => setActiveTab("all"),
            active: activeTab === "all",
        },
        {
            title: `Vendors Cataloged ${catalogedResultsTableMeta.count !== undefined
                ? "(" + numberWithCommas(catalogedResultsTableMeta.count) + ")"
                : ""
                }`,
            onClick: () => setActiveTab("cataloged"),
            active: activeTab === "cataloged",
        },
    ];

    const onSearchSubmit = async (fetchAll) => {
        // With empty search input fetch all of the vendors
        if (!searchInputValue || fetchAll === true) {
            setAllResultsTableLoading(true);
            setCatalogedResultsTableLoading(true);
            fetchVendorsList({
                limit: allResultsTablePageSize,
                page: allResultsTablePage + 1,
                orderBy: allResultsOrderBy,
                order: allResultsOrder,
            });
            fetchCatalogedVendorsList({
                limit: catalogedResultsTablePageSize,
                page: catalogedResultsTablePage + 1,
                cataloged: true,
                orderBy: catalogedOrderBy,
                order: catalogedOrder,
            });
        }
        // If search keyword is present use this and filter value to fetch
        // the results
        else {
            if (selectedFilter === "vendor") {
                setAllResultsTableLoading(true);
                loadVendorsByName(1);
                loadCatalogedVendorsByName(1);
            } else if (selectedFilter === "product") {
                setAllResultsTableLoading(true);
                loadVendorsByProductName(1);
                loadCatalogedVendorsByProductName(1);
            }
        }
    };

    const clearSearchInput = () => {
        setSearchVal("");
        setMarketSegments("");
        onSearchSubmit(true);
    };

    const onCatalogedTableOrder = (e) => {
        if (!e || !Array.isArray(e) || e.length < 1) {
            return;
        }

        const _orderBy = e[0].id;
        const _order = e[0].desc === true ? "DESC" : "ASC";

        setCatalogedOrderBy(_orderBy);
        setCatalogedOrder(_order);
    };

    const onFullTableOrder = (e) => {
        if (!e || !Array.isArray(e) || e.length < 1) {
            return;
        }

        const _orderBy = e[0].id;
        const _order = e[0].desc === true ? "DESC" : "ASC";

        setAllOrderBy(_orderBy);
        setAllOrder(_order);
    };

    const isVSourceVendexUser = userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_USER);
    //const isVSourceVendexUser = false;

    let allResultsTableColumns = [
        {
            Header: "Vendor Name",
            id: "full_legal_name",
            width: 300,
            headerStyle: {
                fontSize: 16,
            },
            Cell: (row) => {
                return (
                    <div style={{ ...row.styles }}>
                        <Link to={`/vsource/vendors/${row.original.id}`}>
                            <CellContents>{row.original.full_legal_name}</CellContents>
                        </Link>
                    </div>
                );
            },
        },
        {
            Header: "Brief Vendor Description",
            id: "brief_description",
            accessor: "briefVendorDescription",
            headerStyle: {
                fontSize: 16,
            },
            width: isVSourceVendexUser ? 580 : undefined,
            Cell: (row) => {
                return (
                    <div title={row.original.brief_description} style={{ ...row.styles }}>
                        <CellContents>{row.original.brief_description}</CellContents>
                    </div>
                );
            },
        },
    ];

    let catalogedTableColumns = [
        {
            Header: "Vendor Name",
            id: "full_legal_name",
            headerStyle: {
                fontSize: 16,
            },
            width: 300,
            Cell: (row) => {
                return (
                    <div style={{ ...row.styles }}>
                        <Link to={`/vsource/vendors/${row.original.id}`}>
                            <CellContents>{row.original.full_legal_name}</CellContents>
                        </Link>
                    </div>
                );
            },
        },
        {
            Header: "Brief Vendor Description",
            id: "brief_description",
            headerStyle: {
                fontSize: 16,
            },
            width: isVSourceVendexUser ? 580 : undefined,
            Cell: (row) => {
                return (
                    <div title={row.original.brief_description} style={{ ...row.styles }}>
                        <CellContents>{row.original.brief_description}</CellContents>
                    </div>
                );
            },
        },
    ];

    // Additional columns for admin user
    if (isVSourceVendexUser) {
        const adminColumns = [
            {
                Header: "Vendor Stage",
                id: "vendor_stage_code",
                accessor: "vendor_stage",
                width: 200,
                headerStyle: {
                    fontSize: 16,
                },
            },
            {
                Header: "VendEx Analyst",
                accessor: "assigned_analyst",
                id: "assigned_analyst",
                headerStyle: {
                    fontSize: 16,
                },
                width: 180,
            },
            {
                Header: "Last Reviewed",
                id: "last_reviewed_at",
                headerStyle: {
                    fontSize: 16,
                },
                width: 140,
                Cell: (row) => {
                    return (
                        <div title={row.original.last_reviewed_at || ""} style={{ ...row.styles }}>
                            <CellContents>
                                {row.original.last_reviewed_at !== null
                                    ? formatToTimeZone(new Date(row.original.last_reviewed_at), "DD-MMM-YYYY", {
                                        timeZone: "GMT",
                                    }) || ""
                                    : ""}
                            </CellContents>
                        </div>
                    );
                },
            },
            {
                Header: "Last Published",
                id: "last_published_at",
                headerStyle: {
                    fontSize: 16,
                },
                width: 220,
                Cell: (row) => {
                    return (
                        <div title={row.original.last_published_at || ""} style={{ ...row.styles }}>
                            <CellContents>
                                {row.original.last_published_at !== null
                                    ? formatToTimeZone(new Date(row.original.last_published_at), "DD-MMM-YYYY", {
                                        timeZone: "GMT",
                                    }) || ""
                                    : ""}
                            </CellContents>
                        </div>
                    );
                },
            },
        ];

        allResultsTableColumns = [...allResultsTableColumns, ...adminColumns];
        catalogedTableColumns = [...catalogedTableColumns, ...adminColumns];
    }

    return (
        <PageWrapper>
            {(loading || allResultsTableLoading) && <Loader />}
            <PageHeader title={"Vendors"}>
                {userHavePermission({ user }, USER_ROLES.VSOURCE_VENDEX_SUPER_USER) && (
                    <ButtonWrapper onClick={() => history.push(`/vsource/vendor/add`)}>Add Vendor</ButtonWrapper>
                )}
            </PageHeader>
            <VendorStats>
                <Stat
                    label={"Vendors in Directory"}
                    stat={
                        Number.isInteger(stats?.total_number_of_vendors)
                            ? numberWithCommas(stats.total_number_of_vendors)
                            : "..."
                    }
                />
                <VerticalLine />
                <VerticalStats>
                    <Stat
                        label={"Vendors Cataloged"}
                        stat={
                            Number.isInteger(stats?.number_of_vendors_cataloged)
                                ? numberWithCommas(stats.number_of_vendors_cataloged)
                                : "..."
                        }
                    />
                    <Stat
                        label={"Products Cataloged"}
                        stat={
                            Number.isInteger(stats?.number_of_products_cataloged)
                                ? numberWithCommas(stats.number_of_products_cataloged)
                                : "..."
                        }
                    />
                </VerticalStats>
            </VendorStats>
            <SearchBar
                searchInputValue={searchInputValue}
                setSearchVal={setSearchVal}
                selectedFilter={selectedFilter}
                selectedMarketSegments={selectedMarketSegments}
                filterOptions={filterOptions}
                setSelectedFilter={setSelectedFilter}
                onSearchSubmit={onSearchSubmit}
                setMarketSegments={setMarketSegments}
                clearSearchInput={clearSearchInput}
            />
            <TabsBlock>
                <Tabs tabs={tabsData} />
            </TabsBlock>
            <TableContainer>
                {!allResultsTableLoading && Object.keys(resultsList).length === 0 && activeTab === "all" && (
                    <p>No vendors found</p>
                )}
                {
                    /* Object.keys(resultsList).length > 0 && */
                    /*!loading &&
                    !vendorsLoading && */
                    activeTab === "all" && showTable && (
                        <ReactTable
                            manual
                            className="-striped -highlight"
                            loading={allResultsTableLoading || (loading && Object.keys(resultsList).length === 0)}
                            page={allResultsTablePage}
                            pageSize={allResultsTablePageSize}
                            pages={allResultsTableMeta.pageCount}
                            onPageChange={onAllResultsTablePageChangeHandler}
                            onPageSizeChange={setAllResultsTablePageSize}
                            defaultPageSize={100}
                            data={resultsList[allResultsTablePage + 1] || []}
                            sortable={true}
                            onSortedChange={onFullTableOrder}
                            columns={allResultsTableColumns}
                        />
                    )
                }
                {/** Vendors cataloged table */}
                {Object.keys(catalogedResultsList).length === 0 &&
                    !catalogedResultsTableLoading &&
                    //!vendorsLoading &&
                    activeTab === "cataloged" && <p>No vendors found</p>}

                {
                    /* catalogedResultsList.length > 0 &&
                    !loading && */
                    //!vendorsLoading &&
                    activeTab === "cataloged" && showCatalogedTable && (
                        <ReactTable
                            manual
                            className="-striped -highlight"
                            loading={catalogedResultsTableLoading}
                            page={catalogedResultsTablePage}
                            pageSize={catalogedResultsTablePageSize}
                            pages={catalogedResultsTableMeta.pageCount}
                            onPageChange={onCatalogedResultsTablePageChangeHandler}
                            onPageSizeChange={setCatalogedResultsTablePageSize}
                            defaultPageSize={100}
                            sortable={true}
                            onSortedChange={onCatalogedTableOrder}
                            data={catalogedResultsList[catalogedResultsTablePage + 1] || []}
                            columns={catalogedTableColumns}
                        />
                    )
                }
            </TableContainer>
        </PageWrapper>
    );
};
