import { gql, useMutation } from "@apollo/client";
import { API, graphqlOperation } from "aws-amplify";
import { Button, Modal } from "semantic-ui-react";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import _get from "lodash/get";
import { FeatureBlock } from "./FeatureBlock";
import styled from "styled-components";
import { updateVsourceProductField, updateVsourceVendorField } from "../../graphql/customMutations";
import RichTextEditor from "./RichTextEditor";
import { useAlerts } from "../../hooks/useAlerts";

const ProductQuery = /* GraphQL */ `
    query GetProduct($id: ID!) {
        product(id: $id) {
            premium_benefit,
            name
        }
    }
`;

const VendorQuery = /* GraphQL */ `
    query GetVendor($id: ID!) {
        vendor(id: $id) {
            premium_benefit
        }
    }
`;

const StyledText = styled.div`
   ol, ul {
     list-style: inside;
   }
`;

export default function PremiumBenefitBlock({permissions, level, vsourceData, showButtons}) {
    const [value, setValue] = useState('');
    const [open, setOpen] = useState(false);
    const [savedValue, setSavedValue] = useState('');
    const { id: targetId } = useParams();
    const { pathname } = useLocation();
    const { newErrorAlert, newSuccessAlert } = useAlerts();

    const [updateVsourceFieldMutation] = useMutation(level === 'VENDOR' ? updateVsourceVendorField : updateVsourceProductField);
    let formType = undefined;

    const isVendorPage = pathname.includes("vendors/");
    const isProductPage = pathname.includes("products/");

    if (isVendorPage) {
        formType = "VENDOR";
    } else if (isProductPage) {
        formType = "PRODUCT";
    }
    const onSetOpen = (value) => {
        setOpen(value);
        if (!value) {
            setValue('');
        }
    }
    const saveHandler = useCallback(() => {
        updateVsourceFieldMutation({
            variables: {
                [level === 'VENDOR' ? 'vendorId' : 'productId']: Number(targetId),
                input: {
                    fieldName: 'premium_benefit',
                    value,
                }
            }
        }).then((res) => {
            const data = _get(res, 'data.vlink');
            const dataKey = Object.keys(data);
            const savedValue = _get(data, `[${dataKey[0]}].value`);
            setValue(savedValue);
            setSavedValue(savedValue);
            setOpen(false);
            newSuccessAlert("Benefits updated successfully")
        })
            .catch(e => {
                console.log(e.message);
                console.error("Premium Benefit block error: ", e)
                newErrorAlert(e.message ?? "Error saving the data");
            });
    }, [value])

    useEffect(() => {
        if(vsourceData) {
            setValue(vsourceData.premium_benefit);
        }
    }, [vsourceData]);

    return (
        <FeatureBlock
            title={formType === 'VENDOR' ? 'Benefits & Description' : 'Product Benefits'}
            headerButtonValue={showButtons ? "Edit" : null}
            onTitleBtnClick={() => onSetOpen(true)}
        >
         <>
             <StyledText>
                 {(!!vsourceData?.premium_benefit || !!savedValue) && <p dangerouslySetInnerHTML={{__html: savedValue || vsourceData.premium_benefit}}></p>}
             </StyledText>
             <Modal
                 size="small"
                 open={open}
                 onClose={() => onSetOpen(false)}
             >
                 <Modal.Header>Benefits</Modal.Header>
                 <Modal.Content>
                     <RichTextEditor initialValue={savedValue || vsourceData?.premium_benefit} onChange={value => setValue(value)} />
                 </Modal.Content>
                 <Modal.Actions>
                     <Button positive onClick={saveHandler}>
                         Save
                     </Button>
                 </Modal.Actions>
             </Modal>
         </>
        </FeatureBlock>
    )
}