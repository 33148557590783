import React, { useCallback, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PageHeader } from "../components/PageHeader";
import CloseIcon from "../components/icons/CloseIcon";
import { AgreementContext } from "../context/agreement/AgreementContext";
import { get } from "lodash";

import CreateAgreementForm from "../components/forms/CreateAgreementForm";
import { AuthContext } from "../context/AuthContext";

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin: 0 16px;
`;

export const AgreementConfiguratorPage = () => {
    const { context: authContext } = useContext(AuthContext);
    let history = useHistory();
    let match = useRouteMatch();

    const {
        context: { isLoading, selectedAgreement },
        createNewAgreement,
        fetchAgreement,
        updateAgreement,
    } = useContext(AgreementContext);

    //TODO: move customer name into context.s
    const [agreement, setAgreementInitialValues] = useState({
        customerName: authContext.activeEntity.name,
        customerId: authContext.activeEntity.id,
        masterProductName: "",
        vendorName: "",
        vendorId: "",
        docs: [],
    });

    const agreementId = get(match, "params.id", null);

    const [isEditing, setEditing] = useState(false);
    const [pageTitle, setPageTitle] = useState("...");
    const [curAgreementId, setCurAgreementId] = useState(null);

    useEffect(() => {
        const selectedAgreementId = get(selectedAgreement, "id", null);

        if (agreementId === null || agreementId === "") {
            history.goBack();
        }

        if (agreementId !== "new") {
            setEditing(true);

            if (!selectedAgreementId) console.log("selectedAgreementId is null");
            console.log(`${selectedAgreementId} === ${agreementId}`);

            fetchAgreement(agreementId)
                .then((fetchedAgreement) => {
                    setCurAgreementId(fetchedAgreement.id);
                    setAgreementInitialValues({
                        customerName: authContext.activeEntity.name,
                        customerId: authContext.activeEntity.id,
                        masterProductName: fetchedAgreement.masterProduct,
                        docs: fetchedAgreement.documents.items.reduce((memo, item) => {
                            memo.push(item);
                            return memo;
                        }, []), //TODO: Format that this to match the correct document object for the create form
                        ...fetchedAgreement,
                    });
                })
                .catch((err) => {
                    console.error(`agreementId : Agreement with ${agreementId} id must be deleted.`, err);
                    history.push(`/vkey/agreements`);
                });
            setPageTitle("Edit Agreement");
        } else {
            setPageTitle("New Agreement");
        }
    }, []);

    useEffect(() => {
        console.log("agreement : ", agreement);
    }, [agreement]);

    const handleFormSubmission = async (agreementData, navTo) => {
        agreementData.createdBy = authContext.user.attributes.email;

        if (isEditing) {
            updateAgreement({ ...agreementData, id: agreementId }).then((_) => {
                if (navTo === "dataEntry") {
                    history.push(`/vkey/agreements/meta/${agreementId}`);
                } else {
                    history.push(`/vkey/agreements`);
                }
            });

            return;
        }

        createNewAgreement(agreementData)
            .then((newAgreement) => {
                if (navTo === "dataEntry") {
                    history.push(`/vkey/agreements/meta/${newAgreement.id}`);
                } else {
                    history.push(`/vkey/agreements`);
                }
            })
            .catch((e) => {
                console.error("Error creating agreement", e);
            });
    };

    const goBack = useCallback(() => {
        history.goBack();
    }, []);

    return (
        <Wrapper>
            <PageHeader title={pageTitle}>
                <CloseIcon onClick={goBack} />
            </PageHeader>
            <CreateAgreementForm
                {...agreement}
                match={match}
                isEditing={isEditing}
                curAgreementId={curAgreementId}
                loading={isLoading}
                onSubmit={handleFormSubmission}
            />
        </Wrapper>
    );
};
