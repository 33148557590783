import styled from "styled-components";
import { PageWrapper } from "../../components/PageWrapper";
import PremiumForm from "../../components/vlink/PremiumForm";
import RevisionForm from "../../components/vlink/RevisionForm";
import { VendorFormsWrapper } from "./VendorDetails";
import ProfileHeader from "../../components/ProfileHeader";


export default function VendorDetailsPublic() {
    let context = 'PUBLIC';
    return (
        <PageWrapper>
            {/* <Breadcrumb sections={breadcrumbSections} /> */}
            <ProfileHeader context={context} level='VENDOR' />
            <VendorFormsWrapper>
                <RevisionForm 
                    context={context} 
                    level='VENDOR'
                />
                <PremiumForm context={context} level='VENDOR'/>
            </VendorFormsWrapper>
        </PageWrapper>
    )
}