import React from "react";

const SafeIcon = props => (
    <svg width={32} height={32} {...props}>
        <path
            d="M1.622 0C.73 0 0 .721 0 1.602v28.41c0 .881.73 1.603 1.622 1.603h26.476a.39.39 0 0 0 .396-.386.39.39 0 0 0-.396-.386H1.622a.827.827 0 0 1-.842-.83V1.602c0-.467.37-.832.842-.832h28.756c.473 0 .842.365.842.831v28.41a.827.827 0 0 1-.842.832.39.39 0 0 0-.396.385.39.39 0 0 0 .396.386c.892 0 1.622-.722 1.622-1.603V1.602C32 .722 31.27 0 30.378 0H1.622zM15.22 8.47c-4.09 0-7.403 3.297-7.403 7.337 0 4.04 3.312 7.314 7.403 7.314 3.416 0 6.279-2.28 7.134-5.386h3.792c1.075 0 1.951-.866 1.951-1.928 0-1.062-.876-1.927-1.95-1.927h-3.793a7.404 7.404 0 0 0-7.134-5.41zm-.39.795v3.097c-.671.075-1.29.338-1.793.734l-2.232-2.192a6.666 6.666 0 0 1 4.024-1.639zm.78 0a6.665 6.665 0 0 1 4.024 1.639l-2.232 2.192a3.539 3.539 0 0 0-1.792-.734V9.265zm-5.342 2.193l2.207 2.18a3.442 3.442 0 0 0-.743 1.784H8.597a6.486 6.486 0 0 1 1.671-3.964zm9.903 0a6.486 6.486 0 0 1 1.67 3.964h-3.134a3.443 3.443 0 0 0-.744-1.783l2.208-2.181zm-4.952 1.65c1.514 0 2.732 1.204 2.732 2.7 0 1.495-1.218 2.698-2.732 2.698-1.514 0-2.732-1.203-2.732-2.699 0-1.495 1.218-2.698 2.732-2.698zm7.305 1.543h3.622c.656 0 1.17.509 1.17 1.156 0 .648-.514 1.157-1.17 1.157h-3.622c.061-.377.098-.764.098-1.157 0-.396-.037-.777-.098-1.156zM8.597 16.193h3.122c.075.672.35 1.281.756 1.783l-2.22 2.18a6.463 6.463 0 0 1-1.658-3.963zm10.122 0h3.122a6.462 6.462 0 0 1-1.658 3.964l-2.22-2.181a3.427 3.427 0 0 0 .756-1.783zm-5.695 2.325c.505.4 1.13.66 1.805.735v3.072a6.65 6.65 0 0 1-4.024-1.626l2.219-2.18zm4.39 0l2.22 2.18a6.65 6.65 0 0 1-4.024 1.627v-3.072a3.53 3.53 0 0 0 1.804-.735z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

export default SafeIcon;
