import API, { graphqlOperation } from "@aws-amplify/api";
import { vendor, vendorsBy } from "../../graphql/queries";

export const generateAgreementName = (agreement, vendorName, activeEntity) => {
    return `${activeEntity?.name || ""}-${vendorName || ""}-${agreement?.masterProduct || ""}`;
};

export const getVSourceVendor = async (vSourceId) => {
    try {
        let {
            data: { vendor: vend },
        } = await API.graphql(graphqlOperation(vendor, { id: vSourceId }));

        return vend;
    } catch (e) {
        console.log("Error fetching vendor data:", e);
    }
};

export const getVSourceVendorsByID = async (vSourceIDs) => {
    let {
        data: {
            vendorsBy: { data: vendorsList },
        },
    } = await API.graphql(
        graphqlOperation(vendorsBy, {
            param: "ids",
            value: vSourceIDs,
            orderBy: "full_legal_name",
            order: "ASC",
        })
    );

    vendorsList = vendorsList.reduce((memo, vendor) => {
        memo[vendor.id] = vendor;
        return memo;
    }, {});

    return vendorsList;
};
