import { camelCase, upperFirst } from "lodash";
import API, { graphqlOperation } from "@aws-amplify/api";

const upsertObject = async (property, createMutation, updateMutation, propertyName, activeEntity) => {
    let operation = createMutation;
    // console.log("property:", property);
    const metaPropertyName = camelCase(`agreementMeta${upperFirst(propertyName)}Id`);

    if (!property) {
        return { id: metaPropertyName, value: null };
    }

    if (property.id) {
        operation = updateMutation;
    }

    const { data } = await API.graphql(
        graphqlOperation(operation, {
            input: { ...property, groupsCanAccess: activeEntity?.groupName },
        })
    );

    const upsertedObject = data[Object.keys(data)[0]];

    return { id: metaPropertyName, value: upsertedObject.id };
};

export default upsertObject;
