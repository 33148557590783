import React, { createContext, useMemo } from "react";
import { useImmerReducer } from "use-immer";

const initialState = {
    errors: [],
    success: [],
    alerts: [],
};

// Check if this message is already in the array.
const isDuplicate = (message, list) => {
    // Convert immer Proxy object back to JS object
    list = JSON.parse(JSON.stringify(list))
    const res = list.find(el => el.message === message);
    if(res){
        return true;
    }
    return false;
}

const reducer = (draft, action) => {
    switch (action.type) {
        case "NEW_ERROR":
            if(!isDuplicate(action.payload, draft.errors)){
                draft.errors = [
                    ...draft.errors,
                    { message: action.payload, timestamp: action.timestamp },
                ];
            }
            break;
        case "NEW_SUCCESS":
            if(!isDuplicate(action.payload, draft.success)){
                draft.success = [
                    ...draft.success,
                    { message: action.payload, timestamp: action.timestamp },
                ];
            }
            break;
        case "NEW_ALERT":
            if(!isDuplicate(action.payload, draft.alerts)){
                draft.alerts = [
                    ...draft.alerts,
                    { message: action.payload, timestamp: action.timestamp },
                ];
            }
            break;
        case "DELETE_ERROR":
            draft.errors = draft.errors.filter(
                (el) => el.timestamp !== action.timestamp
            );
            break;
        case "DELETE_ALERT":
            draft.alerts = draft.alerts.filter(
                (el) => el.timestamp !== action.timestamp
            );
            break;
        case "DELETE_SUCCESS":
            draft.success = draft.success.filter(
                (el) => el.timestamp !== action.timestamp
            );
            break;
        default:
            break;
    }
};

export const AlertContext = createContext(initialState);

export const AlertContextProvider = ({ children, ...rest }) => {
    const [context, dispatch] = useImmerReducer(reducer, initialState);

    const value = useMemo(
        () => ({
            context,
            dispatch,
        }),
        [context]
    );

    return (
        <AlertContext.Provider {...rest} value={value}>
            {children}
        </AlertContext.Provider>
    );
};
