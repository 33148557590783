import React, { useState, useEffect, useCallback, useMemo, useContext, useRef } from "react";
import styled from 'styled-components';
import { AuthContext } from "../../context/AuthContext";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { GET_DOCUMENT_DATA } from "../../graphql/customQueries";
import { pdfjs } from 'react-pdf';
import DragSelect from "dragselect";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TextractArea = styled.div`
  width: 50%;
  overflow-y: scroll;
`;

const CanvasWrapper = styled.div`
  position: relative;
  width: 50%;
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;

  canvas {
    display: block;
    width: 100%;
    margin: 0;
    border: 1px solid rgba(0, 0, 0, 0.70);
    border-radius: 4px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }
  
  .ds-selected {
    outline: 3px solid black;
    outline-offset: 3px;
    color: black;
    font-weight: bold;
  }
`;

const Line = styled.div`
  background: #f8f8f8;
  margin: 4px;
  padding: 2px;
  border-radius: 0 4px 4px 0;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.07);
  border: 1px solid rgba(0, 0, 0, 0.40);
  transition: background 100ms ease-in-out;

  &:hover {
    background: rgba(253, 201, 30, 0.4);
  }
`;

const Word = styled.div`
  display: inline-block;
  background: #1ea7fd;
`;

const BoundingBoxArea = styled.div`
  position: relative;
`;

const BoundingBoxHighLighter = styled.div`
  opacity: 0;
  transition: opacity 100ms ease-in-out/*, left 100ms ease-in-out, top 100ms ease-in-out, right 100ms ease-in-out, height 100ms ease-in-out*/;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 0;

  background: rgba(253, 201, 30, 0.4);
`;

const BoundingBox = styled.div`
  position: absolute;
`;

const FormBox = styled.div`
  background: #fff;
  position: absolute;
  right: calc(100% - 20px);
  top: 0;
  width: 400px;
  border: 1px solid rgba(0, 0, 0, 0.70);
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  padding: 1rem;
  
  display: flex;
  flex-direction: column;
  
  textarea {
    width: 100%;
    height: 100px;
    margin: 1rem 0;
  }
  
  button {
    margin-left: auto;
  }
`;

const Category = styled.div`
  padding: 32px 8px 8px 8px;
  border: 1px solid #dee0e2;
  border-radius: 0 8px 8px 8px;
  margin: 8px 16px;
  position: relative;
`;

const CategoryTitle = styled.div`
  background: #dee0e2;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 0 0 8px 0;
  color: #242424;
  padding: 4px 12px;
  font-size: 12px;
`;

const DocumentNotes = ({ match: { params } }) => {
    const {
        fetchDoc,
        context: { activeEntity },
    } = useContext(AuthContext);

    const [runTextract, { called, loading, data }] = useLazyQuery(GET_DOCUMENT_DATA, {
        variables: { key: `public/vkey/contracts/${activeEntity.groupName}/${params.id}`},
    });

    const [textRows, setTextRows] = useState([]);

    const prepareData = () => {
        const dataChunks = data?.vkey?.document?.documentData?.chunk;

        if (dataChunks && dataChunks.length) {
            const [textData] = dataChunks;

            const rows = [];
            let rowIndex = 0;

            textData.forEach((chunk, index) => {
                if (chunk.BlockType === 'LINE') {
                    const { Geometry: { BoundingBox } } = chunk;
                    const { Top } = BoundingBox;

                    if (!rows[rowIndex]) {
                        rows[rowIndex] = [];
                    }

                    rows[rowIndex].push(chunk);

                    const next = index < textData.length - 2 ? textData[index + 1] : null;

                    if (Math.abs(next?.Geometry?.BoundingBox?.Top - Top) > 0.01) {
                        rowIndex += 1;
                    }
                }
            });

            setTextRows(rows);
        }
    };


    const [highlight, setHighlight] = useState({ opacity: 0 });
    const [boundingBoxes, setBoundingBoxes] = useState([]);
    const [selected, setSelected] = useState({ text: '', top: 0, show: false });
    const [categories, setCategories] = useState([]);

    const renderData = useMemo( () => {
        const _boundingBoxes = [];

        const elems = <React.Fragment>
            {textRows.map((chunks, index) => {

                const getBoundingBox = (idx) => {
                    const { Geometry: { BoundingBox } } = chunks[idx];

                    return BoundingBox;
                };

                const BB = getBoundingBox(0);
                const BBLast = getBoundingBox(chunks.length - 1);

                const box = {
                    opacity: 1,
                    left: `${BB.Left * 100}%`,
                    right: `${(1 - BBLast.Left - BBLast.Width) * 100}%`,
                    top: `${BB.Top * 100}%`,
                    height: `${BB.Height * 100}%`,
                };

                _boundingBoxes.push({ ...box, id: chunks[0].Id });

                const map = (x, inMin, inMax, outMin, outMax) => (x - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
                const conf = map(Math.min.apply(Math, chunks.map((c) => c.Confidence)), 80, 100, 0, 100);
                const category = categories.find(c => c.id === chunks[0].Id);

                return (
                    <Line key={index}
                      data-id={chunks[0].Id}
                      className="line"
                      style={{ borderLeft: `3px solid hsl(${Math.round(conf)} 50% 50%)` }}
                      onMouseOver={() => {
                          setHighlight(box);
                      }}
                    >
                        {category?.name ?? ''}
                        {chunks.map(chunk => {
                            return chunk.Text + ' '
                        })}
                    </Line>)
            })}
        </React.Fragment>;

        setBoundingBoxes(_boundingBoxes);

        return elems;
    }, [textRows]);

    useEffect(() => {
        if (called && !loading && data) {
            prepareData();
        }
    }, [data, called, loading]);

    const [pdfLink, setPdfLink] = useState(null);

    useEffect(() => {
        fetchDoc({ key: `vkey/contracts/${activeEntity.groupName}/${params.id}`})
            .then((downloadedPdf) => {
                setPdfLink(downloadedPdf);
            })
            .catch((err) => {
                console.error("err with doc fetch ", err);
            });
    }, [pdfLink]);

    const canvasRef = useRef(null);
    const dragSelect = useRef(null);

    const selectCallback = useCallback((items) => {
        const text = [];

        if (items.items.length === 0) {
            setSelected(s => ({ ...s, show: false }));
        } else {
            items.items.forEach((item) => {
                const id = item.getAttribute('data-id');
                const order = item.getAttribute('data-order');
                const line = document.querySelector(`.line[data-id="${id}"]`);

                text.push({
                    order: parseInt(order, 10),
                    text: line.innerHTML,
                    top:item.style.top
                });
            });

            text.sort((a, b) => a.order - b.order);

            setSelected(s => ({
                show: true,
                top: text[0].top,
                text: text.map(t => t.text).join(' ')
            }));
        }

    }, [data]);

    if (canvasRef.current && dragSelect.current === null) {
        dragSelect.current = new DragSelect({
            area: canvasRef.current,
            draggability: false
        });

        dragSelect.current.subscribe('callback', selectCallback);
    }

    useEffect(() => {
        dragSelect.current?.setSelectables(document.getElementsByClassName('box'));
    }, [boundingBoxes]);

    useEffect(() => {
        if (pdfLink) {
            (async function () {
                // We import this here so that it's only loaded during client-side rendering.
                const pdfJS = await import('pdfjs-dist/build/pdf');
                const pdf = await pdfJS.getDocument(pdfLink).promise;
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1.5 });

                // Prepare canvas using PDF page dimensions.
                const canvas = canvasRef.current;
                const canvasContext = canvas.getContext('2d');
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                // Render PDF page into canvas context.
                const renderContext = { canvasContext, viewport };
                page.render(renderContext);
            })();
        }
    }, [pdfLink]);

    // public/vkey/contracts/${activeEntity.groupName}/${encodeURIComponent(params.id)}
    if (pdfLink) {
        return <Main>
            <TextractArea onMouseOut={() => { setHighlight({ opacity: 0 })}}>
                {!data && (
                    <button onClick={() => { runTextract() } }>
                        {loading ? 'Loading...' : 'Run text analysis'}
                    </button>
                )}
                <Category>
                    <CategoryTitle>Uncategorized</CategoryTitle>
                    {data && textRows.length && renderData}
                </Category>
            </TextractArea>
            <CanvasWrapper>
                <BoundingBoxArea>
                    <canvas ref={canvasRef} />
                    <BoundingBoxHighLighter style={highlight} />
                    {boundingBoxes.map((box, order) => <BoundingBox data-order={order} className="box" data-id={box.id} key={`box-${box.id}`} style={box} />)}
                    <FormBox style={{ display: selected.show ? 'flex' : 'none', top: selected.top }}>
                        <h2>Some input</h2>
                        <textarea defaultValue={selected.text}></textarea>
                        <button onClick={() => {
                            if (dragSelect.current) {
                                dragSelect.current.clearSelection(true);
                            }
                        }}>Submit</button>
                    </FormBox>
                </BoundingBoxArea>
            </CanvasWrapper>
        </Main>
    }

    return <div>Loading...</div>
};

export default DocumentNotes;