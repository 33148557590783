export const _searchInTree = (value, tree, pathArray = []) => {
    const level = pathArray.length + 1;
    let foundTreeBranch = [];
    for (let i = 0; i < tree.length; i++) {
        let item = tree[i];
        if (item.value.toLowerCase().trim().includes(value) && (level === item.level)) {
            foundTreeBranch.push(item)
        } else if (!!level && item.children && item.children.length > 0 && (level >= item.level + 1)) {
            let foundInChildren = _searchInTree(value, item.children, pathArray);
            if (foundInChildren && foundInChildren.length) {
                foundTreeBranch.push({
                    ...item,
                    children: [
                        ...foundInChildren
                    ]
                })
            }
        }
    }
    return foundTreeBranch;
};