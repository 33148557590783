import React from "react";
import styled from "styled-components/macro";
import Highlighter from "react-highlight-words";
import { Search } from "./DocSearch";
import AgreementDoc from "./AgreementDoc";
import { lowerCase, upperFirst } from "lodash";

const { formatToTimeZone } = require("date-fns-timezone");
const isDate = require("date-fns/is_date");

const SummaryCardWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    padding: 16px 32px;
    width: 300px;
`;
const SummaryCardRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
`;
const SummaryCardCol = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    border-right: 1px solid rgba(0,0,0,0.02);
    
    &:first-child {
      margin-top: 16px;
      color: rgba(0,0,0,.7);
      //flex: 0 0 ${8 * 10}px;
    }
    
    &:last-child {
      //flex: 0 0 ${8 * 8}px;
      padding: 4px 0 0 8px;
      border-right: none;
      text-align: left;
      font-size: 16px;
      align-items: flex-start;
      justify-content: flex-start;
    }
`;
const SummaryCard = ({ data }) => {
    return (
        <SummaryCardWrapper>
            {Object.keys(data).map((key) => {
                if (["id", "updatedAt", "createdAt", "updatedBy", "createdBy"].find((fieldLabel) => key === fieldLabel))
                    return null;

                return (
                    <SummaryCardRow key={key}>
                        <SummaryCardCol key={`${key}-key`}>{upperFirst(lowerCase(key))}</SummaryCardCol>
                        <SummaryCardCol key={data[key]}>
                            {typeof data[key] === "object" ? JSON.stringify(data[key]) : data[key]}
                        </SummaryCardCol>
                    </SummaryCardRow>
                );
            })}
        </SummaryCardWrapper>
    );
};
const TermsTable = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    flex-direction: column;
    border: 1px solid #c0c1c2;
    border-radius: 3px;
    margin: 0 ${8 * 3}px;

    & > *:nth-child(even) {
        background: #fff4db;
    }
`;
const TermsTableRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    padding: ${8}px;
`;
const TermsLabelCol = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    align-items: center;
    justify-content: flex-start;
    line-height: 1.6em;
    padding: 10px 20px;
    /* text-transform: capitalize; */
    border-right: 1px solid rgba(0, 0, 0, 0.06);
`;
const TermsValueColWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 12;
    line-height: 1.6em;
    align-items: flex-start;
    justify-content: center;
    padding: 16px 20px;

    & > *:first-child {
        margin-top: 0;
    }

  & > * {
    margin-top: 16px;
  }
  & .TermsValue {
    span {
      white-space: pre-wrap;
    }
  }
`;
const SelectClue = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
`;

const TermsValueCol = ({ type, value, searchTerm }) => {
    let comp = value;

    if (typeof value === "object" && value !== null) {
        if (value.items) {
            comp = value.items.map((item) => <SummaryCard data={item} />);
        } else {
            comp = <SummaryCard data={value} />;
        }
    }

    if (type === "date") {
        if (value && isNaN(value) && value.length > 0 && isDate(new Date(value))) {
            if (formatToTimeZone(new Date(value), "D-MMM-YYYY", { timeZone: "GMT" }) !== "Invalid Date") {
                comp = formatToTimeZone(new Date(value), "D-MMM-YYYY", { timeZone: "GMT" });
            }
        }
    } else {
        comp = value;
    }

    // if(comp && searchTerm) {
    //     /*
    //         normalize the search term and the value for the column
    //      */
    //     const compNormalized = comp.toLowerCase();
    //     const searchTermNormalized = searchTerm.toLowerCase();
    //     console.log("Array String : ", compNormalized.split(' ').includes(searchTermNormalized));
    //     console.log("Array String : ", compNormalized.split(' '));
    // }

    return (
        <TermsValueColWrapper>
            <Highlighter
                className="TermsValue"
                highlightClassName="YourHighlightClass"
                searchWords={searchTerm.split()}
                autoEscape
                textToHighlight={comp}
            />
        </TermsValueColWrapper>
    );
};

const DocsWrapper = styled.div`
    position: relative;
    display: flex;
    flex: 2;
    margin: 0 ${8 * 3}px;
    border: 1px solid #c0c1c2;
    border-radius: 3px;
    padding: 2em;
`;

export default ({
    categoryMeta,
    onSearchChange,
    SummaryDetailData,
    currentCategory,
    categorySearchTerm,
    documents,
}) => {
    if (currentCategory === "Documents") {
        return (
            <DocsWrapper>
                {documents.map((doc) => {
                    if(doc?.document){
                        return (
                            <AgreementDoc
                                large
                                navigate={true}
                                pid={doc.document?.vendExFileName}
                                contractKey={doc.document?.vendExFileName}
                                key={`agreement-doc-${doc?.id}`}
                            />
                        );
                    }
                    return null
                })}
            </DocsWrapper>
        );
    }

    return (
        <TermsTable>
            {Object.keys(categoryMeta).length > 0 ? (
                <>
                    <TermsTableRow key={"search"}>
                        <Search
                            style={{ margin: 0 }}
                            placeholder={"Search Agreement terms"}
                            onChange={(e) => onSearchChange({
                                currentTarget: {
                                    value: e
                                }
                            })}
                        />
                    </TermsTableRow>
                    {SummaryDetailData.length < 1 ? (
                        <div>No data for {currentCategory}</div>
                    ) : (
                        SummaryDetailData.map(({ label, value, category }, index) => {
                            let val = "";
                            if (typeof value === "number") {
                                val = value + "";
                            } else if (value !== undefined) {
                                val = value;
                            }
                            return (
                                <TermsTableRow key={label + index}>
                                    <TermsLabelCol>{label}</TermsLabelCol>
                                    <TermsValueCol type={category.type} searchTerm={categorySearchTerm} value={val} />
                                </TermsTableRow>
                            );
                        })
                    )}
                </>
            ) : (
                <SelectClue>Select Category</SelectClue>
            )}
        </TermsTable>
    );
};
