import React from "react";
import styled from "styled-components/macro";
import * as PropTypes from "prop-types";

const CostNameListWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    color: #505867;
    margin-right: 13%;
`;

const CostTitle = styled.h6`
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 15%;
`
const CostList = styled.ul`
  list-style: none;
  font-size: 1.2rem;
`
const CostListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 1.8rem;
  font-weight: bold;
  span {
    display: inline-block;
    border-radius:50%;
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 3rem;
  }
`
export const ConstNamesList = ({
    list
}) => {
    return <CostNameListWrap>
        <CostTitle>Annual Cost Breakdown</CostTitle>
        <CostList>
            {
                list.map(({ name, color }) => (
                    <CostListItem key={color}>
                        <span style={{backgroundColor: color}} />
                        {name}
                    </CostListItem>
                ))
            }
        </CostList>
    </CostNameListWrap>
};

ConstNamesList.propTypes = {
    list: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    })),
};

export default ConstNamesList;
