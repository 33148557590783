import React from "react";

const PhoneIcon = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1309 4.66211L15.0508 2.58398C14.7969 2.33008 14.457 2.1875 14.0977 2.1875C13.7383 2.1875 13.3984 2.32813 13.1445 2.58398L10.9043 4.82031C10.6504 5.07422 10.5078 5.41602 10.5078 5.77539C10.5078 6.13672 10.6484 6.47461 10.9043 6.73047L12.6543 8.48242C12.2556 9.4113 11.6828 10.2553 10.9668 10.9688C10.25 11.6895 9.41211 12.2578 8.48242 12.6602L6.73242 10.9082C6.47852 10.6543 6.13867 10.5117 5.7793 10.5117C5.60204 10.5111 5.42643 10.5458 5.26276 10.6139C5.0991 10.682 4.95067 10.782 4.82617 10.9082L2.58398 13.1445C2.33008 13.3984 2.1875 13.7402 2.1875 14.0996C2.1875 14.4609 2.32813 14.7988 2.58398 15.0547L4.66211 17.1328C5.0957 17.5664 5.69336 17.8145 6.30664 17.8145C6.43359 17.8145 6.55664 17.8047 6.68164 17.7832C9.26758 17.3574 11.834 15.9805 13.9062 13.9102C15.9766 11.8359 17.3516 9.26953 17.7812 6.68164C17.9043 5.94727 17.6582 5.19141 17.1309 4.66211ZM16.3965 6.44922C16.0156 8.75195 14.7773 11.0488 12.9121 12.9141C11.0469 14.7793 8.75195 16.0176 6.44922 16.3984C6.16016 16.4473 5.86328 16.3496 5.65234 16.1406L3.61133 14.0996L5.77539 11.9336L8.11523 14.2773L8.13281 14.2949L8.55469 14.1387C9.83384 13.6684 10.9954 12.9255 11.959 11.9617C12.9225 10.9978 13.6649 9.83596 14.1348 8.55664L14.291 8.13477L11.9316 5.77734L14.0957 3.61133L16.1367 5.65234C16.3477 5.86328 16.4453 6.16016 16.3965 6.44922Z" fill="#B5B5C3"/>
        </svg>
    );
};

export default PhoneIcon;
