import React, { useState, useContext, useEffect, useRef } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { productsByName, marketSegments } from "../graphql/queries";
import styled from "styled-components";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ThemeContext } from "styled-components";
import SearchIcon from "./icons/SearchIcon";
import { Search, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useAlerts } from "../hooks/useAlerts";
import { fetchVendorsBy } from "../pages/VSource/VendorsPage";
import { ButtonWrapper } from "../components/VendBtn";

const SearchBarBlock = styled.div`
    /*background: ${({ theme }) => theme.gray2};*/
    margin: 0 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 45px;
    button {
        background-color: #556983;
        height: 38px;
        margin-left: 0.25rem;
        margin-right: 0;
        &:hover {
            opacity: 0.95;
        }
    }
`;

const SearchInput = styled.input`
    height: 37px;
    width: 100%;
    padding-left: 3rem;
    border: none;
    color: ${({ theme }) => theme.text};
    font-family: inherit;
    grid-column: 1;
    grid-row: 1;
`;

const SearchInputWrapper = styled.div`
    margin: 0.5rem 0;
    flex: auto;
    display: grid;
    .vendor-searchbar {
        width: 100%;

        .input {
            width: 100%;
            input {
                border-radius: 0;
            }
        }
    }
`;

const SearchIconWrapper = styled(SearchIcon)`
    position: relative;
    left: 1rem;
    top: 7px;
    min-width: 20px;
    grid-column: 1;
    grid-row: 1;
`;

const DropdownBlock = styled.div`
    margin: 0.25rem;
    margin-left: 0;
    display: flex;
    align-items: center;
    & > div {
        min-width: 200px;
    }
`;

const ProductSearchResult = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const StyledSearch = styled(Search)`
    .results {
        max-height: 50vh;
        overflow-y: auto;
    }
`;

const animatedComponents = makeAnimated();

/**
 * General:
 * 1. Initial data is received and stored in "vendorsList" prop
 *    This is the data to display in the all-results table because it contains the data about all vendors
 * 2. The result of the search must be saved to "results" state variable
 *    and will be rendered in the filtered table
 *
 * Product search:
 * 1. With every letter entered the database query is made with a limit to 100.
 *    These results are displayed in the search dropdown. Front-end is not making
 *    any filtering or matching - everything is done on the back-end side
 * 2. If the user clicks on the result from the dropdown the page is redirected to the product page and
 *    the full list is not queried.
 * 3. If the user presses "Enter" then the database query without the limit is made and the result
 *    is displayed in the table (through variable "results")
 */

const VendorSearchBar = ({
    searchInputValue,
    setSearchVal,
    selectedFilter,
    setSelectedFilter,
    filterOptions,
    onSearchSubmit: _onSearchSubmit,
    selectedMarketSegments,
    setMarketSegments,
    clearSearchInput,
}) => {
    const [resultsLoading, setResultsLoading] = useState(false);
    const [productDataLoading, setProductDataLoading] = useState(false);

    // Search results values. Will be loaded as searchbar results dropdown
    const [results, setResults] = useState([]);
    // List of market segments selected by user
    const [searchResultsOpen, setResultsOpen] = useState(false);
    // Dataset for product search
    //const [productDataSet, setProductDataset] = useState([]);
    const [marketSegmentOptions, setMarketSegmentOptions] = useState({});
    const [marketSegmentOptionsLoaded, setSegmentOptionsLoaded] = useState(false);

    const themeContext = useContext(ThemeContext);
    const { newErrorAlert } = useAlerts();

    // Fetch the product search results
    const productSearch = async (text, limit) => {
        try {
            //setProductDataLoading(true);
            setResultsLoading(true);
            const { data } = await API.graphql(
                graphqlOperation(productsByName, {
                    name: text,
                    limit: limit ? limit : 0,
                })
            );
            if (data && data.productsByName && data.productsByName.data) {
                //setProductDataset(data.productsByName.data);
                setResults(data.productsByName.data);
                return data.productsByName;
            }
            return null;
        } catch (e) {
            console.log("Products fetch error: ", e);
            newErrorAlert("Error fetching products data");
        } finally {
            //setProductDataLoading(false);
            setResultsLoading(false);
        }
    };

    // Fetch the product search results
    const vendorSearch = async (text, limit) => {
        try {
            setResultsLoading(true);
            let res = await fetchVendorsBy("name", text, limit);

            if (res && res.data) {
                if (res.data.length > 0) {
                    res = res.data.map((vendor) => ({
                        ...vendor,
                        vendorId: vendor.id,
                    }));
                } else {
                    res = [];
                }
                setResults(res);
            }
            return null;
        } catch (e) {
            console.log("Vendors fetch error: ", e);
            newErrorAlert("Error fetching vendors data");
        } finally {
            //setProductDataLoading(false);
            setResultsLoading(false);
        }
    };

    const fetchMarketSegmentOptions = async () => {
        try {
            setSegmentOptionsLoaded(false);
            const { data } = await API.graphql(graphqlOperation(marketSegments));
            if (data && data.marketSegments) {
                const marketSegments = data.marketSegments.map((el) => ({
                    label: el.value,
                    value: el.code,
                }));

                marketSegments.sort(function (a, b) {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });

                setMarketSegmentOptions(marketSegments);
            }
        } catch (e) {
            console.log("Market segments fetch error: ", e);
            newErrorAlert("Error fetching market segments data");
        } finally {
            setSegmentOptionsLoaded(true);
        }
    };

    useEffect(() => {
        if (selectedFilter === "market_segment") {
            // Fetch the list of market segments
            fetchMarketSegmentOptions();
        }
    }, [selectedFilter]);

    const dropDownStyles = {
        option: (styles, state) => {
            const background = () => {
                if (state.isSelected) return themeContext.gray3;
                else if (state.isFocused) return "transparent";
                return styles.backgroundColor;
            };
            return {
                ...styles,
                "&:hover": {
                    backgroundColor: themeContext.gray1,
                },
                backgroundColor: background(),
                color: "hsl(0, 0%, 20%)",
            };
        },
        control: (styles, state) => ({
            ...styles,
            borderRadius: 0,
            boxShadow: "none",
            borderColor: "hsl(0, 0%, 80%)",
            "&:hover": {
                borderColor: "hsl(0, 0%, 80%)",
            },
        }),
    };

    const setFilter = (option) => {
        setSelectedFilter(option.value);
        // Reset the search input and results list
        setSearchVal("");
        setMarketSegments("");
    };

    const renderPlaceHolder = () => {
        if (selectedFilter === "vendor") return "Search Vendor Name";
        else if (selectedFilter === "product") return "Search Product Name and Product Brief Description";
        else return "";
    };

    const resultRenderer = (result) => {
        if (selectedFilter === "vendor") {
            return <Link to={`/vsource/vendors/${result.vendorId}`}>{result.full_legal_name}</Link>;
        } else if (selectedFilter === "product") {
            // TODO: link to vendor products tab?
            return (
                <Link to={`/vsource/product/${result.product_id}?vendor=${result.vendor_id}`}>
                    <ProductSearchResult>
                        <span>{result.name}</span>
                        <span>{result.full_legal_name}</span>
                    </ProductSearchResult>
                </Link>
            );
        }
    };

    const timeoutRef = useRef();
    const handleSearchChange = React.useCallback(
        (e, data) => {
            clearTimeout(timeoutRef.current);
            setSearchVal(data.value);
            setResultsOpen(true);
            setResultsLoading(true);

            timeoutRef.current = setTimeout(() => {
                // If user deletes search value reset the values and set initial table data
                if (data.value.length === 0) {
                    setSearchVal("");
                    setResultsOpen(false);
                    _onSearchSubmit();
                }

                if (selectedFilter === "vendor") {
                    vendorSearch(data.value, 100);
                } else if (selectedFilter === "product") {
                    // Fetch new set of product search results, limit by 100
                    productSearch(data.value, 100);
                }

                setResultsLoading(false);
            }, 300);
        },
        [selectedFilter]
    );

    const onSearchSubmit = async (e) => {
        e.preventDefault();

        // Close the search dropdown
        setResultsOpen(false);
        _onSearchSubmit();
    };

    const _clearSearchInput = () => {
        // Reset the market segment options dropdown to clear the selection
        setSegmentOptionsLoaded(false);
        setResultsOpen(false);
        clearSearchInput();
        setTimeout(() => {
            setSegmentOptionsLoaded(true);
        }, 0.1);
    };

    return (
        <SearchBarBlock>
            <DropdownBlock>
                <Select
                    options={filterOptions}
                    onChange={(e) => setFilter(e)}
                    defaultValue={filterOptions[0]}
                    styles={dropDownStyles}
                    value={filterOptions.find(option => option.value === selectedFilter)}
                />
                {selectedFilter === "market_segment" && (
                    <>
                        {marketSegmentOptionsLoaded && (
                            <div style={{ marginLeft: "0.25rem" }}>
                                <Select
                                    options={marketSegmentOptions}
                                    onChange={(e) => setMarketSegments(e)}
                                    styles={dropDownStyles}
                                    components={animatedComponents}
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={selectedMarketSegments.length ? marketSegmentOptions.filter(option => selectedMarketSegments.find(opt => opt.value === option.value)) : []}
                                />
                            </div>
                        )}
                        {!marketSegmentOptionsLoaded && <Loader active inline />}
                    </>
                )}
            </DropdownBlock>
            {(selectedFilter === "vendor" || selectedFilter === "product") && (
                <SearchInputWrapper>
                    <form onSubmit={onSearchSubmit}>
                        <StyledSearch
                            fluid
                            className="vendor-searchbar"
                            loading={resultsLoading}
                            onResultSelect={(e, data) => console.log("Data selected: ", data)}
                            onSearchChange={handleSearchChange}
                            resultRenderer={resultRenderer}
                            results={results}
                            value={searchInputValue}
                            open={searchResultsOpen}
                            placeholder={renderPlaceHolder()}
                            disabled={productDataLoading}
                        />
                    </form>
                    {/* <Form onSubmit={() => alert("On submit")}>
                        <Form.Input
                            placeholder="Search..."
                            value={searchInputValue}
                            onChange={handleSearchChange}
                        />
                    </Form> */}
                </SearchInputWrapper>
            )}
            {(selectedFilter !== "market_segment" ||
                (selectedFilter === "market_segment" && marketSegmentOptionsLoaded)) && (
                    <ButtonWrapper space-between onClick={_clearSearchInput}>
                        Clear Search
                    </ButtonWrapper>
                )}
        </SearchBarBlock>
    );
};

export default VendorSearchBar;
