import React from "react";
import styled from "styled-components/macro";
import * as PropTypes from "prop-types";

const getSizePercent = (size) => {
    if (size === "small") {
        return 1.5
    } if (size === "large") {
        return 1
    } else {
        return 1.3
    }
}
const calculateBySize = (value, { size }) => {
    return (value / getSizePercent(size)).toFixed(2);
}
const ValueCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: ${(props) => calculateBySize(1.6, props)}rem ${(props) => calculateBySize(2, props)}rem;
  margin-bottom: 6%;
  color: #ACB1B6;
  box-shadow: rgb(149 157 165 / 12%) 0 2px 30px;
`
const ValueTitle = styled.div`
  width: ${(props) => props.widthTitle};
  white-space: normal;
  margin-right: 1rem;
  font-size: ${(props) => calculateBySize(1.3, props)}rem;
  color: #80808F;
`
const Value = styled.div`
  margin-right: auto;
  font-weight: bold;
  font-size: ${(props) => calculateBySize(1.6, props)}rem;
  color: #1B2559;
`

export const ValueCard = ({
    title = "",
    value = 0,
    size= "medium",
    widthTitle= "auto",
}) => {
    return <ValueCardWrapper size={size} >
        <ValueTitle size={size} widthTitle={widthTitle}>{title}</ValueTitle>
        <Value size={size}>{value}</Value>
    </ValueCardWrapper>
};

ValueCard.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    size: PropTypes.oneOf([
        "small",
        "medium",
        "large"
    ]),
    fontSize: PropTypes.number,
    width: PropTypes.string,
};

export default ValueCard;
