import React from "react";

const GalleryIcon = ({ fill = '#C8C8C8', ...props}) => (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props} fill="none" >
        <rect width="6" height="6" rx="1" fill={fill}/>
        <rect y="10" width="6" height="6" rx="1" fill={fill}/>
        <rect y="20" width="6" height="6" rx="1" fill={fill}/>
        <rect x="10" width="6" height="6" rx="1" fill={fill}/>
        <rect x="10" y="10" width="6" height="6" rx="1" fill={fill}/>
        <rect x="10" y="20" width="6" height="6" rx="1" fill={fill}/>
        <rect x="20" width="6" height="6" rx="1" fill={fill}/>
        <rect x="20" y="10" width="6" height="6" rx="1" fill={fill}/>
        <rect x="20" y="20" width="6" height="6" rx="1" fill={fill}/>
    </svg>
);

export default GalleryIcon;