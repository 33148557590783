import { useContext } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "../components/Loader";
import PdfViewer from "../components/PdfViewer";
import { PrivateRoute } from "../components/PrivateRoute";
import { AuthContext } from "../context/AuthContext";
import { ClientDashboard } from "../pages/ClientDashboard";
import _get from "lodash/get";
import { DownloadItem } from "../components/DownloadItem";
import VPortRoutes from "./VPortRoutes";
import VKeyRoutes from "./VKeyRoutes";
import VSourceRoutes from "./VSourceRoutes";
import VlinkRoutes from "./VLinkRoutes";
import { AnalystDashboard } from "../pages/AnalystDashboard";
import UserRegistry from "../pages/Admin/UserRegistry";
import PostArticle from "../pages/Admin/PostArticle";
import ListArticles from "../pages/Admin/ListArticles";
import { AdminRoute } from "./AdminRoute";
import VKey2 from "../pages/VKey2";
import VPort from "../pages/VPort";

const AppWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
`;
const DownloadSectionWrapper = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 24px;
    right: 24px;

    height: auto;
    min-width: 360px;

    background: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 3px;
`;

export default function Routes() {
    const history = useHistory();

    const { context } = useContext(AuthContext);

    return context.user ? (
        <AppWrapper>
            {context?.entityCheckLoading === true ? (
                <Loader />
            ) : (
                <>
                    <Switch>
                        <PrivateRoute path={"/home"} component={ClientDashboard} />
                        <Route path={"/blank"} render={() => <div>Access Denied</div>} />
                        <PrivateRoute path={"/dashboard"} component={AnalystDashboard} />

                        <Route path="/vsource" component={VSourceRoutes} />
                        <Route path="/vlink" component={VlinkRoutes} />
                        <Route path="/vkey" component={VKeyRoutes} />
                        <Route path="/vkey2" component={VKey2} />
                        <Route path="/vport" component={VPort} />

                        <PrivateRoute path={"/user-admin"} component={UserRegistry} />
                        <AdminRoute path={"/admin-post-article"} component={PostArticle} />
                        <AdminRoute path={"/admin-list-article"} component={ListArticles} />

                        <PrivateRoute exact path={"/pdf-viewer/:id"} component={PdfViewer} />
                    </Switch>
                    <DownloadSectionWrapper>
                        {context.downloadList &&
                            Object.keys(context.downloadList).map((fileKey) => {
                                const name = _get(context.downloadList[fileKey], "name");
                                const loaded = _get(context.downloadList[fileKey], "loaded");
                                const total = _get(context.downloadList[fileKey], "total");
                                const estimated = _get(context.downloadList[fileKey], "estimated", "estimated 2mins");

                                return (
                                    <DownloadItem
                                        key={fileKey}
                                        name={name}
                                        loaded={loaded}
                                        total={total}
                                        estimated={estimated}
                                    />
                                );
                            })}
                    </DownloadSectionWrapper>
                </>
            )}
        </AppWrapper>
    ) : (
        <Redirect
            to={{
                pathname: "/login",
                state: { from: history.location },
            }}
        />
    );
}
