import React from "react";

const TableIcon = props => (
    <svg width={18} height={18} {...props}>
        <g fill="none" fillRule="evenodd">
            <rect width={18} height={18} rx={2} />
            <rect stroke="#546982" x={0.5} y={0.5} width={17} height={17} rx={1.5} />
            <path
                d="M12.666 8.666H9.333V5.333a.334.334 0 0 0-.667 0v3.333H5.333a.334.334 0 0 0 0 .667h3.333v3.333a.333.333 0 1 0 .667 0V9.333h3.333a.333.333 0 1 0 0-.667z"
                fill="#546982"
                fillRule="nonzero"
            />
        </g>
    </svg>
);

export default TableIcon;

