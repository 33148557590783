import sortClassifiers from "./sortClassifiers";

/**
 * @param  {string[]} values
 * @param  {{value}[]} classifiers
 * 
 * @returns {string[]}
 */
export default function sortClassifierValues(values, classifiers, key = 'value') {
    return classifiers
        .filter(classifier => values.includes(classifier[key]))
        .sort(sortClassifiers)
        .map(classifier => classifier[key]);
}