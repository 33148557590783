import React from "react";
import styled from "styled-components";

const Title = styled.h1`
    position: relative;
    font-size: 1.6rem !important;
    margin: 0.3em 0 !important;
    color: ${({ theme }) => theme.darkBlue};
    text-transform: ${(props) => (props.upper ? `uppercase` : `none`)};
`;

const Controls = styled.div`
    display: flex;
    position: relative;
    flex: 2 0 0;
    align-items: center;
    justify-content: flex-end;

    & > * {
        margin-left: 1em;
    }
`;

const CompanyHeader = ({ title, upper, children, ...props }) => {
    return (
        <div {...props}>
            <Title upper={upper}>
                <div
                    dangerouslySetInnerHTML={{
                        __html: title,
                    }}
                />
            </Title>
            <Controls>{children}</Controls>
        </div>
    );
};

export default styled(CompanyHeader)`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex: 0 0 auto;
    padding: 0.4em 0 0.4em 0;
    margin: 0 0 1.5em 0;
    border-bottom: ${(props) =>
        !!props.dashed ? `1px dashed #FFC003` : `none`};
`;
