import React, { useCallback, useEffect, useState, useContext } from "react";
import { Storage } from "aws-amplify";
import CloseIcon from "./icons/CloseIcon";
import styled from "styled-components/macro";
import styledMap from "styled-map";
import { rgba } from "polished";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
// import {useHistory, useRouteMatch} from 'react-router-dom';

const AgreementDocWrapper = styled.div`
    position: relative;
    display: flex;
    background: white;
    height: ${styledMap`
        large: 160px;
        small: 120px;
        medium: 100px;
        default: 80px;
    `};
    width: ${styledMap`
        large: 140px;
        small: 100px;
        medium: 80px;
        default: 60px;
    `};
    margin: 0 16px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: 0 3px 6px ${rgba("#000", 0.16)};
`;
export const AgreementDoc = ({ pid, contractKey, title, close, navigate, ...props }) => {
    const history = useHistory();
    // const match = useRouteMatch();
    const { context } = useContext(AuthContext);

    const handleDocOpen = useCallback(() => {
        history.push(`/vkey/vault/pdf-viewer/${contractKey}`);
    }, [pid]);

    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        //TODO: Fix the issue of having to prepend the word 'contracts' to this s3 key
        Storage.get(`vkey/contracts/${context.activeEntity.groupName}/${contractKey}.jpg`, { level: "public" }).then(
            (s3ImageUrl) => {
                setImageUrl(s3ImageUrl);
            }
        );
    }, [contractKey]);

    return (
        <AgreementDocWrapper
            title={title}
            style={{
                cursor: navigate ? "pointer" : "default",
            }}
            onClick={navigate ? handleDocOpen : () => {}}
            {...props}
        >
            {close ? <CloseIcon style={{ position: "absolute", top: 10, right: 10 }} onClick={close} /> : null}
            <img alt={"no preview available"} src={imageUrl} height={"90%"} width={"80%"} />
        </AgreementDocWrapper>
    );
};

export default AgreementDoc;
