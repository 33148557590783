import React, { createContext, useMemo, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { partialRight } from "lodash";
import { AuthContext } from "../AuthContext";
import vendors from "../../config/vendors";

import createNewAgreement from "./createNewAgreement";
import updateAgreement from "./updateAgreement";
import addDocumentToAgreement from "./addDocumentToAgreement";
import updateAgreementMeta from "./updateAgreementMeta";
import fetchAgreements from "./fetchAgreements";
import fetchAgreement from "./fetchAgreement";
import deleteAgreement from "./deleteAgreement";
import deleteDocument from "./deleteDocument";
import fetchAgreementMeta from "./fetchAgreementMeta";
import fetchSummaryList from "./fetchSummaryList";
import fetchMetaCategories from "./fetchMetaCategories";

import reducer from "./AgreementReducer";

const emptyAgreement = {
    documents: {
        items: [],
    },
};

const initialState = {
    agreements: [],
    selectedAgreement: emptyAgreement,
    selectedMeta: {},
    isLoading: false,
    vendors,
    vendorName: "",
    selectedVendor: {},
    agreementToDelete: {},
    agreementDeleted: {},
    agreementDeleteCheck: false,
    summaryList: {
        stats: {
            totalAgreements: 0,
            totalDocuments: 0,
            totalVendors: 0,
        },
        list: [],
    },
    categories: [],
};

const AgreementContext = createContext(initialState);

const selectAgreementForDeletion = async (agreementToDelete, { dispatch }) => {
    dispatch({ type: "SELECT_AGREEMENT_FOR_DELETION", agreementToDelete });
};

const clearAgreementDeleted = async ({ dispatch }) => {
    dispatch({ type: "CLEAR_AGREEMENT_DELETED" });
};

const deselectAgreementForDeletion = async ({ dispatch, user }) => {
    dispatch({ type: "DESELECT_AGREEMENT_FOR_DELETION" });
};

// prettier-ignore
const AgreementProvider = ({ children, ...rest }) => {
    const [context, dispatch] = useImmerReducer(reducer, initialState);

    const {
        context: { user, activeEntity },
    } = useContext(AuthContext);
    
    const createNewAgreementPartial           = partialRight(createNewAgreement, {dispatch, user, activeEntity});
    const fetchAgreementsPartial              = partialRight(fetchAgreements, {dispatch, user, activeEntity});
    const fetchAgreementPartial               = partialRight(fetchAgreement, {dispatch, user, activeEntity});
    const fetchSummaryListPartial             = partialRight(fetchSummaryList, {dispatch, user, activeEntity});
    const updateAgreementMetaPartial          = partialRight(updateAgreementMeta, {dispatch, user, activeEntity});
    const addDocumentToAgreementPartial       = partialRight(addDocumentToAgreement, {dispatch, user, activeEntity});
    const fetchAgreementMetaPartial           = partialRight(fetchAgreementMeta, {dispatch, user});
    const deleteAgreementPartial              = partialRight(deleteAgreement, {dispatch, user});
    const deselectAgreementForDeletionPartial = partialRight(deselectAgreementForDeletion, {dispatch, user});
    const selectAgreementForDeletionPartial   = partialRight(selectAgreementForDeletion, {dispatch, user});
    const clearAgreementDeletedPartial        = partialRight(clearAgreementDeleted, {dispatch, user});
    const updateAgreementPartial              = partialRight(updateAgreement, {dispatch, user, activeEntity});
    const deleteDocumentPartial               = partialRight(deleteDocument, {dispatch, user});
    const fetchMetaCategoriesPartial          = partialRight(fetchMetaCategories, {dispatch, user})

    const value = useMemo(
        () => ({
            context,
            dispatch,
            createNewAgreement: createNewAgreementPartial,
            fetchAgreements: fetchAgreementsPartial,
            fetchAgreement: fetchAgreementPartial,
            updateAgreementMeta: updateAgreementMetaPartial,
            addDocumentToAgreement: addDocumentToAgreementPartial,
            fetchAgreementMeta: fetchAgreementMetaPartial,
            deleteAgreement: deleteAgreementPartial,
            deselectAgreementForDeletion: deselectAgreementForDeletionPartial,
            selectAgreementForDeletion: selectAgreementForDeletionPartial,
            clearAgreementDeleted: clearAgreementDeletedPartial,
            updateAgreement: updateAgreementPartial,
            deleteDocument: deleteDocumentPartial,
            fetchSummaryList: fetchSummaryListPartial,
            fetchMetaCategories: fetchMetaCategoriesPartial,
        }),
        [context, dispatch, activeEntity]
    );

    return (
        <AgreementContext.Provider {...rest} value={value}>
            {children}
        </AgreementContext.Provider>
    );
};

export { AgreementProvider, AgreementContext };
