import _get from "lodash/get";
const axios = require("axios");

export const getUserRegistryData = async (user) => {
    try {
        const jwtToken = _get(user, "signInUserSession.idToken.jwtToken");
        const response = await axios({
            url: `${process.env.REACT_APP_USER_REGISTRY_BACKEND_URL}/getUserInfo`,
            method: "GET",
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Cache-Control": "no-store",
                Expires: "0",
            },
        });
        const userRegistryData = response.data;
        user.userRegistryData = userRegistryData;
    } catch (e) {
        console.error("Error when trying to get UR data", e);
    }
    return user;
};

export const getUserCredentailsFromAutenticationCode = async (code) => {
    let JWT;
    try {
        const response = await axios({
            url: `${process.env.REACT_APP_USER_REGISTRY_BACKEND_URL}/sso`,
            method: "POST",
            data: { code },
        });
        JWT = response?.data;
        console.log(JWT);
    } catch (e) {
        console.error("Error when trying to get UR data", e);
    }
    return JWT;
};
