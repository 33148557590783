import React, { createContext, useMemo, useContext } from "react";
import { useImmerReducer } from "use-immer";
import { partialRight } from "lodash";
import fetchProducts from "./fetchProducts";
import fetchProduct from "./fetchProduct";
import addProduct from "./addProduct";
import updateProduct from "./updateProduct";
import deleteProduct from "./deleteProduct";
import getProductAgreements from "./getProductAgreements";
import { fetchVendorsList, fetchVendor } from "./fetchVendors";
import { AuthContext } from "../AuthContext";
import { useAlerts } from "../../hooks/useAlerts";

const initialState = {
    vendors: {},
    products: [],
    productToDelete: {},
    productDeleted: {},
    productDeleteCheck: false,
    checkingProductToDeleteAgreements: false,
    productToDeleteAgreements: [],
    selectedProduct: null,
    vendorSearchTerm: '',
    vendorSelectedFilter: '',
    vendorSelectedMarketSegments: '',
    vendorProductSearchTerm: null,
    vendorProductSearchVendId: null,
    vendorsList: {},
    vendorsListMeta: {},
};

const selectProductForDeletion = async (productToDelete, { dispatch }) => {
    dispatch({ type: "SELECT_PRODUCT_FOR_DELETION", productToDelete });

    try {
        const agreements = await getProductAgreements(productToDelete.id);
        dispatch({ type: "CHECK_PRODUCT_AGREEMENTS", agreements });
    } catch (e) {
        console.error(e);
        //TODO handle the error case
        dispatch({ type: "CHECK_PRODUCT_AGREEMENTS", agreements: [] });
    }
};

const clearProductDeleted = async ({ dispatch }) => {
    dispatch({ type: "CLEAR_PRODUCT_DELETED" });
};

const deselectProductForDeletion = async ({ dispatch, user }) => {
    dispatch({ type: "DESELECT_PRODUCT_FOR_DELETION" });
};

const deleteVendorFromList = (vendors, vendorId) => {
    if (Object.keys(vendors).length === 0) {
        return vendors;
    } else {
        delete vendors[vendorId];
        return vendors;
    }
};

const updateVendorData = (vendors, vendor) => {
    if (!vendor) return vendors;

    // If vendors list is empty return the list with one element
    if (Object.keys(vendors).length === 0) return { [vendor.id]: vendor };
    // If this vendor is missing from the list add it there
    else {
        vendors[vendor.id] = vendor;
        return vendors;
    }
};

const updateVendorsList = (vendorsList, results, page) => {
    console.log(`page: ${page}`);
    if (!page) return vendorsList;

    vendorsList[page] = results;
    return vendorsList;
};

const VsourceContext = createContext(initialState);

const reducer = (draft, action) => {
    switch (action.type) {
        case "FETCH_PRODUCTS":
            draft.productsLoading = true;
            break;
        case "CREATING_PRODUCT":
            draft.productsLoading = true;
            break;
        case "PRODUCT_CREATED":
            draft.productsLoading = false;
            draft.product = action.product;
            break;
        case "LOADING_PRODUCTS":
            draft.productsLoading = true;
            break;
        case "PRODUCTS_FETCHED":
            draft.productsLoading = false;
            draft.products = action.products;
            break;
        case "SELECT_PRODUCT":
            draft.product = action.product;
            break;
        case "UPDATE_PRODUCT":
            draft.productsLoading = true;
            break;
        case "PRODUCT_UPDATED":
            draft.productsLoading = false;
            break;
        case "SELECT_PRODUCT_FOR_DELETION":
            draft.productDeleteCheck = true;
            draft.checkingProductToDeleteAgreements = true;
            draft.productToDelete = action.productToDelete;
            break;
        case "CHECK_PRODUCT_AGREEMENTS":
            draft.checkingProductToDeleteAgreements = false;
            draft.productToDeleteAgreements = action.agreements;
            break;
        case "DESELECT_PRODUCT_FOR_DELETION":
            draft.productDeleteCheck = false;
            draft.checkingProductToDeleteAgreements = false;
            draft.productToDelete = {};
            draft.productToDeleteAgreements = [];
            break;
        case "PRODUCT_DELETED":
            draft.productDeleteCheck = false;
            draft.productToDelete = {};
            draft.productToDeleteAgreements = [];
            draft.productDeleted = action.productDeleted;
            break;
        case "CLEAR_PRODUCT_DELETED":
            draft.productDeleteCheck = false;
            draft.productDeleted = {};
            draft.productToDeleteAgreements = [];
            break;
        case "LOADING_VENDORSLIST":
            draft.vendorsListLoading = true;
            break;
        case "VENDORSLIST_FETCHED":
            draft.vendorsListLoading = false;
            draft.vendorsList = updateVendorsList(draft.vendorsList, action.vendorsList, action.vendorsListMeta?.page);
            draft.vendorsListMeta = action.vendorsListMeta;
            break;
        case "CLEAR_VENDORSLIST":
            draft.vendorsList = initialState.vendorsList;
            break;
        case "LOADING_VENDOR":
            draft.vendorLoading = true;
            break;
        case "CLEAR_VENDOR_DATA":
            draft.vendors = deleteVendorFromList(draft.vendors, action.id);
            break;
        case "CLEAR_VENDOR_SEARCH":
            draft.vendorSelectedFilter = '';
            draft.vendorSelectedMarketSegments = '';
            draft.vendorSearchTerm = '';
            break;
        case "VENDOR_FETCHED":
            draft.vendorLoading = false;
            draft.vendors = updateVendorData(draft.vendors, action.vendor);
            break;
        case "VENDOR_SELECTED_MARKET_SEGMENTS":
            draft.vendorSelectedMarketSegments = action.selected_segments;
            break;
        case "VENDOR_SELECTED_FILTER":
            draft.vendorSelectedFilter = action.selected_filter;
            break;
        case "VENDOR_SEARCH_TERM":
            draft.vendorSearchTerm = action.search_term;
            break;
        case "VENDOR_PRODUCT_SEARCH_TERM":
            draft.vendorProductSearchTerm = action.search_term;
            draft.vendorProductSearchVendId = action.vendorId;
            break;
        default:
            break;
    }
};

const VsourceProvider = ({ children, ...rest }) => {
    const [context, dispatch] = useImmerReducer(reducer, initialState);
    const { newErrorAlert, newSuccessAlert } = useAlerts();
    const {
        context: { user },
    } = useContext(AuthContext);

    const value = useMemo(
        () => ({
            context,
            dispatch,
            fetchProducts: partialRight(fetchProducts, { dispatch, user }),
            addProduct: partialRight(addProduct, { dispatch, user }),
            fetchProduct: partialRight(fetchProduct, { dispatch, user }),
            updateProduct: partialRight(updateProduct, { dispatch, user }),
            deleteProduct: partialRight(deleteProduct, { dispatch, user }),
            clearProductDeleted: partialRight(clearProductDeleted, {
                dispatch,
                user,
            }),
            deselectProductForDeletion: partialRight(deselectProductForDeletion, { dispatch, user }),
            selectProductForDeletion: partialRight(selectProductForDeletion, {
                dispatch,
                user,
            }),
            fetchVendorsList: partialRight(fetchVendorsList, {
                dispatch,
                newErrorAlert,
                vendors: context.vendors,
            }),
            clearVendorsList: () => dispatch({ type: "CLEAR_VENDORSLIST" }),
            fetchVendor: partialRight(fetchVendor, { dispatch, newErrorAlert }),
            clearVendorData: (id) => dispatch({ type: "CLEAR_VENDOR_DATA", id }),
            clearVendorSearch: () => dispatch({ type: "CLEAR_VENDOR_SEARCH" }),
            setVendorSelectedMarketSegments: (selected_segments) => dispatch({ type: "VENDOR_SELECTED_MARKET_SEGMENTS", selected_segments }),
            setVendorSelectedFilter: (selected_filter) => dispatch({ type: "VENDOR_SELECTED_FILTER", selected_filter }),
            setVendorSearchTerm: (search_term) => dispatch({ type: "VENDOR_SEARCH_TERM", search_term }),
            setVendorProductSearchTerm: (search_term, vendorId) =>
                dispatch({
                    type: "VENDOR_PRODUCT_SEARCH_TERM",
                    search_term,
                    vendorId,
                }),
        }),
        [context, dispatch]
    );

    return (
        <VsourceContext.Provider {...rest} value={value}>
            {children}
        </VsourceContext.Provider>
    );
};

export { VsourceProvider, VsourceContext };
