import React, { useCallback, useReducer, useMemo, useState, useEffect } from "react";
import { Grid, Input, List, Modal } from "semantic-ui-react";
import _get from "lodash/get";
import HierarchicalSelectBox from "../components/hierarchical-selectbox/HierarchicalSelectBox";
import { InputRow, InputTitle } from "../pages/VSource/AddVendorPage";
import { ButtonWrapper } from "../components/VendBtn";
import Spinner from "./vlink/Spinner";
import styled from "styled-components";
import SelectedVidsvTable from "./SelectedVidsvTable";
import { vidsvMultiKeys, vidsvMultiLabels, vidsvMultiTypes } from "../Constants";
import { StyledModal } from "./vlink/UpdateFieldsetModal";
import { isUnique, notEmpty } from "../utils/validate";

const VidsvSection = styled.div`
    width: 33%;
`;

const SectionsWrapper = styled.div`
    display: flex;
    gap: 10px;
`;

const VidsvNameWrapper = styled.div`
    margin-bottom: 25px;
	width: 33%;
	
	label {
		display: block;
		margin: 5px 0;
		font-size: 16px;
		font-weight: bold;
	}

	.ui {
		width: 100%;
		&.input {
			width: '100%'
			>input {
				padding: 10px;
			}
		}
	}

    span {
        color: red;
    }
`;

const ErrorWrapper = styled.span`
    display: inline-block;
    margin-top: 10px;
    color: red;
`;

const selectedVidsvReducer = (state, { type, classifierType, data = []}) => {
    if (type === "UPDATE") {
        return {
            ...state,
            [classifierType]: [
                ...data
            ]
        }
    } else if (type === "INIT") {
        return {
            ...data
        }
    } else {
        return state;
    }
}

function extractNames(rows) {
    return rows.map(row => row.name);
}

function extractIds(allRows) {
    return allRows.map(row => {
        let newRow = {};
        vidsvMultiTypes.forEach((type, index) => {
            newRow[type] = row[vidsvMultiKeys[index]].map(el => el.id).sort();
        });

        return newRow;
    });
}


const VidsvModal = ({
    loading = false,
    show,
    mode,
    onClose,
    templates = [],
    editData,
    onSave,
    allRows,
    vidsvClassifiers
}) => {
    const [selectedVidsv, selectedVidsvDispatch] = useReducer(selectedVidsvReducer, null);
    const [name, setName] = useState(editData?.name ?? '');
    const [validationErrors, setValidationErrors] = useState({});

    const allRowsWithIds = useMemo(() => extractIds(allRows), [allRows]);

    const allRowNames = useMemo(() => extractNames(allRows), [allRows])

    useEffect(() => {
        setValidationErrors({});
        setName('');
    }, [show])

    const closeModal = () => {
        onClose();
    };

    const onSaveCallback = useCallback(() => {
        if(!notEmpty(name)) {
            setValidationErrors({name: 'Name is required!'})
            return;
        } else {
            setValidationErrors({});
        }
        let selectedItems = {...selectedVidsv};
        for(const item in selectedItems) {
            selectedItems[item] = selectedItems[item].map(data => data.id);
        }
        
        let namesToCompare = allRowNames;
        let rowsToCompare = allRowsWithIds;

        if(editData) {
            namesToCompare = extractNames(allRows.filter(row => row.id !== editData.id));
            rowsToCompare = extractIds(allRows.filter(row => row.id !== editData.id));
        }

        if(!isUnique(name, namesToCompare)) {
            setValidationErrors({name: 'Row with exact same name already exists!'});
            return;
        } else if(!isUnique(selectedItems, rowsToCompare)) {
            setValidationErrors({general: 'Row with exact same values already exists!'})
            return;
        } else {
            setValidationErrors({});
        }

        onSave(selectedItems, mode, name)
    }, [selectedVidsv, mode, name]);

    const onSelectVidsv = useCallback((arr, index) => {
        selectedVidsvDispatch({
            type: 'UPDATE',
            classifierType: vidsvMultiTypes[index],
            data: arr,
        })
    }, [vidsvMultiTypes]);

    const selectedItemsByType = useMemo(() => {
        let res = {};

        vidsvMultiTypes.forEach((type, index) => {
            res[type] = _get(editData, vidsvMultiKeys[index], []);
        });

        return res;
    }, [
        editData
    ]);

    useEffect(() => {
        if(editData) {
            setName(editData.name);
        }
    }, [editData])
    return (
        <StyledModal
            closeIcon
            onClose={closeModal}
            size="large"
            open={show}
            resizable={1}
            wider={1}
        >
            <Spinner loading={loading} />
            <Modal.Header>Product Attribute Groupings</Modal.Header>
            <Modal.Content>
                <SelectedVidsvTable selectedVidsv={selectedVidsv}/>
                <VidsvNameWrapper>
                    <label>Grouping Name:</label>
                    <Input 
                        value={name}
                        label={false}
                        onChange={(_, data) => setName(data.value)}
                        error={Boolean(validationErrors.name)}
                    />
                    {validationErrors.name && <span>{validationErrors.name}</span>}
                    
                </VidsvNameWrapper>
                <SectionsWrapper>
                {
                    vidsvMultiTypes.map((type, index) => (
                        <VidsvSection key={type}>
                            <InputTitle>{vidsvMultiLabels[index]}</InputTitle>
                            <HierarchicalSelectBox
                                flatTemplate={vidsvClassifiers[index]}
                                template={templates[index + 3]}
                                defaultSelectedValues={editData ? selectedItemsByType[type] : []}
                                isMultiselect
                                lastLevelSelectable={false}
                                showValueWithPath
                                onSelect={selectedItems => onSelectVidsv(selectedItems, index)}
                                nonSelectableParents
                                showSelectedItemsBar={false}
                            />
                        </VidsvSection>
                    ))
                }
                </SectionsWrapper>
                <ErrorWrapper>{validationErrors.general}</ErrorWrapper>
            </Modal.Content>
            <Modal.Actions>
                <ButtonWrapper space-between type={"button"} onClick={onSaveCallback}>
                    {
                        mode === 'add' ? 'Add' : 'Save'
                    }
                </ButtonWrapper>
            </Modal.Actions>
        </StyledModal>
    );
};

export default VidsvModal;