import React, { useState } from "react";
import styled from "styled-components";

const CardWrapper = styled.div`
    width: 100%;
    .page-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        perspective: 1000px;

        .card-container {
            cursor: pointer;
            height: 120px;
            margin: 0 4px;
            padding: 0;
            /* width: 250px; */
            width: 100%;
            max-width: 280px;
            position: relative;
            transform-style: preserve-3d;
            box-sizing: border-box;
            &.flipped {
                .front {
                    transform: rotateY(180deg);
                }
                .back {
                    transform: rotateY(0deg);
                }
            }
            .front,
            .back {
                box-sizing: border-box;
                height: 100%;
                width: 100%;
                display: block;
                background: white;
                position: absolute;
                border: 1px solid ${({ theme }) => theme.yellow1};
                backface-visibility: hidden;
                transform-style: preserve-3d;
                transition: -webkit-transform ease 500ms;
                transition: transform ease 500ms;
                overflow: hidden;
            }
            .front {
                z-index: 2;
                transform: rotateY(0deg);
            }
            .back {
                background: white;
                transform: rotateY(-180deg);
            }
        }
    }
`;

export const FlippingCard = ({ front, back, onClick }) => {
    const [flipped, setFlipped] = useState(false);

    return (
        <CardWrapper onClick={onClick} className={"flip-card"}>
            <div className="page-container">
                <div
                    onMouseEnter={() => setFlipped(!flipped)}
                    onMouseLeave={() => setFlipped(!flipped)}
                    className={"card-container" + (flipped ? " flipped" : "")}
                >
                    <div className="front">{front}</div>
                    <div className="back">{back}</div>
                </div>
            </div>
        </CardWrapper>
    );
};
