import React from "react";

const DownloadStopIcon = props => (
    <svg width={8} height={8} {...props}>
        <rect width={8} height={8} rx={2} fill="#64ACFF" fillRule="evenodd" />
    </svg>
);

export default DownloadStopIcon;

