import React from "react";

const ClockIcon = props => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99999 1.66675C5.40499 1.66675 1.66666 5.40508 1.66666 10.0001C1.66666 14.5951 5.40499 18.3334 9.99999 18.3334C14.595 18.3334 18.3333 14.5951 18.3333 10.0001C18.3333 5.40508 14.595 1.66675 9.99999 1.66675ZM9.99999 16.6667C6.32416 16.6667 3.33332 13.6759 3.33332 10.0001C3.33332 6.32425 6.32416 3.33341 9.99999 3.33341C13.6758 3.33341 16.6667 6.32425 16.6667 10.0001C16.6667 13.6759 13.6758 16.6667 9.99999 16.6667Z" fill="#9F3F47"/>
            <path d="M10.8333 5.83325H9.16666V10.3449L11.9108 13.0891L13.0892 11.9108L10.8333 9.65492V5.83325Z" fill="#9F3F47"/>
        </svg>
    );
};

export default ClockIcon;
