import { format } from "date-fns";
import _orderBy from "lodash/orderBy";
import _uniq from "lodash/uniq";
import { diff_match_patch } from "../../utils/diffMatchPatch";
import { moneyConvertWithoutDecimal } from "../../utils/numberConverts";
import _ from 'lodash';
import sortClassifierValues from "../../utils/sortClassifierValues";

export function getCustomerSupportData(classifiers) {
	return {
		title: 'Customer Support',
		group: 'foundational',
		rowList: [{
			status: 'TEMPLATE',
			tableName: 'customer_support',
			itemTypeCode: 'RECORD',
			fields: [
				{
					name: 'phoneNumber',
					label: 'Phone Number',
					type: 'text',
					widthRatio: 1 / 3
				},
				{
					name: 'timeZoneCode',
					label: 'Time Zone',
					type: 'select',
					widthRatio: 1 / 3,
					options: classifiers['TIME_ZONE'] || [],
					formatedValue: function(value) {
						return this.options.find(option => option.value === value)?.description;
					}
				},
				{
					name: 'supportHours',
					label: 'Support Hours',
					type: 'text',
					widthRatio: 1 / 3
				},
				{
					name: 'emailAddress',
					label: 'Email Address',
					type: 'text',
					widthRatio: 1 / 3
				},
				{
					name: 'supportPortalUrl',
					label: 'Support Portal URL',
					type: 'url',
					widthRatio: 1 / 3
				}
			]
		}]
	}
}

export function buildVendorFormData(vendorRevisionsData, classifiers, context) {
	let result = {
		vendorID: {
			title: 'Vendor ID',
			rowList: [{
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				status: 'TEMPLATE',
				labelLeft: true,
				fields: [
					{
						name: 'vendor_id_code',
						label: 'Vendor ID',
						type: 'text',
						widthRatio: 1
					}
				]
			}]
		},
		fullLegalName: {
			title: 'Full Legal Name',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'full_legal_name',
						label: 'Full Legal Name',
						type: 'text',
						widthRatio: 1
					}
				]
			}]
		},
		vendorLogoFilename: {
			title: 'Vendor Logo Image',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				fields: [
					{
						name: 'vendor_logo_filename',
						label: 'Vendor Logo Image',
						type: 'image',
						widthRatio: 1
					}
				]
			}]
		},
		briefDescription: {
			title: 'Brief Description',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				fields: [
					{
						name: 'brief_description',
						label: 'Brief Description',
						type: 'textarea',
						helpText: 'This text will appear in the header for the profile'
					}
				]
			}]
		},
		detailedDescription: {
			title: 'Detailed Description',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				fields: [
					{
						name: 'detailed_description',
						label: 'Detailed Description',
						type: 'textarea',
						rows: 20
					}
				]
			}]
		},
		aliases: {
			title: 'Aliases',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'alias',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'name',
						label: 'Alias',
						type: 'text',
						widthRatio: 1 / 2,
						rules: ['required']
					}
				]
			}]
		},
		historicalNames: {
			title: 'Historical Names',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'historical_name',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'name',
						label: 'Name',
						type: 'text',
						widthRatio: 1 / 2,
						rules: ['required']
					}
				]
			}]
		},
		tickerData: {
			title: 'Exchange/Ticker Symbol',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'ticker_data',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'stockExchange',
						label: 'Stock Exchange',
						type: 'text',
						widthRatio: 1 / 3,
						rules: ['required']
					},
					{
						name: 'tickerSymbol',
						label: 'Ticker Symbol',
						type: 'text',
						widthRatio: 1 / 3,
						rules: ['required']
					}
				]
			}]
		},
		companyType: {
			title: 'Company Type',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'company_type_code',
						label: 'Company Type',
						type: 'select',
						options: classifiers['COMPANY_TYPE'] || []
					}
				]
			}]
		},
		marketSegments: {
			title: 'Market Segments',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'market_segment',
				fieldName: 'type_code',
				itemTypeCode: 'FIELD',
				JSONType: 'array',
				labelLeft: true,
				modalInfo: 'Select all that apply.',
				fields: [
					{
						name: 'market_segments',
						label: 'Market Segments',
						type: 'multiselect_tree',
						options: classifiers['MARKET_SEGMENT'] || [],
						widthRation: 1,
						formatedValue: function(value) {
							console.log('formatedValue', value);
							if (value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return sortedValues.map(classifier => this.options.find(option => option.value === classifier)?.text)
									.join(', ');
							}

							return value;
						}
					}
				]
			}]
		},
		yearFounded: {
			title: 'Year Founded',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'year_founded',
						label: 'Year Founded',
						type: 'number'
					}
				]
			}]
		},
		headquarters: {
			title: 'Headquarters',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'headquarters',
						label: 'Headquarters',
						type: 'text'
					}
				]
			}]
		},
		numberOfOffices: {
			title: 'Number of Offices',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'number_of_offices',
						label: 'Number of Offices',
						type: 'number'
					}
				]
			}]
		},
		companySize: {
			title: 'Number of Employees',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'company_size_code',
						label: 'Number of Employees',
						type: 'select',
						rules: ['required'],
						options: classifiers['COMPANY_SIZE'] || []
					}
				]
			}]
		},
		website: {
			title: 'Website',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'url',
						label: 'Website',
						type: 'url'
					}
				]
			}]
		},
		LEICode: {
			title: 'LEI Code',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'lei_code',
						label: 'LEI Code',
						type: 'text'
					}
				]
			}]
		},
		immediateParentName: {
			title: 'Immediate Parent Name',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'immediate_parent_name',
						label: 'Immediate Parent Name',
						type: 'text',
						oldValue: ''
					}
				]
			}]
		},
		ultimateParentName: {
			title: 'Ultimate Parent Name',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'ultimate_parent_name',
						label: 'Ultimate Parent Name',
						type: 'text'
					}
				]
			}]
		},
		corporateActions: {
			title: 'Corporate Actions',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'corporate_action',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'typeCode',
						label: 'Action Type',
						type: 'select',
						widthRatio: 2 / 3,
						options: classifiers['CORPORATE_ACTION'] || [],
						rules: ['required']
					},
					{
						name: 'actionTime',
						label: 'Action Time',
						type: 'date',
						widthRatio: 1 / 3,
						formatedValue: val => format(val, 'DD-MMM-YYYY'),
						rules: ['required']
					},
					{
						name: 'description',
						label: 'Description',
						type: 'textarea',
						widthRatio: 1,
						rules: ['required']
					}
				]
			}]
		},
		investorRelations: {
			title: 'Investor Relations',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'investor_relations',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'contactPerson',
						label: 'Contact Person',
						type: 'text',
						widthRatio: 1 / 4,
						rules: ['required_partially']
					},
					{
						name: 'emailAddress',
						label: 'Email Address',
						type: 'text',
						widthRatio: 1 / 4,
						rules: ['required_partially']
					},
					{
						name: 'phoneNumber',
						label: 'Phone Number',
						type: 'text',
						widthRatio: 1 / 5,
						rules: ['required_partially']
					},
					{
						name: 'blogUrl',
						label: 'Blog URL',
						type: 'url',
						widthRatio: 1 / 4,
						rules: ['required_partially']
					},
				]
			}]
		},
		socialMediaChannels: {
			title: 'Social Media Channels',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'social_media_channel',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'socialMediaTypeCode',
						label: 'Social Media',
						type: 'select',
						widthRatio: 1 / 3,
						options: classifiers['SOCIAL_MEDIA_CHANNEL'] || [],
						rules: ['required']
					},
					{
						name: 'url',
						label: 'Url',
						type: 'url',
						widthRatio: 2 / 3,
						rules: ['required'],
					}
				]
			}]
		},
		officers: {
			title: 'Officers',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'officer_information',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'businessRoleCode',
						label: 'Role',
						type: 'select',
						widthRatio: 1 / 3,
						options: classifiers['BUSINESS_ROLE'] || [],
						rules: ['required']
					},
					{
						name: 'fullName',
						label: 'Full Name',
						type: 'text',
						widthRatio: 1 / 3,
						rules: ['required']
					},
					{
						name: 'businessRoleAdditional',
						label: 'Additional Officer Title',
						type: 'text',
						widthRatio: 1 / 3,
						rules: ['required_with_condition'],
						conditionFunction: (values) => {
							return values.includes("ADDITIONAL_OFFICER")
						}
					}
				]
			}]
		},
		financials: {
			title: 'Financials',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'vendor_financials',
				itemTypeCode: 'RECORD',
				sortByField: [
					'latestRevision.newRecordValueJson.periodEndDate',
					'submittedRevision.newRecordValueJson.periodEndDate',
					'fields[0].latestNewValue'
				],
				fields: [
					{
						name: 'periodEndDate',
						label: 'Financial Year Ending',
						type: 'date',
						formatedValue: (value) =>  value ? format(value, 'DD-MMM-YYYY') : '',
						saveDateFormat: 'YYYY-MM-DD',
						dateFormat: 'dd-MMM-yyyy',
						widthRatio: 1 / 3,
						rules: ['required']
					},
					{
						name: 'revenueAmount',
						label: 'Revenue Amount',
						type: 'number',
						formatedValue: (value) => value != null && value !== '' ? moneyConvertWithoutDecimal(value, true) : '',
						widthRatio: 1 / 3,
						rules: ['required', 'number']
					},
					{
						name: 'currencyCode',
						label: 'Currency',
						type: 'select',
						options: classifiers['CURRENCY'] || [],
						widthRatio: 1 / 5,
						rules: ['required']
					}
				]
			}]
		},
		locations: {
			title: 'Locations & Support',
			group: 'foundational',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'location',
				itemTypeCode: 'RECORD',
				supportLocations: getCustomerSupportData(classifiers).rowList,
				fields: [
					{
						name: 'name',
						label: 'Name',
						type: 'text',
						rules: ['required'],
						widthRatio: 2 / 5
					},
					{
						name: 'typeCode',
						label: 'Office',
						type: 'select',
						widthRatio: 2 / 5,
						options: classifiers['LOCATION_TYPE'] || []
					},
					{
						name: 'streetAddress1',
						label: 'Street Address 1',
						type: 'text',
						widthRatio: 2 / 5
					},
					{
						name: 'streetAddress2',
						label: 'Street Address 2',
						type: 'text',
						widthRatio: 2 / 5
					},
					{
						name: 'city',
						label: 'City',
						type: 'text',
						widthRatio: 1 / 3
					},
					{
						name: 'stateProvince',
						label: 'State/Province',
						type: 'text',
						widthRatio: 1 / 3
					},
					{
						name: 'postalCode',
						label: 'Postal Code',
						type: 'text',
						widthRatio: 1 / 3
					},
					{
						name: 'countryCode',
						label: 'Country',
						type: 'select',
						options: classifiers['COUNTRY'] || [],
						widthRatio: 2 / 5
					},
					{
						name: 'regionCode',
						label: 'Region',
						type: 'select',
						widthRatio: 2 / 5,
						options: classifiers['REGION'] || []
					},

					{
						name: 'division',
						label: 'Division',
						type: 'text',
						widthRatio: 2 / 3
					},
					{
						name: 'phoneNumber',
						label: 'Phone Number',
						type: 'text',
						widthRatio: 2 / 3
					},
				]
			}]
		},
		customerSupport: getCustomerSupportData(classifiers),
	};

	// Loop trough api data and generate state data for FormFieldset component for each revision field/record
	for(const revisionData of vendorRevisionsData) {
		switch (revisionData.tableName + (revisionData.itemTypeCode === 'FIELD' ? '|' + revisionData.fieldName : '')) {
			case 'vendor|vendor_id_code': {
				buildFieldsMetaData(result.vendorID, revisionData);
				buildFieldsData(result.vendorID);
				break;
			}
			case 'vendor|full_legal_name': {
				buildFieldsMetaData(result.fullLegalName, revisionData);
				buildFieldsData(result.fullLegalName);
				break;
			}
			case 'vendor|vendor_logo_filename': {
				buildFieldsMetaData(result.vendorLogoFilename, revisionData);
				buildFieldsData(result.vendorLogoFilename);
				break;
			}
			case 'vendor|brief_description': {
				buildFieldsMetaData(result.briefDescription, revisionData);
				buildFieldsData(result.briefDescription);
				break;
			}
			case 'vendor|detailed_description': {
				buildFieldsMetaData(result.detailedDescription, revisionData);
				buildFieldsData(result.detailedDescription);
				break;
			}
			case 'alias': {
				buildFieldsMetaData(result.aliases, revisionData);
				buildFieldsData(result.aliases);
				break;
			}
			case 'historical_name': {
				buildFieldsMetaData(result.historicalNames, revisionData);
				buildFieldsData(result.historicalNames);
				break;
			}
			case 'ticker_data': {
				buildFieldsMetaData(result.tickerData, revisionData);
				buildFieldsData(result.tickerData);
				break;
			}
			case 'market_segment|type_code': {
				buildFieldsMetaData(result.marketSegments, revisionData);
				buildFieldsData(result.marketSegments);
				break;
			}
			case 'vendor|company_type_code': {
				buildFieldsMetaData(result.companyType, revisionData);
				buildFieldsData(result.companyType);
				break;
			}
			case 'vendor|year_founded': {
				buildFieldsMetaData(result.yearFounded, revisionData);
				buildFieldsData(result.yearFounded);
				break;
			}
			case 'vendor|headquarters': {
				buildFieldsMetaData(result.headquarters, revisionData);
				buildFieldsData(result.headquarters);
				break;
			}
			case 'vendor|number_of_offices': {
				buildFieldsMetaData(result.numberOfOffices, revisionData);
				buildFieldsData(result.numberOfOffices);
				break;
			}
			case 'vendor|company_size_code': {
				buildFieldsMetaData(result.companySize, revisionData);
				buildFieldsData(result.companySize);
				break;
			}
			case 'vendor|url': {
				buildFieldsMetaData(result.website, revisionData);
				buildFieldsData(result.website);
				break;
			}
			case 'vendor|lei_code': {
				buildFieldsMetaData(result.LEICode, revisionData);
				buildFieldsData(result.LEICode);
				break;
			}
			case 'vendor|immediate_parent_name': {
				buildFieldsMetaData(result.immediateParentName, revisionData);
				buildFieldsData(result.immediateParentName);
				break;
			}
			case 'vendor|ultimate_parent_name': {
				buildFieldsMetaData(result.ultimateParentName, revisionData);
				buildFieldsData(result.ultimateParentName);
				break;
			}
			case 'corporate_action': {
				buildFieldsMetaData(result.corporateActions, revisionData);
				buildFieldsData(result.corporateActions);
				break;
			}
			case 'investor_relations': {
				buildFieldsMetaData(result.investorRelations, revisionData);
				buildFieldsData(result.investorRelations);
				break;
			}
			case 'social_media_channel': {
				buildFieldsMetaData(result.socialMediaChannels, revisionData);
				buildFieldsData(result.socialMediaChannels);
				break;
			}
			case 'officer_information': {
				buildFieldsMetaData(result.officers, revisionData);
				buildFieldsData(result.officers);
				break;
			}
			case 'vendor_financials': {
				buildFieldsMetaData(result.financials, revisionData);
				buildFieldsData(result.financials);
				break;
			}
			case 'location': {
				buildFieldsMetaData(result.locations, revisionData);
				buildFieldsData(result.locations);
				break;
			}
			case 'customer_support': {
				buildFieldsMetaData(result.customerSupport, revisionData);
				buildFieldsData(result.customerSupport);
				break;
			}
			default:
				break;
		}
	}

	fillCustomerSupportData(result);

	return result;
}

export function buildProductFormData(productRevisionsData, classifiers, context) {
	let result = {
		productID: {
			title: 'Product ID',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'product_id_code',
						label: 'Product ID',
						type: 'text',
						widthRatio: 1
					}
				]
			}]
		},
		name: {
			title: 'Product Name',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'name',
						label: 'Product name',
						type: 'text',
						widthRatio: 1
					}
				]
			}]
		},
		briefDescription: {
			title: 'Brief Description',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				fields: [
					{
						name: 'brief_description',
						label: 'Brief Description',
						type: 'textarea',
						helpText: 'This text will appear on tiles in the product section'
					}
				]
			}]
		},
		detailedDescription: {
			title: 'Detailed Description',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				fields: [
					{
						name: 'detailed_description',
						label: 'Detailed Description',
						type: 'textarea',
						rows: 20
					}
				]
			}]
		},
		statusCode: {
			title: 'Product Status',
			subTitle: 'Pre-release products do not appear in VSource.',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'status_code',
						label: 'Product Status',
						type: 'select',
						options: classifiers['PRODUCT_STATUS'] || []
					}
				]
			}]
		},
		URL: {
			title: 'URL',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'url',
						label: 'URL',
						type: 'url'
					}
				]
			}]
		},
		demoURL: {
			title: 'Demo URL',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product',
				itemTypeCode: 'FIELD',
				labelLeft: true,
				fields: [
					{
						name: 'demo_url',
						label: 'Demo URL',
						type: 'url'
					}
				]
			}]
		},
		productChanges: {
			title: 'Product Status Updates',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product_change',
				itemTypeCode: 'RECORD',
				fields: [
					{
						name: 'typeCode',
						label: 'Change Type',
						type: 'select',
						widthRatio: 2 / 3,
						options: classifiers['PRODUCT_CHANGE'] || [],
						formatedValue: function(value){ 
							return value === 'NONE' ? '[NONE]' : this.options.find(option => option.value === value)?.text
						}
					},
					{
						name: 'changeTime',
						label: 'Change Date',
						type: 'date',
						formatedValue: (value) =>  format(value, 'DD-MMM-YYYY'),
						saveDateFormat: 'YYYY-MM-DD',
						dateFormat: 'dd-MMM-yyyy',
						widthRatio: 1 / 3,
					},
					{
						name: 'changeNotes',
						label: 'Change notes',
						type: 'text',
						widthRatio: 1
					}
				]
			}]
		},
		jobRoles: {
			title: 'Target Audience',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product_tag',
				fieldName: 'job_role',
				itemTypeCode: 'FIELD',
				JSONType: 'array',
				labelLeft: true,
				fields: [
					{
						name: 'job_role',
						label: 'Target Audience',
						type: 'multiselect_tree',
						options: classifiers['JOB_ROLE'] || [],
						widthRation: 1,
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return getPathToNestedClassifiers(sortedValues, this.options);
							}

							return value;
						}
					}
				]
			}]
		},
		regulations: {
			title: 'Map Product to Regulations',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product_tag',
				fieldName: 'regulation',
				itemTypeCode: 'FIELD',
				JSONType: 'array',
				labelLeft: true,
				fields: [
					{
						name: 'regulation',
						label: 'Map Product to Regulations',
						type: 'multiselect_tree',
						options: classifiers['REGULATION'] || [],
						widthRation: 1,
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return getPathToNestedClassifiers(sortedValues, this.options);
							}

							return value;
						}
					}
				]
			}]
		},
		deliveryMethods: {
			title: 'Delivery Methods',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product_tag',
				fieldName: 'regulation',
				itemTypeCode: 'FIELD',
				JSONType: 'array',
				labelLeft: true,
				modalInfo: 'Select all that apply.', 
				fields: [
					{
						name: 'delivery_method',
						label: 'Delivery Methods',
						type: 'multiselect_tree',
						options: classifiers['DELIVERY_METHOD'] || [],
						widthRation: 1,
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return getPathToNestedClassifiers(sortedValues, this.options);
							}

							return value;
						}
					}
				]
			}]
		},
		vidsv: {
			title: 'Product Attribute Groupings',
			rowList: [{
				status: 'TEMPLATE',
				tableName: 'product_vidsv',
				itemTypeCode: 'RECORD',
				rules: ['row_unique'],
				fields: [
					{
						name: 'name',
						label: 'Grouping Name',
						type: 'text',
						widthRatio: 1 / 3,
						singleRow: true,
						rules: ['required', 'unique'],
						style: {
							fontStyle: 'italic'
						}
					},
					{
						name: 'productSolutionTypeCodeArray',
						label: 'Product Solutions',
						type: 'vidsv',
						widthRatio: 1 / 3,
						options: classifiers['PRODUCT_SOLUTION'] || [],
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return getPathToNestedClassifiers(sortedValues, this.options);
							}

							return value;
						}
					},
					{
						name: 'productClassificatorTypeCodeArray',
						label: 'Product Classifications',
						type: 'vidsv',
						widthRatio: 1 / 3,
						options: classifiers['PRODUCT_CLASSIFICATION'] || [],
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return getPathToNestedClassifiers(sortedValues, this.options);
							}

							return value;
						}
					},
					{
						name: 'productGeographyTypeCodeArray',
						label: 'Geographies',
						type: 'vidsv',
						widthRatio: 1 / 3,
						options: classifiers['PRODUCT_GEOGRAPHY'] || [],
						formatedValue: function(value) {
							if(value) {
								let sortedValues = sortClassifierValues(value.split(' '), this.options);
								return sortedValues.map(classifier => this.options.find(option => option.value === classifier)?.text)
								.join(', ');
							}

							return value;
						}
					}
				]
			}]
		},
	};

	// Loop trough api data and generate state data for FormFieldset component for each revision field/record
	for(const revisionData of productRevisionsData) {
		// make value variable to target on correct object/primitive in case of record/field

		switch (revisionData.tableName + (revisionData.fieldName != null ? '|' + revisionData.fieldName : '')) {
			case 'product|product_id_code': {
				buildFieldsMetaData(result.productID, revisionData);
				buildFieldsData(result.productID);
				break;
			}
			case 'product|name': {
				buildFieldsMetaData(result.name, revisionData);
				buildFieldsData(result.name);
				break;
			}
			case 'product|brief_description': {
				buildFieldsMetaData(result.briefDescription, revisionData);
				buildFieldsData(result.briefDescription);
				break;
			}
			case 'product|detailed_description': {
				buildFieldsMetaData(result.detailedDescription, revisionData);
				buildFieldsData(result.detailedDescription);
				break;
			}
			case 'product|status_code': {
				buildFieldsMetaData(result.statusCode, revisionData);
				buildFieldsData(result.statusCode);
				break;
			}
			case 'product|url': {
				buildFieldsMetaData(result.URL, revisionData);
				buildFieldsData(result.URL);
				break;
			}
			case 'product|demo_url': {
				buildFieldsMetaData(result.demoURL, revisionData);
				buildFieldsData(result.demoURL);
				break;
			}
			case 'product_change': {
				buildFieldsMetaData(result.productChanges, revisionData);
				buildFieldsData(result.productChanges);
				break;
			}
			case 'product_tag|job_role': {
				buildFieldsMetaData(result.jobRoles, revisionData);
				buildFieldsData(result.jobRoles);
				break;
			}
			case 'product_tag|regulation': {
				buildFieldsMetaData(result.regulations, revisionData);
				buildFieldsData(result.regulations);
				break;
			}
			case 'product_tag|delivery_method': {
				buildFieldsMetaData(result.deliveryMethods, revisionData);
				buildFieldsData(result.deliveryMethods);
				break;
			}
			case 'product_vidsv': {
				buildFieldsMetaData(result.vidsv, revisionData);
				buildFieldsData(result.vidsv);
				break;
			}
			default:
				break;
		}
	}

	return result;
}

// ex: getFieldValue(row, 'latestRevision', 'year')
function getFieldValue(row, valueProp, field) {
	let value;
	switch(valueProp) {
		case 'vsourceValue':
			if(row.itemTypeCode === 'RECORD'){
				if(row.vsourceRecordValueJson) {
					switch(field.type) {
						case 'multiselect_tree':
							value = row.vsourceRecordValueJson.join(' ');
							break;
						case 'vidsv':
							value = row.vsourceRecordValueJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.vsourceRecordValueJson[field.name];
							break;
					}
				}
			} else {
				value = row.vsourceFieldValue;
			}
			break;
		case 'latestOldValue':
			if(row.latestRevision) {
				if(row.itemTypeCode === 'RECORD'){
					switch(field.type) {
						case 'multiselect_tree':
							value = row.latestRevision.oldRecordValueJson.join(' ');
							break;
						case 'vidsv':
							value = row.latestRevision.oldRecordValueJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.latestRevision.oldRecordValueJson[field.name];
							break;
					}
				} else {
					value = row.latestRevision.oldFieldValue;
				}
			}
			break;
		case 'latestNewValue':
			if(row.latestRevision) {
				if(row.itemTypeCode === 'RECORD'){
					switch(field.type) {
						case 'multiselect_tree':
							value = row.latestRevision.newRecordValueJson.join(' ');
							break;
						case 'vidsv':
							value = row.latestRevision.newRecordValueJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.latestRevision.newRecordValueJson[field.name];
							break;
					}
				} else {					
					value = row.latestRevision.newFieldValue;
				}
			}
			break;
		case 'submittedOldValue':
			if(row.submittedRevision) {
				if(row.itemTypeCode === 'RECORD'){
					switch(field.type) {
						case 'multiselect_tree':
							value = row.submittedRevision.oldRecordValueJson.join(' ');
							break;
						case 'vidsv':
							value = row.submittedRevision.oldRecordValueJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.submittedRevision.oldRecordValueJson[field.name];
							break;
					}
				} else {
					value = row.submittedRevision.oldFieldValue;
				}
			}
			break;
		case 'submittedNewValue':
			if(row.submittedRevision) {
				if(row.itemTypeCode === 'RECORD'){
					switch(field.type) {
						case 'multiselect_tree':
							value = row.submittedRevision.newRecordValueJson.join(' ');
							break;
						case 'vidsv':
							value = row.submittedRevision.newRecordValueJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.submittedRevision.newRecordValueJson[field.name];
							break;
					}
				} else {
					value = row.submittedRevision.newFieldValue;
				}
			}
			break;
		case 'submittedVendexValue':
			if(row.submittedRevision) {
				if(row.itemTypeCode === 'RECORD' && row.submittedRevision.vendexNewRecordValuesJson){
					switch(field.type) {
						case 'multiselect_tree':
							value = row.submittedRevision.vendexNewRecordValuesJson.join(' ');
							break;
						case 'vidsv':
							value = row.submittedRevision.vendexNewRecordValuesJson[field.name]?.join(' ') || null;
							break;
						default:
							value = row.submittedRevision.vendexNewRecordValuesJson[field.name];
							break;
					}
				} else {
					value = row.submittedRevision.vendexNewFieldValue;
				}
			}
			break;
		default:
			value = null;
	}

	return value ?? null;
}

function buildFieldsData(section) {
	let templateRow = section.rowList[0];
	let currentRow = section.rowList[section.rowList.length - 1];
	currentRow.fields = templateRow.fields.map(function (field) {
		let fieldData = {...field};

		['vsourceValue', 'latestOldValue', 'latestNewValue', 'submittedOldValue', 'submittedNewValue', 'submittedVendexValue']
			.forEach(valueProp => {
				fieldData[valueProp] = getFieldValue(currentRow, valueProp, field);
			});
		
		return fieldData;
	});

}

// Build fieldset data with common top level properties to pass it to the FormFieldset component
function buildFieldsMetaData(section, revisionData) {
    let row = {};

    section.rowList.push(row);
	if(section.rowList[0].JSONType){
		row.JSONType = section.rowList[0].JSONType;
	}
 
    for (let prop in revisionData) {
		row[prop] = revisionData[prop];
    }

	// data.vsourceRecordValueJson = revisionData.vsourceRecordValueJson; 

    row.revisionId = null;
    row.certified = false;
    row.proposed = false;
    row.accepted = null;
    row.submitted = false;
    row.responded = false;
    row.respondedAt = null;
    row.declineComment = null;
    row.recordActionType = null;
    row.vendexResponseType = null;
	row.hasLatestRevision = false;
    row.new = false;
    row.deleted = false;

    if(revisionData.latestRevision) {
		row.hasLatestRevision = true;
		row.responded = revisionData.latestRevision.vendexRespondedAt != null;
        row.respondedAt = revisionData.latestRevision.vendexRespondedAt;
        row.revisionId = revisionData.latestRevision.revisionId;
        row.certified = revisionData.latestRevision.vendorActionTypeCode === 'CERTIFIED' ||
            (row.responded && revisionData.latestRevision.vendexActionTypeCode === 'ACCEPTED')
        row.proposed = revisionData.latestRevision.vendorActionTypeCode === 'PROPOSAL' &&
            (!row.responded || revisionData.latestRevision.vendexActionTypeCode !== 'ACCEPTED')
        row.recordActionType = revisionData.latestRevision.recordActionType;
		row.vendexResponseType = revisionData.latestRevision.vendexResponseTypeCode;
        row.new = row.latestRevision.recordActionType === 'ADD';
        row.deleted = row.latestRevision.recordActionType === 'DELETE';

        if(revisionData.submittedRevision) {
			row.submittedAt = revisionData.submittedRevision.vendorSubmittedAt;
			row.respondedAt = revisionData.submittedRevision.vendexRespondedAt;

			if(revisionData.latestRevision.revisionId === revisionData.submittedRevision.revisionId) {
				if(revisionData.submittedRevision.vendexActionTypeCode != null) {
					row.accepted = revisionData.submittedRevision.vendexActionTypeCode === 'ACCEPTED';
				}
				row.declineComment = revisionData.submittedRevision.vendexDeclineComment;
				row.submitted = revisionData.submittedRevision.vendorSubmittedAt != null;
			}
        }
    } else {
        if(revisionData.submittedRevision) {
            row.certified = revisionData.submittedRevision.vendorActionTypeCode === 'CERTIFIED';
            if(!row.certified) {
                if(revisionData.submittedRevision.vendexActionTypeCode === 'ACCEPTED'){
                    if(revisionData.itemTypeCode === 'RECORD'){
                        row.certified = JSON.stringify(revisionData.submittedRevision.newRecordValueJson) === JSON.stringify(revisionData.vsourceRecordValueJson);
                    } else {
                        row.certified = revisionData.submittedRevision.newFieldValue === revisionData.vsourceFieldValue;
                    }
                }
            }
			
			if(revisionData.submittedRevision.vendexActionTypeCode != null) {
				row.accepted = revisionData.submittedRevision.vendexActionTypeCode === 'ACCEPTED';
			}

            row.submitted = revisionData.submittedRevision.vendorSubmittedAt != null;
            row.submittedAt = revisionData.submittedRevision.vendorSubmittedAt;
            row.responded = revisionData.submittedRevision.vendexRespondedAt != null;
            row.respondedAt = revisionData.submittedRevision.vendexRespondedAt;
            row.recordActionType = revisionData.submittedRevision.recordActionType;
			row.vendexResponseType = revisionData.submittedRevision.vendexResponseTypeCode;
            row.new = row.submittedRevision.recordActionType === 'ADD';
			row.deleted = row.submittedRevision.recordActionType === 'DELETE';
			row.declineComment = revisionData.submittedRevision.vendexDeclineComment;
        }
    }	
}

function fillCustomerSupportData(formData) {
    formData.locations.rowList.slice(1).forEach((locationData, index) => {
        let supportData = formData.customerSupport.rowList.filter(data => data.parentItemId === locationData.itemId || data.parentItemId === -locationData.latestRevision?.revisionId);
		const customerSupportTemplate = {
			...formData.customerSupport.rowList[0],
			fields: formData.customerSupport.rowList[0].fields.map(field => ({...field}))
		};
		locationData.supportLocations = [customerSupportTemplate, ...supportData];
		locationData.supportLocations[0].parentItemId = locationData.itemId || -locationData.latestRevision?.revisionId || null;
    });
}

export function buildInsertRevisionInput(row, context, level) {
	let input = {
		context: context,
		tableName: row.tableName,
		itemTypeCode: row.itemTypeCode,
		vendorActionTypeCode: row.certified ? 'CERTIFIED' : row.proposed ? 'PROPOSAL' : 'NOT_CERTIFIED',
		itemId: row.itemId,
	};
	
	if(row.tableName === 'customer_support') {
		input.parentItemId = row.parentItemId;
	}

	if(row.itemTypeCode === 'FIELD' || row.tableName === 'product_tag') {
		input.fieldName = row.fieldName || row.fields[0].name;
	}

	if (row.itemTypeCode === 'FIELD') {
		input.oldFieldValue = row.fields[0].vsourceValue;
		if(row.proposed) {
			if (row.fields[0].type !== 'multiselect_tree') {
				input.newFieldValue =  row.fields[0].latestNewValue || row.fields[0].vsourceValue;
			} else {
				input.newFieldValue =  (row.fields[0].latestNewValue || '').split(' ').sort().join(' ');
			}
		} else {
			input.newFieldValue = row.fields[0].submittedVendexValue || row.fields[0].vsourceValue;
		}		
	} else {
		input.recordActionType = row.deleted && input.vendorActionTypeCode !== 'CERTIFIED' ? 'DELETE' : input.itemId ? 'UPDATE' : 'ADD';
		input.oldRecordValueJson = JSON.stringify(buildJsonFromRowFields(row, 'vsourceValue'));
		if(context === 'VENDEX') {
			input.newRecordValueJson = JSON.stringify(buildJsonFromRowFields(row,'submittedVendexValue', 'vsourceValue')) || input.oldRecordValueJson;
		} else {
			if(row.proposed) {
				input.newRecordValueJson = JSON.stringify(buildJsonFromRowFields(row, 'latestNewValue', 'vsourceValue')) || input.oldRecordValueJson;
			} else {
				input.newRecordValueJson = JSON.stringify(buildJsonFromRowFields(row, 'submittedVendexValue', 'vsourceValue')) || input.oldRecordValueJson;
			}		
		}
	}

	return input;
}

export function buildUpdateRevisionInput(row, context, level) {
	let input = {
		context: context,
		revisionStatusTypeCode: row.revisionStatusTypeCode
	};
	if(context === 'VENDOR') {
		input.vendorActionTypeCode = row.certified ? 'CERTIFIED' : row.proposed ? 'PROPOSAL' : 'NOT_CERTIFIED';
		if (row.itemTypeCode === 'FIELD') {
			if(row.proposed) {
				if (row.fields[0].type !== 'multiselect_tree') {
					input.newFieldValue =  row.fields[0].latestNewValue || row.fields[0].vsourceValue;
				} else {
					input.newFieldValue =  (row.fields[0].latestNewValue || '').split(' ').sort().join(' ');
				}
			} else {
				input.newFieldValue = row.fields[0].submittedVendexValue || row.fields[0].vsourceValue;
			}		
		} else {
			input.recordActionType = row.deleted && input.vendorActionTypeCode !== 'CERTIFIED' ? 'DELETE' : row.new ? 'ADD' : 'UPDATE';
			if(row.proposed) {
				input.newRecordValueJson = JSON.stringify(buildJsonFromRowFields(row, 'latestNewValue', 'vsourceValue'));
			} else {
				input.newRecordValueJson = JSON.stringify(buildJsonFromRowFields(row, 'submittedVendexValue', 'vsourceValue'));
			}		
		}
	} else {
		input.vendexActionTypeCode = row.accepted === true ? 'ACCEPTED' : row.accepted === false ? 'REJECTED' : null;
		if(row.accepted === false) {
			input.vendexResponseTypeCode = row.vendexResponseType;
			input.declineComment = row.declineComment;

			if(row.vendexResponseType === 'REJECT_SUBMIT_TO_VSOURCE') {
				if (row.itemTypeCode === 'FIELD') {
					if (row.fields[0].type !== 'multiselect_tree') {
						input['vendexNewFieldValue'] = row.fields[0].submittedVendexValue;
					} else {
						input['vendexNewFieldValue'] =  (row.fields[0].submittedVendexValue || '').split(' ').sort().join(' ');
					}
				} else {
					input['vendexNewRecordValueJson'] = JSON.stringify(buildJsonFromRowFields(row, 'submittedVendexValue', 'vsourceValue'));
				}
			}
		}
	}

	return input;
}

export function buildJsonFromRowFields(row, valueProp, fallbackProp) {
	let json = {};

	if(row.JSONType === 'array') {
		json = [];
	}
	
	row.fields.forEach(field => {
		let val = field[valueProp] ?? field[fallbackProp];
		if(val != null) {
			if(row.JSONType === 'array') {
				json = val.split(' ');
			} else if(field.type === 'vidsv') {
				if(val) {
					json[field.name] = val.split(' ');
					if(valueProp === 'latestNewValue' || valueProp === 'submittedVendexValue') {
						json[field.name].sort();
					}
				}
			} else {
				json[field.name] = parseFieldValue(field, val);
			}
		}
	});

	return json;
}

export function getDiff(field, baseValueProp, finalValueProp, row) {
	let baseValue;
	let finalValue;
	switch (field.type) {
		case 'image':
			baseValue = getSpecificValue(field, baseValueProp, row);
			finalValue = getSpecificValue(field, finalValueProp, row);
			if(finalValue !== baseValue) {
				return [
					baseValue,
					finalValue
				];
			} else {
				return [finalValue];
			}
		case 'vidsv':
		case 'multiselect_tree':
			baseValue = field[baseValueProp];
			finalValue = field[finalValueProp];
			let baseValues = baseValue == null ? [] : getPathToNestedClassifiers(baseValue, field.options).split(', ');
			let finalValues = finalValue == null ? [] : getPathToNestedClassifiers(finalValue, field.options).split(', ');

			let combinedValues = _.uniq([...baseValues, ...finalValues]);
			// let combinedSortedValues = sortClassifierValues(combinedValues, field.options);
			let combinedSortedValues = combinedValues.sort();

			let resHTML = `<div class="ms-column">`;

			combinedSortedValues.forEach((value, index) => {
				if (index && index % 5 === 0) {
					resHTML += `</div><div class="ms-column">`;
				}

				if(baseValues.includes(value)) {
					if(finalValues.includes(value)) {
						resHTML += `<span>${value}</span>`;
					} else {
						resHTML += `<span class='removed'>${value}</span>`;
					}
				} else {
					if(finalValues.includes(value)) {
						resHTML += `<span class='added'>${value}</span>`;
					}
				}
			});

			resHTML += `</div>`;

			if(resHTML !== '') {
			//	resHTML = resHTML.slice(0, -4);
			}

			return resHTML;
		default:
			baseValue = getSpecificValue(field, baseValueProp, row);
			finalValue = getSpecificValue(field, finalValueProp, row);

			if (field.type === 'url' && baseValue === finalValue) {
				return [finalValue];
			}

			if(finalValue !== baseValue) {
				baseValue = baseValue == null ? '' : baseValue.toString();
				finalValue = finalValue == null ? '' : finalValue.toString();
				var dmp = new diff_match_patch();
				var diffs = dmp.diff_main(baseValue, finalValue);
				// Result: [(-1, "Hell"), (1, "G"), (0, "o"), (1, "odbye"), (0, " World.")]
				dmp.diff_cleanupSemantic(diffs);
				// Result: [(-1, "Hello"), (1, "Goodbye"), (0, " World.")]

				let resHTML = '';
				diffs.forEach(diff => {
					resHTML += `<span class="${diff[0] === 1 ? 'added' : diff[0] === -1 ? 'removed' : ''}">${diff[1]}</span>`;
				});

				return resHTML;
			} 
			return finalValue;
	}
	
}

export function getFieldValueWithDiff(field, row, context, box = 'main') {
	if (box === 'vendex-modal') {
		return getDiff(
			field,
			'submittedOldValue',
			'submittedNewValue',
			row
		);
	}

	let baseValue;
	let finalValue;

	switch(context) {
		case 'VENDOR':
			// grey box. row.responded && context === 'VENDOR' && !row.certified && row.accepted === false  
			if(box === 'response') { 
				baseValue = 'submittedNewValue';
				finalValue = field.submittedVendexValue != null ? 'submittedVendexValue' : 'vsourceValue';
			} else {
				if(row.hasLatestRevision) {
					baseValue = 'vsourceValue';
					finalValue = field.latestNewValue != null ? 'latestNewValue' : 'vsourceValue';
				} else {
					baseValue = 'vsourceValue';
					finalValue = 'vsourceValue';
				}
			}
			break;
		case 'VENDEX':
			if(row.submitted) {
				if(row.vendexResponseType) {
					if(row.submittedRevision.vendexRespondedAt) {
						baseValue = finalValue = field.submittedVendexValue != null ? 'submittedVendexValue' : 'vsourceValue';
						finalValue = finalValue = field.submittedVendexValue != null ? 'submittedVendexValue' : 'vsourceValue';
					} else {
						baseValue = field.submittedNewValue != null ? 'submittedNewValue' : 'vsourceValue';
						finalValue = finalValue = field.submittedVendexValue != null ? 'submittedVendexValue' : 'vsourceValue';
					}
				} else {
					if(row.accepted) {
						baseValue = field.submittedNewValue != null ? 'submittedNewValue' : 'vsourceValue';
						finalValue = field.submittedNewValue != null ? 'submittedNewValue' : 'vsourceValue';
					} else {
						baseValue = 'submittedOldValue';
						finalValue = field.submittedNewValue != null ? 'submittedNewValue' : 'vsourceValue';
					}
				}
			} else {
				baseValue = 'vsourceValue';
				finalValue = 'vsourceValue'
			}
			break;
		case 'VENDOR_PREVIEW':
			baseValue = field.latestNewValue != null ? 'latestNewValue' : 'vsourceValue';
			finalValue = baseValue;
			break;
		default: //context === 'PUBLIC'
			baseValue = 'vsourceValue';
			finalValue = 'vsourceValue'
			break;
	}

	if(context !== 'PUBLIC' && context !== 'VENDOR_PREVIEW' && row.new) {
		if(row.responded && row.accepted === false && row.submittedRevision.vendexResponseTypeCode === 'REJECT_SUBMIT_TO_VSOURCE') {
			baseValue = 'vsourceValue';
		} else {
			baseValue = field.latestNewValue ? 'latestNewValue' : 'submittedNewValue';
		}
		finalValue = field.submittedVendexValue != null ? 'submittedVendexValue' : field.latestNewValue ? 'latestNewValue' : 'submittedNewValue';
	}

	if (field[baseValue] == null && field[finalValue] == null) {
		return `[${field.label}]`;
	}

	return getDiff(
		field,
		baseValue,
		finalValue,
		row
	);
}

export function getSpecificValue(field, valueProp, row = null) {
	if(valueProp === 'emptyValue') {
		return '';
	}

	switch (field.name) {
		case 'revenueAmount': {
			const currencyCodeField = row.fields.find(field => field.name === 'currencyCode');
			return field.formatedValue ?
				(field.formatedValue(field[valueProp], currencyCodeField[valueProp]) || '')
				: field[valueProp];
		}
		case 'socialMediaTypeCode': {
			return field[valueProp] === 'TWITTER' ? 'X' : field.options.find(option => option.value === field[valueProp])?.text;
		}
		default: {
			if(field.formatedValue) {
				return field.formatedValue(field[valueProp]);
			}

			if (field.type === 'select') {
				return field.options.find(option => option.value === field[valueProp])?.text;
			}

			if (field.type === 'multiselect_tree' || field.type === 'vidsv') {
				return field[valueProp] != null ? field[valueProp].split(' ')
					.map(classifier => field.options.find(option => option.value === classifier)?.text)
					.join(', ') :
					null
			}

			return field[valueProp];
		}
	}
}

export function parseFieldValue(field, value){
	switch(field.type) {
		case 'number':
			return Number(value);
		default:
			return value;
	}
}

export function getPathToNestedClassifiers(values, classifiers) {
	let valuesArray = Array.isArray(values) ? values : values.split(' ');
	let paths = [];
	
	valuesArray.forEach(value => {
		paths.push(getPathToNestedClassifier(value, classifiers));
	})

	return paths.join(', ');
}

export function getPathToNestedClassifier(value, classifiers) {
	let path = '';
	let currentClassifier = classifiers.find(classifier => classifier.code === value);
	if(currentClassifier) {
		path = currentClassifier.text || currentClassifier.value;

		while(currentClassifier.parent_classifier_code) {
			currentClassifier = classifiers.find(classifier => classifier.code === currentClassifier.parent_classifier_code);
			if(!currentClassifier) {
				break;
			}
			path = (currentClassifier.text || currentClassifier.value) + ' > ' + path;
		}
	}

	return path;
}

export function shouldDisplayRow(row, context) {
	if(row.status === 'TEMPLATE') {
		return false;
	}

	if(context === 'PUBLIC') {
		if(row.itemTypeCode === 'RECORD' && row.vsourceRecordValueJson == null) {
			return false;
		}
	}

	if(context === 'VENDOR_PREVIEW') {
		if(row.deleted && (!row.responded || row.accepted)) {
			return false;
		}

		if(row.new && row.responded && row.submittedRevision.vendexResponseTypeCode === 'REJECT_WITHOUT_EDITING') {
			return false;
		}
	}

	if(context !== 'VENDOR' && context !== 'VENDOR_PREVIEW' && row.new && row.submittedRevision == null ) {
		return false;
	}
	
	return true;
}

export function getRowClassNames(row, context, isResponseRow) {
	let classes = [];
	if(['location', 'customer_support'].includes(row.tableName)) {
		classes.push('p0');
	}

	if(!['PUBLIC', 'VENDOR_PREVIEW'].includes(context)) {
		if(isResponseRow) {
			if(row.new) {
				if(row.submittedRevision.vendexResponseTypeCode === 'REJECT_SUBMIT_TO_VSOURCE') {
					classes.push('new');
				} else {
					classes.push('deleted');
				}
			} else if(row.deleted) {
				classes.push('new');
			}
		} else {
			if(context === 'VENDEX') {
				if(row.submitted) {
					if(row.accepted === false) {
						if(row.new) {
							if(row.submittedRevision.vendexResponseTypeCode === 'REJECT_SUBMIT_TO_VSOURCE' || row.vendexResponseType === 'REJECT_SUBMIT_TO_VSOURCE') {
								classes.push('new');
							} else {
								classes.push('deleted');
							}
						} else if(row.deleted) {
							classes.push('new');
						}
					} else {
						if(row.new) {
							classes.push('new');
						} else if(row.deleted) {
							classes.push('deleted');
						}
					}
				}
			} else { // context = VENDOR
				if(row.new && row.proposed) {
					if(row.responded && row.submittedRevision.vendexActionTypeCode === 'REJECTED') {
						classes.push('deleted');
					} else {
						classes.push('new');
					}
				} else if(row.deleted) {
					if(!row.responded || row.submittedRevision.vendexActionTypeCode !== 'REJECTED') { 
						classes.push('deleted');
					}
				}
			}
			
		}
	}

	return _uniq(classes).join(' ');
}

export function resetFieldValues(fields, leaveLatest) {
	fields.forEach(field => {
		if(leaveLatest) {
			var latest = field.latestNewValue ? 'latestNewValue' : 'vsourceValue';
			var value = field[latest];
		}

		field.latestNewValue = null;
		field.latestOldValue = null;
		field.submittedNewValue = null;
		field.submittedOldValue = null;
		field.submittedVendexValue = null;
		field.vsourceValue = null;

		if(leaveLatest) {
			field.vsourceValue = value;
		}
	});
}