import React, {useState, useEffect} from "react";
import styled from "styled-components";
import { Storage } from "aws-amplify";

const WrapperStyles = styled.article`
  h1 {
    margin-top: 35px!important;
    color: #000;
  }
  li {
    list-style-type: disc;
    margin-left: 1em;
    margin-bottom: 1em;
  }
`
const HelpVidsvText = () => {
    const [exampleXlsxFileUrl, setXlsxFileUrl] = useState("");

    useEffect(()=>{
        Storage.get(`vlink/ProductAttributeExamples.xlsx`, {
            level: "public",
            expires: 60 * 60
        })
            .then((s3FileUrl) => {
                setXlsxFileUrl(s3FileUrl);
            })
            .catch((e) => console.error("Error fetching Product Attributes Excel file URL: ", e));
    },[])


    return <WrapperStyles>
        <p>
            <b>Things to know about reporting Product Attributes:</b>
        </p>
        <ul>
            <li>
                The Solutions, Classifications, and Geographies lists are built so that the highest level of known specificity should be indicated.
            </li>
            <li>
                For example, if an organization provides Validated Pricing for Fixed Income in EMEA, without having further breakdown or specificity, EMEA should be selected.
            </li>
            <li>
                Because "EMEA" is selected, it does not mean that every single continent or country in EMEA will be included in search results. If it is known that specific continents or regions are included, e.g., Europe and Africa, these continents should be selected.
            </li>
            <li>
                Similarly, if it is known that within Europe, the organization provides validated pricing for fixed income in France, Germany, Switzerland, and Italy, these countries should be selected.
            </li>
        </ul>
        <p>
            This holds true for the Solutions (e.g., Validated Pricing, Analytics) and Classifications (Asset Classes, e.g., Fixed Income or Industry Sector, e.g., Pharmaceuticals) as well as Geographies.

        </p>
        <p>
            For all items in the drop-down list that are marked as "general", it  should be noted that selection will not include all of the items in that category in the VSource search results. These need to be selected individually, again, to the highest level of known specificity.

        </p>
        <p>
            <b>Things to know about segmentation of Product Groupings:</b>
        </p>
        <p>
            Often within a single product, there will be Groupings of Solutions, Classifications, and Geographies that share similar characteristics and have an "all to all" relationship.
            VLink has the ability to define Groupings and to delineate or segment the products based on Solutions, Classifications, and Geographies. They should be separated into multiple Groupings that share common Solutions, Classifications, and Geographies.

        </p>
        <p>
            This enables the user to specify that within a single product:

        </p>
        <p>
            -	<b>Grouping 1</b>: All of these Solutions (e.g., Validated Pricing, Analytics) are available for all of these Classifications (e.g., Fixed Income, Pharmaceuticals) in all of these Geographies (e.g., France, Germany, Switzerland, and the U.K.)

        </p>
        <p>
            And
        </p>
        <p>
            -	<b>Grouping 2</b>: All of these Solutions (e.g., News, Trend Analysis) are available for all of these Classifications (e.g., Equities) in all of these Geographies (e.g., Globally).
            Note that sometimes the Solutions and/or Classifications will vary, and sometimes the Geographies will vary. They should all be separated into Groupings accordingly.
            See Examples in <a href={exampleXlsxFileUrl} download>ProductAttributeExamples.xlsx</a> – 2 Examples
        </p>
    </WrapperStyles>
}

export default HelpVidsvText;