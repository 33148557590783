import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {AuthProvider} from './context/AuthContext';
import {BrowserRouter} from 'react-router-dom';
import * as Sentry from "@sentry/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { BrowserTracing } from "@sentry/tracing";
import {getEnvByHostname} from "./utils/environments";

const env = getEnvByHostname();

Sentry.init({
    dsn: "https://55ceade0141d4bf5bca596259a1c04a5@o1196936.ingest.sentry.io/6319845",
    integrations: [
        new BrowserTracing(),
        new CaptureConsoleIntegration(
            {
              // array of methods that should be captured
              // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
              levels: ['error']
            }
          )
    ],
    denyUrls: ['http://localhost:3000'],
    // debug: false,
    environment: env,
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: env === "Production" ? 0.5 : 1.0,
  });

ReactDOM.render(<>
        <AuthProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </AuthProvider>
        </>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
