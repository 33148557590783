import React from "react";

const AddIcon = props => (
    <svg width={16} height={16} {...props}>
        <g fillRule="nonzero">
            <path d="M8 16a1 1 0 0 1-1-1V1a1 1 0 1 1 2 0v14a1 1 0 0 1-1 1z" />
            <path d="M15 9H1a1 1 0 1 1 0-2h14a1 1 0 1 1 0 2z" />
        </g>
    </svg>
);

export default AddIcon;

