import styled from "styled-components";
import { default as NumberFormat } from 'react-number-format';

export const NumberFormatStyled = styled(NumberFormat)`
    position: relative;
    display: block;
  
    height: 24px;
    width: 100%;
    padding: 16px;
    border: 1px solid #E8E8F0;
    background: #FBFCFD;
    border-radius: 3px;
`;