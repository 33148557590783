import { PageWrapper } from "../../components/PageWrapper";
import PremiumForm from "../../components/vlink/PremiumForm";
import RevisionForm from "../../components/vlink/RevisionForm";
import ProfileHeader from "../../components/ProfileHeader";
import { VendorFormsWrapper } from "./VendorDetails";

export default function ProductDetailsPublic() {

    let context = 'PUBLIC';

    return (
        <PageWrapper>
            <ProfileHeader context={context} level='PRODUCT' />
            <VendorFormsWrapper>
                <RevisionForm 
                    context={context}
                    level='PRODUCT'
                />
                <PremiumForm context={context} level='PRODUCT' />
            </VendorFormsWrapper>
        </PageWrapper>
    )
}