import styled from "styled-components";

export const CompanyInfoSection = styled.section`
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 2em 2em 2em;
    flex: 0 0 auto;
    //width: 100%;
`;
