import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageWrapper } from "../../components/PageWrapper";
import { PageHeader } from "../../components/PageHeader";
import CreateProductForm from "../../components/forms/CreateProductForm";
import CloseIcon from "../../components/icons/CloseIcon";
import { VsourceContext } from "../../context/vsource/vsourceContext";
import { get } from "lodash";

export const ProductConfiguratorPage = ({ history, match }) => {
    const { addProduct, fetchProduct, updateProduct } = useContext(
        VsourceContext
    );

    const [isEditing, setEditing] = useState(false);
    const [pageTitle, setPageTitle] = useState("...");
    const [productId] = useState(get(match, "params.id", null));
    const [product, setCurrentProduct] = useState({
        productName: "",
        serviceType: null,
        vendExProductId: "",
        briefDescription: "",
        productDescription: "",
    });

    useEffect(() => {
        if (productId !== "new") {
            setEditing(true);
            if (productId !== null) {
                fetchProduct(productId)
                    .then((fetchedProduct) => {
                        console.log("product : ", fetchedProduct);
                        setCurrentProduct({
                            ...fetchedProduct,
                        });
                        setPageTitle("Edit Product");
                    })
                    .catch((err) => {
                        console.error(err)
                        history.goBack();
                    });
            }
        } else {
            setEditing(false);
            setPageTitle("New Product");
        }
    }, []);

    const goBack = useCallback(() => {
        history.goBack();
    }, []);

    const handleFormSubmission = useCallback(
        async (productData, navTo) => {
            console.log("productData : ", productData);
            if (isEditing) {
                updateProduct({ ...productData, id: productId }).then((_) => {
                    history.goBack();
                });
                return;
            }
            addProduct(productData)
                .then((_) => {
                    history.goBack();
                })
                .catch((e) => {
                    console.error("Error creating product", e);
                });
        },
        [addProduct]
    );

    return (
        <PageWrapper>
            <PageHeader title={pageTitle}>
                <CloseIcon onClick={goBack} />
            </PageHeader>
            <CreateProductForm {...product} onSubmit={handleFormSubmission} />
        </PageWrapper>
    );
};
