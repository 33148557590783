import React, { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute = (props) => {
    const { component: Component, ...rest } = props;
    const { context } = useContext(AuthContext);

    return (
        <Route
            {...rest}
            render={(props) => {
              if (context.user) {
                return <Component {...props} {...rest} />;
              }

              return <Redirect
                to={{
                  pathname: "/login",
                  state: { from: props.location },
                }}
              />
            }}
        />
    );
};
