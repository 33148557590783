import API, { graphqlOperation } from "@aws-amplify/api";
import * as queries from "../../graphql/queries";
import { get } from "lodash";

const fetchAgreementMeta = async (options, { dispatch }) => {
    dispatch({ type: "FETCH_AGREEMENT_META" });
    const {
        data: { getAgreementMeta: agreementMeta },
    } = await API.graphql(
        graphqlOperation(queries.getAgreementMeta, {
            id: options.id,
        })
    );

    const definitionItems = get(agreementMeta, "definitions.items", []);
    definitionItems.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    dispatch({ type: "AGREEMENT_META_FETCHED", agreementMeta });
    return agreementMeta;
};

export default fetchAgreementMeta;
