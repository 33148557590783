import React, { useState, useEffect } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import { postsByDate } from "../graphql/queries";
import { format } from "date-fns";
import styled from "styled-components";
import styledMap from "styled-map";
import { newLinesToBreaks } from "../utils/textConverters";

const Section = styled.div`
    margin-top: ${styledMap`
    first-item: 0;
    default: 2rem;
    `};

    & > p {
        margin-bottom: 0.5em;
    }
`;

export const NewsSection = () => {
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        const fetchNews = async () => {
            try {
                let {
                    data: {
                        postsByDate: { items },
                    },
                } = await API.graphql(
                    graphqlOperation(postsByDate, {
                        type: "News",
                        sortDirection: "DESC",
                        filter: {
                            and: [
                                { activeFrom: { lt: new Date().toISOString() } },
                                { activeTo: { gt: new Date().toISOString() } },
                            ],
                        },
                    })
                );

                setNewsList(items);
            } catch (e) {
                console.log("Error fetching news:", e);
            }
        };

        fetchNews();
    }, []);

    if (newsList.length === 0) return null;

    return newsList.map((el, i) => {
        const postedOn = format(el?.createdAt, "DD-MMM-YYYY");

        return (
            <Section first-item={i === 0 ? true : false} key={el?.id}>
                {postedOn && <p>Posted on {postedOn}</p>}
                <p
                    dangerouslySetInnerHTML={{
                        __html: newLinesToBreaks(el?.description),
                    }}
                />
            </Section>
        );
    });
};
