import React from "react";
import styled from "styled-components";

export const MainWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: ${({ theme }) => theme.darkBlue};
    font-size: 1.1rem;

    & > div {
        display: inline-block;
    }
    .label {
        text-transform: capitalize;
        margin-right: 0.4rem;
        #sv {
            font-size: 0.9rem;
        }
    }

    .yellow-text {
        color: ${({ theme }) => theme.primary};
    }
`;

const VidSV = ({ value }) => {
    return (
        <MainWrapper className={"item"}>
            <div className={"label"}>
                <span className="yellow-text">V</span>ID:
            </div>
            <div>{value}</div>
        </MainWrapper>
    );
};

export default VidSV;
