import styled from "styled-components";

export const CopyRight = styled.div`
    position: absolute;
    display: block;
    opacity: 0.4;
    font-size: 12px;
    color: #595973;
    letter-spacing: -0.01px;
    text-align: center;
    bottom: 16px;
    right: 8px;
    left: 8px;
`;