import React from "react";

const ProfileIcon = props => (
    <svg width={20} height={24} {...props}>
        <path
            d="M20 22.91A1.1 1.1 0 0 1 18.889 24a1.1 1.1 0 0 1-1.111-1.09c0-4.218-3.483-7.637-7.778-7.637s-7.778 3.42-7.778 7.636A1.1 1.1 0 0 1 1.112 24 1.1 1.1 0 0 1 0 22.91c0-5.423 4.477-9.82 10-9.82s10 4.397 10 9.82zM10 12c-3.375 0-6.111-2.687-6.111-6S6.625 0 10 0c3.375 0 6.111 2.687 6.111 6S13.375 12 10 12zm0-2.182c2.148 0 3.889-1.709 3.889-3.818 0-2.11-1.74-3.818-3.889-3.818C7.852 2.182 6.111 3.89 6.111 6c0 2.11 1.74 3.818 3.889 3.818z"
            fill="#000"
            fillRule="nonzero"
        />
    </svg>
);

export default ProfileIcon;

