import { useApolloClient } from "@apollo/client";
import React, { createContext, useMemo } from "react";
import { useImmerReducer } from "use-immer";
import fetchNotifications from "./fetchNotifications";

const initialState = {
    notifications: [],
    errorMessage: null,
    loading: false,
    newNotificationsCount: 0,
};

const reducer = (draft, action) => {
    switch (action.type) {
        case "SET_NOTIFICATIONS":
            const count = action.payload.length - draft.notifications.length;
            if(count < 0){
                draft.newNotificationsCount = 0;
            } else {
                draft.newNotificationsCount = count;
            }
            draft.notifications = action.payload;
            break;
        case "SET_LOADING":
            draft.loading = action.payload;
            break;
        case "SET_ERROR_MESSAGE":
            draft.error = action.payload;
            break;
        case "SET_NEW_NOTIFICATIONS_COUNT":
            draft.newNotificationsCount = action.payload;
            break;
        default:
            break;
    }
};

export const NotificationContext = createContext(initialState);

export const NotificationContextProvider = ({ children, ...rest }) => {
    const [context, dispatch] = useImmerReducer(reducer, initialState);
    const {query} = useApolloClient();

    const value = useMemo(() => {
        return {
            context,
            dispatch,
            fetchNotifications: () => fetchNotifications(query, dispatch),
        };
    }, [context, dispatch, query]);

    return (
        <NotificationContext.Provider {...rest} value={value}>
            {children}
        </NotificationContext.Provider>
    );
};
