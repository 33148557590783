export default function sortClassifiers(a, b) {
    let num1 = a.order_num ?? Infinity;
    let num2 = b.order_num ?? Infinity;

    // Order by order_num
    if(num1 < num2) {
        return -1;
    } else if(num1 > num2) {
        return 1;
    }

    // If order_nums are equal
    if(a.text < b.text) {
        return -1;
    }

    return 0;
}