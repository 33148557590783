import React from "react";
import { Input } from "semantic-ui-react";

const UpdateImage = ({...props}) => {
    const onChange = (e) => {
        props.onChange(e.target.files[0]);
    }
    return <>
        {props.label ? <label>{props.label}:</label> : null}
        <Input type="file" accept=".png, .jpg, .jpeg" onChange={(e) => onChange(e)} label={false} />
    </>
}

export default UpdateImage;