import React from "react";

const VSourceIcon = props => (
    <svg width={32} height={32} {...props}>
        <path
            d="M14.88 10.087C10.826 7.995 6.406 6.874 1.92 6.8h-.493C.762 6.817.232 7.412.24 8.136v19.13c.002.354.135.69.367.935.238.246.554.382.88.376h.437c4.21.072 8.36 1.098 12.185 3.013.327.16.708.13 1.011-.08.304-.21.489-.572.492-.964v-19.2c.002-.539-.285-1.03-.732-1.259zm-.228 20.439a.11.11 0 0 1-.047.094.1.1 0 0 1-.103 0A29.184 29.184 0 0 0 1.92 27.53h-.457a.221.221 0 0 1-.183-.076.298.298 0 0 1-.08-.205V8.119c0-.156.114-.282.256-.286h.468c4.346.075 8.627 1.163 12.552 3.193.11.06.177.18.177.31v19.19zM14.4 7.876a.55.55 0 0 1 .132.699.459.459 0 0 1-.635.187C8.88 5.405 4.521 4.522 3.12 4.292l-.183 1.07v.002c-.047.288-.3.48-.566.427-.265-.052-.441-.328-.394-.616l.205-1.204v.002c.08-.472.485-.792.922-.731 1.308.18 5.958 1.057 11.296 4.633zm.361-.794c-3.926-3.66-7.526-5.235-8.71-5.69l-.32.9c-.099.268-.379.398-.626.291-.246-.107-.365-.412-.266-.68L5.216.858C5.381.424 5.83.201 6.24.349c1.085.413 4.96 2.026 9.149 5.94a.561.561 0 0 1 .045.744.459.459 0 0 1-.685.049h.012zm15.805-.275h-.492C25.59 6.88 21.173 8 17.12 10.087c-.446.23-.732.719-.732 1.256v19.186c.003.39.188.754.492.964a.99.99 0 0 0 1.012.08 28.258 28.258 0 0 1 12.188-3h.435c.328.006.644-.129.88-.375.238-.25.37-.593.37-.953V8.115c-.008-.715-.537-1.294-1.195-1.308h-.004zm.23 20.442c0 .077-.028.15-.08.205a.232.232 0 0 1-.188.08h-.458a29.178 29.178 0 0 0-12.579 3.102.094.094 0 0 1-.102 0 .106.106 0 0 1-.048-.094V11.347a.35.35 0 0 1 .175-.31c3.929-2.031 8.214-3.12 12.564-3.193h.468c.142.004.256.13.256.285l-.008 19.12zM17.444 8.595a.564.564 0 0 1-.06-.395.521.521 0 0 1 .216-.325c5.338-3.576 9.987-4.452 11.284-4.637.437-.062.842.26.921.731l.205 1.204c.048.288-.129.564-.394.615-.265.052-.518-.14-.566-.428l-.182-1.07c-1.402.212-5.76 1.112-10.778 4.472a.46.46 0 0 1-.66-.17l.014.003zm8.505-7.203c-1.184.452-4.8 2.03-8.714 5.69a.459.459 0 0 1-.685-.05.561.561 0 0 1 .045-.744C20.8 2.375 24.675.762 25.76.348c.418-.155.873.073 1.036.518l.378 1.043h.001c.099.27-.021.574-.267.68-.247.108-.528-.022-.627-.29l-.332-.907z"
            fillRule="nonzero"
        />
    </svg>
);

export default VSourceIcon;

